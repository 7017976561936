import { useState, useRef } from 'react';
import { useSteps } from '@chakra-ui/react';
import { useNavigate, useParams } from 'react-router-dom';
import { useIntl } from 'react-intl';
import { LocalDate } from '@webapp/bff/src/types/dates';
import { pointArrayToWkt } from '@lib/gis';
import StepCreate from './StepCreate';
import useMapPositionManager from './useMapPositionManager';
import CreateSiteMap from './CreateSiteMap';
import { SiteType } from '../CreateSiteTrigger/types';
import { pathSitesEdit } from '../../constants/path';
import { SiteFormSections } from '../SiteEdit/EditSite';
import {
  WorkAreasEditorList,
  useWorkAreasEditorManager,
} from '../WorkAreasEditor';
import useWorkAreasData from '../../data/useWorkAreasData';
import OccupationDatesEditor from '../OccupationDatesEditor';
import useSiteData from '../../data/useSiteData';
import { DisruptionPackSection } from '../DisruptionPackSection';
import DisruptionPackMainPanel from './DisruptionPackMainPanel';
import useDisruptionPacksData from '../../data/useDisruptionPacksData';
import SiteDetailStep from './SiteDetailStep';
import { useDisruptionAreaEditorManager } from '../DisruptionAreaEditor';
import DisruptionAreaEditorAutoSavePanel from '../DisruptionAreaEditor/DisruptionAreaEditorAutoSavePanel';
import { useAnalytics } from '../../hooks/analytics/useAnalytics';
import { RoutesEditorSection, useRoutesEditorManager } from '../RoutesEditor';
import useRoutesData from '../../data/useRoutesData';
import MonitoringSection from './MonitoringSection';
import useSitesData from '../../data/useSitesData';
import useCurrentUserData from '../../data/useCurrentUserData';
import { useSitePermissions } from '../../hooks/useSitePermissions';
import LayoutMultiPanel from '../LayoutMultiPanel';
import CreateWizardLayout from '../CreateWizardLayout/CreateWizardLayout';
import useFeatureSwitch, {
  FeatureSwitchState,
} from '../../hooks/useFeatureSwitch';
import { FEATURE_SITE_IMPACT_UI_BASELINES } from '../../constants/features';
import StepBaseline from './StepBaseline';

enum CreateSiteStepsOriginal {
  CREATE,
  DATES,
  WORK_AREAS,
  DISRUPTION_AREA,
  DISRUPTION_PACK,
  ROUTES,
  MONITORING,
}
enum CreateSiteStepsFeatureFlagBaseline {
  CREATE,
  DATES,
  WORK_AREAS,
  DISRUPTION_AREA,
  DISRUPTION_PACK,
  ROUTES,
  BASELINE,
  MONITORING,
}

export default function SiteCreate() {
  const { insightId, siteType: siteTypeFragment } = useParams();
  if (!insightId) {
    throw new Error('Insight ID is required');
  }
  const siteType = fragmentToSiteType(siteTypeFragment);
  const navigate = useNavigate();
  const { formatMessage } = useIntl();
  const stepsManager = useSteps();
  const mapPositionManager = useMapPositionManager();
  // TODO: remove test site id
  // const [currentSiteId, setCurrentSiteId] = useState<string>('1001978');
  const [currentSiteId, setCurrentSiteId] = useState<string>();
  const { checkSitePermission } = useSitePermissions(
    insightId && currentSiteId
      ? {
          insightId,
          siteId: currentSiteId,
        }
      : null
  );
  const goToSitePage = (siteId: string) => {
    navigate(
      {
        pathname: pathSitesEdit(insightId),
        search: `?siteId=${siteId}`,
      },
      {
        state: {
          siteFormSection: SiteFormSections.ROUTES,
          mapCenter: mapPositionManager.mapCenter,
        },
      }
    );
  };
  const siteCreatedHandler = (siteId: string) => {
    if (siteType === SiteType.PROJECT_SITE) {
      setCurrentSiteId(siteId);
    } else {
      goToSitePage(siteId);
    }
  };
  const { track } = useAnalytics();

  const workAreasDataHandler = useWorkAreasData(currentSiteId, false);
  const workAreasEditorManager = useWorkAreasEditorManager(
    workAreasDataHandler.data
  );

  const currentSiteDataHandler = useSiteData(
    !currentSiteId
      ? null
      : {
          orgId: insightId,
          siteId: currentSiteId,
        }
  );

  const routesDataHandler = useRoutesData(
    currentSiteId !== undefined ? parseInt(currentSiteId, 10) : undefined
  );

  const disruptionAreaEditorManager = useDisruptionAreaEditorManager({
    workAreas: workAreasDataHandler.data,
    disruptionArea: currentSiteDataHandler.data?.disruptionArea,
  });

  const currentUserDataHandler = useCurrentUserData();
  const disruptionPacksDataHandler = useDisruptionPacksData(
    currentSiteId,
    false
  );
  const siteDetailSaveRef = useRef<() => void>(() => {});
  const routesEditorManager = useRoutesEditorManager();

  const { startSiteMonitoring, stopSiteMonitoring } = useSitesData(insightId);

  const featureBaselinesUI = useFeatureSwitch(FEATURE_SITE_IMPACT_UI_BASELINES);
  const isFeatureBaselineUI = featureBaselinesUI === FeatureSwitchState.ON;
  const CreateSiteSteps = isFeatureBaselineUI
    ? CreateSiteStepsFeatureFlagBaseline
    : CreateSiteStepsOriginal;

  const stepsOriginal = [
    {
      name: formatMessage({
        defaultMessage: 'Name & location',
        id: 'lWJcAl',
      }),
      title: formatMessage({
        defaultMessage: 'Site details',
        id: '3sIiZI',
      }),
      renderContent: () => (
        <SiteDetailStep
          insightId={insightId!}
          siteId={currentSiteId}
          onSiteCreated={siteCreatedHandler}
          siteName={currentSiteDataHandler.data?.siteName ?? ''}
          mapPositionManager={mapPositionManager}
          imperativeSaveRef={siteDetailSaveRef}
        />
      ),
      onStepWillExit: async () => {
        await siteDetailSaveRef.current();
      },
      onCancel: currentSiteId
        ? undefined
        : () => {
            navigate(-1);
          },
    },
    {
      name: formatMessage({
        defaultMessage: 'Occupation dates',
        id: 'hWl4QU',
      }),
      title: formatMessage({
        defaultMessage: 'Define occupation dates',
        id: 'Hpfw8k',
      }),
      renderContent: () => (
        <OccupationDatesEditor
          occupationDates={{
            startDate: currentSiteDataHandler.data?.occupationStartDate,
            endDate: currentSiteDataHandler.data?.occupationEndDate,
          }}
          updateOccupationDates={async ({ startDate, endDate }) => {
            if (
              currentSiteDataHandler.data?.occupationStartDate ||
              currentSiteDataHandler.data?.occupationEndDate
            ) {
              track('Occupation dates updated', {
                referrer: 'Create Site',
                siteId: Number(currentSiteId),
                startDate,
                endDate,
              });
            } else {
              track('Occupation dates set', {
                referrer: 'Create Site',
                siteId: Number(currentSiteId),
                startDate,
                endDate,
              });
            }
            await currentSiteDataHandler.updateSiteOccupationDates({
              startDate: startDate as LocalDate,
              endDate: endDate as LocalDate,
            });
          }}
        />
      ),
    },
    {
      name: formatMessage({
        defaultMessage: 'Work area',
        id: 'Edr2gU',
      }),
      title: formatMessage({
        defaultMessage: 'Define work areas',
        id: '99Cnzu',
      }),
      renderContent: ({ LayoutFocusContent }) => (
        <WorkAreasEditorList
          workAreas={workAreasDataHandler.data || []}
          workAreasEditorManager={workAreasEditorManager}
          createWorkArea={workAreasDataHandler.createWorkArea}
          editWorkArea={workAreasDataHandler.editWorkArea}
          deleteWorkArea={workAreasDataHandler.deleteWorkArea}
          LayoutFocusContent={LayoutFocusContent}
        />
      ),
      onStepWillExit: () => {
        if (workAreasEditorManager.polygonCreatorManager.isCreating) {
          workAreasEditorManager.polygonCreatorManager.stopCreating();
        }
      },
    },
    {
      name: formatMessage({
        defaultMessage: 'Disruption area',
        id: 'SmWcwR',
      }),
      title: formatMessage({
        defaultMessage: 'Define your disruption area',
        id: 'XsPr2S',
      }),
      renderContent: () => (
        <DisruptionAreaEditorAutoSavePanel
          disruptionAreaEditorManager={disruptionAreaEditorManager}
        />
      ),
      onStepWillExit: async () => {
        const {
          polygonCreatorManager: { isCreating, stopCreating },
          disruptionAreaState,
          hasUnsavedChanges,
        } = disruptionAreaEditorManager;
        if (isCreating) {
          stopCreating();
        }
        if (
          !hasUnsavedChanges ||
          !disruptionAreaState ||
          disruptionAreaState.length < 3
        ) {
          return;
        }
        const wkt = pointArrayToWkt(disruptionAreaState);
        await currentSiteDataHandler.updateSiteDisruptionArea(wkt);
      },
    },
    {
      name: formatMessage({
        defaultMessage: 'Impact assessment',
        id: 'bf+0+y',
      }),
      title: formatMessage({
        defaultMessage: 'Impact assessment',
        id: 'bf+0+y',
      }),
      renderContent: () => (
        <DisruptionPackSection
          currentUserEmail={currentUserDataHandler.data?.email}
          disruptionPacks={disruptionPacksDataHandler.data || []}
          createDisruptionPack={disruptionPacksDataHandler.createDisruptionPack}
          createDisruptionPackTrackProps={{
            siteId: currentSiteId || '',
            referrer: 'create site',
          }}
          hasDisruptionArea={Boolean(
            currentSiteDataHandler.data?.disruptionArea
          )}
          plannedDates={{
            startDate: currentSiteDataHandler.data?.occupationStartDate,
            endDate: currentSiteDataHandler.data?.occupationEndDate,
          }}
          goToDisruptionArea={() => {
            stepsManager.setActiveStep(CreateSiteSteps.DISRUPTION_AREA);
          }}
          goToSetDates={() => {
            stepsManager.setActiveStep(CreateSiteSteps.DATES);
          }}
        />
      ),
    },
    {
      name: formatMessage({
        defaultMessage: 'Routes',
        id: 'eyx5By',
      }),
      title: formatMessage({
        defaultMessage: 'Add routes to your site',
        id: 'oTDRfm',
      }),
      renderContent: ({ LayoutFocusContent }) => (
        <RoutesEditorSection
          canEditRoutes={checkSitePermission('CanEditSiteRoutes')}
          routes={routesDataHandler.data}
          createRoute={routesDataHandler.createRoute}
          editRoute={routesDataHandler.editRoute}
          deleteRoute={routesDataHandler.deleteRoute}
          routesEditorManager={routesEditorManager}
          LayoutFocusContent={LayoutFocusContent}
        />
      ),
    },
    {
      name: formatMessage({
        defaultMessage: 'Monitoring',
        id: '1P6GMj',
      }),
      title: formatMessage({
        defaultMessage: 'Turn on monitoring?',
        id: 'fe1rQk',
      }),
      renderContent: () => (
        <MonitoringSection
          hasRoutes={!!routesDataHandler.data?.length}
          isMonitoring={!!currentSiteDataHandler.data?.active}
          startMonitoring={async () => {
            await startSiteMonitoring(Number(currentSiteId));
          }}
          stopMonitoring={async () => {
            await stopSiteMonitoring(Number(currentSiteId));
          }}
          goToRoutes={() => {
            stepsManager.setActiveStep(CreateSiteSteps.ROUTES);
          }}
        />
      ),
    },
  ];
  const stepBaseline = {
    name: formatMessage({
      defaultMessage: 'Baseline',
      id: 'QFTqtX',
    }),
    title: formatMessage({
      defaultMessage: 'Define baseline',
      id: 'NhyBQf',
    }),
    renderContent: (stepFormRenderProps) =>
      !currentSiteId ? null : (
        <StepBaseline
          siteId={currentSiteId}
          stepFormRenderProps={stepFormRenderProps}
        />
      ),
  };
  const stepsFeatureFlagBaseline = [
    ...stepsOriginal.slice(0, CreateSiteStepsFeatureFlagBaseline.BASELINE),
    stepBaseline,
    ...stepsOriginal.slice(CreateSiteStepsFeatureFlagBaseline.BASELINE),
  ];

  return (
    <LayoutMultiPanel
      contentSidebar={
        siteType === SiteType.MONITORED_NETWORK ? (
          <StepCreate
            insightId={insightId!}
            onSiteCreated={siteCreatedHandler}
            mapPositionManager={mapPositionManager}
          />
        ) : (
          <CreateWizardLayout
            stepsManager={stepsManager}
            onExit={
              currentSiteId ? () => goToSitePage(currentSiteId) : undefined
            }
            steps={
              isFeatureBaselineUI ? stepsFeatureFlagBaseline : stepsOriginal
            }
          />
        )
      }
      contentMain={
        stepsManager.activeStep === CreateSiteSteps.DISRUPTION_PACK ? (
          <DisruptionPackMainPanel />
        ) : (
          <CreateSiteMap
            mapPositionManager={mapPositionManager}
            workAreas={workAreasDataHandler.data || []}
            workAreasEditorManager={workAreasEditorManager}
            disruptionAreaEditorManager={disruptionAreaEditorManager}
            routesEditorManager={routesEditorManager}
            routes={routesDataHandler.data || []}
            isEditingDisruptionArea={
              stepsManager.activeStep === CreateSiteSteps.DISRUPTION_AREA
            }
            showSatelliteImage={
              stepsManager.activeStep === CreateSiteSteps.WORK_AREAS
            }
            showLocationPin={
              !currentSiteId ||
              stepsManager.activeStep === CreateSiteSteps.CREATE
            }
          />
        )
      }
    />
  );
}

function fragmentToSiteType(fragmentString?: string): SiteType {
  if (fragmentString === 'network') {
    return SiteType.MONITORED_NETWORK;
  }
  return SiteType.PROJECT_SITE;
}
