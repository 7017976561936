import { FormattedMessage } from 'react-intl';
import SelectionGroup from 'design-system/molecules/selection-group';
import { Box, useDisclosure } from '@chakra-ui/react';
import { useNavigate } from 'react-router-dom';
import { useState } from 'react';
import { ActiveVms } from '@webapp/bff/src/types/vms';
import { SiteView, SiteViewState } from '../../hooks/useSiteView';
import VmsIcon from '../../svg/VMS.svg?react';
import { pathSiteVmsBoard, pathSiteVmsBoards } from '../../constants/path';
import { useAnalytics } from '../../hooks/analytics/useAnalytics';
import { getCurrentSetupStep, getVmsContextMenuItems } from '../SmartVms/utils';
import VmsPreview from '../SmartVms/VmsPreview';
import DeleteBoardConfirmationModal from '../SmartVms/DeleteBoardConfirmationModal';

interface VisibleVmsSelectorProps {
  vms?: ActiveVms[];
  vmsColors: Record<string, string>;
  hiddenVms: SiteViewState['hiddenVms'];
  setHiddenVms: SiteView['setHiddenVms'];
  insightId: string;
  siteId: string;
  deleteVmsBoard: (vmsConfigId: number) => Promise<void>;
}

export default function VisibleVmsSelector({
  vms = [],
  vmsColors,
  hiddenVms,
  setHiddenVms,
  insightId,
  siteId,
  deleteVmsBoard,
}: VisibleVmsSelectorProps) {
  const navigate = useNavigate();
  const { track } = useAnalytics();
  const sortedVms = [...vms].sort((vmA, vmB) =>
    vmA.vmsName.localeCompare(vmB.vmsName)
  );
  const selectedOptions = sortedVms
    .filter((vm) => !hiddenVms.includes(vm.vmsConfigId))
    .map((vm) => vm.vmsConfigId);

  const onSelectedOptionsChange = (newSelectedOptions: string[]) => {
    const newHiddenVms = sortedVms
      .filter((vm) => !newSelectedOptions.includes(vm.vmsConfigId))
      .map((vm) => vm.vmsConfigId);

    setHiddenVms(newHiddenVms);
  };
  const {
    isOpen: deleteModalOpen,
    onOpen: onOpenDeleteModal,
    onClose: onCloseDeleteModal,
  } = useDisclosure();

  const [vmsConfigId, setVmsConfigId] = useState<string | undefined>(undefined);
  const [isOpen, setIsOpen] = useState(false);

  const handleOpenPreview = async (id: string) => {
    setVmsConfigId(id);
    setIsOpen(true);
  };

  const handleOpenDeleteModal = (id) => {
    track('Delete Vms Board Clicked', {
      property: 'Delete Vms Board',
      referrer: 'Vms List page',
      value: vmsConfigId,
    });
    setVmsConfigId(id);
    onOpenDeleteModal();
  };

  const handleDeleteBoard = async (id: number) => {
    await deleteVmsBoard(id);
    onCloseDeleteModal();
    setIsOpen(false);
  };

  const handleOnClose = () => {
    setIsOpen(false);
  };

  const vmsBoard = vms?.find((vmBoard) => vmBoard.vmsConfigId === vmsConfigId);
  const lastConnected = vmsBoard?.lastConnected;
  const lastUpdatedDate = lastConnected
    ? new Date(Number(lastConnected))
    : undefined;

  return (
    <Box p={4}>
      <SelectionGroup
        title={
          <FormattedMessage
            defaultMessage="Smart VMS"
            id="kfh6M2"
            description="Visible VMS selector title"
          />
        }
        addButton={{
          label: (
            <FormattedMessage defaultMessage="Add smart VMS" id="gyfHpc" />
          ),
          action: () => {
            navigate(pathSiteVmsBoard(insightId, siteId, null));
          },
        }}
        titleIcon={<VmsIcon />}
        onNavigationClick={() => {
          track('View more vms button clicked', {
            property: 'View more vms',
            referrer: 'Site view page',
            value: sortedVms,
          });
        }}
        navigationPath={pathSiteVmsBoards(insightId, siteId)}
        options={sortedVms.map((vm) => ({
          active: vm.status === 'Active',
          id: vm.vmsConfigId,
          label: vm.vmsName || vm.vmsConfigId,
          color: vmsColors[vm.vmsConfigId],
          warningStatus: vm.lastConnected
            ? (new Date().getTime() - vm.lastConnected) / 1000 / 60 > 60
            : !vm.isRoutePolling,
        }))}
        selectedOptions={selectedOptions}
        onSelectedOptionsChange={onSelectedOptionsChange}
        optionContextMenu={(option) => {
          const board = vms.find(
            (vmBoard) => vmBoard.vmsConfigId === option.id
          );
          const currentStep = board ? getCurrentSetupStep(board) : undefined;

          return getVmsContextMenuItems({
            orgId: insightId,
            siteId,
            vmsConfigId: option.id,
            onOpenDeleteModal: handleOpenDeleteModal,
            handleOpenPreview,
            hasSetupCompleted: !!(
              board?.stopUpdatingAt ?? board?.startUpdatingAt
            ),
            currentStep,
          });
        }}
      />
      {isOpen && (
        <VmsPreview
          orgId={insightId}
          siteId={siteId}
          deleteVmsBoard={async () => {
            await handleDeleteBoard(Number(vmsConfigId));
          }}
          vmsConfigId={vmsConfigId}
          isOpen={isOpen}
          onCancel={handleOnClose}
          isLive={vmsBoard?.status === 'Active'}
          hasSetupCompleted={
            !!(vmsBoard?.stopUpdatingAt ?? vmsBoard?.startUpdatingAt)
          }
          isRoutePolling={vmsBoard?.isRoutePolling}
          lastUpdatedDate={lastUpdatedDate}
        />
      )}
      <DeleteBoardConfirmationModal
        isOpen={deleteModalOpen}
        onClose={onCloseDeleteModal}
        onConfirm={async () => {
          await handleDeleteBoard(Number(vmsConfigId));
        }}
      />
    </Box>
  );
}
