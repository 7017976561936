import { Box, Flex, Stack, Text } from '@chakra-ui/react';
import { FormattedMessage } from 'react-intl';
import { Fragment } from 'react';
import RouteCurrentPerformance from './RouteCurrentPerformance';
import {
  DataHandlerFeedback,
  hasDataHandlerFeedBack,
} from '../../utils/dataHandler';
import useRoutePerformanceDataHandler from '../../data/useRoutePerformanceData';
import useRouteInsightsData from '../../data/useRouteInsightsData';
import LayoutPanel from '../LayoutPanel';
import useAnnotationsData from '../../data/useAnnotationsData';
import RouteInsightChart from './RouteInsightChart';

export default function RouteInsights({
  insightId,
  trackId,
  siteId,
}: {
  insightId: string;
  trackId: string;
  siteId: number;
}) {
  const routePerformanceDataHandler = useRoutePerformanceDataHandler(
    trackId,
    insightId,
    siteId
  );
  const routeInsightsDataHandler = useRouteInsightsData(
    trackId,
    insightId,
    siteId
  );
  const annotationsData = useAnnotationsData(
    insightId,
    routeInsightsDataHandler.data?.siteId
  );
  if (
    hasDataHandlerFeedBack([
      routePerformanceDataHandler,
      routeInsightsDataHandler,
    ])
  ) {
    return (
      <DataHandlerFeedback
        dataHandlersParam={[
          routePerformanceDataHandler,
          routeInsightsDataHandler,
        ]}
      />
    );
  }
  if (!routePerformanceDataHandler.data || !routeInsightsDataHandler.data) {
    return null;
  }
  return (
    <Box>
      <LayoutPanel padding={6} mb={8}>
        <RouteCurrentPerformance
          performanceData={routePerformanceDataHandler.data}
        />
      </LayoutPanel>
      {routeInsightsDataHandler.data!.insights.map(
        (routeInsight) =>
          routeInsightsDataHandler.data && (
            <Fragment key={routeInsight.insightType}>
              <LayoutPanel>
                <RouteInsightChart
                  routeName={routeInsightsDataHandler.data.routeName}
                  siteUnit={routeInsightsDataHandler.data.siteUnit}
                  timeZone={routeInsightsDataHandler.data.timeZone}
                  routeInsight={routeInsight}
                  annotations={annotationsData.data}
                />
              </LayoutPanel>
              {routeInsight.insightType === 'JOURNEY_TIME' &&
                routeInsight.items.some((item) => item.compareValue) && (
                  <LayoutPanel>
                    <RouteInsightChart
                      routeName={routeInsightsDataHandler.data.routeName}
                      siteUnit={routeInsightsDataHandler.data.siteUnit}
                      timeZone={routeInsightsDataHandler.data.timeZone}
                      routeInsight={routeInsight}
                      annotations={annotationsData.data}
                      isChartModePercentage
                    />
                    <Stack
                      direction={{ base: 'column', sm: 'row' }}
                      justifyContent="center"
                      marginBottom={2}
                      spacing={1}
                      fontSize="xs"
                    >
                      <Text as="span" fontWeight={600} textAlign="center">
                        <FormattedMessage
                          id="+7mqEn"
                          defaultMessage="Journey time compared to last 4 weeks"
                          description="Routes chart legend title"
                        />
                      </Text>
                      <Flex justifyContent="center">
                        <Text as="span">
                          <FormattedMessage
                            id="N0hDRV"
                            defaultMessage="Expected"
                            description="Route performance legend - expected"
                          />
                        </Text>
                        <Box display="inline-block" whiteSpace="nowrap">
                          {['#D0E5F8', '#FDEAD5', '#FDD9D9', '#D8C5C6'].map(
                            (color) => (
                              <Box
                                key={color}
                                marginLeft={1}
                                display="inline-block"
                                verticalAlign="middle"
                                width={4}
                                height="6px"
                                borderRadius="3px"
                                backgroundColor={color}
                              />
                            )
                          )}
                        </Box>
                        <Text as="span" marginLeft={1}>
                          <FormattedMessage
                            id="5onLKa"
                            defaultMessage="Slower"
                            description="Route performance legend - slower"
                          />
                        </Text>
                      </Flex>
                    </Stack>
                  </LayoutPanel>
                )}
            </Fragment>
          )
      )}
    </Box>
  );
}
