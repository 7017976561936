import classnames from 'classnames';

import { FormattedMessage } from 'react-intl';
import { MAX_ROUTE_COUNT } from '../../constants/clientConstants';
import { Route } from '../../types/route';
import './index.scss';

interface Props {
  routes: Route[];
  colors: string[];
  activeId?: number;
  opacity: number;
  setOpacity: (value: number) => void;
  showCount?: boolean;
  title: string;
}

export default function RoutesLegend({
  routes = [],
  colors,
  activeId,
  opacity,
  setOpacity,
  showCount = true,
  title,
}: Props) {
  return (
    <>
      <h4
        className={classnames({
          'v2-routes-legend__title': true,
          'v2-routes-legend__title--without-count': !showCount,
        })}
      >
        {title}
      </h4>
      {showCount && (
        <div className="v2-routes-legend__route-counter">
          <FormattedMessage
            defaultMessage="{routesCount} / {maxRoutes} routes"
            id="aJgoxX"
            description="route counter for routes editor"
            values={{ routesCount: routes.length, maxRoutes: MAX_ROUTE_COUNT }}
          />
        </div>
      )}
      <div>
        {routes.map((route, routeIndex) => (
          <div key={route.routeId} className="v2-routes-legend__route">
            <span
              className="v2-routes-legend__route-color"
              style={{
                backgroundColor: colors[routeIndex],
                opacity: activeId === route.routeId ? 1 : opacity,
              }}
            />
            {route.name}{' '}
            {activeId && activeId === route.routeId && (
              <FormattedMessage
                defaultMessage="(editing)"
                id="IczbBr"
                description="existing routes panel - active route label"
              />
            )}
          </div>
        ))}
        <label
          htmlFor="routes-legend"
          className="v2-routes-legend__opacity-legend"
          aria-label="Routes opacity on map"
        >
          <FormattedMessage
            defaultMessage="Routes opacity on map"
            id="423Sm1"
            description="existing routes panel - opacity slider label"
          />
        </label>
        <input
          name="routes-legend"
          className="v2-routes-legend__opacity-input"
          type="range"
          value={opacity}
          min={0}
          max={1}
          step={0.01}
          onChange={(e) => {
            setOpacity(Number(e.target.value));
          }}
        />
      </div>
    </>
  );
}
