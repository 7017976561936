import { ProviderMessage } from '@vms/shared/src/lib/integrationTypes';
import { MessageType } from '@vms/shared/src/message-types';
import {
  MessagesFramesContentType,
  OverrideScheduleConfigType,
} from '@vms/shared/src/message-types/common';

export enum VendorType {
  Bartco = 'Bartco',
  Datasigns = 'Datasigns',
  VirtualVms = 'VirtualVms',
  J1 = 'J1',
}

export function IsVendorType(value: unknown): value is VendorType {
  return (
    typeof value === 'string' &&
    Object.values(VendorType).includes(value as VendorType)
  );
}

export interface ActiveVms {
  vmsId?: string;
  vmsConfigId: string;
  vmsName: string;
  startUpdatingAt?: Date;
  stopUpdatingAt?: Date;
  lastConnected?: number;
  latitude?: number;
  longitude?: number;
  isRoutePolling: boolean;
  status: 'Active' | 'Past' | 'Future' | 'Draft' | 'Deleted';
  vmsType?: VendorType;
  vmsApiKey?: string;
  providerMessage?: ProviderMessage;
  monitoredRoutes: number[];
  messageConfig?: { type: MessageType; content?: any };
  fallbackMessageConfig?: MessagesFramesContentType;
  userEmail?: string;
  hasCredentials?: boolean;
  overrideScheduleMessageConfig?: MessagesFramesContentType;
  overrideScheduleConfig?: OverrideScheduleConfigType;
}
