import { Flex, Heading } from '@chakra-ui/react';
import { Component, ReactNode } from 'react';
import { errorReport } from '../../../utils/errors';

type Props = {
  children?: ReactNode;
};
type State = {
  hasError: boolean;
};

export default class ErrorBoundary extends Component<Props, State> {
  // eslint-disable-next-line react/static-property-placement
  static defaultProps = {
    children: null,
  };

  constructor(props: { children?: ReactNode }) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError() {
    return { hasError: true };
  }

  componentDidCatch(error, errorInfo) {
    errorReport.unknown(error, errorInfo);
  }

  render() {
    const { hasError } = this.state;
    const { children } = this.props;
    if (hasError) {
      return (
        <Flex w="full" justifyContent="center" alignItems="center">
          <Heading fontSize="sm">Oh no!!! Something went wrong.</Heading>
        </Flex>
      );
    }
    return children;
  }
}
