import { ReactNode } from 'react';
import { Flex, VStack, Box, SimpleGrid } from '@chakra-ui/react';
import UserBadge from './UserBadge';

export default function UsersList<
  TUser extends {
    userId: number | string;
    email: string;
    name: string;
    pending: boolean;
  },
>({
  currentUserId,
  users,
  getUserInfo,
  getUserAlerts,
  getUserContextMenu,
}: {
  currentUserId?: TUser['userId'];
  users: TUser[];
  getUserInfo?: (user: TUser) => ReactNode;
  getUserAlerts?: (user: TUser) => ReactNode;
  getUserContextMenu?: (user: TUser) => ReactNode;
}) {
  return (
    <VStack spacing={4}>
      {users.map((user) => {
        const isCurrentUser = Boolean(
          currentUserId && user.userId === currentUserId
        );
        return (
          <Flex
            key={user.userId}
            align="flex-start"
            justifyContent="space-between"
            data-testid="user-list-row"
            data-userid={`${user.email}`}
            width="100%"
            gap={2}
          >
            <SimpleGrid
              minChildWidth={{ base: '100%', md: '200px' }}
              flex="1 1 100%"
              maxWidth="100%"
              overflow="hidden"
              gap={2}
            >
              <Box maxWidth="100%" overflow="hidden">
                <UserBadge
                  name={user.name}
                  email={isCurrentUser ? undefined : user.email}
                  isCurrentUser={isCurrentUser}
                  isPendingUser={user.pending}
                  info={getUserInfo ? getUserInfo(user) : undefined}
                />
              </Box>
              {getUserAlerts && <Box m="auto">{getUserAlerts(user)}</Box>}
            </SimpleGrid>

            {getUserContextMenu && (
              <Box m="auto">{getUserContextMenu(user)}</Box>
            )}
          </Flex>
        );
      })}
    </VStack>
  );
}
