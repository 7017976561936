import { FormattedMessage, useIntl } from 'react-intl';
import moment from 'moment-timezone';
import {
  Box,
  Button,
  FormControl,
  FormLabel,
  HStack,
  Input,
  Stack,
  Link,
} from '@chakra-ui/react';
import { useForm } from 'react-hook-form';
import { useImperativeHandle, useState } from 'react';
import ConfirmationModal, {
  ConfirmationModalProps,
} from 'design-system/molecules/confirmation-modal';
import { ActiveVms } from '@webapp/bff/src/types/vms';
import AlertBox from '../AlertBox';
import { useFailureToast } from '../../hooks/useFailureToast';
import { CreateWizardStepRenderProps } from '../CreateWizardLayout/CreateWizardLayout';
import { equalFieldValues } from './utils';
import { FEATURE_VMS_ENABLED_TO_CUSTOMER } from '../../constants/features';
import useFeatureSwitch, {
  FeatureSwitchState,
} from '../../hooks/useFeatureSwitch';

export default function StepBoardScheduleConnection({
  vms,
  updateVmsDates,
  stepFormRenderProps: { submitActionRef, isSubmitting },
  siteTimeZone,
}: {
  vms: ActiveVms;
  updateVmsDates: (
    vmsDates: Pick<ActiveVms, 'startUpdatingAt' | 'stopUpdatingAt'>
  ) => Promise<void>;
  stepFormRenderProps: CreateWizardStepRenderProps;
  siteTimeZone: string;
}) {
  const featureVmsEnabledToCustomer = useFeatureSwitch(
    FEATURE_VMS_ENABLED_TO_CUSTOMER
  );
  const isFeatureVmsEnabledToCustomer =
    featureVmsEnabledToCustomer === FeatureSwitchState.ON;
  const { register, setValue, getValues, handleSubmit } = useForm({
    defaultValues: {
      startUpdatingAt: vms.startUpdatingAt
        ? moment(vms.startUpdatingAt)
            .tz(siteTimeZone)
            .toISOString(true)
            .slice(0, 16)
        : '',
      stopUpdatingAt: vms.stopUpdatingAt
        ? moment(vms.stopUpdatingAt)
            .tz(siteTimeZone)
            .toISOString(true)
            .slice(0, 16)
        : '',
    },
  });
  const { providerMessage } = vms;

  const [awaitingConfirmation, setAwaitingConfirmation] = useState<Omit<
    ConfirmationModalProps,
    'confirmButtonColorScheme' | 'open'
  > | null>(null);

  const failureToast = useFailureToast();
  const { formatMessage } = useIntl();
  useImperativeHandle(submitActionRef, () =>
    handleSubmit(
      async (formValues) => {
        if (!isFeatureVmsEnabledToCustomer) {
          return new Promise<void>((resolve, reject) => {
            setAwaitingConfirmation({
              body: (
                <Stack spacing={4}>
                  <AlertBox
                    message={
                      <FormattedMessage
                        defaultMessage="In order to start using Mooven’s Smart VMS messaging, you will need to add it to your Mooven subscription.{br}
                        Talk to <l>sales@mooven.com</l> to get Smart VMS added today."
                        id="rGBs41"
                        values={{
                          br: <br />,
                          l: (it) => (
                            <Link href="mailto:sales@mooven.com">{it}</Link>
                          ),
                        }}
                      />
                    }
                  />
                </Stack>
              ),
              onConfirm: () => {
                // eslint-disable-next-line prefer-promise-reject-errors
                reject(true);
              },
              onCancel: () => {
                window.location.href = 'mailto:sales@mooven.com';
                // eslint-disable-next-line prefer-promise-reject-errors
                reject(true);
              },
              cancelButtonText: formatMessage({
                defaultMessage: 'Contact Mooven',
                id: 'qkU/KI',
              }),
              confirmButtonText: formatMessage({
                defaultMessage: 'Close',
                id: 'rbrahO',
              }),
              title: formatMessage({
                defaultMessage:
                  'You’ll need smart VMS added to your subscription',
                id: 'Cp675X',
              }),
            });
          });
        }
        const { startUpdatingAt, stopUpdatingAt } = formValues;
        if (
          equalFieldValues(
            {
              startUpdatingAt: startUpdatingAt
                ? new Date(startUpdatingAt)
                : undefined,
              stopUpdatingAt: stopUpdatingAt
                ? new Date(stopUpdatingAt)
                : undefined,
            },
            vms
          )
        ) {
          return undefined;
        }
        const startUpdatingAtDisplayValue = startUpdatingAt
          ? moment(startUpdatingAt)
              .tz(siteTimeZone, true)
              .format('ddd, D MMM, h:mm a')
          : '';
        const stopUpdatingAtDisplayValue = stopUpdatingAt
          ? moment(stopUpdatingAt)
              .tz(siteTimeZone, true)
              .format('ddd, D MMM, h:mm a')
          : '';

        return new Promise<void>((resolve, reject) => {
          setAwaitingConfirmation({
            body: (
              <Stack spacing={4}>
                {startUpdatingAt ? (
                  <Stack spacing={4}>
                    <FormattedMessage
                      defaultMessage="Your board is scheduled to go live:"
                      id="WKB0Ll"
                    />
                    <Box as="span" fontWeight="bold">
                      {startUpdatingAtDisplayValue}
                      {stopUpdatingAt && ` - ${stopUpdatingAtDisplayValue}`}
                    </Box>
                  </Stack>
                ) : (
                  <Box>
                    <FormattedMessage
                      defaultMessage="Your board has not been scheduled. Please select a starting date"
                      id="LXlC3l"
                    />
                  </Box>
                )}
                {vms.providerMessage?.warningMessage && (
                  <AlertBox
                    title={formatMessage({
                      defaultMessage: 'We are unable to connect to your board',
                      id: 'C+dfwV',
                    })}
                    message={
                      <FormattedMessage
                        defaultMessage="This may be because your board is either offline or unavailable, or the ID or credentials for your board are incorrect.{br}
            You can complete your board setup and test the connection at a later date"
                        id="Rr0l5s"
                        values={{
                          br: <br />,
                        }}
                      />
                    }
                  />
                )}
              </Stack>
            ),
            onConfirm: () => {
              updateVmsDates({
                // TODO: provide vmsConfigId from steps to avoid drilling through stepper
                // vmsConfigId: vms.vmsConfigId,
                startUpdatingAt: startUpdatingAt
                  ? moment.tz(startUpdatingAt, siteTimeZone).toDate()
                  : undefined,
                stopUpdatingAt: stopUpdatingAt
                  ? moment.tz(stopUpdatingAt, siteTimeZone).toDate()
                  : undefined,
              }).then(resolve, reject);
            },
            onCancel: () => {
              // eslint-disable-next-line prefer-promise-reject-errors
              reject(true);
            },
            title: formatMessage({
              defaultMessage: 'Are you sure?',
              id: '2oCaym',
            }),
            confirmButtonText: startUpdatingAt
              ? formatMessage({
                  defaultMessage: 'Yes, save schedule',
                  id: 'UAwNIH',
                  description: 'Schedule confirmation modal confirm button',
                })
              : formatMessage({
                  defaultMessage: 'Yes, save board',
                  id: 'bO4SqF',
                }),
          });
        });
      },
      // TODO: trigger failure toast from submit validation instead of field validation,
      // to avoid showing toast on field change/blur (if form config changes)
      // eslint-disable-next-line prefer-promise-reject-errors
      () => Promise.reject(true)
    )
  );

  const validateEndDate = (value: string) => {
    const startDate = getValues('startUpdatingAt');
    if (!value || !startDate || new Date(value) >= new Date(startDate)) {
      return true;
    }
    failureToast({
      title: formatMessage({
        defaultMessage: 'End date cannot be before start date',
        id: 'LS/vTD',
      }),
    });
    return false;
  };

  const handleClearSchedule = () => {
    setValue('startUpdatingAt', '');
    setValue('stopUpdatingAt', '');
  };

  return (
    <>
      <Stack spacing={4}>
        {providerMessage?.warningMessage && (
          <AlertBox
            title={
              <FormattedMessage
                defaultMessage="We were unable to connect to your board."
                id="D5W8c0"
              />
            }
            message={
              <FormattedMessage
                defaultMessage="This may be because your board is either offline or unavailable, or the ID or credentials for your board are incorrect.{br}
              You can complete your board setup and test the connection at a later date."
                id="5PCskL"
                values={{
                  br: <br />,
                }}
              />
            }
          />
        )}

        <HStack spacing={4}>
          <FormControl>
            <FormLabel fontSize="xs">
              <FormattedMessage
                defaultMessage="Start date & time"
                id="Ms+TB1"
              />
            </FormLabel>
            <Input
              data-testid="date-range-selection-from-date"
              type="datetime-local"
              disabled={isSubmitting}
              {...register('startUpdatingAt')}
            />
          </FormControl>
          <FormControl>
            <FormLabel fontSize="xs">
              <FormattedMessage defaultMessage="End date & time" id="D5XhxD" />
            </FormLabel>
            <Input
              data-testid="date-range-selection-to-date"
              type="datetime-local"
              disabled={isSubmitting}
              {...register('stopUpdatingAt', {
                validate: validateEndDate,
              })}
            />
          </FormControl>
        </HStack>
        <Box>
          <Button variant="outline" size="xs" onClick={handleClearSchedule}>
            <FormattedMessage defaultMessage="Clear schedule" id="AsSSHf" />
          </Button>
        </Box>
      </Stack>
      {awaitingConfirmation && (
        <ConfirmationModal
          {...awaitingConfirmation}
          open={awaitingConfirmation !== null}
          onConfirm={() => {
            awaitingConfirmation.onConfirm();
            setAwaitingConfirmation(null);
          }}
          onCancel={() => {
            awaitingConfirmation.onCancel();
            setAwaitingConfirmation(null);
          }}
        />
      )}
    </>
  );
}
