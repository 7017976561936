import useSWR, { mutate } from 'swr';
import { PublicViewConfig } from '@webapp/bff/src/types/public-views';
import { PublicView } from '../types/views';
import { envConfig } from '../config/envConfig';
import { trpc } from '../config/trpc';

export const getPublicViewsListSwrCacheKey = (
  insightId: number,
  siteId: number
) =>
  `${envConfig.API_URL}/api/v1/insights/${insightId}/sites/${siteId}/publicviews`;

export default function usePublicViewData(
  insightId: number,
  siteId: number
): {
  data: Required<PublicView>[] | undefined;
  isValidating: boolean;
  error: any;
  createPublicView: (
    view: Partial<PublicView>
  ) => Promise<Required<PublicView>>;
  editPublicView: (view: Partial<PublicView>) => Promise<void>;
  deletePublicView: (viewId: number) => Promise<void>;
} {
  const { data, error, isValidating } = useSWR<Required<PublicView>[]>(
    getPublicViewsListSwrCacheKey(insightId, siteId)
  );

  return {
    data,
    isValidating,
    error,
    createPublicView: async (view: Partial<PublicView>) => {
      const response = (await trpc.publicView.createPublicView.mutate({
        ...view,
        siteId: siteId.toString(),
        name: view.name ?? '',
        config: view.config as PublicViewConfig,
      })) as any;
      await mutate(getPublicViewsListSwrCacheKey(insightId, siteId));
      const createdView = response.data[0];
      return createdView as Required<PublicView>;
    },
    editPublicView: async (view: Partial<PublicView>) => {
      await trpc.publicView.updatePublicView.mutate({
        ...view,
        siteId: siteId.toString(),
        name: view.name ?? '',
        config: view.config as PublicViewConfig,
        viewId: view.viewId!,
      });
      await mutate(getPublicViewsListSwrCacheKey(insightId, siteId));
    },
    deletePublicView: async (viewId: number) => {
      await trpc.publicView.deletePublicView.mutate({
        viewId,
        siteId: siteId.toString(),
      });
      await mutate(getPublicViewsListSwrCacheKey(insightId, siteId));
    },
  };
}
