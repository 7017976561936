import { VehicleCountSummary } from '@data-pipeline/api/src/types/vehicle-count';
import { CountSourceIdProps } from '../../types/sharedProps';
import CountSourceMarker from './CountSourceMarker';
import './index.scss';

interface Props extends CountSourceIdProps {
  sources: VehicleCountSummary[];
}

export default function CountSourcesOverlay({
  sources = [],
  selectedSourceId,
  setSelectedSourceId,
  focusedSourceId,
  setFocusedSourceId,
}: Props) {
  return (
    <>
      {sources.map((source) => (
        <CountSourceMarker
          key={source.id}
          selectedSourceId={selectedSourceId}
          setSelectedSourceId={setSelectedSourceId}
          focusedSourceId={focusedSourceId}
          setFocusedSourceId={setFocusedSourceId}
          source={source}
        />
      ))}
    </>
  );
}
