import { Divider, HStack, VStack, useBreakpointValue } from '@chakra-ui/react';
import { useIntl } from 'react-intl';
import PlanInsightTypeSelector from '../PlanInsightTypeSelector';
import DateRangeField from './DateRangeField';
import ModalFieldsetTemplate from './ModalFieldsetTemplate';
import WorkConstraintsFieldset from './WorkConstraintsFieldset';
import DataModeFieldset, {
  PeriodValueLabel,
  PredictionModeValueLabel,
} from './DataModeFieldset';
import { PlanControlsManager, isPlanControlsManagerTypeJourney } from './types';
import { TIME_PRESET_COMPLETE_HOUR12 } from '../../utils/dateUtils';
import WeekDaysPicker from './WeekDaysPicker';
import { useAnalytics } from '../../hooks/analytics/useAnalytics';

export default function PlanControls({
  planControlsManager,
  planControlsManager: {
    insightId,
    insightType,
    insightUnitLabel,
    hasSiteImpact,
    siteTimeZone,
    availableDateRange,
    controlsState,
    setControlsState,
  },
  isCompact = false,
}: {
  planControlsManager: PlanControlsManager;
  isCompact?: boolean;
}) {
  const { formatMessage } = useIntl();
  const { track } = useAnalytics();
  const isSmallScreen = useBreakpointValue({ base: true, lg: false });
  const hasConstraintControls =
    isPlanControlsManagerTypeJourney(planControlsManager);

  const handleFieldsetChange = (newValues) => {
    Object.keys(newValues).forEach((propKey) => {
      if (controlsState[propKey] !== newValues[propKey]) {
        track('Plan Settings Changed', {
          referrer: 'Plan controls',
          control: propKey,
          value: newValues[propKey],
        });
      }
    });
    setControlsState({ ...controlsState, ...newValues });
  };

  return (
    <VStack
      padding={isCompact ? 4 : 0}
      spacing={isCompact ? 4 : { base: 4, lg: 8 }}
      backgroundColor={isCompact ? 'white' : undefined}
      shadow={isCompact ? 'soft' : undefined}
      borderRadius="2xl"
      alignItems="start"
    >
      <PlanInsightTypeSelector
        insightId={insightId}
        currentInsightType={insightType}
        hasSiteImpact={hasSiteImpact}
        isCompact={isCompact}
      />

      <HStack
        shadow={isCompact ? undefined : 'soft'}
        paddingY="6px"
        paddingX={3}
        p={isCompact ? 0 : undefined}
        spacing={4}
        divider={
          <Divider
            height={isCompact ? '20px' : '32px'}
            orientation="vertical"
            color="gray.200"
          />
        }
        backgroundColor="white"
        borderRadius="2xl"
      >
        <WeekDaysPicker
          isCompact={isCompact || isSmallScreen}
          value={controlsState.daysOfTheWeek}
          onChange={(value) => {
            handleFieldsetChange({ daysOfTheWeek: value });
          }}
        />
        <DateRangeField
          isCompact={isCompact}
          siteTimeZone={siteTimeZone}
          fieldState={{
            startDate: controlsState.startDate,
            endDate: controlsState.endDate,
          }}
          setFieldState={handleFieldsetChange}
          availableDateRange={availableDateRange}
        />
        {hasConstraintControls && (
          <ModalFieldsetTemplate
            isCompact={isCompact}
            title={formatMessage({
              defaultMessage: 'Your occupation',
              id: 'gqJd4G',
            })}
            labels={[
              {
                title: formatMessage({
                  defaultMessage: 'Your occupation',
                  id: 'gqJd4G',
                }),
                value: (
                  <TimeConstraintsValueLabel
                    startTime={planControlsManager.controlsState.startTime}
                    endTime={planControlsManager.controlsState.endTime}
                  />
                ),
              },
              {
                title: formatMessage({
                  defaultMessage: 'KPI',
                  id: 'QVgyk9',
                }),
                value: (
                  <KpiValueLabel
                    kpiValue={planControlsManager.controlsState.kpi}
                    unitLabel={insightUnitLabel}
                  />
                ),
              },
            ]}
            values={{
              showConstraints: true,
              startTime: planControlsManager.controlsState.startTime,
              endTime: planControlsManager.controlsState.endTime,
              kpi: planControlsManager.controlsState.kpi,
            }}
            onChange={handleFieldsetChange}
            renderFieldset={({ formProps }) => (
              <WorkConstraintsFieldset
                formProps={formProps}
                kpiUnitLabel={insightUnitLabel}
              />
            )}
          />
        )}
        <ModalFieldsetTemplate
          isCompact={isCompact}
          title={formatMessage({
            defaultMessage: 'Graph display settings',
            id: 'awZ6zz',
          })}
          labels={[
            {
              title: formatMessage({
                defaultMessage: 'Period',
                id: 'jCNpEL',
              }),
              value: <PeriodValueLabel period={controlsState.period} />,
            },
          ].concat(
            !hasConstraintControls
              ? []
              : [
                  {
                    title: formatMessage({
                      defaultMessage: 'Mode',
                      id: 'mrOnjM',
                    }),
                    value: (
                      <PredictionModeValueLabel
                        mode={planControlsManager.controlsState.mode}
                      />
                    ),
                  },
                ]
          )}
          values={{
            period: controlsState.period,
            ...(!hasConstraintControls
              ? {}
              : { mode: planControlsManager.controlsState.mode }),
          }}
          onChange={handleFieldsetChange}
          renderFieldset={DataModeFieldset}
        />
      </HStack>
    </VStack>
  );
}

function TimeConstraintsValueLabel({ startTime, endTime }) {
  const { formatMessage, formatTime } = useIntl();
  if (!startTime && !endTime) {
    return '---';
  }
  const formattedStartTime =
    startTime &&
    formatTime(new Date(`1/1/1970 ${startTime}`), {
      ...TIME_PRESET_COMPLETE_HOUR12,
    });
  const formattedEndTime =
    endTime &&
    formatTime(new Date(`1/1/1970 ${endTime}`), {
      ...TIME_PRESET_COMPLETE_HOUR12,
    });
  if (!endTime) {
    return formatMessage(
      {
        defaultMessage: 'From {startTime}',
        id: '09aILH',
      },
      { startTime: formattedStartTime }
    );
  }
  if (!startTime) {
    return formatMessage(
      {
        defaultMessage: 'Until {endTime}',
        id: 'zTSeu2',
      },
      { endTime: formattedEndTime }
    );
  }
  return `${formattedStartTime} - ${formattedEndTime}`;
}

function KpiValueLabel({
  kpiValue,
  unitLabel,
}: {
  kpiValue: undefined | number;
  unitLabel?: string;
}) {
  if (!kpiValue) {
    return '---';
  }
  if (!unitLabel) {
    return kpiValue.toString();
  }
  return `${kpiValue} ${unitLabel}`;
}
