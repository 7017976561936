import { useIntl } from 'react-intl';
import ConfirmationModal from 'design-system/molecules/confirmation-modal';

export interface NoteDateOutsideTimePeriodModalProps {
  onCancel: () => void;
  onConfirm: () => void;
  open: boolean;
}

export default function NoteDateOutsideTimePeriodModal({
  onCancel,
  onConfirm,
  open,
}: NoteDateOutsideTimePeriodModalProps) {
  const { formatMessage } = useIntl();
  return (
    <ConfirmationModal
      body={formatMessage({
        id: 'A8ZD8n',
        description: 'Note date outside time period modal body.',
        defaultMessage: `You're saving a note with dates outside your current view range. Are you sure you want to do this?`,
      })}
      cancelButtonText={formatMessage({
        id: '2+GZ3h',
        description: 'Note date outside time period modal cancel text.',
        defaultMessage: 'Change dates',
      })}
      confirmButtonText={formatMessage({
        id: 'QH4TPK',
        description: 'Note date outside time period modal confirm text.',
        defaultMessage: 'Save note',
      })}
      open={open}
      onCancel={onCancel}
      onConfirm={onConfirm}
      title={formatMessage({
        id: '6m96Ik',
        description: 'Note date outside time period modal title.',
        defaultMessage: 'Have you set the right dates?',
      })}
    />
  );
}
