import { useState, useEffect, useCallback } from 'react';
import {
  Box,
  Popover,
  PopoverContent,
  PopoverArrow,
  forwardRef,
  useBreakpointValue,
} from '@chakra-ui/react';
import { Annotation } from '../../types/annotations';
import { EditableAnnotation } from '../AnnotationsOverlay/EditableAnnotation';
import {
  backToFirstPhaseControl,
  ControlPoint,
  goToSecondPhaseOnTheRight,
  InsightHighchartsType,
  updateChartWhenEndDateChange,
  updateChartWhenStartDateChange,
} from '../../utils/insightCharts';

interface Props {
  isOpen: boolean;
  onClose: () => void;
  timeZone: string;
  annotation: Partial<Annotation>;
  insightHighchart?: InsightHighchartsType;
  updateControlStatus: (controlPoint: ControlPoint) => void;
  createAnnotation: (annotation: Partial<Annotation>) => Promise<void>;
  resendAnnotationAlert: (annotationId: number) => Promise<void>;
}

const PopoverNote = forwardRef<Props, 'div'>(
  (
    {
      isOpen,
      onClose,
      timeZone,
      annotation,
      insightHighchart,
      updateControlStatus,
      createAnnotation,
      resendAnnotationAlert,
    },
    ref
  ) => {
    const popoverRef = ref as React.RefObject<HTMLDivElement>;
    const [contentHeight, setContentHeight] = useState(0);
    const [contentWidth, setContentWidth] = useState(0);
    const onPopoverResize = useCallback((entries) => {
      setContentHeight(entries[0].contentRect.height);
      setContentWidth(entries[0].contentRect.width);
    }, []);
    useEffect(() => {
      const resizeObserver = new ResizeObserver(onPopoverResize);
      if (popoverRef.current) {
        resizeObserver.observe(popoverRef.current);
      }
      return () => resizeObserver.disconnect();
    }, [popoverRef, onPopoverResize]);
    const popoverContentWidth = useBreakpointValue({
      md: 480,
      sm: 368,
      base: 300,
    });
    let xPosDiff = insightHighchart?.xPosDiff;
    if (!xPosDiff || Number.isNaN(xPosDiff)) {
      xPosDiff = 0;
    }
    return (
      <Popover
        isOpen={isOpen}
        onClose={onClose}
        autoFocus={false}
        placement="top"
        closeOnBlur={false}
        isLazy
      >
        <Box
          sx={{
            '.chakra-popover__popper': {
              top: `${-contentHeight + 30}px !important`,
              zIndex: 11,
            },
          }}
        >
          <PopoverContent
            p={4}
            pos="absolute"
            opacity={0}
            rounded="md"
            border="none"
            shadow="lg"
            width={`${popoverContentWidth}px`}
            ref={popoverRef}
          >
            <EditableAnnotation
              annotation={annotation}
              timeZone={timeZone}
              onSave={async (editedAnnotation) => {
                await createAnnotation(editedAnnotation);
                onClose();
              }}
              onCancel={onClose}
              updateChartCallbacks={{
                backToFirstPhase: () =>
                  backToFirstPhaseControl(
                    updateControlStatus,
                    insightHighchart
                  ),
                goToSecondPhase: () =>
                  goToSecondPhaseOnTheRight(
                    updateControlStatus,
                    insightHighchart
                  ),
                updateChartStartDate: (startDateEpoch) =>
                  updateChartWhenStartDateChange(
                    updateControlStatus,
                    startDateEpoch,
                    insightHighchart
                  ),
                updateChartEndDate: (endDateEpoch) =>
                  updateChartWhenEndDateChange(
                    updateControlStatus,
                    endDateEpoch,
                    insightHighchart
                  ),
              }}
              onResendAlert={resendAnnotationAlert}
            />
            <PopoverArrow
              pos="absolute"
              bg="white"
              border="none"
              w="10px"
              h="10px"
              bottom="4px"
              transform={`translate(${contentWidth / 2 + xPosDiff - 5}px, ${
                contentHeight + 26
              }px) rotate(45deg)`}
            />
          </PopoverContent>
        </Box>
      </Popover>
    );
  }
);

export default PopoverNote;
