export function insertArrayItem<ArrayItem>(
  array: ArrayItem[],
  newItem: ArrayItem,
  itemPosition: number = 0
): ArrayItem[] {
  return [
    ...array.slice(0, itemPosition),
    newItem,
    ...array.slice(itemPosition),
  ];
}
export function updateArrayItem<ArrayItem>(
  array: ArrayItem[],
  newItem: ArrayItem,
  itemPosition: number
): ArrayItem[] {
  return [
    ...array.slice(0, itemPosition),
    newItem,
    ...array.slice(itemPosition + 1),
  ];
}
export function removeArrayItem<ArrayItem>(
  array: ArrayItem[],
  itemPosition: number
): ArrayItem[] {
  return [...array.slice(0, itemPosition), ...array.slice(itemPosition + 1)];
}
