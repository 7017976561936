import useSWR from 'swr';
import type { TeamUser } from '@webapp/bff';
import { useIntl } from 'react-intl';
import { InviteUsersResult } from '../local_design_system/organisms/SiteAccessSettings/InviteUsersForm';
import { useAnalytics } from '../hooks/analytics/useAnalytics';
import useSiteUsersData from './useSiteUsersData';
import useTeamData from './useTeamData';
import { useSuccessToast } from '../hooks/useSuccessToast';
import { useFailureToast } from '../hooks/useFailureToast';
import { envConfig } from '../config/envConfig';

interface SiteAlertSettings {
  siteImpact?: boolean;
  delay?: boolean;
  speed?: boolean;
  queue?: boolean;
  annotations?: boolean;
}

interface SiteAccessSettings {
  authenticatedTeamEditors: TeamUser[];
  authenticatedTeamViewers: TeamUser[];
  pendingTeamEditors: TeamUser[];
  pendingTeamViewers: TeamUser[];
  authenticatedGuestEditors: TeamUser[];
  authenticatedGuestViewers: TeamUser[];
  pendingGuests: TeamUser[];
  autocompleteEmails: string[];
  siteAlertsSettings: {
    userId: number;
    alerts: SiteAlertSettings;
  }[];
  inviteUsers: (users: InviteUsersResult) => Promise<void>;
  resendInvite: (user: TeamUser) => Promise<void>;
  sendPasswordReset: (user: TeamUser) => Promise<void>;
  removeSiteUser: (user: TeamUser) => Promise<void>;
  updateSiteRole: (
    user: TeamUser,
    newRoleValue: 'Viewer' | 'Editor'
  ) => Promise<void>;
  updateSiteAlertSettings: (
    user: TeamUser,
    newSettingsValue: SiteAlertSettings
  ) => Promise<void>;
}

export default function useSiteAccessSettings(
  insightId: number,
  siteId: number,
  suspense = true
): SiteAccessSettings {
  const { formatMessage } = useIntl();
  // TODO: Move visual feedback out of data handler. Please don't replicate this pattern.
  const successToast = useSuccessToast();
  const failureToast = useFailureToast();
  const { track } = useAnalytics();
  const usersAutoCompleteDataHandler = useSWR(
    `${envConfig.API_URL}/api/v1/user/insight/${insightId}`
  );
  const siteUsersDataHandler = useSiteUsersData(insightId, siteId);
  const {
    data: teamData,
    mutate: mutateTeamData,
    updateLocalCacheSiteRole,
    onSendPasswordReset,
  } = useTeamData(insightId.toString(), suspense);
  const {
    authenticatedTeamEditors,
    authenticatedTeamViewers,
    pendingTeamEditors,
    pendingTeamViewers,
    authenticatedGuestEditors,
    authenticatedGuestViewers,
    pendingGuests,
  } = teamData!.users.reduce(
    (acc, teamUser) => {
      if (teamUser.pending) {
        if (teamUser.teamRole === 'Editor') {
          acc.pendingTeamEditors.push(teamUser);
        } else if (teamUser.teamRole === 'Viewer') {
          acc.pendingTeamViewers.push(teamUser);
        } else {
          const hasSiteAccess = teamUser.sites.some(
            (site) => site.siteId === siteId.toString()
          );
          if (hasSiteAccess) {
            acc.pendingGuests.push(teamUser);
          }
        }
      } else if (teamUser.teamRole === 'Editor') {
        acc.authenticatedTeamEditors.push(teamUser);
      } else if (teamUser.teamRole === 'Viewer') {
        acc.authenticatedTeamViewers.push(teamUser);
      } else {
        const userSiteAccess = teamUser.sites.find(
          (site) => site.siteId === siteId.toString()
        );
        if (userSiteAccess) {
          if (userSiteAccess.siteRole === 'Editor') {
            acc.authenticatedGuestEditors.push(teamUser);
          } else if (userSiteAccess.siteRole === 'Viewer') {
            acc.authenticatedGuestViewers.push(teamUser);
          }
        }
      }
      return acc;
    },
    {
      authenticatedTeamEditors: [] as TeamUser[],
      authenticatedTeamViewers: [] as TeamUser[],
      pendingTeamEditors: [] as TeamUser[],
      pendingTeamViewers: [] as TeamUser[],
      authenticatedGuestEditors: [] as TeamUser[],
      authenticatedGuestViewers: [] as TeamUser[],
      pendingGuests: [] as TeamUser[],
    }
  );
  const siteAlertsSettings = (siteUsersDataHandler.data || []).map(
    (siteUser) => {
      if (siteUser.userId === undefined) {
        throw new Error('User provided does not have ID');
      }
      return {
        userId: siteUser.userId,
        alerts: {
          siteImpact: siteUser.siteImpactAlertEnable,
          delay: siteUser.delayAlertEnable,
          speed: siteUser.speedAlertEnable,
          queue: siteUser.queueAlertEnable,
          annotations: siteUser.annotationsAlertEnable,
        },
      };
    }
  );
  const autocompleteEmails = (usersAutoCompleteDataHandler.data ?? [])
    .filter((userEmail) =>
      teamData!.users.every((user) => user.email !== userEmail)
    )
    .map((user) => user.email);

  async function sendPasswordReset(user) {
    try {
      await onSendPasswordReset(user, 'Site Access Settings');
      successToast({
        title: formatMessage({
          defaultMessage: 'Password reset email sent',
          id: '3Jlqgv',
          description: 'Password reset email sent',
        }),
      });
    } catch (error) {
      failureToast({
        title: formatMessage({
          defaultMessage: 'Unable to send password reset email',
          id: 'k10lv+',
          description: 'Unable to send password reset email',
        }),
      });
      throw error;
    }
  }
  async function inviteUsers(users) {
    await siteUsersDataHandler.inviteSiteUsers({
      emails: users.emails.map((email) => ({
        email,
      })),
      userRolePrivilege: users.role,
      siteImpactAlert: users.siteImpactAlert,
      delayAlert: users.delayAlert,
      speedAlert: users.speedAlert,
      queueAlert: users.queueAlert,
      annotationsAlert: users.annotationsAlert,
    });
    await mutateTeamData();
    if (users.annotationsAlert) {
      track('Annotations Alert Enabled for Site Users', {
        referrer: 'Site Access Settings',
        emails: users.emails,
      });
    }
    track('Users Invited', { referrer: 'Site Access Settings', users });
  }

  async function removeSiteUser(user: TeamUser) {
    const siteUser = siteUsersDataHandler.data?.find(
      (_siteUser) => _siteUser.userId === user.userId
    );
    await siteUsersDataHandler.removeSiteUser({
      ...(siteUser || {}),
      userId: user.userId,
      email: user.email,
    });
    await mutateTeamData();
    track('Site User Removed', {
      referrer: 'Site Access Settings',
      user: siteUser,
    });
  }

  async function resendInvite(user: TeamUser) {
    await siteUsersDataHandler.resendInvite({
      email: user.email,
    });
    track('User Re-Invited', { referrer: 'Site Access Settings', user });
  }

  async function updateSiteRole(user, newRoleValue) {
    const siteUser = siteUsersDataHandler.data?.find(
      (_siteUser) => _siteUser.userId === user.userId
    );
    const updatedSiteUserPermission =
      newRoleValue === 'Editor'
        ? (siteUser?.permissions || []).concat(['UPDATE_SITE_PRIVILEGE'])
        : (siteUser?.permissions || []).filter(
            (existingPermission) =>
              existingPermission !== 'UPDATE_SITE_PRIVILEGE' &&
              existingPermission !== 'MONITOR_SITE_PRIVILEGE'
          );
    // Update local cache of new BFF
    updateLocalCacheSiteRole({
      siteId,
      userId: user.userId,
      role: newRoleValue,
    });
    // Update site role on old BFF
    await siteUsersDataHandler.updateSiteUser({
      ...(siteUser || {}),
      userId: user.userId,
      email: user.email,
      permissions: updatedSiteUserPermission,
    });
    // Since this method depends on 2 separate endpoints, things can get out of sync.
    // Make sure local chache wasn't overridden by revalidation in the meantime.
    await mutateTeamData();
  }

  async function updateSiteAlertSettings(user, newSettingsValue) {
    const siteUser = siteUsersDataHandler.data?.find(
      (_siteUser) => _siteUser.userId === user.userId
    );
    // Update settings on old BFF
    await siteUsersDataHandler.updateSiteUser({
      ...(siteUser || {}),
      userId: user.userId,
      email: user.email,
      siteImpactAlertEnable: newSettingsValue.siteImpact,
      delayAlertEnable: newSettingsValue.delay,
      speedAlertEnable: newSettingsValue.speed,
      queueAlertEnable: newSettingsValue.queue,
      annotationsAlertEnable: newSettingsValue.annotations,
    });
  }

  return {
    authenticatedTeamEditors,
    authenticatedTeamViewers,
    pendingTeamEditors,
    pendingTeamViewers,
    authenticatedGuestEditors,
    authenticatedGuestViewers,
    pendingGuests,
    autocompleteEmails,
    siteAlertsSettings,
    inviteUsers,
    resendInvite,
    sendPasswordReset,
    removeSiteUser,
    updateSiteRole,
    updateSiteAlertSettings,
  };
}
