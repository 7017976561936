import {
  ROLE_ADMIN_USER,
  ROLE_FEATURE_USER,
  ROLE_READ_USER,
  ROLE_STORMPATH_USER,
} from '../constants/roles';

export function persistPostAuthRoles(roles: string[]) {
  localStorage.setItem('roles', roles.join(','));
  localStorage.setItem(
    'isAdmin',
    roles.indexOf(ROLE_ADMIN_USER) > -1 ? '1' : '0'
  );
  if (roles.indexOf(ROLE_STORMPATH_USER) > -1) {
    localStorage.setItem('currentRole', ROLE_STORMPATH_USER);
    localStorage.setItem('isLoggedIn', '1');
    localStorage.setItem(
      'isFeatureUser',
      roles.indexOf(ROLE_FEATURE_USER) > -1 ? '1' : '0'
    );
  } else if (roles.indexOf(ROLE_READ_USER) > -1) {
    localStorage.setItem('currentRole', ROLE_READ_USER);
    localStorage.setItem('isLoggedIn', '1');
  }
}
