import { Box, Flex, Text, VStack } from '@chakra-ui/react';

import { FormattedMessage } from 'react-intl';
import Card, { CardProps } from 'design-system/atoms/card';

export interface RoutesMapsLegendProps
  extends Pick<CardProps, 'bottom' | 'left' | 'position' | 'transform'> {}

export function RoutesPerformanceLegend(props: RoutesMapsLegendProps) {
  return (
    <Card padding={2} background="white" {...props}>
      <VStack spacing={0} textAlign="center" fontSize="xs">
        <Text as="span" fontWeight={600}>
          <FormattedMessage
            id="5vMsUB"
            defaultMessage="Journey time compared to last 4 weeks"
            description="Routes map legend title"
          />
        </Text>
        <Flex>
          <Text as="span">
            <FormattedMessage
              id="N0hDRV"
              defaultMessage="Expected"
              description="Route performance legend - expected"
            />
          </Text>
          <Box display="inline-block" whiteSpace="nowrap">
            {['blue.400', 'orange.300', 'red.400', 'red.900'].map((color) => (
              <Box
                key={color}
                marginLeft={1}
                display="inline-block"
                verticalAlign="middle"
                width={4}
                height="6px"
                borderRadius="3px"
                backgroundColor={color}
              />
            ))}
          </Box>
          <Text as="span" marginLeft={1}>
            <FormattedMessage
              id="5onLKa"
              defaultMessage="Slower"
              description="Route performance legend - slower"
            />
          </Text>
        </Flex>
      </VStack>
    </Card>
  );
}
