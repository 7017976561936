import { IntlFormatters } from 'react-intl';
import {
  UNIT_SYSTEM_TYPE_IMPERIAL,
  UNIT_SYSTEM_TYPE_METRIC,
} from '../constants/serverConstants';
import { METRIC_TO_IMPERIAL_FACTOR } from '../constants/clientConstants';
import { DistanceUnit, SiteUnitType, SpeedUnit } from '../types/units';

export function convertSpeedValueToDisplay(
  value: number,
  unitSystem: SiteUnitType
): string {
  switch (unitSystem) {
    case UNIT_SYSTEM_TYPE_IMPERIAL: {
      const adjustedValue = Math.round(value * METRIC_TO_IMPERIAL_FACTOR);
      return adjustedValue.toString();
    }
    default:
      return value.toString();
  }
}
export function convertSpeedDisplayToValue(
  display: string,
  unitSystem: SiteUnitType
): number {
  const numericValue = parseInt(display, 10);
  if (Number.isNaN(numericValue)) {
    return 0;
  }
  switch (unitSystem) {
    case UNIT_SYSTEM_TYPE_IMPERIAL: {
      const adjustedValue = Math.round(
        numericValue / METRIC_TO_IMPERIAL_FACTOR
      );
      return adjustedValue;
    }
    default:
      return numericValue;
  }
}
export function convertDistanceValueToDisplay(
  value: number,
  unitSystem: SiteUnitType
): string {
  switch (unitSystem) {
    case UNIT_SYSTEM_TYPE_IMPERIAL: {
      const adjustedValue = (value / 1000) * METRIC_TO_IMPERIAL_FACTOR;
      return adjustedValue.toFixed(2);
    }
    default:
      return value.toString();
  }
}
export function convertDistanceDisplayToValue(
  display: string,
  unitSystem: SiteUnitType
): number {
  const numericValue = parseFloat(display);
  if (Number.isNaN(numericValue)) {
    return 0;
  }
  switch (unitSystem) {
    case UNIT_SYSTEM_TYPE_IMPERIAL:
      return Math.round((numericValue / METRIC_TO_IMPERIAL_FACTOR) * 1000);
    default:
      return Math.round(numericValue);
  }
}
export function secondsValueToMinutesDisplay(value: number): string {
  return Math.round(value / 60).toString();
}
export function minutesDisplayToSecondsValue(display: string): number {
  const numericValue = parseInt(display, 10);
  if (Number.isNaN(numericValue)) {
    return 0;
  }
  return Math.round(numericValue * 60);
}

export function getIntlJsSpeedUnit(unitSystem: SiteUnitType): SpeedUnit {
  switch (unitSystem) {
    case 'IMPERIAL':
      return SpeedUnit.milePerHour;
    default:
      return SpeedUnit.kilometerPerHour;
  }
}

export function getIntlJsDistanceUnit(unitSystem: SiteUnitType): DistanceUnit {
  switch (unitSystem) {
    case 'IMPERIAL':
      return DistanceUnit.mile;
    default:
      return DistanceUnit.meter;
  }
}

export function formatSiteSpeed(
  kmphValue: number,
  unitSystem: SiteUnitType,
  formatNumber: IntlFormatters['formatNumber']
) {
  const adjustedValue = Math.round(
    unitSystem === UNIT_SYSTEM_TYPE_METRIC
      ? kmphValue
      : kmphValue * METRIC_TO_IMPERIAL_FACTOR
  );
  return formatNumber(adjustedValue, {
    style: 'unit',
    unit: getIntlJsSpeedUnit(unitSystem),
    unitDisplay: 'short',
  });
}

export function formatSiteDistance(
  metersValue: number,
  unitSystem: SiteUnitType,
  formatNumber: IntlFormatters['formatNumber']
) {
  const adjustedValue =
    unitSystem === UNIT_SYSTEM_TYPE_METRIC
      ? Math.round(metersValue)
      : (metersValue / 1000) * METRIC_TO_IMPERIAL_FACTOR;
  return formatNumber(adjustedValue, {
    style: 'unit',
    unit: getIntlJsDistanceUnit(unitSystem),
    unitDisplay: 'long',
  });
}
