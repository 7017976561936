import { IncidentType } from '@data-pipeline/lib-collection/src/types/incidents';
import { Annotation } from '../types/annotations';
import { TimePeriod } from '../types/timePeriod';
import { selectedEndDate, selectedStartDate } from './timePeriodUtils';

export type AnnotationSource = 'Manual' | 'Here' | 'TomTom';
export type AnnotationType = 'ManualAnnotation' | IncidentType;

export type AnnotatedEvent = {
  id: string;
  startDate: number;
  endDate?: number;
  title?: string;
  description?: string;
  source: AnnotationSource;
  type: AnnotationType;
  manualContent?: Annotation;
};

interface SplitAnnotations {
  earlier: AnnotatedEvent[];
  current: AnnotatedEvent[];
  later: AnnotatedEvent[];
}

export function splitAnnotationsByPeriod(
  annotations: AnnotatedEvent[],
  selectedPeriod: TimePeriod,
  timeZone: string
): SplitAnnotations {
  const selectedEndDateSeconds = selectedEndDate(selectedPeriod);
  const selectedStartDateSeconds = selectedStartDate(selectedPeriod, timeZone);
  const initialSplitAnnotations: SplitAnnotations = {
    earlier: [],
    current: [],
    later: [],
  };
  return [...annotations]
    .sort(
      (annotationA, annotationB) =>
        (annotationB.startDate || 0) - (annotationA.startDate || 0)
    )
    .reduce((acc, annotation) => {
      if (annotation.startDate === undefined) {
        return acc;
      }
      if (annotation.startDate > selectedEndDateSeconds) {
        acc.later.push(annotation);
      } else if (annotation.startDate < selectedStartDateSeconds) {
        acc.earlier.push(annotation);
      } else {
        acc.current.push(annotation);
      }
      return acc;
    }, initialSplitAnnotations);
}
