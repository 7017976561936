import { ReactNode } from 'react';
import { Box, Divider, Heading } from '@chakra-ui/react';
import { FormattedMessage } from 'react-intl';

export default function StatusGroupsLayout({
  actionHeader,
  activeList,
  pendingList,
}: {
  actionHeader?: ReactNode;
  activeList?: ReactNode;
  pendingList?: ReactNode;
}) {
  return (
    <>
      {actionHeader && <Box mb="10">{actionHeader}</Box>}
      <Box>
        {activeList}
        {pendingList && (
          <>
            <Divider my={6} variant="dashed" borderColor="gray.300" />
            <Box>
              <Heading size="sm" my={4}>
                <FormattedMessage defaultMessage="Pending" id="eKEL/g" />
              </Heading>
              {pendingList}
            </Box>
          </>
        )}
      </Box>
    </>
  );
}
