import { ReactNode } from 'react';
import ReactDOMServer from 'react-dom/server';
import { Marker, MarkerProps } from '@react-google-maps/api';
import Point = google.maps.Point;

export default function ReactSVGMarker({
  svgComponent,
  svgComponentAnchor,
  ...markerProps
}: MarkerProps & {
  svgComponentAnchor?: Point;
  svgComponent: ReactNode;
}) {
  return (
    <Marker
      {...markerProps}
      icon={{
        url: encodeSvg(svgComponent),
        anchor: svgComponentAnchor,
      }}
    />
  );
}
export function encodeSvg(reactNode) {
  return `data:image/svg+xml,${encodeURIComponent(
    ReactDOMServer.renderToStaticMarkup(reactNode)
  )}`;
}
