import useSWRImmutable from 'swr/immutable';
import { VehicleCountSummary } from '@data-pipeline/api/src/types/vehicle-count';
import { dataTrpc } from '../config/trpc';

export default function useCountSourcesData(
  insightId?: string,
  siteId?: number
): {
  data?: VehicleCountSummary[];
  isValidating: boolean;
  error: any;
} {
  const { data, isValidating, error } = useSWRImmutable(
    siteId !== undefined && insightId !== undefined
      ? {
          keyName: 'useCountSourcesData',
          siteId,
          insightId,
        }
      : null,
    async (params) => {
      const vehicleCountSummaries =
        await dataTrpc.vehicleCount.getVehicleCountSiteSummariesBySiteId.query({
          siteId: Number(params.siteId),
        });
      return vehicleCountSummaries;
    }
  );

  return {
    data,
    isValidating,
    error,
  };
}
