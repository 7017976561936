import './SiteStatus.scss';
import moment from 'moment-timezone';
import { FormattedMessage, useIntl } from 'react-intl';
import classnames from 'classnames';
import ClockIcon from '../svg/clock.svg?react';
import { SiteSummary } from '../types/site';

export enum SiteStatusMode {
  COMPACT = 'COMPACT',
  LONG = 'LONG',
}
interface SiteStatusProps {
  site: Pick<SiteSummary, 'active' | 'schedules' | 'siteTimeZone'>;
  mode?: SiteStatusMode;
}
export default function SiteStatus({
  site,
  mode = SiteStatusMode.COMPACT,
}: SiteStatusProps) {
  const { formatDate } = useIntl();
  const nextSchedule =
    site.schedules &&
    site.schedules.find((schedule) => {
      if (site.active) {
        return (schedule.endDate || 0) > Date.now();
      }
      return (schedule.startDate || 0) > Date.now();
    });
  return (
    <>
      <ClockIcon
        className={classnames({
          'v2-site-status__icon': true,
          'v2-site-status__icon--schedule': nextSchedule,
          'v2-site-status__icon--active': site.active,
        })}
      />
      {mode === SiteStatusMode.LONG && (
        <span
          className={classnames({
            'v2-site-status__description': true,
            'v2-site-status__description--scheduled': nextSchedule,
            'v2-site-status__description--inactive': !site.active,
          })}
        >
          <FormattedMessage
            defaultMessage={`{
              monitoringStatus,
              select,
              ON {Monitoring active}
              OFF {Monitoring inactive}
              ON_WITH_SCHEDULE {Monitoring active: ends ({scheduleDate})}
              OFF_WITH_SCHEDULE {Monitoring scheduled: starts ({scheduleDate})}
              other {}
            }`}
            id="SNFEjA"
            description="Site monitoring status description"
            values={{
              monitoringStatus: getStatus(site),
              scheduleDate:
                nextSchedule &&
                formatDate(
                  getSiteDisplayDate(
                    site.active
                      ? nextSchedule.endDate || 0
                      : nextSchedule.startDate || 0,
                    site.siteTimeZone!
                  ),
                  {
                    year: 'numeric',
                    month: '2-digit',
                    day: '2-digit',
                  }
                ),
            }}
          />
        </span>
      )}
    </>
  );
}
enum Status {
  ON = 'ON',
  OFF = 'OFF',
  ON_WITH_SCHEDULE = 'ON_WITH_SCHEDULE',
  OFF_WITH_SCHEDULE = 'OFF_WITH_SCHEDULE',
}
function getStatus(site: SiteStatusProps['site']): Status {
  const nextSchedule =
    site.schedules &&
    site.schedules.find((schedule) => {
      if (site.active) {
        return (schedule.endDate || 0) > Date.now();
      }
      return (schedule.startDate || 0) > Date.now();
    });
  if (site.active) {
    if (nextSchedule) {
      return Status.ON_WITH_SCHEDULE;
    }
    return Status.ON;
  }
  if (nextSchedule) {
    return Status.OFF_WITH_SCHEDULE;
  }
  return Status.OFF;
}
function getSiteDisplayDate(date: number, siteTimeZone: string) {
  return moment(date).tz(siteTimeZone).format('YYYY-MM-DD[T]HH:mm');
}
