import { FormattedMessage, useIntl } from 'react-intl';
import {
  Center,
  FormControl,
  FormLabel,
  Spinner,
  Switch,
  VStack,
} from '@chakra-ui/react';
import DateRangeSelectionButton from 'design-system/molecules/date-range-selection/date-range-selection-button';
import { useState } from 'react';
import { useFailureToast } from '../../hooks/useFailureToast';
import { useSuccessToast } from '../../hooks/useSuccessToast';
import { errorReport } from '../../utils/errors';

export default function OccupationDatesEditor({
  occupationDates,
  updateOccupationDates,
}: {
  occupationDates: { startDate?: string; endDate?: string };
  updateOccupationDates: ({
    startDate,
    endDate,
  }: {
    startDate?: string;
    endDate?: string;
  }) => Promise<void>;
}) {
  const [isEditingOccupationDates, setEditingOccupationDates] = useState(false);
  const [isUpdatingDates, setIsUpdatingDates] = useState(false);
  const isOccupationDatesSet =
    !!occupationDates.startDate && !!occupationDates.endDate;
  const { formatMessage } = useIntl();
  const successToast = useSuccessToast({
    title: formatMessage({
      defaultMessage: 'Occupation dates updated',
      id: 'Xkpug5',
    }),
  });
  const failureToast = useFailureToast({
    title: formatMessage({
      defaultMessage: 'There was an issue updating occupation dates',
      id: 'L1y+Ea',
    }),
  });
  if (isUpdatingDates) {
    return (
      <Center>
        <Spinner />
      </Center>
    );
  }
  return (
    <VStack w="full" alignItems="stretch" justifyContent="center">
      <FormControl
        display="flex"
        alignItems="center"
        justifyContent="space-between"
        mb={2}
      >
        <FormLabel mb={0} ml={0} fontSize="sm" fontWeight={400}>
          <FormattedMessage
            defaultMessage="Are occupation dates planned?"
            id="qLZ1ko"
            description="Ocupation dates editor: Switch label"
          />
        </FormLabel>
        <Switch
          size="sm"
          mb={0}
          colorScheme="green"
          data-testid="site-editor-occupation-dates-switch"
          onChange={async () => {
            if (isOccupationDatesSet) {
              setIsUpdatingDates(true);
              try {
                await updateOccupationDates({
                  startDate: undefined,
                  endDate: undefined,
                });
                successToast();
              } catch (e) {
                errorReport.handled(e, { action: 'updateOccupationDates' });
                failureToast();
              } finally {
                setIsUpdatingDates(false);
              }
            } else {
              setEditingOccupationDates(true);
            }
          }}
          isChecked={isOccupationDatesSet}
        />
      </FormControl>
      {(isOccupationDatesSet || isEditingOccupationDates) && (
        <DateRangeSelectionButton
          title="Occupation start and end dates"
          startDate={occupationDates.startDate || ''}
          endDate={occupationDates.endDate || ''}
          isOpen={isEditingOccupationDates}
          onOpen={() => setEditingOccupationDates(true)}
          hideLabels
          infoText="What are the planned start and end dates for this work?"
          onConfirm={async ({ startDate, endDate }) => {
            setEditingOccupationDates(false);
            setIsUpdatingDates(true);
            try {
              await updateOccupationDates({ startDate, endDate });
              successToast();
            } catch (e) {
              errorReport.handled(e, { action: 'updateOccupationDates' });
              failureToast();
            } finally {
              setIsUpdatingDates(false);
            }
          }}
          onCancel={() => {
            setEditingOccupationDates(false);
          }}
        />
      )}
    </VStack>
  );
}
