import moment from 'moment-timezone';
import { FormatDateOptions } from 'react-intl';

export function dateFormatter(epoch: number, timeZone: string, format: string) {
  return moment.unix(epoch).tz(timeZone).format(format);
}

// Tue, 09 Oct 2022
export const DATE_PRESET_SHORT_COMPLETE: FormatDateOptions = {
  weekday: 'short',
  day: 'numeric',
  month: 'short',
  year: 'numeric',
};

// Tue, 09 Oct 22
export const DATE_PRESET_NARROW_COMPLETE: FormatDateOptions = {
  weekday: 'short',
  day: 'numeric',
  month: 'short',
  year: '2-digit',
};

// Sun, 09/10/2022
export const DATE_PRESET_NUMERIC_COMPLETE: FormatDateOptions = {
  weekday: 'short',
  day: '2-digit',
  month: '2-digit',
  year: 'numeric',
};

// 9 Oct 22
export const DATE_PRESET_SHORT_D_M_Y: FormatDateOptions = {
  day: 'numeric',
  month: 'short',
  year: '2-digit',
};

// Wednesday, 27 Mar
export const DATE_PRESET_RECENT_LONG: FormatDateOptions = {
  weekday: 'long',
  day: 'numeric',
  month: 'short',
};

// 27 Mar
export const DATE_PRESET_RECENT_MONTH: FormatDateOptions = {
  day: 'numeric',
  month: 'short',
};

// 08:00 pm
export const TIME_PRESET_COMPLETE_HOUR12: FormatDateOptions = {
  hour: 'numeric',
  minute: '2-digit',
  hour12: true,
};
