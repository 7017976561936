import rg4js from 'raygun4js';
import { getEnvironment } from '../config/envConfig';

type CustomData = Record<any, any> | any[];
enum Severity {
  CRITICAL = 'critical',
  HANDLED = 'handled',
  UNKNOWN = 'unknown',
  LOG = 'log',
}
// critical: errors that require immediate attention
// handled: errors that won't crash the application but could indicate a problem
// log: for debugging purposes or providing additional context on external errors (such as server calls)
// unknown: errors that don't fit, or could fit multiple other categories

export const errorReport = {
  critical: reportErrorWithSeverityTag(Severity.CRITICAL),
  handled: reportErrorWithSeverityTag(Severity.HANDLED),
  log: reportErrorWithSeverityTag(Severity.LOG),
  unknown: reportErrorWithSeverityTag(Severity.UNKNOWN),
};

function reportErrorWithSeverityTag(tag: Severity) {
  return (e: any, customData?: CustomData) => {
    rg4js('send', {
      error: e,
      customData,
      tags: [tag],
    });
    if (getEnvironment() === 'local') {
      // eslint-disable-next-line no-console
      console.log('Raygun error', e);
    }
  };
}
