import { envConfig } from '../../config/envConfig';
import { fetchWithError } from '../../utils/fetch';
import { INVALID, MFACode, OnboardStatus } from './constants';

const CONFIRM_MFA_CODE_URI = `${envConfig.API_URL}/api/v1/onboard/activateMfa`;

export default async function confirmMFACode(code: MFACode, token?: string) {
  const headers = new Headers();
  if (token) {
    headers.append('OnboardAuthorization', `Bearer ${token}`);
  }
  const response = await fetchWithError<OnboardStatus>(CONFIRM_MFA_CODE_URI, {
    method: 'POST',
    body: JSON.stringify({ code }),
    headers,
  });

  if (!response) {
    return INVALID;
  }

  return response;
}
