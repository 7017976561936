import { components } from '../types/schema';
import { envConfig } from '../config/envConfig';
import { standardFetch } from '../utils/fetch';

export type OrgInviteUsersData = components['schemas']['OrgInviteUsersRequest'];

export function inviteOrgUsers(insightId: string, data: OrgInviteUsersData) {
  return standardFetch(
    `${envConfig.API_URL}/api/v1/orgs/${insightId}/users/invite`,
    {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(data),
    }
  );
}

export function resendOrgInvite(insightId: string, data: OrgInviteUsersData) {
  return standardFetch(
    `${envConfig.API_URL}/api/v1/insights/${insightId}/resendInvite`,
    {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(data),
    }
  );
}
