import { Avatar, Box } from '@chakra-ui/react';
import { ReactNode } from 'react';
import { useIntl } from 'react-intl';
import Label from 'design-system/molecules/label';

export interface UserBadgeProps {
  name?: string;
  info?: ReactNode;
  email?: string;
  isCurrentUser?: boolean;
  isPendingUser?: boolean;
}

export default function UserBadge({
  name,
  email,
  isCurrentUser,
  isPendingUser,
  info,
}: UserBadgeProps) {
  const { formatMessage } = useIntl();
  const userDisplayName = getUserDisplayName(
    formatMessage,
    isCurrentUser,
    isPendingUser,
    name
  );
  return (
    <Label
      avatar={
        <Avatar
          name={isPendingUser ? undefined : name}
          bgColor={isPendingUser ? 'gray.300' : undefined}
        />
      }
      primaryText={userDisplayName}
      secondaryText={
        !isCurrentUser && email ? (
          <Box
            maxWidth="100%"
            whiteSpace="nowrap"
            overflow="hidden"
            textOverflow="ellipsis"
          >
            {email}
          </Box>
        ) : undefined
      }
      footerText={info}
    />
  );
}

function getUserDisplayName(
  formatMessage,
  isCurrentUser,
  isPendingUser,
  name = ''
) {
  if (isCurrentUser) {
    return formatMessage({
      id: 'kJ5W29',
      defaultMessage: 'You',
    });
  }
  if (isPendingUser) {
    return formatMessage({
      id: 'eKEL/g',
      defaultMessage: 'Pending',
    });
  }
  return name;
}
