import { useState } from 'react';
import { unwrapResponse } from '../utils/fetch';

const RequestStatus = {
  IDLE: 'IDLE',
  ACTIVE: 'ACTIVE',
  ERROR: 'ERROR',
};

type UnknownRequest = (...args: any[]) => Promise<any>;
type RequestWithStatus = <RequestType extends UnknownRequest>(
  request: RequestType
) => RequestType;

export default function useRequestStatus(): [
  isActive: boolean,
  hasError: boolean,
  requestWithStatus: RequestWithStatus,
  clearError: () => void,
] {
  const [reqStatus, setReqStatus] = useState(RequestStatus.IDLE);
  const isActive = reqStatus === RequestStatus.ACTIVE;
  const hasError = reqStatus === RequestStatus.ERROR;

  const requestWithStatus = <RequestType extends UnknownRequest>(
    request: RequestType
  ) =>
    ((...args: unknown[]) => {
      if (reqStatus === RequestStatus.ACTIVE) {
        return Promise.reject(
          new Error("Can't initiate fetch request. Fetch already in progress.")
        );
      }
      setReqStatus(RequestStatus.ACTIVE);
      return request(...args)
        .then(unwrapResponse)
        .then((response) => {
          setReqStatus(RequestStatus.IDLE);
          return response;
        })
        .catch((error) => {
          setReqStatus(RequestStatus.ERROR);
          throw error;
        });
    }) as RequestType;

  const clearError = () => {
    if (reqStatus === RequestStatus.ACTIVE) {
      throw new Error(
        "Can't clear request error. Request currently in progress."
      );
    }
    setReqStatus(RequestStatus.IDLE);
  };

  return [isActive, hasError, requestWithStatus, clearError];
}
