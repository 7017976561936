import { Box, Flex, Text } from '@chakra-ui/react';
import { FormattedMessage } from 'react-intl';
import EmptyStateNotesIcon from '../../svg/empty_state_notes.svg?react';

export default function NoAnnotations() {
  return (
    <Flex alignItems="center">
      <Box mr={4}>
        <EmptyStateNotesIcon />
      </Box>
      <Flex direction="column">
        <Text textStyle="h3">
          <FormattedMessage
            defaultMessage="No notes"
            id="1xhiqy"
            description="No notes header"
          />
        </Text>
        <Text textStyle="sm">
          <FormattedMessage
            defaultMessage="There aren't any notes recorded in this date range"
            id="8AuR0G"
            description="No notes text"
          />
        </Text>
      </Flex>
    </Flex>
  );
}
