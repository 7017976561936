import LatLngLiteral = google.maps.LatLngLiteral;
import GeocoderResult = google.maps.GeocoderResult;

const geocoder = new google.maps.Geocoder();

export async function getPlacesForLocation(
  location: LatLngLiteral
): Promise<GeocoderResult[]> {
  const geocoderResponse = await geocoder.geocode({ location });
  return geocoderResponse.results;
}
