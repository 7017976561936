import { Text } from '@chakra-ui/react';
import { getYear } from 'date-fns';
import { formatInTimeZone } from 'date-fns-tz';
import { JSX } from 'react';

export interface DateRangeProps {
  startDate: Date;
  endDate: Date;
  timezone: string;
}

export default function DateRange({
  startDate,
  endDate,
  timezone,
}: DateRangeProps): JSX.Element {
  return (
    <Text color="gray.700" fontSize="md">
      {formatDateRange(startDate, endDate, timezone)}
    </Text>
  );
}

export function formatDateRange(
  startDate: Date,
  endDate: Date,
  timezone: string
): string {
  if (datesInSameYear(startDate, endDate)) {
    return `${formatInTimeZone(
      startDate,
      timezone,
      'd MMM'
    )} - ${formatInTimeZone(endDate, timezone, 'd MMM y')}`;
  }
  return `${formatInTimeZone(
    startDate,
    timezone,
    'd MMM y'
  )} - ${formatInTimeZone(endDate, timezone, 'd MMM y')}`;
}

function datesInSameYear(date1: Date, date2: Date): boolean {
  return getYear(date1) === getYear(date2);
}
