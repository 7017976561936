import useInsightData from '../../data/useInsightData';
import useSitesData from '../../data/useSitesData';
import useParamInsightId from '../../hooks/useParamInsightId';
import useSiteView from '../../hooks/useSiteView';
import {
  DataHandlerFeedback,
  hasDataHandlerFeedBack,
} from '../../utils/dataHandler';
import SiteSelectorMenu from './SiteSelectorMenu';

export default function SiteSelector() {
  const insightId = useParamInsightId();
  const insightDataHandler = useInsightData(insightId);
  const sitesDataHandler = useSitesData(insightId);
  const siteView = useSiteView();

  if (hasDataHandlerFeedBack([sitesDataHandler, insightDataHandler])) {
    return (
      <DataHandlerFeedback
        dataHandlersParam={[sitesDataHandler, insightDataHandler]}
      />
    );
  }
  if (!sitesDataHandler.data || !siteView) {
    return null;
  }
  return (
    <SiteSelectorMenu
      title={insightDataHandler.data?.name || ''}
      siteId={siteView.state.siteId.toString()}
      setSiteView={siteView.setState}
      sites={sitesDataHandler.data}
    />
  );
}
