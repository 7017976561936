import { Heading } from '@chakra-ui/react';
import { FormattedMessage, useIntl } from 'react-intl';
import UserContextMenu from '../../local_design_system/organisms/SiteAccessSettings/UserContextMenu';
import RoleMenu from '../../local_design_system/organisms/SiteAccessSettings/RoleMenu';
import UserActionsMenu from '../../local_design_system/organisms/SiteAccessSettings/UserActionsMenu';
import UsersList from '../../local_design_system/organisms/SiteAccessSettings/UsersList';
import Alerts from '../../local_design_system/organisms/SiteAccessSettings/Alerts';
import SiteAlertsMenu from '../../local_design_system/organisms/SiteAccessSettings/SiteAlertsMenu';
import StatusGroupsLayout from '../../local_design_system/organisms/SiteAccessSettings/StatusGroupsLayout';
import InviteUsersForm from '../../local_design_system/organisms/SiteAccessSettings/InviteUsersForm';
import Permissions from '../../local_design_system/organisms/SiteAccessSettings/Permissions';
import useCurrentUserData from '../../data/useCurrentUserData';
import useSiteAccessSettings from '../../data/useSiteAccessSettings';
import { canUserEditSite, getUserAlertsSettings } from './utils';
import useSiteData from '../../data/useSiteData';
import useFeatureSwitch, {
  FeatureSwitchState,
} from '../../hooks/useFeatureSwitch';
import { FEATURE_SITE_IMPACT_KPI_ALERTS } from '../../constants/features';

export default function SiteGuestsSettings({
  siteId,
  orgId,
}: {
  orgId: number;
  siteId: number;
}) {
  const { formatMessage } = useIntl();
  const { data: currentUserData } = useCurrentUserData();
  const { siteHasQueue } = useSiteData({
    siteId: siteId.toString(),
    orgId: orgId.toString(),
  });
  const enableSiteImpactAlerts =
    useFeatureSwitch(FEATURE_SITE_IMPACT_KPI_ALERTS) === FeatureSwitchState.ON;
  const {
    authenticatedGuestEditors,
    authenticatedGuestViewers,
    pendingGuests,
    siteAlertsSettings,
    autocompleteEmails,
    updateSiteRole,
    updateSiteAlertSettings,
    sendPasswordReset,
    removeSiteUser,
    resendInvite,
    inviteUsers,
  } = useSiteAccessSettings(orgId, siteId);
  const updateRole = ({ user, newRoleValue }) =>
    updateSiteRole(user, newRoleValue);
  const updateAlerts = ({ user, newAlertsSettings }) =>
    updateSiteAlertSettings(user, newAlertsSettings);
  const getUserMenu = (user) => (
    <UserContextMenu
      user={user.name}
      triggerButtonLabel={
        <Permissions
          user={{
            canEdit: canUserEditSite(user, siteId),
          }}
          rawText
        />
      }
    >
      <RoleMenu
        header={formatMessage({
          id: 'CY844A',
          defaultMessage: 'Role for this site',
          description: 'Role menu title for site role of an org member',
        })}
        user={user}
        role={canUserEditSite(user, siteId) ? 'Editor' : 'Viewer'}
        updateRole={updateRole}
      />
      <SiteAlertsMenu
        user={user}
        alerts={getUserAlertsSettings(user, siteAlertsSettings)}
        updateAlerts={updateAlerts}
        showQueue={siteHasQueue}
        showSiteImpact={enableSiteImpactAlerts}
      />
      <UserActionsMenu
        user={user}
        sendPasswordReset={user.pending ? undefined : sendPasswordReset}
        resendInvite={user.pending ? resendInvite : undefined}
        removeSiteUser={
          user.userId === currentUserData?.userId ? undefined : removeSiteUser
        }
      />
    </UserContextMenu>
  );

  const getUserAlerts = (user) => (
    <Alerts alerts={getUserAlertsSettings(user, siteAlertsSettings)} />
  );

  return (
    <StatusGroupsLayout
      actionHeader={
        <InviteUsersForm
          siteId={siteId}
          onSubmit={inviteUsers}
          autocompleteEmails={autocompleteEmails}
        />
      }
      activeList={
        <>
          <UsersList
            users={authenticatedGuestEditors}
            currentUserId={currentUserData?.userId}
            getUserAlerts={getUserAlerts}
            getUserContextMenu={getUserMenu}
          />
          {authenticatedGuestViewers.length > 0 && (
            <>
              <Heading my={6} size="sm" color="gray.500">
                <FormattedMessage
                  id="SBOr5J"
                  defaultMessage="Guest viewers"
                  description="Title for site guests list with view only access"
                />
              </Heading>
              <UsersList
                users={authenticatedGuestViewers}
                currentUserId={currentUserData?.userId}
                getUserAlerts={getUserAlerts}
                getUserContextMenu={getUserMenu}
              />
            </>
          )}
        </>
      }
      pendingList={
        pendingGuests.length > 0 && (
          <UsersList users={pendingGuests} getUserContextMenu={getUserMenu} />
        )
      }
    />
  );
}
