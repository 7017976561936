import { useRef } from 'react';
import { Polygon } from '@react-google-maps/api';
import { WorkArea } from '@webapp/bff/src/types/work-area';
import {
  googleDynamicCoordinatesToPointArray,
  pointArrayToWkt,
} from '@lib/gis';
import { LatLng } from '@data-pipeline/lib-collection';
import { parseWkt } from '../../utils/routes';
import {
  WorkAreasEditorManager,
  isEditWorkArea,
} from './useWorkAreasEditorManager';
import { PolygonCreatorMapLayer } from '../PolygonCreator';

export default function WorkAreasEditorMapLayer({
  workAreas,
  workAreasEditorManager,
}: {
  workAreas: WorkArea[];
  workAreasEditorManager: WorkAreasEditorManager;
}) {
  const {
    polygonCreatorManager,
    onCreatingComplete,
    areaEditing,
    onWktChange,
  } = workAreasEditorManager;

  const polygonCreateHandler = (polygonPoints: LatLng[]) => {
    const wkt = pointArrayToWkt(polygonPoints);
    onCreatingComplete(wkt);
  };

  const polygonLayerRef = useRef<google.maps.Polygon>();
  const onPolygonLayerEdit = () => {
    if (polygonLayerRef.current) {
      onWktChange(
        googleDynamicCoordinatesToWktPolygon(
          polygonLayerRef.current.getPath().getArray()
        )
      );
    }
  };

  return (
    <>
      <PolygonCreatorMapLayer
        polygonCreatorManager={polygonCreatorManager}
        onCreate={polygonCreateHandler}
      />
      {workAreas.map((area) =>
        areaEditing &&
        isEditWorkArea(areaEditing) &&
        area.id === areaEditing.id ? null : (
          <Polygon
            key={area.id}
            options={{
              strokeColor: area.fillColor,
              fillColor: area.fillColor,
              fillOpacity: area.fillTransparency,
              strokeWeight: 3,
              editable: false,
              clickable: false,
              zIndex: 2,
            }}
            path={parseWkt(area.wkt)}
          />
        )
      )}
      {areaEditing && (
        <Polygon
          onLoad={(polygonLayer) => {
            polygonLayerRef.current = polygonLayer;
          }}
          onMouseUp={onPolygonLayerEdit}
          onDragEnd={onPolygonLayerEdit}
          options={{
            strokeColor: areaEditing.fillColor,
            fillColor: areaEditing.fillColor,
            fillOpacity: areaEditing.fillTransparency,
            strokeWeight: 3,
            editable: true,
            draggable: true,
          }}
          path={parseWkt(areaEditing.wkt)}
        />
      )}
    </>
  );
}

function googleDynamicCoordinatesToWktPolygon(
  coordinates: google.maps.LatLng[]
) {
  const staticCoordinates = googleDynamicCoordinatesToPointArray(coordinates);
  return pointArrayToWkt(staticCoordinates);
}
