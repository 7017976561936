import './EditableSite.scss';
import { useState } from 'react';
import classnames from 'classnames';
import { Box, Flex, Grid, GridItem } from '@chakra-ui/react';
import { FormattedMessage } from 'react-intl';
import useSiteData from '../../data/useSiteData';
import { BlankSchedule, Schedule } from '../../types/schedule';
import { SiteSummary } from '../../types/site';
import {
  DataHandlerFeedback,
  hasDataHandlerFeedBack,
} from '../../utils/dataHandler';
import { createEnterKeyHandler } from '../../utils/event';
import EditableSiteProperties from './EditableSiteProperties';
import EditableSiteSchedules from './EditableSiteSchedules';
import EditableSiteUsers from './EditableSiteUsers';
import SiteStatus from '../SiteStatus';
import { useSitePermissions } from '../../hooks/useSitePermissions';

export interface EditableSiteProps {
  insightId: string;
  site: SiteSummary;
  deleteSite: (...args: any[]) => Promise<void | Response>;
  updateSiteProps: (...args: any[]) => Promise<void | Response>;
  updateSiteKpis: (...args: any[]) => Promise<void | Response>;
  saveSiteSchedule: (
    siteId: number,
    scheduleProps: Schedule | BlankSchedule
  ) => Promise<unknown>;
  deleteSiteSchedule: (siteId: number, scheduleId: number) => Promise<unknown>;
  startSiteMonitoring: (siteId: number) => Promise<unknown>;
  stopSiteMonitoring: (siteId: number) => Promise<unknown>;
  defaultExpanded?: boolean;
}

function SiteEditPanel({
  siteId,
  insightId,
  deleteSite,
  updateSiteProps,
  updateSiteKpis,
  saveSiteSchedule,
  deleteSiteSchedule,
  startSiteMonitoring,
  stopSiteMonitoring,
  canEditSite,
}: {
  insightId: string;
  siteId: number;
  canEditSite: boolean;
  deleteSite: (...args: any[]) => Promise<void | Response>;
  updateSiteProps: (...args: any[]) => Promise<void | Response>;
  updateSiteKpis: (...args: any[]) => Promise<void | Response>;
  saveSiteSchedule: (
    siteId: number,
    scheduleProps: Schedule | BlankSchedule
  ) => Promise<unknown>;
  deleteSiteSchedule: (siteId: number, scheduleId: number) => Promise<unknown>;
  startSiteMonitoring: (siteId: number) => Promise<unknown>;
  stopSiteMonitoring: (siteId: number) => Promise<unknown>;
}) {
  const currentSiteDataHandler = useSiteData({
    orgId: insightId,
    siteId: siteId.toString(),
  });
  const {
    hasCurrentViewSiteEditPermission,
    hasCurrentViewSiteMonitorPermission,
  } = useSitePermissions({
    insightId,
    siteId: siteId.toString(),
  });
  if (hasDataHandlerFeedBack([currentSiteDataHandler])) {
    return <DataHandlerFeedback dataHandlersParam={[currentSiteDataHandler]} />;
  }
  const site = currentSiteDataHandler.data!;
  const isUserReadOnly = !hasCurrentViewSiteEditPermission;

  return (
    <div className="v2-editable-site__body">
      <EditableSiteProperties
        site={site}
        canEditSite={canEditSite}
        deleteSite={deleteSite}
        updateSiteProps={updateSiteProps}
        updateSiteKpis={updateSiteKpis}
        siteHasQueue={currentSiteDataHandler.siteHasQueue}
        isUserReadOnly={isUserReadOnly}
      />
      <EditableSiteSchedules
        siteId={site.siteId}
        isSiteActive={site.active}
        siteTimeZone={site.siteTimeZone}
        siteSchedules={site.schedules}
        saveSiteSchedule={saveSiteSchedule}
        deleteSiteSchedule={deleteSiteSchedule}
        startSiteMonitoring={startSiteMonitoring}
        stopSiteMonitoring={stopSiteMonitoring}
        hasSiteMonitorPermission={hasCurrentViewSiteMonitorPermission}
      />
      {!isUserReadOnly && (
        <EditableSiteUsers
          insightId={Number(insightId)}
          siteId={siteId}
          siteHasQueue={currentSiteDataHandler.siteHasQueue}
        />
      )}
    </div>
  );
}
export default function EditableSite({
  insightId,
  deleteSite,
  updateSiteProps,
  updateSiteKpis,
  saveSiteSchedule,
  deleteSiteSchedule,
  startSiteMonitoring,
  stopSiteMonitoring,
  defaultExpanded = false,
  site,
}: EditableSiteProps) {
  const [isPanelOpen, setPanelOpen] = useState(defaultExpanded);
  const { checkSitePermission } = useSitePermissions({
    insightId,
    siteId: site.siteId.toString(),
  });
  return (
    <div key={site.siteId} className="v2-editable-site">
      <SitePanelHeader
        site={site}
        isPanelOpen={isPanelOpen}
        setPanelOpen={setPanelOpen}
      />
      {isPanelOpen && (
        <SiteEditPanel
          canEditSite={checkSitePermission('CanEditSite')}
          siteId={site.siteId}
          insightId={insightId}
          deleteSite={deleteSite}
          updateSiteKpis={updateSiteKpis}
          updateSiteProps={updateSiteProps}
          saveSiteSchedule={saveSiteSchedule}
          deleteSiteSchedule={deleteSiteSchedule}
          startSiteMonitoring={startSiteMonitoring}
          stopSiteMonitoring={stopSiteMonitoring}
        />
      )}
    </div>
  );
}

interface SitePanelHeaderProps {
  site: SiteSummary;
  isPanelOpen: boolean;
  setPanelOpen: (isPanelOpen: boolean) => void;
}

function SitePanelHeader({
  site,
  isPanelOpen,
  setPanelOpen,
}: SitePanelHeaderProps) {
  const headerClickHandler = () => setPanelOpen(!isPanelOpen);
  return (
    <div
      role="button"
      aria-label="Expand site panel"
      className={classnames({
        'v2-editable-site__header': true,
        'v2-editable-site__header--expanded': isPanelOpen,
      })}
      onClick={headerClickHandler}
      onKeyDown={createEnterKeyHandler(headerClickHandler)}
      tabIndex={0}
    >
      <Flex alignItems="center">
        <Box flex="0 0 40px">
          <div className="v2-editable-site__header-status">
            <SiteStatus site={site} />
          </div>
        </Box>
        <Box flex={1}>
          <Grid
            alignItems="center"
            gridTemplateColumns="repeat(12, 1fr)"
            gap={6}
          >
            <GridItem colSpan={{ base: 12, md: 7 }}>
              <h2 className="v2-editable-site__header-title">
                {site.siteName}
              </h2>
              <h3 className="v2-editable-site__header-subtitle">
                {site.siteAddress}
              </h3>
            </GridItem>
            <GridItem colSpan={{ base: 12, md: 5 }}>
              <Flex alignItems="center">
                <Box flex={1} p={2}>
                  <FormattedMessage
                    defaultMessage="Monitoring Status: {isActive, select, true {<strong>on</strong>} other {<strong>off</strong>}}"
                    id="cetdmX"
                    description="Monitoring status from manage sites header"
                    values={{ isActive: site.active }}
                  />
                </Box>
              </Flex>
            </GridItem>
          </Grid>
        </Box>
      </Flex>
    </div>
  );
}
