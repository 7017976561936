import { useImperativeHandle } from 'react';
import type { MutableRefObject } from 'react';
import {
  Box,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
  VStack,
} from '@chakra-ui/react';
import { FormattedMessage, useIntl } from 'react-intl';
import type { SubmitErrorHandler, SubmitHandler } from 'react-hook-form';
import { useForm } from 'react-hook-form';
import { BaselineConfig } from '@webapp/bff/src/types/baseline';
import AlertBox from '../AlertBox';

interface BaselineFormFields {
  name: BaselineConfig['name'];
  description: BaselineConfig['description'];
  startDate: BaselineConfig['config']['startDate'];
  endDate: BaselineConfig['config']['endDate'];
}

export default function BaselineForm({
  defaultValues,
  submitActionRef,
  onSubmit,
  onValidationError,
  isSubmitting,
  isDisabled,
}: {
  defaultValues?: BaselineFormFields;
  submitActionRef: MutableRefObject<undefined | (() => Promise<void>)>;
  isSubmitting: boolean;
  onSubmit: SubmitHandler<BaselineFormFields>;
  onValidationError?: SubmitErrorHandler<BaselineFormFields>;
  isDisabled?: boolean;
}) {
  const { formatMessage } = useIntl();
  const {
    register,
    watch,
    handleSubmit,
    formState: { errors },
  } = useForm<BaselineFormFields>({
    defaultValues,
    mode: 'onBlur',
  });
  const startDateValue = watch('startDate');
  const endDateValue = watch('endDate');
  useImperativeHandle(submitActionRef, () =>
    handleSubmit(onSubmit, onValidationError)
  );

  const requiredMessage = formatMessage({
    defaultMessage: 'This field is required',
    id: 'TKmub+',
  });

  return (
    <VStack width="full" maxWidth="400px" alignItems="stretch">
      <Box>
        <FormControl isInvalid={!!errors.name}>
          <FormLabel fontSize="xs" fontWeight="normal">
            <FormattedMessage defaultMessage="Name of baseline" id="VIrAKI" />
          </FormLabel>
          <Input
            size="sm"
            placeholder={formatMessage({
              defaultMessage: 'Give your baseline a name',
              id: '6iNS2D',
            })}
            isDisabled={isSubmitting || isDisabled}
            maxLength={36}
            {...register('name', { required: true })}
          />
          {errors.name && (
            <FormErrorMessage>{requiredMessage}</FormErrorMessage>
          )}
        </FormControl>
      </Box>
      <Box>
        <FormControl>
          <FormLabel fontSize="xs" fontWeight="normal">
            <FormattedMessage defaultMessage="Description" id="Q8Qw5B" />
          </FormLabel>
          <Input
            size="sm"
            placeholder={formatMessage({
              defaultMessage: 'Add description',
              id: 'JB4Jq1',
            })}
            isDisabled={isSubmitting || isDisabled}
            {...register('description')}
          />
        </FormControl>
      </Box>
      <VStack alignItems="stretch">
        <Flex direction="row" gap={4}>
          <Box flex="1">
            <FormControl isInvalid={!!errors.startDate}>
              <FormLabel fontSize="xs" fontWeight="normal">
                <FormattedMessage defaultMessage="Start date" id="n5QvJy" />
              </FormLabel>
              <Input
                size="sm"
                type="date"
                isDisabled={isSubmitting || isDisabled}
                {...register('startDate', { required: true })}
              />
              {errors.startDate && (
                <FormErrorMessage>{requiredMessage}</FormErrorMessage>
              )}
            </FormControl>
          </Box>
          <Box flex="1">
            <FormControl isInvalid={!!errors.endDate}>
              <FormLabel fontSize="xs" fontWeight="normal">
                <FormattedMessage defaultMessage="End date" id="Humfno" />
              </FormLabel>
              <Input
                size="sm"
                type="date"
                isDisabled={isSubmitting || isDisabled}
                {...register('endDate', {
                  validate: (value) => {
                    if (!value) {
                      return requiredMessage;
                    }
                    if (
                      startDateValue &&
                      new Date(value) <= new Date(startDateValue)
                    ) {
                      return formatMessage({
                        defaultMessage: 'End date must be after start date',
                        id: 'OkC7HF',
                      });
                    }
                    return (
                      !startDateValue ||
                      new Date(value) > new Date(startDateValue)
                    );
                  },
                })}
              />
              {errors.endDate?.message && (
                <FormErrorMessage>{errors.endDate?.message}</FormErrorMessage>
              )}
            </FormControl>
          </Box>
        </Flex>
        {areDatesInFuture(startDateValue, endDateValue) && (
          <Box>
            <AlertBox
              title={formatMessage({
                defaultMessage: 'You have included future dates',
                id: 'DjOO1e',
              })}
              message={formatMessage({
                defaultMessage:
                  "We'll inform you when your baseline is ready to be compared against.",
                id: 'tbLM/b',
              })}
            />
          </Box>
        )}
        {areDatesLessThanFourWeeks(startDateValue, endDateValue) && (
          <Box>
            <AlertBox
              title={formatMessage({
                defaultMessage: 'Fewer than 4 weeks selected',
                id: 'gkM9cz',
              })}
              message={formatMessage({
                defaultMessage:
                  'Mooven recommends including 4 weeks in your baseline in order to increase accuracy.',
                id: 'RKx6xO',
              })}
            />
          </Box>
        )}
      </VStack>
    </VStack>
  );
}
function areDatesInFuture(startDate: string, endDate: string) {
  const areDatesValid =
    isStringValidDateFormat(startDate) && isStringValidDateFormat(endDate);
  return (
    areDatesValid &&
    (new Date(startDate) > new Date() || new Date(endDate) > new Date())
  );
}
const MS_FOUR_WEEKS = 2419200000;
function areDatesLessThanFourWeeks(startDate: string, endDate: string) {
  const areDatesValid =
    isStringValidDateFormat(startDate) && isStringValidDateFormat(endDate);
  return (
    areDatesValid &&
    new Date(endDate).getTime() - new Date(startDate).getTime() < MS_FOUR_WEEKS
  );
}
function isStringValidDateFormat(date: string | undefined) {
  return !!date?.match(/^\d{4}-\d{2}-\d{2}$/);
}
