import { SplitTreatments } from '@splitsoftware/splitio-react';
import * as features from '../constants/features';
import useCurrentUserData from '../data/useCurrentUserData';

type Feature = (typeof features)[keyof typeof features];
type Treatment = 'on' | 'off';

interface Props {
  children?: JSX.Element | null;
  fallbackElement?: JSX.Element | null;
  feature: Feature;
  treatment: Treatment;
  loadingElement?: JSX.Element | null;
}

export default function FeatureFlag({
  children = null,
  fallbackElement = null,
  feature,
  loadingElement = null,
  treatment,
}: Props) {
  const currentUser = useCurrentUserData();
  if (!currentUser.data) {
    return loadingElement;
  }

  const attributes = {
    userId: currentUser.data.email,
  };

  return (
    <SplitTreatments attributes={attributes} names={[feature]}>
      {({ treatments, isReady }) => {
        if (!isReady || !treatments) {
          return loadingElement;
        }

        if (treatments[feature]?.treatment !== treatment) {
          return fallbackElement;
        }

        return children;
      }}
    </SplitTreatments>
  );
}
