import {
  convertTimeToMilliSeconds,
  drawOccupationChart,
  removeOccupationChart,
} from 'design-system/utilities/occupation';
import SplashMessage from 'design-system/molecules/splash-message';

import ReactDOMServer from 'react-dom/server';
import { useIntl } from 'react-intl';
import {
  AlignValue,
  Axis,
  AxisPlotBandsOptions,
  AxisTitleAlignValue,
  SeriesLineOptions,
  TooltipFormatterContextObject,
} from 'highcharts';
import Chart from 'design-system/atoms/chart';
import { isInteger } from 'lodash';
import { PlanRequestParams } from '../../data/usePlanningTimeData';
import {
  formatKpiLineToHighcharts,
  shiftDataDayNight,
} from '../../utils/highcharts';
import { MS_24_HOURS, MS_ONE_HOUR } from '../../constants/time';
import { PlanChartConfig } from '../../utils/planCharts';
import Tooltip from '../InsightChart/Tooltip';
import {
  KpiBreachType,
  PlanConstraints,
  PredictionMode,
} from '../../types/plan';
import { DATE_PRESET_SHORT_D_M_Y } from '../../utils/dateUtils';
import { useAnalytics } from '../../hooks/analytics/useAnalytics';
import TapeRecorderSvg from '../../svg/tape-recorder.svg?react';

const COLOR_KPI_BREACH = '#ff00001a';

interface DataItem {
  date: number;
  value: number;
}

export interface PlanChartProps {
  planData: {
    name: string;
    label: string;
    items: DataItem[];
  }[];
  chartConfig: PlanChartConfig;
  title: string;
  planDataParams: PlanRequestParams;
  constraints: PlanConstraints;
  hiddenRoutes: number[];
  routesColors: Record<string, string>;
  daysOfWeek?: string[];
  midnightLabel?: string;
  yAxisMax: number | undefined;
}

export default function PlanChartV2({
  planData,
  chartConfig,
  title,
  planDataParams,
  constraints,
  hiddenRoutes,
  routesColors,
  daysOfWeek,
  midnightLabel,
  yAxisMax,
}: PlanChartProps) {
  const { track } = useAnalytics();
  const trackChartEvents = (eventName, eventProps = {}) => {
    track(eventName, {
      ...eventProps,
      referrer: 'Plan Chart',
    });
  };
  const { formatTime, formatDate, formatMessage } = useIntl();

  const { formattedChartSeries, isDataEmpty } = planData.reduce(
    (acc, routeData) => {
      const isRouteVisible = checkRouteIsVisible(routeData, hiddenRoutes);
      if (!isRouteVisible) {
        return acc;
      }
      const { formattedRouteData, isRouteDataEmpty } = routeData.items.reduce(
        (accRoute, item) => ({
          formattedRouteData: [
            ...accRoute.formattedRouteData!,
            [item.date * 1000, item.value],
          ],
          isRouteDataEmpty: accRoute.isRouteDataEmpty && item.value === null,
        }),
        {
          formattedRouteData: [] as SeriesLineOptions['data'],
          isRouteDataEmpty: true,
        }
      );
      return {
        formattedChartSeries: [
          ...acc.formattedChartSeries,
          {
            type: 'line',
            data: planDataParams.isNightShift
              ? shiftDataDayNight(formattedRouteData)
              : formattedRouteData,
            name: routeData.label,
            color: routesColors[routeData.name],
          } as SeriesLineOptions,
        ],
        isDataEmpty: acc.isDataEmpty && isRouteDataEmpty,
      };
    },
    {
      formattedChartSeries: [] as SeriesLineOptions[],
      isDataEmpty: true,
    }
  );

  const kpiRealValue =
    chartConfig.kpiDisplayWriteRatio * (constraints.kpi ?? 0);

  const breachBands =
    !constraints.showConstraints ||
    kpiRealValue <= 0 ||
    formattedChartSeries.length === 0
      ? []
      : getBreachBands(
          formattedChartSeries,
          kpiRealValue,
          chartConfig.kpiBreach
        );

  const kpiAnnotation = formatKpiLineToHighcharts({
    label: chartConfig.kpiTitle || 'KPI',
    value: kpiRealValue,
  });

  const isDisplayOccupation = constraints.showConstraints;
  const startTimeMs = convertTimeToMilliSeconds(`${constraints.startTime}:00`);
  const endTimeMs =
    convertTimeToMilliSeconds(`${constraints.endTime}:00`) +
    (planDataParams.isNightShift ? 24 * 60 * 60 * 1000 : 0);

  const subtitle = formatMessage(
    {
      defaultMessage: `{predictionMode, select, PESSIMISTIC {85th Percentile} other {Average}} from {fromDate} to {toDate}{daysOfWeek}`,
      id: 'YyGqBh',
      description: 'Plan chart subtitle',
    },
    {
      predictionMode:
        planDataParams.predictionMode === PredictionMode.PESSIMISTIC
          ? 'PESSIMISTIC'
          : 'AVERAGE',
      fromDate: formatDate(planDataParams.startDate, {
        ...DATE_PRESET_SHORT_D_M_Y,
        timeZone: planDataParams.siteTimeZone,
      }),
      toDate: formatDate(planDataParams.endDate, {
        ...DATE_PRESET_SHORT_D_M_Y,
        timeZone: planDataParams.siteTimeZone,
      }),
      daysOfWeek: daysOfWeek === undefined ? '' : ` - ${daysOfWeek.join(', ')}`,
    }
  );

  return isDataEmpty ? (
    <EmptyPlanChart title={title} subtitle={subtitle} />
  ) : (
    <Chart
      eventTracker={trackChartEvents}
      options={{
        chart: {
          spacingBottom: isDisplayOccupation ? 30 : 15,
          events: {
            redraw() {
              if (isDisplayOccupation) {
                drawOccupationChart({
                  title: formatMessage({
                    defaultMessage: 'Your occupation',
                    id: 'gqJd4G',
                  }),
                  startTime: startTimeMs,
                  endTime: endTimeMs,
                  chart: this as any, // FIXME
                  isNightShift: planDataParams.isNightShift,
                });
              } else {
                removeOccupationChart(
                  this as any // FIXME
                );
              }
            },
            load() {
              if (isDisplayOccupation) {
                drawOccupationChart({
                  title: formatMessage({
                    defaultMessage: 'Your occupation',
                    id: 'gqJd4G',
                  }),
                  startTime: startTimeMs,
                  endTime: endTimeMs,
                  chart: this as any, // FIXME
                  isNightShift: planDataParams.isNightShift,
                });
              }
            },
          },
        },
        title: {
          text: title,
          margin: 64,
        },
        subtitle: {
          text: subtitle,
        },
        tooltip: {
          useHTML: true,
          shared: true,
          borderRadius: 6,
          backgroundColor: 'rgba(255, 255, 255, 0.8)',
          borderColor: '#f3f3f3',
          shadow: false,
          outside: true,
          formatter() {
            const formatterObject =
              this as unknown as TooltipFormatterContextObject;
            if (!formatterObject.points) {
              return '';
            }
            formatterObject.points.sort(
              (pointA, pointB) => pointB.y! - pointA.y!
            );
            const tooltipInHtml = ReactDOMServer.renderToStaticMarkup(
              <Tooltip
                timeZone="UTC"
                points={formatterObject.points}
                formatValue={chartConfig.formatValue}
                dateFormat="LT"
                formatMessage={formatMessage}
              />
            );
            return tooltipInHtml.toString();
          },
        },

        legend: {
          enabled: false,
          margin: 0,
        },
        xAxis: {
          type: 'datetime',
          tickInterval: MS_ONE_HOUR,
          plotBands: [...breachBands].filter((a) => a) as any,
          labels: {
            formatter: (ctx) => {
              if (
                midnightLabel &&
                isInteger(ctx.value) &&
                (ctx.value as number) % MS_24_HOURS === 0
              ) {
                return midnightLabel;
              }
              return formatTime(ctx.value, {
                hour: 'numeric',
                hour12: true,
                timeZone: 'UTC',
              });
            },
          },
          title: {
            style: {
              fontSize: '12px',
            },
            text: formatMessage(
              {
                defaultMessage: 'Time in {timeZone}',
                id: 'Ez/Pyo',
                description: 'Time based chart x axis label',
              },
              { timeZone: planDataParams.siteTimeZone }
            ),
          },
        },
        yAxis: {
          softMin: 0,
          softMax: yAxisMax,
          title: {
            text: chartConfig.dataAxisTitle,
            reserveSpace: false,
            rotation: 0,
            x: 0,
            y: -32,
            align: 'high' as AxisTitleAlignValue,
            textAlign: 'left' as AlignValue,
          },
          tickInterval: chartConfig.dataAxisInterval,
          labels: {
            formatter: (item) =>
              chartConfig.formatValue
                ? chartConfig.formatValue(Number(item.value))
                : item.value.toString(),
          },
        },
        series: formattedChartSeries,
        annotations:
          constraints.showConstraints && kpiRealValue > 0
            ? [kpiAnnotation]
            : [],
        exporting: {
          buttons: {
            contextButton: {
              menuItems: [
                'viewFullscreen',
                'printChart',
                'separator',
                'downloadPNG',
                'downloadJPEG',
                'downloadPDF',
                'downloadSVG',
                'separator',
                'downloadCSV',
              ],
            },
          },
          csv: {
            dateFormat: '%H:%M',
            columnHeaderFormatter: (item) => {
              // KPI annotation column header:
              if (typeof item === 'number') {
                return 'Generated by Mooven.com';
              }
              if (!item || item instanceof Axis) {
                return 'Time';
              }
              return false;
            },
          },
          chartOptions: {
            legend: {
              enabled: true,
            },
            chart: {
              events: {
                load() {
                  if (isDisplayOccupation) {
                    drawOccupationChart({
                      title: formatMessage({
                        defaultMessage: 'Your occupation',
                        id: 'gqJd4G',
                      }),
                      startTime: startTimeMs,
                      endTime: endTimeMs,
                      chart: this as any, // FIXME
                      isNightShift: planDataParams.isNightShift,
                    });
                  }
                },
              },
            },
          },
        },
      }}
    />
  );
}

function EmptyPlanChart({
  title,
  subtitle,
}: {
  title: string;
  subtitle: string;
}) {
  const { formatMessage } = useIntl();
  return (
    <Chart
      overlayComponent={
        <SplashMessage
          size="sm"
          image={<TapeRecorderSvg width="280px" />}
          title={formatMessage({
            id: '3GYOh3',
            defaultMessage:
              'There is no recording available for selected routes',
          })}
          subtitle={formatMessage({
            id: '2LthiG',
            defaultMessage: 'Try changing selected routes or date range',
          })}
        />
      }
      options={{
        title: {
          text: title,
          margin: 64,
        },
        subtitle: {
          text: subtitle,
        },
        legend: {
          enabled: false,
        },
        exporting: {
          enabled: false,
        },
      }}
    />
  );
}

function getBreachBands(
  formattedChartSeries,
  kpiValue,
  kpiBreach = KpiBreachType.OVER
) {
  const breachBands: AxisPlotBandsOptions[] = [];
  const maxSeries: DataItem[] = [];
  formattedChartSeries.forEach((series) => {
    if (series.visible) {
      series.data.forEach((point, pointIndex) => {
        const pointDate = point[0];
        const pointValue = point[1];
        const maxItemValue = maxSeries[pointIndex]?.value;
        maxSeries[pointIndex] = {
          date: pointDate,
          value:
            kpiBreach === KpiBreachType.OVER
              ? Math.max(pointValue || 0, maxItemValue || 0)
              : Math.min(pointValue || Infinity, maxItemValue || Infinity),
        };
      });
    }
  });
  let openBand;
  maxSeries.forEach((maxSeriesItem, maxSeriesItemIndex) => {
    const nextItem = maxSeries[maxSeriesItemIndex + 1];
    if (!nextItem) {
      if (openBand) {
        breachBands.push({
          ...openBand,
          to: maxSeriesItem.date,
        });
        openBand = undefined;
      }
    } else {
      // eslint-disable-next-line no-lonely-if
      if (openBand) {
        if (
          (kpiBreach === KpiBreachType.OVER &&
            maxSeriesItem.value <= kpiValue &&
            nextItem.value <= kpiValue) ||
          (kpiBreach === KpiBreachType.UNDER &&
            maxSeriesItem.value >= kpiValue &&
            nextItem.value >= kpiValue)
        ) {
          breachBands.push({
            ...openBand,
            to: maxSeriesItem.date,
          });
          openBand = undefined;
        }
      } else {
        // eslint-disable-next-line no-lonely-if
        if (
          (kpiBreach === KpiBreachType.OVER &&
            (nextItem.value > kpiValue || maxSeriesItem.value > kpiValue)) ||
          (kpiBreach === KpiBreachType.UNDER &&
            (nextItem.value < kpiValue || maxSeriesItem.value < kpiValue))
        ) {
          openBand = {
            color: COLOR_KPI_BREACH,
            from: maxSeriesItem.date,
          };
        }
      }
    }
  });
  return breachBands;
}

function checkRouteIsVisible(route, hiddenRoutes) {
  return hiddenRoutes.indexOf(Number(route.name.replace('_', ''))) === -1;
}
