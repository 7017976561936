import { ReactNode } from 'react';
import {
  Box,
  Modal,
  ModalContent,
  ModalOverlay,
  SlideFade,
  useBreakpointValue,
} from '@chakra-ui/react';

interface OverlayProps {
  onClose: () => void;
  isOpen: boolean;
  children?: ReactNode;
}

const OVERLAY_WIDTH = 400;

export default function Overlay({ children, isOpen, onClose }: OverlayProps) {
  const isOverlay = useBreakpointValue({
    md: true,
    base: false,
  });

  if (isOverlay) {
    return (
      <Box
        pos="fixed"
        top={0}
        right={0}
        bottom={0}
        width={OVERLAY_WIDTH}
        pt={12}
        zIndex={1050}
        pointerEvents="none"
      >
        <SlideFade
          in={isOpen}
          offsetX={OVERLAY_WIDTH}
          offsetY={0}
          style={{ height: '100%' }}
          unmountOnExit
        >
          <Box width={OVERLAY_WIDTH} height="100%">
            {children}
          </Box>
        </SlideFade>
      </Box>
    );
  }
  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      size="full"
      motionPreset="slideInRight"
    >
      <ModalOverlay background="whiteAlpha.800" />
      <ModalContent background="transparent">{children}</ModalContent>
    </Modal>
  );
}
