import {
  FormControl,
  FormLabel,
  HStack,
  Spinner,
  Switch,
  VStack,
} from '@chakra-ui/react';
import { FormattedMessage } from 'react-intl';
import { useActionWithFeedback } from '../../hooks/useActionWithFeedback';
import AlertBox from '../AlertBox';

export default function MonitoringSection({
  hasRoutes,
  isMonitoring,
  startMonitoring,
  stopMonitoring,
  goToRoutes,
}: {
  hasRoutes: boolean;
  isMonitoring: boolean;
  startMonitoring: () => Promise<void>;
  stopMonitoring: () => Promise<void>;
  goToRoutes: () => void;
}) {
  const { performAction: toggleMonitoring, isPerformingAction } =
    useActionWithFeedback({
      action: isMonitoring ? stopMonitoring : startMonitoring,
      trackEvent: {
        name: isMonitoring
          ? 'Site Monitoring Disabled'
          : 'Site Monitoring Enabled',
        data: {
          referrer: 'Create site',
        },
      },
    });

  return (
    <FormControl>
      <VStack spacing={4} alignItems="left">
        <HStack spacing={2} alignItems="center">
          <Switch
            isChecked={isMonitoring}
            isDisabled={isPerformingAction || !hasRoutes}
            size="md"
            colorScheme="green"
            onChange={toggleMonitoring}
          />
          <FormLabel fontSize="sm" fontWeight="normal" m="0" lineHeight="1">
            {isMonitoring ? (
              <FormattedMessage
                id="XXtFBu"
                defaultMessage="Turn off monitoring"
              />
            ) : (
              <FormattedMessage
                id="LD3rq4"
                defaultMessage="Start monitoring now"
              />
            )}
          </FormLabel>
          {isPerformingAction && <Spinner size="sm" variant="mooven" />}
        </HStack>
        {!hasRoutes && (
          <AlertBox
            message={
              <FormattedMessage
                defaultMessage="In order to start monitoring, you'll need to set up at least one route."
                id="F348N2"
              />
            }
            cta={{
              label: (
                <FormattedMessage defaultMessage="Add routes" id="776cLn" />
              ),
              action: goToRoutes,
            }}
          />
        )}
      </VStack>
    </FormControl>
  );
}
