import { SmallCloseIcon } from '@chakra-ui/icons';
import {
  Box,
  Button,
  Flex,
  IconButton,
  Text,
  Spinner,
  useDisclosure,
} from '@chakra-ui/react';
import {
  memo,
  RefCallback,
  useCallback,
  useEffect,
  useLayoutEffect,
  useRef,
} from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import Card from 'design-system/atoms/card';
import { PencilIcon } from 'design-system/atoms/custom-icons';
import { SiteView } from '../../hooks/useSiteView';
import { Annotation } from '../../types/annotations';
import { Site } from '../../types/site';
import {
  AnnotatedEvent,
  splitAnnotationsByPeriod,
} from '../../utils/annotations';
import AnnotationCard from './AnnotationCard';
import { EditableAnnotation } from './EditableAnnotation';
import NoAnnotations from './NoAnnotations';
import StickyBackButton from './StickyBackButton';
import IncidentAnnotation from './IncidentAnnotation';
import { useActionWithFeedback } from '../../hooks/useActionWithFeedback';

const TWELVE_HOURS = 12 * 60 * 60 * 1000;

function OverlayContent({
  onClose,
  selectedSiteData,
  annotations,
  siteView,
  createAnnotation,
  updateAnnotation,
  deleteAnnotation,
  resendAnnotationAlert,
  overlayBottomRef,
  isFetching,
  isReachingEnd,
}: {
  onClose: () => void;
  selectedSiteData: Site;
  annotations: AnnotatedEvent[];
  siteView: SiteView;
  createAnnotation: (newAnnotation: Partial<Annotation>) => Promise<void>;
  updateAnnotation: (editedAnnotation: Annotation) => Promise<void>;
  deleteAnnotation: (annotationToDelete: Annotation) => Promise<void>;
  resendAnnotationAlert: (annoatationId: number) => Promise<void>;
  overlayBottomRef: RefCallback<HTMLDivElement | null>;
  isFetching: boolean;
  isReachingEnd: boolean;
}) {
  const { isOpen, onOpen: openForm, onClose: closeForm } = useDisclosure();
  const currentDateRangeRef = useRef<HTMLDivElement>(null);
  const scrollToCurrentDateRange = () => {
    currentDateRangeRef.current?.scrollIntoView({
      block: 'nearest',
      behavior: 'smooth',
    });
  };
  const { formatMessage } = useIntl();
  const scrollContainerRef = useRef<HTMLDivElement>(null);
  const scrollPropsBeforeUpdate = useRef<{
    scrollTop: number;
    contentHeight: number;
  }>();
  useLayoutEffect(() => {
    if (
      scrollContainerRef.current &&
      scrollPropsBeforeUpdate.current !== undefined
    ) {
      const contentHeightDiff =
        scrollPropsBeforeUpdate.current.contentHeight -
        scrollContainerRef.current.scrollHeight;
      const newScrollTop = Math.max(
        0,
        scrollPropsBeforeUpdate.current.scrollTop - contentHeightDiff
      );
      scrollContainerRef.current.scroll(0, newScrollTop);
      scrollPropsBeforeUpdate.current = undefined;
    }
  });
  const centerDateRange = useCallback(
    (annotation: { startDate: number; endDate?: number }) => {
      if (scrollContainerRef.current) {
        scrollPropsBeforeUpdate.current = {
          scrollTop: scrollContainerRef.current.scrollTop,
          contentHeight: scrollContainerRef.current.scrollHeight,
        };
      }
      siteView?.setPeriod({
        type: 'TIME_PERIOD_CUSTOM',
        startDate: annotation.startDate * 1000 - TWELVE_HOURS,
        endDate:
          (annotation.endDate || annotation.startDate) * 1000 + TWELVE_HOURS,
      });
    },
    [siteView]
  );

  useEffect(() => {
    currentDateRangeRef.current?.scrollIntoView({
      block: 'nearest',
    });
  }, []);

  const splitAnnotations = splitAnnotationsByPeriod(
    annotations,
    siteView.state.period,
    selectedSiteData.siteTimeZone
  );

  const resentAlertWithFeedback = useActionWithFeedback({
    action: resendAnnotationAlert,
    successMessage: {
      title: formatMessage({
        defaultMessage: 'Annotation alert sent',
        id: 'jiAdq9',
      }),
    },
    failureMessage: {
      title: formatMessage({
        defaultMessage: 'Unable to send annotation alert',
        id: 'yS9I2X',
      }),
    },
  });

  const renderAnnotation = (
    annotation: AnnotatedEvent,
    inTimeWindow: boolean
  ) => (
    <AnnotationCard inTimeWindow={inTimeWindow} key={annotation.id}>
      {annotation.manualContent ? (
        <EditableAnnotation
          annotation={annotation.manualContent}
          timeZone={selectedSiteData.siteTimeZone}
          onSave={updateAnnotation}
          onDelete={deleteAnnotation}
          onResendAlert={resentAlertWithFeedback.performAction}
          onClick={centerDateRange}
        />
      ) : (
        <IncidentAnnotation
          incident={annotation}
          timeZone={selectedSiteData.siteTimeZone}
          onClick={centerDateRange}
        />
      )}
    </AnnotationCard>
  );
  const renderCurrentAnnotation = (annotation: AnnotatedEvent) =>
    renderAnnotation(annotation, true);
  const renderNotCurrentAnnotation = (annotation: AnnotatedEvent) =>
    renderAnnotation(annotation, false);

  return (
    <Flex direction="column" maxH="100%" pointerEvents="all">
      <Box flex="0 0 auto" px={4} pt={4} mb={-2} pos="relative" zIndex={2}>
        <Card p={4} layerStyle="floating">
          <Flex justifyContent="space-between" align="center">
            <Text as="h2" textStyle="h2">
              <FormattedMessage
                defaultMessage="Annotations"
                id="TGJ5lB"
                description="Annotations header"
              />
            </Text>
            <Flex align="center">
              <Button
                mr={2}
                size="sm"
                variant="ghost"
                leftIcon={<PencilIcon />}
                onClick={openForm}
              >
                <FormattedMessage
                  defaultMessage="Create"
                  id="oO2Yk9"
                  description="Create Annotation"
                />
              </Button>

              <IconButton
                onClick={onClose}
                variant="solid"
                size="sm"
                aria-label="Close annotations"
              >
                <SmallCloseIcon />
              </IconButton>
            </Flex>
          </Flex>
        </Card>
        {isOpen && (
          <Card p={4} mt={2} layerStyle="floating">
            <EditableAnnotation
              timeZone={selectedSiteData.siteTimeZone}
              onSave={(editedAnnotation) =>
                createAnnotation(editedAnnotation).then(closeForm)
              }
              onResendAlert={resentAlertWithFeedback.performAction}
              onCancel={closeForm}
            />
          </Card>
        )}
      </Box>
      <Box
        flex="1"
        overflowY="auto"
        pt={6}
        pb={20}
        px={4}
        ref={scrollContainerRef}
      >
        {splitAnnotations.later.map(renderNotCurrentAnnotation)}
        {splitAnnotations.later.length > 0 && (
          <StickyBackButton direction="up" onClick={scrollToCurrentDateRange} />
        )}
        <Box ref={currentDateRangeRef} pt={16} pb={12} my={-16}>
          {splitAnnotations.current.length === 0 && !isFetching && (
            <AnnotationCard>
              <NoAnnotations />
            </AnnotationCard>
          )}
          {splitAnnotations.current.length > 0 &&
            splitAnnotations.current.map(renderCurrentAnnotation)}
        </Box>
        {splitAnnotations.earlier.length > 0 && (
          <StickyBackButton
            direction="down"
            onClick={scrollToCurrentDateRange}
          />
        )}
        {splitAnnotations.earlier.map(renderNotCurrentAnnotation)}
        {isFetching ? (
          <Flex
            width="100%"
            height="100%"
            alignItems="center"
            justifyContent="center"
            p={10}
          >
            <Spinner variant="mooven" size="lg" />
          </Flex>
        ) : (
          !isReachingEnd && <Box marginTop="50px" ref={overlayBottomRef} />
        )}
      </Box>
    </Flex>
  );
}

export default memo(OverlayContent);
