import { Checkbox, VStack } from '@chakra-ui/react';
import { ReactNode } from 'react';

export interface MultiSelectListProps<Options extends string[]> {
  options: Options;
  selected: Options[number][];
  getLabel: (item: Options[number]) => ReactNode;
  getColor?: (item: Options[number]) => string;
  onChange: (items: Options[number][]) => void;
  size?: 'sm' | 'md' | 'lg';
  isDisabled?: boolean;
}

export default function MultiSelectList<Options extends string[]>({
  options,
  getLabel,
  getColor,
  onChange,
  selected,
  size = 'md',
  isDisabled = false,
}: MultiSelectListProps<Options>) {
  return (
    <VStack width="full" height="full" alignItems="flex-start">
      {options.map((o) => {
        const isChecked = selected.indexOf(o) >= 0;
        return (
          <Checkbox
            size={size}
            m={0}
            key={o}
            colorScheme={getColor ? getColor(o) : undefined}
            onChange={() =>
              isChecked
                ? onChange(selected.filter((op) => op !== o))
                : onChange(selected.concat([o]))
            }
            isChecked={isChecked}
            isDisabled={isDisabled}
          >
            {getLabel(o)}
          </Checkbox>
        );
      })}
    </VStack>
  );
}
