import { PlanInsightType } from '../../constants/path';
import { TimeString, NightDayPeriod, PredictionMode } from '../../types/plan';

const journeyTypeInsights = [
  PlanInsightType.DELAY,
  PlanInsightType.SPEED,
  PlanInsightType.TIME,
  PlanInsightType.IMPACT,
] as const;
type PlanControlsTypeJourney = (typeof journeyTypeInsights)[number];
export type PlanControlsType = PlanControlsTypeJourney | PlanInsightType.COUNT;

export type PlanConstraints = {
  startTime: TimeString | '';
  endTime: TimeString | '';
  kpi: number | undefined;
  showConstraints: boolean;
};
export type PlanSettingsParams = {
  startDate?: number;
  endDate?: number;
  daysOfTheWeek: number[];
  period: NightDayPeriod;
  mode: PredictionMode;
};

export interface PlanCommonControlsState {
  startDate?: PlanSettingsParams['startDate'];
  endDate?: PlanSettingsParams['endDate'];
  daysOfTheWeek: PlanSettingsParams['daysOfTheWeek'];
  period: PlanSettingsParams['period'];
}
export interface PlanJourneyControlsState extends PlanCommonControlsState {
  mode: PlanSettingsParams['mode'];
  showConstraints: PlanConstraints['showConstraints'];
  startTime: PlanConstraints['startTime'];
  endTime: PlanConstraints['endTime'];
  kpi: PlanConstraints['kpi'];
}
type PlanControlsManagerBase = {
  insightId: string;
  insightUnitLabel?: string;
  hasSiteImpact: boolean;
  siteTimeZone: string;
  availableDateRange?: {
    from: number;
    to: number;
  };
};
type PlanCommonControlsManager = PlanControlsManagerBase & {
  insightType: Exclude<PlanControlsType, PlanControlsTypeJourney>;
  controlsState: PlanCommonControlsState;
  setControlsState: (newState: PlanCommonControlsState) => void;
};
type PlanJourneyControlsManager = PlanControlsManagerBase & {
  insightType: PlanControlsTypeJourney;
  controlsState: PlanJourneyControlsState;
  setControlsState: (newState: PlanJourneyControlsState) => void;
};
export type PlanControlsManager =
  | PlanCommonControlsManager
  | PlanJourneyControlsManager;
export function isPlanControlsManagerTypeJourney(
  planManager: PlanControlsManager
): planManager is PlanJourneyControlsManager {
  return journeyTypeInsights.indexOf(planManager.insightType as any) > -1;
}
