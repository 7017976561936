import { FormattedMessage } from 'react-intl';
import SelectionGroup from 'design-system/molecules/selection-group';
import { Box } from '@chakra-ui/react';
import { colorsSortedByKey } from '../utils/routes';
import { SiteView, SiteViewState } from '../hooks/useSiteView';
import RouteIcon from '../svg/route.svg?react';

export interface VisibleRoute {
  id: string;
  routeLabel: string;
}

interface VisibleRoutesSelectorProps {
  routes: VisibleRoute[];
  hiddenRoutes: SiteViewState['hiddenRoutes'];
  setHiddenRoutes: SiteView['setHiddenRoutes'];
}

export default function VisibleRoutesSelector({
  routes = [],
  hiddenRoutes,
  setHiddenRoutes,
}: VisibleRoutesSelectorProps) {
  const routesColors = colorsSortedByKey(routes, 'id');
  const sortedRoutes = [...routes].sort((routeA, routeB) =>
    routeA.routeLabel.localeCompare(routeB.routeLabel)
  );
  const selectedOptions = sortedRoutes
    .filter(
      (route) => !hiddenRoutes.includes(Number(route.id?.replace('_', '')))
    )
    .map((route) => route.id);

  const onSelectedOptionsChange = (newSelectedOptions: string[]) => {
    const newHiddenRoutes = sortedRoutes
      .filter((route) => !newSelectedOptions.includes(route.id))
      .map((route) => Number(route.id.replace('_', '')));

    setHiddenRoutes(newHiddenRoutes);
  };

  return (
    <Box p={4} data-testid="visible-routes-selector">
      <SelectionGroup
        title={
          <FormattedMessage
            defaultMessage="Routes"
            id="Ho/VQ9"
            description="Visible routes selector title"
          />
        }
        titleIcon={<RouteIcon />}
        options={sortedRoutes.map((route) => ({
          id: route.id,
          label: route.routeLabel,
          color: routesColors[route.id || 0],
        }))}
        selectedOptions={selectedOptions}
        onSelectedOptionsChange={onSelectedOptionsChange}
      />
    </Box>
  );
}
