import {
  FunctionComponent,
  MutableRefObject,
  ReactNode,
  createContext,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { runIfFn } from '@chakra-ui/utils';
import {
  UseStepsReturn,
  HStack,
  Button,
  Box,
  Text,
  Flex,
} from '@chakra-ui/react';
import { FormattedMessage } from 'react-intl';
import ProgressSteps from 'design-system/molecules/progress-steps';
import { createPortal } from 'react-dom';
import { errorReport } from '../../utils/errors';
import { useFailureToast } from '../../hooks/useFailureToast';

const CreateWizardLayoutContext = createContext({
  setHasFocusContent: () => {},
  focusContentRoot: null,
} as {
  setHasFocusContent: (hasFocusContent: boolean) => void;
  focusContentRoot: HTMLDivElement | null;
});

export interface CreateWizardStepRenderProps {
  submitActionRef: MutableRefObject<undefined | (() => Promise<void>)>;
  isSubmitting: boolean;
  LayoutFocusContent: FunctionComponent<{ children: ReactNode }>;
}
interface WizardStepConfig {
  name: string;
  title?: string;
  renderContent: (wizardProps: CreateWizardStepRenderProps) => ReactNode;
  onStepWillExit?: () => Promise<void> | void;
  onCancel?: () => void;
}

export default function CreateWizardLayout({
  steps,
  stepsManager,
  onExit,
  isDisabled,
  hideStepper,
}: {
  steps: WizardStepConfig[];
  stepsManager: UseStepsReturn;
  onExit?: () => void;
  isDisabled?: boolean;
  hideStepper?: boolean;
}) {
  const { activeStep: activeStepIndex, goToNext } = stepsManager;
  const currentStep = steps[activeStepIndex];
  const isLastStep = stepsManager.activeStep === steps.length - 1;

  const [hasFocusContent, setHasFocusContent] = useState(false);
  const [focusContentRoot, setFocusContentRoot] =
    useState<HTMLDivElement | null>(null);
  const layoutContextValue = useMemo(
    () => ({ setHasFocusContent, focusContentRoot }),
    [setHasFocusContent, focusContentRoot]
  );

  const allStepsSubmitRef = useRef<
    Array<{ current: undefined | (() => Promise<void>) }>
  >(steps.map(() => ({ current: undefined })));

  const failureToast = useFailureToast();
  const currentStepSubmitRef = allStepsSubmitRef.current[activeStepIndex];
  const [isWaitingStepExit, setIsWaitingStepExit] = useState(false);
  const stepWillExitHandler = async () => {
    if (currentStep.onStepWillExit || currentStepSubmitRef?.current) {
      setIsWaitingStepExit(true);
      try {
        if (currentStepSubmitRef?.current) {
          await currentStepSubmitRef.current();
        }
        await currentStep.onStepWillExit?.();
      } catch (err) {
        // if promise rejected with true (handled failure with no message) prevent step exit with no ui feedback
        if (err === true) {
          throw err;
        }
        // if promise rejected with string (handled failure with message), show string on failure toast and prevent step exit
        if (typeof err === 'string') {
          failureToast({
            title: err,
          });
          throw err;
        }
        // otherwise (unexpected), show generic failure toast, log error, and prevent step exit
        errorReport.unknown(err);
        failureToast({
          title: (
            <FormattedMessage
              defaultMessage="An error has occured. We were unable to save your data. Please try again."
              id="BZZfC5"
            />
          ),
        });
        throw err;
      } finally {
        setIsWaitingStepExit(false);
      }
    }
  };
  const formSubmitHandler = async (e) => {
    e.preventDefault();
    if (!currentStepSubmitRef.current) {
      return;
    }
    try {
      await stepWillExitHandler();
    } catch (err) {
      return;
    }
    if (isLastStep) {
      runIfFn(onExit);
    } else {
      goToNext();
    }
  };
  const nextClickHandler = async () => {
    try {
      await stepWillExitHandler();
    } catch (err) {
      return;
    }
    goToNext();
  };
  const exitClickHandler = async () => {
    try {
      await stepWillExitHandler();
    } catch (err) {
      return;
    }
    runIfFn(onExit);
  };

  return (
    <CreateWizardLayoutContext.Provider value={layoutContextValue}>
      <Flex
        direction="column"
        minHeight="100%"
        ref={(el) => setFocusContentRoot(el)}
        display={!hasFocusContent ? 'none' : undefined}
      />
      <Flex
        direction="column"
        gap={4}
        minHeight="100%"
        display={hasFocusContent ? 'none' : undefined}
      >
        {!hideStepper && (
          <Box marginRight="-16px" flex="0">
            <ProgressSteps
              steps={steps}
              stepsManager={stepsManager}
              stepWillExitHandler={stepWillExitHandler}
              isDisabled={isWaitingStepExit || isDisabled}
            />
          </Box>
        )}

        <Flex direction="column" gap={8} height="100%" flex="1">
          <Flex direction="column" gap={4} flex="1">
            {currentStep.title && (
              <Text m={0} fontSize="2xl" fontWeight="bold" flex="0">
                {currentStep.title}
              </Text>
            )}
            <Box
              as="form"
              flex="1"
              onSubmit={formSubmitHandler}
              autoComplete="off"
            >
              {currentStep.renderContent({
                submitActionRef: currentStepSubmitRef,
                isSubmitting: isWaitingStepExit,
                LayoutFocusContent: CreateWizardFocusContent,
              })}
            </Box>
          </Flex>
          <HStack
            spacing={2}
            position="sticky"
            bottom="0"
            margin="-8px -16px -16px"
            padding="8px 16px 16px"
            backgroundColor="rgba(255, 255, 255, 0.9)"
            backdropFilter="blur(4px)"
          >
            {!isLastStep && !hideStepper && (
              <Button
                size="sm"
                variant="solid"
                colorScheme="greenDark"
                onClick={nextClickHandler}
                isLoading={isWaitingStepExit}
                isDisabled={isDisabled}
              >
                <FormattedMessage id="9+Ddtu" defaultMessage="Next" />
              </Button>
            )}
            {onExit && (
              <Button
                size="sm"
                variant={isLastStep ? 'solid' : 'outline'}
                colorScheme={isLastStep ? 'greenDark' : undefined}
                onClick={exitClickHandler}
                isDisabled={isWaitingStepExit || isDisabled}
                isLoading={isWaitingStepExit && isLastStep}
              >
                <FormattedMessage id="+kXXNF" defaultMessage="Save & exit" />
              </Button>
            )}
            {currentStep.onCancel && (
              <Button
                size="sm"
                variant={hideStepper || isLastStep ? 'outline' : 'ghost'}
                onClick={currentStep.onCancel}
                isDisabled={isWaitingStepExit || isDisabled}
              >
                <FormattedMessage id="47FYwb" defaultMessage="Cancel" />
              </Button>
            )}
          </HStack>
        </Flex>
      </Flex>
    </CreateWizardLayoutContext.Provider>
  );
}

function CreateWizardFocusContent({ children }) {
  const { setHasFocusContent, focusContentRoot } = useContext(
    CreateWizardLayoutContext
  );

  useEffect(() => {
    setHasFocusContent(true);
    return () => {
      setHasFocusContent(false);
    };
  }, [setHasFocusContent]);

  return focusContentRoot && createPortal(children, focusContentRoot);
}
