import { useEffect, useRef } from 'react';

export const useScrollPage = (
  targetElement: HTMLElement | null,
  updatePageAction: () => void
) => {
  const updateActionRef = useRef<() => void>(updatePageAction);
  const observerRef = useRef(
    new IntersectionObserver((entries) => {
      entries.forEach((en) => {
        if (en.intersectionRatio > 0) {
          updateActionRef.current();
        }
      });
    })
  );
  useEffect(() => {
    updateActionRef.current = updatePageAction;
  }, [updatePageAction]);
  useEffect(() => {
    const observer = observerRef.current;
    if (targetElement) {
      observer.observe(targetElement);
      return () => {
        observer.unobserve(targetElement);
      };
    }
    return undefined;
  }, [targetElement]);
};
