import { WorkArea } from '@webapp/bff/src/types/work-area';
import { useState } from 'react';
import { usePolygonCreatorManager } from '../PolygonCreator';

const NEW_AREA_DEFAULT_PROPS = {
  name: 'Work area',
  wkt: 'POLYGON()',
  fillColor: '#FC8181',
  fillTransparency: 0.4,
};

export type WorkAreasEditorManager = ReturnType<
  typeof useWorkAreasEditorManager
>;
export type NewWorkArea = Pick<
  WorkArea,
  'name' | 'wkt' | 'fillColor' | 'fillTransparency'
>;
export type EditWorkArea = NewWorkArea & Pick<WorkArea, 'id'>;
export function isEditWorkArea(
  workArea: NewWorkArea | EditWorkArea
): workArea is EditWorkArea {
  return (workArea as any).id !== undefined;
}

export default function useWorkAreasEditorManager(workAreas: WorkArea[] = []) {
  const [areaEditing, setAreaEditing] = useState<
    null | NewWorkArea | EditWorkArea
  >(null);
  const polygonCreatorManager = usePolygonCreatorManager();

  return {
    polygonCreatorManager: {
      ...polygonCreatorManager,
      startCreating: () => {
        if (polygonCreatorManager.isCreating) {
          return;
        }
        setAreaEditing(null);
        polygonCreatorManager.startCreating();
      },
    },
    onCreatingComplete: (wkt: string) => {
      setAreaEditing({
        ...NEW_AREA_DEFAULT_PROPS,
        wkt,
      });
    },
    areaEditing,
    cancelEditing: () => {
      setAreaEditing(null);
    },
    onWktChange: (wkt: string) => {
      if (areaEditing) {
        setAreaEditing({
          ...areaEditing,
          wkt,
        });
      }
    },
    onEditingChange: (workArea: NewWorkArea | EditWorkArea) => {
      setAreaEditing(workArea);
    },
    startEditing: (areaId) => {
      const workArea = workAreas.find((area) => area.id === areaId);
      if (workArea) {
        setAreaEditing(workArea);
        polygonCreatorManager.stopCreating();
      }
    },
  };
}
