import './RoutesMap.scss';
import { GoogleMap } from '@react-google-maps/api';
import classnames from 'classnames';
import { ReactNode, useEffect, useMemo, useRef, useState } from 'react';
import { useIntl } from 'react-intl';
import { WorkArea } from '@webapp/bff/src/types/work-area';
import {
  MAP_CONTAINER_STYLING,
  MAP_DEFAULT_CENTER,
  MAP_FEATURE_OPTIONS,
} from '../constants/map';
import useMapBounds from '../hooks/useMapBounds';
import { Route } from '../types/route';
import { LatLng } from '../types/site';
import {
  getBoundsWithMinDiameter,
  sortedColorsForItems,
} from '../utils/routes';
import PlacesSearchField from './PlacesSearchField';
import RoutesLegend from './RoutesLegend';
import {
  WorkAreasEditorMapLayer,
  WorkAreasEditorManager,
} from './WorkAreasEditor';

import Map = google.maps.Map;
import {
  DisruptionAreaEditorManager,
  DisruptionAreaEditorMapLayer,
} from './DisruptionAreaEditor';
import { RoutesEditorManager, RoutesEditorMapLayer } from './RoutesEditor';

export default function RoutesMap({
  onLoad,
  routesEditorManager,
  routes,
  monitoringStatus,
  children,
  siteLocation,
  workAreas,
  workAreasEditorManager,
  disruptionAreaEditorManager,
  isEditingDisruptionArea,
}: {
  onLoad?: (map: Map) => void;
  routesEditorManager: RoutesEditorManager;
  routes?: Route[];
  siteLocation?: LatLng;
  monitoringStatus?: ReactNode;
  children?: ReactNode;
  workAreas: WorkArea[];
  workAreasEditorManager: WorkAreasEditorManager;
  disruptionAreaEditorManager: DisruptionAreaEditorManager;
  isEditingDisruptionArea: boolean;
}) {
  const { formatMessage } = useIntl();
  const {
    mapReference,
    editingRoute,
    mapProps: {
      options: routesEditorMapOptions,
      onLoad: routesEditorMapLoadHandler,
      onClick: routesEditorMapClickHandler,
    },
  } = routesEditorManager;
  const mapLocation =
    siteLocation?.lat && siteLocation?.lng
      ? { lat: siteLocation.lat, lng: siteLocation.lng }
      : undefined;

  const [mapCenter, setMapCenter] = useState(mapLocation || MAP_DEFAULT_CENTER);
  const sortedColors = routes ? sortedColorsForItems(routes, 'trackId') : [];
  const [otherRoutesOpacity, setOtherRoutesOpacity] = useState(0.6);
  const mapOptions = useMemo(
    () => ({
      ...MAP_FEATURE_OPTIONS,
      ...routesEditorMapOptions,
    }),
    [routesEditorMapOptions]
  );

  useMapBounds({
    mapInstance: mapReference,
    routes,
    workAreas,
    defaultCenter: mapLocation,
  });

  const disruptionEditingRef = useRef(isEditingDisruptionArea);
  useEffect(() => {
    if (disruptionEditingRef.current !== isEditingDisruptionArea) {
      disruptionEditingRef.current = isEditingDisruptionArea;
      if (
        mapReference &&
        isEditingDisruptionArea &&
        disruptionAreaEditorManager.disruptionAreaState &&
        disruptionAreaEditorManager.disruptionAreaState.length > 2
      ) {
        const newBounds = getBoundsWithMinDiameter(
          disruptionAreaEditorManager.disruptionAreaState
        );
        mapReference.fitBounds(
          new google.maps.LatLngBounds(newBounds.sw, newBounds.ne)
        );
      }
    }
  }, [
    isEditingDisruptionArea,
    disruptionAreaEditorManager.disruptionAreaState,
    mapReference,
  ]);

  return (
    <div
      className={classnames({
        'v2-routes-map': true,
        'v2-routes-map--loading': !routes,
      })}
    >
      <div className="v2-routes-map__search-field">
        <PlacesSearchField
          fieldProps={{
            placeholder: formatMessage({
              defaultMessage: 'Find location',
              id: '6NLtZr',
              description: 'map search field placeholder',
            }),
            customClassNames: {
              element: 'v2-routes-map__search-field-input',
            },
          }}
          placesBias={
            mapLocation && {
              location: mapLocation,
              radius: 50000,
            }
          }
          placeFields={['geometry']}
          onPlaceDetailsReceived={(placeDetailsResult) => {
            if (placeDetailsResult?.geometry?.location) {
              setMapCenter(placeDetailsResult.geometry.location.toJSON());
            }
            if (placeDetailsResult?.geometry?.viewport) {
              mapReference?.fitBounds(placeDetailsResult.geometry.viewport);
            }
          }}
        />
      </div>
      {monitoringStatus && (
        <div className="v2-routes-map__monitoring-status">
          {monitoringStatus}
        </div>
      )}
      {editingRoute && routes && routes.length > 0 && (
        <div className="v2-routes-map__existing-routes">
          <RoutesLegend
            title={formatMessage({
              defaultMessage: 'Existing routes',
              id: 'EpW06p',
              description: 'existing routes panel title',
            })}
            showCount
            routes={routes}
            colors={sortedColors}
            activeId={editingRoute.routeId}
            opacity={otherRoutesOpacity}
            setOpacity={setOtherRoutesOpacity}
          />
        </div>
      )}
      <GoogleMap
        onLoad={(map) => {
          routesEditorMapLoadHandler(map);
          if (onLoad) {
            onLoad(map);
          }
        }}
        mapContainerStyle={MAP_CONTAINER_STYLING}
        center={mapCenter}
        clickableIcons={false}
        zoom={15}
        options={mapOptions}
        onClick={routesEditorMapClickHandler}
      >
        <WorkAreasEditorMapLayer
          workAreas={workAreas}
          workAreasEditorManager={workAreasEditorManager}
        />
        <RoutesEditorMapLayer
          routes={routes || []}
          routesEditorManager={routesEditorManager}
          otherRoutesOpacity={otherRoutesOpacity}
        />
        {isEditingDisruptionArea && (
          <DisruptionAreaEditorMapLayer
            disruptionAreaEditorManager={disruptionAreaEditorManager}
          />
        )}
        {children}
      </GoogleMap>
    </div>
  );
}
