import { createContext, useContext, useMemo, useState } from 'react';
import RouteSelectModal from '../components/RouteSelectModal/RouteSelectModal';

export interface RouteSelectionContext {
  selectRoutes: (
    routes: { name: string; routeId: number }[],
    allowMultiple: boolean
  ) => Promise<{ name: string; routeId: number }[] | undefined>;
}

export const RouteSelectionCtx = createContext<RouteSelectionContext>({
  selectRoutes: async () => [],
});

export function RouteSelectionProvider({
  siteId,
  children,
}: {
  siteId: string;
  children: React.ReactNode;
}) {
  const [settings, setSettings] = useState<{
    allowMultiple: boolean;
    initialValue: { name: string; routeId: number }[] | undefined;
    routeSelectedCallback: (
      selectedRoutes: { name: string; routeId: number }[] | undefined
    ) => Promise<void>;
  }>();
  const ctx: RouteSelectionContext = useMemo(
    () => ({
      selectRoutes: (
        routes: { name: string; routeId: number }[],
        allowMultiple: boolean
      ) =>
        new Promise<{ name: string; routeId: number }[] | undefined>(
          (resolve) => {
            setSettings({
              initialValue: routes,
              allowMultiple,
              routeSelectedCallback: async (
                selectedRoutes: { name: string; routeId: number }[] | undefined
              ) => {
                setSettings(undefined);
                resolve(selectedRoutes);
              },
            });
          }
        ),
    }),
    [setSettings]
  );
  return (
    <RouteSelectionCtx.Provider value={ctx}>
      <RouteSelectModal
        siteId={siteId}
        initialValue={settings?.initialValue}
        isOpen={settings !== undefined}
        allowMultipleRoutes={settings?.allowMultiple}
        onCancel={() => {
          settings?.routeSelectedCallback(undefined);
        }}
        onSave={(selectedRoutes) => {
          settings?.routeSelectedCallback(selectedRoutes);
        }}
      />
      {children}
    </RouteSelectionCtx.Provider>
  );
}

export const useRouteSelection = () => useContext(RouteSelectionCtx);
