import { Box, Flex, Radio, RadioGroup, VStack } from '@chakra-ui/react';
import { FormattedMessage, useIntl } from 'react-intl';
import { UseFormReturn } from 'react-hook-form';
import { ReactElement, ReactNode } from 'react';
import { PredictionMode, NightDayPeriod } from '../../types/plan';

export default function DataModeFieldset({
  formProps: { register, getValues },
}: {
  formProps: UseFormReturn<{ mode?: PredictionMode; period: NightDayPeriod }>;
}) {
  const modeDefaultValue = getValues('mode');
  const periodDefaultValue = getValues('period');
  return (
    <VStack spacing={8} alignItems="stretch">
      <LayoutRadioGroup
        defaultValue={periodDefaultValue}
        groupLabel={
          <FormattedMessage
            defaultMessage="Time of day to centre graph on"
            id="Yfbjkx"
          />
        }
        options={[
          <Radio
            {...register('period')}
            data-testid={`plan-settings-period-${NightDayPeriod.DAY}`}
            value={NightDayPeriod.DAY}
            variant="cell"
          >
            <PeriodValueLabel period={NightDayPeriod.DAY} />
          </Radio>,
          <Radio
            {...register('period')}
            data-testid={`plan-settings-period-${NightDayPeriod.NIGHT}`}
            value={NightDayPeriod.NIGHT}
            variant="cell"
          >
            <PeriodValueLabel period={NightDayPeriod.NIGHT} />
          </Radio>,
        ]}
      />
      {modeDefaultValue && (
        <LayoutRadioGroup
          defaultValue={modeDefaultValue}
          groupLabel={
            <FormattedMessage defaultMessage="Mode of data" id="3xIYyx" />
          }
          options={[
            <Radio
              {...register('mode')}
              data-testid={`plan-settings-mode-${PredictionMode.AVERAGE}`}
              value={PredictionMode.AVERAGE}
              variant="cell"
            >
              <PredictionModeValueLabel mode={PredictionMode.AVERAGE} />
            </Radio>,
            <Radio
              {...register('mode')}
              data-testid={`plan-settings-mode-${PredictionMode.PESSIMISTIC}`}
              value={PredictionMode.PESSIMISTIC}
              variant="cell"
            >
              <PredictionModeValueLabel mode={PredictionMode.PESSIMISTIC} />
            </Radio>,
          ]}
        />
      )}
    </VStack>
  );
}

function LayoutRadioGroup({
  groupLabel,
  options,
  defaultValue,
}: {
  groupLabel: ReactNode;
  options: ReactElement<{ value: string }>[];
  defaultValue?: string;
}) {
  return (
    <VStack spacing={2} alignItems="stretch">
      <Box fontSize="xs">{groupLabel}</Box>
      <RadioGroup defaultValue={defaultValue}>
        <Flex gap={2}>
          {options.map((option) => (
            <Flex
              key={option.props.value}
              flexDirection="column"
              flex="1"
              alignItems="stretch"
            >
              {option}
            </Flex>
          ))}
        </Flex>
      </RadioGroup>
    </VStack>
  );
}

export function PredictionModeValueLabel({ mode }: { mode: PredictionMode }) {
  const { formatMessage } = useIntl();
  switch (mode) {
    case PredictionMode.AVERAGE:
      return formatMessage({
        defaultMessage: 'Typical',
        id: 'YoikrZ',
      });
    case PredictionMode.PESSIMISTIC:
      return formatMessage({
        defaultMessage: 'Pessimistic',
        id: '5yceXs',
      });
    default:
      throw new Error(`invalid field value for period: ${mode}`);
  }
}

export function PeriodValueLabel({ period }: { period: NightDayPeriod }) {
  const { formatMessage } = useIntl();
  switch (period) {
    case NightDayPeriod.DAY:
      return formatMessage({
        defaultMessage: 'Day',
        id: '7pXrnq',
      });
    case NightDayPeriod.NIGHT:
      return formatMessage({
        defaultMessage: 'Night',
        id: '00/IyC',
      });
    default:
      throw new Error(`invalid field value for period: ${period}`);
  }
}
