import useSWR from 'swr';
import { Permission } from 'permissions';
import { Site } from '../types/site';
import { getSiteDetailsKey } from './sharedKeys';
import { standardFetch } from '../utils/fetch';
import { trpc } from '../config/trpc';

interface FetcherKey {
  keyName: string;
  siteId: string;
  insightId: string;
}

type UserSiteAuthorizationData = {
  permissions?: Site['permissions'];
  newPermissions?: Permission[];
};

export default function useUserSiteAuthorizationData({
  insightId,
  siteId,
}: {
  insightId?: string;
  siteId?: string;
}) {
  const { data, error, isValidating } = useSWR<UserSiteAuthorizationData>(
    siteId && insightId
      ? {
          keyName: 'userSiteAuthorizationData',
          siteId,
          insightId,
        }
      : null,
    async (params: FetcherKey) => {
      const newSitePermissions = await trpc.user.sitePermissions.query({
        siteId: params.siteId,
      });

      const siteData = await standardFetch<Site>(
        getSiteDetailsKey(params.insightId, Number(params.siteId))
      );
      return {
        permissions: siteData.permissions,
        newPermissions: newSitePermissions,
      };
    },
    { revalidateOnFocus: false }
  );
  return {
    data,
    error,
    isValidating,
  };
}
