import { SiteViewState } from '../../hooks/useSiteView';
import { TimePeriodType } from '../../types/timePeriod';

export interface UserViewConfigParams {
  siteId: number;
  timePeriod: TimePeriodType;
  hidden?: string;
  startDate?: number | undefined;
  date?: number | undefined;
}
export function getParamsFromSiteViewState(
  state: SiteViewState
): UserViewConfigParams {
  return {
    siteId: state.siteId,
    timePeriod: state.period.type,
    hidden: state.hiddenRoutes.map((id) => `_${id}`).join(','),
    startDate:
      state.period.type === 'TIME_PERIOD_CUSTOM'
        ? Math.round(state.period.startDate / 1000)
        : undefined,
    date:
      state.period.type === 'TIME_PERIOD_CUSTOM'
        ? Math.round(state.period.endDate / 1000)
        : undefined,
  };
}
