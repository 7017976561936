import { useRef, useMemo } from 'react';
import { Polygon, Polyline } from '@react-google-maps/api';
import { googleDynamicCoordinatesToPointArray } from '@lib/gis';
import { DisruptionAreaEditorManager } from './useDisruptionAreaEditorManager';
import { PolygonCreatorMapLayer } from '../PolygonCreator';

export default function DisruptionAreaEditorMapLayer({
  disruptionAreaEditorManager,
}: {
  disruptionAreaEditorManager: DisruptionAreaEditorManager;
}) {
  const {
    polygonCreatorManager,
    onAreaCreated,
    disruptionAreaState,
    setDisruptionAreaState,
  } = disruptionAreaEditorManager;
  const { isCreating } = polygonCreatorManager;

  const polygonLayerRef = useRef<google.maps.Polygon>();
  const onPolygonLayerEdit = () => {
    if (polygonLayerRef.current) {
      setDisruptionAreaState(
        googleDynamicCoordinatesToPointArray(
          polygonLayerRef.current.getPath().getArray()
        )
      );
    }
  };

  const disruptionAreaClosedPath = useMemo(() => {
    if (disruptionAreaState && disruptionAreaState.length > 2) {
      return [...disruptionAreaState, disruptionAreaState[0]];
    }
    return [];
  }, [disruptionAreaState]);

  if (isCreating) {
    return (
      <PolygonCreatorMapLayer
        polygonCreatorManager={polygonCreatorManager}
        onCreate={onAreaCreated}
      />
    );
  }

  if (!disruptionAreaState?.length || disruptionAreaState?.length <= 2) {
    return null;
  }

  return (
    <>
      <Polygon
        onLoad={(polygonLayer) => {
          polygonLayerRef.current = polygonLayer;
        }}
        onMouseUp={onPolygonLayerEdit}
        onDragEnd={onPolygonLayerEdit}
        options={{
          strokeColor: '#F0A0A0',
          strokeOpacity: 1,
          fillColor: '#FEB2B2',
          fillOpacity: 0.3,
          strokeWeight: 1,
          editable: true,
          draggable: false,
          zIndex: 1,
        }}
        path={disruptionAreaState}
      />
      <Polyline
        options={{
          zIndex: 2,
          strokeOpacity: 0,
          icons: [
            {
              icon: {
                path: 'M -0.5,0 0.5,0',
                strokeWeight: 2,
                strokeColor: '#FFFFFF',
                strokeOpacity: 1,
                scale: 1,
              },
              offset: '0px',
              repeat: '4px',
            },
          ],
        }}
        path={disruptionAreaClosedPath}
      />
    </>
  );
}
