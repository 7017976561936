import {
  useRef,
  useState,
  useEffect,
  useImperativeHandle,
  RefObject,
} from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { Box, Text } from '@chakra-ui/react';
import {
  GOOGLE_API_KEY,
  MAX_SITE_NAME_LENGTH,
} from '../../constants/clientConstants';
import { MESSAGE_GENERIC_ERROR } from '../../constants/messages';
import { UNIT_SYSTEM_TYPE_METRIC } from '../../constants/serverConstants';
import useSitesData from '../../data/useSitesData';
import useForm, { useFormValidation } from '../../hooks/useForm';
import useRequestStatus from '../../hooks/useRequestStatus';
import { FormFieldOption } from '../../types/form';
import { Site } from '../../types/site';
import {
  validateRequired,
  validatorWithFormatter,
} from '../../utils/validation';
import FormField from '../FormField';
import PlacesSearchField from '../PlacesSearchField';
import { useAnalytics } from '../../hooks/analytics/useAnalytics';
import { MapPositionManager } from './useMapPositionManager';
import { SiteType } from '../CreateSiteTrigger/types';
import { errorReport } from '../../utils/errors';
import LatLngLiteral = google.maps.LatLngLiteral;
import GeocoderResult = google.maps.GeocoderResult;
import PlacesService = google.maps.places.PlacesService;
import GeocoderAddressComponent = google.maps.GeocoderAddressComponent;

const geocoder = new google.maps.Geocoder();
const placesService = new PlacesService(document.createElement('div'));

export interface SiteDetailStepProps {
  insightId: string;
  siteId?: string;
  mapPositionManager: MapPositionManager;
  onSiteCreated: (siteId: string) => void;
  siteName: string;
  imperativeSaveRef: RefObject<() => void>;
}

export default function SiteDetailsStep({
  insightId,
  siteId,
  mapPositionManager,
  onSiteCreated,
  siteName,
  imperativeSaveRef,
}: SiteDetailStepProps) {
  const { mapCenter, setMapCenter, setMapBounds } = mapPositionManager;
  const mapCenterRef = useRef<LatLngLiteral>();
  const { formatMessage } = useIntl();
  const { track } = useAnalytics();
  const [isUpdatingLocation, setIsUpdatingLocation] = useState(false);
  const siteForm = useForm({
    siteType: SiteType.PROJECT_SITE,
    addressSearch: '',
    siteName,
    siteAddress: '',
    siteTimeZone:
      Intl.DateTimeFormat().resolvedOptions().timeZone ?? 'Pacific/Auckland',
    siteUnit: UNIT_SYSTEM_TYPE_METRIC as Site['siteUnit'],
  });
  const siteFormValidation = useFormValidation(siteForm, {
    siteName: validatorWithFormatter(validateRequired, formatMessage),
    siteAddress: validatorWithFormatter(validateRequired, formatMessage),
  });
  const updateSiteLocationFromMap = async (newLocation: LatLngLiteral) => {
    const requestMapCenter = mapCenterRef.current;
    setIsUpdatingLocation(true);
    const [place, locationTimeZone] = await Promise.all([
      getPlaceForLocation(newLocation),
      getTimeZoneForLocation(newLocation),
    ]);
    // check if map hasn't moved since request, otherwise, ignore updates
    if (!areCoordinatesEqual(requestMapCenter, mapCenterRef.current)) {
      return;
    }
    const locationUnitSystem = getUnitSystemForPlace(place.address_components);
    siteForm.setValues((asyncValues) => ({
      ...asyncValues,
      addressSearch: place.formatted_address,
      siteAddress: place.formatted_address,
      siteTimeZone: locationTimeZone,
      siteUnit: locationUnitSystem,
    }));
    setIsUpdatingLocation(false);
  };

  const updateSiteLocationFromSearch = async (newLocation: FormFieldOption) => {
    const requestMapCenter = mapCenterRef.current;
    siteForm.setValues({
      ...siteForm.values,
      addressSearch: newLocation.label,
      siteAddress: newLocation.label,
    });
    setIsUpdatingLocation(true);
    placesService.getDetails(
      {
        placeId: newLocation.value,
        fields: ['geometry', 'address_component'],
      },
      async (placesServiceResponse) => {
        if (placesServiceResponse?.geometry?.location) {
          const location = placesServiceResponse.geometry.location.toJSON();
          const locationUnitSystem = getUnitSystemForPlace(
            placesServiceResponse?.address_components
          );
          const locationTimeZone = await getTimeZoneForLocation(location);
          // check if map hasn't moved since request, otherwise, ignore updates
          if (!areCoordinatesEqual(requestMapCenter, mapCenterRef.current)) {
            return;
          }
          siteForm.setValues((asyncValues) => ({
            ...asyncValues,
            siteTimeZone: locationTimeZone,
            siteUnit: locationUnitSystem,
          }));
          if (placesServiceResponse?.geometry?.viewport) {
            mapCenterRef.current = placesServiceResponse.geometry.viewport
              .getCenter()
              .toJSON();
            setMapBounds(placesServiceResponse.geometry.viewport);
          } else {
            mapCenterRef.current = location;
            setMapCenter(location);
          }
          setIsUpdatingLocation(false);
        }
      }
    );
  };

  // on map center change
  useEffect(() => {
    if (!areCoordinatesEqual(mapCenter, mapCenterRef.current)) {
      mapCenterRef.current = mapCenter;
      if (mapCenter) {
        updateSiteLocationFromMap(mapCenter);
      }
    }
  });

  const siteNameChangeHandler = (e) => {
    const trimmedValue = e.target.value.substring(0, MAX_SITE_NAME_LENGTH);
    siteForm.fields.siteName.setValue(trimmedValue);
  };
  const [isSaving, hasSaveError, withSaveStatus] = useRequestStatus();
  const { createSite, updateSiteProps } = useSitesData(insightId!);
  const createHandler = async () => {
    if (!siteFormValidation.validate()) {
      throw new Error('form invalid');
    }
    const currentMapCenter = mapCenterRef.current;
    const response = await withSaveStatus(createSite)({
      ...siteForm.values,
      siteLocation: currentMapCenter,
    });
    onSiteCreated(response.toString());
    track('Site Created', {
      referrer: 'Create Site',
      site: {
        ...siteForm.values,
        siteLocation: currentMapCenter,
      },
    });
    track('Project site created', {
      referrer: 'Create Site',
      site: {
        ...siteForm.values,
        siteLocation: currentMapCenter,
      },
    });
  };
  const patchHandler = async () => {
    if (!siteFormValidation.validate()) {
      throw new Error('form invalid');
    }
    const currentMapCenter = mapCenterRef.current;
    await withSaveStatus(updateSiteProps)(Number(siteId), {
      ...siteForm.values,
      siteLocation: currentMapCenter,
    });
    track('Site Patched', {
      referrer: 'Patch Site Step',
      site: {
        ...siteForm.values,
        siteLocation: currentMapCenter,
      },
    });
  };
  useImperativeHandle(imperativeSaveRef, () =>
    siteId ? patchHandler : createHandler
  );
  return (
    <Box>
      <FormField
        label={formatMessage({
          defaultMessage: 'Site name',
          id: 'NGHVxL',
          description: 'Site name field label',
        })}
        value={siteForm.fields.siteName.value}
        error={siteFormValidation.fields.siteName.error}
        onChange={siteNameChangeHandler}
        disabled={isSaving}
        autoFocus
      />
      {siteForm.fields.siteName.value.length > MAX_SITE_NAME_LENGTH - 16 && (
        <Text mt="-12px" mb="12px" fontSize="12px">
          <FormattedMessage
            defaultMessage="characters left: {charCount}"
            id="KrjaEY"
            description="max char count for site name"
            values={{
              charCount:
                MAX_SITE_NAME_LENGTH - siteForm.fields.siteName.value.length,
            }}
          />
        </Text>
      )}
      <PlacesSearchField
        fieldProps={{
          label: formatMessage({
            defaultMessage: 'Site location',
            id: 'i5VUAD',
            description: 'Site location field label',
          }),
          error: siteFormValidation.fields.siteAddress.error,
          disabled: isSaving || isUpdatingLocation,
        }}
        value={siteForm.fields.addressSearch.value}
        onChange={siteForm.fields.addressSearch.onChange}
        onBlur={() => {
          siteForm.setValues((asyncValues) => ({
            ...asyncValues,
            addressSearch: asyncValues.siteAddress,
          }));
        }}
        onPlaceSelected={(selectedPlace) => {
          updateSiteLocationFromSearch(selectedPlace);
        }}
        placesBias={{
          location: mapCenter,
          radius: 50000,
        }}
      />
      {hasSaveError && (
        <Box color="red.500" fontSize="sm" mt={2}>
          {formatMessage(MESSAGE_GENERIC_ERROR)}
        </Box>
      )}
    </Box>
  );
}

function areCoordinatesEqual(coordsA?: LatLngLiteral, coordsB?: LatLngLiteral) {
  return coordsA?.lat === coordsB?.lat && coordsA?.lng === coordsB?.lng;
}

function getUnitSystemForPlace(addressComponents?: GeocoderAddressComponent[]) {
  if (!addressComponents) {
    return 'METRIC';
  }
  return addressComponents.some(
    (addressComponent) =>
      addressComponent.types.indexOf('country') > -1 &&
      addressComponent.short_name.toUpperCase() === 'US'
  )
    ? 'IMPERIAL'
    : 'METRIC';
}
async function getPlaceForLocation(
  location: LatLngLiteral
): Promise<GeocoderResult> {
  const geocoderResponse = await geocoder.geocode({ location });
  const bestPlaceMatch = geocoderResponse.results[0];
  return bestPlaceMatch;
}
async function getTimeZoneForLocation(location: LatLngLiteral) {
  const timezoneApiData = await fetch(
    `https://maps.googleapis.com/maps/api/timezone/json?location=${
      location.lat
    },${location.lng}&timestamp=${Date.now() / 1000}&key=${GOOGLE_API_KEY}`
  ).then((response) => response.json());
  if (!timezoneApiData.timeZoneId) {
    errorReport.log(new Error('No timezone found for location'), { location });
    return 'Pacific/Auckland';
  }
  return timezoneApiData.timeZoneId;
}
