import { useMemo } from 'react';
import useWindowSize from './useWindowSize';

export default function useOptimalHeight(
  desiredGap = 360,
  minHeight = 400,
  maxHeight = 720
) {
  const { height } = useWindowSize();
  return useMemo(() => {
    if (!height) {
      return minHeight;
    }
    const desiredHeight = height - desiredGap;
    return Math.min(maxHeight, Math.max(desiredHeight, minHeight));
  }, [height, desiredGap, minHeight, maxHeight]);
}
