import { Checkbox, Heading, VStack } from '@chakra-ui/react';

import { FormattedMessage } from 'react-intl';

export default function SiteAlertsMenu<
  TAlerts extends {
    siteImpact?: boolean;
    delay?: boolean;
    speed?: boolean;
    queue?: boolean;
    annotations?: boolean;
  },
  TUser,
>({
  user,
  alerts,
  updateAlerts,
  showSiteImpact = false,
  showQueue = false,
}: {
  user: TUser;
  alerts: TAlerts;
  updateAlerts: (input: { user: TUser; newAlertsSettings: TAlerts }) => void;
  showSiteImpact?: boolean;
  showQueue?: boolean;
}) {
  const getChangeHandler = (fieldName: string) => (e) => {
    updateAlerts({
      user,
      newAlertsSettings: {
        ...alerts,
        [fieldName]: e.target.checked,
      },
    });
  };
  return (
    <VStack spacing={4} alignItems="left">
      <Heading size="sm">
        <FormattedMessage
          defaultMessage="Alerts"
          id="f5aUQ+"
          description="site settings alerts menu header"
        />
      </Heading>
      {showSiteImpact && (
        <Checkbox
          isChecked={alerts.siteImpact}
          onChange={getChangeHandler('siteImpact')}
        >
          <FormattedMessage id="X2NaVV" defaultMessage="Site Impact" />
        </Checkbox>
      )}
      <Checkbox isChecked={alerts.delay} onChange={getChangeHandler('delay')}>
        <FormattedMessage id="R86IN4" defaultMessage="Delay" />
      </Checkbox>
      <Checkbox isChecked={alerts.speed} onChange={getChangeHandler('speed')}>
        <FormattedMessage id="QWTP0t" defaultMessage="Speed" />
      </Checkbox>
      {showQueue && (
        <Checkbox isChecked={alerts.queue} onChange={getChangeHandler('queue')}>
          <FormattedMessage id="4YDNPC" defaultMessage="Queue" />
        </Checkbox>
      )}
      <Checkbox
        isChecked={alerts.annotations}
        onChange={getChangeHandler('annotations')}
      >
        <FormattedMessage defaultMessage="Annotations" id="c2l+EP" />
      </Checkbox>
    </VStack>
  );
}
