import { useEffect, useState } from 'react';

export function useWatchStickyTop() {
  const [elementRef, setElementRef] = useState<HTMLElement | null>(null);
  const [isStuck, setIsStuck] = useState(false);
  const [stuckHeight, setStuckHeight] = useState('');

  useEffect(() => {
    if (!elementRef) {
      return () => {};
    }
    const topMargin = getTopValue(elementRef);
    const observer = new IntersectionObserver(
      (entries) => {
        const observedStickyState =
          entries[0].intersectionRatio < 1 &&
          entries[0].boundingClientRect.top <= Math.abs(topMargin + 1);
        setIsStuck((isStuckAsyncState) => {
          if (observedStickyState !== isStuckAsyncState) {
            setStuckHeight(
              observedStickyState ? `${elementRef.offsetHeight}px` : 'auto'
            );
          }
          return observedStickyState;
        });
      },
      { threshold: 1, rootMargin: `${topMargin}px 0px 0px 0px` }
    );
    observer.observe(elementRef);
    return () => {
      observer.disconnect();
    };
  }, [elementRef]);

  return {
    refCallback: setElementRef,
    isStuck,
    stuckHeight,
  };
}

function getTopValue(el: HTMLElement) {
  try {
    const attr = getComputedStyle(el).getPropertyValue('top');
    const splitValuePx = attr.split('px');
    const parsedPxValue = Number(splitValuePx[0]);
    return parsedPxValue >= 0
      ? -(parsedPxValue + 1)
      : Math.abs(parsedPxValue + 1);
  } catch (err) {
    return 0;
  }
}
