import { useEffect, useRef, useState } from 'react';

export default function usePopOver(defaultOpen = false) {
  const [isOpen, setOpen] = useState(defaultOpen);
  const noClickRefs = useRef(new Map<string, HTMLElement | null>());
  const createNoClickRef =
    (refId: string) => (refElement: HTMLElement | null) =>
      noClickRefs.current.set(refId, refElement);

  useEffect(() => {
    if (isOpen) {
      const documentClickHandler = (event: MouseEvent) => {
        const refElements = [...noClickRefs.current.values()];
        if (
          refElements.length === 0 ||
          !refElements.some(
            (el) =>
              el && (el === event.target || el.contains(event.target as Node))
          )
        ) {
          setOpen(false);
        }
      };
      document.addEventListener('click', documentClickHandler);
      return () => {
        document.removeEventListener('click', documentClickHandler);
      };
    }
    return undefined;
  }, [isOpen]);

  return [isOpen, setOpen, createNoClickRef] as const;
}
