import { Polyline } from '@react-google-maps/api';
import { VehicleCountSummary } from '@data-pipeline/api/src/types/vehicle-count';
import classNames from 'classnames';
import { useState } from 'react';
import { useIntl } from 'react-intl';
import CustomGoogleMap from 'design-system/atoms/custom-google-map';
import { POLYLINE_ICONS } from '../../constants/map';
import useMapBounds from '../../hooks/useMapBounds';
import { Route } from '../../types/route';
import { CountSourceIdProps } from '../../types/sharedProps';
import { LatLng } from '../../types/site';
import { parseWkt, sortedColorsForItems } from '../../utils/routes';
import CountSourcesOverlay from '../CountSourcesOverlay';
import RoutesLegend from '../RoutesLegend';
import './index.scss';

type LatLngLiteral = google.maps.LatLngLiteral;

interface Props extends CountSourceIdProps {
  onLoad?: (map: google.maps.Map) => void;
  onMapClick?: (e: google.maps.MapMouseEvent) => void;
  children?: React.ReactNode;
  routes?: Route[];
  sources?: VehicleCountSummary[];
  isFetchingSources: boolean;
  siteLocation?: LatLng;
}

export default function CountMap({
  onLoad,
  onMapClick,
  children,
  routes = [],
  sources = [],
  selectedSourceId,
  setSelectedSourceId,
  focusedSourceId,
  setFocusedSourceId,
  isFetchingSources,
  siteLocation,
}: Props) {
  const { formatMessage } = useIntl();
  const [mapInstance, setMapInstance] = useState<google.maps.Map>();
  const [opacity, setOpacity] = useState(0.6);
  const onCountMapLoad = (map: google.maps.Map) => {
    setMapInstance(map);
    if (onLoad) {
      onLoad(map);
    }
  };
  const mapLocation = siteLocation
    ? ({ lat: siteLocation.lat, lng: siteLocation.lng } as LatLngLiteral)
    : undefined;
  useMapBounds({
    mapInstance,
    routes,
    sources: sources
      .filter((source) => source.location)
      .map((s) => ({ ...s, location: s.location! })),
    defaultCenter: mapLocation,
  });
  const colors = sortedColorsForItems(routes, 'trackId');

  return (
    <div
      className={classNames({
        'v2-count-map': true,
        'v2-count-map--loading': isFetchingSources,
      })}
    >
      <CustomGoogleMap onLoad={onCountMapLoad} onClick={onMapClick}>
        {routes.map((route, idx) => (
          <Polyline
            key={route.name}
            options={{
              strokeColor: colors[idx],
              strokeWeight: 6,
              icons: POLYLINE_ICONS,
              strokeOpacity: opacity,
            }}
            path={parseWkt(route.wkt)}
          />
        ))}
        <CountSourcesOverlay
          sources={sources}
          selectedSourceId={selectedSourceId}
          setSelectedSourceId={setSelectedSourceId}
          focusedSourceId={focusedSourceId}
          setFocusedSourceId={setFocusedSourceId}
        />
        <div className="v2-count-map__legend">
          <RoutesLegend
            title={formatMessage({
              defaultMessage: 'Routes',
              id: 'DZPnNX',
              description: 'Count map routes legend title',
            })}
            showCount={false}
            routes={routes}
            colors={colors}
            opacity={opacity}
            setOpacity={setOpacity}
          />
        </div>
        {children}
      </CustomGoogleMap>
    </div>
  );
}
