import { Box, Button, Input, Text } from '@chakra-ui/react';
import { ChangeEvent, FormEvent, ReactNode, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { MFACode } from '../../api/onboard/constants';
import usePersistentCountdown from '../../hooks/usePersistentCountdown';

interface Props {
  phoneNumber: string;
  handleChangePhoneNumber: () => void;
  handleConfirmMFACode: (code: MFACode) => Promise<void>;
  handleRequestNewCode: () => Promise<void>;
}

const ConfirmMFACode = ({
  phoneNumber,
  handleChangePhoneNumber,
  handleConfirmMFACode,
  handleRequestNewCode,
}: Props) => {
  const { formatMessage } = useIntl();
  const [mfaCode, setMfaCode] = useState('');
  const [isValid, setIsValid] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [timeRemaining, setTimeRemaining] = usePersistentCountdown(
    'mv-onboarding-mfa-code-time-remaining',
    60
  );

  const onChange = (ev: ChangeEvent<HTMLInputElement>) => {
    setMfaCode(ev.target.value);
    if (ev.target.value) {
      setIsValid(true);
    }
  };

  const onSubmit = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setIsSubmitting(true);
    await handleConfirmMFACode(mfaCode);
    setIsSubmitting(false);
  };

  const onRequestNewCode = async () => {
    await handleRequestNewCode();
    setTimeRemaining(60);
  };

  return (
    <>
      <Box mb={4}>
        <Text fontSize="2xl">
          <FormattedMessage
            defaultMessage="A unique code has been sent to <strong>{phoneNumber}</strong>"
            id="G+FyMo"
            description="MFA code sent to phone number"
            values={{
              phoneNumber:
                phoneNumber ||
                formatMessage({
                  description: 'Phone number fallback',
                  defaultMessage: 'your phone number',
                  id: 'P0O6Um',
                }),
              strong: (chunks: ReactNode) => <strong>{chunks}</strong>,
            }}
          />
        </Text>
        <Text color="gray.500">
          <FormattedMessage
            defaultMessage="Enter the code below to get started"
            id="Zxrjp+"
            description="MFA code instructions"
          />
        </Text>
      </Box>
      <form onSubmit={onSubmit}>
        <Box my={10}>
          <Input
            type="number"
            autoComplete="one-time-code"
            inputMode="numeric"
            pattern="[0-9]*"
            placeholder={formatMessage({
              description: 'MFA code input placeholder',
              defaultMessage: 'SMS Code',
              id: 'x80KsF',
            })}
            value={mfaCode}
            onChange={onChange}
          />
        </Box>
        <Button
          isLoading={isSubmitting}
          variant="solid"
          colorScheme="greenDark"
          type="submit"
          isDisabled={!isValid || isSubmitting}
        >
          <FormattedMessage
            defaultMessage="Confirm mobile number"
            id="Z+9N9V"
            description="Confirm phone number"
          />
        </Button>
      </form>
      <Box my={10} data-ignore-visual-test>
        <Text color="gray.500" mb={2}>
          {timeRemaining > 0 ? (
            <FormattedMessage
              defaultMessage="The code has been sent. In {timeRemaining} seconds you can request a new code."
              id="YBYALZ"
              description="Confirm MFA code status"
              values={{ timeRemaining }}
            />
          ) : (
            <Button
              variant="link"
              colorScheme="green"
              onClick={onRequestNewCode}
            >
              <FormattedMessage
                defaultMessage="Request new code"
                id="+9OJyx"
                description="Confirm MFA code request new code action"
              />
            </Button>
          )}
        </Text>
        <Text color="gray.500">
          <FormattedMessage
            defaultMessage="Wrong phone number?"
            id="j+ZEOG"
            description="Confirm MFA code change phone number description"
          />{' '}
          <Button
            variant="link"
            onClick={handleChangePhoneNumber}
            color="green.700"
          >
            <FormattedMessage
              defaultMessage="Change phone number"
              id="7iDBVR"
              description="Confirm MFA code change phone number action"
            />
          </Button>
        </Text>
      </Box>
    </>
  );
};

export default ConfirmMFACode;
