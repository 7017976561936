import { Fragment, ReactNode } from 'react';
import { BlankRoute, Route } from '../../types/route';
import { validateRouteName } from '../../utils/validation';
import RoutesEditorList from './RoutesEditorList';
import RoutesEditorRoutePanel from './RoutesEditorRoutePanel';
import { RoutesEditorManager } from './useRoutesEditorManager';

export default function RoutesSection({
  routes,
  createRoute,
  editRoute,
  deleteRoute,
  routesEditorManager,
  canEditRoutes,
  LayoutFocusContent = Fragment,
}: {
  routes?: Route[];
  canEditRoutes: boolean;
  createRoute: (newRoute: BlankRoute) => Promise<any>;
  editRoute: (route: Route) => Promise<any>;
  deleteRoute: (route: Route) => Promise<any>;
  routesEditorManager: RoutesEditorManager;
  LayoutFocusContent?: ({ children }: { children: ReactNode }) => ReactNode;
}) {
  const { editingRoute, setEditingRoute, stopEditingRoute } =
    routesEditorManager;

  return (
    <>
      <RoutesEditorList
        canEditRoutes={canEditRoutes}
        routes={routes}
        onRouteEdit={setEditingRoute}
        deleteRoute={deleteRoute}
      />
      {editingRoute && (
        <LayoutFocusContent>
          <RoutesEditorRoutePanel
            key={editingRoute.routeId}
            route={editingRoute}
            routesEditorManager={routesEditorManager}
            validateRouteName={(newName) =>
              validateRouteName(
                newName,
                (routes || []).filter(
                  (route) => route.routeId !== editingRoute?.routeId
                )
              )
            }
            saveRoute={(route) => {
              if (route.routeId !== undefined) {
                return editRoute(route as Route).then(() => {
                  stopEditingRoute();
                });
              }
              return createRoute(route).then(() => {
                stopEditingRoute();
              });
            }}
            onCancel={() => {
              stopEditingRoute();
            }}
          />
        </LayoutFocusContent>
      )}
    </>
  );
}
