import './RoutesEditorRoutePanel.scss';
import classnames from 'classnames';
import { useCallback, useEffect, useRef, useState } from 'react';
import {
  Box,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Link,
  Switch,
  Text,
} from '@chakra-ui/react';
import { unstable_usePrompt as usePrompt } from 'react-router-dom';
import { FormattedMessage, MessageDescriptor, useIntl } from 'react-intl';
import {
  MESSAGE_BROWSE_AWAY_UNSAVED_CHANGES,
  MESSAGE_CANCEL_UNSAVED_CHANGES,
  MESSAGE_GENERIC_ERROR,
  MESSAGE_UI_DISABLED_HAS_COLLECTED_DATA,
} from '../../constants/messages';
import { useAnalytics } from '../../hooks/analytics/useAnalytics';
import useForm, { useFormValidation } from '../../hooks/useForm';
import useRequestStatus from '../../hooks/useRequestStatus';
import { FormFieldProps, UseFormProps } from '../../types/form';
import { BlankRoute, Route, RouteDirections } from '../../types/route';
import {
  insertArrayItem,
  removeArrayItem,
  updateArrayItem,
} from '../../utils/array';
import {
  formatCoordinates,
  isValidLatLngString,
  parseCoordinates,
} from '../../utils/routes';
import {
  validateRequired,
  validatorWithFormatter,
} from '../../utils/validation';
import DirectionsPin from '../DirectionsPin';
import FormActions from '../FormActions';
import FormField from '../FormField';
import Spinner from '../Spinner';
import Map = google.maps.Map;
import MapMouseEvent = google.maps.MapMouseEvent;
import useFeatureSwitch, {
  FeatureSwitchState,
} from '../../hooks/useFeatureSwitch';
import { FEATURE_CONFIGURE_QUEUEING_SITE_IMPACT } from '../../constants/features';
import cursorStart from '../../images/cursor_point_a.png';
import cursorEnd from '../../images/cursor_point_b.png';
import cursorWaypoint from '../../images/cursor_point_wp.png';
import DelayedInputField from '../DelayedInputField';
import { RoutesEditorManager } from './useRoutesEditorManager';
import RoadNameForPoint from './RoadNameForPoint';
import { useUserRoles } from '../../hooks/useUserRoles';

type DirectionsFormStage = 'start' | 'end' | number | null;
interface WaypointField {
  key: string;
  value: string;
}
function isWaypointField(x: any): x is WaypointField {
  return x && typeof x.key === 'string' && typeof x.value === 'string';
}
type DirectionsFormValues = {
  startPoint: string;
  endPoint: string;
  waypoints: WaypointField[];
};
type SetMapAction = (mapAction?: {
  click: (e: MapMouseEvent) => void;
  cursor: string;
}) => void;
interface RoutesEditorRoutePanelProps {
  route: Route | BlankRoute;
  routesEditorManager: RoutesEditorManager;
  validateRouteName: (routeName: string) => MessageDescriptor | null;
  saveRoute: (
    route: Omit<Route, 'routeId' | 'trackId'> & Partial<Route>
  ) => Promise<any>;
  onCancel: () => void;
}
interface RouteFormValues extends Record<string, any> {
  name: string;
  description: string;
  startPoint: string;
  endPoint: string;
  waypoints: WaypointField[];
  queuing: boolean;
  siteImpact: boolean;
}
type RouteForm = UseFormProps<RouteFormValues>;

const MAX_WAYPOINTS = 25;

export default function RoutesEditorRoutePanel({
  route,
  routesEditorManager,
  validateRouteName,
  saveRoute,
  onCancel,
}: RoutesEditorRoutePanelProps) {
  const {
    routeLength,
    routeWkt,
    routeDirections,
    setRouteDirections,
    isFetchingDirections,
    hasDirectionsError,
    mapReference,
    setMapAction,
  } = routesEditorManager;
  const { formatMessage } = useIntl();
  const { track } = useAnalytics();
  const routeForm = useForm(
    extractFormValuesFromRoute({ ...route, ...routeDirections })
  );
  const routeFormValidation = useFormValidation(routeForm, {
    name: validatorWithFormatter(validateRouteName, formatMessage),
    description: (value) => {
      if (value.length > 500) {
        return formatMessage({
          defaultMessage:
            'Too many characters. Maximum 500 characters allowed.',
          id: 'yy3ayd',
          description:
            'create route form - description field validation message',
        });
      }
      return undefined;
    },
    startPoint: validatorWithFormatter(validateRequired, formatMessage),
    endPoint: validatorWithFormatter(validateRequired, formatMessage),
    queuing: (value) => {
      if (value && (routeLength < 150 || routeLength > 2000)) {
        return formatMessage({
          defaultMessage:
            'Queue length monitoring works best for routes between 150m and 2km in length. Please adjust the length of the route or disable this option to continue.',
          id: '+9K+po',
          description: 'create route form - queue length validation message',
        });
      }
      return undefined;
    },
  });

  const hasUnsavedValues = isRouteDifferentThanFormValues(
    { ...route, ...routeDirections },
    routeForm.values
  );

  const [isSavingRoute, hasSaveError, trackSaveStatus] = useRequestStatus();
  const { addWaypoint, editWaypoint, removeWaypoint } =
    getWaypointsEditorHelpers(routeForm);
  const {
    currentDirectionsFocusRef,
    directionsFocusHandler,
    directionsBlurHandler,
  } = useDelayedFocusHandler(mapReference);
  const nextMapClickTarget = getMapClickTarget(
    routeForm.values,
    currentDirectionsFocusRef.current
  );
  useSetMapAction(routeForm, setMapAction, nextMapClickTarget);
  useRemoveEmptyWaypointField(routeForm, currentDirectionsFocusRef.current);
  useWatchDirectionsChanges(routeForm, routeDirections, setRouteDirections);
  const { isAdmin } = useUserRoles();
  const featureQueueSiteImpact = useFeatureSwitch(
    FEATURE_CONFIGURE_QUEUEING_SITE_IMPACT
  );
  const isConfigureQueueingOnlyForAdmins =
    featureQueueSiteImpact === FeatureSwitchState.ON;

  const cancelEditHandler = () => {
    if (
      !hasUnsavedValues ||
      window.confirm(formatMessage(MESSAGE_CANCEL_UNSAVED_CHANGES))
    ) {
      onCancel();
    }
  };
  const addWaypointLabel = (
    <FormattedMessage
      defaultMessage="+ Add waypoint"
      id="RvtIQA"
      description="create route form - add waypoint button label"
    />
  );
  const routePointFieldPlaceholderMessage = formatMessage({
    defaultMessage: 'Click the map to select location',
    id: 'C4pu0R',
    description: 'route builder - point field placeholder',
  });
  const canAddWaypoints =
    routeDirections.startPoint &&
    routeDirections.endPoint &&
    !route.monitorStarted;
  const disabledTooltip = route.monitorStarted
    ? formatMessage(MESSAGE_UI_DISABLED_HAS_COLLECTED_DATA)
    : undefined;

  usePrompt({
    message: formatMessage(MESSAGE_BROWSE_AWAY_UNSAVED_CHANGES),
    when: hasUnsavedValues,
  });
  return (
    <div className="routes-editor-route-panel">
      <h3 className="routes-editor-route-panel__title">
        {!route.routeId ? (
          <FormattedMessage
            defaultMessage="Create a route"
            id="1BwH+a"
            description="create route form title"
          />
        ) : (
          <FormattedMessage
            defaultMessage="Editing {routeName}"
            id="ZxZNA0"
            description="edit route form title"
            values={{ routeName: route.name }}
          />
        )}
      </h3>
      <h4 className="routes-editor-route-panel__section-title">
        <FormattedMessage
          defaultMessage="Route details"
          id="vg1dDt"
          description="create route form - details section title"
        />
      </h4>
      <FormField
        label={formatMessage({
          defaultMessage: 'Route name',
          id: 'ISt8g+',
          description: 'Route name input label',
        })}
        value={routeForm.fields.name.value}
        onChange={routeForm.fields.name.onChange}
        error={routeFormValidation.fields.name.error}
      />
      <FormField
        label={formatMessage({
          defaultMessage: 'Route description',
          id: 'IzRffW',
          description: 'Route description input label',
        })}
        value={routeForm.fields.description.value}
        onChange={routeForm.fields.description.onChange}
        error={routeFormValidation.fields.description.error}
        maxLength={500}
        multiline
      />
      <h4 className="routes-editor-route-panel__section-title">
        <FormattedMessage
          defaultMessage="The route"
          id="5bsVYv"
          description="create route form - coordinates section title"
        />
      </h4>
      <div className="routes-editor-route-panel__directions">
        <div className="routes-editor-route-panel__directions-step">
          <div className="routes-editor-route-panel__directions-step-marker">
            <DirectionsPin
              label="A"
              className="routes-editor-route-panel__directions-pin"
            />
          </div>
          <div
            className={classnames({
              'routes-editor-route-panel__directions-step-field': true,
              'routes-editor-route-panel__directions-step-field--active':
                nextMapClickTarget === 'start',
            })}
          >
            <RoutePointField
              fieldProps={{
                placeholder:
                  nextMapClickTarget === 'start'
                    ? routePointFieldPlaceholderMessage
                    : undefined,
                disabled: isFetchingDirections || route.monitorStarted,
                tooltip: disabledTooltip,
                error: routeFormValidation.fields.startPoint.error,
                onFocus: directionsFocusHandler('start'),
                onBlur: directionsBlurHandler('start'),
              }}
              value={routeForm.fields.startPoint.value}
              onChange={(newValue) => {
                if (isValidLatLngString(newValue)) {
                  routeForm.fields.startPoint.setValue(newValue);
                }
              }}
            />
          </div>
        </div>
        {canAddWaypoints && (
          <button
            type="button"
            className="routes-editor-route-panel__directions-add"
            onClick={() => addWaypoint(0)}
            disabled={
              isFetchingDirections ||
              routeForm.values.waypoints.length >= MAX_WAYPOINTS
            }
          >
            {addWaypointLabel}
          </button>
        )}
        {routeForm.fields.waypoints.value.map(
          (wpField: WaypointField, wpFieldIndex: number) => (
            <div key={wpField.key}>
              <div className="routes-editor-route-panel__directions-step">
                <div className="routes-editor-route-panel__directions-step-marker">
                  <span className="routes-editor-route-panel__directions-waypoint" />
                </div>
                <div
                  className={classnames({
                    'routes-editor-route-panel__directions-step-field': true,
                    'routes-editor-route-panel__directions-step-field--active':
                      nextMapClickTarget === wpFieldIndex,
                  })}
                >
                  <RoutePointField
                    fieldProps={{
                      placeholder:
                        nextMapClickTarget === wpFieldIndex
                          ? routePointFieldPlaceholderMessage
                          : undefined,
                      disabled: isFetchingDirections || route.monitorStarted,
                      tooltip: disabledTooltip,
                      autoFocus: wpField.value === '',
                      onFocus: directionsFocusHandler(wpFieldIndex),
                      onBlur: directionsBlurHandler(wpFieldIndex),
                    }}
                    value={wpField.value}
                    onChange={(newValue) => {
                      if (isValidLatLngString(newValue)) {
                        editWaypoint(wpFieldIndex, newValue);
                      }
                    }}
                    onPointRemoved={() => {
                      removeWaypoint(wpFieldIndex);
                    }}
                  />
                </div>
              </div>
              {canAddWaypoints && (
                <button
                  type="button"
                  className="routes-editor-route-panel__directions-add"
                  onClick={() => addWaypoint(wpFieldIndex + 1)}
                  disabled={
                    isFetchingDirections ||
                    routeForm.values.waypoints.length >= MAX_WAYPOINTS
                  }
                >
                  {addWaypointLabel}
                </button>
              )}
            </div>
          )
        )}
        <div className="routes-editor-route-panel__directions-step">
          <div className="routes-editor-route-panel__directions-step-marker">
            <DirectionsPin
              label="B"
              className="routes-editor-route-panel__directions-pin"
            />
          </div>
          <div
            className={classnames({
              'routes-editor-route-panel__directions-step-field': true,
              'routes-editor-route-panel__directions-step-field--active':
                nextMapClickTarget === 'end',
            })}
          >
            <RoutePointField
              fieldProps={{
                placeholder:
                  nextMapClickTarget === 'end'
                    ? routePointFieldPlaceholderMessage
                    : undefined,
                disabled: isFetchingDirections || route.monitorStarted,
                tooltip: disabledTooltip,
                error: routeFormValidation.fields.endPoint.error,
                onFocus: directionsFocusHandler('end'),
                onBlur: directionsBlurHandler('end'),
              }}
              value={routeForm.fields.endPoint.value}
              onChange={(newValue) => {
                if (isValidLatLngString(newValue)) {
                  routeForm.fields.endPoint.setValue(newValue);
                }
              }}
            />
          </div>
        </div>
      </div>

      <div className="routes-editor-route-panel__feedback">
        {isFetchingDirections ? (
          <Spinner />
        ) : (
          <div>
            {hasDirectionsError ? (
              <div className="routes-editor-route-panel__route-error">
                <FormattedMessage
                  defaultMessage="No routes have been found. Try moving the markers on the map to find a route."
                  id="YnN2VW"
                  description="Error message for when a route couldn't be found between points."
                />
              </div>
            ) : (
              <div className="routes-editor-route-panel__length">
                <FormattedMessage
                  defaultMessage="Route total length:"
                  id="cmbLH1"
                  description="create route form - route length label"
                />{' '}
                {routeLength}m
              </div>
            )}
          </div>
        )}
      </div>

      <h4 className="routes-editor-route-panel__section-title">Route KPIs</h4>
      <Text fontSize="xs" mb={2}>
        <FormattedMessage
          defaultMessage="Determine what kind of monitoring will take place along this route."
          id="p3zKoY"
          description="create route form - KPIs section intro text."
        />
      </Text>
      <FormControl display="flex" pt={2}>
        <Switch
          size="sm"
          colorScheme="green"
          isChecked={routeForm.fields.siteImpact.value}
          onChange={routeForm.fields.siteImpact.onChange}
          disabled
        />
        <FormLabel margin={0} pl={1}>
          <Text as="h3" fontSize="xs">
            <FormattedMessage
              defaultMessage="Site impact"
              id="wawcLv"
              description="Site impact checkbox label"
            />
          </Text>
          <Text fontSize="xs" mb={0}>
            <FormattedMessage
              defaultMessage="Delay compared to the expected travel time."
              id="PxDBuy"
              description="Delay compared to the expected travel time"
            />
          </Text>
          <Text fontSize="xs" mb={0}>
            {routeForm.fields.siteImpact.value ? (
              <FormattedMessage
                defaultMessage="To disable site impact monitoring"
                id="EDSE2w"
                description="To disable site impact monitoring"
              />
            ) : (
              <FormattedMessage
                defaultMessage="To enable site impact monitoring"
                id="V8Pg9w"
                description="To enable site impact monitoring"
              />
            )}
          </Text>
          <Link
            display="block"
            fontSize="xs"
            href="mailto:rkrctuz0@mooven.intercom-mail.com"
            id="intercom-activator"
          >
            <FormattedMessage
              defaultMessage="contact Mooven"
              id="Gsw2Yx"
              description="contact Mooven"
            />
          </Link>
        </FormLabel>
      </FormControl>
      <FormControl
        display="flex"
        pt={2}
        pb={4}
        isInvalid={!!routeFormValidation.fields.queuing.error}
      >
        <Switch
          size="sm"
          colorScheme="green"
          isChecked={routeForm.fields.queuing.value}
          onChange={routeForm.fields.queuing.onChange}
          disabled={isConfigureQueueingOnlyForAdmins && !isAdmin}
        />
        <FormLabel margin={0} pl={1}>
          <Text as="h3" fontSize="xs">
            <FormattedMessage
              defaultMessage="Queue length"
              id="wmvbUA"
              description="Queue length checkbox label"
            />
          </Text>
          <Text fontSize="xs" mb={0}>
            <FormattedMessage
              defaultMessage="Queue length from end of route."
              id="MmxTba"
              description="Queue length from end of route"
            />
          </Text>
          {isConfigureQueueingOnlyForAdmins && (
            <Text fontSize="xs" mb={0}>
              {routeForm.fields.queuing.value ? (
                <FormattedMessage
                  defaultMessage="To disable queue length monitoring"
                  id="tk7OVB"
                  description="To disable queue length monitoring"
                />
              ) : (
                <FormattedMessage
                  defaultMessage="To enable queue length monitoring"
                  id="fP69BD"
                  description="To enable queue length monitoring"
                />
              )}
            </Text>
          )}
          {isConfigureQueueingOnlyForAdmins && (
            <Link
              display="block"
              fontSize="xs"
              href="mailto:rkrctuz0@mooven.intercom-mail.com"
              id="intercom-activator"
            >
              <FormattedMessage
                defaultMessage="contact Mooven"
                id="Gsw2Yx"
                description="contact Mooven"
              />
            </Link>
          )}
        </FormLabel>
        {routeFormValidation.fields.queuing.error && (
          <FormErrorMessage>
            {routeFormValidation.fields.queuing.error}
          </FormErrorMessage>
        )}
      </FormControl>
      <FormActions
        onSave={() => {
          if (
            !isSavingRoute &&
            routeFormValidation.validate() &&
            !isFetchingDirections &&
            !hasDirectionsError &&
            routeDirections.startPoint &&
            routeDirections.endPoint &&
            routeWkt
          ) {
            const updatedRoute = {
              ...route,
              name: routeForm.values.name,
              description: routeForm.values.description,
              queuing: routeForm.values.queuing,
              siteImpact: routeForm.values.siteImpact,
              wkt: routeWkt,
              startPoint: routeDirections.startPoint,
              endPoint: routeDirections.endPoint,
              waypoints: routeDirections.waypoints,
            } as Route;
            trackSaveStatus(saveRoute)(updatedRoute).then(() => {
              const evt =
                route.routeId != null ? 'Route Edited' : 'Route Created';
              track(evt, {
                referrer: 'Edit Site Tab',
                route: updatedRoute,
              });
            });
          }
        }}
        saveLabel={
          route.routeId
            ? undefined
            : formatMessage({
                defaultMessage: 'Create',
                id: '2dn52a',
                description: 'create route form > create button label',
              })
        }
        onCancel={cancelEditHandler}
        isSaving={isSavingRoute}
        error={hasSaveError ? formatMessage(MESSAGE_GENERIC_ERROR) : undefined}
      />
    </div>
  );
}

function useWatchDirectionsChanges(
  routeForm: RouteForm,
  routeDirections: RouteDirections,
  setRouteDirections: (directions: RouteDirections) => void
) {
  const prevIncomingDirectionsRef = useRef(JSON.stringify(routeDirections));
  const prevLocalDirectionsRef = useRef(
    JSON.stringify(formatLocalDirections(routeForm.values))
  );
  useEffect(() => {
    const newIncomingDirectionsRef = JSON.stringify(routeDirections);
    if (prevIncomingDirectionsRef.current !== newIncomingDirectionsRef) {
      prevIncomingDirectionsRef.current = newIncomingDirectionsRef;
      const newFormValues = {
        startPoint: routeDirections.startPoint
          ? formatCoordinates(routeDirections.startPoint)
          : '',
        endPoint: routeDirections.endPoint
          ? formatCoordinates(routeDirections.endPoint)
          : '',
        waypoints: routeDirections.waypoints.map((wp, wpIndex) => ({
          key: `${Date.now()}_${wpIndex}`,
          value: formatCoordinates(wp),
        })),
      };
      prevLocalDirectionsRef.current = JSON.stringify(
        formatLocalDirections(newFormValues)
      );
      routeForm.setValues({
        ...routeForm.values,
        ...newFormValues,
      });
    } else {
      const newLocalDirectionsRef = JSON.stringify(
        formatLocalDirections(routeForm.values)
      );
      if (prevLocalDirectionsRef.current !== newLocalDirectionsRef) {
        prevLocalDirectionsRef.current = newLocalDirectionsRef;
        setRouteDirections(formatLocalDirections(routeForm.values));
      }
    }
  }, [routeDirections, routeForm, setRouteDirections]);
}

function useSetMapAction(
  routeForm: RouteForm,
  setMapAction: SetMapAction,
  nextMapClickTarget: DirectionsFormStage
) {
  const nextMapClickTargetRef = useRef<DirectionsFormStage>(null);
  const { editWaypoint } = getWaypointsEditorHelpers(routeForm);
  useEffect(() => {
    if (nextMapClickTargetRef.current !== nextMapClickTarget) {
      nextMapClickTargetRef.current = nextMapClickTarget;
      setMapAction(
        nextMapClickTarget === null
          ? undefined
          : {
              cursor: getMapCursorForTarget(nextMapClickTarget),
              click: (event: MapMouseEvent) => {
                if (!event.latLng) {
                  return;
                }
                const mapEventCoordinates = formatCoordinates(
                  event.latLng.toJSON()
                );
                if (nextMapClickTarget === 'start') {
                  routeForm.fields.startPoint.setValue(mapEventCoordinates);
                } else if (nextMapClickTarget === 'end') {
                  routeForm.fields.endPoint.setValue(mapEventCoordinates);
                } else if (typeof nextMapClickTarget === 'number') {
                  editWaypoint(nextMapClickTarget, mapEventCoordinates);
                }
              },
            }
      );
    }
  });
  return nextMapClickTarget;
}
function useDelayedFocusHandler(mapReference?: Map) {
  const [, _setFormFocusLastUpdated] = useState(0);
  const directionsFormFocusRef = useRef<DirectionsFormStage>(null);
  const setDirectionsFormFocus = (newFocus: DirectionsFormStage) => {
    directionsFormFocusRef.current = newFocus;
    _setFormFocusLastUpdated(Date.now());
  };
  const delayedDirectionsBlurHandler =
    (focusValue: DirectionsFormStage) => () => {
      if (directionsFormFocusRef.current === focusValue) {
        setDirectionsFormFocus(null);
      }
    };
  const isMapBeingClickedRef = useWatchMapClick(mapReference);
  const directionsBlurHandler = (focusValue: DirectionsFormStage) => () => {
    if (!isMapBeingClickedRef.current) {
      setTimeout(delayedDirectionsBlurHandler(focusValue));
    } else {
      const mapDiv = mapReference?.getDiv();
      if (!mapDiv) {
        return;
      }
      const blurAfterMapClick = () => {
        setTimeout(delayedDirectionsBlurHandler(focusValue));
        mapDiv.removeEventListener('mouseup', blurAfterMapClick);
      };
      mapDiv.addEventListener('mouseup', blurAfterMapClick);
    }
  };
  const directionsFocusHandler = (focusValue: DirectionsFormStage) => () => {
    setDirectionsFormFocus(focusValue);
  };

  return {
    currentDirectionsFocusRef: directionsFormFocusRef,
    directionsFocusHandler,
    directionsBlurHandler,
  };
}
function useWatchMapClick(mapReference?: Map) {
  const mapBeingClickedRef = useRef(false);
  const mouseDownListener = useCallback(() => {
    mapBeingClickedRef.current = true;
  }, []);
  const mouseUpListener = useCallback(() => {
    mapBeingClickedRef.current = false;
  }, []);
  const mapDivRef = useRef<HTMLElement>(document.createElement('div'));
  useEffect(() => {
    if (mapReference) {
      const oldRef = mapDivRef.current;
      mapDivRef.current = mapReference.getDiv();
      mapDivRef.current.addEventListener('mousedown', mouseDownListener);
      mapDivRef.current.addEventListener('mouseup', mouseUpListener);
      return () => {
        oldRef.removeEventListener('mousedown', mouseDownListener);
        oldRef.removeEventListener('mouseup', mouseUpListener);
      };
    }
    return () => {};
  }, [mapReference, mouseDownListener, mouseUpListener]);
  return mapBeingClickedRef;
}

function useRemoveEmptyWaypointField(
  routeForm: RouteForm,
  currentDirectionsFocus: DirectionsFormStage
) {
  const prevDirectionsFocusRef = useRef<DirectionsFormStage>(null);
  const { removeWaypoint } = getWaypointsEditorHelpers(routeForm);
  useEffect(() => {
    if (prevDirectionsFocusRef.current !== currentDirectionsFocus) {
      if (
        typeof prevDirectionsFocusRef.current === 'number' &&
        routeForm.values.waypoints[prevDirectionsFocusRef.current] &&
        routeForm.values.waypoints[prevDirectionsFocusRef.current].value === ''
      ) {
        removeWaypoint(prevDirectionsFocusRef.current);
      }
      prevDirectionsFocusRef.current = currentDirectionsFocus;
    }
  });
}

function getWaypointsEditorHelpers(routeForm: RouteForm) {
  return {
    addWaypoint(wpIndex: number) {
      const itemId = `${Date.now()}_${wpIndex}`;
      routeForm.fields.waypoints.setValue(
        insertArrayItem(
          routeForm.fields.waypoints.value,
          {
            key: itemId,
            value: '',
          },
          wpIndex
        )
      );
    },
    editWaypoint(wpIndex: number, newValue: string) {
      routeForm.fields.waypoints.setValue(
        updateArrayItem(
          routeForm.fields.waypoints.value,
          {
            key: routeForm.fields.waypoints.value[wpIndex].key,
            value: newValue,
          },
          wpIndex
        )
      );
    },
    removeWaypoint(wpIndex: number) {
      routeForm.fields.waypoints.setValue(
        removeArrayItem(routeForm.fields.waypoints.value, wpIndex)
      );
    },
  };
}

function formatLocalDirections(formValues: DirectionsFormValues) {
  return {
    startPoint:
      formValues.startPoint === ''
        ? undefined
        : parseCoordinates(formValues.startPoint),
    endPoint:
      formValues.endPoint === ''
        ? undefined
        : parseCoordinates(formValues.endPoint),
    waypoints: formValues.waypoints
      .filter((wpField: WaypointField) => wpField.value !== '')
      .map((wpField: WaypointField) => parseCoordinates(wpField.value)),
  };
}
function getMapClickTarget(
  formValues: DirectionsFormValues,
  formFocus: DirectionsFormStage
): DirectionsFormStage {
  if (!formFocus) {
    if (formValues.startPoint === '') {
      return 'start';
    }
    if (formValues.endPoint === '') {
      return 'end';
    }
  }
  return formFocus;
}

function getMapCursorForTarget(
  targetAction: Exclude<DirectionsFormStage, null>
): string {
  if (targetAction === 'start') {
    return `url(${cursorStart}) 9 29, auto`;
  }
  if (targetAction === 'end') {
    return `url(${cursorEnd}) 9 29, auto`;
  }
  return `url(${cursorWaypoint}) 8 25, auto`;
}

function extractFormValuesFromRoute(
  route: Route | BlankRoute
): RouteFormValues {
  return {
    name: route.name,
    description: route.description || '',
    startPoint: route.startPoint ? formatCoordinates(route.startPoint) : '',
    endPoint: route.endPoint ? formatCoordinates(route.endPoint) : '',
    waypoints: route.waypoints.map((wp, wpIndex) => ({
      key: `${Date.now()}_${wpIndex}`,
      value: formatCoordinates(wp),
    })),
    queuing: route.queuing,
    siteImpact: route.siteImpact,
  };
}
function isRouteDifferentThanFormValues(
  route: Route | BlankRoute,
  formValues: RouteFormValues
) {
  const routeValues = extractFormValuesFromRoute(route);
  return Object.keys(formValues).some((key: keyof RouteFormValues) => {
    if (!(formValues[key] instanceof Array)) {
      return formValues[key] !== routeValues[key];
    }
    return (
      (formValues[key] as Array<any>).length !==
        (routeValues[key] as Array<any>).length ||
      (formValues[key] as Array<any>).some((item, itemIndex) => {
        if (
          isWaypointField(item) &&
          isWaypointField((routeValues[key] as Array<any>)[itemIndex])
        ) {
          return (
            item.value !== (routeValues[key] as Array<any>)[itemIndex].value
          );
        }
        return item !== (routeValues[key] as Array<any>)[itemIndex];
      })
    );
  });
}

function RoutePointField({
  value,
  onChange,
  fieldProps,
  onPointRemoved,
}: {
  onPointRemoved?: () => void;

  fieldProps?: Omit<FormFieldProps, 'value' | 'options'>;
  value?: FormFieldProps['value'];
  onChange: (value: string) => void;
}) {
  return (
    <Flex gap={2} maxWidth="100%">
      <Box
        flex="1 1 auto"
        position="relative"
        paddingRight={onPointRemoved && '15px'}
      >
        <DelayedInputField
          fieldProps={fieldProps}
          value={value}
          onDelayedChange={onChange}
        />
        {onPointRemoved && (
          <button
            type="button"
            onClick={onPointRemoved}
            className="routes-editor-route-panel__remove-waypoint-btn"
            disabled={fieldProps?.disabled}
          >
            X
          </button>
        )}
      </Box>
      <Box flex="0 0 80px" paddingTop="5px" overflow="auto">
        <RoadNameForPoint pointString={value} />
      </Box>
    </Flex>
  );
}
