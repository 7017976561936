import { envConfig } from '../../config/envConfig';
import { fetchWithError } from '../../utils/fetch';
import { INVALID, OnboardStatus, PhoneNumber } from './constants';

const SET_PHONE_NUMBER_URI = `${envConfig.API_URL}/api/v1/onboard/setPhoneNumber`;

export default async function setPhoneNumber(
  phoneNumber: PhoneNumber,
  token?: string
) {
  const headers = new Headers();
  if (token) {
    headers.append('OnboardAuthorization', `Bearer ${token}`);
  }
  const response = await fetchWithError<OnboardStatus>(SET_PHONE_NUMBER_URI, {
    method: 'POST',
    body: JSON.stringify({ phoneNumber }),
    headers,
  });

  if (!response) {
    return INVALID;
  }

  return response;
}
