import SiteAccessSettingModal from 'design-system/organisms/site-access-settings-modal';
import UserSiteAccessSettings from './UserSiteAccessSettings';

export interface EditSiteUsersPopupProps {
  insightId: number;
  siteId: number;
  isOpen: boolean;
  onClose: () => void;
}

function EditSiteUsersPopup({
  insightId,
  siteId,
  isOpen = false,
  onClose,
}: EditSiteUsersPopupProps) {
  return (
    <SiteAccessSettingModal isOpen={isOpen} onClose={onClose}>
      <UserSiteAccessSettings orgId={insightId} siteId={siteId} />
    </SiteAccessSettingModal>
  );
}

export default EditSiteUsersPopup;
