import { useIntl } from 'react-intl';
import { SiteUnitType } from '../../types/units';
import { components } from '../../types/schema';
import { Annotation } from '../../types/annotations';
import pointNowUrl from '../../images/chart-point-now.svg?inline';
import {
  ChartType,
  InsightChartSeries,
  getChartConfigFromChartType,
} from '../../utils/insightCharts';
import InsightChart from '../InsightChart';
import { drawImageToValuePosition } from '../../utils/highcharts';

type SeriesItem = [number, number | undefined, number | undefined];

export default function RouteInsightChart({
  routeName,
  siteUnit,
  timeZone,
  routeInsight,
  annotations,
  isChartModePercentage = false,
}: {
  routeName: string;
  siteUnit: SiteUnitType;
  timeZone: string;
  routeInsight: components['schemas']['InsightData'];
  annotations?: Annotation[];
  isChartModePercentage?: boolean;
}) {
  const { formatMessage, formatNumber } = useIntl();
  const baseChartConfig = getChartConfigFromChartType(
    routeInsight.insightType,
    {
      siteUnit,
      delayKPI: routeInsight.kpi,
      queueKPI: routeInsight.kpi,
      speedKPI: routeInsight.kpi,
    },
    formatMessage
  );
  const chartConfig = isChartModePercentage
    ? {
        chartType: 'line' as ChartType,
        title: baseChartConfig.title,
        dataAxisTitle: formatMessage({
          defaultMessage: '% (against 4 week average)',
          id: 'rQqv+4',
          description: 'percentage insight chart, data axis title',
        }),
        formatValue: (value: number) => {
          const percentage = formatNumber(value, { style: 'percent' });
          if (value > 0) {
            return `+${percentage}`;
          }
          return percentage;
        },
        dataAxisPlotBands: [
          { from: -10, to: 0.05, color: '#D0E5F8' },
          { from: 0.05, to: 0.3, color: '#FDEAD5' },
          { from: 0.3, to: 0.55, color: '#FDD9D9' },
          { from: 0.55, to: 10, color: '#D8C5C6' },
        ],
        zoomingType: baseChartConfig.zoomingType,
      }
    : baseChartConfig;
  const formattedRouteData = routeInsight.items.map(
    isChartModePercentage ? formatRouteDataPercentage : formatRouteData
  );

  const lastLiveDataPoint = getLastValidDataPoint(formattedRouteData);
  const onChartRender = (chartInstance: Highcharts.Chart) => {
    if (lastLiveDataPoint) {
      drawImageToValuePosition({
        chartInstance,
        image: {
          url: pointNowUrl,
          offsetX: -22,
          offsetY: -23,
        },
        value: {
          x: lastLiveDataPoint[0],
          y: lastLiveDataPoint[1],
        },
      });
    }
  };

  const formattedChartSeries: InsightChartSeries[] = [
    {
      type: 'line',
      name: formatMessage({
        defaultMessage: '4 week average',
        id: 'RikQxo',
        description: 'route insight chart, comparison series name',
      }),
      data: formattedRouteData,
      keys: ['x', '_ignore_key', 'y'],
      color: '#D4DBE2',
      dashStyle: 'ShortDash',
      showInLegend: !isChartModePercentage,
      custom: { hideFromTooltip: isChartModePercentage },
    },
    {
      type: 'line',
      name: routeName,
      data: formattedRouteData,
      keys: ['x', 'y', isChartModePercentage ? '_ignore_key' : 'compareValue'],
      color: '#4299E1',
    },
  ];

  return (
    <InsightChart
      timeZone={timeZone}
      chartConfig={chartConfig}
      chartSeries={formattedChartSeries}
      startTime={routeInsight.startTime * 1000}
      endTime={routeInsight.endTime * 1000}
      annotations={annotations}
      showContextMenu={false}
      customChartRender={onChartRender}
    />
  );
}

function getLastValidDataPoint(
  data: SeriesItem[]
): [number, number] | undefined {
  for (let i = data.length - 1; i > 0; i -= 1) {
    const dataPoint = data[i];
    if (dataPoint[1] !== null && dataPoint[1] !== undefined) {
      return [dataPoint[0], dataPoint[1]];
    }
  }
  return undefined;
}

function formatRouteData(
  item: components['schemas']['InsightData']['items'][number]
): SeriesItem {
  return [item.date * 1000, item.value, item.compareValue];
}

function formatRouteDataPercentage(
  item: components['schemas']['InsightData']['items'][number]
): SeriesItem {
  if (
    item.value === null ||
    item.value === undefined ||
    item.value === 0 ||
    item.compareValue === null ||
    item.compareValue === undefined ||
    item.compareValue === 0
  ) {
    return [item.date * 1000, undefined, undefined];
  }
  const percentageDiff = item.value / item.compareValue - 1;
  return [item.date * 1000, percentageDiff, 0];
}
