import { VehicleCountAvailability } from '@data-pipeline/api/src/types/vehicle-count';
import { FormattedMessage, useIntl } from 'react-intl';
import './CountAvailabilityPanel.scss';
import CarIcon from '../../svg/car.svg?react';
import TruckIcon from '../../svg/truck.svg?react';
import AvailabilityChart from '../AvailabilityChart';

interface CountAvailabilityPanelProps {
  title: string;
  description: string;
  availabilityData: VehicleCountAvailability['dataAvailability'];
  startDate: number;
  endDate: number;
  timeZone: string;
}

export default function CountAvailabilityPanel({
  title,
  description,
  availabilityData,
  startDate,
  endDate,
  timeZone,
}: CountAvailabilityPanelProps) {
  const { formatNumber, formatDate } = useIntl();
  return (
    <div
      className="v2-count-availability-panel"
      data-testid="vehicle-count-availability-panel"
    >
      <div className="v2-count-availability-panel__header">
        <div className="v2-count-availability-panel__header-summary">
          <h2 className="v2-count-availability-panel__title">{title}</h2>
          <div className="v2-count-availability-panel__period">
            {description}
          </div>
        </div>
      </div>
      {availabilityData!.directions &&
        availabilityData!.directions.length > 0 &&
        availabilityData!.directions.map((direction) => (
          <div
            key={direction.name}
            className="v2-count-availability-panel__chart"
          >
            <div className="v2-count-availability-graph__header">
              <div className="v2-count-availability-graph__header-title">
                <div className="v2-count-availability-graph__header-title-direction">
                  {direction.name}
                </div>
                <div>
                  <span className="v2-count-availability-graph__header-title-description mr-1">
                    <FormattedMessage
                      defaultMessage={`Average daily count {startDate} to {endDate}: `}
                      id="CJDSgY"
                      description="Graph header description"
                      values={{
                        startDate: formatDate(startDate, {
                          timeZone,
                        }),
                        endDate: formatDate(endDate, { timeZone }),
                      }}
                    />
                  </span>
                  <span className="v2-count-availability-graph__header-title-description-count">
                    {formatNumber(direction.averageDailyCount!)}
                  </span>
                </div>
              </div>
              {(direction.lightVehicleCount! > 0 ||
                direction.heavyVehicleCount! > 0) && (
                <div>
                  <span className="v2-count-availability-graph__average-category">
                    <span className="v2-count-availability-graph__average-category-icon">
                      <CarIcon />
                    </span>
                    {formatNumber(
                      direction.lightVehicleCount! /
                        direction.averageDailyCount!,
                      {
                        style: 'percent',
                      }
                    )}
                  </span>
                  <span className="v2-count-availability-graph__average-category">
                    <span className="v2-count-availability-graph__average-category-icon">
                      <TruckIcon />
                    </span>
                    {formatNumber(
                      direction.heavyVehicleCount! /
                        direction.averageDailyCount!,
                      {
                        style: 'percent',
                      }
                    )}
                  </span>
                </div>
              )}
            </div>
            <AvailabilityChart
              data={direction.items!}
              startDate={startDate}
              endDate={endDate}
              timeZone={timeZone}
            />
          </div>
        ))}
      <div className="v2-count-availability-panel__footer">
        <div className="v2-count-legend__dot v2-count-legend__dot--available" />
        <div className="v2-count-legend__text">
          <FormattedMessage
            defaultMessage="Available"
            id="YXOeL5"
            description="Available"
          />
        </div>
        <div className="v2-count-legend__dot v2-count-legend__dot--unavailable" />
        <div className="v2-count-legend__text">
          <FormattedMessage
            defaultMessage="Unavailable"
            id="U21RGF"
            description="UnAvailable"
          />
        </div>
        <div className="v2-count-legend__dot v2-count-legend__dot--partially" />
        <div className="v2-count-legend__text">
          <FormattedMessage
            defaultMessage="Partially available"
            id="d5FBym"
            description="Partially available"
          />
        </div>
      </div>
    </div>
  );
}
