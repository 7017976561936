import { useEffect, useMemo, useState } from 'react';
import { Box, Flex, Spinner } from '@chakra-ui/react';
import { GoogleMap } from '@react-google-maps/api';
import { WorkArea } from '@webapp/bff/src/types/work-area';
import BouncingPin from 'design-system/svg/bouncing-pin.svg?react';
import {
  MAP_CONTAINER_STYLING,
  MAP_FEATURE_OPTIONS,
} from '../../constants/map';
import { MapPositionManager } from './useMapPositionManager';
import {
  WorkAreasEditorManager,
  WorkAreasEditorMapLayer,
} from '../WorkAreasEditor';
import {
  DisruptionAreaEditorManager,
  DisruptionAreaEditorMapLayer,
} from '../DisruptionAreaEditor';
import { RoutesEditorManager, RoutesEditorMapLayer } from '../RoutesEditor';
import { Route } from '../../types/route';

export default function CreateSiteMap({
  mapPositionManager,
  showLocationPin,
  workAreas,
  workAreasEditorManager,
  disruptionAreaEditorManager,
  routes,
  routesEditorManager,
  isEditingDisruptionArea,
  showSatelliteImage,
}: {
  mapPositionManager: MapPositionManager;
  showLocationPin: boolean;
  workAreas: WorkArea[];
  workAreasEditorManager: WorkAreasEditorManager;
  disruptionAreaEditorManager: DisruptionAreaEditorManager;
  routes: Route[];
  routesEditorManager: RoutesEditorManager;
  isEditingDisruptionArea: boolean;
  showSatelliteImage: boolean;
}) {
  const { mapLoadHandler, mapCenterChangeHandler, mapCenter } =
    mapPositionManager;
  const {
    mapReference,
    mapProps: {
      options: routesEditorMapOptions,
      onLoad: routesEditorMapLoadHandler,
      onClick: routesEditorMapClickHandler,
    },
  } = routesEditorManager;
  const [mapTypeId, setMapTypeId] = useState<string>();
  useEffect(() => {
    setMapTypeId(
      showSatelliteImage
        ? google.maps.MapTypeId.HYBRID
        : google.maps.MapTypeId.ROADMAP
    );
  }, [showSatelliteImage]);
  const mapOptions = useMemo(
    () => ({
      ...MAP_FEATURE_OPTIONS,
      mapTypeId,
      ...routesEditorMapOptions,
    }),
    [mapTypeId, routesEditorMapOptions]
  );
  return mapCenter ? (
    <Box width="100%" height="100%" position="relative">
      {showLocationPin && (
        <Box
          pointerEvents="none"
          position="absolute"
          left="50%"
          top="50%"
          width="56px"
          marginLeft="-28px"
          marginTop="-52px"
          zIndex="1"
        >
          <BouncingPin />
        </Box>
      )}
      <GoogleMap
        onLoad={(map) => {
          mapLoadHandler(map);
          routesEditorMapLoadHandler(map);
        }}
        mapContainerStyle={MAP_CONTAINER_STYLING}
        options={mapOptions}
        center={mapCenter}
        onCenterChanged={mapCenterChangeHandler}
        clickableIcons={false}
        zoom={14}
        onClick={routesEditorMapClickHandler}
        onMapTypeIdChanged={() => {
          const newTypeId = mapReference?.getMapTypeId();
          if (newTypeId) {
            setMapTypeId(newTypeId);
          }
        }}
      >
        <WorkAreasEditorMapLayer
          workAreas={workAreas}
          workAreasEditorManager={workAreasEditorManager}
        />
        {isEditingDisruptionArea && (
          <DisruptionAreaEditorMapLayer
            disruptionAreaEditorManager={disruptionAreaEditorManager}
          />
        )}

        <RoutesEditorMapLayer
          routes={routes}
          routesEditorManager={routesEditorManager}
        />
      </GoogleMap>
    </Box>
  ) : (
    <Flex
      width="100%"
      height="100%"
      alignItems="center"
      justifyContent="center"
      p={10}
    >
      <Spinner variant="mooven" size="xl" />
    </Flex>
  );
}
