import { FormattedMessage, useIntl } from 'react-intl';
import { Box, Heading, VStack } from '@chakra-ui/react';
import {
  RoutePollingStatusFailureType,
  RoutePollingStatusDataResponded,
} from '../../data/useRoutePollingStatusData';
import RouteMap from './RouteMap';
import EditableQuote from './EditableQuote';
import OtherCauses from './OtherCauses';
import MoreDetailsForm from './MoreDetailsForm';
import {
  TextQuoteTitle,
  TextExpectedOutcome,
  TextFailureStatus,
} from './TextContent';
import { DATE_PRESET_RECENT_MONTH } from '../../utils/dateUtils';

export default function ExistingResponseState({
  pollingStatus,
  setNewResponse,
}: {
  pollingStatus: RoutePollingStatusDataResponded;
  setNewResponse: (newResponse: {
    responseType: RoutePollingStatusFailureType | 'OTHER';
    responseComment?: string;
  }) => void;
}) {
  const {
    userResponse,
    userResponse: { responseDate, userName, responseType, responseComment },
  } = pollingStatus.failure;
  const { formatDate } = useIntl();
  return (
    <VStack alignItems="flex-start" spacing="20">
      <VStack alignItems="flex-start" spacing="4">
        <Heading size="2xl">
          <FormattedMessage id="PUdrnY" defaultMessage="Route status" />
        </Heading>
        <RouteMap route={pollingStatus.route} />
        <Box fontSize="2xl">
          <TextFailureStatus pollingStatus={pollingStatus} />
        </Box>
      </VStack>
      <EditableQuote
        title={
          <FormattedMessage
            id="MmNCjb"
            defaultMessage="As informed by <strong>{userName}</strong> on <strong>{date}</strong>"
            values={{
              userName,
              date: formatDate(responseDate, {
                timeZone: pollingStatus.site.siteTimeZone,
                ...DATE_PRESET_RECENT_MONTH,
              }),
            }}
          />
        }
        quoteTitle={<TextQuoteTitle pollingStatus={pollingStatus} />}
        quoteContent={responseComment}
        renderEditComponent={({ finishEditing }) => (
          <MoreDetailsForm
            currentResponse={userResponse}
            setNewResponse={setNewResponse}
            cancelEditing={finishEditing}
          />
        )}
      />
      <TextExpectedOutcome pollingStatus={pollingStatus} />
      <OtherCauses
        currentResponseType={responseType}
        setNewResponse={setNewResponse}
      />
    </VStack>
  );
}
