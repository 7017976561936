import './EditSiteUsers.scss';
import { useState } from 'react';
import InfoTooltip from 'design-system/atoms/info-tooltip';
import { useIntl } from 'react-intl';
import { VStack } from '@chakra-ui/react';
import { MESSAGE_BUTTON_ADD_MANAGE_USERS } from '../constants/messages';
import useParamInsightId from '../hooks/useParamInsightId';
import useCurrentUserData from '../data/useCurrentUserData';
import EditSiteUsersPopup from './EditSiteUsersPopup';
import useTeamData from '../data/useTeamData';
import UserBadge from '../local_design_system/organisms/SiteAccessSettings/UserBadge';

export interface EditSiteUserContainerProps {
  siteId: number;
  // TODO:
  // eslint-disable-next-line react/no-unused-prop-types
  siteHasQueue: boolean;
  isUserReadOnly: boolean;
}

export default function EditSiteUsers({
  siteId,
  isUserReadOnly,
}: EditSiteUserContainerProps) {
  const { formatMessage } = useIntl();
  const [showPopup, setShowPopup] = useState<boolean>(false);
  const insightId = useParamInsightId();
  const { data: currentUser } = useCurrentUserData();
  const { data: teamData } = useTeamData(insightId);

  if (!teamData) {
    throw new Error('Rendering should have been suspended by data handler');
  }

  const activeSiteUsers = teamData.users.filter((teamUser) => {
    if (teamUser.pending) {
      return false;
    }
    if (teamUser.teamRole === 'Editor' || teamUser.teamRole === 'Viewer') {
      return true;
    }
    const userSiteSettings = teamUser.sites.find(
      (_userSiteSettings) => _userSiteSettings.siteId === siteId.toString()
    );
    return !!(userSiteSettings && userSiteSettings.siteRole !== 'None');
  });

  return (
    <div>
      <VStack spacing={2} alignItems="left">
        {activeSiteUsers.map((user) => (
          <UserBadge
            key={user.userId}
            name={user.name}
            isCurrentUser={currentUser?.userId === user.userId}
          />
        ))}
      </VStack>
      <InfoTooltip
        content={
          isUserReadOnly
            ? formatMessage({
                defaultMessage:
                  'You don’t have permission to manage users who have access to this site.',
                id: 'XVCOcM',
                description:
                  'Message from disabled "manage users" button for read only users.',
              })
            : undefined
        }
      >
        <button
          type="button"
          className="site-users__btn-manage-users"
          onClick={() => setShowPopup(true)}
          disabled={isUserReadOnly}
        >
          {formatMessage(MESSAGE_BUTTON_ADD_MANAGE_USERS)}
        </button>
      </InfoTooltip>
      {showPopup ? (
        <EditSiteUsersPopup
          insightId={Number(insightId)}
          isOpen={showPopup}
          siteId={siteId}
          onClose={() => setShowPopup(false)}
        />
      ) : null}
    </div>
  );
}
