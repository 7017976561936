import { defineMessage } from 'react-intl';
import { PlanInsightType } from '../constants/path';
import { Site } from '../types/site';
import { getChartConfigFromChartType } from './insightCharts';
import { IMPERIAL_TO_METRIC_FACTOR } from '../constants/clientConstants';
import { errorReport } from './errors';
import { KpiBreachType } from '../types/plan';
import {
  MESSAGE_PLAN_INSIGHT_TYPE_DELAY,
  MESSAGE_PLAN_INSIGHT_TYPE_IMPACT,
  MESSAGE_PLAN_INSIGHT_TYPE_SPEED,
  MESSAGE_PLAN_INSIGHT_TYPE_TIME,
  MESSAGE_PLAN_INSIGHT_TYPE_VEHICLE_COUNT,
} from '../constants/messages';

export interface PlanChartConfig {
  dataAxisTitle: string;
  dataAxisInterval?: number;
  formatValue?: (value: number) => string;
  kpiBreach?: KpiBreachType;
  kpiDisplayWriteRatio: number;
  kpiTitle?: string;
  kpiUnitLabel?: string;
}

const kpiUnitMinutes = defineMessage({
  defaultMessage: 'minutes',
  id: 'V3U2Mt',
  description: 'KPI unit label for minutes',
});

export function getPlanChartConfigFromType(
  insightType: PlanInsightType,
  siteData: Partial<
    Pick<Site, 'siteUnit' | 'delayKPI' | 'queueKPI' | 'speedKPI'>
  >,
  formatMessage
): PlanChartConfig {
  switch (insightType) {
    case PlanInsightType.DELAY:
      return {
        ...getChartConfigFromChartType('TIME_DELAY', siteData, formatMessage),
        kpiBreach: KpiBreachType.OVER,
        kpiDisplayWriteRatio: 60,
        kpiTitle: formatMessage({
          defaultMessage: 'Delay KPI',
          id: 'QYKD8v',
          description: 'Time delay, plan chart kpi label',
        }),
        kpiUnitLabel: formatMessage(kpiUnitMinutes),
      };
    case PlanInsightType.TIME:
      return {
        ...getChartConfigFromChartType('JOURNEY_TIME', siteData, formatMessage),
        kpiBreach: KpiBreachType.OVER,
        kpiDisplayWriteRatio: 60,
        kpiTitle: formatMessage({
          defaultMessage: 'Journey Time KPI',
          id: 'Zl1BMS',
          description: 'Journey Time, plan chart kpi label',
        }),
        kpiUnitLabel: formatMessage(kpiUnitMinutes),
      };
    case PlanInsightType.IMPACT:
      return {
        ...getChartConfigFromChartType(
          'SITE_IMPACT_DELAY',
          siteData,
          formatMessage
        ),
        kpiBreach: KpiBreachType.OVER,
        kpiDisplayWriteRatio: 60,
        kpiTitle: formatMessage({
          defaultMessage: 'Delay KPI',
          id: 't2XcB/',
          description: 'Site Impact Delay, plan chart kpi label',
        }),
        kpiUnitLabel: formatMessage(kpiUnitMinutes),
      };
    case PlanInsightType.SPEED:
      return {
        ...getChartConfigFromChartType('SPEED', siteData, formatMessage),
        kpiBreach: KpiBreachType.OVER,
        kpiDisplayWriteRatio:
          siteData.siteUnit === 'IMPERIAL' ? IMPERIAL_TO_METRIC_FACTOR : 1,
        kpiTitle: formatMessage({
          defaultMessage: 'Speed KPI',
          id: 'oY4Sx2',
          description: 'Speed, plan chart kpi label',
        }),
        kpiUnitLabel: formatMessage(
          {
            defaultMessage: '{unitType, select, IMPERIAL {mph} other {kmph}}',
            id: 'PpvHk9',
          },
          {
            unitType: siteData.siteUnit === 'IMPERIAL' ? 'IMPERIAL' : 'METRIC',
          }
        ),
      };
    default:
      errorReport.handled(
        new Error(`Failed to parse unsupported plan chart type: ${insightType}`)
      );
      return {
        dataAxisTitle: formatMessage({
          defaultMessage: 'Unknown data type',
          id: 'yzwtpG',
          description: 'Unknown chart yaxis label',
        }),
        kpiDisplayWriteRatio: 1,
      };
  }
}

export const getLabelByInsightType = (
  insightType: PlanInsightType,
  formatMessage
): string => {
  switch (insightType) {
    case PlanInsightType.DELAY:
      return formatMessage(MESSAGE_PLAN_INSIGHT_TYPE_DELAY);
    case PlanInsightType.SPEED:
      return formatMessage(MESSAGE_PLAN_INSIGHT_TYPE_SPEED);
    case PlanInsightType.COUNT:
      return formatMessage(MESSAGE_PLAN_INSIGHT_TYPE_VEHICLE_COUNT);
    case PlanInsightType.TIME:
      return formatMessage(MESSAGE_PLAN_INSIGHT_TYPE_TIME);
    case PlanInsightType.IMPACT:
      return formatMessage(MESSAGE_PLAN_INSIGHT_TYPE_IMPACT);
    default:
      errorReport.handled('Unknown insight type', { critical: true });
      return insightType;
  }
};
