import { Heading, Text } from '@chakra-ui/react';
import { FormattedMessage, useIntl } from 'react-intl';
import UserContextMenu from '../../local_design_system/organisms/SiteAccessSettings/UserContextMenu';
import RoleMenu from '../../local_design_system/organisms/SiteAccessSettings/RoleMenu';
import UserActionsMenu from '../../local_design_system/organisms/SiteAccessSettings/UserActionsMenu';
import UsersList from '../../local_design_system/organisms/SiteAccessSettings/UsersList';
import Alerts from '../../local_design_system/organisms/SiteAccessSettings/Alerts';
import SiteAlertsMenu from '../../local_design_system/organisms/SiteAccessSettings/SiteAlertsMenu';
import StatusGroupsLayout from '../../local_design_system/organisms/SiteAccessSettings/StatusGroupsLayout';
import useCurrentUserData from '../../data/useCurrentUserData';
import useSiteAccessSettings from '../../data/useSiteAccessSettings';
import { canUserEditSite, getUserAlertsSettings } from './utils';
import useSiteData from '../../data/useSiteData';
import useFeatureSwitch, {
  FeatureSwitchState,
} from '../../hooks/useFeatureSwitch';
import { FEATURE_SITE_IMPACT_KPI_ALERTS } from '../../constants/features';

export default function TeamMembersSiteSettings({
  orgId,
  siteId,
}: {
  orgId: number;
  siteId: number;
}) {
  const { formatMessage } = useIntl();
  const { data: currentUserData } = useCurrentUserData();
  const { siteHasQueue } = useSiteData({
    siteId: siteId.toString(),
    orgId: orgId.toString(),
  });
  const enableSiteImpactAlerts =
    useFeatureSwitch(FEATURE_SITE_IMPACT_KPI_ALERTS) === FeatureSwitchState.ON;
  const {
    authenticatedTeamEditors,
    authenticatedTeamViewers,
    pendingTeamEditors,
    pendingTeamViewers,
    siteAlertsSettings,
    updateSiteRole,
    updateSiteAlertSettings,
    sendPasswordReset,
  } = useSiteAccessSettings(orgId, siteId);
  const updateRole = ({ user, newRoleValue }) =>
    updateSiteRole(user, newRoleValue);
  const updateAlerts = ({ user, newAlertsSettings }) =>
    updateSiteAlertSettings(user, newAlertsSettings);
  const getUserAlerts = (user) => (
    <Alerts alerts={getUserAlertsSettings(user, siteAlertsSettings)} />
  );
  return (
    <StatusGroupsLayout
      activeList={
        <>
          <UsersList
            users={authenticatedTeamEditors}
            currentUserId={currentUserData?.userId}
            getUserAlerts={getUserAlerts}
            getUserContextMenu={(user) => (
              <UserContextMenu user={user.name}>
                <SiteAlertsMenu
                  user={user}
                  alerts={getUserAlertsSettings(user, siteAlertsSettings)}
                  updateAlerts={updateAlerts}
                  showQueue={siteHasQueue}
                  showSiteImpact={enableSiteImpactAlerts}
                />
                <UserActionsMenu
                  user={user}
                  sendPasswordReset={sendPasswordReset}
                />
              </UserContextMenu>
            )}
          />
          {authenticatedTeamViewers.length > 0 && (
            <>
              <Heading my={6} size="sm" color="gray.500">
                <FormattedMessage
                  id="ei/Vls"
                  defaultMessage="Team viewers"
                  description="Title for team viewers list of site users"
                />
              </Heading>
              <UsersList
                users={authenticatedTeamViewers}
                currentUserId={currentUserData?.userId}
                getUserAlerts={getUserAlerts}
                getUserInfo={(user) =>
                  canUserEditSite(user, siteId) ? (
                    <Text
                      m={0}
                      fontSize="12"
                      fontWeight="700"
                      color="green.500"
                    >
                      <FormattedMessage
                        defaultMessage="Can edit this site"
                        id="8P14dj"
                        description="User access can edit site"
                      />
                    </Text>
                  ) : null
                }
                getUserContextMenu={(user) => (
                  <UserContextMenu user={user.name}>
                    <RoleMenu
                      header={formatMessage({
                        id: 'CY844A',
                        defaultMessage: 'Role for this site',
                        description:
                          'Role menu title for site role of an org member',
                      })}
                      user={user}
                      role={canUserEditSite(user, siteId) ? 'Editor' : 'Viewer'}
                      updateRole={updateRole}
                    />
                    <SiteAlertsMenu
                      user={user}
                      alerts={getUserAlertsSettings(user, siteAlertsSettings)}
                      updateAlerts={updateAlerts}
                      showQueue={siteHasQueue}
                      showSiteImpact={enableSiteImpactAlerts}
                    />
                    <UserActionsMenu
                      user={user}
                      sendPasswordReset={sendPasswordReset}
                    />
                  </UserContextMenu>
                )}
              />
            </>
          )}
        </>
      }
      pendingList={
        <>
          {pendingTeamEditors.length > 0 && (
            <UsersList
              users={pendingTeamEditors}
              getUserContextMenu={(user) => (
                <UserContextMenu user={user.name}>
                  <SiteAlertsMenu
                    user={user}
                    alerts={getUserAlertsSettings(user, siteAlertsSettings)}
                    updateAlerts={updateAlerts}
                  />
                </UserContextMenu>
              )}
            />
          )}
          {pendingTeamViewers.length > 0 && (
            <>
              <Heading my={6} size="sm" color="gray.500">
                <FormattedMessage
                  id="ei/Vls"
                  defaultMessage="Team viewers"
                  description="Title for team viewers list of site users"
                />
              </Heading>
              <UsersList
                users={pendingTeamViewers}
                getUserContextMenu={(user) => (
                  <UserContextMenu user={user.name}>
                    <RoleMenu
                      header={formatMessage({
                        id: 'CY844A',
                        defaultMessage: 'Role for this site',
                        description:
                          'Role menu title for site role of an org member',
                      })}
                      user={user}
                      role={canUserEditSite(user, siteId) ? 'Editor' : 'Viewer'}
                      updateRole={updateRole}
                    />
                    <SiteAlertsMenu
                      user={user}
                      alerts={getUserAlertsSettings(user, siteAlertsSettings)}
                      updateAlerts={updateAlerts}
                    />
                  </UserContextMenu>
                )}
              />
            </>
          )}
        </>
      }
    />
  );
}
