import { useCallback, useEffect, useRef, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import {
  Box,
  Button,
  Flex,
  Text,
  useBreakpointValue,
  useDisclosure,
} from '@chakra-ui/react';
import { ShowIcon, HideIcon } from 'design-system/atoms/custom-icons';
import { Annotation } from '../../types/annotations';
import {
  afterChartRender,
  cancelControlMode,
  ControlPoint,
  InsightHighchartsType,
  positionPopover,
  startControlMode,
} from '../../utils/insightCharts';
import PopoverNote from './PopoverNote';

interface ChartEditableAnnotationLayerProps {
  chart: InsightHighchartsType;
  timeZone: string;
  isEditingAnnotation: boolean;
  setEditingAnnotation: (state: boolean) => void;
  showAnnotation: boolean;
  setShowAnnotation: (state: boolean) => void;
  createAnnotation: (annotation: Partial<Annotation>) => Promise<void>;
  resendAnnotationAlert: (annotationId: number) => Promise<void>;
}

export default function ChartEditableAnnotationLayer({
  chart,
  timeZone,
  isEditingAnnotation,
  setEditingAnnotation,
  showAnnotation,
  setShowAnnotation,
  createAnnotation,
  resendAnnotationAlert,
}: ChartEditableAnnotationLayerProps) {
  const [controlStatus, setControlStatus] = useState<Partial<Annotation>>({});
  const { isOpen, onOpen, onClose } = useDisclosure();
  const popoverRef = useRef<HTMLDivElement>(null);

  const cancelAddNoteControlMode = () => {
    onClose();
    cancelControlMode(chart);
    setEditingAnnotation(false);
  };

  useEffect(() => {
    if (isOpen && popoverRef.current?.clientWidth) {
      if (chart && chart.xAxis) {
        /* eslint-disable-next-line no-param-reassign */
        chart.popover = popoverRef;
        afterChartRender(chart);
      }
    }
  }, [isOpen, popoverRef, chart, chart.xAxis]);

  const updateControlStatus = useCallback(
    (controlPoint: ControlPoint) => {
      if (popoverRef.current && chart) {
        const currentLeft = positionPopover(controlPoint.start.xPos, chart);
        popoverRef.current.style.left = `${currentLeft}px`;
        setControlStatus({
          startDate: controlPoint.start.time / 1000,
          endDate: controlPoint.end ? controlPoint.end.time / 1000 : undefined,
        });
      }
    },
    [popoverRef, setControlStatus, chart]
  );

  const startAddNoteControlMode = () => {
    startControlMode(chart, updateControlStatus);
    onOpen();
    setEditingAnnotation(true);
  };
  const controlsPositioning = useBreakpointValue({
    base: {
      left: '24px',
      top: '60px',
    },
    md: {
      left: 'auto',
      right: '96px',
      top: '24px',
    },
  });
  return (
    <Box>
      <PopoverNote
        createAnnotation={createAnnotation}
        resendAnnotationAlert={resendAnnotationAlert}
        isOpen={isOpen}
        onClose={cancelAddNoteControlMode}
        timeZone={timeZone}
        annotation={controlStatus}
        insightHighchart={chart}
        updateControlStatus={updateControlStatus}
        ref={popoverRef}
      />
      <Flex justify="center" align="center" {...controlsPositioning}>
        <Text fontSize="sm" mb={0} mr={2}>
          <FormattedMessage
            defaultMessage="Annotation:"
            id="DmmCUD"
            description="Annotation label"
          />
        </Text>
        <Button
          mr={2}
          size="sm"
          variant="outline"
          leftIcon={showAnnotation ? <HideIcon /> : <ShowIcon />}
          onClick={() => setShowAnnotation(!showAnnotation)}
        >
          <FormattedMessage
            defaultMessage="{showOrHide}"
            id="MTqzt/"
            description="Show"
            values={{
              showOrHide: showAnnotation ? 'Hide' : 'Show',
            }}
          />
        </Button>

        <Button
          mr={2}
          size="sm"
          variant="outline"
          isDisabled={isEditingAnnotation}
          onClick={startAddNoteControlMode}
        >
          <FormattedMessage
            defaultMessage="Create"
            id="oO2Yk9"
            description="Create Annotation"
          />
        </Button>
      </Flex>
    </Box>
  );
}
