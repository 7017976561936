import { useMemo } from 'react';
import { useIntl } from 'react-intl';
import { DirectionItem } from '@data-pipeline/api/src/types/vehicle-count';
import moment from 'moment-timezone';
import {
  AxisTypeValue,
  DashStyleValue,
  SeriesLineOptions,
  TooltipFormatterContextObject,
} from 'highcharts';
import Chart from 'design-system/atoms/chart';
import { DataAvailabilityStatus } from '../types/vehicleCount';
import { MS_24_HOURS } from '../constants/time';

const RED = '#dd0c0c';
const YELLOW = '#e4d491';
const GREEN = '#007c49';

const availabilityChartStyle = {
  chart: {
    type: 'column',
    height: 90,
    animation: false,
  },
  title: {
    text: undefined,
  },
  subtitle: {
    text: undefined,
  },
  yAxis: {
    min: 0,
    max: 1,
    title: {
      text: null,
    },
    labels: {
      enabled: false,
    },
    gridLineColor: '#DDDDDD',
    gridLineDashStyle: 'Solid' as DashStyleValue,
    gridLineWidth: 1,
  },
  plotOptions: {
    column: {
      grouping: false,
      borderWidth: 0,
      borderRadius: 0,
      pointRange: MS_24_HOURS,
    },
  },
  legend: {
    enabled: false,
  },
  exporting: {
    enabled: false,
  },
};

export default function AvailabilityChart({
  data,
  startDate,
  endDate,
  timeZone,
}: {
  data: DirectionItem[];
  startDate: number;
  endDate: number;
  timeZone: string;
}) {
  const { formatDate } = useIntl();
  const formattedChartSeries = useMemo(
    () =>
      data.reduce(
        (series, item) => {
          const timestamp = item.date! * 1000;
          series[0].data?.push([
            timestamp,
            item.dataStatus === DataAvailabilityStatus.AVAILABLE ? 1 : 0,
          ]);
          series[1].data?.push([
            timestamp,
            item.dataStatus === DataAvailabilityStatus.UNAVAILABLE ? 1 : 0,
          ]);
          series[2].data?.push([
            timestamp,
            item.dataStatus === DataAvailabilityStatus.PARTIALLY ? 1 : 0,
          ]);
          return series;
        },
        [
          {
            name: 'available',
            data: [] as [number, number][],
            color: GREEN,
          },
          {
            name: 'unavailable',
            data: [] as [number, number][],
            color: RED,
          },
          {
            name: 'partially available',
            data: [] as [number, number][],
            color: YELLOW,
          },
        ] as SeriesLineOptions[]
      ),
    [data]
  );
  const chartOptions = useMemo(
    () => ({
      ...availabilityChartStyle,
      series: formattedChartSeries,
      time: {
        timezone: timeZone,
        moment,
      },
      xAxis: {
        type: 'datetime' as AxisTypeValue,
        softMin: startDate,
        softMax: moment(endDate).tz(timeZone).startOf('day').valueOf(),
        minTickInterval: MS_24_HOURS,
        labels: {
          formatter: (ctx) =>
            formatDate(ctx.value, {
              timeZone,
              month: 'short',
              day: 'numeric',
            }),
        },
      },
      tooltip: {
        useHTML: true,
        borderColor: '#888888',
        formatter() {
          const { points, x } =
            this as unknown as TooltipFormatterContextObject;
          const activeColor = points?.find((point) => point.y === 1)?.color;
          return `<span>
                  ${
                    activeColor
                      ? `<span style="color:${activeColor};font-size:24px;line-height:12px;vertical-align:text-top">
                      \u25CF
                    </span>`
                      : ''
                  }
                  ${formatDate(x, {
                    timeZone,
                    weekday: 'short',
                    year: 'numeric',
                    month: 'short',
                    day: 'numeric',
                  })}
              </span>`;
        },
      },
    }),
    [startDate, endDate, timeZone, formattedChartSeries, formatDate]
  );
  return <Chart options={chartOptions} />;
}
