import ConfirmationModal from 'design-system/molecules/confirmation-modal';
import { FormattedMessage, useIntl } from 'react-intl';
import { Text } from '@chakra-ui/react';

export interface DeleteBoardConfirmationModalProps {
  isOpen: boolean;
  onClose: () => void;
  onConfirm: () => Promise<void>;
}

export default function DeleteBoardConfirmationModal({
  isOpen,
  onClose,
  onConfirm,
}: DeleteBoardConfirmationModalProps) {
  const { formatMessage } = useIntl();

  return (
    <ConfirmationModal
      body={
        <Text fontSize="xs">
          <FormattedMessage
            id="kYPJgc"
            defaultMessage="Deleting this board will remove the saved connection and all messaging. It cannot be undone."
          />
        </Text>
      }
      cancelButtonText={formatMessage({
        id: 'tmKY1N',
        description: 'Delete board confirmation modal cancel text.',
        defaultMessage: 'Cancel',
      })}
      confirmButtonText={formatMessage({
        id: 'gP2RHx',
        description: 'Delete note confirmation modal confirm text.',
        defaultMessage: 'Delete board',
      })}
      confirmButtonColorScheme="red"
      open={isOpen}
      onCancel={onClose}
      onConfirm={onConfirm}
      title={formatMessage({
        id: 'jJeerw',
        description: 'Delete board confirmation modal title.',
        defaultMessage: 'Are you sure?',
      })}
    />
  );
}
