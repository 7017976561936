import { Box, Text, VStack } from '@chakra-ui/react';
import { FormattedMessage } from 'react-intl';
import bgReportsUrl from '../../images/bg-disruption-report-sample.png';

export default function DisruptionPackMainPanel() {
  return (
    <Box
      height="100%"
      overflowY="auto"
      backgroundColor="yellow.100"
      borderRadius="16px"
    >
      <Box minHeight="100%" position="relative" p="32px">
        <VStack
          alignItems="left"
          spacing={3}
          width={{ xl: '283px' }}
          maxWidth={{
            base: '60%',
            lg: '50%',
            xl: '30%',
          }}
        >
          <Text
            m={0}
            fontSize={{ base: 'md', md: '3xl' }}
            lineHeight={{ base: 6, md: 9 }}
            fontWeight="700"
          >
            <FormattedMessage
              defaultMessage="Deliver the right information to the right people."
              id="LuwGrQ"
            />
          </Text>
          <Box fontSize="xs">
            <Text mb={1}>
              <FormattedMessage
                defaultMessage="Impact assessments include a list of <strong>public transport</strong> and <strong>nearby businesses</strong> that could be disrupted by your project."
                id="AiYZS/"
              />
            </Text>
            <Text>
              <FormattedMessage
                defaultMessage="You can also request <strong>volume analysis</strong> to help you  better understand the relationship between volume and journey time on your closures and/or detours."
                id="CWc4Xm"
              />
            </Text>
          </Box>
        </VStack>
        <Box
          position="absolute"
          top="0"
          right="0"
          bottom="0"
          width={{
            base: '40%',
            lg: '50%',
            xl: '70%',
          }}
          maxHeight="1520px"
          overflow="hidden"
        >
          <Box
            backgroundImage={bgReportsUrl}
            backgroundSize="contain"
            backgroundRepeat="no-repeat"
            backgroundPosition="center top"
            position="absolute"
            top="-20px"
            right="0"
            bottom="-60px"
            width="100%"
            maxHeight="1520px"
          />
        </Box>
      </Box>
    </Box>
  );
}
