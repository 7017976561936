import useSWRInfinite from 'swr/infinite';
import { useCallback, useMemo } from 'react';
import { Annotation } from '../types/annotations';
import { envConfig } from '../config/envConfig';
import {
  createAnnotation,
  deleteAnnotation,
  updateAnnotation,
  exportAnnotationCSV,
  resendAnnotationAlert,
} from '../api/annotation';
import { Site } from '../types/site';

const PAGE_SIZE = 30;

export interface AnnotationsDataHandler {
  data: Annotation[];
  error: any;
  isValidating: boolean;
  isReachingEnd: boolean;
  size: number;
  setSize: (size: number | ((_size: number) => number)) => void;
  exportAnnotationCSV: (
    site: Pick<Site, 'siteName' | 'siteTimeZone'>,
    startTime: number,
    endTime: number
  ) => Promise<Response | null>;
  createAnnotation: (newAnnotation: Partial<Annotation>) => Promise<void>;
  updateAnnotation: (editedAnnotation: Annotation) => Promise<void>;
  deleteAnnotation: (annotationToDelete: Annotation) => Promise<void>;
  resendAnnotationAlert: (annoatationId: number) => Promise<void>;
}

export default function useAnnotationsData(
  insightId,
  siteId
): AnnotationsDataHandler {
  const getKey = (pageIndex) =>
    `${
      envConfig.API_URL
    }/api/v1/insights/${insightId}/sites/${siteId}/annotations?page=${
      pageIndex + 1
    }&pageSize=${PAGE_SIZE}`;

  const exportCsv = useCallback(
    (site, startTime, endTime) =>
      exportAnnotationCSV(insightId, siteId, startTime, endTime, site),
    [insightId, siteId]
  );
  const { data, error, isValidating, mutate, setSize, size } = useSWRInfinite<
    Annotation[]
  >(getKey, null, {
    revalidateOnFocus: false,
    refreshInterval: 10 * 1000,
  });
  const allPagesData = useMemo(
    () => (data ? ([] as Annotation[]).concat(...data) : []),
    [data]
  );
  return {
    data: allPagesData,
    error,
    isValidating,
    isReachingEnd: data ? data[data.length - 1]?.length < PAGE_SIZE : false,
    size,
    setSize,
    createAnnotation: useCallback(
      (newAnnotation) =>
        createAnnotation(insightId, siteId, newAnnotation).then(async () => {
          await mutate();
        }),
      [mutate, insightId, siteId]
    ),
    updateAnnotation: useCallback(
      (editedAnnotation) => {
        if (editedAnnotation.annotationId === undefined) {
          return Promise.reject(
            new Error('Annotation ID is required to update an annotation')
          );
        }
        // TS doesn't know that the annotationId has been checked for undefined
        // @ts-ignore
        return updateAnnotation(insightId, siteId, editedAnnotation).then(
          () => {
            mutate((asyncData) => {
              if (!asyncData) {
                return undefined;
              }
              return asyncData.map((page) =>
                page.map((annotation) => {
                  if (
                    annotation.annotationId === editedAnnotation.annotationId
                  ) {
                    return editedAnnotation;
                  }
                  return annotation;
                })
              );
            });
          }
        );
      },
      [insightId, siteId, mutate]
    ),
    deleteAnnotation: useCallback(
      (annotationToDelete) => {
        if (annotationToDelete.annotationId === undefined) {
          return Promise.reject(
            new Error('Annotation ID is required to update an annotation')
          );
        }
        // TS doesn't know that the annotationId has been checked for undefined
        // @ts-ignore
        return deleteAnnotation(insightId, siteId, annotationToDelete).then(
          () => {
            mutate((asyncData) => {
              if (!asyncData) {
                return undefined;
              }
              return asyncData.map((page) =>
                page.filter(
                  (annotation) =>
                    annotation.annotationId !== annotationToDelete.annotationId
                )
              );
            });
          }
        );
      },
      [insightId, siteId, mutate]
    ),
    exportAnnotationCSV: exportCsv,
    resendAnnotationAlert: useCallback(
      async (annotationId: number) => {
        await resendAnnotationAlert(insightId, siteId, annotationId);
      },
      [insightId, siteId]
    ),
  };
}
