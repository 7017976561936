import {
  Flex,
  Input,
  InputGroup,
  InputRightElement,
  VStack,
} from '@chakra-ui/react';
import { SearchIcon } from '@chakra-ui/icons';
import DropDownSort, {
  SortDirection,
  SortOption,
} from '../../molecules/drop-down-sort';

export type SearchBarProps = {
  sortOptions?: SortOption[];
  selectedSortOption: string;
  setSelectedSortOption: (option: string) => void;
  sortDirectionIncrease: boolean;
  setSortDirectionIncrease: (isIncrease: boolean) => void;
  searchTerm: string;
  setSearchTerm: (term: string) => void;
};

export default function SearchBar({
  sortOptions = [],
  setSelectedSortOption,
  selectedSortOption,
  sortDirectionIncrease,
  setSortDirectionIncrease,
  searchTerm,
  setSearchTerm,
}: SearchBarProps) {
  return (
    <VStack width="full">
      <Flex width="full" justifyContent="space-between">
        <InputGroup maxW={220} size="sm" mr={2}>
          <Input
            type="search"
            placeholder="Search"
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
          />
          <InputRightElement pointerEvents="none">
            <SearchIcon color="gray.300" />
          </InputRightElement>
        </InputGroup>
        <DropDownSort
          sortOptions={sortOptions}
          selectedDirection={
            sortDirectionIncrease ? SortDirection.INC : SortDirection.DESC
          }
          selectedSortOption={selectedSortOption}
          onSelectDirection={(dir) =>
            setSortDirectionIncrease(dir === SortDirection.INC)
          }
          onSelectSortOption={setSelectedSortOption}
        />
      </Flex>
    </VStack>
  );
}
