import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box,
  Button,
  Flex,
  HStack,
  IconButton,
  VStack,
} from '@chakra-ui/react';
import { ReactNode, useState } from 'react';
import { Link } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import Checkbox from '../checkbox';
import { OutlinedWarningIcon, TimerOffIcon } from '../../atoms/custom-icons';
import ContextMenu, { ContextMenuItemConfig } from '../context-menu';

type OptionType = {
  id: string;
  label: string;
  color: string;
  warningStatus?: boolean;
  active?: boolean;
};

export interface SelectionGroupProps {
  title: ReactNode;
  titleIcon: ReactNode;
  options: OptionType[];
  selectedOptions: string[];
  optionContextMenu?: (option: OptionType) => ContextMenuItemConfig[];
  onSelectedOptionsChange: (selectedOptions: string[]) => void;
  addButton?: {
    label: ReactNode;
    action: () => void;
  };
  navigationPath?: string;
  onNavigationClick?: () => void;
}

export default function SelectionGroup({
  title,
  titleIcon,
  addButton,
  options,
  selectedOptions,
  optionContextMenu,
  navigationPath,
  onNavigationClick,
  onSelectedOptionsChange,
}: SelectionGroupProps) {
  return (
    <Accordion allowToggle defaultIndex={[0]}>
      <AccordionItem border="none">
        <VStack spacing={2} alignItems="stretch" role="heading">
          <Flex justifyContent="space-between">
            <HStack spacing={2}>
              <HStack spacing={1}>
                <Box display="flex" alignItems="center">
                  {titleIcon}
                </Box>
                <Box fontSize="sm" fontWeight="bold">
                  {title}
                </Box>
              </HStack>
              {navigationPath && (
                <Button
                  as={Link}
                  to={navigationPath}
                  aria-label="View more"
                  variant="outline"
                  size="xs"
                  onClick={onNavigationClick}
                >
                  More...
                </Button>
              )}
            </HStack>
            <Box>
              <AccordionButton
                as={IconButton}
                aria-label="show-hide"
                size="xs"
                width="24px"
                minWidth="auto"
                padding={0}
                height="24px"
                variant="outline"
                icon={<AccordionIcon />}
              />
            </Box>
          </Flex>
          <AccordionPanel p={0}>
            <VStack spacing={4} alignItems="stretch">
              <Flex justifyContent="space-between">
                <Button
                  size="xs"
                  variant="outline"
                  onClick={() => {
                    if (selectedOptions.length === options.length) {
                      onSelectedOptionsChange([]);
                    } else {
                      onSelectedOptionsChange(
                        options.map((option) => option.id)
                      );
                    }
                  }}
                >
                  {selectedOptions.length === options.length ? (
                    <FormattedMessage
                      defaultMessage="Deselect all"
                      id="Spd88H"
                    />
                  ) : (
                    <FormattedMessage defaultMessage="Select all" id="94Fg25" />
                  )}
                </Button>
                {addButton && (
                  <Button
                    size="xs"
                    colorScheme="greenDark"
                    variant="solid"
                    onClick={addButton.action}
                  >
                    {addButton.label}
                  </Button>
                )}
              </Flex>
              <VStack spacing={2}>
                {options.map((option) => (
                  <SelectionOption
                    key={option.id}
                    option={option}
                    isSelected={selectedOptions.includes(option.id)}
                    onSelectionChange={() => {
                      if (selectedOptions.includes(option.id)) {
                        onSelectedOptionsChange(
                          selectedOptions.filter((id) => id !== option.id)
                        );
                      } else {
                        onSelectedOptionsChange(
                          selectedOptions.concat(option.id)
                        );
                      }
                    }}
                    contextMenuItems={optionContextMenu?.(option)}
                  />
                ))}
              </VStack>
            </VStack>
          </AccordionPanel>
        </VStack>
      </AccordionItem>
    </Accordion>
  );
}

function SelectionOption({
  option,
  isSelected,
  onSelectionChange,
  contextMenuItems,
}: {
  option: OptionType;
  isSelected: boolean;
  onSelectionChange: () => void;
  contextMenuItems?: ContextMenuItemConfig[];
}) {
  const [isOptionHovered, setIsOptionHovered] = useState(false);
  const [isContextMenuOpen, setIsContextMenuOpen] = useState(false);
  const hoverHandlers = !contextMenuItems
    ? {}
    : {
        onMouseEnter: () => {
          setIsContextMenuOpen(true);
        },
        onMouseLeave: () => {
          setIsContextMenuOpen(false);
        },
      };
  const contextMenuHandlers = {
    onOpen: () => setIsOptionHovered(true),
    onClose: () => setIsOptionHovered(false),
  };
  const isContextMenuVisible = isOptionHovered || isContextMenuOpen;
  return (
    <Flex
      width="100%"
      minHeight="24px"
      alignItems="center"
      justifyContent="space-between"
      {...hoverHandlers}
    >
      <Checkbox
        badge={getVmsStatusIcon(option)}
        checked={isSelected}
        color={option.active ?? true ? option.color : 'gray.200'}
        onChange={onSelectionChange}
      >
        {option.label}
      </Checkbox>
      {contextMenuItems && isContextMenuVisible && (
        <Box>
          <ContextMenu
            size="xs"
            items={contextMenuItems}
            menuProps={contextMenuHandlers}
          />
        </Box>
      )}
    </Flex>
  );
}

function getVmsStatusIcon(option: OptionType) {
  if (option.active === false) {
    return <TimerOffIcon color="gray.500" />;
  }
  if (option.warningStatus) {
    return <OutlinedWarningIcon color="orange.400" stroke="white" />;
  }
  return undefined;
}
