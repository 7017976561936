import { useEffect, useRef, useState } from 'react';
import { defineMessage, FormattedMessage, useIntl } from 'react-intl';
import {
  Flex,
  IconButton,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
} from '@chakra-ui/react';
import { SettingsIcon } from '@chakra-ui/icons';
import classNames from 'classnames';
import InfoTooltip from 'design-system/atoms/info-tooltip';
import Spinner from '../Spinner';
import { SiteView } from '../../hooks/useSiteView';
import { EditViewForm } from './EditViewForm';
import { useAnalytics } from '../../hooks/analytics/useAnalytics';
import {
  UserViewInput,
  UserDefaultViewInput,
} from '../../data/useSiteUserViewData';
import { UserViewConfigParams } from './utils';

export interface UserViewProps {
  insightId: number;
  siteId: number;
  viewId: number;
  viewName: string;
  viewUrl: string;
  viewDefault: boolean;
  viewSelected: boolean;
  siteViewParams: UserViewConfigParams;
  setSiteView: SiteView['setState'];
  editable?: boolean;
  sharedView: boolean;
  deleteViewFetcher: (viewId: string) => Promise<unknown>;
  updateUserDefaultViewFetcher: (
    viewId: string,
    config: UserDefaultViewInput
  ) => Promise<unknown>;
  updateViewFetcher: (
    viewId: string,
    config: UserViewInput
  ) => Promise<unknown>;
}

const UserView = ({
  insightId,
  siteId,
  viewId,
  viewName,
  viewUrl,
  viewDefault,
  viewSelected = false,
  siteViewParams,
  setSiteView,
  editable = true,
  sharedView,
  deleteViewFetcher,
  updateUserDefaultViewFetcher,
  updateViewFetcher,
}: UserViewProps) => {
  const [showCopied, setShowCopied] = useState(false);
  const [showViewForm, setShowViewForm] = useState(false);
  const maxLengthViewName = useRef(60);
  const { formatMessage } = useIntl();
  useEffect(() => {
    const timeout = setTimeout(() => setShowCopied(false), 1000);
    return () => {
      clearTimeout(timeout);
    };
  }, [showCopied]);
  const { track } = useAnalytics();

  const displayViewName = () => {
    if (viewName.length > maxLengthViewName.current) {
      return `${viewName.substring(0, maxLengthViewName.current - 1)} ...`;
    }
    return viewName;
  };

  const updateDefaultView = async () => {
    const defaultViewPayload = {
      isDefault: !viewDefault,
      isSiteLevel: sharedView,
    };
    await updateUserDefaultViewFetcher(viewId.toString(), defaultViewPayload);
  };

  const saveViewAsCurrentSelection = async () => {
    const viewPayload = {
      hidden: siteViewParams.hidden,
      timePeriod: siteViewParams.timePeriod,
      date: siteViewParams.date,
      startDate: siteViewParams.startDate,
      saveCurrentState: true,
    };
    await updateViewFetcher(viewId.toString(), viewPayload);
  };

  const deleteView = async () => {
    await deleteViewFetcher(viewId.toString());
    track('User View Deleted', { referrer: 'View Site Tab' });
  };

  const onClickView = () => {
    setSiteView(`?siteId=${siteId}&${viewUrl}`);
  };

  const showEditableViewForm = () => setShowViewForm(!showViewForm);

  const copyToClipboard = async () => {
    setShowCopied(true);
    try {
      await navigator.clipboard.writeText(
        `${window.location.href.split('?')[0]}?${viewUrl}&siteId=${siteId}`
      );
    } catch (error) {
      // Some old browsers might not be able to use Clipboard API
      const el = document.createElement('input');
      el.value = `${window.location.href.split('?')[0]}?${viewUrl}`;
      document.body.appendChild(el);
      el.select();
      document.execCommand('copy');
      document.body.removeChild(el);
    }
  };

  return (
    <Flex width="100%" flexDirection="column">
      <Flex
        width="100%"
        flexDirection="row"
        justifyContent="start"
        alignItems="center"
        className={classNames('user-site-view__item', {
          'user-site-view__item--editable': showViewForm,
          'user-site-view__item--selected': viewSelected,
        })}
      >
        {showCopied && <Spinner />}
        {!showCopied && (
          <InfoTooltip
            content={
              !showViewForm && viewName.length > maxLengthViewName.current
                ? viewName
                : undefined
            }
          >
            <button
              className={classNames(
                'user-site-view__item__btn user-site-view__item__viewName',
                {
                  'user-site-view__item__viewName--selected': viewSelected,
                }
              )}
              type="button"
              onClick={() => onClickView()}
            >
              {displayViewName()}
            </button>
          </InfoTooltip>
        )}
        {viewDefault && !showCopied ? (
          <div className="user-site-view__item__indicator">
            <FormattedMessage
              defaultMessage="DEFAULT"
              id="bMUT17"
              description="DEFAULT."
            />
          </div>
        ) : null}
        {showCopied ? (
          <div className="user-site-view__item__indicator">
            <FormattedMessage
              defaultMessage="COPIED TO CLIPBOARD"
              id="h1er+U"
              description="Copied to clipboard."
            />
          </div>
        ) : null}
        <Menu>
          {({ isOpen }) => (
            <>
              <MenuButton
                isActive={isOpen}
                as={IconButton}
                size="xs"
                variant="ghost"
                ml={1}
                pos="absolute"
                right="5px"
                icon={<SettingsIcon />}
              />
              <MenuList>
                <MenuItem onClick={updateDefaultView}>
                  {viewDefault
                    ? formatMessage(
                        defineMessage({
                          defaultMessage: 'Clear default',
                          id: 'Z0z9Jl',
                          description: 'Clear default',
                        })
                      )
                    : formatMessage(
                        defineMessage({
                          defaultMessage: 'Set as default site view',
                          id: '7VG7lT',
                          description: 'Set as default site view',
                        })
                      )}
                </MenuItem>
                <MenuItem onClick={copyToClipboard}>
                  <FormattedMessage
                    defaultMessage="Copy to clipboard"
                    id="QePekS"
                    description="Copy to clipboard."
                  />
                </MenuItem>
                <MenuItem onClick={showEditableViewForm} isDisabled={!editable}>
                  {showViewForm
                    ? formatMessage(
                        defineMessage({
                          defaultMessage: 'Close edit name & sharing',
                          id: '48ns4X',
                          description: 'Close edit name & sharing',
                        })
                      )
                    : formatMessage(
                        defineMessage({
                          defaultMessage: 'Edit name & sharing',
                          id: 'NqHrsD',
                          description: 'Edit name & sharing',
                        })
                      )}
                </MenuItem>
                <MenuItem
                  onClick={saveViewAsCurrentSelection}
                  isDisabled={!editable}
                >
                  <FormattedMessage
                    defaultMessage="Save view as current selection"
                    id="j+Xsrk"
                    description="Save view as current selection."
                  />
                </MenuItem>
                <MenuItem onClick={deleteView} isDisabled={!editable}>
                  <FormattedMessage
                    defaultMessage="Delete saved view"
                    id="arcWv2"
                    description="Delete saved view."
                  />
                </MenuItem>
              </MenuList>
            </>
          )}
        </Menu>
      </Flex>
      {showViewForm && (
        <div className="user-site-view__form">
          <EditViewForm
            insightId={insightId}
            editable={editable}
            sharedView={sharedView}
            viewId={viewId}
            name={viewName}
            showViewForm={setShowViewForm}
            siteViewParams={siteViewParams}
          />
        </div>
      )}
    </Flex>
  );
};

export default UserView;
