import { VStack, Button, HStack, Box } from '@chakra-ui/react';
import { FormattedMessage } from 'react-intl';
import { RoutePollingStatusFailureType } from '../../data/useRoutePollingStatusData';

export default function OtherCauses({
  currentResponseType,
  setNewResponse,
}: {
  currentResponseType: RoutePollingStatusFailureType;
  setNewResponse: (newResponse: {
    responseType: RoutePollingStatusFailureType | 'OTHER';
    responseComment?: string;
  }) => void;
}) {
  return (
    <VStack alignItems="flex-start" spacing="4">
      <Box fontSize="sm">
        <TextOtherCauses currentResponseType={currentResponseType} />
      </Box>
      <HStack spacing="4">
        {currentResponseType !== 'CLOSURE' && (
          <Button
            variant="outline"
            size="sm"
            onClick={() => setNewResponse({ responseType: 'CLOSURE' })}
          >
            <FormattedMessage defaultMessage="Road is closed" id="C0UCOI" />
          </Button>
        )}
        {currentResponseType !== 'ROAD_LAYOUT' &&
          currentResponseType !== 'ROAD_LAYOUT_UNAVAILABLE' &&
          currentResponseType !== 'ROAD_LAYOUT_UPDATE' && (
            <Button
              variant="outline"
              size="sm"
              onClick={() => setNewResponse({ responseType: 'ROAD_LAYOUT' })}
            >
              <FormattedMessage
                defaultMessage="Road layout has changed"
                id="dHjyeb"
              />
            </Button>
          )}
        {currentResponseType !== 'UNKNOWN_CAUSE' &&
          currentResponseType !== 'KNOWN_CAUSE' && (
            <Button
              variant="outline"
              size="sm"
              onClick={() => setNewResponse({ responseType: 'OTHER' })}
            >
              <FormattedMessage defaultMessage="Something else" id="qm/eL5" />
            </Button>
          )}
      </HStack>
    </VStack>
  );
}
function TextOtherCauses({
  currentResponseType,
}: {
  currentResponseType: RoutePollingStatusFailureType;
}) {
  switch (currentResponseType) {
    case 'CLOSURE':
      return (
        <FormattedMessage
          defaultMessage="Or, is something other than a road closure causing this?"
          id="FGdEb5"
        />
      );
    case 'ROAD_LAYOUT':
    case 'ROAD_LAYOUT_UPDATE':
    case 'ROAD_LAYOUT_UNAVAILABLE':
      return (
        <FormattedMessage
          defaultMessage="Or, is something other than a road layout change causing this?"
          id="2MPaJw"
        />
      );
    default:
      return (
        <FormattedMessage
          defaultMessage="Could these reasons be the cause?"
          id="DtRd02"
        />
      );
  }
}
