import { ReactNode, Suspense } from 'react';
import { Flex, Spinner } from '@chakra-ui/react';

export default function SpinnerSuspense({ children }: { children: ReactNode }) {
  return (
    <Suspense
      fallback={
        <Flex
          width="100%"
          height="100%"
          alignItems="center"
          justifyContent="center"
          p={10}
        >
          <Spinner variant="mooven" size="xl" data-ignore-visual-test />
        </Flex>
      }
    >
      {children}
    </Suspense>
  );
}
