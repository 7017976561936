import { Box, BoxProps, Flex } from '@chakra-ui/react';
import { ReactNode } from 'react';

export interface LayoutPageProps {
  children?: ReactNode;
  maxWidth?: BoxProps['maxWidth'];
}

export default function LayoutPage({
  children,
  maxWidth = '800px',
}: LayoutPageProps) {
  return (
    <Flex
      flex={1}
      flexDirection="column"
      justifyContent="flex-start"
      alignItems="center"
      backgroundColor="white"
      m={{ base: 0, md: 6 }}
      borderRadius={{ base: 0, md: 16 }}
      p={{ base: 4, md: 8 }}
      pt={{ base: 20, md: '58px' }}
    >
      <Box w="full" maxW={maxWidth}>
        {children}
      </Box>
    </Flex>
  );
}
