export default function MoovenLogo({
  width = '150',
}: {
  width?: string;
}): JSX.Element {
  return (
    <svg
      width={width}
      viewBox="0 0 150 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_8438_187733)">
        <path
          d="M26.4142 24.6169C24.8822 26.433 22.9191 27.7828 20.7376 28.5793C22.9016 26.8733 24.4686 24.4055 24.987 21.4888C26.1084 15.16 21.8619 9.12672 15.4979 8.01159C14.8105 7.88993 14.129 7.83201 13.4533 7.83201C8.70869 7.83201 4.4243 10.714 2.6418 15.0587C2.83403 12.2491 3.90877 9.57857 5.76408 7.38016C8.33006 4.33888 12.096 2.59232 16.0979 2.59232C19.2551 2.59232 22.3308 3.70746 24.754 5.72918C30.4481 10.4823 31.1937 18.9544 26.4142 24.6169ZM18.6551 26.8211C17.9648 27.218 17.1814 27.4265 16.392 27.4265C14.7697 27.4265 13.261 26.5605 12.4543 25.1673C11.2018 23.0123 11.9475 20.2433 14.1173 18.9978C14.8076 18.601 15.5911 18.3925 16.3804 18.3925C17.9998 18.3925 19.5114 19.2585 20.3182 20.6517C20.924 21.6973 21.0871 22.9138 20.7726 24.0782C20.458 25.2426 19.7066 26.2158 18.658 26.8211M4.51459 17.9001C5.2835 13.5699 9.04072 10.4272 13.4533 10.4272C13.9834 10.4272 14.5193 10.4736 15.0435 10.5663C17.4348 10.9862 19.5202 12.307 20.9124 14.2853C21.9696 15.7886 22.5317 17.5322 22.558 19.3251C21.2298 17.063 18.8386 15.8001 16.3804 15.8001C15.1688 15.8001 13.9397 16.1072 12.8125 16.7531C9.39314 18.7169 8.22229 23.0644 10.197 26.4649C10.6252 27.2035 11.1698 27.8349 11.7931 28.3534C6.91163 27.4439 3.65247 22.7777 4.5175 17.9001M26.4288 3.74222C23.4143 1.2281 19.7473 0 16.0979 0C11.5048 0 6.94658 1.94352 3.76314 5.7147C-1.94841 12.4837 -1.06007 22.5749 5.7466 28.2549C8.76112 30.769 12.431 31.9971 16.0804 31.9971C20.6706 31.9971 25.2317 30.0536 28.4122 26.2824C34.1238 19.5134 33.2354 9.42216 26.4288 3.74222Z"
          fill="#00C853"
        />
        <path
          d="M124.681 10.5721C126.915 10.5721 128.625 11.9421 128.969 13.9494C128.98 14.0247 128.922 14.0942 128.843 14.0942H120.283C120.199 14.0942 120.138 14.0131 120.164 13.9349C120.805 11.8494 122.5 10.575 124.681 10.575M124.809 7.68433C120.208 7.68433 116.605 11.3107 116.605 15.9392C116.605 20.5677 120.222 24.3215 124.841 24.3215C127.949 24.3215 130.629 23.1311 131.954 20.9732C132.073 20.7821 132.018 20.5301 131.829 20.4084L129.778 19.0848C129.586 18.9602 129.336 19.0181 129.21 19.2064C128.342 20.5127 126.679 21.2397 124.941 21.2397C122.299 21.2397 120.385 19.5511 119.977 16.9066C119.966 16.8313 120.027 16.7618 120.103 16.7618H132.041C132.257 16.7618 132.437 16.5967 132.452 16.3824C132.478 16.029 132.504 15.5974 132.504 15.3339C132.504 11.0442 129.126 7.68433 124.812 7.68433"
          fill="#3D3D3D"
        />
        <path
          d="M74.3259 21.0775C71.489 21.0775 69.3483 18.908 69.3483 16.0319C69.3483 13.1557 71.489 10.9225 74.3259 10.9225C77.1627 10.9225 79.3034 13.118 79.3034 16.0319C79.3034 18.9457 77.1161 21.0775 74.3259 21.0775ZM74.3259 7.6814C69.6716 7.6814 66.025 11.3483 66.025 16.0319C66.025 20.7154 69.6716 24.3186 74.3259 24.3186C78.9801 24.3186 82.6267 20.6778 82.6267 16.0319C82.6267 11.386 78.9801 7.6814 74.3259 7.6814Z"
          fill="#3D3D3D"
        />
        <path
          d="M92.6402 21.0775C89.8033 21.0775 87.6626 18.908 87.6626 16.0319C87.6626 13.1557 89.8033 10.9225 92.6402 10.9225C95.477 10.9225 97.6178 13.118 97.6178 16.0319C97.6178 18.9457 95.4304 21.0775 92.6402 21.0775ZM92.6402 7.6814C87.9859 7.6814 84.3394 11.3483 84.3394 16.0319C84.3394 20.7154 87.9859 24.3186 92.6402 24.3186C97.2945 24.3186 100.941 20.6778 100.941 16.0319C100.941 11.386 97.2945 7.6814 92.6402 7.6814Z"
          fill="#3D3D3D"
        />
        <path
          d="M113.232 8.10427L108.849 19.1716C108.822 19.2411 108.723 19.2411 108.694 19.1716L104.279 8.10427C104.253 8.04055 104.194 8 104.124 8H100.801C100.682 8 100.603 8.11875 100.65 8.22882L107.238 23.7509C107.302 23.9015 107.453 24.0029 107.619 24.0029H109.926C110.092 24.0029 110.241 23.9044 110.308 23.7509L116.896 8.22882C116.943 8.12165 116.861 8 116.745 8H113.389C113.322 8 113.261 8.04055 113.235 8.10427"
          fill="#3D3D3D"
        />
        <path
          d="M148.838 24H146.409C146.135 24 145.914 23.7799 145.914 23.5076V14.5228C145.914 12.7994 144.51 11.4033 142.777 11.4033H140.893C139.16 11.4033 137.756 12.7994 137.756 14.5228V23.5076C137.756 23.7799 137.535 24 137.261 24H134.832C134.558 24 134.337 23.7799 134.337 23.5076V14.5228C134.337 10.9225 137.272 8 140.896 8H142.78C146.403 8 149.339 10.9196 149.339 14.5228V23.5076C149.339 23.7799 149.118 24 148.844 24"
          fill="#3D3D3D"
        />
        <path
          d="M57.6339 8H55.7495C53.8592 8 52.1583 8.79363 50.9612 10.0681C50.9292 10.1028 50.8768 10.1028 50.8447 10.0681C49.6477 8.79652 47.9438 8 46.0565 8H44.172C40.5517 8 37.6129 10.9196 37.6129 14.5228V23.5916C37.6129 23.8175 37.7964 24.0029 38.0265 24.0029H40.6216C40.8488 24.0029 41.0352 23.8204 41.0352 23.5916V14.5228C41.0352 12.7994 42.439 11.4033 44.172 11.4033H46.1293C47.8564 11.4033 49.2341 12.8081 49.1933 14.5228V23.5916C49.1933 23.8175 49.3768 24.0029 49.6069 24.0029H52.202C52.4292 24.0029 52.6156 23.8204 52.6156 23.5916V14.5228C52.5719 12.8052 53.9466 11.4033 55.6738 11.4033H57.6398C59.3727 11.4033 60.7766 12.7994 60.7766 14.5228V23.5916C60.7766 23.8175 60.963 24.0029 61.1902 24.0029H63.7853C64.0125 24.0029 64.1989 23.8204 64.1989 23.5916V14.5228C64.1989 10.9225 61.263 8 57.6398 8"
          fill="#3D3D3D"
        />
      </g>
      <defs>
        <clipPath id="clip0_8438_187733">
          <rect width="149.333" height="32" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}
