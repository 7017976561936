import { Tab, TabList, TabPanel, TabPanels, Tabs } from '@chakra-ui/react';

import { FormattedMessage } from 'react-intl';
import TeamMembersSiteSettings from './TeamMembersSiteSettings';
import SiteGuestsSettings from './SiteGuestsSettings';

export default function UserSiteAccessSettings({
  orgId,
  siteId,
}: {
  orgId: number;
  siteId: number;
}) {
  return (
    <Tabs colorScheme="green" width="full" defaultIndex={1}>
      <TabList>
        <Tab
          fontSize="sm"
          fontWeight="600"
          data-testid="team-members-site-settings-tab"
        >
          <FormattedMessage
            defaultMessage="Team Members"
            id="BUAM1d"
            description="Site settings tabs - team members tab label"
          />
        </Tab>
        <Tab fontSize="sm" fontWeight="600">
          <FormattedMessage
            defaultMessage="Guests"
            id="Xtd0hd"
            description="Site settings tabs - guests tab label"
          />
        </Tab>
      </TabList>
      <TabPanels>
        <TabPanel paddingX={0} paddingY={10}>
          <TeamMembersSiteSettings siteId={siteId} orgId={orgId} />
        </TabPanel>
        <TabPanel paddingX={0} paddingY={10}>
          <SiteGuestsSettings siteId={siteId} orgId={orgId} />
        </TabPanel>
      </TabPanels>
    </Tabs>
  );
}
