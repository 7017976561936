import './EditableSiteStatus.scss';
import classnames from 'classnames';
import moment from 'moment';
import { FormattedMessage, useIntl } from 'react-intl';
import InfoTooltip from 'design-system/atoms/info-tooltip';
import useRequestStatus from '../../hooks/useRequestStatus';
import Spinner from '../Spinner';
import {
  MESSAGE_GENERIC_ERROR,
  MESSAGE_UI_DISABLED_SITE_MONITOR_PERMISSION,
} from '../../constants/messages';
import { Schedule } from '../../types/schedule';
import { Site } from '../../types/site';

interface EditableSiteStatusProps {
  nextSchedule: Schedule | undefined;
  isSiteActive: Site['active'];
  siteTimeZone: Site['siteTimeZone'];
  startMonitoring: () => Promise<unknown>;
  stopMonitoring: () => Promise<unknown>;
  hasSiteMonitorPermission: boolean;
  startEditingSchedule: () => void;
}
export default function EditableSiteStatus({
  nextSchedule,
  isSiteActive,
  siteTimeZone,
  startMonitoring,
  stopMonitoring,
  hasSiteMonitorPermission,
  startEditingSchedule,
}: EditableSiteStatusProps) {
  const { formatMessage } = useIntl();
  const [isSaving, hasSavingError, withSavingStatus] = useRequestStatus();
  const startMonitoringWithStatus = withSavingStatus(startMonitoring);
  const stopMonitoringWithStatus = withSavingStatus(stopMonitoring);

  return (
    <div
      className={classnames({
        'v2-editable-site-status': true,
        'v2-editable-site-status--on': isSiteActive,
      })}
    >
      <h4 className="v2-editable-site-status__status">
        <FormattedMessage
          defaultMessage={`
            {isMonitoring, select, true {Currently monitoring} other {NOT monitoring}}
          `}
          id="2CFxA3"
          description="Current site monitoring status."
          values={{ isMonitoring: isSiteActive }}
        />
        {nextSchedule && nextSchedule.updatedBy && (
          <small>
            &nbsp; (
            <FormattedMessage
              defaultMessage="last updated by {useName}"
              id="2SM41h"
              description="Monitoring panel's updated by label."
              values={{ useName: nextSchedule.updatedBy }}
            />
            )
          </small>
        )}
      </h4>
      {isSiteActive ? (
        <MonitoringEnabledPanel
          nextSchedule={nextSchedule}
          startEditingSchedule={startEditingSchedule}
          siteTimeZone={siteTimeZone}
          stopMonitoring={stopMonitoringWithStatus}
          isSaving={isSaving}
          hasSiteMonitorPermission={hasSiteMonitorPermission}
        />
      ) : (
        <MonitoringDisabledPanel
          nextSchedule={nextSchedule}
          startEditingSchedule={startEditingSchedule}
          siteTimeZone={siteTimeZone}
          startMonitoring={startMonitoringWithStatus}
          isSaving={isSaving}
          hasSiteMonitorPermission={hasSiteMonitorPermission}
        />
      )}
      {hasSavingError && (
        <div className="v2-editable-site-status__error">
          {formatMessage(MESSAGE_GENERIC_ERROR)}
        </div>
      )}
    </div>
  );
}

interface MonitoringEnabledPanelProps {
  nextSchedule: Schedule | undefined;
  startEditingSchedule: () => void;
  siteTimeZone: Site['siteTimeZone'];
  stopMonitoring: () => Promise<unknown>;
  isSaving: boolean;
  hasSiteMonitorPermission: boolean;
}
function MonitoringEnabledPanel({
  nextSchedule,
  startEditingSchedule,
  siteTimeZone,
  stopMonitoring,
  isSaving,
  hasSiteMonitorPermission,
}: MonitoringEnabledPanelProps) {
  const { formatMessage, formatTime } = useIntl();
  const displayEndDate =
    nextSchedule && nextSchedule.endDate
      ? getSiteDisplayDate(nextSchedule.endDate, siteTimeZone)
      : 0;
  return (
    <div>
      <span className="v2-editable-site-status__label">
        <FormattedMessage
          defaultMessage={`
            {endDate, select,
              0 {Monitoring will run continuously}
              other {Monitoring will stop on {endDate}}
            }
            as per {scheduleName, select, defaultName {current settings} other {"{scheduleName}"}}
          `}
          id="2v2d2F"
          description="Active monitoring message, showing the date it is scheduled to stop. Zero means no date has been set."
          values={{
            endDate:
              displayEndDate === 0
                ? 0
                : formatTime(displayEndDate, {
                    year: 'numeric',
                    month: '2-digit',
                    day: '2-digit',
                    hour: 'numeric',
                    minute: 'numeric',
                  }),
            scheduleName:
              nextSchedule && nextSchedule.scheduleName
                ? nextSchedule.scheduleName
                : 'defaultName',
          }}
        />
      </span>
      <span className="v2-editable-site-status__buttons">
        <InfoTooltip
          content={
            !hasSiteMonitorPermission
              ? formatMessage(MESSAGE_UI_DISABLED_SITE_MONITOR_PERMISSION)
              : undefined
          }
        >
          <button
            type="button"
            aria-label="Edit end date"
            onClick={startEditingSchedule}
            className="v2-editable-site-status__btn-edit"
            disabled={isSaving || !hasSiteMonitorPermission}
          >
            <FormattedMessage
              defaultMessage="{hasEndDate, select, true {Edit} other {Set}} end date"
              id="9s4j+C"
              description="Label for button that enables user to edit the end date for the current monitoring schedule."
              values={{ hasEndDate: !!(nextSchedule && nextSchedule.endDate) }}
            />
          </button>
        </InfoTooltip>
        <InfoTooltip
          content={
            !hasSiteMonitorPermission
              ? formatMessage(MESSAGE_UI_DISABLED_SITE_MONITOR_PERMISSION)
              : undefined
          }
        >
          <button
            type="button"
            aria-label="Stop monitoring"
            onClick={() => {
              if (
                window.confirm(
                  formatMessage({
                    defaultMessage:
                      'Are you sure you want to turn off monitoring?',
                    id: 'elPD27',
                    description:
                      'Confirmation message when user requests to stop monitoring',
                  })
                )
              ) {
                stopMonitoring();
              }
            }}
            className="v2-editable-site-status__btn-stop"
            disabled={isSaving || !hasSiteMonitorPermission}
          >
            <FormattedMessage
              defaultMessage="Stop monitoring now"
              id="CnztcF"
              description="Stop monitoring button label."
            />
          </button>
        </InfoTooltip>
      </span>

      {isSaving && <Spinner />}
    </div>
  );
}

interface MonitoringDisabledPanelProps {
  nextSchedule: Schedule | undefined;
  startEditingSchedule: () => void;
  siteTimeZone: Site['siteTimeZone'];
  startMonitoring: () => Promise<unknown>;
  isSaving: boolean;
  hasSiteMonitorPermission: boolean;
}
function MonitoringDisabledPanel({
  nextSchedule,
  startEditingSchedule,
  siteTimeZone,
  startMonitoring,
  isSaving,
  hasSiteMonitorPermission,
}: MonitoringDisabledPanelProps) {
  const { formatMessage, formatTime } = useIntl();
  const displayStartDate =
    nextSchedule && nextSchedule.startDate
      ? getSiteDisplayDate(nextSchedule.startDate, siteTimeZone)
      : 0;

  return (
    <div>
      {displayStartDate !== 0 && (
        <span className="v2-editable-site-status__label">
          <FormattedMessage
            defaultMessage='Monitoring will start on {nextStartDate} as per "{scheduleName}"'
            id="Rpmj15"
            description="When monitoring will start."
            values={{
              nextStartDate: formatTime(displayStartDate, {
                year: 'numeric',
                month: '2-digit',
                day: '2-digit',
                hour: 'numeric',
                minute: 'numeric',
              }),
              scheduleName: (nextSchedule && nextSchedule.scheduleName) || '',
            }}
          />
        </span>
      )}
      <span className="v2-editable-site-status__buttons">
        <InfoTooltip
          content={
            !hasSiteMonitorPermission
              ? formatMessage(MESSAGE_UI_DISABLED_SITE_MONITOR_PERMISSION)
              : undefined
          }
        >
          <button
            type="button"
            aria-label="Start monitoring"
            onClick={startMonitoring}
            className="v2-editable-site-status__btn-start"
            disabled={isSaving || !hasSiteMonitorPermission}
          >
            <FormattedMessage
              defaultMessage="Start monitoring now"
              id="uRNeOf"
              description="Start monitoring button label."
            />
          </button>
        </InfoTooltip>
        <InfoTooltip
          content={
            !hasSiteMonitorPermission
              ? formatMessage(MESSAGE_UI_DISABLED_SITE_MONITOR_PERMISSION)
              : undefined
          }
        >
          <button
            type="button"
            className="v2-editable-site-status__btn-edit"
            onClick={startEditingSchedule}
            disabled={isSaving || !hasSiteMonitorPermission}
          >
            {displayStartDate ? (
              <FormattedMessage
                defaultMessage="Edit dates"
                id="9nJ9RT"
                description="Label for button that enables user to edit the next monitoring schedule."
              />
            ) : (
              <FormattedMessage
                defaultMessage="Start monitoring later"
                id="/ov02G"
                description="Label for button that enables user to create a monitoring schedule."
              />
            )}
          </button>
        </InfoTooltip>
      </span>
      {isSaving && <Spinner />}
    </div>
  );
}

function getSiteDisplayDate(date: number, siteTimeZone: string) {
  return moment(date).tz(siteTimeZone).format('YYYY-MM-DD[T]HH:mm');
}
