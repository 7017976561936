import { Box, Flex, Text } from '@chakra-ui/react';
import type { TeamUser } from '@webapp/bff';
import { FormattedMessage } from 'react-intl';
import UserContextMenu from '../../local_design_system/organisms/SiteAccessSettings/UserContextMenu';
import UserActionsMenu from '../../local_design_system/organisms/SiteAccessSettings/UserActionsMenu';
import UsersList from '../../local_design_system/organisms/SiteAccessSettings/UsersList';
import StatusGroupsLayout from '../../local_design_system/organisms/SiteAccessSettings/StatusGroupsLayout';

export interface TeamGuestsSettingsProps {
  users: TeamUser[];
  orgSitesCount: number;
  removeTeamMember: (user: TeamUser) => Promise<void>;
  sendPasswordReset: (user: TeamUser) => Promise<void>;
}

export default function TeamGuestsSettings({
  users,
  orgSitesCount,
  removeTeamMember,
  sendPasswordReset,
}: TeamGuestsSettingsProps) {
  const activeUsers = users.filter((u) => !u.pending);
  const pendingUsers = users.filter((u) => u.pending);
  if (users.length === 0) {
    return (
      <Text fontWeight="bold">
        <FormattedMessage
          defaultMessage="There are no guest accounts on any of your sites"
          id="OJtz0R"
          description="Team Settings no guests message"
        />
      </Text>
    );
  }
  return (
    <StatusGroupsLayout
      activeList={
        <UsersList
          users={activeUsers}
          getUserInfo={(user) => getInfo(user, orgSitesCount)}
          getUserContextMenu={(user) => (
            <UserContextMenu user={user.name}>
              <UserActionsMenu
                user={user}
                sendPasswordReset={sendPasswordReset}
                removeTeamMember={removeTeamMember}
              />
            </UserContextMenu>
          )}
        />
      }
      pendingList={
        pendingUsers.length > 0 && (
          <UsersList
            users={pendingUsers}
            getUserInfo={(user) => getInfo(user, orgSitesCount)}
            getUserContextMenu={(user) => (
              <UserContextMenu user={user.name}>
                <UserActionsMenu
                  user={user}
                  removeTeamMember={removeTeamMember}
                />
              </UserContextMenu>
            )}
          />
        )
      }
    />
  );
}

function getInfo(user: TeamUser, totalCount) {
  const canEditCount = user.sites.filter((s) => s.siteRole === 'Editor').length;
  const canViewCount = user.sites.filter((s) => s.siteRole === 'Viewer').length;
  return canEditCount === 0 && canViewCount === 0 ? null : (
    <Flex flexWrap="wrap" rowGap={1} columnGap={2}>
      {canEditCount > 0 && (
        <Box>
          <FormattedMessage
            id="iFigLC"
            defaultMessage="Can edit {canEditCount}/{totalCount}"
            description="Team settings guest can edit sites count"
            values={{ canEditCount, totalCount }}
          />{' '}
        </Box>
      )}
      {canViewCount > 0 && (
        <Box>
          <FormattedMessage
            id="tJ4JQH"
            defaultMessage="Can view {canViewCount}/{totalCount}"
            description="Team settings guest can view sites count"
            values={{ canViewCount, totalCount }}
          />{' '}
        </Box>
      )}
    </Flex>
  );
}
