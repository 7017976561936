import { defineMessage } from 'react-intl';
import {
  MAX_DUST,
  MAX_NOISE,
  MAX_QUEUE_LENGTH,
  MAX_SITE_IMPACT_DELAY,
  MAX_TIME_DELAY,
  MIN_SPEED,
  SUM_TRUCK_HOURS,
  SUM_DWELL_HOURS,
  TOTAL_RAIN,
  SUM_TRIP_COUNTS,
} from './serverConstants';
import SpeedometerIcon from '../svg/speedometer.svg?react';
import TruckIcon from '../svg/truck.svg?react';
import DwellTimeIcon from '../svg/dwell-time.svg?react';
import TransitTimeIcon from '../svg/transit-time.svg?react';
import ClockIcon from '../svg/clock.svg?react';
import TrafficIcon from '../svg/traffic.svg?react';
import DustIcon from '../svg/dust.svg?react';
import SoundIcon from '../svg/sound.svg?react';
import RainIcon from '../svg/rain.svg?react';
import StatImpactIcon from '../svg/stat_impact.svg?react';

export default {
  [SUM_TRIP_COUNTS]: {
    value: SUM_TRIP_COUNTS,
    sortOptionMessage: defineMessage({
      defaultMessage: 'Trip counts',
      id: 'mnnTB9',
      description: 'Sort option for Total trip counts',
    }),
    titleMessage: defineMessage({
      defaultMessage: 'Max trip counts',
      id: 'mGdx0Z',
      description: 'Total trip counts panel title for trip counts',
    }),
    IconComponent: TruckIcon,
  },
  [SUM_DWELL_HOURS]: {
    value: SUM_DWELL_HOURS,
    sortOptionMessage: defineMessage({
      defaultMessage: 'Dwell hours',
      id: '1KINNV',
      description: 'Sort option for Total dwell hours',
    }),
    titleMessage: defineMessage({
      defaultMessage: 'Highest dwell hours',
      id: 'IAUamI',
      description: 'Total dwell hours panel title for dwell hours',
    }),
    IconComponent: DwellTimeIcon,
  },
  [SUM_TRUCK_HOURS]: {
    value: SUM_TRUCK_HOURS,
    sortOptionMessage: defineMessage({
      defaultMessage: 'Truck transit hours',
      id: 'i7dSRT',
      description: 'Sort option for Total truck hours',
    }),
    titleMessage: defineMessage({
      defaultMessage: 'Highest transit hours',
      id: 'D7dtQA',
      description: 'Total truck hours panel title for truck hours',
    }),
    IconComponent: TransitTimeIcon,
  },
  [MIN_SPEED]: {
    value: MIN_SPEED,
    sortOptionMessage: defineMessage({
      defaultMessage: 'Average speed',
      id: 'iJJCdL',
      description: 'Sort option for speed',
    }),
    titleMessage: defineMessage({
      defaultMessage: 'Lowest average speed',
      id: 'CbqEKq',
      description: 'Site performance panel title for speed',
    }),
    IconComponent: SpeedometerIcon,
  },
  [MAX_TIME_DELAY]: {
    value: MAX_TIME_DELAY,
    sortOptionMessage: defineMessage({
      defaultMessage: 'Delay',
      id: 'xutKIU',
      description: 'Sort option for delay',
    }),
    titleMessage: defineMessage({
      defaultMessage: 'Worst delay time',
      id: 'cLaRWZ',
      description: 'Site performance panel title for time delay',
    }),
    IconComponent: ClockIcon,
  },
  [MAX_QUEUE_LENGTH]: {
    value: MAX_QUEUE_LENGTH,
    sortOptionMessage: defineMessage({
      defaultMessage: 'Queue length',
      id: 'ANKJ6m',
      description: 'Sort option for queue length',
    }),
    titleMessage: defineMessage({
      defaultMessage: 'Worst queue length',
      id: 'DvdbMx',
      description: 'Site performance panel title for queue length',
    }),
    IconComponent: TrafficIcon,
  },
  [MAX_SITE_IMPACT_DELAY]: {
    value: MAX_SITE_IMPACT_DELAY,
    sortOptionMessage: defineMessage({
      defaultMessage: 'Site impact/Customer experience',
      id: '0+vezs',
      description: 'Sort option for site impact',
    }),
    titleMessage: defineMessage({
      defaultMessage: 'Site impact/Customer experience',
      id: 'mizrSQ',
      description: 'Site performance panel title for site impact',
    }),
    IconComponent: StatImpactIcon,
  },
  [MAX_DUST]: {
    value: MAX_DUST,
    sortOptionMessage: defineMessage({
      defaultMessage: 'Dust level',
      id: 'xAIl97',
      description: 'Sort option for dust',
    }),
    titleMessage: defineMessage({
      defaultMessage: 'Worst dust level',
      id: 'qBL5Rq',
      description: 'Site performance panel title for dust',
    }),
    IconComponent: DustIcon,
  },
  [MAX_NOISE]: {
    value: MAX_NOISE,
    sortOptionMessage: defineMessage({
      defaultMessage: 'Noise level',
      id: 't8tVcF',
      description: 'Sort option for site noise',
    }),
    titleMessage: defineMessage({
      defaultMessage: 'Worst noise level',
      id: 'zXfOn4',
      description: 'Site performance panel title for noise',
    }),
    IconComponent: SoundIcon,
  },
  [TOTAL_RAIN]: {
    value: TOTAL_RAIN,
    sortOptionMessage: defineMessage({
      defaultMessage: 'Total rain fall',
      id: '+6jYBe',
      description: 'Sort option for rain',
    }),
    titleMessage: defineMessage({
      defaultMessage: 'Total rain fall',
      id: 'L6ofUQ',
      description: 'Site performance panel title for rain fall',
    }),
    IconComponent: RainIcon,
  },
};
