import { useState } from 'react';
import { DisruptionPackResponse } from '@webapp/bff/src/types/report';
import { QuestionIcon } from '@chakra-ui/icons';
import {
  useDisclosure,
  Text,
  Tooltip,
  Stack,
  InputGroup,
  Input,
  InputRightAddon,
  Box,
  Button,
  Alert,
  AlertIcon,
  VStack,
  AlertTitle,
  AlertDescription,
  Checkbox,
  HStack,
  Tag,
  TagLabel,
  TagCloseButton,
} from '@chakra-ui/react';
import { useIntl, FormattedMessage } from 'react-intl';
import { emailValidator } from '../../utils/email';
import AlertBox from '../AlertBox';
import { DisruptionPackHistoryModal } from './DisruptionPackHistoryModal';
import { useActionWithFeedback } from '../../hooks/useActionWithFeedback';

export interface DisruptionPackSectionProps {
  currentUserEmail?: string;
  disruptionPacks: DisruptionPackResponse[];
  createDisruptionPack: (requestParams: {
    recipientEmails: string[];
    requestVolumeAnalysis: boolean;
  }) => Promise<void>;
  createDisruptionPackTrackProps?: {
    referrer: string;
    siteId: string;
  };
  hasDisruptionArea: boolean;
  plannedDates: {
    startDate?: string;
    endDate?: string;
  };
  goToDisruptionArea: () => void;
  goToSetDates: () => void;
}

export function DisruptionPackSection({
  currentUserEmail,
  disruptionPacks,
  createDisruptionPack,
  createDisruptionPackTrackProps,
  hasDisruptionArea,
  plannedDates,
  goToDisruptionArea,
  goToSetDates,
}: DisruptionPackSectionProps) {
  const { formatMessage } = useIntl();

  const [packRequestState, setPackRequestState] = useState({
    recipientEmails: currentUserEmail ? [currentUserEmail] : ([] as string[]),
    requestVolumeAnalysis: false,
  });
  const historyModal = useDisclosure();
  const pendingPack = disruptionPacks.find((pack) => pack.status === 'PENDING');
  const hasPlannedDates = Boolean(
    plannedDates.startDate && plannedDates.endDate
  );
  const areDatesFuture =
    hasPlannedDates && new Date(plannedDates.startDate!) > new Date();
  const isDisruptionPackInputValid =
    hasDisruptionArea &&
    hasPlannedDates &&
    packRequestState.recipientEmails.length > 0;

  const handleEmailAdded = (newEmail) => {
    setPackRequestState((prevState) => {
      const uniqueRecipientEmails = new Set([
        ...prevState.recipientEmails,
        newEmail,
      ]);
      return {
        ...prevState,
        recipientEmails: Array.from(uniqueRecipientEmails),
      };
    });
  };
  const handleEmailRemoved = (removedRecipientEmail: string) => {
    setPackRequestState((prevState) => ({
      ...prevState,
      recipientEmails: prevState.recipientEmails.filter(
        (recipientEmail) => recipientEmail !== removedRecipientEmail
      ),
    }));
  };

  const {
    performAction: submitDisruptionPackRequest,
    isPerformingAction: isSubmitting,
  } = useActionWithFeedback({
    action: () => createDisruptionPack(packRequestState),
    trackEvent: createDisruptionPackTrackProps && {
      name: 'Disruption pack created',
      data: {
        ...createDisruptionPackTrackProps,
        recipientEmails: packRequestState.recipientEmails,
        requestVolumeAnalysis: packRequestState.requestVolumeAnalysis,
      },
    },
    successMessage: {
      title: formatMessage({
        defaultMessage: 'Impact assessment sent',
        id: 'yu4JyZ',
      }),
      description: formatMessage({
        defaultMessage: 'It should arrive in your inbox shortly',
        id: 'L3URWd',
      }),
    },
  });

  return (
    <>
      <Stack spacing={4}>
        <Text marginBottom={0} fontSize="xs">
          <FormattedMessage
            defaultMessage="Send to team members who would find value in understanding potential
          disruption. Roles that are useful to include are those in
          Communications, Planning, Delivery, Traffic management and Client
          liaison."
            id="YHSDLo"
          />
        </Text>
        <Stack spacing={1}>
          <Text marginBottom={0} fontSize="xs">
            <FormattedMessage
              defaultMessage="Add recipients of impact assessment"
              id="2YAS7Q"
            />
          </Text>
          <AddEmailInput
            onEmailAdded={handleEmailAdded}
            isDisabled={!!pendingPack}
          />
        </Stack>
        {pendingPack && pendingPack.recipientEmails.length > 0 && (
          <Alert status="info">
            <AlertIcon />
            <VStack alignItems="left" spacing={1}>
              <AlertTitle fontSize="xs" lineHeight="4">
                <FormattedMessage
                  defaultMessage="Sending impact assessment to"
                  id="mvpyWI"
                />
              </AlertTitle>
              <AlertDescription fontSize="xs" lineHeight="4">
                <FormattedMessage
                  defaultMessage="{alertRecipientEmails}{br}
                  It should arrive within the next 10 minutes"
                  id="+nG2gZ"
                  values={{
                    br: <br />,
                    alertRecipientEmails:
                      pendingPack.recipientEmails.join(', '),
                  }}
                />
              </AlertDescription>
            </VStack>
          </Alert>
        )}
        {!pendingPack && packRequestState.recipientEmails.length > 0 && (
          <Stack spacing={2} align="start">
            {packRequestState.recipientEmails.map((recipientEmail) => (
              <Tag
                key={recipientEmail}
                size="sm"
                variant="solid"
                backgroundColor="purple.700"
              >
                <TagLabel>{recipientEmail}</TagLabel>
                <TagCloseButton
                  onClick={() => handleEmailRemoved(recipientEmail)}
                />
              </Tag>
            ))}
          </Stack>
        )}
        {hasPlannedDates && hasDisruptionArea && (
          <>
            <VStack alignItems="left" spacing={2}>
              <Text mb={0} fontSize="sm" fontWeight="bold" lineHeight={5}>
                <FormattedMessage
                  defaultMessage="Volume analysis"
                  id="bemT1F"
                />
              </Text>
              <Checkbox
                size="lg"
                isChecked={packRequestState.requestVolumeAnalysis}
                onChange={(e) => {
                  setPackRequestState((prevState) => ({
                    ...prevState,
                    requestVolumeAnalysis: e.target.checked,
                  }));
                }}
                isDisabled={!isDisruptionPackInputValid || !!pendingPack}
              >
                <Box fontSize="xs">
                  <FormattedMessage
                    defaultMessage="I would also like volume analysis on my project"
                    id="JZE/JW"
                  />
                </Box>
              </Checkbox>
            </VStack>
            <HStack spacing="10px">
              <Button
                variant="solid"
                colorScheme="greenDark"
                onClick={submitDisruptionPackRequest}
                isDisabled={!isDisruptionPackInputValid || !!pendingPack}
                isLoading={isSubmitting}
              >
                <FormattedMessage
                  defaultMessage="Send impact assessment"
                  id="P0fZYQ"
                />
              </Button>
              <Tooltip
                hasArrow
                label={formatMessage({
                  defaultMessage:
                    'The impact assessment will be emailed to the recipients above. If volume analysis is requested, we will contact you for further details.',
                  id: 'sbL/1e',
                })}
              >
                <QuestionIcon color="gray.700" width={6} height={6} />
              </Tooltip>
            </HStack>
          </>
        )}
        {disruptionPacks.length > 0 && (
          <Box>
            <Button variant="outline" size="xs" onClick={historyModal.onOpen}>
              <FormattedMessage defaultMessage="History" id="djJp6c" />
            </Button>
          </Box>
        )}
        {!hasDisruptionArea && (
          <AlertBox
            message={
              <FormattedMessage
                defaultMessage="In order to receive an impact assessment you'll need to add a disruption area."
                id="xVCV7U"
              />
            }
            cta={{
              label: (
                <FormattedMessage
                  defaultMessage="Add a disruption area"
                  id="U3yKPy"
                />
              ),
              action: goToDisruptionArea,
            }}
          />
        )}
        {!hasPlannedDates && (
          <AlertBox
            message={
              <FormattedMessage
                defaultMessage="In order to receive an impact assessment that includes public transport disruption we require a planned date for works."
                id="vfDd7P"
              />
            }
            cta={{
              label: (
                <FormattedMessage
                  defaultMessage="Add planned dates"
                  id="1iEwWt"
                />
              ),
              action: goToSetDates,
            }}
          />
        )}
        {hasPlannedDates && !areDatesFuture && (
          <AlertBox
            message={
              <FormattedMessage
                defaultMessage="In order to receive an impact assessment that includes public transport disruption we require future dates."
                id="j73Yzj"
              />
            }
            cta={{
              label: (
                <FormattedMessage
                  defaultMessage="Update planned dates"
                  id="xsM5lf"
                />
              ),
              action: goToSetDates,
            }}
          />
        )}
      </Stack>
      <DisruptionPackHistoryModal
        isOpen={historyModal.isOpen}
        onClose={historyModal.onClose}
        disruptionPacks={disruptionPacks}
      />
    </>
  );
}

function AddEmailInput({
  onEmailAdded,
  isDisabled,
}: {
  onEmailAdded: (newEmail: string) => void;
  isDisabled: boolean;
}) {
  const { formatMessage } = useIntl();
  const [currentRecipientEmail, setCurrentRecipientEmail] = useState('');
  const isCurrentRecipientEmailValid =
    currentRecipientEmail.length > 0 && emailValidator([currentRecipientEmail]);
  const handleCurrentRecipientEmailSubmit = () => {
    if (isCurrentRecipientEmailValid) {
      onEmailAdded(currentRecipientEmail.trim().toLowerCase());
      setCurrentRecipientEmail('');
    }
  };
  return (
    <InputGroup>
      <Input
        disabled={isDisabled}
        type="email"
        value={currentRecipientEmail}
        onChange={(event) => setCurrentRecipientEmail(event.target.value)}
        placeholder={formatMessage({
          defaultMessage: 'email address',
          id: 'eGwKE1',
        })}
        onKeyDown={(event) => {
          if (event.key === 'Enter') {
            handleCurrentRecipientEmailSubmit();
          }
        }}
      />
      <InputRightAddon
        as={Button}
        type="button"
        isDisabled={!isCurrentRecipientEmailValid}
        onClick={handleCurrentRecipientEmailSubmit}
      >
        +
      </InputRightAddon>
    </InputGroup>
  );
}
