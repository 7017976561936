import { ReactNode } from 'react';
import { Tooltip } from '@chakra-ui/react';

export default function InfoTooltip({
  content,
  children,
}: {
  content?: ReactNode;
  children: ReactNode;
}) {
  return (
    <Tooltip
      hasArrow
      placement="top"
      offset={[0, 10]}
      color="gray.900"
      fontWeight="normal"
      bgColor="rgba(239,239,239,1)"
      label={content}
    >
      {children}
    </Tooltip>
  );
}
