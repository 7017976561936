import { Fragment, useState } from 'react';
import { InfoWindow } from '@react-google-maps/api';
import {
  Button,
  Flex,
  Heading,
  Text,
  useToken,
  VStack,
} from '@chakra-ui/react';
import { useNavigate } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import { RouteSummary } from '../../data/useProjectRoutes';
import BaseMap from '../BaseMap';
import { MAP_DEFAULT_CENTER } from '../../constants/map';
import useMapBounds, { WithWkt } from '../../hooks/useMapBounds';
import { parseWkt } from '../../utils/routes';
import { pathSitesOverview } from '../../constants/path';
import LatLng = google.maps.LatLng;
import AnomalyIcon from './AnomalyIcon';
import { RoutesPerformanceLegend } from './RoutesPerformanceLegend';
import AnimatedRoute, {
  ANIM_BASE_SPEED,
  routesNearby,
  shouldRouteAnimated,
} from '../AnimatedRoute';

export interface RoutesMapProps {
  Wrapper: typeof BaseMap;
  insightId: string;
  routes: RouteSummary[];
  highlightedTrackId?: string;
  onHighlightedTrackIdChange: (trackId: string | undefined) => void;
}

interface ClickedRoutes {
  routes: RouteSummary[];
  clickLatLng: LatLng;
}

const DEFAULT_ROUTE_COLOR = '#4299E1';
const INACTIVE_ROUTE_COLOR = 'gray.400';
const ROUTES_MAP_OPTIONS: google.maps.MapOptions = {
  minZoom: 3,
  mapTypeControlOptions: {
    style: google.maps.MapTypeControlStyle.DEFAULT,
    position: google.maps.ControlPosition.BOTTOM_LEFT,
    mapTypeIds: [
      google.maps.MapTypeId.ROADMAP,
      google.maps.MapTypeId.SATELLITE,
    ],
  },
};

export default function RoutesMap({
  Wrapper,
  insightId,
  routes,
  highlightedTrackId,
  onHighlightedTrackIdChange,
}: RoutesMapProps): React.JSX.Element {
  const [mapInstance, setMapInstance] = useState<google.maps.Map>();
  const [mapZoom, setMapZoom] = useState(mapInstance?.getZoom());
  const [clickedRoutes, setClickedRoutes] = useState<ClickedRoutes | null>(
    null
  );
  const isRouteAnimated = shouldRouteAnimated(mapZoom);
  useMapBounds({
    mapInstance,
    routes: routes.filter((route) => route.wkt) as WithWkt[],
  });
  const navigate = useNavigate();
  return (
    <Wrapper
      onLoad={(map: google.maps.Map) => {
        setMapInstance(map);
        setMapZoom(map.getZoom());
      }}
      mapCenter={MAP_DEFAULT_CENTER}
      options={ROUTES_MAP_OPTIONS}
      onZoomChanged={() => {
        if (mapInstance) {
          setMapZoom(mapInstance.getZoom());
        }
      }}
      onMapClick={() => {
        setClickedRoutes(null);
      }}
    >
      {routes.map(
        (route) =>
          route.wkt && (
            <Fragment key={`animated_route_${route.trackId}`}>
              <AnomalyAnimatedRoute
                route={route}
                shouldAnimate={isRouteAnimated}
                showAnomalyScores
                selectedTrackId={highlightedTrackId}
                zIndex={
                  route.trackId === highlightedTrackId
                    ? 10000
                    : routeZIndex(route)
                }
                onRouteClick={(e) => {
                  if (!e.latLng) {
                    return;
                  }
                  const routesUnderClick = routesNearby<RouteSummary>({
                    selectedRoute: route,
                    routes,
                    point: e.latLng,
                    mapZoom,
                  });
                  routesUnderClick?.sort(
                    (route1, route2) =>
                      (route2.anomalyScore ?? 0) - (route1.anomalyScore ?? 0)
                  );
                  setClickedRoutes({
                    clickLatLng: e.latLng,
                    routes: routesUnderClick,
                  });
                }}
              />
            </Fragment>
          )
      )}
      {clickedRoutes && (
        <InfoWindow position={clickedRoutes.clickLatLng}>
          <VStack>
            <Heading size="sm">
              <FormattedMessage
                defaultMessage="Select route for more detail"
                id="9TnBll"
              />
            </Heading>
            {clickedRoutes.routes.map((route) => (
              <RouteLabel
                key={route.trackId}
                routeName={route.routeName ?? ''}
                anomalyScore={route.anomalyScore}
                anomalyScorePecentage={route.anomalyScorePercentage ?? ''}
                anomalyScoreColor={
                  route.anomalyScoreColor ?? INACTIVE_ROUTE_COLOR
                }
                onClick={() => {
                  navigate(
                    pathSitesOverview(insightId, 'routes', route.trackId)
                  );
                }}
                onMouseEnter={() => onHighlightedTrackIdChange(route.trackId)}
                onMouseLeave={() => onHighlightedTrackIdChange(undefined)}
              />
            ))}
          </VStack>
        </InfoWindow>
      )}
      <RoutesPerformanceLegend
        bottom="60px"
        left="50%"
        position="absolute"
        transform="translate(-50%, 0)"
      />
    </Wrapper>
  );
}

function RouteLabel({
  routeName,
  anomalyScore,
  anomalyScorePecentage,
  anomalyScoreColor,
  onClick,
  onMouseEnter,
  onMouseLeave,
}: {
  routeName: RouteSummary['routeName'];
  anomalyScore: RouteSummary['anomalyScore'];
  anomalyScorePecentage: RouteSummary['anomalyScorePercentage'];
  anomalyScoreColor: RouteSummary['anomalyScoreColor'];
  onClick: () => void;
  onMouseEnter: () => void;
  onMouseLeave: () => void;
}) {
  return (
    <Button
      borderRadius="6px"
      backgroundColor="white"
      padding="4px 6px"
      height="auto"
      fontSize="sm"
      fontFamily="Helvetica, Arial, sans-serif"
      width="full"
      onClick={onClick}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
    >
      <Flex as="span" justify="space-between" width="full">
        <Text as="span" fontSize="xs" fontWeight="light" paddingRight={2}>
          {routeName}
        </Text>
        <Flex as="span">
          <Text as="span" fontSize="xs" fontWeight="semibold" paddingRight={1}>
            {anomalyScorePecentage}
          </Text>
          <AnomalyIcon anomalyScore={anomalyScore} color={anomalyScoreColor} />
        </Flex>
      </Flex>
    </Button>
  );
}

const ANIM_MAX_SPEED = 5;
const ANIM_MIN_SPEED = 0.1;
const ANIM_VARIABILITY = 2;

function AnomalyAnimatedRoute({
  route,
  shouldAnimate,
  showAnomalyScores,
  zIndex = 0,
  selectedTrackId,
  onRouteClick,
}: {
  route: RouteSummary;
  shouldAnimate: boolean;
  showAnomalyScores: boolean;
  zIndex?: number;
  selectedTrackId?: string | undefined;
  onRouteClick: (e) => void;
}) {
  const highlightedRouteColor = useToken(
    'colors',
    route.anomalyScoreColor ?? INACTIVE_ROUTE_COLOR
  );
  const animationSpeed = !route.anomalyScore
    ? ANIM_BASE_SPEED
    : Math.max(
        Math.min(
          (ANIM_BASE_SPEED / route.anomalyScore) ** ANIM_VARIABILITY,
          ANIM_MAX_SPEED
        ),
        ANIM_MIN_SPEED
      );
  const routeColor = useToken(
    'colors',
    showAnomalyScores
      ? route.anomalyScoreColor ?? INACTIVE_ROUTE_COLOR
      : DEFAULT_ROUTE_COLOR
  );
  return route.wkt ? (
    <AnimatedRoute
      zIndex={zIndex}
      shouldAnimate={shouldAnimate}
      animationSpeed={animationSpeed}
      routeColor={routeColor}
      isHighlighted={selectedTrackId === route.trackId!}
      highlightedRouteColor={highlightedRouteColor}
      routePoints={parseWkt(route.wkt)}
      onRouteClick={onRouteClick}
    />
  ) : null;
}

function routeZIndex(route: RouteSummary): number {
  return route.anomalyScore ? Math.round(1000 * route.anomalyScore) : 0;
}
