import { Box, Button, Wrap, WrapItem } from '@chakra-ui/react';
import { useIntl } from 'react-intl';
import InfoTooltip from 'design-system/atoms/info-tooltip';
import {
  MESSAGE_BUTTON_CANCEL,
  MESSAGE_BUTTON_SAVE,
  MESSAGE_UI_DISABLED_SITE_PERMISSION,
} from '../constants/messages';

interface FormActionsProps {
  hasUserPermission?: boolean;
  onSave: () => void;
  onCancel?: () => void;
  isSaving: boolean;
  isDisabled?: boolean;
  error?: string;
  saveLabel?: string;
}

export default function FormActions({
  hasUserPermission = true,
  onSave,
  onCancel,
  isSaving,
  isDisabled,
  error,
  saveLabel,
}: FormActionsProps) {
  const { formatMessage } = useIntl();
  return (
    <>
      {error && (
        <Box color="red.500" fontSize="sm" mt={2}>
          {error}
        </Box>
      )}
      <Wrap spacing={2} mt={2}>
        <WrapItem>
          <InfoTooltip
            content={
              hasUserPermission
                ? undefined
                : formatMessage(MESSAGE_UI_DISABLED_SITE_PERMISSION)
            }
          >
            <Button
              variant="solid"
              colorScheme="brand"
              onClick={onSave}
              isDisabled={isSaving || isDisabled || !hasUserPermission}
              isLoading={isSaving}
              loadingText={saveLabel || formatMessage(MESSAGE_BUTTON_SAVE)}
              data-testid={`${formatMessage(MESSAGE_BUTTON_SAVE)}`}
            >
              {saveLabel || formatMessage(MESSAGE_BUTTON_SAVE)}
            </Button>
          </InfoTooltip>
        </WrapItem>
        {onCancel && (
          <WrapItem>
            <Button
              variant="outline"
              onClick={onCancel}
              isDisabled={isSaving || isDisabled}
            >
              {formatMessage(MESSAGE_BUTTON_CANCEL)}
            </Button>
          </WrapItem>
        )}
      </Wrap>
    </>
  );
}
