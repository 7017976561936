import { Box } from '@chakra-ui/react';
import { ComponentType } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import {
  RoutePollingStatusDataResponded,
  RoutePollingStatusDataFailure,
  RoutePollingStatusData,
} from '../../data/useRoutePollingStatusData';
import {
  DATE_PRESET_RECENT_LONG,
  TIME_PRESET_COMPLETE_HOUR12,
} from '../../utils/dateUtils';
import {
  intercomLinkFormatter,
  siteLinkFormatter,
} from '../../utils/messageUtils';

export function TextQuoteTitle({
  pollingStatus,
  responseAuthor = 'UNKNOWN',
}: {
  pollingStatus: RoutePollingStatusDataResponded;
  responseAuthor?: 'SELF' | 'UNKNOWN';
}) {
  const siteLink = siteLinkFormatter({
    siteId: pollingStatus.site.siteId,
    orgId: pollingStatus.orgId,
  });
  const { responseType } = pollingStatus.failure.userResponse;
  if (responseType === 'CLOSURE') {
    return (
      <FormattedMessage
        id="VHtKdi"
        defaultMessage="{routeName} route on your site <siteLink>{siteName}</siteLink> is currently closed."
        values={{
          routeName: pollingStatus.route.name,
          siteName: pollingStatus.site.siteName,
          siteLink,
        }}
      />
    );
  }
  if (responseType === 'ROAD_LAYOUT' || responseType === 'ROAD_LAYOUT_UPDATE') {
    return (
      <FormattedMessage
        id="KuONrr"
        defaultMessage="{routeName} route on your site <siteLink>{siteName}</siteLink> has recently experienced a road layout change."
        values={{
          routeName: pollingStatus.route.name,
          siteName: pollingStatus.site.siteName,
          siteLink,
        }}
      />
    );
  }
  if (responseType === 'ROAD_LAYOUT_UNAVAILABLE') {
    return (
      <FormattedMessage
        id="rMTjNn"
        defaultMessage="{routeName} route on your site <siteLink>{siteName}</siteLink> is no longer available and should stop monitoring."
        values={{
          routeName: pollingStatus.route.name,
          siteName: pollingStatus.site.siteName,
          siteLink,
        }}
      />
    );
  }
  if (responseType === 'UNKNOWN_CAUSE') {
    if (responseAuthor === 'SELF') {
      return (
        <FormattedMessage
          id="Z51bi1"
          defaultMessage="You’re unaware of the cause."
        />
      );
    }
    return (
      <FormattedMessage
        id="YhQH/a"
        defaultMessage="{routeName} route on your site <siteLink>{siteName}</siteLink> has no expected reason to be failing and I am unsure of the reasoning for this."
        values={{
          routeName: pollingStatus.route.name,
          siteName: pollingStatus.site.siteName,
          siteLink,
        }}
      />
    );
  }
  if (responseType === 'KNOWN_CAUSE') {
    if (responseAuthor === 'SELF') {
      return (
        <FormattedMessage
          id="UIj4Co"
          defaultMessage="You know the likely cause."
        />
      );
    }
    return (
      <FormattedMessage id="BYJeGm" defaultMessage="I know the likely cause." />
    );
  }
  return null;
}

export function TextExpectedOutcome({
  pollingStatus,
  responseAuthor = 'UNKNOWN',
  Wrapper = ({ children }) => <Box fontSize="2xl">{children}</Box>,
}: {
  pollingStatus: RoutePollingStatusDataResponded;
  responseAuthor?: 'SELF' | 'UNKNOWN';
  Wrapper?: ComponentType<{ children: React.ReactNode }>;
}) {
  switch (pollingStatus.failure.userResponse.responseType) {
    case 'UNKNOWN_CAUSE':
      return (
        <Wrapper>
          {responseAuthor === 'SELF' ? (
            <FormattedMessage
              id="M1Aanq"
              defaultMessage="Our team will investigate and get back to you shortly."
            />
          ) : (
            <FormattedMessage
              id="j1QjRb"
              defaultMessage="Our team is currently investigating the issue."
            />
          )}
        </Wrapper>
      );
    case 'ROAD_LAYOUT_UNAVAILABLE':
    case 'ROAD_LAYOUT_UPDATE':
      return (
        <Wrapper>
          <FormattedMessage
            id="wYOpiW"
            defaultMessage="Our team will update the route as soon as possible. If you think this requires urgent action, <supportLink>please contact support.</supportLink>"
            values={{ supportLink: intercomLinkFormatter() }}
          />
        </Wrapper>
      );
    default:
      return null;
  }
}

export function TextRecordedResponse({
  pollingStatus,
}: {
  pollingStatus: RoutePollingStatusDataFailure;
}) {
  const siteLink = siteLinkFormatter({
    siteId: pollingStatus.site.siteId,
    orgId: pollingStatus.orgId,
  });
  switch (pollingStatus.failure?.userResponse?.responseType) {
    case 'CLOSURE':
      return (
        <FormattedMessage
          id="E9mggr"
          defaultMessage="We have recorded that the {routeName} route on your site <siteLink>{siteName}</siteLink> is currently closed."
          values={{
            siteName: pollingStatus.site.siteName,
            routeName: pollingStatus.route.name,
            siteLink,
          }}
        />
      );
    case 'ROAD_LAYOUT':
    case 'ROAD_LAYOUT_UPDATE':
    case 'ROAD_LAYOUT_UNAVAILABLE':
      return (
        <FormattedMessage
          id="Kq4+31"
          defaultMessage="We have recorded that the {routeName} route on your site <siteLink>{siteName}</siteLink> has recently experienced a road layout change."
          values={{
            siteName: pollingStatus.site.siteName,
            routeName: pollingStatus.route.name,
            siteLink,
          }}
        />
      );
    default:
      return <TextFailureStatus pollingStatus={pollingStatus} />;
  }
}
export function TextFailureStatus({
  pollingStatus,
}: {
  pollingStatus: RoutePollingStatusDataFailure;
}) {
  const { formatDate } = useIntl();
  const lastPolledDate = formatDate(pollingStatus.failure.failStartDate, {
    timeZone: pollingStatus.site.siteTimeZone,
    ...DATE_PRESET_RECENT_LONG,
  });
  const lastPolledTime = formatDate(pollingStatus.failure.failStartDate, {
    timeZone: pollingStatus.site.siteTimeZone,
    ...TIME_PRESET_COMPLETE_HOUR12,
  });
  const siteLink = siteLinkFormatter({
    siteId: pollingStatus.site.siteId,
    orgId: pollingStatus.orgId,
  });
  return (
    <FormattedMessage
      id="PJDhl+"
      defaultMessage="Since {lastPolled} we have had issues with collecting journey time for the {routeName} route on your site <siteLink>{siteName}</siteLink>."
      values={{
        siteName: pollingStatus.site.siteName,
        routeName: pollingStatus.route.name,
        lastPolled: `${lastPolledDate} ${lastPolledTime}`,
        siteLink,
      }}
    />
  );
}

export function TextResolvedStatus({
  pollingStatus,
}: {
  pollingStatus: RoutePollingStatusData;
}) {
  const { monitorStarted, active } = pollingStatus.route;
  if (active) {
    return (
      <FormattedMessage
        id="kflNpN"
        defaultMessage="This route is currently collecting data - any data collection issues have been resolved. You can always <supportLink>get in touch</supportLink> with us if you have any questions."
        values={{
          supportLink: intercomLinkFormatter(),
        }}
      />
    );
  }
  if (!monitorStarted) {
    return (
      <FormattedMessage
        id="t2ZmEF"
        defaultMessage="Monitoring has not started on this route. You can always <supportLink>get in touch</supportLink> with us if you have any questions."
        values={{
          supportLink: intercomLinkFormatter(),
        }}
      />
    );
  }
  return (
    <FormattedMessage
      id="2V0cTI"
      defaultMessage="Monitoring has been disabled on this route. You can always <supportLink>get in touch</supportLink> with us if you have any questions."
      values={{
        supportLink: intercomLinkFormatter(),
      }}
    />
  );
}
