import { memo, useEffect, useRef, useState } from 'react';
import { Box, IconButton, useDisclosure } from '@chakra-ui/react';
import NoteIcon from '../../svg/note.svg?react';
import Overlay from './Overlay';
import { useAnalytics } from '../../hooks/analytics/useAnalytics';
import OverlayContent from './OverlayContent';
import useParamInsightId from '../../hooks/useParamInsightId';
import useSiteView from '../../hooks/useSiteView';
import { useCurrentSiteData } from '../../data/useSiteData';
import useAnnotationsData from '../../data/useAnnotationsData';
import { hasDataHandlerFeedBack } from '../../utils/dataHandler';
import { useScrollPage } from '../../hooks/useScrollPage';
import { FEATURE_PLAN_INSIGHT_TYPE_SELECTOR } from '../../constants/features';
import useFeatureSwitch, {
  FeatureSwitchState,
} from '../../hooks/useFeatureSwitch';
import useIncidentsData from '../../data/useIncidentsData';
import {
  AnnotatedEvent,
  AnnotationSource,
  AnnotationType,
} from '../../utils/annotations';

function AnnotationsOverlay() {
  const featurePlanInsightTypeSelector = useFeatureSwitch(
    FEATURE_PLAN_INSIGHT_TYPE_SELECTOR
  );
  const isFeaturePlanInsightTypeSelector =
    featurePlanInsightTypeSelector === FeatureSwitchState.ON;
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { setSessionTag } = useAnalytics();
  const [overlayBottomEl, setOverlayBottomEl] = useState<HTMLDivElement | null>(
    null
  );
  useEffect(() => {
    setSessionTag('annotations', true);
  }, [setSessionTag]);
  const btnRef = useRef<HTMLButtonElement>(null);
  const onCloseWithFocus = () => {
    onClose();
    btnRef.current?.focus();
  };
  const insightId = useParamInsightId();
  const siteView = useSiteView();
  const currentSiteDataHandler = useCurrentSiteData();
  const annotationsDataHandler = useAnnotationsData(
    insightId,
    siteView?.state.siteId
  );
  const incidentsDataHandler = useIncidentsData();

  useScrollPage(overlayBottomEl, () => {
    annotationsDataHandler.setSize((currSize) => currSize + 1);
  });
  if (
    hasDataHandlerFeedBack([
      annotationsDataHandler,
      currentSiteDataHandler,
      incidentsDataHandler,
    ]) ||
    !siteView
  ) {
    return null;
  }
  const allAnnotations: AnnotatedEvent[] = annotationsDataHandler.data.map(
    (a) => ({
      ...a,
      id: `${a.annotationId}`,
      type: 'ManualAnnotation' as AnnotationType,
      startDate: a.startDate!,
      source: 'Manual' as AnnotationSource,
      manualContent: a,
    })
  );
  if (incidentsDataHandler.data) {
    allAnnotations.push(
      ...incidentsDataHandler.data.map((i) => ({
        id: i.id,
        startDate: i.startTime / 1000,
        endDate: (i.endTime ?? 0) / 1000,
        type: i.type,
        title: i.type,
        description: i.description,
        source: i.datasource,
      }))
    );
  }
  return (
    <>
      <Box
        pos="fixed"
        top={{
          base: isFeaturePlanInsightTypeSelector ? '191px' : '190px',
          md: isFeaturePlanInsightTypeSelector ? '206px' : '200px',
          lg: isFeaturePlanInsightTypeSelector ? '80px' : '64px',
        }}
        bottom={1}
        right={isFeaturePlanInsightTypeSelector ? 8 : 4}
        style={{ zIndex: 2 }}
      >
        <IconButton
          top={0}
          right={0}
          pos="absolute"
          ref={btnRef}
          bg="white"
          onClick={onOpen}
          aria-label="Open annotations"
          variant="ghost"
          layerStyle="floating"
        >
          <NoteIcon />
        </IconButton>
      </Box>
      <Overlay isOpen={isOpen} onClose={onCloseWithFocus}>
        <OverlayContent
          onClose={onClose}
          annotations={allAnnotations}
          siteView={siteView}
          selectedSiteData={currentSiteDataHandler.data!}
          createAnnotation={annotationsDataHandler.createAnnotation}
          deleteAnnotation={annotationsDataHandler.deleteAnnotation}
          updateAnnotation={annotationsDataHandler.updateAnnotation}
          resendAnnotationAlert={annotationsDataHandler.resendAnnotationAlert}
          overlayBottomRef={(el) => {
            if (el !== overlayBottomEl) {
              setOverlayBottomEl(el);
            }
          }}
          isFetching={annotationsDataHandler.isValidating}
          isReachingEnd={annotationsDataHandler.isReachingEnd}
        />
      </Overlay>
    </>
  );
}
export default memo(AnnotationsOverlay);
