import { createContext, memo, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import {
  Box,
  CheckboxGroup,
  FormControl,
  FormLabel,
  Switch,
  useToken,
  VStack,
} from '@chakra-ui/react';
import {
  Marker,
  TrafficLayer,
  InfoWindow,
  Polygon,
} from '@react-google-maps/api';
import { WorkArea } from '@webapp/bff/src/types/work-area';
import { Incident } from '@data-pipeline/lib-collection/src/types/incidents';
import MapLayerIncidents from 'design-system/atoms/custom-google-map/map-layer-incidents';
import Checkbox from 'design-system/molecules/checkbox';
import { ActiveVms } from '@webapp/bff/src/types/vms';
import countSource from '../../svg/count-source.svg?inline';
import { MAP_DEFAULT_CENTER, MAP_FEATURE_OPTIONS } from '../../constants/map';
import useMapBounds from '../../hooks/useMapBounds';
import { parseWkt, toggleArrayItem } from '../../utils/routes';
import { Route } from '../../types/route';
import { MapInsightsData } from '../../data/useMapInsightsData';
import { Site } from '../../types/site';
import TrafficInsightMarker, { TrafficIntensity } from './TrafficInsightMarker';
import AnimatedRoute, {
  routesNearby,
  shouldRouteAnimated,
} from '../AnimatedRoute';
import BaseMap from '../BaseMap';
import LatLng = google.maps.LatLng;
import { SiteView } from '../../hooks/useSiteView';
import MapLayerVmsBoardsWithPreview from '../SmartVms/VmsMapLayerWithPreview';

const MS_15_MINUTES = 1000 * 60 * 15;

type LatLngLiteral = google.maps.LatLngLiteral;
interface SiteViewMapProps {
  site: Site;
  workAreas: WorkArea[];
  routes: Route[];
  vms?: ActiveVms[];
  mapInsights?: MapInsightsData;
  hiddenRoutes: number[];
  hiddenWorkAreas: string[];
  hiddenVms: string[];
  vmsColors: Record<string, string>;
  routesColors: Record<string, string>;
  siteLocation?: LatLngLiteral;
  incidents?: Incident[];
  setHiddenRoutes: SiteView['setHiddenRoutes'];
  orgId: string;
  deleteVmsBoard: (vmsConfigId: number) => Promise<void>;
}

interface RoutesNearby {
  routes: Route[];
  position: LatLng;
}

const NON_SELECTED_ROUTE_COLOR = 'gray.400';

const MapHighlightContext = createContext<
  [string | undefined, (id: string | undefined) => void]
>([undefined, () => {}]);
function MapHighlightContextProvider({
  children,
}: {
  children: React.ReactNode;
}) {
  const stateHook = useState<string | undefined>();
  return (
    <MapHighlightContext.Provider value={stateHook}>
      {children}
    </MapHighlightContext.Provider>
  );
}

export default memo(SiteViewMap);
function SiteViewMap({
  site,
  workAreas,
  routes,
  vms,
  incidents,
  mapInsights,
  hiddenRoutes,
  hiddenWorkAreas,
  hiddenVms,
  vmsColors,
  routesColors,
  siteLocation,
  setHiddenRoutes,
  orgId,
  deleteVmsBoard,
}: SiteViewMapProps) {
  const vmsWithColors = vms?.map((vm) => ({
    ...vm,
    color: vmsColors[vm.vmsConfigId],
  }));
  const countRoutes = (mapInsights?.count?.data || [])
    .filter(
      (countRoute) =>
        hiddenRoutes.indexOf(Number(countRoute.name.replace('_', ''))) === -1
    )
    .map((countRoute) =>
      routes.find((route) => route.routeId === countRoute.routeId)
    );
  const { mediumTrafficRoutes, highTrafficRoutes } = getCongestedRoutes(
    mapInsights,
    hiddenRoutes
  );
  const visibleTrackIds = routes
    .filter(
      (route) =>
        hiddenRoutes.indexOf(Number(route.trackId.replace('_', ''))) === -1
    )
    .map((route) => route.trackId);

  const [mapInstance, setMapInstance] = useState<google.maps.Map>();
  const [showRoutesNearby, setShowRoutesNearby] = useState<RoutesNearby | null>(
    null
  );
  const [mapZoom, setMapZoom] = useState(mapInstance?.getZoom());
  const [showTrafficLayer, setShowTrafficLayer] = useState(false);
  const nonSelectedRouteColor = useToken('colors', NON_SELECTED_ROUTE_COLOR);
  const mapLocation = siteLocation
    ? ({ lat: siteLocation.lat, lng: siteLocation.lng } as LatLngLiteral)
    : MAP_DEFAULT_CENTER;
  useMapBounds({
    mapInstance,
    workAreas,
    routes,
    defaultCenter: mapLocation,
  });
  return (
    <MapHighlightContextProvider>
      <Box
        w="100%"
        h="100%"
        position="relative"
        data-ignore-visual-test-conditional
      >
        <BaseMap
          onLoad={(map) => {
            setMapInstance(map);
            setMapZoom(map.getZoom());
          }}
          options={MAP_FEATURE_OPTIONS}
          onZoomChanged={() => {
            if (mapInstance) {
              setMapZoom(mapInstance.getZoom());
            }
          }}
          onMapClick={() => {
            setShowRoutesNearby(null);
          }}
        >
          <MapLayerIncidents incidents={incidents} />
          {vmsWithColors && (
            <MapLayerVmsBoardsWithPreview
              orgId={orgId}
              deleteVmsBoard={deleteVmsBoard}
              siteId={String(site.siteId)}
              vmsBoards={vmsWithColors.filter(
                (vm) => hiddenVms.indexOf(vm.vmsConfigId) === -1
              )}
            />
          )}
          {(workAreas || [])
            .filter((area) => hiddenWorkAreas.indexOf(area.id) === -1)
            .map((area) => (
              <Polygon
                key={area.id}
                options={{
                  strokeColor: area.fillColor,
                  fillColor: area.fillColor,
                  fillOpacity: area.fillTransparency,
                  strokeWeight: 3,
                  editable: false,
                }}
                path={parseWkt(area.wkt)}
              />
            ))}
          <MapHighlightContext.Consumer>
            {([selectedTrackId, setSelectedTrackId]) => [
              false,
              routes.map((route) => (
                <AnimatedRoute
                  key={route.trackId}
                  zIndex={visibleTrackIds.includes(route.trackId) ? 2 : 1}
                  shouldAnimate={
                    shouldRouteAnimated(mapZoom) &&
                    visibleTrackIds.includes(route.trackId)
                  }
                  isHighlighted={selectedTrackId === route.trackId}
                  animationSpeed={0.5}
                  routeColor={
                    visibleTrackIds.includes(route.trackId)
                      ? routesColors[route.trackId]
                      : nonSelectedRouteColor
                  }
                  routePoints={parseWkt(route.wkt)}
                  onRouteClick={(e) => {
                    if (!e.latLng) {
                      return;
                    }
                    const routesNearbyCurrentRoute = routesNearby<Route>({
                      selectedRoute: route,
                      routes,
                      point: e.latLng,
                      mapZoom,
                    });
                    routesNearbyCurrentRoute.sort((routeA, routeB) =>
                      routeA.name > routeB.name ? 1 : -1
                    );
                    setShowRoutesNearby({
                      position: e.latLng,
                      routes: routesNearbyCurrentRoute,
                    });
                  }}
                />
              )),
              showRoutesNearby && (
                <InfoWindow position={showRoutesNearby.position}>
                  <CheckboxGroup>
                    <VStack m="10px" spacing={4} align="start">
                      {showRoutesNearby.routes.map((route) => (
                        <Checkbox
                          key={route.routeId}
                          color={routesColors[route.trackId]}
                          checked={
                            hiddenRoutes.indexOf(
                              Number(route.trackId.replace('_', ''))
                            ) === -1
                          }
                          onMouseEnter={() => setSelectedTrackId(route.trackId)}
                          onMouseLeave={() => setSelectedTrackId(undefined)}
                          onChange={() =>
                            setHiddenRoutes(
                              toggleArrayItem(
                                Number(route.trackId.replace('_', '')),
                                hiddenRoutes
                              )
                            )
                          }
                        >
                          {route.name}
                        </Checkbox>
                      ))}
                    </VStack>
                  </CheckboxGroup>
                </InfoWindow>
              ),
            ]}
          </MapHighlightContext.Consumer>
          {countRoutes.map((route) => (
            <Marker
              key={route.routeId}
              position={route.startPoint}
              icon={countSource as unknown as string}
            />
          ))}
          {mapInsights &&
            mediumTrafficRoutes.map((routeId) => (
              <TrafficInsightMarker
                key={routeId}
                routeId={routeId}
                trafficDensity={TrafficIntensity.MEDIUM}
                routes={routes}
                mapInsights={mapInsights}
                site={site}
              />
            ))}
          {mapInsights &&
            highTrafficRoutes.map((routeId) => (
              <TrafficInsightMarker
                key={routeId}
                routeId={routeId}
                trafficDensity={TrafficIntensity.HIGH}
                routes={routes}
                mapInsights={mapInsights}
                site={site}
              />
            ))}
          {showTrafficLayer && <TrafficLayer />}
        </BaseMap>
        <FormControl
          display="flex"
          position="absolute"
          top="60px"
          left="10px"
          width="167px"
          padding={3}
          backgroundColor="gray.50"
          alignItems="center"
        >
          <Switch
            colorScheme="green"
            checked={showTrafficLayer}
            onChange={(e) => {
              setShowTrafficLayer(e.target.checked);
            }}
          />
          <FormLabel margin={0} pl={3} fontSize="14px">
            <FormattedMessage
              defaultMessage="Live traffic"
              id="FWI0Lp"
              description="map switch label for displaying traffic layer"
            />
          </FormLabel>
        </FormControl>
      </Box>
    </MapHighlightContextProvider>
  );
}

function getCongestedRoutes(mapInsights, hiddenRoutes) {
  const delayInsight =
    mapInsights?.siteImpactDelay?.data || mapInsights?.timeDelay?.data;
  return (delayInsight || []).reduce(
    (acc, route) => {
      if (hiddenRoutes.indexOf(Number(route.name.replace('_', ''))) > -1) {
        return acc;
      }
      const pointDate = route.items?.[0]?.date;
      if (!pointDate || Date.now() - pointDate * 1000 > MS_15_MINUTES) {
        return acc;
      }
      const pointValue = route.items?.[0]?.value;
      if (pointValue >= 300) {
        acc.highTrafficRoutes.push(route.routeId);
      } else if (pointValue < 300 && pointValue > 120) {
        acc.mediumTrafficRoutes.push(route.routeId);
      }
      return acc;
    },
    {
      mediumTrafficRoutes: [],
      highTrafficRoutes: [],
    }
  );
}
