import './EditSiteProperties.scss';
import { useEffect, useRef, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { Text } from '@chakra-ui/react';
import useForm, { useFormValidation } from '../hooks/useForm';
import useRequestStatus from '../hooks/useRequestStatus';
import {
  validateNumeric,
  validateRequired,
  validatorWithFormatter,
} from '../utils/validation';
import {
  MESSAGE_CANCEL_UNSAVED_CHANGES,
  MESSAGE_GENERIC_ERROR,
  MESSAGE_UNIT_SYSTEM_IMPERIAL,
  MESSAGE_UNIT_SYSTEM_METRIC,
} from '../constants/messages';
import {
  UNIT_SYSTEM_TYPE_IMPERIAL,
  UNIT_SYSTEM_TYPE_METRIC,
} from '../constants/serverConstants';
import {
  MAX_SITE_NAME_LENGTH,
  TIME_ZONE_OPTIONS,
} from '../constants/clientConstants';
import { Site } from '../types/site';
import FormField from './FormField';
import FormActions from './FormActions';
import {
  convertDistanceDisplayToValue,
  convertDistanceValueToDisplay,
  convertSpeedDisplayToValue,
  convertSpeedValueToDisplay,
  minutesDisplayToSecondsValue,
  secondsValueToMinutesDisplay,
} from '../utils/units';
import { useAnalytics } from '../hooks/analytics/useAnalytics';
import PlacesSearchField from './PlacesSearchField';

export interface EditSitePropertiesProps {
  updateSiteProps: (...args: any[]) => Promise<void | Response>;
  siteHasQueue: boolean;
  isUserReadOnly?: boolean;
  onUnsavedStatusChange?: (status: boolean) => void;
  site: Site;
  mapReference?: google.maps.Map;
}

interface SitePropertiesFormValues extends Record<string, unknown> {
  addressSearch: string;
  siteName: string;
  siteDescription: string;
  siteAddress: string;
  siteTimeZone: string;
  siteUnit: string;
  delayKPI: string;
  speedKPI: string;
  queueKPI: string;
}

export default function EditSiteProperties({
  updateSiteProps,
  siteHasQueue,
  isUserReadOnly,
  onUnsavedStatusChange,
  site,
  mapReference,
}: EditSitePropertiesProps) {
  const { formatMessage } = useIntl();
  const { track } = useAnalytics();
  const checkRequiredError = validatorWithFormatter(
    validateRequired,
    formatMessage
  );
  const checkNumericError = validatorWithFormatter(
    validateNumeric,
    formatMessage
  );
  const [isSaving, hasSaveError, withSaveStatus, clearSaveError] =
    useRequestStatus();
  const [isUpdatingLocation, setIsUpdatingLocation] = useState(false);
  const siteForm = useForm(extractFormValuesFromSite(site));
  const siteFormValidation = useFormValidation(siteForm, {
    siteName: checkRequiredError,
    siteAddress: checkRequiredError,
    delayKPI: checkNumericError,
    speedKPI: checkNumericError,
    queueKPI: checkNumericError,
  });
  const siteNameChangeHandler = (e) => {
    const trimmedValue = e.target.value.substring(0, MAX_SITE_NAME_LENGTH);
    track('Site Property Changed', {
      property: 'Title',
      referrer: 'Edit Site Tab',
      value: trimmedValue,
    });
    siteForm.fields.siteName.setValue(trimmedValue);
  };
  const hasUnsavedValuesRef = useRef(false);
  const hasUnsavedValues = isSiteDifferentThanFormValues(site, siteForm.values);

  useEffect(() => {
    if (hasUnsavedValues !== hasUnsavedValuesRef.current) {
      hasUnsavedValuesRef.current = hasUnsavedValues;
      if (onUnsavedStatusChange) {
        onUnsavedStatusChange(hasUnsavedValues);
      }
    }
  }, [hasUnsavedValues, onUnsavedStatusChange]);

  const resetForm = () => {
    if (
      !hasUnsavedValues ||
      window.confirm(formatMessage(MESSAGE_CANCEL_UNSAVED_CHANGES))
    ) {
      siteForm.reset();
      clearSaveError();
    }
  };

  const save = () => {
    if (siteFormValidation.validate()) {
      const transformedFormValues = {
        ...siteForm.values,
        delayKPI: minutesDisplayToSecondsValue(siteForm.values.delayKPI),
        speedKPI: convertSpeedDisplayToValue(
          siteForm.values.speedKPI,
          site.siteUnit
        ),
        queueKPI: convertDistanceDisplayToValue(
          siteForm.values.queueKPI,
          site.siteUnit
        ),
      };
      withSaveStatus(updateSiteProps)(site.siteId, transformedFormValues).then(
        () => {
          siteForm.reset();
        }
      );
    }
  };

  return (
    <div className="v2-edit-site-properties">
      <FormField
        label={formatMessage({
          defaultMessage: 'Title',
          id: 'wLP4Qg',
          description: 'Edit site name field label',
        })}
        value={siteForm.fields.siteName.value}
        onChange={siteNameChangeHandler}
        dataTestId="edit-site-title"
        disabled={isSaving}
        error={siteFormValidation.fields.siteName.error}
      />
      {siteForm.fields.siteName.value.length > MAX_SITE_NAME_LENGTH - 16 && (
        <Text mt="-12px" mb="12px" fontSize="12px">
          <FormattedMessage
            defaultMessage="characters left: {charCount}"
            id="KrjaEY"
            description="max char count for site name"
            values={{
              charCount:
                MAX_SITE_NAME_LENGTH - siteForm.fields.siteName.value.length,
            }}
          />
        </Text>
      )}
      <PlacesSearchField
        fieldProps={{
          dataTestId: 'edit-site-location',
          label: formatMessage({
            defaultMessage: 'Location',
            id: '+SMf4k',
            description: 'Edit site location field label',
          }),
          error: siteFormValidation.fields.siteAddress.error,
          disabled: isSaving || isUpdatingLocation,
        }}
        value={siteForm.fields.addressSearch.value}
        onChange={siteForm.fields.addressSearch.onChange}
        onBlur={() => {
          siteForm.setValues((asyncValues) => ({
            ...asyncValues,
            addressSearch: asyncValues.siteAddress,
          }));
        }}
        onPlaceSelected={(selectedPlace) => {
          setIsUpdatingLocation(true);
          track('Site Property Changed', {
            property: 'Location',
            referrer: 'Edit Site Tab',
            value: selectedPlace.label,
          });
          siteForm.setValues((asyncValues) => ({
            ...asyncValues,
            siteAddress: selectedPlace.label,
            addressSearch: selectedPlace.label,
          }));
        }}
        onPlaceDetailsReceived={(place) => {
          const placeLocation = place?.geometry?.location?.toJSON();
          if (placeLocation) {
            siteForm.fields.siteLocation.setValue(placeLocation);
          }
          setIsUpdatingLocation(false);
        }}
        placesBias={{ location: mapReference?.getCenter(), radius: 50000 }}
      />
      <FormField
        label={formatMessage({
          defaultMessage: 'Time zone',
          id: 'FFEE1X',
          description: 'Edit site time zone field label',
        })}
        value={siteForm.fields.siteTimeZone.value}
        dataTestId="edit-site-timezone"
        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
          track('Site Property Changed', {
            property: 'Timezone',
            referrer: 'Edit Site Tab',
            value: e.target.value,
          });
          siteForm.fields.siteTimeZone.onChange(e);
        }}
        disabled={isSaving}
        options={TIME_ZONE_OPTIONS}
      />
      <FormField
        label={formatMessage({
          defaultMessage: 'Unit system',
          id: '9shvsD',
          description: 'Edit site unit system field label',
        })}
        dataTestId="edit-site-unitsystem"
        value={siteForm.fields.siteUnit.value}
        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
          track('Site Property Changed', {
            property: 'Unit System',
            referrer: 'Edit Site Tab',
            value: e.target.value,
          });
          siteForm.fields.siteUnit.onChange(e);
        }}
        disabled={isSaving}
        options={[
          {
            value: UNIT_SYSTEM_TYPE_METRIC,
            label: formatMessage(MESSAGE_UNIT_SYSTEM_METRIC),
          },
          {
            value: UNIT_SYSTEM_TYPE_IMPERIAL,
            label: formatMessage(MESSAGE_UNIT_SYSTEM_IMPERIAL),
          },
        ]}
      />
      <FormField
        multiline
        label={formatMessage({
          defaultMessage: 'Description',
          id: 'Wowq70',
          description: 'Site description field label',
        })}
        dataTestId="edit-site-description"
        value={siteForm.fields.siteDescription.value}
        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
          track('Site Property Changed', {
            property: 'Description',
            referrer: 'Edit Site Tab',
            value: e.target.value,
          });
          siteForm.fields.siteDescription.onChange(e);
        }}
        disabled={isSaving}
      />

      <div className="v2-edit-site-properties__highlight">
        <div className="v2-edit-site-properties__highlight-intro">
          <FormattedMessage
            defaultMessage="These are the limits that you want to operate within, these limits are also used in SMS alerts."
            id="0GWzfS"
            description="Intro text of site KPIs management panel."
          />
        </div>
        <FormField
          type="number"
          label={formatMessage({
            defaultMessage: 'Delay KPI (minutes)',
            id: 'EnmnE6',
            description: 'Edit site Delay KPI field label',
          })}
          dataTestId="edit-site-delaykpi"
          value={siteForm.fields.delayKPI.value}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
            track('Site Property Changed', {
              referrer: 'Edit Site Tab',
              property: 'Delay KPI',
              value: e.target.value,
              routesCount: site.routes?.length || 0,
            });
            siteForm.fields.delayKPI.onChange(e);
          }}
          disabled={isSaving}
          error={siteFormValidation.fields.delayKPI.error}
        />
        <FormField
          type="number"
          label={formatMessage(
            {
              defaultMessage:
                'Speed KPI {unitType, select, IMPERIAL {(mph)} other {(km/h)}}',
              id: 'pjNroE',
              description: 'Edit site Speed KPI field label',
            },
            {
              unitType: site.siteUnit,
            }
          )}
          dataTestId="edit-site-speedkpi"
          value={siteForm.fields.speedKPI.value}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
            track('Site Property Changed', {
              referrer: 'Edit Site Tab',
              property: 'Speed KPI',
              value: e.target.value,
              routesCount: site.routes?.length || 0,
            });
            siteForm.fields.speedKPI.onChange(e);
          }}
          disabled={isSaving}
          error={siteFormValidation.fields.speedKPI.error}
        />
        {siteHasQueue && (
          <FormField
            type="number"
            label={formatMessage(
              {
                defaultMessage:
                  'Queue KPI {unitType, select, IMPERIAL {(miles)} other {(meters)}}',
                id: '13u+Nr',
                description: 'Edit site Queue KPI field label',
              },
              {
                unitType: site.siteUnit,
              }
            )}
            dataTestId="edit-site-queuekpi"
            value={siteForm.fields.queueKPI.value}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              track('Site Property Changed', {
                referrer: 'Edit Site Tab',
                property: 'Queue KPI',
                value: e.target.value,
                routesCount: site.routes?.length || 0,
              });
              siteForm.fields.queueKPI.onChange(e);
            }}
            step={site.siteUnit === 'IMPERIAL' ? 0.1 : 1}
            disabled={isSaving}
            error={siteFormValidation.fields.queueKPI.error}
          />
        )}
      </div>
      <FormActions
        hasUserPermission={!isUserReadOnly}
        onSave={() => {
          save();
          track('Site Changes Saved', {
            referrer: 'Edit Site Tab',
          });
        }}
        onCancel={() => {
          resetForm();
          track('Site Changes Cancelled', {
            referrer: 'Edit Site Tab',
          });
        }}
        isSaving={isSaving}
        isDisabled={isUpdatingLocation}
        error={hasSaveError ? formatMessage(MESSAGE_GENERIC_ERROR) : undefined}
      />
    </div>
  );
}

function extractFormValuesFromSite(site: Site): SitePropertiesFormValues {
  return {
    addressSearch: site.siteAddress,
    siteName: site.siteName,
    siteDescription: site.siteDescription || '',
    siteAddress: site.siteAddress,
    siteLocation: site.siteLocation,
    siteTimeZone: site.siteTimeZone,
    siteUnit: site.siteUnit,
    delayKPI: secondsValueToMinutesDisplay(site.delayKPI),
    speedKPI: convertSpeedValueToDisplay(site.speedKPI, site.siteUnit),
    queueKPI: convertDistanceValueToDisplay(site.queueKPI, site.siteUnit),
  };
}

function isSiteDifferentThanFormValues(
  site: Site,
  formValues: SitePropertiesFormValues
) {
  const siteValues = extractFormValuesFromSite(site);
  return Object.keys(formValues).some(
    (key: keyof SitePropertiesFormValues) =>
      key !== 'addressSearch' &&
      key !== 'siteLocation' &&
      formValues[key] !== siteValues[key]
  );
}
