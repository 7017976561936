import './RoutesEditorList.scss';
import { useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import InfoTooltip from 'design-system/atoms/info-tooltip';
import {
  Box,
  Button,
  Flex,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  VStack,
} from '@chakra-ui/react';
import { ChevronDownIcon } from '@chakra-ui/icons';
import Card from 'design-system/atoms/card';
import { MAX_ROUTE_COUNT } from '../../constants/clientConstants';
import {
  MESSAGE_UI_DISABLED_EDIT_SITE_ROUTE_PERMISSION,
  MESSAGE_UI_DISABLED_HAS_COLLECTED_DATA,
} from '../../constants/messages';
import { useAnalytics } from '../../hooks/analytics/useAnalytics';
import { BlankRoute, Route } from '../../types/route';
import { sortedColorsForItems } from '../../utils/routes';
import Spinner from '../Spinner';

export default function RoutesEditorList({
  routes,
  onRouteEdit,
  deleteRoute,
  canEditRoutes,
}: {
  canEditRoutes: boolean;
  routes?: Route[];
  onRouteEdit: (value: Route | BlankRoute) => void;
  deleteRoute: (route: Route) => Promise<void>;
}) {
  const { formatMessage } = useIntl();
  const { track } = useAnalytics();
  const [deletingRoutes, setDeletingRoutes] = useState<number[]>([]);
  if (!routes) {
    return <Spinner />;
  }
  const sortedColors = sortedColorsForItems(routes, 'trackId');
  return (
    <div className="routes-editor-list">
      <div className="routes-editor-list__routes-counter">
        <FormattedMessage
          defaultMessage="{routesCount} / {maxRoutes} routes added."
          id="UiqP8N"
          description="route counter for routes editor"
          values={{ routesCount: routes.length, maxRoutes: MAX_ROUTE_COUNT }}
        />
      </div>
      <VStack spacing={4}>
        {routes.map((route, routeIndex) => {
          const isDeleting = deletingRoutes.indexOf(route.routeId) > -1;
          return (
            <Card
              key={route.routeId}
              pl={4}
              pr={4}
              width="full"
              position="relative"
              boxShadow="soft"
            >
              <Box
                position="absolute"
                left="6px"
                top="6px"
                width="16px"
                height="6px"
                borderRadius="3px"
                backgroundColor={sortedColors[routeIndex % sortedColors.length]}
              />
              <VStack spacing={4} alignItems="left">
                <Flex gap={4}>
                  <Box flex="1" fontSize="14px" fontWeight="bold">
                    {route.name}
                  </Box>
                  <Box flex="0" my="-4px">
                    <Menu strategy="fixed">
                      <MenuButton
                        as={Button}
                        size="xs"
                        fontWeight="normal"
                        variant="outline"
                        rightIcon={<ChevronDownIcon />}
                      >
                        <FormattedMessage
                          id="VUKXQG"
                          defaultMessage="Options"
                          description="Routes context menu trigger button label"
                        />
                      </MenuButton>
                      <MenuList borderColor="green.700" fontSize="14px">
                        <InfoTooltip
                          content={
                            !canEditRoutes
                              ? formatMessage(
                                  MESSAGE_UI_DISABLED_EDIT_SITE_ROUTE_PERMISSION
                                )
                              : undefined
                          }
                        >
                          <MenuItem
                            isDisabled={isDeleting || !canEditRoutes}
                            onClick={() => onRouteEdit(route)}
                          >
                            <FormattedMessage
                              defaultMessage="Edit route"
                              id="cAz5MQ"
                            />
                          </MenuItem>
                        </InfoTooltip>
                        <InfoTooltip
                          content={
                            !canEditRoutes
                              ? formatMessage(
                                  MESSAGE_UI_DISABLED_EDIT_SITE_ROUTE_PERMISSION
                                )
                              : undefined
                          }
                        >
                          <MenuItem
                            isDisabled={!canEditRoutes}
                            onClick={() => {
                              onRouteEdit(duplicateRoute(route, routes));
                            }}
                          >
                            <FormattedMessage
                              defaultMessage="Duplicate route"
                              id="+nEkjb"
                            />
                          </MenuItem>
                        </InfoTooltip>
                        <InfoTooltip
                          content={
                            !canEditRoutes
                              ? formatMessage(
                                  MESSAGE_UI_DISABLED_EDIT_SITE_ROUTE_PERMISSION
                                )
                              : undefined
                          }
                        >
                          <MenuItem
                            isDisabled={!canEditRoutes}
                            onClick={() => {
                              onRouteEdit(reverseRoute(route, routes));
                            }}
                          >
                            <FormattedMessage
                              defaultMessage="Duplicate and reverse"
                              id="o1Kzec"
                            />
                          </MenuItem>
                        </InfoTooltip>
                        <InfoTooltip
                          content={
                            !canEditRoutes
                              ? formatMessage(
                                  MESSAGE_UI_DISABLED_EDIT_SITE_ROUTE_PERMISSION
                                )
                              : undefined
                          }
                        >
                          <MenuItem
                            isDisabled={!canEditRoutes}
                            onClick={() => {
                              onRouteEdit(createDetour(route, routes));
                            }}
                          >
                            <FormattedMessage
                              defaultMessage="Create detour"
                              id="ADjvCB"
                            />
                          </MenuItem>
                        </InfoTooltip>
                        <InfoTooltip
                          content={
                            route.monitorStarted || !canEditRoutes
                              ? formatMessage(
                                  MESSAGE_UI_DISABLED_HAS_COLLECTED_DATA
                                )
                              : undefined
                          }
                        >
                          <MenuItem
                            isDisabled={
                              route.monitorStarted ||
                              isDeleting ||
                              !canEditRoutes
                            }
                            onClick={() => {
                              if (
                                window.confirm(
                                  formatMessage({
                                    defaultMessage:
                                      'Are you sure you want to delete this route?',
                                    id: 'Mitpk0',
                                    description:
                                      'Confirmation dialogue message when trying to delete a route.',
                                  })
                                )
                              ) {
                                setDeletingRoutes([
                                  ...deletingRoutes,
                                  route.routeId,
                                ]);
                                deleteRoute(route)
                                  .then(() => {
                                    track('Route Deleted', {
                                      route,
                                      referrer: 'Edit Site Tab',
                                    });
                                  })
                                  .finally(() => {
                                    setDeletingRoutes((asyncState) =>
                                      asyncState.filter(
                                        (routeId) => routeId !== route.routeId
                                      )
                                    );
                                  });
                              }
                            }}
                          >
                            <FormattedMessage
                              defaultMessage="Delete route"
                              id="wLpxWl"
                            />
                          </MenuItem>
                        </InfoTooltip>
                      </MenuList>
                    </Menu>
                  </Box>
                </Flex>
                {route.description && route.description !== '' && (
                  <Box fontSize="12px">{route.description}</Box>
                )}
                {isDeleting && <Spinner />}
              </VStack>
            </Card>
          );
        })}
      </VStack>
      <div>
        <InfoTooltip
          content={
            !canEditRoutes
              ? formatMessage(MESSAGE_UI_DISABLED_EDIT_SITE_ROUTE_PERMISSION)
              : undefined
          }
        >
          <button
            type="button"
            className="routes-editor-list__add-btn"
            aria-label="Add a route"
            disabled={routes.length >= MAX_ROUTE_COUNT || !canEditRoutes}
            onClick={() =>
              onRouteEdit({
                name: '',
                description: '',
                waypoints: [],
                wkt: '',
                queuing: false,
                siteImpact: false,
                monitorStarted: false,
                active: false,
              })
            }
          >
            <FormattedMessage
              defaultMessage="+ {routesCount, select, 0 {Add a route} other {Add another route}}"
              id="X0LCXo"
              description="add route button label"
              values={{ routesCount: routes.length }}
            />
          </button>
        </InfoTooltip>
      </div>
    </div>
  );
}

function duplicateRoute(
  route: Route,
  allRoutes: Route[],
  suffix = 'copy'
): BlankRoute {
  const suffixRegexp = new RegExp(` - ${suffix}( \\d{1,3})*$`);
  const highestNumberedCopy = allRoutes.reduce((acc, collectionRoute) => {
    if (collectionRoute.name.replace(suffixRegexp, '') === route.name) {
      const suffixMatch = collectionRoute.name.match(suffixRegexp);
      const suffixNumberString = suffixMatch && suffixMatch[1];
      const suffixNumber =
        suffixNumberString && parseInt(suffixNumberString, 10);
      if (suffixNumber && suffixNumber > acc) {
        return suffixNumber;
      }
    }
    return acc;
  }, 0);
  return {
    ...route,
    routeId: undefined,
    active: false,
    monitorStarted: false,
    name: `${route.name} - ${suffix}${
      highestNumberedCopy > 0 ? ` ${highestNumberedCopy + 1}` : ''
    }`,
  };
}
function reverseRoute(route: Route, allRoutes: Route[]): BlankRoute {
  const duplicatedRoute = duplicateRoute(route, allRoutes, 'reverse');
  return {
    ...duplicatedRoute,
    wkt: '',
    startPoint: duplicatedRoute.endPoint,
    endPoint: duplicatedRoute.startPoint,
    waypoints: [...duplicatedRoute.waypoints].reverse(),
  };
}
function createDetour(route: Route, allRoutes: Route[]) {
  return duplicateRoute(route, allRoutes, 'detour');
}
