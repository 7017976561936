import { useParams, Link as RouterLink } from 'react-router-dom';
import { ActiveVms } from '@webapp/bff/src/types/vms';
import {
  Box,
  Heading,
  Text,
  UnorderedList,
  ListItem,
  Link,
  Button,
  VStack,
  useDisclosure,
} from '@chakra-ui/react';
import { FormattedMessage } from 'react-intl';
import { Fragment, useMemo, useState } from 'react';
import { pathSitesView, pathSiteVmsBoard } from '../../constants/path';
import useSiteData from '../../data/useSiteData';
import LayoutSmartVms from './LayoutSmartVms';
import SvgImageSmartVMS from '../../svg/smart-VMS-board.svg?inline';
import useSiteVmsData from '../../data/useSiteVmsData';
import {
  DataHandlerFeedback,
  hasDataHandlerFeedBack,
} from '../../utils/dataHandler';
import VmsCard from './VmsCard';

import { colorsSortedByKey, parseWkt } from '../../utils/routes';
import VmsPreview from './VmsPreview';
import { useAnalytics } from '../../hooks/analytics/useAnalytics';
import useRoutesData from '../../data/useRoutesData';
import AnimatedRoute from '../AnimatedRoute';
import { getCurrentSetupStep, getVmsContextMenuItems } from './utils';
import MapLayerVmsBoardsWithPreview from './VmsMapLayerWithPreview';
import DeleteBoardConfirmationModal from './DeleteBoardConfirmationModal';

type ActiveVmsWithColor = ActiveVms & { color: string };

export default function SmartVms({
  Wrapper,
}: {
  Wrapper: typeof LayoutSmartVms;
}) {
  const { insightId, siteId } = useParams();
  if (!insightId || !siteId) {
    throw new Error('Missing required params from URL');
  }
  const vmsDataHandler = useSiteVmsData(siteId);
  const currentSiteDataHandler = useSiteData({
    orgId: insightId,
    siteId,
  });
  const routesDataHandler = useRoutesData(Number(siteId));
  const isBoardListEmpty =
    !vmsDataHandler.data || vmsDataHandler.data.length === 0;
  const linkToSite = pathSitesView(insightId, siteId);
  const siteName = currentSiteDataHandler.data?.siteName;
  const linkToAddBoard = pathSiteVmsBoard(insightId, siteId, null);

  const routeColorsByTrackId = useMemo(
    () => colorsSortedByKey(routesDataHandler.data ?? [], 'trackId'),
    [routesDataHandler.data]
  );
  const vmsColors = useMemo(
    () => colorsSortedByKey(vmsDataHandler.data ?? [], 'vmsConfigId'),
    [vmsDataHandler.data]
  );
  const vmsDataWithColors = useMemo(
    () =>
      vmsDataHandler.data?.map((vm) => ({
        ...vm,
        color: vmsColors[vm.vmsConfigId],
      })),
    [vmsDataHandler.data, vmsColors]
  );

  if (
    hasDataHandlerFeedBack([
      vmsDataHandler,
      currentSiteDataHandler,
      routesDataHandler,
    ])
  ) {
    return (
      <DataHandlerFeedback
        dataHandlersParam={[
          vmsDataHandler,
          currentSiteDataHandler,
          routesDataHandler,
        ]}
      />
    );
  }

  return (
    <Wrapper
      contentSidebar={
        <SidebarContent
          orgId={insightId}
          siteId={siteId}
          siteName={siteName}
          linkToSite={linkToSite}
          linkToAddBoard={linkToAddBoard}
          vmsBoards={vmsDataWithColors}
          deleteVmsBoard={vmsDataHandler.deleteVmsBoard}
        />
      }
      contentMain={isBoardListEmpty ? <EmptyStatePanel /> : undefined}
      contentMap={
        <>
          <MapLayerVmsBoardsWithPreview
            deleteVmsBoard={vmsDataHandler.deleteVmsBoard}
            orgId={insightId}
            siteId={siteId}
            vmsBoards={vmsDataWithColors || []}
          />
          {routesDataHandler?.data?.map((route) => (
            <Fragment key={route.trackId}>
              <AnimatedRoute
                zIndex={2}
                shouldAnimate
                animationSpeed={0.5}
                routeColor={routeColorsByTrackId[route.trackId]}
                routePoints={parseWkt(route.wkt)}
              />
            </Fragment>
          ))}
        </>
      }
      contentFooter={
        <Button
          size="sm"
          variant="outline"
          as={RouterLink}
          to={pathSitesView(insightId, siteId)}
        >
          <FormattedMessage id="oWjhon" defaultMessage="Back to site" />
        </Button>
      }
      layoutSettings={{
        sidebarMode: isBoardListEmpty ? 'narrow' : 'wide',
        mapProps: {
          setBoundsToElements: routesDataHandler.data,
        },
      }}
    />
  );
}

function SidebarContent({
  orgId,
  siteId,
  siteName,
  linkToSite,
  linkToAddBoard,
  vmsBoards,
  deleteVmsBoard,
}: {
  orgId: string;
  siteId: string;
  siteName?: string;
  linkToSite: string;
  linkToAddBoard: string;
  vmsBoards?: ActiveVmsWithColor[];
  deleteVmsBoard: (vmsConfigId: number) => Promise<void>;
}) {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const {
    isOpen: deleteModalOpen,
    onOpen: onOpenDeleteModal,
    onClose: onCloseDeleteModal,
  } = useDisclosure();
  const [vmsConfigId, setVmsConfigId] = useState(undefined);
  const { track } = useAnalytics();
  const handleOpenPreview = (id) => {
    track('Vms Preview Clicked', {
      property: 'Vms Preview',
      referrer: 'Vms List page',
      value: id,
    });
    setVmsConfigId(id);
    onOpen();
  };

  const handleOpenDeleteModal = (id) => {
    track('Delete Vms Board Clicked', {
      property: 'Delete Vms Board',
      referrer: 'Vms List page',
      value: vmsConfigId,
    });
    setVmsConfigId(id);
    onOpenDeleteModal();
  };

  const handleDeleteBoard = async (id: number) => {
    await deleteVmsBoard(id);
    onCloseDeleteModal();
    setVmsConfigId(undefined);
  };

  const vmBoard = vmsBoards?.find((board) => board.vmsConfigId === vmsConfigId);
  const lastConnected = vmBoard?.lastConnected;
  const lastUpdatedDate = lastConnected
    ? new Date(Number(lastConnected))
    : undefined;

  return (
    <Box>
      {siteName && (
        <Link
          color="gray.700"
          fontSize="xs"
          fontWeight="semibold"
          as={RouterLink}
          to={linkToSite}
        >
          {siteName} /
        </Link>
      )}
      <Heading fontSize="2xl">
        <FormattedMessage defaultMessage="Smart VMS" id="nDOVki" />
      </Heading>
      <VStack spacing={4} align="stretch">
        <Text fontSize="xs" mb={0}>
          <FormattedMessage
            defaultMessage="Integrate with existing VMS boards to provide automated load balancing, journey time, speed, queue information.{br}
       All without the need for any extra hardware"
            id="/U1UhG"
            values={{ br: <br /> }}
          />
        </Text>

        <Box width="auto">
          <Button
            as={RouterLink}
            size="sm"
            variant="solid"
            colorScheme="greenDark"
            to={linkToAddBoard}
            onClick={() => {
              track('Create vms board button clicked', {
                property: 'Add smart VMS button',
                referrer: 'Vms List page',
                value: siteName,
              });
            }}
          >
            <FormattedMessage id="gyfHpc" defaultMessage="Add smart VMS" />
          </Button>
        </Box>

        {vmsBoards && vmsBoards.length > 0 && (
          <Box>
            {vmsBoards.map((vmsBoard) => {
              const currentStep = getCurrentSetupStep(vmsBoard);
              return (
                <VmsCard
                  key={vmsBoard.vmsConfigId}
                  providerMessage={vmsBoard.providerMessage}
                  status={vmsBoard.status}
                  vmsName={vmsBoard.vmsName || vmsBoard.vmsConfigId}
                  vmsColor={vmsBoard.color}
                  lastUpdatedDate={
                    (vmsBoard.lastConnected &&
                      new Date(Number(vmsBoard.lastConnected))) ||
                    undefined
                  }
                  scheduleOffDate={
                    (vmsBoard.stopUpdatingAt &&
                      new Date(vmsBoard.stopUpdatingAt)) ||
                    undefined
                  }
                  scheduleOnDate={
                    (vmsBoard.startUpdatingAt &&
                      new Date(vmsBoard.startUpdatingAt)) ||
                    undefined
                  }
                  isRoutePolling={vmsBoard.isRoutePolling}
                  onOpenPreview={() => handleOpenPreview(vmsBoard.vmsConfigId)}
                  contextMenuItems={getVmsContextMenuItems({
                    orgId,
                    siteId,
                    vmsConfigId: vmsBoard.vmsConfigId,
                    onOpenDeleteModal: handleOpenDeleteModal,
                    hasSetupCompleted: !!(
                      vmsBoard.startUpdatingAt ?? vmsBoard.stopUpdatingAt
                    ),
                    currentStep,
                  })}
                />
              );
            })}
          </Box>
        )}
      </VStack>
      {isOpen && (
        <VmsPreview
          orgId={orgId}
          siteId={siteId}
          deleteVmsBoard={async () => {
            await handleDeleteBoard(Number(vmsConfigId));
          }}
          vmsConfigId={vmsConfigId}
          hasSetupCompleted={
            !!(vmBoard?.stopUpdatingAt ?? vmBoard?.startUpdatingAt)
          }
          isOpen={isOpen}
          isLive={vmBoard?.status === 'Active'}
          onCancel={onClose}
          isRoutePolling={vmBoard?.isRoutePolling}
          lastUpdatedDate={lastUpdatedDate}
          currentStep={getCurrentSetupStep(
            vmsBoards?.find((vmsBoard) => vmsBoard.vmsConfigId === vmsConfigId)
          )}
        />
      )}
      <DeleteBoardConfirmationModal
        isOpen={deleteModalOpen}
        onClose={onCloseDeleteModal}
        onConfirm={async () => {
          await handleDeleteBoard(Number(vmsConfigId));
        }}
      />
    </Box>
  );
}

function EmptyStatePanel() {
  return (
    <Box
      display={{ base: 'block', md: 'flex' }}
      flexDirection={{ base: 'column', md: 'row' }}
      padding={8}
    >
      <Box width={{ sm: '100%', md: '75%', lg: '50%' }}>
        <Heading fontSize="3xl" letterSpacing="-0.15px">
          <FormattedMessage
            defaultMessage="Reduce complaints and manage road users expectations"
            id="9tUwyy"
          />
        </Heading>
        <Heading fontSize="16px" my="10px">
          <FormattedMessage
            defaultMessage="with smart mobile VMS messaging"
            id="c7+lI/"
          />
        </Heading>
        <Text fontSize="sm">
          <FormattedMessage
            defaultMessage="Our integration with existing VMS offerings allows:"
            id="5GZ2Wi"
          />
        </Text>
        <UnorderedList pl={2}>
          <ListItem fontSize="sm" fontWeight="bold">
            <FormattedMessage
              defaultMessage="Dynamically changing alternate routes so that you're always suggesting the optimum route"
              id="68hcS/"
            />
          </ListItem>
          <ListItem fontSize="sm" fontWeight="bold">
            <FormattedMessage
              defaultMessage="Displaying live journey times with no extra hardware"
              id="LYNcVx"
            />
          </ListItem>
          <ListItem fontSize="sm" fontWeight="bold">
            <FormattedMessage
              defaultMessage="Improve safety with conditional messaging based on speed"
              id="L+Fo+X"
            />
          </ListItem>
        </UnorderedList>
      </Box>
      <Box
        width={{ sm: '100%', md: '75%', lg: '50%' }}
        height={{ sm: '65%', md: '50%' }}
        background={`bottom center/100% no-repeat url(${SvgImageSmartVMS})`}
        position="absolute"
        bottom={{ base: 'auto', md: 0 }}
        right={{ base: 'auto', md: 6 }}
      />
    </Box>
  );
}
