import { ChevronDownIcon, ChevronUpIcon } from '@chakra-ui/icons';
import {
  Box,
  FormControl,
  FormErrorMessage,
  Input,
  InputGroup,
  InputLeftAddon,
  Text,
  useDisclosure,
  useOutsideClick,
} from '@chakra-ui/react';
import {
  parseNumber,
  validatePhoneNumberLength,
  ValidatePhoneNumberLengthResult,
} from 'libphonenumber-js';
import {
  ChangeEvent,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useIntl } from 'react-intl';
import countries, { Code, getByCode } from './countries';
import CountrySearch from './country-search';
import { Country } from './types';

export interface PhoneNumberInputProps {
  onChange: (phoneNumber: string, isValid: boolean) => void;
  defaultNumber?: string;
  defaultCountry?: Code;
}

const PhoneNumberInput = ({
  onChange,
  defaultNumber = '',
  defaultCountry = 'AU',
}: PhoneNumberInputProps) => {
  const { formatMessage } = useIntl();
  const ref = useRef(null);
  const parsedDefaultNumber = parseNumber(defaultNumber);
  const [number, setNumber] = useState(
    'country' in parsedDefaultNumber ? parsedDefaultNumber.phone : ''
  );
  const [country, setCountry] = useState(
    'country' in parsedDefaultNumber
      ? getByCode(parsedDefaultNumber.country as Code)
      : getByCode(defaultCountry)
  );
  const [validateResult, setValidateResult] = useState<
    ValidatePhoneNumberLengthResult | undefined | 'INVALID_NUMBER'
  >(undefined);
  const { isOpen, onToggle, onClose } = useDisclosure();

  useOutsideClick({
    ref,
    handler: () => onClose(),
  });

  const parse = useCallback(
    (num: string, dialCode?: string) => `${dialCode || country.dialCode}${num}`,
    [country.dialCode]
  );

  const validate = (num: string) => {
    const result = validatePhoneNumberLength(num);
    return result === undefined;
  };

  useEffect(() => {
    const parsed = parse(number);
    const result = validatePhoneNumberLength(parsed);
    if (result) {
      setValidateResult(result);
    } else {
      const actualNumber = parseNumber(parsed);
      setValidateResult('phone' in actualNumber ? undefined : 'INVALID_NUMBER');
    }
  }, [number, parse]);

  const isValid = useMemo(
    () => number.length === 0 || validateResult === undefined,
    [number, validateResult]
  );

  const placeholder = formatMessage({
    id: 'AvA842',
    description: 'Placeholder for telephone input',
    defaultMessage: 'Enter your telephone number',
  });

  const errorMessages: {
    [key in ValidatePhoneNumberLengthResult | 'INVALID_NUMBER']: string;
  } = {
    TOO_SHORT: formatMessage({
      id: 'wU3vz5',
      description: 'Error message for too short phone numbers',
      defaultMessage: 'Too short',
    }),
    TOO_LONG: formatMessage({
      id: 'fiLpuU',
      description: 'Error message for too long phone numbers',
      defaultMessage: 'Too long',
    }),
    NOT_A_NUMBER: formatMessage({
      id: 'UK9azs',
      description: 'Error message for NaN phone numbers',
      defaultMessage: 'Not a number',
    }),
    INVALID_COUNTRY: formatMessage({
      id: '4Sbnxv',
      description: 'Error message for invalid country phone numbers',
      defaultMessage: 'Country code is invalid',
    }),
    INVALID_LENGTH: formatMessage({
      id: 'M4ctL+',
      description: 'Error message for invalid length phone numbers',
      defaultMessage: 'Invalid length',
    }),
    INVALID_NUMBER: formatMessage({
      description: 'Error message for invalid number',
      defaultMessage: 'Invalid number',
      id: '+Smu6x',
    }),
  };

  const onCountryChange = (item: Country) => {
    setCountry(item);
    const parsed = parse(number, item.dialCode);
    const valid = validate(parsed);
    onChange(parsed, valid);
    onClose();
  };

  const onPhoneNumberChange = (event: ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;
    setNumber(value);
    const actualNumber = parseNumber(parse(value));
    const isNumberValid = 'phone' in actualNumber;
    onChange(
      isNumberValid ? parse(actualNumber.phone) : '',
      'phone' in actualNumber
    );
  };

  return (
    <Box as="section" ref={ref} position="relative">
      <FormControl isInvalid={!isValid}>
        <InputGroup>
          <InputLeftAddon cursor="pointer" onClick={onToggle}>
            <Text as="span" mr={2}>
              {country.flag}
            </Text>
            <Text fontSize="sm" as="span" lineHeight={1}>
              {country.dialCode}
            </Text>
            {isOpen ? (
              <ChevronUpIcon boxSize={6} color="gray.700" />
            ) : (
              <ChevronDownIcon boxSize={6} color="gray.700" />
            )}
          </InputLeftAddon>
          <Input
            type="tel"
            value={number}
            placeholder={placeholder}
            onChange={onPhoneNumberChange}
          />
        </InputGroup>
        {validateResult !== undefined && (
          <FormErrorMessage>{errorMessages[validateResult]}</FormErrorMessage>
        )}
      </FormControl>
      {isOpen ? (
        <CountrySearch
          data={countries}
          onChange={onCountryChange}
          selectedCountry={country}
        />
      ) : null}
    </Box>
  );
};

export default PhoneNumberInput;
