import { useState } from 'react';
import { useLocation, useParams, useSearchParams } from 'react-router-dom';
import { SWRResponse } from 'swr';
import useRoutePollingStatusData, {
  RoutePollingStatusFailureType,
  IsRoutePollingStatusFailureType,
  IsRoutePollingStatusDataFailure,
  IsRoutePollingStatusDataResponded,
} from '../../data/useRoutePollingStatusData';
import { DataHandlerFeedback } from '../../utils/dataHandler';
import ResponseUpdatedState from './ResponseUpdatedState';
import UpdateResponse from './UpdateResponse';
import ExistingResponseState from './ExistingResponseState';
import ResolvedState from './ResolvedState';
import LayoutWebpage from '../LayoutWebpage';
import { useAnalytics } from '../../hooks/analytics/useAnalytics';

export default function RoutePollingStatus() {
  const { track } = useAnalytics();
  const location = useLocation();
  const { routeId: routeIdParam } = useParams();
  const [searchParams] = useSearchParams();
  const responseParam = searchParams.get('response');
  const token = searchParams.get('token');
  const validatedResponseParam =
    IsRoutePollingStatusFailureType(responseParam) || responseParam === 'OTHER'
      ? (responseParam as RoutePollingStatusFailureType | 'OTHER')
      : undefined;
  const [isResponseUpdated, setIsResponseUpdated] = useState(false);
  const [newResponseState, setNewResponseState] = useState<{
    responseType: RoutePollingStatusFailureType | 'OTHER';
    responseComment?: string;
  }>();

  const routePollingStatusDataHandler = useRoutePollingStatusData({
    routeId: routeIdParam,
    path: location.pathname,
    token,
  });
  const statusData = routePollingStatusDataHandler.data;
  const isDataReady = statusData && IsDataReady(routePollingStatusDataHandler);
  const hasFailure = IsRoutePollingStatusDataFailure(statusData);
  const hasUserResponse = IsRoutePollingStatusDataResponded(statusData);

  const setResponse = async (newResponse: {
    responseType: RoutePollingStatusFailureType;
    responseComment?: string;
  }) => {
    try {
      if (!isDataReady) {
        throw new Error('Data is not ready');
      }
      track('Responded Polling Failure', {
        referrer: 'Route Status Page',
        routeId: statusData.route.routeId,
        responseType: newResponse.responseType,
        responseComment: newResponse.responseComment,
        previousResponseType: statusData.failure?.userResponse?.responseType,
        previousResponseComment:
          statusData.failure?.userResponse?.responseComment,
      });
      await routePollingStatusDataHandler.updateResponse({
        routeId: statusData.route.routeId,
        token,
        ...newResponse,
      });
      setIsResponseUpdated(true);
    } finally {
      setNewResponseState(undefined);
    }
  };
  if (!isDataReady) {
    return (
      <LayoutWebpage>
        <DataHandlerFeedback
          dataHandlersParam={[routePollingStatusDataHandler]}
        />
      </LayoutWebpage>
    );
  }
  if (!hasFailure) {
    return (
      <LayoutWebpage>
        <ResolvedState pollingStatus={statusData} />
      </LayoutWebpage>
    );
  }
  if (newResponseState || !hasUserResponse) {
    return (
      <LayoutWebpage>
        <UpdateResponse
          pollingStatus={statusData}
          setResponse={setResponse}
          newResponse={
            newResponseState ||
            (validatedResponseParam && { responseType: validatedResponseParam })
          }
        />
      </LayoutWebpage>
    );
  }
  if (isResponseUpdated) {
    return (
      <LayoutWebpage>
        <ResponseUpdatedState
          pollingStatus={statusData}
          setNewResponse={setNewResponseState}
        />
        ;
      </LayoutWebpage>
    );
  }
  return (
    <LayoutWebpage>
      <ExistingResponseState
        pollingStatus={statusData}
        setNewResponse={setNewResponseState}
      />
    </LayoutWebpage>
  );
}

type DataHandlerWithData<TDataHandler extends Pick<SWRResponse, 'data'>> =
  TDataHandler & { data: NonNullable<unknown>; error: undefined };
function IsDataReady<TDataHandler extends Pick<SWRResponse, 'data' | 'error'>>(
  dataHandlers: TDataHandler
): dataHandlers is DataHandlerWithData<TDataHandler> {
  return (
    dataHandlers.error === undefined &&
    dataHandlers.data !== undefined &&
    dataHandlers.data !== null
  );
}
