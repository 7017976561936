import {
  Box,
  Popover,
  PopoverContent,
  Wrap,
  Input,
  InputGroup,
  InputLeftElement,
  InputRightElement,
  useControllableState,
  useOutsideClick,
  useDisclosure,
  useDimensions,
  forwardRef,
} from '@chakra-ui/react';
import { runIfFn } from '@chakra-ui/utils';
import { useState, ReactNode, useRef, useImperativeHandle } from 'react';
import SelectTag from './SelectTag';
import Suggestion from './Suggestion';

export type AutoCompleteRefMethods = {
  resetTags: () => void;
};

export interface AutoCompleteInputProps {
  onValueChange?: (query: string) => void;
  onTagsChange?: (tags: string[]) => void;
  validator?: (query: string[]) => boolean;
  inputLeftElement?: ReactNode;
  inputRightElement?: ReactNode;
  autoCompleteItems: string[];
  errorMessage?: string;
  maxSuggestions?: number;
  placeholder?: string;
  ref?: React.RefObject<AutoCompleteRefMethods>;
  variant?: 'outlined' | 'ghost';
  tokenizer?: (str: string) => string[];
}

const AutoCompleteInput = forwardRef<AutoCompleteInputProps, 'div'>(
  (
    {
      onValueChange,
      onTagsChange,
      tokenizer = (str) => [str],
      validator = () => true,
      inputLeftElement,
      inputRightElement,
      autoCompleteItems,
      errorMessage = 'There is an error',
      maxSuggestions = 5,
      placeholder = '',
      variant = 'outlined',
    },
    ref
  ) => {
    const [tags, setTags] = useControllableState<string[]>({
      defaultValue: [],
      onChange: (newValues: string[]) => {
        runIfFn(onTagsChange, newValues);
      },
    });
    const [query, setQuery] = useControllableState<string>({
      defaultValue: '',
      onChange: (newValue: string) => {
        runIfFn(onValueChange, newValue);
      },
    });
    const [suggestions, setSuggestions] = useState<string[]>([]);
    const [valid, setValid] = useState<boolean>(true);
    const { isOpen, onOpen, onClose } = useDisclosure();
    const inputRef = useRef<HTMLInputElement>(null);
    const leftElementRef = useRef<HTMLDivElement>(null);
    const rightElementRef = useRef<HTMLDivElement>(null);
    const divRef = useRef<HTMLDivElement>(null);
    useOutsideClick({
      ref: divRef,
      handler: () => onClose(),
    });
    const resetTags = () => {
      setQuery('');
      setTags([]);
      setValid(true);
      inputRef.current?.focus();
    };
    useImperativeHandle(ref, () => ({ resetTags }));
    const leftElementDim = useDimensions(leftElementRef, true);
    const rightElementDim = useDimensions(rightElementRef, true);

    const removeTag = (removedTag: string) => {
      setTags((prevTags) => prevTags.filter((tag) => tag !== removedTag));
    };

    const selectTag = (tag: string) => {
      if (!tags.includes(tag)) {
        setTags((prevTags) => [...prevTags, tag]);
      }
      setQuery('');
      onClose();
      setValid(true);
      inputRef.current?.focus();
    };

    const wrapProps =
      variant === 'outlined'
        ? {
            p: 2,
            borderRadius: 'md',
            border: '1px',
            borderColor: valid ? 'gray.100' : 'red.500',
            boxShadow: 'sm',
            backgroundColor: 'white',
            _hover: { borderColor: 'gray.300' },
          }
        : { p: '1px' };
    return (
      <>
        <Popover
          isLazy
          isOpen={isOpen}
          onClose={onClose}
          onOpen={onOpen}
          autoFocus={false}
          placement="bottom"
          closeOnBlur
        >
          <Box
            sx={{
              '.chakra-popover__popper': {
                position: 'relative !important',
              },
            }}
            w="full"
            ref={divRef}
          >
            <Wrap w="100%" {...wrapProps}>
              <InputGroup>
                {inputLeftElement && (
                  <InputLeftElement ref={leftElementRef} width="" left="2px">
                    {inputLeftElement}
                  </InputLeftElement>
                )}
                <Input
                  ref={inputRef}
                  value={query}
                  placeholder={placeholder}
                  autoComplete="off"
                  bgColor="white"
                  {...(variant === 'outlined'
                    ? { border: 'none' }
                    : { borderColor: 'green.500' })}
                  autoFocus
                  paddingLeft={
                    inputLeftElement
                      ? `${(leftElementDim?.marginBox.width ?? 0) + 16}px`
                      : 4
                  }
                  paddingRight={
                    inputRightElement
                      ? `${(rightElementDim?.marginBox.width ?? 0) + 8}px`
                      : 4
                  }
                  onKeyDown={(e) => {
                    const { key } = e;
                    if (['Enter', 'Tab', ','].includes(key)) {
                      setValid(true);
                      onClose();
                      if (!query) {
                        return;
                      }
                      if (query && !runIfFn(validator, tokenizer(query))) {
                        setValid(false);
                      } else {
                        if (!tags.includes(query)) {
                          setTags((prevTags) => [
                            ...prevTags,
                            ...tokenizer(query),
                          ]);
                        }
                        setQuery('');
                      }
                      e.preventDefault();
                    }
                  }}
                  onChange={(e) => {
                    const { value } = e.target;
                    setQuery(value);
                    setValid(true);
                    if (!value) {
                      onClose();
                    } else {
                      const filteredEmails = [...autoCompleteItems].filter(
                        (item) =>
                          (item ?? '').includes(value) &&
                          !(tags ?? []).includes(item)
                      );
                      if (filteredEmails && filteredEmails.length > 0) {
                        setSuggestions(
                          filteredEmails.length > maxSuggestions
                            ? filteredEmails.slice(0, maxSuggestions)
                            : filteredEmails
                        );
                        onOpen();
                      } else {
                        onClose();
                      }
                    }
                  }}
                />
                {inputRightElement && (
                  <InputRightElement width="" right="2px" ref={rightElementRef}>
                    {inputRightElement}
                  </InputRightElement>
                )}
              </InputGroup>
              {tags.map((tag) => (
                <SelectTag key={tag} label={tag} onRemove={removeTag} />
              ))}
            </Wrap>
            <PopoverContent
              mt={4}
              py={4}
              opacity={0}
              rounded="md"
              border="none"
              shadow="base"
              pos="absolute"
              zIndex="popover"
              overflowY="auto"
              w="100%"
              top="-8px"
              _light={{ bg: '#ffffff' }}
              _focus={{ boxShadow: 'none' }}
            >
              {suggestions.map((suggestion) => (
                <Suggestion
                  key={suggestion}
                  suggestion={suggestion}
                  onClick={selectTag}
                />
              ))}
            </PopoverContent>
          </Box>
        </Popover>
        {!valid && (
          <Box m={2} color="red.500">
            {errorMessage}
          </Box>
        )}
      </>
    );
  }
);

export default AutoCompleteInput;
