import './SiteSelectorMenu.scss';
import { useMemo } from 'react';
import classnames from 'classnames';
import usePopOver from '../../hooks/usePopOver';
import SiteStatus from '../SiteStatus';
import { SiteView } from '../../hooks/useSiteView';
import { SiteSummary } from '../../types/site';

interface SiteSelectorMenuProps {
  siteId: string;
  setSiteView: SiteView['setState'];
  sites: SiteSummary[];
  title: string;
}

export default function SiteSelectorMenu({
  siteId,
  setSiteView,
  sites,
  title,
}: SiteSelectorMenuProps) {
  const [isOpen, setOpen, createNoClickRef] = usePopOver();
  const selectedSite = useMemo(
    () => sites.find((site) => site.siteId.toString() === siteId),
    [sites, siteId]
  );

  if (!sites || sites.length === 0) {
    return null;
  }
  if (!selectedSite) {
    return null;
  }
  return (
    <div className="site-selector-menu">
      {title && <h2 className="site-selector-menu__title">{title}</h2>}
      <button
        type="button"
        className={classnames('site-selector-menu__popup-trigger', {
          'site-selector-menu__popup-trigger--active': isOpen,
        })}
        onClick={() => setOpen(!isOpen)}
        ref={createNoClickRef('popupTriggerRef')}
      >
        <span
          className="site-selector-menu__current-site-name"
          data-testid="siteselector-sitename"
        >
          {selectedSite.siteName}
        </span>
        <span
          className="site-selector-menu__current-site-address"
          data-testid="siteselector-sitelocation"
        >
          {selectedSite.siteAddress}
        </span>
      </button>

      {isOpen && (
        <ul
          className="site-selector-menu__popup site-selector-menu__popup-new-layout"
          ref={createNoClickRef('popupContentRef')}
        >
          {sites.map((site) => (
            <li className="site-selector-menu__item" key={site.siteId}>
              <button
                type="button"
                className={classnames('site-selector-menu__item-primary', {
                  'site-selector-menu__item-primary--active':
                    site.siteId.toString() === siteId,
                })}
                onClick={() => {
                  setSiteView({
                    siteId: site.siteId,
                  });
                  setOpen(false);
                }}
              >
                <span className="site-selector-menu__item-status">
                  <SiteStatus site={site} />
                </span>
                <span className="site-selector-menu__item-body">
                  <span className="site-selector-menu__item-title">
                    {site.siteName}
                  </span>
                  <span className="site-selector-menu__item-subtitle">
                    {site.siteAddress}
                  </span>
                </span>
              </button>
            </li>
          ))}
        </ul>
      )}
    </div>
  );
}
