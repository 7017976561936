import useSWR from 'swr';
import moment from 'moment-timezone';
import { useMemo } from 'react';
import { DataType } from '@webapp/bff';
import { TimePeriod } from '../types/timePeriod';
import { SummaryModel } from '../types/site';
import { dataTrpc } from '../config/trpc';
import useRoutesData from './useRoutesData';

function formatTime(timeInSecs) {
  if (!timeInSecs && timeInSecs !== 0) {
    return '';
  }
  const hour = Math.floor(timeInSecs / 3600);
  const min = Math.floor(timeInSecs / 60) % 60;
  const sec = timeInSecs % 60;

  const rst =
    (hour !== 0 ? `${hour}h` : '') +
    (min !== 0 ? `${min}m` : '') +
    (sec !== 0 ? `${sec}s` : '');
  return rst === '' ? '0s' : rst;
}

export interface UseInsightSummaryDataParams {
  insightId: string;
  siteId?: number;
  timePeriod: TimePeriod;
  resolution?: string;
  siteUnit: string;
  hiddenRoutes: Array<number>;
}
export interface InsightSummaryDataHandler {
  data?: SummaryModel[];
  error: any;
  isValidating: boolean;
  anyRoutesSelected: boolean;
  dataAvailable: boolean;
}

const METER_TO_MILE_RATIO = 0.000621371;
const KM_TO_MILE_RATIO = 0.621371;

// 'TIME_DELAY', 'SPEED', 'QUEUE_LENGTH', 'SITE_IMPACT_DELAY'
const formatSummary = (dataType: DataType, value: number, siteUnit: string) => {
  switch (dataType) {
    case 'TIME_DELAY':
      return formatTime(value);
    case 'SPEED':
      return siteUnit === 'METRIC'
        ? `${value.toFixed(0)} km/hr`
        : `${(KM_TO_MILE_RATIO * value).toFixed(0)} mi/hr`;
    case 'QUEUE_LENGTH':
      return siteUnit === 'METRIC'
        ? `${value.toFixed(0)} m`
        : `${(METER_TO_MILE_RATIO * value).toFixed(1)} mi`;
    case 'SITE_IMPACT_DELAY':
      return formatTime(value);
    default:
      return `${value}`;
  }
};

export default function useInsightSummaryData({
  insightId,
  siteId,
  timePeriod,
  resolution,
  siteUnit,
  hiddenRoutes,
}: UseInsightSummaryDataParams): InsightSummaryDataHandler {
  const routesData = useRoutesData(siteId);
  const { startDateEpoch, endDateEpoch } =
    getDateBoundaryByTimePeriod(timePeriod);
  const { data, error, isValidating } = useSWR<
    {
      dataType: DataType;
      aggregation: 'min' | 'max';
      routes: {
        value?: number;
        time: number;
        routeId: string;
        label: string;
      }[];
    }[]
  >(
    !siteId
      ? null
      : {
          path: `getSummaryData`,
          siteId,
          timePeriod,
          resolution,
          siteUnit,
        },
    () =>
      dataTrpc.viewData.getSummaryData.query({
        orgId: `${insightId}`,
        siteId: `${siteId}`,
        startTime: startDateEpoch * 1000,
        endTime: endDateEpoch * 1000,
      })
  );

  const summary = useMemo(() => {
    if (!data) {
      return {
        data: undefined,
        dataAvailable: false,
        anyRoutesSelected: false,
      };
    }
    const hiddenRouteIds =
      routesData.data
        ?.filter((r) =>
          hiddenRoutes.find((h) => h === parseInt(r.trackId.substring(1), 10))
        )
        .map((r) => r.routeId) || [];
    let dataAvailable = false;
    const anyRoutesSelected = hiddenRouteIds.length !== routesData.data?.length;
    const models = [] as SummaryModel[];
    ['TIME_DELAY', 'SPEED', 'QUEUE_LENGTH', 'SITE_IMPACT_DELAY'].forEach(
      (dataType): void => {
        const dataTypeData = data.find((d) => d.dataType === dataType);
        if (dataTypeData) {
          const routeData = dataTypeData.routes.reduce(
            (acc, r) => {
              if (hiddenRouteIds.indexOf(parseInt(r.routeId, 10)) >= 0) {
                return acc;
              }
              let summaryType:
                | 'MAX_TIME_DELAY'
                | 'MIN_SPEED'
                | 'MAX_QUEUE_LENGTH'
                | 'MAX_SITE_IMPACT_DELAY' = 'MAX_TIME_DELAY';
              switch (dataTypeData.dataType) {
                case 'TIME_DELAY':
                  summaryType = 'MAX_TIME_DELAY';
                  break;
                case 'SPEED':
                  summaryType = 'MIN_SPEED';
                  break;
                case 'QUEUE_LENGTH':
                  summaryType = 'MAX_QUEUE_LENGTH';
                  break;
                case 'SITE_IMPACT_DELAY':
                  summaryType = 'MAX_SITE_IMPACT_DELAY';
                  break;
                default:
                  break;
              }
              if (dataTypeData.aggregation === 'min') {
                if (r.value && r.value < (acc?.value ?? Number.MAX_VALUE)) {
                  return {
                    value: r.value,
                    routeId: r.routeId,
                    routeName: r.label,
                    summaryType,
                    date: Math.floor(r.time / 1000),
                    display: formatSummary(
                      dataTypeData.dataType,
                      r.value,
                      siteUnit
                    ),
                  };
                }
              } else if (dataTypeData.aggregation === 'max') {
                if (r.value && r.value > (acc?.value ?? Number.MIN_VALUE)) {
                  return {
                    value: r.value,
                    routeId: r.routeId,
                    routeName: r.label,
                    summaryType,
                    date: Math.floor(r.time / 1000),
                    display: formatSummary(
                      dataTypeData.dataType,
                      r.value,
                      siteUnit
                    ),
                  };
                }
              }
              return acc;
            },
            undefined as SummaryModel | undefined
          );
          if (routeData) {
            dataAvailable = true;
            models.push(routeData);
          }
        }
      }
    );
    return { data: models, anyRoutesSelected, dataAvailable };
  }, [hiddenRoutes, routesData.data, data, siteUnit]);
  return {
    data: summary.data,
    anyRoutesSelected: summary.anyRoutesSelected,
    dataAvailable: summary.dataAvailable,
    error,
    isValidating,
  };
}

export function buildInsightSummaryDataUrl({
  path,
  siteId,
  timePeriod,
  resolution = 'minutes_15',
  siteUnit,
}: { path: string } & Pick<
  UseInsightSummaryDataParams,
  'siteId' | 'timePeriod' | 'resolution' | 'siteUnit'
>) {
  const { startDateEpoch, endDateEpoch } =
    getDateBoundaryByTimePeriod(timePeriod);
  return `${path}?siteId=${siteId}&resolution=${resolution}&starttime=${startDateEpoch}&endtime=${endDateEpoch}&siteUnit=${siteUnit}&timePeriod=${timePeriod.type}`;
}

export function getDateBoundaryByTimePeriod(timePeriod: TimePeriod) {
  const endDateEpoch = Math.round(
    (timePeriod.type === 'TIME_PERIOD_CUSTOM'
      ? timePeriod.endDate
      : Date.now()) / 1000
  );
  let startDateEpoch = Math.round(
    timePeriod.type === 'TIME_PERIOD_CUSTOM'
      ? timePeriod.startDate / 1000
      : moment().unix()
  );
  if (timePeriod.type === 'TIME_PERIOD_LAST_24_HOURS') {
    startDateEpoch = moment().subtract(1, 'day').unix();
  } else if (timePeriod.type === 'TIME_PERIOD_LAST_WEEK') {
    startDateEpoch = moment().subtract(1, 'week').unix();
  } else if (timePeriod.type === 'TIME_PERIOD_LAST_MONTH') {
    startDateEpoch = moment().subtract(1, 'month').unix();
  }
  return {
    startDateEpoch,
    endDateEpoch,
  };
}
