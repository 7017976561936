import { Box, Flex, Text } from '@chakra-ui/react';
import { useIntl } from 'react-intl';
import { TimeIcon } from '@chakra-ui/icons';
import {
  DATE_PRESET_NARROW_COMPLETE,
  TIME_PRESET_COMPLETE_HOUR12,
} from '../../utils/dateUtils';
import EmptyStateNotesIcon from '../../svg/empty_state_notes.svg?react';
import { AnnotatedEvent } from '../../utils/annotations';

export default function IncidentAnnotation({
  incident,
  onClick,
  timeZone,
}: {
  timeZone: string;
  incident: AnnotatedEvent;
  onClick: (annotation: { startDate: number; endDate?: number }) => void;
}) {
  const { formatDate, formatTime } = useIntl();
  return (
    <Flex
      alignItems="center"
      onClick={() => onClick(incident)}
      _hover={{ cursor: 'pointer' }}
    >
      <Box mr={4}>
        <EmptyStateNotesIcon />
      </Box>
      <Flex direction="column">
        <Text textStyle="h3">{incident.description ?? ''}</Text>
        <Flex mb={4} justify="space-between">
          {incident.startDate && (
            <Box>
              <Text fontSize="xs" mb={0}>
                <TimeIcon boxSize="14px" mr={1.5} />
                {formatTime(incident.startDate * 1000, {
                  ...TIME_PRESET_COMPLETE_HOUR12,
                  timeZone,
                })}
                ,{' '}
                {formatDate(incident.startDate * 1000, {
                  ...DATE_PRESET_NARROW_COMPLETE,
                  timeZone,
                })}
              </Text>
            </Box>
          )}
          {incident.startDate && incident.endDate && (
            <Box>
              <Text fontSize="xs" mb={0} px={1.5}>
                -
              </Text>
            </Box>
          )}
          {incident.endDate && (
            <Box>
              <Text fontSize="xs" mb={0}>
                <TimeIcon boxSize="14px" mr={1.5} />
                {formatTime(incident.endDate * 1000, {
                  ...TIME_PRESET_COMPLETE_HOUR12,
                  timeZone,
                })}
                ,{' '}
                {formatDate(incident.endDate * 1000, {
                  ...DATE_PRESET_NARROW_COMPLETE,
                  timeZone,
                })}
              </Text>
            </Box>
          )}
        </Flex>
      </Flex>
    </Flex>
  );
}
