/* eslint-disable no-param-reassign */
const marginFromYTopPos = 10;
const marginFromYBottomPos = 20;
const titleMarginLeft = 15;
const titleCharacterSize = 7;
const occupationTitleRefsKey = 'occupationChTitleRefs';
const occupationWindowRefsKey = 'occupationWindowRefs';
const dayMs = 24 * 60 * 60 * 1000;

type OccupationChart = Highcharts.Chart & {
  [occupationTitleRefsKey]?: Highcharts.SVGElement[];
  [occupationWindowRefsKey]?: Highcharts.SVGElement[];
};

interface OccupationChartProps {
  title: string;
  startTime: number;
  endTime: number;
  chart: OccupationChart;
  titleColor?: string;
  occupationColor?: string;
  isNightShift?: boolean;
}

const defaultTitleColor = 'rgba(44, 122, 123, 1)';
const defaultBackgroundColor = 'rgba(79, 209, 197, 0.4)';

export const convertTimeToMilliSeconds = (time: string): number => {
  // Time must be in this format: 'hh:mm:ss'
  const ts = time.split(':');
  const hours = Number.parseInt(ts[0], 10);
  const minutes = Number.parseInt(ts[1], 10);
  const seconds = Number.parseInt(ts[2], 10);
  return (
    (Number.isNaN(hours) ? 0 : hours * 1000 * 60 * 60) +
    (Number.isNaN(minutes) ? 0 : minutes * 1000 * 60) +
    (Number.isNaN(seconds) ? 0 : seconds * 1000)
  );
};

export const removeOccupationChart = (chart: OccupationChart) => {
  chart[occupationTitleRefsKey]?.forEach((ref) => ref.destroy());
  chart[occupationWindowRefsKey]?.forEach((ref) => ref.destroy());
};

function applyNightShift(msTime: number): number {
  return ((msTime + dayMs / 2) % dayMs) + dayMs / 2;
}

const renderOccupationChart = ({
  title,
  startTime,
  endTime,
  chart,
  titleColor = defaultTitleColor,
  occupationColor = defaultBackgroundColor,
}: OccupationChartProps) => {
  const { renderer } = chart;
  const xLeftPos = chart.xAxis[0].toPixels(startTime, false);
  const xRightPos = chart.xAxis[0].toPixels(endTime, false);
  const yTopPos = chart.yAxis[0].toPixels(chart.yAxis[0].max!, false);
  const yBottomPos = chart.yAxis[0].toPixels(0, false);
  const rectWidth = xRightPos - xLeftPos;
  if (Number.isNaN(xLeftPos) || Number.isNaN(yTopPos)) {
    return;
  }
  if (!chart[occupationTitleRefsKey]) {
    chart[occupationTitleRefsKey] = [];
  }
  chart[occupationTitleRefsKey].push(
    renderer
      .rect(
        xLeftPos,
        yTopPos + marginFromYTopPos,
        rectWidth,
        yBottomPos - yTopPos - marginFromYBottomPos,
        8
      )
      .attr({
        'stroke-width': 2,
        fill: `${occupationColor}`,
      })
      .add()
  );
  if (!chart[occupationWindowRefsKey]) {
    chart[occupationWindowRefsKey] = [];
  }
  chart[occupationWindowRefsKey].push(
    renderer
      .text(
        title,
        xLeftPos + titleMarginLeft,
        yTopPos + marginFromYTopPos + title.length * titleCharacterSize
      )
      .attr({
        rotation: -90,
        zIndex: 3,
      })
      .css({
        color: `${titleColor}`,
        fontSize: '12px',
        fontWeight: '600',
      })
      .add()
  );
};

export function shouldInvertOccupationWindow({
  startTime,
  endTime,
  isNightShift,
}: {
  startTime: number;
  endTime: number;
  isNightShift: boolean;
}): boolean {
  if (isNightShift) {
    return applyNightShift(endTime) < applyNightShift(startTime);
  }
  return endTime < startTime;
}

export const drawOccupationChart = ({
  title,
  startTime,
  endTime,
  chart,
  titleColor = defaultTitleColor,
  occupationColor = defaultBackgroundColor,
  isNightShift = false,
}: OccupationChartProps) => {
  removeOccupationChart(chart);
  if (isNightShift ? startTime === endTime - dayMs : startTime === endTime) {
    return; // Render nothing
  }
  if (chart && chart.renderer) {
    const invertWindow = shouldInvertOccupationWindow({
      startTime,
      endTime,
      isNightShift,
    });
    if (invertWindow) {
      renderOccupationChart({
        title,
        startTime: isNightShift ? dayMs / 2 : 0,
        endTime: isNightShift ? applyNightShift(endTime) : endTime,
        chart,
        titleColor,
        occupationColor,
      });
      renderOccupationChart({
        title,
        startTime: isNightShift ? applyNightShift(startTime) : startTime,
        endTime: isNightShift ? 1.5 * dayMs : dayMs,
        chart,
        titleColor,
        occupationColor,
      });
    } else {
      renderOccupationChart({
        title,
        startTime: isNightShift ? applyNightShift(startTime) : startTime,
        endTime: isNightShift ? applyNightShift(endTime) : endTime,
        chart,
        titleColor,
        occupationColor,
        isNightShift,
      });
    }
  }
};
