import { ReactNode } from 'react';
import NewComponent from './index_feature';
import OriginalComponent from './index_original';

export interface StringDateRange {
  startDate: string;
  endDate: string;
}

export interface DateRangeSelectionProps {
  value: StringDateRange;
  onConfirm: (value: StringDateRange) => void;
  onCancel: () => void;
  isOpen: boolean;
  infoText?: ReactNode;
  hideLabels?: boolean;
  minDate?: string;
  maxDate?: string;
  maxDayRange?: number;
  title?: string;
  useV2?: boolean;
}

export default function DateRangeSelection(props: DateRangeSelectionProps) {
  if (props.useV2) {
    return <NewComponent {...props} />;
  }
  return <OriginalComponent {...props} />;
}
