import { UseFormReturn } from 'react-hook-form';
import { useParams } from 'react-router-dom';
import {
  Box,
  Button,
  VStack,
  Text,
  useDisclosure,
  Flex,
  Tag,
  TagLabel,
  TagCloseButton,
  Wrap,
  Select,
  Input,
} from '@chakra-ui/react';
import RadioGroup from 'design-system/molecules/radio-group';
import { FormattedMessage, useIntl } from 'react-intl';
import { ReactNode } from 'react';
import Substep from 'design-system/molecules/substep';
import { ShowIcon, TimerOnIcon } from 'design-system/atoms/custom-icons';
import { AddIcon } from '@chakra-ui/icons';
import SvgAlternateRoute from '../../svg/alternate-route.svg?react';
import SvgFastestRoute from '../../svg/fastest-route.svg?react';
import SvgRouteSelection from '../../svg/route-selection.svg?react';
import {
  MessageTypeFormType,
  AutomatedLoadBalancingContent,
  RouteProps,
} from './messageTypes';
import Frame, { GenericFrameContent } from './Frame';
import { useAnalytics } from '../../hooks/analytics/useAnalytics';
import VmsPreview from './VmsPreview';
import { errorReport } from '../../utils/errors';
import { useRouteSelection } from '../../hooks/useRouteSelection';

export const DEFAULT_ROUTE_INITIAL_LIMIT = 15;

export default function StepBoardMessageTypeAutomatedLoadBalancingTab({
  routes,
  formManager,
}: {
  routes: RouteProps[];
  formManager: UseFormReturn<MessageTypeFormType>;
}) {
  const { vmsConfigId } = useParams();
  const {
    isOpen: isPreviewOpen,
    onClose: onPreviewClose,
    onOpen,
  } = useDisclosure();
  const { watch, setValue } = formManager;
  const { track } = useAnalytics();

  const formValue = watch('automatedLoadBalancingContent') ?? {
    type: 'FastestRoute' as const,
    messages: generateDefaultRouteMessages(routes),
    alternateDefaultRouteId: routes[0]?.routeId.toString() ?? '',
    alternateDefaultRouteLimit: DEFAULT_ROUTE_INITIAL_LIMIT,
  };
  const setFormValue = (value: AutomatedLoadBalancingContent) => {
    setValue('automatedLoadBalancingContent', value);
  };
  const { selectRoutes } = useRouteSelection();
  const selectedRouteIds = getSelectedRouteIdsFromForm(formValue);
  const selectedRoutes = selectedRouteIds.map((routeId) =>
    getRouteProps(routeId.toString(), routes)
  );
  const handleRouteSelectionChange = (
    routesSelected: { routeId: number }[]
  ) => {
    const newMessages = (formValue.messages ?? [])
      .filter((message) =>
        routesSelected.some(
          (selectedRoute) =>
            selectedRoute.routeId.toString() === message.routeId
        )
      )
      .concat(
        routesSelected
          .filter((selectedRoute) =>
            (formValue.messages ?? []).every(
              (message) => selectedRoute.routeId.toString() !== message.routeId
            )
          )
          .map((newSelectedRoute) => {
            const newRouteProps = getRouteProps(
              newSelectedRoute.routeId.toString(),
              routes
            );
            return generateDefaultMessageForRoute(newRouteProps);
          })
      );
    const defaultRouteMessage = newMessages.find(
      (message) => message.routeId === formValue.alternateDefaultRouteId
    );
    setFormValue({
      ...formValue,
      alternateDefaultRouteId:
        defaultRouteMessage?.routeId ?? newMessages[0].routeId,
      messages: newMessages,
    });
  };
  const handleRouteRemove = (routeId: string) => {
    const hasDeletedDefaultRoute =
      formValue.alternateDefaultRouteId === routeId;
    const filteredMessages = (formValue.messages ?? []).filter(
      (message) => message.routeId !== routeId
    );
    setFormValue({
      ...formValue,
      alternateDefaultRouteId:
        hasDeletedDefaultRoute && filteredMessages.length > 0
          ? filteredMessages[0].routeId
          : formValue.alternateDefaultRouteId ?? '',
      messages: filteredMessages,
    });
  };
  const handleBalancingTypeChange = (
    newValue: 'FastestRoute' | 'AlternateRoute'
  ) => {
    const isDefaultRouteSelected =
      formValue.alternateDefaultRouteId &&
      formValue.messages.some(
        (routeMessage) =>
          routeMessage.routeId === formValue.alternateDefaultRouteId
      );
    setFormValue({
      ...formValue,
      type: newValue,
      alternateDefaultRouteId: isDefaultRouteSelected
        ? formValue.alternateDefaultRouteId ?? ''
        : selectedRoutes[0]?.routeId.toString() ?? '',
      alternateDefaultRouteLimit:
        formValue.alternateDefaultRouteLimit ?? DEFAULT_ROUTE_INITIAL_LIMIT,
    });
  };
  const handleDefaultRouteChange = (newValue: string) => {
    setFormValue({
      ...formValue,
      alternateDefaultRouteId: newValue,
    });
  };
  const handleDefaultRouteLimitChange = (newValue: number) => {
    setFormValue({
      ...formValue,
      alternateDefaultRouteLimit: newValue,
    });
  };
  const handleAddFrame = (routeId: string) => {
    setFormValue({
      ...formValue,
      messages: formValue.messages.map((routeMessage) => {
        if (routeMessage.routeId !== routeId) {
          return routeMessage;
        }
        return {
          ...routeMessage,
          message: routeMessage.message.concat([
            { lines: [], key: `frame-${Math.random()}-${Date.now()}` },
          ]),
        };
      }),
    });
  };
  const handleFrameChange = (
    routeId: string,
    frameIndex: number,
    newFrameContent: GenericFrameContent
  ) => {
    const updatedRouteMessages = formValue.messages.map((routeMessage) => {
      if (routeMessage.routeId !== routeId) {
        return routeMessage;
      }
      return {
        ...routeMessage,
        message: routeMessage.message.map((messageFrame, messageFrameIndex) => {
          if (messageFrameIndex !== frameIndex) {
            return messageFrame;
          }
          return {
            ...messageFrame,
            lines: newFrameContent.lines,
          };
        }),
      };
    });
    setFormValue({
      ...formValue,
      messages: updatedRouteMessages,
    });
  };
  const handleDeleteFrame = (routeId: string, frameIndex: number) => {
    if (!formValue.messages) return;
    const updatedRouteMessages = formValue.messages.map((frame) => {
      if (frame.routeId !== routeId) return frame;
      return {
        ...frame,
        message: frame.message.filter((_, idx) => idx !== frameIndex),
      };
    });
    // TODO: check what happens when all frames are deleted.
    // Silently filtering out routes that the user selected doesn't seem appropriate.
    // Might need to add validation to the form to prevent empty messages
    // .filter((frame) => frame.message.length > 0);
    setFormValue({
      ...formValue,
      messages: updatedRouteMessages,
    });
  };

  return (
    <>
      <VStack bgColor="gray.50" borderRadius="16px" p={4} spacing={6}>
        <Flex
          w="full"
          justifyContent="space-between"
          gap="2"
          direction={{ base: 'column', md: 'row' }}
        >
          <Box fontSize="md" fontWeight="bold">
            <FormattedMessage
              defaultMessage="Automated load balancing allows you to dynamically direct traffic to a faster alternate route"
              id="oTvEv/"
            />
          </Box>
          <Box flex="0">
            <Button
              size="xs"
              variant="outline"
              leftIcon={<ShowIcon />}
              onClick={() => {
                onOpen();
                track('Preview button clicked', {
                  property: 'Preview button',
                  referrer: 'Automated load balancing tab',
                });
              }}
            >
              <FormattedMessage defaultMessage="Preview message" id="qZMvvj" />
            </Button>
          </Box>
        </Flex>

        <VStack w="full" spacing={2} alignItems="stretch">
          <Substep
            stepNumber={1}
            stepText={
              <FormattedMessage
                id="DceyoO"
                defaultMessage="Select the routes that you want to balance between"
              />
            }
          />
          <VStack
            bgColor="white"
            borderRadius="16px"
            p={4}
            alignItems="start"
            spacing={4}
          >
            <Button
              size="xs"
              variant="solid"
              colorScheme="greenDark"
              leftIcon={<AddIcon />}
              onClick={async () => {
                const selected = await selectRoutes(selectedRoutes, true);
                if (selected) {
                  handleRouteSelectionChange(selected);
                }
              }}
            >
              <FormattedMessage defaultMessage="Add routes" id="776cLn" />
            </Button>
            <Wrap spacing={2}>
              {selectedRoutes.map((route) => (
                <Tag
                  key={route.routeId}
                  size="sm"
                  variant="solid"
                  colorScheme="green"
                  backgroundColor="green.400"
                  marginTop="1px"
                  p={0}
                  maxWidth="120px"
                >
                  <TimerOnIcon mr={1} ml={2} />
                  <TagLabel
                    display="inline-block"
                    whiteSpace="nowrap"
                    overflow="hidden"
                    textOverflow="ellipsis"
                  >
                    {route.name}
                  </TagLabel>
                  <TagCloseButton
                    mr={1}
                    onClick={() => {
                      handleRouteRemove(route.routeId.toString());
                    }}
                  />
                </Tag>
              ))}
            </Wrap>
          </VStack>
        </VStack>
        <VStack w="full" spacing={2} alignItems="stretch">
          <Substep
            stepNumber={2}
            stepText={
              <FormattedMessage
                id="MkgKrL"
                defaultMessage="Select how routes are balanced"
              />
            }
          />
          <RouteBalancingSelector
            value={formValue.type}
            onChange={handleBalancingTypeChange}
          />
        </VStack>
        {selectedRoutes.length === 0 ? (
          <EmptyRouteListMessage />
        ) : (
          <RouteMessages
            content={formValue}
            onAddFrame={handleAddFrame}
            onDeleteFrame={handleDeleteFrame}
            onEditFrame={handleFrameChange}
            onDefaultRouteChange={handleDefaultRouteChange}
            onDefaultRouteLimitChange={handleDefaultRouteLimitChange}
            allRoutes={routes}
            selectedRoutes={selectedRoutes}
          />
        )}
      </VStack>
      {isPreviewOpen && (
        <VmsPreview
          messageConfig={{ type: 'AutomatedLoadBalancing', content: formValue }}
          vmsConfigId={vmsConfigId}
          isOpen={isPreviewOpen}
          onCancel={onPreviewClose}
          isLive={false}
        />
      )}
    </>
  );
}

function RouteBalancingSelector({
  value,
  onChange,
}: {
  value: AutomatedLoadBalancingContent['type'];
  onChange: (newValue: AutomatedLoadBalancingContent['type']) => void;
}) {
  const { formatMessage } = useIntl();
  const options = [
    {
      value: 'FastestRoute' as const,
      label: formatMessage({
        id: 'rTcInp',
        defaultMessage: 'Fastest route',
        description: 'Route balancing type selector title',
      }),
      description: formatMessage({
        id: 'mG2c7+',
        defaultMessage: 'Show messaging based on fastest route',
        description: 'Route balancing type selector description',
      }),
      image: <SvgFastestRoute width="100%" height="100%" />,
    },
    {
      value: 'AlternateRoute' as const,
      label: formatMessage({
        id: 'z8oJlt',
        defaultMessage: 'Journey time limit',
        description: 'Route balancing type selector title',
      }),
      description: formatMessage({
        id: 'yilb3Z',
        defaultMessage:
          'Suggest an alternate route when primary route journey time exceeds specified limit',
        description: 'Route balancing type selector description',
      }),
      image: <SvgAlternateRoute width="100%" height="100%" />,
    },
  ];
  return (
    <Box data-testid="route-balancing-type-options">
      <RadioGroup
        options={options}
        onSelectionChange={onChange}
        selection={value}
        direction="row"
      />
    </Box>
  );
}

function EmptyRouteListMessage() {
  return (
    <VStack w="full" spacing={2} alignItems="stretch">
      <Substep
        stepNumber={3}
        stepText={
          <FormattedMessage
            id="DYzACS"
            defaultMessage="Set the messaging for each option"
          />
        }
      />
      <VStack
        spacing={4}
        p={4}
        bgColor="white"
        borderRadius="16px"
        alignItems="start"
      >
        <Text mb={0} fontSize="sm" fontWeight="bold">
          <FormattedMessage defaultMessage="No routes selected" id="qZECxR" />
        </Text>
        <VStack w="full" p={4} borderRadius="16px" backgroundColor="yellow.100">
          <Box w="full" alignItems="start">
            <Text fontSize="sm" fontWeight="bold">
              <FormattedMessage
                defaultMessage="Select the routes you would like to balance between in step 1."
                id="GXG7Y/"
              />
            </Text>
          </Box>
          <Box w="full" display="flex" justifyContent="center">
            <SvgRouteSelection />
          </Box>
        </VStack>
      </VStack>
    </VStack>
  );
}

function RouteMessages({
  onAddFrame,
  onDeleteFrame,
  onEditFrame,
  onDefaultRouteChange,
  onDefaultRouteLimitChange,
  allRoutes,
  selectedRoutes,
  content,
}: {
  onAddFrame: (routeId: string) => void;
  onDeleteFrame: (routeId: string, frameIndex: number) => void;
  onEditFrame: (
    routeId: string,
    frameIndex: number,
    newFrameContent: GenericFrameContent
  ) => void;
  onDefaultRouteChange: (routeId: string) => void;
  onDefaultRouteLimitChange: (limit: number) => void;
  allRoutes: RouteProps[];
  selectedRoutes: RouteProps[];
  content: AutomatedLoadBalancingContent;
}) {
  const isAlternateMode = content.type === 'AlternateRoute';
  const { messages: routeMessages } = content;

  const defaultRouteMessage =
    isAlternateMode &&
    routeMessages.find(
      (routeMessage) => routeMessage.routeId === content.alternateDefaultRouteId
    );
  const defaultRouteProps = allRoutes.find(
    (route) =>
      defaultRouteMessage &&
      route.routeId.toString() === defaultRouteMessage.routeId
  );
  const otherRouteMessages = defaultRouteMessage
    ? routeMessages.filter(
        (routeMessage) => routeMessage.routeId !== defaultRouteMessage.routeId
      )
    : routeMessages;

  const otherRouteMessagesListHeader = isAlternateMode ? (
    <Substep
      stepNumber={4}
      stepText={
        <FormattedMessage
          id="m/aH3b"
          defaultMessage="Set the your alternate messaging for when the selected time limit is breached"
        />
      }
    />
  ) : (
    <Substep
      stepNumber={3}
      stepText={
        <FormattedMessage
          defaultMessage="Set the messaging for each option"
          id="DYzACS"
        />
      }
    />
  );

  return (
    <>
      {isAlternateMode && (
        <VStack w="full" spacing={2} alignItems="stretch">
          <Substep
            stepNumber={3}
            stepText={
              <FormattedMessage
                id="evdfIv"
                defaultMessage="Set the default messaging based on time limit"
              />
            }
          />
          <EditableRouteMessages
            allRoutes={allRoutes}
            routeMessages={defaultRouteMessage ? [defaultRouteMessage] : []}
            onAddFrame={onAddFrame}
            onDeleteFrame={onDeleteFrame}
            onEditFrame={onEditFrame}
            renderRoutePanelHeader={() => (
              <VStack alignItems="flex-start">
                <Box fontSize="sm" fontWeight="bold">
                  <FormattedMessage
                    defaultMessage="Default message"
                    id="Owk0Q5"
                  />
                </Box>
                <Box fontSize="sm">
                  <FormattedMessage
                    defaultMessage="Show this message when {routeName} has a journey time shorter than {limit} minutes."
                    id="MD4iSV"
                    values={{
                      limit: (
                        <Input
                          value={content.alternateDefaultRouteLimit}
                          type="number"
                          onChange={(e) => {
                            onDefaultRouteLimitChange(Number(e.target.value));
                          }}
                          size="sm"
                          width="52px"
                        />
                      ),
                      routeName: (
                        <Select
                          value={defaultRouteProps?.routeId}
                          onChange={(e) => {
                            onDefaultRouteChange(e.target.value);
                          }}
                          width="fit-content"
                          size="sm"
                          display="inline-block"
                        >
                          {selectedRoutes.map((route) => (
                            <option key={route.routeId} value={route.routeId}>
                              {route.name}
                            </option>
                          ))}
                        </Select>
                      ),
                    }}
                  />
                </Box>
              </VStack>
            )}
          />
        </VStack>
      )}
      <VStack w="full" spacing={2} alignItems="stretch">
        {otherRouteMessagesListHeader}
        <EditableRouteMessages
          allRoutes={allRoutes}
          routeMessages={otherRouteMessages}
          onAddFrame={onAddFrame}
          onDeleteFrame={onDeleteFrame}
          onEditFrame={onEditFrame}
          renderRoutePanelHeader={(routeProps) => (
            <RoutePanelHeader
              routeProps={routeProps}
              isAlternateMode={isAlternateMode}
            />
          )}
        />
      </VStack>
    </>
  );
}

function EditableRouteMessages({
  allRoutes,
  routeMessages,
  renderRoutePanelHeader,
  onAddFrame,
  onDeleteFrame,
  onEditFrame,
}: {
  allRoutes: RouteProps[];
  routeMessages: AutomatedLoadBalancingContent['messages'];
  renderRoutePanelHeader: (routeProps: RouteProps) => ReactNode;
  onAddFrame: (routeId: string) => void;
  onDeleteFrame: (routeId: string, frameIndex: number) => void;
  onEditFrame: (
    routeId: string,
    frameIndex: number,
    newFrameContent: GenericFrameContent
  ) => void;
}) {
  return (
    <VStack spacing={4} alignItems="stretch">
      {routeMessages.map((routeMessage) => {
        const routeProps = allRoutes.find(
          (route) => route.routeId.toString() === routeMessage.routeId
        );
        return (
          <VStack
            key={routeMessage.routeId}
            p={4}
            spacing={4}
            alignItems="stretch"
            bgColor="white"
            borderRadius="16px"
          >
            {renderRoutePanelHeader && routeProps && (
              <Box>{renderRoutePanelHeader(routeProps)}</Box>
            )}

            {routeMessage.message.map((message, frameIndex) => (
              <Box key={message.key}>
                <Frame
                  canAddTag
                  index={frameIndex}
                  content={message}
                  routes={allRoutes}
                  onDelete={() =>
                    onDeleteFrame(routeMessage.routeId, frameIndex)
                  }
                  onContentChange={(newContent) =>
                    onEditFrame(routeMessage.routeId, frameIndex, newContent)
                  }
                />
              </Box>
            ))}
            <Flex justifyContent="center">
              <Button
                size="xs"
                variant="outline"
                leftIcon={<AddIcon />}
                onClick={() => onAddFrame(routeMessage.routeId)}
              >
                <FormattedMessage defaultMessage="Add frame" id="vSbhQI" />
              </Button>
            </Flex>
          </VStack>
        );
      })}
    </VStack>
  );
}

function RoutePanelHeader({
  routeProps,
  isAlternateMode = false,
}: {
  routeProps: RouteProps;
  isAlternateMode?: boolean;
}) {
  return (
    <VStack alignItems="flex-start">
      <Box fontSize="sm" fontWeight="bold">
        <FormattedMessage
          defaultMessage="Message for {routeName}"
          id="fB0eme"
          values={{ routeName: routeProps.name }}
        />
      </Box>
      <Box fontSize="sm">
        <FormattedMessage
          defaultMessage="Show this message when {routeName} has the shortest journey time{comparedTo, select, alternateRoutes { of the alternate routes.} other {.}}"
          id="WJHXbC"
          values={{
            comparedTo: isAlternateMode ? 'alternateRoutes' : 'allRoutes',
            routeName: (
              <Tag
                size="sm"
                variant="solid"
                backgroundColor="gray.50"
                marginTop="1px"
                maxWidth="120px"
              >
                <TagLabel
                  display="inline-block"
                  whiteSpace="nowrap"
                  overflow="hidden"
                  textOverflow="ellipsis"
                  color="gray.700"
                >
                  {routeProps.name}
                </TagLabel>
              </Tag>
            ),
          }}
        />
      </Box>
    </VStack>
  );
}

function getRouteProps(routeId: string, routes: RouteProps[]) {
  const routeProps = routes.find((route) => route.routeId === Number(routeId));
  if (!routeProps) {
    errorReport.handled(new Error(`Route with id ${routeId} not found`));
    return { name: 'route not found', routeId: 0 };
  }
  return routeProps;
}

function getSelectedRouteIdsFromForm(
  formFields?: AutomatedLoadBalancingContent
) {
  if (!formFields) {
    return [];
  }
  return formFields.messages.map((message) => message.routeId) ?? [];
}
export function getAllRouteIdsFromAutomatedLoadBalancingContent(
  formFields?: AutomatedLoadBalancingContent
) {
  if (!formFields) {
    return [];
  }
  const selectedRouteIds = getSelectedRouteIdsFromForm(formFields);
  const journeyTimeRouteIds = formFields.messages.flatMap((routeMessage) =>
    routeMessage.message.flatMap(({ lines }) =>
      lines.flatMap((line) =>
        line.lineParts
          .filter((part) => part.type === 'journeyTime')
          .map((part) => part.content)
      )
    )
  );
  return Array.from(new Set([...selectedRouteIds, ...journeyTimeRouteIds]));
}

function generateDefaultFrameForRoute(route: RouteProps) {
  return {
    key: `frame-${route.routeId}-${Date.now()}`,
    lines: [
      {
        key: `line-0-${Date.now()}`,
        lineParts: [
          {
            type: 'text' as const,
            content: `VIA ${route.name.substring(0, 8)}`,
          },
        ],
      },
      {
        key: `line-1-${Date.now()}`,
        lineParts: [
          {
            type: 'journeyTime' as const,
            content: route.routeId.toString(),
          },
          {
            type: 'text' as const,
            content: ' MIN',
          },
        ],
      },
    ],
  };
}
function generateDefaultMessageForRoute(route: RouteProps) {
  return {
    routeId: `${route.routeId}`,
    message: [generateDefaultFrameForRoute(route)],
  };
}

export function generateDefaultRouteMessages(routesData?: RouteProps[]) {
  return (
    routesData
      ?.slice(0, 2)
      .map((route) => generateDefaultMessageForRoute(route)) ?? []
  );
}
