import useSWR from 'swr';
import { BaselineResponse } from '@webapp/bff/src/types/baseline';
import { trpc } from '../config/trpc';

export default function useSiteBaselinesData(
  siteId?: string,
  suspense = false
) {
  const { data, mutate, isValidating, error } = useSWR<BaselineResponse>(
    siteId ? `useSiteBaselinesData-${siteId}` : null,
    !siteId ? null : () => trpc.site.getBaselines.query({ siteId }),
    {
      suspense,
    }
  );

  const mutateThenUpdate =
    (serverOp) =>
    async (
      ...args: Parameters<typeof serverOp>
    ): Promise<ReturnType<typeof serverOp>> => {
      const response = await serverOp(...args);
      await mutate();
      return response;
    };

  return {
    data,
    error,
    isValidating,
    requestBaselineCreation: ({
      name,
      startDate,
      endDate,
      description,
    }: {
      name: string;
      startDate: string;
      endDate: string;
      description?: string;
    }): Promise<void> =>
      mutateThenUpdate(trpc.site.createBaseLine.mutate)({
        siteId,
        name,
        startDate,
        endDate,
        description,
      }),
  };
}
