import { ReactNode, Children } from 'react';
import styles from './index.module.scss';

interface Props {
  children?: ReactNode;
}

export default function GridWrapper({ children }: Props) {
  return (
    <div className={styles.root}>
      {Children.map(children, (child) => (
        <div className={styles.gridElement}>{child}</div>
      ))}
    </div>
  );
}
