import useSWRImmutable from 'swr/immutable';
import { VehicleDailyProfile } from '@data-pipeline/api/src/types/vehicle-count';
import { CountAvailabilityParams } from './useCountAvailabilityData';
import { dataTrpc } from '../config/trpc';

export interface DailyProfileParams {
  insightId?: string;
  siteId?: number;
  sourceId?: number;
  startDate?: number;
  endDate?: number;
  daysOfTheWeek?: number[];
}

const isParamsDefined = (params: CountAvailabilityParams) => {
  if (
    params.insightId !== undefined &&
    params.siteId !== undefined &&
    params.sourceId !== undefined &&
    params.startDate !== undefined &&
    params.endDate !== undefined &&
    params.daysOfTheWeek !== undefined
  ) {
    return true;
  }
  return false;
};

export const useCountDailyProfileData = (
  params: DailyProfileParams
): {
  data?: VehicleDailyProfile | undefined;
  isValidating: boolean;
  error: any;
} => {
  const { data, isValidating, error } = useSWRImmutable(
    isParamsDefined(params)
      ? {
          keyName: 'useCountDailyProfileData',
          insightId: params.insightId,
          siteId: params.siteId,
          sourceId: params.sourceId,
          from: Math.floor(params.startDate! / 1000),
          to: Math.floor(params.endDate! / 1000),
          selectedWeekDays: params.daysOfTheWeek!.join(','),
        }
      : null,
    async (swrParams) =>
      dataTrpc.vehicleCount.getVehicleCountProfileData.query({
        siteId: Number(swrParams.siteId),
        from: swrParams.from,
        to: swrParams.to,
        selectedWeekDays: swrParams.selectedWeekDays,
        sourceId: Number(params.sourceId),
      })
  );

  return {
    data,
    isValidating,
    error,
  };
};
