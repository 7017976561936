export const processBlob = (blob, filename) => {
  const blobCSV = new Blob([blob], {
    type: 'data:text/csv;charset=utf-8,',
  });
  const url = window.URL.createObjectURL(blobCSV);
  const anchor = document.createElement('a');
  anchor.href = url;
  anchor.download = filename;
  anchor.dataset.downloadurl = ['text/csv', anchor.download, anchor.href].join(
    ':'
  );
  anchor.click();
};
