import { ReactNode } from 'react';
import { useIntl } from 'react-intl';
import ConfirmationModal from 'design-system/molecules/confirmation-modal';
import { useForm } from 'react-hook-form';
import DateRangeFieldset from './date-range-fieldset';

export interface StringDateRange {
  startDate: string;
  endDate: string;
}

export interface DateRangeSelectionProps {
  value: StringDateRange;
  onConfirm: (value: StringDateRange) => void;
  onCancel: () => void;
  isOpen: boolean;
  infoText?: ReactNode;
  hideLabels?: boolean;
  minDate?: string;
  maxDate?: string;
  maxDayRange?: number;
  title?: string;
}

export default function DateRangeSelection({
  value,
  onConfirm,
  onCancel,
  isOpen,
  infoText,
  minDate,
  maxDate,
  maxDayRange,
  hideLabels,
  title,
}: DateRangeSelectionProps) {
  const { formatMessage } = useIntl();
  const formProps = useForm({
    values: value,
    mode: 'onBlur',
    reValidateMode: 'onBlur',
  });
  const confirmClickHandler = formProps.handleSubmit(onConfirm);
  return (
    isOpen && (
      <ConfirmationModal
        body={
          <DateRangeFieldset
            formProps={formProps}
            infoText={infoText}
            minDate={minDate}
            maxDate={maxDate}
            maxDayRange={maxDayRange}
            hideLabels={hideLabels}
          />
        }
        confirmButtonText={formatMessage({
          description:
            'View time selection - custom date popup confirm button label',
          defaultMessage: 'Set dates',
          id: 'Ln270B',
        })}
        onCancel={onCancel}
        onConfirm={confirmClickHandler}
        open={isOpen}
        title={
          title ??
          formatMessage({
            description: 'View time selection - custom date popup title',
            defaultMessage: 'Select a date range',
            id: '8MARgK',
          })
        }
      />
    )
  );
}
