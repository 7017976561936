import useSWR from 'swr';
import { LocalDate } from '@webapp/bff/src/types/dates';
import { DataType } from '@webapp/bff';
import useParamInsightId from '../hooks/useParamInsightId';
import useSiteView from '../hooks/useSiteView';
import { Site } from '../types/site';
import { trpc } from '../config/trpc';
import { standardFetch } from '../utils/fetch';
import { getSiteDetailsKey } from './sharedKeys';

export type SiteInfo = Site & { collectedDataTypes?: DataType[] };

const orderedDataTypes = [
  'SITE_IMPACT_DELAY',
  'TIME_DELAY',
  'JOURNEY_TIME',
  'SPEED',
  'QUEUE_LENGTH',
  'VEHICLE_COUNT',
  'DUST',
  'NOISE',
  'RAIN',
  'ENVIRONMENT',
  'COMPLETED_TRIP_TIME',
  'DWELL_TIME',
] as const;

export default function useSiteData(
  siteParams: null | {
    orgId: string;
    siteId: string;
  }
) {
  const { orgId, siteId } = siteParams ?? {};
  const { data, error, mutate, isValidating } = useSWR<SiteInfo>(
    siteId && orgId ? getSiteDetailsKey(orgId, siteId) : null,
    async (params) => {
      if (!siteId || !orgId) {
        throw new Error('Missing siteParams from useSiteData');
      }
      const siteData: SiteInfo = await standardFetch<Site>(params);
      const pollingDates = await trpc.site.getMonitoringInfo.query({
        orgId,
        siteId,
      });
      siteData.startDate = (pollingDates.startTime ?? 0) / 1000;
      siteData.startPollingDate = (pollingDates.startTime ?? 0) / 1000;
      siteData.endPollingDate = (pollingDates.endTime ?? 0) / 1000;
      siteData.dataCollected = pollingDates.endTime !== undefined;
      siteData.collectedDataTypes = pollingDates.availableTypes.sort(
        (t1, t2) => orderedDataTypes.indexOf(t1) - orderedDataTypes.indexOf(t2)
      );
      return siteData;
    },
    { revalidateOnFocus: false }
  );
  const siteHasQueue =
    data?.collectedDataTypes?.includes('QUEUE_LENGTH') ?? false;
  const mutateThenUpdate =
    (serverOp) =>
    async (
      ...args: Parameters<typeof serverOp>
    ): Promise<ReturnType<typeof serverOp>> => {
      const response = await serverOp(...args);
      await mutate();
      return response;
    };
  return {
    data,
    siteHasQueue,
    error,
    mutate,
    isValidating,
    updateSiteOccupationDates: async (occupationDates: {
      startDate?: LocalDate;
      endDate?: LocalDate;
    }) => {
      if (occupationDates.startDate && occupationDates.endDate) {
        await mutateThenUpdate(trpc.site.updateOccupationDates.mutate)({
          siteId: String(siteId),
          occupationStartDate: occupationDates.startDate,
          occupationEndDate: occupationDates.endDate,
        });
      } else {
        await mutateThenUpdate(trpc.site.removeOccupationDates.mutate)({
          siteId: String(siteId),
        });
      }
    },
    updateSiteDisruptionArea: async (disruptionArea: string) => {
      if (data) {
        mutate(
          {
            ...data,
            disruptionArea,
          },
          {
            revalidate: false,
          }
        );
        return trpc.site.updateDisruptionArea.mutate({
          siteId: String(siteId),
          disruptionArea,
        });
      }
      return mutateThenUpdate(trpc.site.updateDisruptionArea.mutate)({
        siteId: String(siteId),
        disruptionArea,
      });
    },
  };
}

export function useCurrentSiteData() {
  const insightId = useParamInsightId();
  const siteView = useSiteView();
  return useSiteData({
    orgId: insightId,
    siteId: siteView.state.siteId.toString(),
  });
}
