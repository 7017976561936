import {
  Box,
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  VStack,
} from '@chakra-ui/react';
import { FormattedMessage } from 'react-intl';
import { useRef } from 'react';
import BaselineForm from '../BaselineForm';
import { useActionWithFeedback } from '../../hooks/useActionWithFeedback';

export function CreateBaselineModal({
  isOpen,
  onClose,
  createBaseline,
}: {
  isOpen: boolean;
  onClose: () => void;
  createBaseline: (input: {
    name: string;
    startDate: string;
    endDate: string;
    description?: string;
  }) => Promise<void>;
}) {
  const submitActionRef = useRef<any>();
  const { performAction, isPerformingAction } = useActionWithFeedback({
    action: async (values) => {
      await createBaseline(values);
      onClose();
    },
    trackEvent: {
      name: 'Baseline Created',
      data: {
        referrer: 'Site Impact Chart',
      },
    },
  });
  return (
    <Modal isCentered isOpen={isOpen} onClose={onClose}>
      <ModalOverlay backgroundColor="whiteAlpha.800" />
      <ModalContent>
        <ModalHeader>
          <VStack w="full" direction="column" alignItems="flex-start">
            <Box>
              <FormattedMessage defaultMessage="Create baseline" id="VhLfoG" />
            </Box>
          </VStack>
        </ModalHeader>
        <ModalCloseButton backgroundColor="gray.100" />
        <ModalBody overflowY="auto">
          <BaselineForm
            onSubmit={performAction}
            isSubmitting={isPerformingAction}
            submitActionRef={submitActionRef}
          />
        </ModalBody>
        <ModalFooter justifyContent="start">
          <Button
            data-testid="create-baseline-confirm"
            variant="solid"
            colorScheme="greenDark"
            mr={2}
            onClick={() => submitActionRef.current?.()}
            isLoading={isPerformingAction}
          >
            <FormattedMessage defaultMessage="Create" id="VzzYJk" />
          </Button>
          <Button
            data-testid="create-baseline-cancel"
            variant="outline"
            mr={2}
            onClick={onClose}
          >
            <FormattedMessage defaultMessage="Cancel" id="47FYwb" />
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}
