import type { Role, Team, TeamUser } from '@webapp/bff';
import { useMemo } from 'react';
import useSWR from 'swr';
import { trpc } from '../config/trpc';
import { useAnalytics } from '../hooks/analytics/useAnalytics';
import { resetPassword } from '../api/siteUser';
import { inviteOrgUsers, resendOrgInvite } from '../api/orgUser';
import useCurrentUserData from './useCurrentUserData';
import { useOptimisticMutation } from '../utils/dataHandler';

// TODO: to go with more generic solution to attach keys to sessions
const getTeamSWRKey = (orgId: string) =>
  `${localStorage.getItem('session-id') ?? ''}/useTeamData/${orgId}`;

export default function useTeamData(orgId?: string, suspense = true) {
  const { track } = useAnalytics();
  const teamKey = useMemo(() => (orgId ? getTeamSWRKey(orgId) : null), [orgId]);
  const { data, mutate, isValidating, error } = useSWR(
    teamKey,
    () => trpc.team.get.query({ orgId: orgId! }),
    {
      suspense,
    }
  );
  const optimisticTeamMutation = useOptimisticMutation(mutate);
  const currentUser = useCurrentUserData();
  return {
    data: data && {
      ...data,
      users: [...data.users].sort(sortUsersByNameThenEmail),
    },
    mutate,
    error,
    isValidating,
    updateTeamRole: optimisticTeamMutation({
      serverOp: trpc.team.updateTeamRole.mutate,
      localOp: (args) => {
        track('Team Member Role Changed', {
          userId: args.userId,
          orgId: args.orgId,
          previousRole: data?.users.find(
            (user) => user.externalId === args.userId
          )?.teamRole,
          currentRole: args.role,
          changedByUserId: currentUser.data?.userId,
          referrer: 'Team settings',
        });
        return {
          ...data!,
          users: data!.users.map((member) => {
            if (member.externalId !== args.userId) {
              return member;
            }
            return {
              ...member,
              teamRole: args.role,
            };
          }),
        };
      },
    }),
    inviteTeamMembers: async (formData: {
      recipients: string[];
      role: Role;
      orgId: string;
    }) => {
      const { recipients, role } = formData;
      await inviteOrgUsers(orgId!, {
        emails: recipients,
        role: role === 'Editor' ? 'editor' : 'viewer',
      });
      await mutate();
      track('Users Invited to Org', {
        referrer: 'Team Settings',
        role,
        users: recipients,
        org: orgId,
      });
      recipients.forEach((recipient) => {
        track('Team Member Invited', {
          referrer: 'Team Settings',
          role,
          email: recipient,
          invitedByUserId: currentUser.data?.userId,
          org: orgId,
        });
      });
    },
    onResendInvite: async (user: TeamUser) => {
      await resendOrgInvite(orgId!, {
        emails: [user.email],
        role: user.teamRole === 'Editor' ? 'editor' : 'viewer',
      });
      track('User Re-Invited', { referrer: 'Team Settings', user });
    },
    onSendPasswordReset: async (user: TeamUser, referrer = 'Team Settings') => {
      await resetPassword(user.email);
      track('Password Reset', {
        referrer,
        user,
        requestedByUserId: currentUser.data?.userId,
      });
    },
    removeFromTeam: async ({ userId }: { userId: string }) => {
      const response = await trpc.team.removeFromTeam.mutate({
        userId,
        orgId: orgId!,
      });
      await mutate((aData: Team | undefined) => ({
        ...aData!,
        users: aData!.users.filter((m) => m.externalId !== userId),
      }));
      track('User Removed', {
        orgId,
        userId,
        orgRole: data?.users.find((m) => m.externalId !== userId)?.teamRole,
        referrer: 'Team settings',
        removedByUserId: currentUser.data?.userId,
      });
      return response;
    },
    // Update only local state since server side logic remains in old BFF
    updateLocalCacheSiteRole({ userId, siteId, role }) {
      return mutate((aData: Team | undefined) => ({
        ...aData!,
        users: aData!.users.map((user) => {
          if (user.userId !== userId) {
            return user;
          }
          return {
            ...user,
            sites: user.sites
              .filter(
                (sitePermission) => sitePermission.siteId !== siteId.toString()
              )
              .concat({
                siteId: siteId.toString(),
                siteRole: role,
              }),
          };
        }),
      }));
    },
  };
}

function sortUsersByNameThenEmail(userA: TeamUser, userB: TeamUser) {
  const nameCompare = userA.name.trim().localeCompare(userB.name.trim());
  if (nameCompare !== 0) {
    return nameCompare;
  }
  return userA.email.localeCompare(userB.email);
}
