import {
  Heading,
  HStack,
  VStack,
  Text,
  Button,
  useDisclosure,
} from '@chakra-ui/react';
import { FormattedMessage } from 'react-intl';
import Taperecorder from '../../svg/tape-recorder.svg?react';
import { CreateBaselineModal } from './CreateBaselineModal';
import LayoutPanel from '../LayoutPanel';

export default function SiteImpactEmptyStatePanel({
  variant,
  createBaseline,
}: {
  variant: 'recording' | 'empty';
  createBaseline: (input: {
    name: string;
    startDate: string;
    endDate: string;
    description?: string;
  }) => Promise<void>;
}) {
  const { isOpen, onClose, onOpen } = useDisclosure();
  return (
    <LayoutPanel
      data-testid="view-site-chart-panel-empty-site-impact"
      padding="0px"
      backgroundColor="yellow.50"
    >
      <HStack
        w="full"
        alignItems="center"
        h="76px"
        justifyContent="space-between"
        px={4}
      >
        <CreateBaselineModal
          createBaseline={createBaseline}
          isOpen={isOpen}
          onClose={onClose}
        />
        <HStack>
          <VStack alignItems="flex-start">
            <Heading size="md">Site impact</Heading>
            <Text fontSize="xs" as="span">
              {variant === 'recording' && (
                <FormattedMessage
                  defaultMessage="Recording in progress"
                  id="rOEJRJ"
                />
              )}
              {variant === 'empty' && (
                <FormattedMessage
                  defaultMessage="Understand how your site is comparing compared to a fixed date in time"
                  id="Gbk1c8"
                />
              )}
            </Text>
          </VStack>
          {variant === 'recording' && <Taperecorder width="112px" />}
        </HStack>
        <Button variant="outline" size="xs" onClick={onOpen}>
          <FormattedMessage defaultMessage="New baseline" id="qUa6D8" />
        </Button>
      </HStack>
    </LayoutPanel>
  );
}
