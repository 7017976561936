import { Button } from '@chakra-ui/react';

interface Props {
  suggestion: string;
  onClick: (suggestion: string) => void;
}

const Suggestion = ({ suggestion, onClick }: Props) => (
  <Button
    mx={1}
    my={0.5}
    px={2}
    py={2}
    fontSize="md"
    fontWeight="normal"
    rounded="md"
    _hover={{ bg: 'gray.50', color: 'gray.900' }}
    alignItems="center"
    justifyContent="start"
    variant="ghost"
    key={suggestion}
    onClick={() => onClick(suggestion)}
  >
    {suggestion}
  </Button>
);

export default Suggestion;
