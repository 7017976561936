import { useState } from 'react';
import { useIntl } from 'react-intl';
import { MESSAGE_GENERIC_ERROR } from '../../constants/messages';
import Note from '../../local_design_system/organisms/Note/Note';
import { useAnalytics } from '../../hooks/analytics/useAnalytics';
import { useSuccessToast } from '../../hooks/useSuccessToast';
import { Annotation } from '../../types/annotations';
import { TimePeriod } from '../../types/timePeriod';
import { isOutsideTimePeriod } from '../../utils/timePeriodUtils';
import DeleteNodeConfirmationModal from './DeleteNoteConfirmationModal';
import NoteDateOutsideTimePeriodModal from './NoteDateOutsideTimePeriodModal';
import { UpdateChartCallbacks } from '../../utils/insightCharts';
import useSiteView from '../../hooks/useSiteView';
import { errorReport } from '../../utils/errors';

export interface EditableAnnotationProps {
  annotation?: Annotation;
  timeZone: string;
  onCancel?: () => void;
  onDelete?: (annotation: Annotation) => Promise<void>;
  onSave: (editedAnnotation: Annotation) => Promise<void>;
  onClick?: (annotation: { startDate: number; endDate?: number }) => void;
  onResendAlert: (annotationId: number) => Promise<void>;
  updateChartCallbacks?: UpdateChartCallbacks;
}

export function EditableAnnotation({
  annotation,
  timeZone,
  onCancel,
  onDelete,
  onSave,
  onClick,
  onResendAlert,
  updateChartCallbacks,
}: EditableAnnotationProps) {
  const isNewAnnotation = !annotation || annotation.annotationId === undefined;
  const [isEditing, setEditing] = useState(false);
  const [isSaving, setSaving] = useState(false);
  const [hasError, setError] = useState(false);
  const { formatMessage } = useIntl();
  const siteView = useSiteView();
  const [outsideTimePeriodModalState, setOutsideTimePeriodModalState] =
    useState<{ open: false } | { open: true; editedAnnotation: Annotation }>({
      open: false,
    });
  const [isDeleteConfirmationModalOpen, setDeleteConfirmationModalOpen] =
    useState(false);
  const toast = useSuccessToast();
  const { track } = useAnalytics();

  const handleSave = (editedAnnotation: Partial<Annotation>) => {
    // TODO: Add form validation
    setSaving(true);
    setError(false);
    return onSave(editedAnnotation)
      .then(() => {
        setSaving(false);
        setEditing(false);
        track(`Note ${isNewAnnotation ? 'Created' : 'Updated'} `, {
          referrer: 'Annotations',
          hasAlert: editedAnnotation.sendAlert,
        });
        if (isNewAnnotation) {
          toast({
            title: formatMessage({
              defaultMessage: 'Note created',
              id: 'L9P/me',
              description: 'Note created',
            }),
          });
        } else {
          toast({
            title: formatMessage({
              defaultMessage: 'Note updated',
              id: 'T2xPB8',
              description: 'Note updated',
            }),
          });
        }
      })
      .catch((error) => {
        setSaving(false);
        setError(true);
        errorReport.handled(error);
      });
  };
  const handleDeleteButtonClick = () => {
    setDeleteConfirmationModalOpen(true);
  };
  const handleDeleteConfirmation =
    (deletedAnnotation: Annotation) => async () => {
      if (onDelete) {
        setSaving(true);
        setError(false);
        try {
          await onDelete(deletedAnnotation);
          track('Note Deleted', {
            referrer: 'Annotations',
          });
          toast({
            title: formatMessage({
              defaultMessage: 'Note deleted',
              id: 'tMzuG2',
              description: 'Note deleted',
            }),
          });
        } catch (error) {
          setError(true);
          errorReport.handled(error);
        } finally {
          setSaving(false);
        }
      }
    };
  return (
    <>
      <Note
        annotation={annotation || {}}
        errorMessage={
          hasError ? formatMessage(MESSAGE_GENERIC_ERROR) : undefined
        }
        timeZone={timeZone}
        isEditing={isEditing || isNewAnnotation}
        isSaving={isSaving}
        onEdit={() => {
          setEditing(true);
        }}
        onCancel={
          onCancel ||
          (() => {
            setEditing(false);
          })
        }
        onSave={async (editedAnnotation) => {
          if (
            // If new start or end dates have been entered
            (isNewAnnotation ||
              annotation.startDate !== editedAnnotation.startDate ||
              annotation.endDate !== editedAnnotation.endDate) &&
            // If start or end dates are outside selected time period
            // TODO: adjust for inactive sites date offset
            siteView &&
            isAnnotationOutsideTimePeriod(
              editedAnnotation,
              siteView.state.period,
              timeZone
            )
          ) {
            // Show confirmation modal
            setOutsideTimePeriodModalState({
              open: true,
              editedAnnotation,
            });
          } else {
            await handleSave(editedAnnotation);
          }
        }}
        onDelete={handleDeleteButtonClick}
        onClick={onClick}
        onResendAlert={onResendAlert}
        updateChartCallbacks={updateChartCallbacks}
      />
      <NoteDateOutsideTimePeriodModal
        open={outsideTimePeriodModalState.open}
        onCancel={() => setOutsideTimePeriodModalState({ open: false })}
        onConfirm={async () => {
          setOutsideTimePeriodModalState({ open: false });
          if ('editedAnnotation' in outsideTimePeriodModalState) {
            await handleSave(outsideTimePeriodModalState.editedAnnotation);
          }
        }}
      />
      <DeleteNodeConfirmationModal
        open={isDeleteConfirmationModalOpen}
        onCancel={() => setDeleteConfirmationModalOpen(false)}
        onConfirm={annotation ? handleDeleteConfirmation(annotation) : () => {}}
      />
    </>
  );
}

function isAnnotationOutsideTimePeriod(
  annotation: Annotation,
  timePeriod: TimePeriod,
  timeZone: string
) {
  return (
    (annotation.startDate !== undefined &&
      isOutsideTimePeriod(annotation.startDate, timePeriod, timeZone)) ||
    (annotation.endDate !== undefined &&
      isOutsideTimePeriod(annotation.endDate, timePeriod, timeZone))
  );
}
