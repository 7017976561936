import { useCallback, useEffect, useRef, useState } from 'react';
import {
  ButtonGroup,
  Flex,
  IconButton,
  Text,
  Box,
  keyframes,
  Button,
  Heading,
} from '@chakra-ui/react';
import { FormattedMessage, FormattedRelativeTime } from 'react-intl';
import {
  ArrowLeftIcon,
  ArrowRightIcon,
  PauseIcon,
  PlayIcon,
} from 'design-system/atoms/custom-icons';
import { Status } from 'design-system/atoms/status';
import { useIntercom } from 'react-use-intercom';

export interface VmsBoardContentProps {
  title: string;
  description: string;
  message: {
    lines: string[];
  }[];
  isPaused?: boolean;
  isRoutePolling?: boolean;
  lastUpdatedDate?: Date;
}
const frameSeconds = 3;

const VmsBoardContent = ({
  title,
  description,
  message,
  isPaused = false,
  isRoutePolling,
  lastUpdatedDate,
}: VmsBoardContentProps) => {
  const intercom = useIntercom();
  const animationProps = `${keyframes(
    'from {width: 0%} to {width: 100%}'
  )} ${frameSeconds}s infinite !important`;
  const isMultipleFrames = message.length > 1;
  const [pause, setPause] = useState(isPaused);
  const progressRef = useRef<HTMLDivElement>(null);
  const [animation, setAnimation] = useState<string | undefined>(
    isPaused ? undefined : animationProps
  );
  const [frame, setFrame] = useState<number>(message.length > 0 ? 0 : -1);
  const handleClickPause = () => {
    if (!pause) {
      setAnimation(undefined);
    } else {
      setAnimation(animationProps);
    }
    setPause(!pause);
  };

  const animationListener = useCallback(() => {
    if (message.length > 0) {
      setFrame(frame + 1 > message.length - 1 ? 0 : frame + 1);
    }
  }, [frame, message.length]);
  useEffect(() => {
    const referenceRef = progressRef.current;
    if (referenceRef) {
      referenceRef.addEventListener('animationiteration', animationListener);
    }
    return () => {
      if (referenceRef) {
        referenceRef.removeEventListener(
          'animationiteration',
          animationListener
        );
      }
    };
  }, [animationListener]);

  const secondsSinceLastUpdated = lastUpdatedDate
    ? Math.floor((new Date().getTime() - lastUpdatedDate.getTime()) / 1000)
    : -1;
  return (
    <Flex direction="column" width="full">
      <Heading
        color="gray.700"
        fontSize="md"
        lineHeight={6}
        fontWeight={700}
        mb={2}
      >
        {title}
      </Heading>
      <Text color="gray.900" fontSize="xs" lineHeight={4} fontWeight={400}>
        {description}
      </Text>
      <Flex
        borderRadius="16px"
        backgroundColor="gray.900"
        width="full"
        height="182px"
        direction="column"
        justifyContent="center"
        alignItems="center"
      >
        {frame !== -1 &&
          message[frame].lines.map((line, lineIndex) => (
            <Text
              // eslint-disable-next-line react/no-array-index-key
              key={lineIndex}
              color="yellow.300"
              textTransform="uppercase"
              fontSize="lg"
              lineHeight={7}
              fontWeight={700}
              m={0}
              mb={1}
            >
              {line}
            </Text>
          ))}
      </Flex>
      {isMultipleFrames && (
        <Box
          mt={2}
          width="full"
          height="8px"
          backgroundColor="gray.100"
          borderRadius="4px"
        >
          <Box
            ref={progressRef}
            width={0}
            height="8px"
            backgroundColor="green.500"
            borderRadius="4px"
            animation={animation}
          />
        </Box>
      )}
      <Flex justify="space-between">
        <Text
          mt={1}
          color="gray.900"
          fontSize="xs"
          lineHeight={4}
          fontWeight={700}
        >
          <FormattedMessage
            defaultMessage="Frame {frameIndex} of {totalFrames}"
            id="/RdU2Z"
            values={{
              frameIndex: frame + 1,
              totalFrames: message.length,
            }}
          />
        </Text>
        {isMultipleFrames && (
          <ButtonGroup mt={1}>
            <IconButton
              variant="ghost"
              onClick={() => {
                setFrame(frame - 1 >= 0 ? frame - 1 : 0);
              }}
              aria-label="Previous"
              icon={<ArrowLeftIcon />}
              size="xs"
            />
            <IconButton
              variant="ghost"
              onClick={handleClickPause}
              aria-label="PauseOrPlay"
              icon={pause ? <PlayIcon /> : <PauseIcon />}
              size="xs"
            />
            <IconButton
              variant="ghost"
              onClick={() => {
                setFrame(
                  frame + 1 <= message.length - 1
                    ? frame + 1
                    : message.length - 1
                );
              }}
              aria-label="Next"
              icon={<ArrowRightIcon />}
              size="xs"
            />
          </ButtonGroup>
        )}
      </Flex>
      <Status
        warningStatus={
          secondsSinceLastUpdated > 3600 ? (
            <>
              <FormattedMessage defaultMessage="Last updated" id="0ICwq5" />{' '}
              <FormattedRelativeTime
                value={-secondsSinceLastUpdated}
                numeric="auto"
                updateIntervalInSeconds={1}
              />
              {isRoutePolling ? (
                <FormattedMessage
                  defaultMessage=": Connection lost."
                  id="byZk3B"
                />
              ) : (
                <FormattedMessage
                  defaultMessage=": Route not recording."
                  id="Hm4jRM"
                />
              )}{' '}
              <FormattedMessage
                id="njpZN6"
                defaultMessage="{getInTouch}"
                values={{
                  getInTouch: (
                    <Button
                      textDecoration="underline"
                      variant="link"
                      color="orange.400"
                      size="xs"
                      onClick={() => {
                        intercom.showNewMessage(
                          'Hi, a route seems to have stopped recording data...'
                        );
                      }}
                    >
                      <FormattedMessage
                        defaultMessage="Get in touch with Mooven"
                        id="yAd/3m"
                      />
                    </Button>
                  ),
                }}
              />
            </>
          ) : (
            ''
          )
        }
      />
    </Flex>
  );
};

export default VmsBoardContent;
