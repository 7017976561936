import { Box, Flex, Heading, Text } from '@chakra-ui/react';
import { FormattedMessage, useIntl } from 'react-intl';
import GridWrapper from 'design-system/atoms/grid-wrapper';
import Insight from 'design-system/molecules/insight';
import LayoutEmpty from 'design-system/molecules/layout-empty';
import performanceStatsOptions from '../../constants/performanceStatsOptions';
import useInsightSummaryData from '../../data/useInsightSummaryData';
import useParamInsightId from '../../hooks/useParamInsightId';
import useSiteView from '../../hooks/useSiteView';
import { useCurrentSiteData } from '../../data/useSiteData';
import {
  DataHandlerFeedback,
  DataHandlerParam,
  hasDataHandlerFeedBack,
} from '../../utils/dataHandler';
import {
  DATE_PRESET_NARROW_COMPLETE,
  TIME_PRESET_COMPLETE_HOUR12,
} from '../../utils/dateUtils';
import ErrorBoundary from '../../local_design_system/molecules/ErrorBoundary';
import NoDataForDates from '../../svg/no-data-for-dates.svg?react';
import NoRouteData from '../../svg/no-routes-data-icon.svg?react';
import SleepIcon from '../../svg/sleep-icon.svg?react';
import { pathSitesEdit } from '../../constants/path';

export default function SummaryPanel() {
  return (
    <Box
      w="full"
      backgroundColor="white"
      borderRadius="md"
      boxShadow="sm"
      overflow="hidden"
      data-testid="view-site-summary-panel"
      px={4}
      py={6}
    >
      <ErrorBoundary>
        <WithSiteData />
      </ErrorBoundary>
    </Box>
  );
}

export function WithSiteData() {
  const siteHandler = useCurrentSiteData();
  const insightId = useParamInsightId();
  const { formatMessage } = useIntl();
  if (hasDataHandlerFeedBack(siteHandler)) {
    return <DataHandlerFeedback dataHandlersParam={siteHandler} />;
  }
  const enableMonitoringCommand = formatMessage({
    defaultMessage: 'ENABLE OR SCHEDULE MONITORING',
    id: 'vGob2S',
    description:
      'Command to enable monitoring from a site card on the Homepage',
  });
  if (!siteHandler.data!.active) {
    return (
      <Insight
        IconSVG={SleepIcon}
        summary={formatMessage({
          defaultMessage: 'Monitoring not active',
          id: 'HnOG4s',
          description: 'Summary panel label when monitoring is not active',
        })}
        paragraph={formatMessage({
          defaultMessage:
            'Data is not currently being collected and is showing the last recorded data at this site.',
          id: 'FlNPpQ',
        })}
        actionLabel={enableMonitoringCommand}
        actionLink={pathSitesEdit(insightId, siteHandler.data?.siteId)}
      />
    );
  }
  return (
    <Summary
      siteId={siteHandler.data!.siteId}
      siteUnit={siteHandler.data!.siteUnit}
      siteTimeZone={siteHandler.data!.siteTimeZone}
      insightId={insightId}
    />
  );
}

export function Summary({
  siteId,
  siteUnit,
  siteTimeZone,
  insightId,
}: {
  siteId: number;
  siteUnit: string;
  siteTimeZone: string;
  insightId: string;
}) {
  const { formatMessage, formatDate, formatTime } = useIntl();
  const siteViewState = useSiteView();
  if (!siteViewState) {
    throw Error('no site selected for the charts');
  }
  const { state } = siteViewState;
  const summaryDataHandler = useInsightSummaryData({
    insightId,
    siteId,
    timePeriod: state.period,
    siteUnit,
    hiddenRoutes: state.hiddenRoutes,
  });
  if (hasDataHandlerFeedBack(summaryDataHandler as DataHandlerParam)) {
    return (
      <DataHandlerFeedback
        dataHandlersParam={summaryDataHandler as DataHandlerParam}
      />
    );
  }
  if (!summaryDataHandler.anyRoutesSelected) {
    return (
      <Insight
        IconSVG={NoRouteData}
        summary={formatMessage({
          defaultMessage: 'No routes selected',
          id: 'CtRSGb',
          description: 'Summary panel label when no routes selected',
        })}
        paragraph={formatMessage({
          defaultMessage:
            'Select routes from the left hand column to display site performance',
          id: 'w2G0Og',
        })}
      />
    );
  }
  if (!summaryDataHandler.dataAvailable) {
    return (
      <LayoutEmpty
        image={<NoDataForDates width="298" />}
        title={
          <FormattedMessage
            defaultMessage="Try changing the date range"
            id="Im7Fa+"
            description="Empty chart title for insight charts"
          />
        }
        text={
          <FormattedMessage
            defaultMessage={`
          There’s no information available for the selected dates.
        `}
            id="5L6Xl6"
            description="Empty chart message for insight charts"
          />
        }
      />
    );
  }
  if (!summaryDataHandler.anyRoutesSelected) {
    return (
      <Flex w="full" alignItems="center" justifyContent="center">
        <Heading fontSize="sm">
          <FormattedMessage
            defaultMessage="No Routes Selected"
            id="ExNbDv"
            description="No Routes Selected - summary panel"
          />
        </Heading>
        <Text>
          <FormattedMessage
            defaultMessage="Select one or more routes on the left to view data"
            id="cDjQfX"
            description="Select one or more routes on the left to view data - summary panel"
          />
        </Text>
      </Flex>
    );
  }

  return (
    <Box w="full" overflowX="auto" mb={-4} pb={4}>
      <GridWrapper>
        {summaryDataHandler
          .data!.filter(
            (summaryModel) =>
              summaryModel.date ||
              summaryModel.summaryType === 'SUM_TRUCK_HOURS' ||
              summaryModel.summaryType === 'SUM_DWELL_HOURS' ||
              summaryModel.summaryType === 'SUM_TRIP_COUNTS'
          )
          .map((summaryModel) => {
            if (!summaryModel?.summaryType) {
              return null;
            }
            const { IconComponent } =
              performanceStatsOptions[summaryModel.summaryType];
            const title = formatMessage(
              performanceStatsOptions[summaryModel.summaryType].titleMessage
            );
            return (
              <Insight
                key={summaryModel.summaryType}
                IconSVG={IconComponent}
                label={title}
                summary={summaryModel.routeName}
                title={summaryModel.display}
                paragraph={
                  summaryModel.date
                    ? `${formatDate(summaryModel.date! * 1000, {
                        ...DATE_PRESET_NARROW_COMPLETE,
                        timeZone: siteTimeZone,
                      })}, ${formatTime(summaryModel.date! * 1000, {
                        ...TIME_PRESET_COMPLETE_HOUR12,
                        timeZone: siteTimeZone,
                      })}`
                    : ''
                }
              />
            );
          })}
      </GridWrapper>
    </Box>
  );
}
