import { Button, HStack } from '@chakra-ui/react';
import useIntlWeekDays from '../../hooks/useIntlWeekDays';

export interface WeekDayPickerProps {
  value: number[];
  onChange: (newValue: number[]) => void;
  isCompact?: boolean;
}

export default function WeekDaysPicker({
  value = [],
  onChange,
  isCompact = false,
}: WeekDayPickerProps) {
  const weekdays = useIntlWeekDays();
  return (
    <HStack spacing={2}>
      {weekdays.map((weekday) => {
        const isSelected = value.includes(weekday.value);
        return (
          <Button
            key={weekday.value}
            variant="solid"
            colorScheme="purple"
            fontWeight="semibold"
            size="xs"
            width={isCompact ? '26px' : '46px'}
            margin="-3px"
            height="26px"
            border="solid 3px white"
            borderRadius={isCompact ? 'full' : '9px'}
            borderColor={isSelected ? 'purple.300' : 'white'}
            backgroundColor={isSelected ? 'purple.700' : 'purple.100'}
            color={isSelected ? 'white' : 'purple.900'}
            _hover={{
              backgroundColor: isSelected ? 'purple.600' : 'purple.200',
              color: isSelected ? 'white' : 'purple.900',
            }}
            onClick={() => {
              const foundIndex = value.indexOf(weekday.value);
              const updatedDays =
                foundIndex > -1
                  ? value.filter(
                      (_, filterDayIndex) => filterDayIndex !== foundIndex
                    )
                  : value.concat(weekday.value);
              if (updatedDays.length > 0) {
                onChange(updatedDays);
              }
            }}
          >
            {isCompact ? weekday.label.substring(0, 1) : weekday.label}
          </Button>
        );
      })}
    </HStack>
  );
}
