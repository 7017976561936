import { envConfig } from '../../config/envConfig';
import { fetchWithError } from '../../utils/fetch';
import { CreateAccountParams, INVALID, OnboardStatus } from './constants';

const CREATE_ACCOUNT_URI = `${envConfig.API_URL}/api/v1/onboard/createAccount`;

export default async function createAccount(
  params: CreateAccountParams,
  token: string
) {
  const searchParams = new URLSearchParams();
  searchParams.set('token', token);
  const url = `${CREATE_ACCOUNT_URI}?${searchParams.toString()}`;
  const response = await fetchWithError<OnboardStatus>(url, {
    method: 'POST',
    body: JSON.stringify({ ...params }),
  });

  if (!response) {
    return INVALID;
  }

  return response;
}
