import { standardFetch } from '../utils/fetch';
import { envConfig } from '../config/envConfig';

export function postSchedule(
  insightId,
  siteId,
  siteTimeZone,
  scheduleName,
  startDate,
  endDate
) {
  return standardFetch(
    `${envConfig.API_URL}/api/v1/insights/${insightId}/sites/${siteId}/schedule`,
    {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({
        siteId,
        siteName: scheduleName,
        timezone: siteTimeZone,
        startDate,
        endDate,
      }),
    }
  );
}
export function deleteSchedule(insightId, siteId, scheduleId) {
  return standardFetch(
    `${envConfig.API_URL}/api/v1/insights/${insightId}/sites/${siteId}/schedule/${scheduleId}`,
    {
      method: 'DELETE',
      headers: { 'Content-Type': 'application/json' },
    }
  );
}
