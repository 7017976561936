import { Box, Heading, VStack } from '@chakra-ui/react';
import { RoutePollingStatusData } from '../../data/useRoutePollingStatusData';
import RouteMap from './RouteMap';
import { TextResolvedStatus } from './TextContent';

export default function ResolvedState({
  pollingStatus,
}: {
  pollingStatus: RoutePollingStatusData;
}) {
  return (
    <VStack alignItems="flex-start" spacing="20">
      <VStack alignItems="flex-start" spacing="4">
        <Heading size="2xl">{pollingStatus.route.name}</Heading>
        <RouteMap route={pollingStatus.route} />
        <Box fontSize="2xl">
          <TextResolvedStatus pollingStatus={pollingStatus} />
        </Box>
      </VStack>
    </VStack>
  );
}
