export default function DirectionsPin({
  label = 'A',
  className,
}: {
  label: string;
  className?: string;
}) {
  return (
    <svg className={className} viewBox="0 0 27 43">
      <path
        transform="translate(1 1)"
        fill="#EA4335"
        stroke="white"
        d="M12.5 0C5.5961 0 0 5.5961 0 12.5c0 1.8859.54297 3.7461 1.4414 5.4617 3.425 6.6156 10.216 13.566 10.216 22.195 0 .46562.37734.84297.84297.84297s.84297-.37734.84297-.84297c0-8.6289 6.7906-15.58 10.216-22.195.89844-1.7156 1.4414-3.5758 1.4414-5.4617 0-6.9039-5.5961-12.5-12.5-12.5z"
      />
      <text
        textAnchor="middle"
        dy=".3em"
        x="14"
        y="15"
        fontFamily="arial, sans-serif"
        fontSize="16px"
        fontWeight="bold"
        fill="white"
      >
        {label}
      </text>
    </svg>
  );
}
