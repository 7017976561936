import './LayoutSite.scss';
import { ReactNode, useState } from 'react';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet';
import classnames from 'classnames';
import SideBarHandleIcon from '../svg/settings-handle.svg?react';

interface LayoutSideBarProps {
  children: ReactNode;
  sideBarHeader?: ReactNode;
  sideBarContent: ReactNode;
  windowTitle?: string;
}
export default function LayoutSideBar({
  children,
  sideBarHeader,
  sideBarContent,
  windowTitle,
}: LayoutSideBarProps) {
  const [isSidebarOpen, setSidebarOpen] = useState(false);
  return (
    <div className="v2-layout-sites">
      {windowTitle && (
        <Helmet>
          <title>{windowTitle}</title>
        </Helmet>
      )}
      <div
        className={classnames('v2-layout-sites__side-bar', {
          'v2-layout-sites__side-bar--open': isSidebarOpen,
        })}
      >
        <button
          type="button"
          className="v2-layout-sites__handle"
          aria-label="Toggle sidebar"
          onClick={() => {
            setSidebarOpen(!isSidebarOpen);
          }}
        >
          <SideBarHandleIcon />
        </button>
        {sideBarHeader && (
          <div className="v2-layout-sites__side-bar-header">
            {sideBarHeader}
          </div>
        )}
        <div className="v2-layout-sites__side-bar-content">
          {sideBarContent}
        </div>
      </div>
      {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions */}
      <div
        className={classnames('v2-layout-sites__body', {
          'v2-layout-sites__body--has-side-bar': isSidebarOpen,
        })}
        onClick={() => {
          if (isSidebarOpen) {
            setSidebarOpen(false);
          }
        }}
      >
        {children}
      </div>
    </div>
  );
}
LayoutSideBar.propTypes = {
  children: PropTypes.node,
  sideBarHeader: PropTypes.node,
  sideBarContent: PropTypes.node,
  windowTitle: PropTypes.string,
};
