import { useEffect, useState } from 'react';
import { BlankRoute, RouteDirections } from '../../types/route';
import { formatCoordinates } from '../../utils/routes';
import DirectionsResult = google.maps.DirectionsResult;
import UnitSystem = google.maps.UnitSystem;

enum DirectionsRequestStatus {
  IDLE = 'IDLE',
  ACTIVE = 'ACTIVE',
  ERROR = 'ERROR',
}
interface DirectionsRequest {
  requestId?: number;
  parameters: RouteDirections;
  status: DirectionsRequestStatus;
  result: null | DirectionsResult;
}
interface DirectionsEditorProps {
  routeDirections: RouteDirections;
  routeLength: number;
  routeWkt: string;
  googleDirections: null | DirectionsResult;
  setRouteDirections: (input: RouteDirections) => void;
  setGoogleDirections: (googleDirections: DirectionsResult) => void;
  hasDirectionsError: boolean;
  isFetchingDirections: boolean;
}

export default function useDirectionsEditor(
  route: BlankRoute | null
): DirectionsEditorProps {
  const formatInitialRouteRequestState = (
    routeProps: RouteDirections | null
  ): DirectionsRequest => ({
    requestId: undefined,
    parameters: {
      startPoint: routeProps ? routeProps.startPoint : undefined,
      endPoint: routeProps ? routeProps.endPoint : undefined,
      waypoints: routeProps ? routeProps.waypoints : [],
    },
    status: DirectionsRequestStatus.IDLE,
    result: null,
  });
  const [directionsRequest, setDirectionsRequest] = useState<DirectionsRequest>(
    formatInitialRouteRequestState(route)
  );
  const requestDirections = (requestParameters: Required<RouteDirections>) => {
    const requestId = Date.now();
    setDirectionsRequest({
      requestId,
      parameters: requestParameters,
      status: DirectionsRequestStatus.ACTIVE,
      result: null,
    });
    getGoogleMapsDirections(requestParameters)
      .then((result) => {
        setDirectionsRequest((asyncDirectionsRequest) =>
          asyncDirectionsRequest.requestId === requestId
            ? formatGoogleDirectionsResponse(result)
            : asyncDirectionsRequest
        );
      })
      .catch(() => {
        setDirectionsRequest((asyncDirectionsRequest) =>
          asyncDirectionsRequest.requestId === requestId
            ? {
                ...asyncDirectionsRequest,
                status: DirectionsRequestStatus.ERROR,
              }
            : asyncDirectionsRequest
        );
      });
  };

  useEffect(() => {
    setDirectionsRequest(formatInitialRouteRequestState(route));
  }, [route]);

  useEffect(() => {
    if (
      directionsRequest.status === DirectionsRequestStatus.IDLE &&
      directionsRequest.parameters.startPoint &&
      directionsRequest.parameters.endPoint &&
      (!directionsRequest.result ||
        !isSameDirections(
          directionsRequest.parameters as Required<RouteDirections>,
          directionsRequest.result
        ))
    ) {
      requestDirections(
        directionsRequest.parameters as Required<RouteDirections>
      );
    }
  }, [directionsRequest]);

  return {
    routeDirections: directionsRequest.parameters,
    routeLength:
      directionsRequest.result &&
      directionsRequest.result.routes[0].legs[0].distance
        ? directionsRequest.result.routes[0].legs[0].distance.value
        : 0,
    routeWkt: directionsRequest.result
      ? formatWkt(directionsRequest.result)
      : '',
    setRouteDirections: (input: RouteDirections) => {
      setDirectionsRequest(formatInitialRouteRequestState(input));
    },
    googleDirections: directionsRequest.result,
    setGoogleDirections: (googleDirections: DirectionsResult) => {
      setDirectionsRequest(formatGoogleDirectionsResponse(googleDirections));
    },
    hasDirectionsError:
      directionsRequest.status === DirectionsRequestStatus.ERROR,
    isFetchingDirections:
      directionsRequest.status === DirectionsRequestStatus.ACTIVE,
  };
}

function formatWkt(result: DirectionsResult) {
  return `LINESTRING(${result.routes[0].overview_path
    .map((point) => `${point.lng()} ${point.lat()}`)
    .join(',')})`;
}

function formatGoogleDirectionsResponse(
  result: DirectionsResult
): DirectionsRequest {
  return {
    requestId: undefined,
    parameters: {
      startPoint: result.routes[0].legs[0].start_location.toJSON(),
      endPoint: result.routes[0].legs[0].end_location.toJSON(),
      waypoints: result.routes[0].legs[0].via_waypoints.map((waypoint) =>
        waypoint.toJSON()
      ),
    },
    status: DirectionsRequestStatus.IDLE,
    result,
  };
}

function isSameDirections(
  userDirections: Required<RouteDirections>,
  googleDirections: DirectionsResult
) {
  const leg = googleDirections.routes[0].legs[0];
  return !(
    formatCoordinates(leg.start_location.toJSON()) !==
      formatCoordinates(userDirections.startPoint) ||
    formatCoordinates(leg.end_location.toJSON()) !==
      formatCoordinates(userDirections.endPoint) ||
    leg.via_waypoints.length !== userDirections.waypoints.length ||
    JSON.stringify(
      leg.via_waypoints.map((waypoint) => formatCoordinates(waypoint.toJSON()))
    ) !==
      JSON.stringify(
        userDirections.waypoints.map((waypoint) => formatCoordinates(waypoint))
      )
  );
}

const directionsService = new google.maps.DirectionsService();
function getGoogleMapsDirections(requestParameters: Required<RouteDirections>) {
  return directionsService.route({
    origin: requestParameters.startPoint,
    destination: requestParameters.endPoint,
    waypoints: requestParameters.waypoints.map((waypoint) => ({
      location: formatCoordinates(waypoint),
      stopover: false,
    })),
    travelMode: google.maps.TravelMode.DRIVING,
    avoidFerries: true,
    unitSystem: UnitSystem.METRIC,
  });
}
