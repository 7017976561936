import moment from 'moment-timezone';
import { ReactNode, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import DateRangeSelectionButton from 'design-system/molecules/date-range-selection/date-range-selection-button';
import { useNavigate, useLocation } from 'react-router-dom';
import { Select, Text } from '@chakra-ui/react';
import { StringDateRange } from 'design-system/molecules/date-range-selection';
import { DATE_PRESET_SHORT_D_M_Y } from 'design-system/utilities/date-presets';
import {
  isPlanInsightType,
  pathSitesPlan,
  PlanInsightType,
} from '../../constants/path';
import useInsightData from '../../data/useInsightData';
import { useAnalytics } from '../../hooks/analytics/useAnalytics';
import useParamInsightId from '../../hooks/useParamInsightId';
import CheckBoxSvg, { CheckboxShape } from '../CheckBoxSvg';
import './PlanSettings.scss';
import { WeekDayPicker } from '../WeekDayPicker';
import { PlanSettingsParams, PredictionMode } from '../../types/plan';
import { NightDayPeriod } from '../../types/vehicleCountSource';
import { getLabelByInsightType } from '../../utils/planCharts';
import { MS_24_HOURS } from '../../constants/time';
import {
  FEATURE_DATE_RANGE_V2,
  FEATURE_PLAN_INSIGHT_TYPE_SELECTOR,
} from '../../constants/features';
import useFeatureSwitch, {
  FeatureSwitchState,
} from '../../hooks/useFeatureSwitch';

export interface PlanSettingsProps {
  timeZone: string;
  availableDateRange?: {
    from?: number;
    to?: number;
  };
  insightType: PlanInsightType;
  parameters: PlanSettingsParams;
  setParameters: (newParams: PlanSettingsParams) => void;
}
export default function PlanSettings({
  timeZone,
  availableDateRange,
  insightType,
  parameters,
  setParameters,
}: PlanSettingsProps) {
  const { formatMessage } = useIntl();
  const { track } = useAnalytics();
  const navigate = useNavigate();
  const insightId = useParamInsightId();
  const location = useLocation();
  const [insightSelectName] = useState(
    `insight-select${Date.now()}-${Math.random()}`
  );

  const featurePlanInsightTypeSelector = useFeatureSwitch(
    FEATURE_PLAN_INSIGHT_TYPE_SELECTOR
  );

  return (
    <div className="v2-plan-settings">
      <h3 className="v2-plan-settings__title">
        <FormattedMessage
          defaultMessage="Report settings"
          id="JXGsFC"
          description="plan controls - title"
        />
      </h3>
      {featurePlanInsightTypeSelector !== FeatureSwitchState.ON && (
        <label
          className="v2-plan-settings__form-row"
          htmlFor={insightSelectName}
          aria-label="Insight type selector"
        >
          <span className="v2-plan-settings__form-row-title">
            <FormattedMessage
              defaultMessage="Insight"
              id="+y8swY"
              description="plan controls - insight label"
            />
          </span>
          <span className="v2-plan-settings__form-row-content">
            <InsightSelect
              name={insightSelectName}
              value={insightType}
              onChange={(newInsightType) => {
                navigate(
                  {
                    pathname: pathSitesPlan(insightId, newInsightType),
                    search: location.search,
                  },
                  {
                    state: location.state,
                    replace: true,
                  }
                );
              }}
            />
          </span>
        </label>
      )}
      {/* disabling linter rule because label is already associated with control by hierarchy */}
      {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
      <label className="v2-plan-settings__form-row">
        <span className="v2-plan-settings__form-row-title">
          <FormattedMessage
            defaultMessage="Date range"
            id="33kCgH"
            description="plan controls - date range label"
          />
        </span>
        <span className="v2-plan-settings__form-row-content">
          <DateRangeField
            value={{
              startDate: dateMSToString(parameters.startDate, timeZone),
              endDate: dateMSToString(parameters.endDate, timeZone),
            }}
            onChange={({ startDate, endDate }) => {
              const startDateMS = dateStringToMS(startDate!, timeZone);
              const endDateMS = dateStringToMS(endDate!, timeZone, true);
              setParameters({
                ...parameters,
                startDate: startDateMS,
                endDate: endDateMS,
              });
              track('Plan Settings Changed', {
                referrer: 'Plan Tab Settings',
                control: 'Date range',
                startDate: startDateMS,
                endDate: endDateMS,
                range: (endDateMS - startDateMS) / MS_24_HOURS,
                value: `${startDate} - ${endDate}`,
              });
            }}
            infoText={
              <InfoTextForDateRange
                dateRange={availableDateRange}
                timeZone={timeZone}
              />
            }
            minDate={
              availableDateRange?.from
                ? dateMSToString(availableDateRange.from, timeZone)
                : undefined
            }
            maxDate={
              availableDateRange?.to
                ? dateMSToString(availableDateRange.to, timeZone)
                : undefined
            }
          />
        </span>
      </label>

      <div className="v2-plan-settings__form-row v2-plan-settings__form-row--full">
        <span className="v2-plan-settings__form-row-title v2-plan-settings__form-row-title--full">
          <FormattedMessage
            defaultMessage="Days of the week"
            id="PSb/At"
            description="plan controls - days of the week"
          />
        </span>
        <WeekDayPicker
          value={parameters.daysOfTheWeek}
          onChange={(value) => {
            setParameters({
              ...parameters,
              daysOfTheWeek: value,
            });
            track('Plan Settings Changed', {
              referrer: 'Plan Tab Settings',
              control: 'Weekdays',
              value,
            });
          }}
        />
      </div>
      {parameters.mode && (
        <div className="v2-plan-settings__form-row">
          <span className="v2-plan-settings__form-row-title">
            <FormattedMessage
              defaultMessage="Mode"
              id="Rmg721"
              description="plan controls - mode title"
            />
          </span>
          <div className="v2-plan-settings__form-row-content">
            <RadioGroup
              dataTestIdPrefix="plan-settings-mode"
              options={[
                {
                  value: PredictionMode.AVERAGE,
                  label: formatMessage({
                    defaultMessage: 'Typical',
                    id: 'PmNQXN',
                    description: 'plan controls - mode label for typical',
                  }),
                },
                {
                  value: PredictionMode.PESSIMISTIC,
                  label: formatMessage({
                    defaultMessage: 'Pessimistic',
                    id: 'Wdb9nL',
                    description: 'plan controls - mode label for pessimistic',
                  }),
                },
              ]}
              value={parameters.mode}
              onChange={(value) => {
                setParameters({ ...parameters, mode: value });
                track('Plan Settings Changed', {
                  referrer: 'Plan Tab Settings',
                  control: 'Mode',
                  value,
                });
              }}
            />
          </div>
        </div>
      )}
      <div className="v2-plan-settings__form-row">
        <span className="v2-plan-settings__form-row-title">
          <FormattedMessage
            defaultMessage="Period"
            id="BU5jQa"
            description="plan controls - period title"
          />
        </span>
        <div className="v2-plan-settings__form-row-content">
          <RadioGroup
            dataTestIdPrefix="plan-settings-period"
            options={[
              {
                value: NightDayPeriod.DAY,
                label: formatMessage({
                  defaultMessage: 'Day',
                  id: 'AAhNha',
                  description: 'plan controls - period label for day',
                }),
              },
              {
                value: NightDayPeriod.NIGHT,
                label: formatMessage({
                  defaultMessage: 'Night',
                  id: 'LNKs8d',
                  description: 'plan controls - period label for night',
                }),
              },
            ]}
            value={parameters.period}
            onChange={(value) => {
              setParameters({ ...parameters, period: value });
              track('Plan Settings Changed', {
                referrer: 'Plan Tab Settings',
                control: 'Period',
                value,
              });
            }}
          />
        </div>
      </div>
    </div>
  );
}

function RadioGroup<TValue extends string>({
  options,
  value,
  dataTestIdPrefix,
  onChange,
}: {
  options: Array<{
    value: TValue;
    label: string;
  }>;
  dataTestIdPrefix: string;
  value: TValue;
  onChange: (newValue: TValue) => void;
}) {
  const [groupName] = useState(`radio_group_${Math.random()}_${Date.now()}`);
  return (
    <div className="v2-plan-settings__radio-group">
      {options.map((option) => (
        <label
          className="v2-plan-settings__radio-option-container"
          key={option.value}
        >
          <input
            type="radio"
            name={groupName}
            className="v2-plan-settings__radio-input"
            value={option.value}
            data-testid={`${dataTestIdPrefix}-${option.value}`}
            checked={option.value === value}
            onChange={(e) => {
              onChange(e.currentTarget.value as TValue);
            }}
          />
          <span className="v2-plan-settings__radio-option-content">
            <CheckBoxSvg
              checkBoxShape={CheckboxShape.CIRCLE}
              checked={option.value === value}
              className="v2-plan-settings__radio-svg"
            />
            <span className="v2-plan-settings__radio-label">
              {option.label}
            </span>
          </span>
        </label>
      ))}
    </div>
  );
}

function InsightSelect({
  name,
  onChange,
  value,
}: {
  name: string;
  onChange: (insightType: PlanInsightType) => void;
  value: string;
}) {
  const { formatMessage } = useIntl();
  const insightId = useParamInsightId();
  const insightData = useInsightData(insightId);
  const hasSiteImpact = insightData.data?.sections?.some(
    (insightDataSection: any) =>
      insightDataSection?.panelType === 'map' &&
      insightDataSection?.configs?.source?.siteImpactDelay
  );
  return (
    <Select
      name={name}
      size="sm"
      value={value}
      onChange={(e) => {
        if (isPlanInsightType(e.currentTarget.value)) {
          onChange(e.currentTarget.value);
        }
      }}
    >
      {[
        PlanInsightType.DELAY,
        PlanInsightType.TIME,
        PlanInsightType.SPEED,
        ...(hasSiteImpact ? [PlanInsightType.IMPACT] : []),
        PlanInsightType.COUNT,
      ].map((insightType) => (
        <option value={insightType} key={insightType}>
          {getLabelByInsightType(insightType, formatMessage)}
        </option>
      ))}
    </Select>
  );
}

function dateMSToString(date: number | undefined, timeZone: string) {
  if (date === undefined) {
    return '';
  }
  return moment(date).tz(timeZone).format('YYYY-MM-DD');
}
function dateStringToMS(date: string, timeZone: string, endOfDay = false) {
  const dateMoment = moment.tz(date, timeZone);
  return endOfDay ? dateMoment.endOf('day').valueOf() : dateMoment.valueOf();
}

function InfoTextForDateRange({
  dateRange,
  timeZone,
}: {
  dateRange: PlanSettingsProps['availableDateRange'];
  timeZone: PlanSettingsProps['timeZone'];
}) {
  const { formatDate } = useIntl();
  if (!dateRange?.from && !dateRange?.to) {
    return undefined;
  }
  if (!dateRange?.to) {
    return (
      <InfoTextTemplate>
        <FormattedMessage
          defaultMessage="from {fromDate}"
          id="Eq+IzW"
          values={{
            fromDate: formatDate(dateRange.from, {
              timeZone,
              ...DATE_PRESET_SHORT_D_M_Y,
            }),
          }}
        />
      </InfoTextTemplate>
    );
  }
  const toDateFormatted =
    dateMSToString(dateRange.to, timeZone) ===
    dateMSToString(Date.now(), timeZone) ? (
      <FormattedMessage defaultMessage="today" id="kkpP2k" />
    ) : (
      formatDate(dateRange.to, {
        timeZone,
        ...DATE_PRESET_SHORT_D_M_Y,
      })
    );
  if (!dateRange.from) {
    return (
      <InfoTextTemplate>
        <FormattedMessage
          defaultMessage="to {toDate}"
          id="z2pN2p"
          values={{
            toDate: toDateFormatted,
          }}
        />
      </InfoTextTemplate>
    );
  }
  return (
    <InfoTextTemplate>
      <>
        {formatDate(dateRange.from, {
          timeZone,
          ...DATE_PRESET_SHORT_D_M_Y,
        })}
        {' - '}
        {toDateFormatted}
      </>
    </InfoTextTemplate>
  );
}
function InfoTextTemplate({ children }: { children: ReactNode }) {
  return (
    <Text fontSize="xs" m={0}>
      <Text as="span" fontWeight="bold" mr={2}>
        <FormattedMessage
          defaultMessage="Available data:"
          id="ir2zKA"
          description="date range picker, available data label"
        />
      </Text>
      {children}
    </Text>
  );
}

function DateRangeField({
  value,
  infoText,
  onChange,
  minDate,
  maxDate,
  maxDayRange,
}: {
  value: StringDateRange;
  onChange: (dates: StringDateRange) => void;
  infoText?: ReactNode;
  minDate?: string;
  maxDate?: string;
  maxDayRange?: number;
}) {
  const [isEditing, setIsEditing] = useState(false);
  const featureDateRangeV2 = useFeatureSwitch(FEATURE_DATE_RANGE_V2);
  return (
    <DateRangeSelectionButton
      useV2={featureDateRangeV2 === FeatureSwitchState.ON}
      isOpen={isEditing}
      startDate={value.startDate}
      onOpen={() => setIsEditing(true)}
      endDate={value.endDate}
      onConfirm={({ startDate, endDate }) => {
        onChange({ startDate, endDate });
        setIsEditing(false);
      }}
      onCancel={() => {
        setIsEditing(false);
      }}
      infoText={infoText}
      minDate={minDate}
      maxDate={maxDate}
      maxDayRange={maxDayRange}
    />
  );
}
