import { FormattedMessage } from 'react-intl';
import { Box, Heading, VStack } from '@chakra-ui/react';
import {
  RoutePollingStatusFailureType,
  RoutePollingStatusDataResponded,
} from '../../data/useRoutePollingStatusData';
import EditableQuote from './EditableQuote';
import OtherCauses from './OtherCauses';
import MoreDetailsForm from './MoreDetailsForm';
import {
  TextRecordedResponse,
  TextQuoteTitle,
  TextExpectedOutcome,
} from './TextContent';

export default function ResponseUpdatedState({
  pollingStatus,
  setNewResponse,
}: {
  pollingStatus: RoutePollingStatusDataResponded;
  setNewResponse: (newResponse: {
    responseType: RoutePollingStatusFailureType | 'OTHER';
    responseComment?: string;
  }) => void;
}) {
  const { responseType } = pollingStatus.failure.userResponse;
  const moreInfoRequired =
    responseType === 'ROAD_LAYOUT' ||
    (!pollingStatus.failure.userResponse.responseComment &&
      responseType === 'CLOSURE');
  return (
    <VStack alignItems="flex-start" spacing="20">
      <VStack alignItems="flex-start" spacing="4">
        <Heading size="2xl">
          <FormattedMessage
            id="i7qGdb"
            defaultMessage="Thanks for letting us know"
          />
        </Heading>
        <Box fontSize="2xl">
          <TextRecordedResponse pollingStatus={pollingStatus} />
        </Box>
      </VStack>
      <VStack alignItems="flex-start" spacing="4" width="full">
        {moreInfoRequired ? (
          <MoreDetailsForm
            currentResponse={pollingStatus.failure.userResponse}
            setNewResponse={setNewResponse}
          />
        ) : (
          <EditableQuote
            title={
              <FormattedMessage
                id="o5I1Od"
                defaultMessage="Thanks, we’ve recorded the added information you’ve provided. You can edit your comment or change the reasoning at any time"
              />
            }
            quoteTitle={
              <TextQuoteTitle
                pollingStatus={pollingStatus}
                responseAuthor="SELF"
              />
            }
            quoteContent={pollingStatus.failure.userResponse.responseComment}
            renderEditComponent={({ finishEditing }) => (
              <MoreDetailsForm
                currentResponse={pollingStatus.failure.userResponse}
                setNewResponse={setNewResponse}
                cancelEditing={finishEditing}
              />
            )}
          />
        )}
      </VStack>
      <TextExpectedOutcome
        pollingStatus={pollingStatus}
        responseAuthor="SELF"
      />
      <OtherCauses
        currentResponseType={responseType}
        setNewResponse={setNewResponse}
      />
    </VStack>
  );
}
