import { Box, Button, HStack } from '@chakra-ui/react';
import { FunctionComponent, ReactNode, useState } from 'react';
import ConfirmationModal from 'design-system/molecules/confirmation-modal';
import { FieldValues, UseFormReturn, useForm } from 'react-hook-form';

interface ModalFieldsetTemplateProps<TValues extends FieldValues> {
  title: ReactNode;
  labels: {
    title: ReactNode;
    value: ReactNode;
  }[];
  values: TValues;
  onChange: (newValue: TValues) => void;
  renderFieldset: FunctionComponent<{ formProps: UseFormReturn<TValues> }>;
  isCompact?: boolean;
}

export default function ModalFieldsetTemplate<TValues extends FieldValues>({
  title,
  labels,
  values,
  onChange,
  renderFieldset,
  isCompact = false,
}: ModalFieldsetTemplateProps<TValues>) {
  const [isEditing, setIsEditing] = useState(false);
  const formProps = useForm<TValues>(values);
  return (
    <>
      <Button
        onClick={() => {
          setIsEditing(true);
          formProps.reset({ ...values });
        }}
        height="auto"
        variant="ghost"
        _hover={{
          backgroundColor: 'gray.100',
        }}
        padding={isCompact ? '12px 8px' : '4px 8px'}
        margin={isCompact ? '-12px -8px' : '-4px -8px'}
        borderRadius="9px"
      >
        <HStack spacing={4}>
          {labels.map((label) => (
            <Box key={label.title?.toString()} textAlign="left">
              {!isCompact && (
                <Box
                  fontSize="x-small"
                  fontWeight="normal"
                  color="gray.500"
                  lineHeight="16px"
                >
                  {label.title}
                </Box>
              )}
              <Box fontSize="xs" fontWeight="semibold">
                {label.value}
              </Box>
            </Box>
          ))}
        </HStack>
      </Button>
      <ConfirmationModal
        onCancel={() => {
          setIsEditing(false);
        }}
        onConfirm={formProps.handleSubmit((newValues) => {
          onChange(newValues);
          setIsEditing(false);
        })}
        body={
          <Box paddingY={4}>
            <form onSubmit={formProps.handleSubmit(onChange)}>
              {renderFieldset({ formProps })}
            </form>
          </Box>
        }
        open={isEditing}
        title={title}
      />
    </>
  );
}
