export enum CompareSortValuesMode {
  LOWEST_FIRST,
  HIGHEST_FIRST,
}
export function compareSortValues(
  valueA: number | undefined,
  valueB: number | undefined,
  mode: CompareSortValuesMode
) {
  if (valueA !== undefined && valueB === undefined) {
    return -1;
  }
  if (valueA === undefined && valueB !== undefined) {
    return 1;
  }
  if (valueA === valueB) {
    return 0;
  }
  return mode === CompareSortValuesMode.LOWEST_FIRST
    ? valueA! - valueB!
    : valueB! - valueA!;
}
