import {
  Box,
  Button,
  Flex,
  Heading,
  HStack,
  Text,
  VStack,
} from '@chakra-ui/react';
import { ReactNode, MouseEventHandler } from 'react';
import cssStyles from './index.module.scss';

interface ActionProps {
  to?: string;
  onClick?: MouseEventHandler<HTMLElement>;
  label: ReactNode;
}
interface SplashMessageProps {
  size?: 'lg' | 'md' | 'sm';
  image?: ReactNode;
  title: ReactNode;
  subtitle?: ReactNode;
  bodyText?: ReactNode;
  primaryAction?: ActionProps;
  secondaryAction?: ActionProps;
}

const stylesConfig = {
  lg: {
    outerGridGap: '24px',
    innerGridGap: '16px',
    titleSize: '5xl',
    subtitleSize: '2xl',
    bodyTextSize: 'md',
    buttonSize: 'md',
  },
  md: {
    outerGridGap: '16px',
    innerGridGap: '8px',
    titleSize: '3xl',
    subtitleSize: 'md',
    bodyTextSize: 'sm',
    buttonSize: 'sm',
  },
  sm: {
    outerGridGap: '16px',
    innerGridGap: '8px',
    titleSize: '2xl',
    subtitleSize: 'sm',
    bodyTextSize: 'sm',
    buttonSize: 'sm',
  },
};

export default function SplashMessage({
  size = 'lg',
  image,
  title,
  subtitle,
  bodyText,
  primaryAction,
  secondaryAction,
}: SplashMessageProps) {
  const { [size]: sizeStyles } = stylesConfig;
  const hasText = !!(title || subtitle || bodyText);
  const hasButtons = !!(primaryAction || secondaryAction);
  return (
    <Flex height="100%" alignItems="center" justifyContent="center">
      <Flex
        direction="column"
        alignItems="center"
        justifyContent="center"
        height="100%"
        width="100%"
        maxWidth="600px"
        gap={sizeStyles.outerGridGap}
        padding={sizeStyles.outerGridGap}
      >
        {image && (
          <Box flex="0" className={cssStyles.imageContainer}>
            {image}
          </Box>
        )}
        <Flex
          flex="0 1 33%"
          direction="column"
          alignItems="center"
          gap={sizeStyles.outerGridGap}
        >
          {hasText && (
            <VStack
              alignItems="center"
              textAlign="center"
              spacing={sizeStyles.innerGridGap}
            >
              {title && (
                <Heading
                  fontSize={sizeStyles.titleSize}
                  lineHeight="1"
                  letterSpacing="-2%"
                  mb={0}
                >
                  {title}
                </Heading>
              )}
              {subtitle && (
                <Text
                  color="gray.700"
                  fontSize={sizeStyles.subtitleSize}
                  as="div"
                >
                  {subtitle}
                </Text>
              )}
              {bodyText && (
                <Text
                  color="gray.500"
                  fontSize={sizeStyles.bodyTextSize}
                  as="div"
                >
                  {bodyText}
                </Text>
              )}
            </VStack>
          )}
          {hasButtons && (
            <HStack spacing={sizeStyles.innerGridGap}>
              {primaryAction && (
                <Button
                  size={sizeStyles.buttonSize}
                  variant="solid"
                  colorScheme="greenDark"
                  href={primaryAction.to}
                  as={primaryAction.to ? 'a' : undefined}
                  onClick={primaryAction.onClick}
                >
                  {primaryAction.label}
                </Button>
              )}
              {secondaryAction && (
                <Button
                  size={sizeStyles.buttonSize}
                  variant="outline"
                  href={secondaryAction.to}
                  as={secondaryAction.to ? 'a' : undefined}
                  onClick={secondaryAction.onClick}
                >
                  {secondaryAction.label}
                </Button>
              )}
            </HStack>
          )}
        </Flex>
      </Flex>
    </Flex>
  );
}
