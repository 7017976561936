import {
  Alert,
  AlertDescription,
  AlertIcon,
  AlertTitle,
  Box,
  Flex,
  FormControl,
  FormLabel,
  Input,
  SimpleGrid,
} from '@chakra-ui/react';
import moment from 'moment';
import {
  ChangeEventHandler,
  Dispatch,
  ReactNode,
  SetStateAction,
  useState,
} from 'react';
import { FormattedMessage, IntlShape, useIntl } from 'react-intl';
import ConfirmationModal from 'design-system/molecules/confirmation-modal';

export interface StringDateRange {
  startDate: string;
  endDate: string;
}

interface InfoMessage {
  title: string;
  description: string;
}

export interface DateRangeSelectionProps {
  value: StringDateRange;
  onConfirm: (value: StringDateRange) => void;
  onCancel: () => void;
  isOpen: boolean;
  infoText?: ReactNode;
  hideLabels?: boolean;
  minDate?: string;
  maxDate?: string;
  maxDayRange?: number;
  title?: string;
}

export default function DateRangeSelection({
  value,
  onConfirm,
  onCancel,
  isOpen,
  infoText,
  minDate,
  maxDate,
  maxDayRange,
  hideLabels,
  title,
}: DateRangeSelectionProps) {
  const { formatMessage } = useIntl();
  const [datesState, setDatesState] = useState(value);
  const [validationMessage, setValidationMessage] = useState<string>();
  const [infoMessage, setInfoMessage] = useState<InfoMessage>();
  const clearMessages = () => {
    if (validationMessage) {
      setValidationMessage(undefined);
    }
    if (infoMessage) {
      setInfoMessage(undefined);
    }
  };
  const confirmClickHandler = () => {
    if (!datesState.startDate || !datesState.endDate) {
      setValidationMessage(
        formatMessage({
          defaultMessage: 'Please, set start and end dates.',
          id: '6ui8bf',
          description:
            'Time period selector: Validation message for empty dates',
        })
      );
      return;
    }
    const newValidationMessage = getValidationMessageForCustomDates(
      datesState.startDate,
      datesState.endDate,
      formatMessage
    );
    if (newValidationMessage) {
      setValidationMessage(newValidationMessage);
    } else {
      const isStartBeforeMin = minDate
        ? moment(datesState.startDate).isBefore(minDate, 'day')
        : false;
      const isEndAfterMax = maxDate
        ? moment(datesState.endDate).isAfter(maxDate, 'day')
        : false;
      if (!isStartBeforeMin && !isEndAfterMax) {
        onConfirm(datesState);
      } else {
        setDatesState({
          startDate: isStartBeforeMin ? minDate! : datesState.startDate,
          endDate: isEndAfterMax ? maxDate! : datesState.endDate,
        });
        setInfoMessage({
          title: formatMessage({
            defaultMessage: 'Date updated:',
            id: 'arr7e2',
          }),
          description: formatMessage({
            defaultMessage: `Dates must be within the period that data is available`,
            id: 'p11unB',
          }),
        });
      }
    }
  };
  return (
    isOpen && (
      <ConfirmationModal
        body={
          <Flex direction="column">
            {infoText && <Box pb={2}>{infoText}</Box>}
            <SimpleGrid columns={2} spacing={2}>
              <FormControl>
                {!hideLabels && (
                  <FormLabel fontSize="xs">
                    <FormattedMessage defaultMessage="From" id="dM+p3/" />
                  </FormLabel>
                )}
                <Input
                  data-testid="date-range-selection-from-date"
                  type="date"
                  value={datesState.startDate}
                  min={minDate}
                  max={maxDate}
                  onChange={handleStartDateChange(
                    datesState,
                    setDatesState,
                    clearMessages,
                    setInfoMessage,
                    formatMessage,
                    maxDayRange
                  )}
                />
              </FormControl>
              <FormControl>
                {!hideLabels && (
                  <FormLabel fontSize="xs">
                    <FormattedMessage defaultMessage="To" id="9j3hXO" />
                  </FormLabel>
                )}
                <Input
                  data-testid="date-range-selection-to-date"
                  type="date"
                  value={datesState.endDate}
                  min={minDate}
                  max={maxDate}
                  onChange={handleEndDateChange(
                    datesState,
                    setDatesState,
                    clearMessages,
                    setInfoMessage,
                    formatMessage,
                    maxDayRange
                  )}
                />
              </FormControl>
            </SimpleGrid>
            {validationMessage && (
              <Box color="red.500" fontSize="sm" mt={2}>
                {validationMessage}
              </Box>
            )}
            {infoMessage && (
              <Alert status="info" rounded="base" marginTop={4}>
                <AlertIcon />
                <Box>
                  <AlertTitle>{infoMessage.title}</AlertTitle>
                  <AlertDescription>{infoMessage.description}</AlertDescription>
                </Box>
              </Alert>
            )}
          </Flex>
        }
        confirmButtonText={formatMessage({
          description:
            'View time selection - custom date popup confirm button label',
          defaultMessage: 'Set dates',
          id: 'Ln270B',
        })}
        onCancel={onCancel}
        onConfirm={confirmClickHandler}
        open={isOpen}
        title={
          title ??
          formatMessage({
            description: 'View time selection - custom date popup title',
            defaultMessage: 'Select a date range',
            id: '8MARgK',
          })
        }
      />
    )
  );
}

function getValidationMessageForCustomDates(
  startDate: string,
  endDate: string,
  formatMessage: IntlShape['formatMessage']
): string | undefined {
  const startMoment = moment(startDate);
  const endMoment = moment(endDate);
  if (!startMoment.isValid()) {
    return formatMessage({
      defaultMessage: 'Start date not valid.',
      id: 'I2fw8V',
      description:
        'Time period selector: Validation message for invalid start date',
    });
  }
  if (!endMoment.isValid()) {
    return formatMessage({
      defaultMessage: 'End date not valid.',
      id: 'rXvoP3',
      description:
        'Time period selector: Validation message for invalid end date',
    });
  }
  if (endMoment.isBefore(startMoment)) {
    return formatMessage({
      defaultMessage: 'End date cannot be before start date.',
      id: '1/s6ud',
      description:
        'Time period selector: Validation message for start date after end date.',
    });
  }
  return undefined;
}

function handleStartDateChange(
  datesState: StringDateRange,
  setDatesState: Dispatch<SetStateAction<StringDateRange>>,
  clearValidation: () => void,
  setInfoMessage: (infoMessage: InfoMessage) => void,
  formatMessage: IntlShape['formatMessage'],
  maxDayRange?: number
): ChangeEventHandler<HTMLInputElement> {
  return (event) => {
    clearValidation();
    setDatesState((previousState) => ({
      ...previousState,
      startDate: event.target.value,
    }));
    const newStartMoment = moment(event.target.value);
    const endMoment = moment(datesState.endDate);
    const diffDays = endMoment.diff(newStartMoment, 'days');
    if (maxDayRange && diffDays > maxDayRange) {
      const newEndMomemt = newStartMoment.clone().add(maxDayRange, 'days');
      setDatesState((previousState) => ({
        ...previousState,
        endDate: newEndMomemt.format('YYYY-MM-DD'),
      }));
      setInfoMessage({
        title: formatMessage({
          defaultMessage: 'End date updated:',
          id: '1iVa+H',
        }),
        description: formatMessage(
          {
            defaultMessage:
              'Date ranges must be no longer than {dateRange} days',
            id: 'p/OJPn',
          },
          {
            dateRange: maxDayRange,
          }
        ),
      });
    }
  };
}

function handleEndDateChange(
  datesState: StringDateRange,
  setDatesState: Dispatch<SetStateAction<StringDateRange>>,
  clearValidation: () => void,
  setInfoMessage: (infoMessage: InfoMessage) => void,
  formatMessage: IntlShape['formatMessage'],
  maxDayRange?: number
): ChangeEventHandler<HTMLInputElement> {
  return (event) => {
    clearValidation();
    setDatesState((previousState) => ({
      ...previousState,
      endDate: event.target.value,
    }));
    const newEndMoment = moment(event.target.value);
    const startMoment = moment(datesState.startDate);
    const diffDays = newEndMoment.diff(startMoment, 'days');
    if (maxDayRange && diffDays > maxDayRange) {
      const newStartMoment = newEndMoment.clone().subtract(maxDayRange, 'days');
      setDatesState((previousState) => ({
        ...previousState,
        startDate: newStartMoment.format('YYYY-MM-DD'),
      }));
      setInfoMessage({
        title: formatMessage({
          defaultMessage: 'Start date updated:',
          id: 'KT+f1V',
        }),
        description: formatMessage(
          {
            defaultMessage: `Date ranges must be no longer than {dateRange} days`,
            id: 'p/OJPn',
          },
          { dateRange: maxDayRange }
        ),
      });
    }
  };
}
