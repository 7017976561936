import { ChevronDownIcon } from '@chakra-ui/icons';
import {
  Menu,
  MenuButton,
  Button,
  MenuList,
  MenuItem,
  MenuProps,
  MenuGroup,
  MenuDivider,
} from '@chakra-ui/react';
import { ReactNode } from 'react';
import { FormattedMessage } from 'react-intl';
import { Link, To } from 'react-router-dom';

export interface ContextMenuItemConfig {
  key: string;
  label: ReactNode;
  action?: To | (() => unknown);
}

export interface ContextMenuGroupItemConfig {
  key: string;
  label: string;
  members: ContextMenuItemConfig[];
}

function isGroupItem(object: any): object is ContextMenuGroupItemConfig {
  return 'members' in object;
}

export interface ContextMenuProps {
  items: ContextMenuItemConfig[] | ContextMenuGroupItemConfig[];
  menuProps?: Partial<MenuProps>;
  triggerLabel?: ReactNode;
  size?: 'xs' | 'sm' | 'md';
  actions?: ReactNode;
}

export default function ContextMenu({
  items,
  menuProps,
  triggerLabel,
  size = 'sm',
  actions,
}: ContextMenuProps) {
  const showMenuItem = (item: ContextMenuItemConfig) => {
    const { key, label, action } = item;
    if (action === undefined) {
      return (
        <MenuItem key={key} fontSize={size} isDisabled>
          {label}
        </MenuItem>
      );
    }
    if (typeof action === 'function') {
      return (
        <MenuItem key={key} fontSize={size} onClick={action}>
          {label}
        </MenuItem>
      );
    }
    return (
      <MenuItem key={key} as={Link} fontSize={size} to={action}>
        {label}
      </MenuItem>
    );
  };
  return (
    <Menu isLazy strategy="fixed" {...(menuProps ?? {})}>
      <MenuButton
        as={Button}
        display="flex"
        size={size}
        variant="outline"
        rightIcon={<ChevronDownIcon />}
      >
        {triggerLabel ?? (
          <FormattedMessage defaultMessage="Options" id="NDV5Mq" />
        )}
      </MenuButton>
      <MenuList>
        {items.map((item) => {
          if (isGroupItem(item)) {
            const { key, label, members } = item;
            return (
              <>
                <MenuDivider />
                <MenuGroup key={key} title={label}>
                  {members.map((member) => showMenuItem(member))}
                </MenuGroup>
              </>
            );
          }
          return showMenuItem(item);
        })}
        {actions}
      </MenuList>
    </Menu>
  );
}
