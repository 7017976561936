/* eslint-disable react/destructuring-assignment */
import { Box, Heading, Link, VStack } from '@chakra-ui/react';
import { ReactNode } from 'react';
import SplashMessage from 'design-system/molecules/splash-message';
import { FormattedMessage, defineMessage, useIntl } from 'react-intl';
import PlanChartV2, { PlanChartProps } from './PlanChartV2';
import { getLabelByInsightType } from '../../utils/planCharts';
import useIntlWeekDays from '../../hooks/useIntlWeekDays';
import CalendarSvg from '../../svg/no-data-for-dates.svg?react';
import TapeRecorderSvg from '../../svg/tape-recorder.svg?react';
import TippedConeSvg from '../../svg/tipped-cone.svg?react';
import {
  DataHandlerFeedback,
  hasDataHandlerFeedBack,
} from '../../utils/dataHandler';
import LayoutPanel from '../LayoutPanel';
import { PlanInsightType } from '../../constants/path';
import usePlanningTimeData from '../../data/usePlanningTimeData';
import useFeatureSwitch, {
  FeatureSwitchState,
} from '../../hooks/useFeatureSwitch';
import { FEATURE_PLAN_INSIGHT_TYPE_SELECTOR } from '../../constants/features';

export interface DailyProfileChartsProps
  extends Omit<PlanChartProps, 'planData' | 'error' | 'yAxisMax' | 'title'> {
  resetDates?: () => void;
  hasSiteImpact: boolean;
}

const chartTitleMessage = defineMessage({
  defaultMessage: 'Daily profile - {profiledDayName}',
  id: '7YJeWv',
});

export default function DailyProfileCharts(
  props: DailyProfileChartsProps
): JSX.Element {
  const { formatMessage } = useIntl();
  const weekdaysShort = useIntlWeekDays();
  const weekdaysLong = useIntlWeekDays('long');
  const sortedSelectedDays = [
    ...(props.planDataParams.daysOfTheWeek ?? []),
  ].sort();
  const hasMultipleDaysSelected =
    (props.planDataParams.daysOfTheWeek?.length ?? 0) > 1;
  const planningTimeDataHandler = usePlanningTimeData(props.planDataParams);
  const insightTypeLabel = getLabelByInsightType(
    props.planDataParams.insightType,
    formatMessage
  ).toLowerCase();
  const featurePlanInsightTypeSelector = useFeatureSwitch(
    FEATURE_PLAN_INSIGHT_TYPE_SELECTOR
  );
  const primaryTitle =
    featurePlanInsightTypeSelector === FeatureSwitchState.ON
      ? undefined
      : formatMessage(
          {
            defaultMessage:
              '{hasMultipleDays, select, true {Aggregated} other {Individual}} daily {insightTypeLabel} profile',
            description:
              'Planning page title. It describes the main chart on the page, which could be for an individual day, or aggregated data for several days.',
            id: 'mhupGW',
          },
          {
            insightTypeLabel,
            hasMultipleDays: hasMultipleDaysSelected ? 'true' : 'false',
          }
        );

  if (
    props.planDataParams &&
    props.planDataParams.startDate &&
    props.planDataParams.endDate &&
    props.planDataParams.startDate > props.planDataParams.endDate
  ) {
    return (
      <InvalidDatesMessage resetDates={props.resetDates} title={primaryTitle} />
    );
  }
  if (
    hasDataHandlerFeedBack(planningTimeDataHandler) ||
    !planningTimeDataHandler.data
  ) {
    return (
      <UserFeedbackLayout title={primaryTitle}>
        <DataHandlerFeedback dataHandlersParam={planningTimeDataHandler} />
      </UserFeedbackLayout>
    );
  }

  if (
    props.planDataParams.insightType === PlanInsightType.IMPACT &&
    !props.hasSiteImpact
  ) {
    return <SiteImpactNotSetUpForSite title={primaryTitle} />;
  }
  const { yAxisMax, hasCollectedData, hasVisibleData, hasVisibleRoutes } =
    planningTimeDataHandler.data.reduce(
      (acc, dayData) => {
        const {
          dayYAxisMax,
          dayHasCollectedData,
          dayHasVisibleData,
          dayHasVisibleRoutes,
        } = dayData.reduce(
          (accDay, routeData) => {
            const isRouteHidden = checkRouteHidden(
              routeData,
              props.hiddenRoutes
            );
            const { routeHasData, routeYAxixMax } = isRouteHidden
              ? {
                  routeHasData: routeData.items.some((item) => item !== null),
                  routeYAxixMax: 0,
                }
              : routeData.items.reduce(
                  (accRoute, itemData) => {
                    const itemHasData = itemData.value !== null;
                    return {
                      routeHasData: accRoute.routeHasData || itemHasData,
                      routeYAxixMax: Math.max(
                        accRoute.routeYAxixMax,
                        itemData.value ?? 0
                      ),
                    };
                  },
                  {
                    routeHasData: false,
                    routeYAxixMax: 0,
                  }
                );
            return {
              dayYAxisMax: Math.max(accDay.dayYAxisMax, routeYAxixMax),
              dayHasCollectedData: accDay.dayHasCollectedData || routeHasData,
              dayHasVisibleData:
                accDay.dayHasVisibleData || (!isRouteHidden && routeHasData),
              dayHasVisibleRoutes: accDay.dayHasVisibleRoutes || !isRouteHidden,
            };
          },
          {
            dayYAxisMax: 0,
            dayHasCollectedData: false,
            dayHasVisibleData: false,
            dayHasVisibleRoutes: false,
          }
        );
        return {
          yAxisMax: Math.max(acc.yAxisMax ?? 0, dayYAxisMax),
          hasCollectedData: acc.hasCollectedData || dayHasCollectedData,
          hasVisibleData: acc.hasVisibleData || dayHasVisibleData,
          hasVisibleRoutes: acc.hasVisibleRoutes || dayHasVisibleRoutes,
        };
      },
      {
        yAxisMax: getInitialYAxisMax(props),
        hasCollectedData: false,
        hasVisibleData: false,
        hasVisibleRoutes: false,
      }
    );

  if (!hasCollectedData) {
    if (props.resetDates) {
      return (
        <EmptyDatesMessage resetDates={props.resetDates} title={primaryTitle} />
      );
    }
    return <NoRecordingMessage title={primaryTitle} />;
  }
  if (!hasVisibleData) {
    if (hasVisibleRoutes) {
      return <NoRecordingMessage title={primaryTitle} />;
    }
    return <NoVisibleDataMessage title={primaryTitle} />;
  }
  return (
    <VStack align="stretch" spacing={4}>
      <Heading fontSize="lg">{primaryTitle}</Heading>
      {hasMultipleDaysSelected && (
        <>
          <Box
            backgroundColor="white"
            borderRadius="md"
            boxShadow="sm"
            overflow="hidden"
          >
            <PlanChartV2
              planData={planningTimeDataHandler.data[0]}
              title={formatMessage(chartTitleMessage, {
                profiledDayName: formatMessage({
                  defaultMessage: 'aggregated view',
                  id: 'ma4o99',
                  description:
                    'chart title detail for plan chart, aggregated view',
                }),
              })}
              chartConfig={props.chartConfig}
              planDataParams={props.planDataParams}
              constraints={props.constraints}
              hiddenRoutes={props.hiddenRoutes}
              routesColors={props.routesColors}
              daysOfWeek={props.planDataParams.daysOfTheWeek?.map((dayValue) =>
                getWeekdayLabel(dayValue, weekdaysLong)
              )}
              yAxisMax={yAxisMax}
            />
          </Box>
          <Heading fontSize="lg">
            <FormattedMessage
              defaultMessage="Individual daily {insightTypeLabel} profiles"
              id="9C+kxJ"
              values={{ insightTypeLabel }}
            />
          </Heading>
        </>
      )}
      {planningTimeDataHandler.data.slice(1).map((dayData, dayDataIndex) => {
        const dayValue = sortedSelectedDays[dayDataIndex];
        return (
          <Box
            key={dayValue}
            backgroundColor="white"
            borderRadius="md"
            boxShadow="sm"
            overflow="hidden"
            data-testid={`plan-daily-chart-${dayValue}`}
          >
            <PlanChartV2
              planData={dayData}
              chartConfig={props.chartConfig}
              title={formatMessage(chartTitleMessage, {
                profiledDayName: getWeekdayLabel(dayValue, weekdaysLong),
              })}
              planDataParams={{
                ...props.planDataParams,
                daysOfTheWeek: [dayValue],
              }}
              constraints={props.constraints}
              hiddenRoutes={props.hiddenRoutes}
              routesColors={props.routesColors}
              yAxisMax={yAxisMax}
              midnightLabel={
                props.planDataParams.isNightShift
                  ? getWeekdayLabel((dayValue + 1) % 7, weekdaysShort)
                  : undefined
              }
            />
          </Box>
        );
      })}
    </VStack>
  );
}

function UserFeedbackLayout({
  title,
  children,
}: {
  title?: string;
  children: ReactNode;
}) {
  return (
    <VStack align="stretch" spacing={4}>
      {title && <Heading fontSize="lg">{title}</Heading>}
      <LayoutPanel height="500px">{children}</LayoutPanel>
    </VStack>
  );
}

function EmptyDatesMessage({
  title,
  resetDates,
}: {
  title?: string;
  resetDates?: () => void;
}) {
  const { formatMessage } = useIntl();
  return (
    <UserFeedbackLayout title={title}>
      <SplashMessage
        size="sm"
        image={<CalendarSvg width="366px" />}
        title={formatMessage({
          id: '+v7Mni',
          defaultMessage: 'There is no recorded data for the selected dates',
        })}
        subtitle={formatMessage({
          id: 'epB2KP',
          defaultMessage: 'Update the date selection to view available data',
        })}
        secondaryAction={
          resetDates && {
            label: formatMessage({
              id: 'g3OywX',
              defaultMessage: 'view latest available',
            }),
            onClick: resetDates,
          }
        }
      />
    </UserFeedbackLayout>
  );
}

function InvalidDatesMessage({
  title,
  resetDates,
}: {
  title?: string;
  resetDates?: () => void;
}) {
  const { formatMessage } = useIntl();
  return (
    <UserFeedbackLayout title={title}>
      <SplashMessage
        size="sm"
        image={<CalendarSvg width="366px" />}
        title={formatMessage({
          id: 'hzku/O',
          defaultMessage: 'The date range selected is invalid',
        })}
        subtitle={formatMessage({
          id: 'epB2KP',
          defaultMessage: 'Update the date selection to view available data',
        })}
        secondaryAction={
          resetDates && {
            label: formatMessage({
              id: 'g3OywX',
              defaultMessage: 'view latest available',
            }),
            onClick: resetDates,
          }
        }
      />
    </UserFeedbackLayout>
  );
}

function NoRecordingMessage({ title }: { title?: string }) {
  const { formatMessage } = useIntl();
  return (
    <UserFeedbackLayout title={title}>
      <SplashMessage
        size="sm"
        image={<TapeRecorderSvg width="280px" />}
        title={
          <Box maxW="366px">
            {formatMessage({
              defaultMessage:
                'There is no recorded data available for the selected routes',
              id: 'xCdDJV',
            })}
          </Box>
        }
        subtitle={formatMessage({
          defaultMessage:
            'Try updating either your route selection or selected date range',
          id: 'IiZUSR',
        })}
      />
    </UserFeedbackLayout>
  );
}

function NoVisibleDataMessage({ title }: { title?: string }) {
  const { formatMessage } = useIntl();
  return (
    <UserFeedbackLayout title={title}>
      <SplashMessage
        size="sm"
        image={<TippedConeSvg width="280px" />}
        title={formatMessage({
          id: 'qZECxR',
          defaultMessage: 'No routes selected',
        })}
        subtitle={formatMessage({
          id: 'zi2sCd',
          defaultMessage: 'Try selecting some routes to view data',
        })}
      />
    </UserFeedbackLayout>
  );
}

function SiteImpactNotSetUpForSite({ title }: { title?: string }) {
  const { formatMessage } = useIntl();
  return (
    <UserFeedbackLayout title={title}>
      <SplashMessage
        size="sm"
        image={<TapeRecorderSvg width="280px" />}
        title={
          <Box maxW="366px">
            {formatMessage({
              defaultMessage: 'Site impact has yet to be set up for this site',
              id: '80BLFW',
            })}
          </Box>
        }
        subtitle={formatMessage(
          {
            defaultMessage:
              'To learn more about Site impact {siteImpactClickHere}.{lineBreak}Or {contactUs} for more information.',
            id: '2gpuTH',
          },
          {
            lineBreak: <br />,
            siteImpactClickHere: (
              <Link
                href="https://help.mooven.com/en/articles/3637213-understanding-our-metrics"
                isExternal
              >
                <FormattedMessage defaultMessage="click here" id="zc+1Gk" />
              </Link>
            ),
            contactUs: (
              <Link href="mailto:support@mooven.com">
                <FormattedMessage defaultMessage="contact us" id="cZ6IMP" />
              </Link>
            ),
          }
        )}
      />
    </UserFeedbackLayout>
  );
}

function getWeekdayLabel(
  dayOfWeek: number,
  weekdays: ReturnType<typeof useIntlWeekDays>
): string {
  const dayConfig = weekdays.at(dayOfWeek);
  if (dayConfig === undefined) {
    throw new Error(`Invalid day of week: ${dayOfWeek}`);
  }
  return dayConfig.label;
}

function checkRouteHidden(route, hiddenRoutes) {
  return hiddenRoutes.indexOf(parseInt(route.name.replace('_', ''), 10)) > -1;
}

function getInitialYAxisMax(
  props: DailyProfileChartsProps
): number | undefined {
  const kpiRealValue =
    props.chartConfig.kpiDisplayWriteRatio * (props.constraints.kpi ?? 0);
  return props.constraints.showConstraints && kpiRealValue
    ? kpiRealValue * 1.05
    : 60;
}
