import {
  Button,
  HStack,
  Select,
  Stack,
  Text,
  Textarea,
} from '@chakra-ui/react';
import { FormattedMessage } from 'react-intl';
import { UseFormReturn } from 'react-hook-form';
import { MessageTypeFormType } from './messageTypes';

export function StepBoardMessageTypeAdminTab({
  formManager,
  routes,
}: {
  routes: { routeId: number; name: string }[];
  formManager: UseFormReturn<MessageTypeFormType>;
}) {
  const { watch, setValue } = formManager;
  const selectedRoutes = watch('advancedMonitoredRoutes');
  const advancedContent = watch('advancedContent');
  const toNumber = (val) => (val ? Number(val) : null);
  return (
    <Stack
      mt={4}
      bgColor="gray.50"
      borderRadius="16px"
      p={4}
      spacing={6}
      height="full"
    >
      <Text fontSize="md" fontWeight="bold">
        <FormattedMessage
          defaultMessage="Specify routes and template to show on the board"
          id="dm3/wo"
        />
      </Text>
      <HStack justifyContent="flex-end">
        <Button
          onClick={() =>
            setValue('advancedMonitoredRoutes', selectedRoutes.concat([null]))
          }
          size="xs"
          colorScheme="greenDark"
        >
          <FormattedMessage defaultMessage="Add variable" id="jKGzI/" />
        </Button>
      </HStack>
      {selectedRoutes.map((route, i) => (
        // eslint-disable-next-line react/no-array-index-key
        <HStack key={i}>
          <Text>r.[{i}]</Text>
          <Select
            placeholder="Select route"
            unselectable="on"
            bgColor="white"
            value={route ?? undefined}
            onChange={(e) =>
              setValue(
                'advancedMonitoredRoutes',
                selectedRoutes.map((prev, j) =>
                  i === j ? toNumber(e.target.value) : prev
                )
              )
            }
          >
            {routes
              .filter(
                (r) =>
                  !selectedRoutes.includes(r.routeId) || r.routeId === route
              )
              .map((r) => (
                <option key={r.routeId} value={r.routeId}>
                  {r.name}
                </option>
              ))}
          </Select>
        </HStack>
      ))}
      <Textarea
        bgColor="white"
        value={advancedContent}
        onChange={(e) => setValue('advancedContent', e.target.value)}
        noOfLines={10}
        height="full"
      />
    </Stack>
  );
}
