import { Text } from '@chakra-ui/react';

import { IntlShape, useIntl } from 'react-intl';

export interface AlertsProps {
  siteImpact?: boolean;
  delay?: boolean;
  speed?: boolean;
  queue?: boolean;
  annotations?: boolean;
}

export default function Alerts({ alerts = {} }: { alerts: AlertsProps }) {
  const { formatMessage } = useIntl();
  return (
    <Text
      color="gray.600"
      fontSize="xs"
      fontWeight={400}
      marginLeft={10}
      my={0}
    >
      <Text as="span" fontWeight={600}>
        {formatMessage({
          id: '4NKhmo',
          description: 'Permissions alerts',
          defaultMessage: 'Alerts:',
        })}
      </Text>{' '}
      {getAlertText(alerts, formatMessage)}
    </Text>
  );
}

function getAlertText(
  alerts: AlertsProps,
  formatMessage: IntlShape['formatMessage']
) {
  const enabledAlerts: string[] = [];
  if (alerts.siteImpact) {
    enabledAlerts.push(
      formatMessage({
        id: 'UU+NkQ',
        description: 'Permissions site impact',
        defaultMessage: 'Site Impact',
      })
    );
  }
  if (alerts.delay) {
    enabledAlerts.push(
      formatMessage({
        id: 'V58tnT',
        description: 'Permissions delay',
        defaultMessage: 'Delay',
      })
    );
  }
  if (alerts.speed) {
    enabledAlerts.push(
      formatMessage({
        id: 'JcIYUA',
        description: 'Permissions speed',
        defaultMessage: 'Speed',
      })
    );
  }
  if (alerts.queue) {
    enabledAlerts.push(
      formatMessage({
        id: 'bA3CbP',
        description: 'Permissions queue',
        defaultMessage: 'Queue',
      })
    );
  }
  if (alerts.annotations) {
    enabledAlerts.push(
      formatMessage({
        id: 'pz6c7m',
        description: 'Permissions annotation',
        defaultMessage: 'Annotations',
      })
    );
  }
  if (enabledAlerts.length === 0) {
    enabledAlerts.push('None');
    return enabledAlerts;
  }
  return enabledAlerts.join(' + ');
}
