import { ChangeEvent, useState } from 'react';
import AutoCompleteField, { AutoCompleteFieldProps } from './AutoCompleteField';
import AutocompleteService = google.maps.places.AutocompleteService;
import PlacesService = google.maps.places.PlacesService;
import Circle = google.maps.Circle;
import PlaceResult = google.maps.places.PlaceResult;
import LatLng = google.maps.LatLng;
import LatLngLiteral = google.maps.LatLngLiteral;

const autoCompleteService = new AutocompleteService();
const placesService = new PlacesService(document.createElement('div'));

type PlacesSearchFieldProps = Omit<
  AutoCompleteFieldProps,
  | 'value'
  | 'onChange'
  | 'onFocus'
  | 'onBlur'
  | 'onOptionSelected'
  | 'getOptions'
> & {
  placesTypes?: string[];
  placesBias?: {
    location?: LatLng | LatLngLiteral;
    radius?: number;
  };
  placeFields?: string[];
  onPlaceSelected?: AutoCompleteFieldProps['onOptionSelected'];
  onPlaceDetailsReceived?: (result: PlaceResult | null) => void;
  value?: string;
  onChange?: (e: ChangeEvent<HTMLInputElement>) => void;
  onBlur?: () => void;
};

export default function PlacesSearchField({
  fieldProps,
  placesTypes,
  placesBias,
  placeFields = ['ALL'],
  onPlaceSelected,
  onPlaceDetailsReceived,
  value,
  onChange,
  onBlur,
}: PlacesSearchFieldProps) {
  const [inputValue, setInputValue] = useState('');
  const valueChangeHandler = (e) => {
    setInputValue(e.currentTarget.value);
  };

  return (
    <AutoCompleteField
      fieldProps={fieldProps}
      value={value || inputValue}
      onChange={onChange || valueChangeHandler}
      onBlur={onBlur}
      onOptionSelected={(location) => {
        if (!onChange) {
          setInputValue(location.label);
        }
        if (onPlaceSelected) {
          onPlaceSelected(location);
        }
        if (onPlaceDetailsReceived) {
          placesService.getDetails(
            {
              placeId: location.value,
              fields: placeFields,
            },
            (response) => {
              if (response && response.geometry && response.geometry.location) {
                onPlaceDetailsReceived(response);
              }
            }
          );
        }
      }}
      getOptions={(newValue) =>
        autoCompleteService
          .getPlacePredictions({
            input: newValue,
            types: placesTypes,
            locationBias:
              placesBias &&
              new Circle({
                center: placesBias.location,
                radius: placesBias.radius,
              }),
            componentRestrictions: {
              country: ['au', 'ca', 'gb', 'nz', 'us'],
            },
          })
          .then((response) =>
            response.predictions.map((location) => ({
              value: location.place_id,
              label: location.description,
            }))
          )
      }
    />
  );
}
