import { ReactNode } from 'react';
import { Box, VStack } from '@chakra-ui/react';
import LayoutSite, { LayoutSiteProps } from '../LayoutSite';
import PlanSettings, { PlanSettingsProps } from '../PlanSettings';
import { FEATURE_PLAN_INSIGHT_TYPE_SELECTOR } from '../../constants/features';
import useFeatureSwitch, {
  FeatureSwitchState,
} from '../../hooks/useFeatureSwitch';
import PlanControls from '../PlanControls';
import { PlanControlsManager } from '../PlanControls/types';
import { useWatchStickyTop } from '../../hooks/useWatchStickyTop';

interface LayoutPlanProps extends LayoutSiteProps {
  planControlsManager: PlanControlsManager;
  planSettingsParams: PlanSettingsProps['parameters'];
  setPlanSettingsParams: PlanSettingsProps['setParameters'];
  siteTimeZone: PlanSettingsProps['timeZone'];
  availableDateRange: PlanSettingsProps['availableDateRange'];
  mapContent: ReactNode;
}
export function LayoutPlan({
  planControlsManager,
  windowTitle,
  sideBarContent,
  planSettingsParams,
  setPlanSettingsParams,
  siteTimeZone,
  availableDateRange,
  mapContent,
  children,
}: LayoutPlanProps) {
  const featurePlanInsightTypeSelector = useFeatureSwitch(
    FEATURE_PLAN_INSIGHT_TYPE_SELECTOR
  );
  const isFeaturePlanInsightTypeSelector =
    featurePlanInsightTypeSelector === FeatureSwitchState.ON;
  const { isStuck, stuckHeight, refCallback } = useWatchStickyTop();
  return (
    <LayoutSite
      windowTitle={windowTitle}
      sideBarContent={
        <>
          {!isFeaturePlanInsightTypeSelector && (
            <PlanSettings
              timeZone={siteTimeZone}
              availableDateRange={availableDateRange}
              insightType={planControlsManager.insightType}
              parameters={planSettingsParams}
              setParameters={setPlanSettingsParams}
            />
          )}
          {sideBarContent}
        </>
      }
      mapContent={mapContent}
    >
      {isFeaturePlanInsightTypeSelector ? (
        <VStack py={8} px={4} alignItems="stretch" spacing={{ base: 4, lg: 8 }}>
          <Box
            height={stuckHeight}
            position="sticky"
            top={{ base: '131px', md: '141px', lg: '-34px' }}
            paddingTop={isStuck ? '50px' : 0}
            zIndex="5"
            ref={refCallback}
          >
            <Box overflowX="auto" m="-16px" p="16px">
              <Box width="max-content" minWidth="100%">
                <PlanControls
                  planControlsManager={planControlsManager}
                  isCompact={isStuck}
                />
              </Box>
            </Box>
          </Box>
          <Box>{children}</Box>
        </VStack>
      ) : (
        <Box mt={4} mx="4" pb="12">
          {children}
        </Box>
      )}
    </LayoutSite>
  );
}
