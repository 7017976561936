import { Avatar, Box, Flex, VStack } from '@chakra-ui/react';
import { ReactElement, ReactNode } from 'react';

interface LabelProps {
  avatar?: ReactElement<typeof Avatar>;
  primaryText?: ReactNode;
  secondaryText?: ReactNode;
  footerText?: ReactNode;
}
export default function Label({
  avatar,
  primaryText,
  secondaryText,
  footerText,
}: LabelProps) {
  const hasContent = primaryText || secondaryText || footerText;
  return (
    <Flex
      width="fit-content"
      maxWidth="100%"
      p={1}
      borderRadius="2xl"
      backgroundColor="gray.50"
      justify="center"
      align="flex-start"
    >
      {avatar && <Box>{avatar}</Box>}
      {hasContent && (
        <VStack px={3} py={1} spacing={1} overflow="hidden" alignItems="left">
          <Flex
            maxWidth="100%"
            flexWrap="wrap"
            align="baseline"
            columnGap={1}
            rowGap={1}
            fontSize="xs"
            color="gray.700"
          >
            {primaryText && (
              <Box fontWeight={600} maxWidth="100%">
                {primaryText}
              </Box>
            )}
            {secondaryText && (
              <Box overflow="hidden" maxWidth="100%">
                {secondaryText}
              </Box>
            )}
          </Flex>
          {footerText && (
            <Box fontSize="xs" color="gray.500" maxWidth="100%">
              {footerText}
            </Box>
          )}
        </VStack>
      )}
    </Flex>
  );
}
