import { useEffect, useMemo } from 'react';
import {
  Button,
  Heading,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Text,
} from '@chakra-ui/react';
import { FormattedMessage, useIntl } from 'react-intl';
import { ArrowBackIcon } from '@chakra-ui/icons';
import { useNavigate, useParams } from 'react-router-dom';
import type { Role, TeamUser } from '@webapp/bff';
import SpinnerSuspense from 'design-system/atoms/spinner-suspense';
import TeamMembersSettings from './TeamMembersSettings';
import { pathTeamSettings, TeamSectionFragment } from '../../constants/path';
import useTeamData from '../../data/useTeamData';
import LayoutPage from '../LayoutPage';
import useCurrentUserData from '../../data/useCurrentUserData';
import { useFailureToast } from '../../hooks/useFailureToast';
import { useSuccessToast } from '../../hooks/useSuccessToast';
import { errorReport } from '../../utils/errors';
import TeamGuestsSettings from './TeamGuestsSettings';
import { useAnalytics } from '../../hooks/analytics/useAnalytics';
import {
  DataHandlerFeedback,
  hasDataHandlerFeedBack,
} from '../../utils/dataHandler';

function TeamSettings() {
  const navigate = useNavigate();
  const { insightId: orgId, sectionId } = useParams<{
    insightId: string;
    sectionId: TeamSectionFragment;
  }>();

  const teamDataHandler = useTeamData(orgId);
  const {
    data: currentTeamData,
    updateTeamRole,
    inviteTeamMembers,
    removeFromTeam,
    onResendInvite,
    onSendPasswordReset,
  } = teamDataHandler;
  const currentUserDataHandler = useCurrentUserData();
  const failureToast = useFailureToast();
  const successToast = useSuccessToast();
  const { formatMessage } = useIntl();

  const analytics = useAnalytics();
  useEffect(() => {
    if (currentUserDataHandler.data?.userId !== undefined) {
      analytics.track('Team settings viewed', {
        referrer: 'Team settings',
        userId: currentUserDataHandler.data?.userId,
        orgId,
      });
    }
  }, [analytics, orgId, currentUserDataHandler.data?.userId]);

  if (!orgId) {
    throw new Error('Org id is mandatory');
  }

  const members = (currentTeamData?.users || []).filter(
    (u) => u.teamRole !== 'None'
  );
  const guests = (currentTeamData?.users || []).filter(
    (u) => u.teamRole === 'None'
  );

  const actions = useMemo(
    () => ({
      onRemoveUser: async (user: TeamUser) => {
        if (!user.externalId) {
          errorReport.critical('Attempt to remove user without external id', {
            user: user.email,
          });
          return;
        }
        try {
          await removeFromTeam({ userId: user.externalId });
          successToast({
            title: formatMessage({
              defaultMessage: 'User removed successfully.',
              id: 'Ab5Fjw',
              description: 'User removed successfully.',
            }),
          });
        } catch (e) {
          errorReport.handled(e, {
            page: 'Team Settings',
            action: 'removeUser',
          });
          failureToast({
            title: formatMessage({
              defaultMessage:
                'Something went wrong. Please try again or contact us.',
              id: '7DD0Qq',
              description: 'User removed: Something went wrong',
            }),
          });
        }
      },
      onResendInvite: async (user: TeamUser) => {
        try {
          await onResendInvite(user);
          successToast({
            title: formatMessage({
              defaultMessage: 'Invite resent',
              id: 'IWK+9W',
              description: 'Invite resent',
            }),
          });
        } catch (e) {
          errorReport.handled(e, {
            page: 'Team Settings',
            action: 'resendInvite',
          });
          failureToast({
            title: formatMessage({
              defaultMessage: 'Failed to resend invite',
              id: 'Z6t6VF',
              description: 'Failed to resend invite',
            }),
          });
        }
      },
      onSendPasswordReset: async (user: TeamUser) => {
        try {
          await onSendPasswordReset(user);
          successToast({
            title: formatMessage({
              defaultMessage: 'Password reset email sent',
              id: '3Jlqgv',
              description: 'Password reset email sent',
            }),
          });
        } catch (e) {
          errorReport.handled(e, {
            page: 'Team Settings',
            action: 'passwordReset',
          });
          failureToast({
            title: formatMessage({
              defaultMessage: 'Unable to send password reset email',
              id: 'k10lv+',
              description: 'Unable to send password reset email',
            }),
          });
        }
      },
      updateTeamRole: async (input: {
        user: TeamUser;
        orgId: string;
        newRoleValue: Role;
      }) => {
        try {
          await updateTeamRole({
            userId: input.user.externalId,
            orgId: input.orgId,
            role: input.newRoleValue,
          });
          successToast({
            title: formatMessage({
              defaultMessage: 'User role updated successfully',
              id: 'dGk4M0',
              description: 'Update team role: successful update',
            }),
          });
        } catch (e) {
          failureToast({
            title: formatMessage({
              defaultMessage:
                'Something went wrong. Please try again or contact us.',
              id: 'gTcIso',
              description: 'Update team role: Something went wrong',
            }),
          });
        }
      },
    }),
    [
      successToast,
      failureToast,
      onResendInvite,
      onSendPasswordReset,
      removeFromTeam,
      updateTeamRole,
      formatMessage,
    ]
  );

  if (hasDataHandlerFeedBack([teamDataHandler, currentUserDataHandler])) {
    return (
      <DataHandlerFeedback
        dataHandlersParam={[teamDataHandler, currentUserDataHandler]}
      />
    );
  }
  if (
    !currentTeamData ||
    !currentUserDataHandler.data ||
    currentUserDataHandler.data.userId === undefined
  ) {
    return null;
  }

  return (
    <>
      <Heading size="lg" my={2} as="h2">
        {currentTeamData?.orgName}
      </Heading>
      <Heading size="md" mb={2} as="h1" fontWeight="normal">
        <FormattedMessage
          defaultMessage="Team settings"
          id="QizexE"
          description="Team settings page subtitle"
        />
      </Heading>
      <Text fontSize="xs" my={0}>
        <FormattedMessage
          defaultMessage="Manage who has access to your organisation's sites."
          id="9RKbCY"
          description="Team settings info block line one"
        />
      </Text>
      <Text fontSize="xs" mb={8}>
        <FormattedMessage
          defaultMessage="Team members can access to all sites while guests are invited to individual sites."
          id="o/q56Z"
          description="Team settings info block line two"
        />
      </Text>
      <Tabs
        index={sectionId === TeamSectionFragment.GUESTS ? 1 : 0}
        onChange={(index) => {
          navigate(
            pathTeamSettings(
              orgId,
              index === 0
                ? TeamSectionFragment.MEMBERS
                : TeamSectionFragment.GUESTS
            ),
            {
              replace: true,
            }
          );
        }}
        colorScheme="green"
        width="full"
      >
        <TabList>
          <Tab fontSize="sm" fontWeight="600">
            <FormattedMessage
              defaultMessage="Team Members"
              id="0TdCqm"
              description="Team settings tabs - team members tab label"
            />
          </Tab>
          <Tab fontSize="sm" fontWeight="600">
            <FormattedMessage
              defaultMessage="Guests"
              id="56DKGM"
              description="Team settings tabs - guests tab label"
            />
          </Tab>
        </TabList>
        <TabPanels>
          <TabPanel paddingX={0} paddingY={10}>
            <TeamMembersSettings
              currentUserId={currentUserDataHandler.data.userId}
              orgId={orgId}
              users={members}
              updateTeamRole={actions.updateTeamRole}
              sendPasswordReset={actions.onSendPasswordReset}
              removeTeamMember={actions.onRemoveUser}
              inviteTeamMembers={inviteTeamMembers}
              resendTeamInvite={actions.onResendInvite}
            />
          </TabPanel>
          <TabPanel paddingX={0} paddingY={10}>
            <TeamGuestsSettings
              orgSitesCount={currentTeamData.sites.length}
              users={guests}
              sendPasswordReset={actions.onSendPasswordReset}
              removeTeamMember={actions.onRemoveUser}
            />
          </TabPanel>
        </TabPanels>
      </Tabs>
    </>
  );
}

export default () => {
  const navigate = useNavigate();
  return (
    <LayoutPage maxWidth="992px">
      <Button
        mt="48px"
        size="xs"
        variant="outline"
        onClick={() => navigate(-1)}
        leftIcon={<ArrowBackIcon />}
      >
        <FormattedMessage
          defaultMessage="Back"
          id="1Ft7uj"
          description="Team settings page Back button"
        />
      </Button>{' '}
      <SpinnerSuspense>
        <TeamSettings />
      </SpinnerSuspense>
    </LayoutPage>
  );
};
