import { useEffect, useState } from 'react';
import { Box, Tag, TagLabel, keyframes } from '@chakra-ui/react';
import { isValidLatLngString, parseCoordinates } from '../../utils/routes';
import { getPlacesForLocation } from '../../utils/maps-api';

interface PlaceRequestState {
  id: string;
  status: 'idle' | 'active';
  name: string | null;
}

const pulsatingOpacityKeyframes = keyframes(
  '0% {opacity: 1} 50% {opacity: 0.8} 100% {opacity: 1}'
);
const pulsatingOpacityAnimation = `${pulsatingOpacityKeyframes} 1s ease-out infinite`;

export default function RoadNameForPoint({
  pointString,
}: {
  pointString?: string;
}) {
  const point = isValidLatLngString(pointString)
    ? parseCoordinates(pointString)
    : null;
  const { roadName, roadNameRequestStatus } = useRoadNameForPoint(point);

  return (
    <Tag
      size="sm"
      variant="solid"
      colorScheme="purple"
      backgroundColor={
        !roadName && roadNameRequestStatus === 'idle' ? 'gray.200' : undefined
      }
      width="100%"
      animation={
        roadNameRequestStatus === 'active'
          ? pulsatingOpacityAnimation
          : undefined
      }
    >
      <TagLabel>
        <Box whiteSpace="nowrap" overflow="hidden" textOverflow="ellipsis">
          {roadName}
        </Box>
      </TagLabel>
    </Tag>
  );
}

function useRoadNameForPoint(point: google.maps.LatLngLiteral | null) {
  const [currentRequest, setCurrentRequest] = useState<PlaceRequestState>({
    id: '',
    status: 'idle',
    name: null,
  });
  useEffect(() => {
    const pointId = point ? `${point.lat},${point.lng}` : '';
    if (pointId !== currentRequest.id) {
      if (!point) {
        setCurrentRequest({
          id: '',
          status: 'idle',
          name: null,
        });
      } else {
        setCurrentRequest({
          id: pointId,
          status: 'active',
          name: null,
        });
        getPlacesForLocation(point)
          .then((response) => {
            setCurrentRequest((asyncState) => {
              if (asyncState.id !== pointId) {
                return asyncState;
              }
              const bestPlaceMatch = response.sort(
                sortPlacesByMatchPreferences
              )[0];
              const placeAddressRouteComponent =
                bestPlaceMatch.address_components.find(
                  (addressComponent) =>
                    addressComponent.types.indexOf('route') !== -1
                );
              return {
                id: pointId,
                status: 'idle',
                name: placeAddressRouteComponent?.short_name ?? null,
              };
            });
          })
          .catch(() => {
            setCurrentRequest((asyncState) => {
              if (asyncState.id !== pointId) {
                return asyncState;
              }
              return {
                id: pointId,
                status: 'idle',
                name: null,
              };
            });
          });
      }
    }
  }, [point, currentRequest.id]);

  return {
    roadNameRequestStatus: currentRequest.status,
    roadName: currentRequest.name,
  };
}

function sortPlacesByMatchPreferences(placeA, placeB) {
  // Prioritising GEOMETRIC_CENTER seems to deliver the most accurate result in terms of
  // proximity. E.g. when picking a point near a crossing.
  // Prioritising places of street_address type seems to deliver the most human readable
  // title for the street name. E.g. Khyber Pass Rd instead of Rte 12.
  //
  // This algorithm will put places in the following order:
  // 1. street_address of any location type GEOMETRIC_CENTER
  // 2. route of any location type GEOMETRIC_CENTER
  // 3. street_address of any location type
  // 4. route, of any location type
  // 5. original order from google

  const isPlaceAExact = placeA.geometry.location_type === 'GEOMETRIC_CENTER';
  const isPlaceBExact = placeB.geometry.location_type === 'GEOMETRIC_CENTER';
  if (isPlaceAExact && !isPlaceBExact) {
    return -1;
  }
  if (isPlaceBExact && !isPlaceAExact) {
    return 1;
  }

  const isPlaceAStreet = placeA.types.indexOf('street_address') !== -1;
  const isPlaceBStreet = placeB.types.indexOf('street_address') !== -1;
  if (isPlaceAStreet && !isPlaceBStreet) {
    return -1;
  }
  if (isPlaceBStreet && !isPlaceAStreet) {
    return 1;
  }

  const isPlaceARoute = placeA.types.indexOf('route') !== -1;
  const isPlaceBRoute = placeB.types.indexOf('route') !== -1;
  if (isPlaceARoute && !isPlaceBRoute) {
    return -1;
  }
  if (isPlaceBRoute && !isPlaceARoute) {
    return 1;
  }

  return 0;
}
