import {
  Alert,
  AlertDescription,
  AlertIcon,
  AlertTitle,
  Box,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
  SimpleGrid,
} from '@chakra-ui/react';
import moment from 'moment';
import { ReactNode, useState } from 'react';
import { UseFormReturn, Validate } from 'react-hook-form';
import {
  FormattedMessage,
  IntlFormatters,
  defineMessage,
  useIntl,
} from 'react-intl';

interface InfoMessage {
  title: string;
  description: string;
}

interface DateFieldValues {
  startDate: string;
  endDate: string;
}

interface DateRangeFieldsetProps {
  formProps: UseFormReturn<DateFieldValues>;
  infoText?: ReactNode;
  minDate?: string;
  maxDate?: string;
  maxDayRange?: number;
  hideLabels?: boolean;
}

export default function DateRangeFieldset({
  formProps: {
    register,
    getValues,
    setValue,
    formState: { errors },
  },
  infoText,
  minDate,
  maxDate,
  maxDayRange,
  hideLabels,
}: DateRangeFieldsetProps) {
  const { formatMessage } = useIntl();
  const [infoMessage, setInfoMessage] = useState<InfoMessage>();
  const validateDateField = (value: string) => {
    if (!value) {
      return formatMessage({
        defaultMessage: 'Please, set a date.',
        id: '5MzZi+',
      });
    }
    const momentDate = moment(value);
    if (!momentDate.isValid()) {
      return formatMessage({
        defaultMessage: 'Date not valid.',
        id: 'RGdS4+',
      });
    }
    const isBeforeMin = minDate ? momentDate.isBefore(minDate, 'day') : false;
    const isAfterMax = maxDate ? momentDate.isAfter(maxDate, 'day') : false;
    if (isBeforeMin || isAfterMax) {
      return formatMessage({
        defaultMessage:
          'Dates must be within the period that data is available.',
        id: 'c8sHvV',
      });
    }
    return true;
  };
  const validateEndDateField: Validate<string, DateFieldValues> = (
    value,
    allValues
  ) => {
    const basicValidation = validateDateField(value);
    if (typeof basicValidation === 'string') {
      return basicValidation;
    }
    const startMoment = moment(allValues.startDate);
    const endMoment = moment(value);
    if (endMoment.isBefore(startMoment)) {
      return formatMessage({
        defaultMessage: 'End date cannot be before start date.',
        id: '4JX3wJ',
      });
    }
    return true;
  };
  const getDateChangeHandler =
    (currentFieldName: keyof DateFieldValues) => () => {
      const otherFieldName =
        currentFieldName === 'startDate' ? 'endDate' : 'startDate';
      const currentFieldMoment = moment(getValues(currentFieldName));
      const otherFieldMoment = moment(getValues(otherFieldName));

      const currentFieldAdjustedMoment = adjustEditedDateWithinRange({
        dateMoment: currentFieldMoment,
        minDate,
        maxDate,
      });
      const shouldUpdateCurrentField = !currentFieldMoment.isSame(
        currentFieldAdjustedMoment
      );

      const otherFieldAdjustedDiffMoment = adjustOtherDateDiff({
        dateMoment: currentFieldAdjustedMoment,
        otherDateMoment: otherFieldMoment,
        currentFieldName,
        maxDayRange,
      });
      const otherFieldAdjustedMoment = adjustEditedDateWithinRange({
        dateMoment: otherFieldAdjustedDiffMoment,
        minDate,
        maxDate,
      });
      const shouldUpdateOtherField = !otherFieldMoment.isSame(
        otherFieldAdjustedMoment
      );

      const messageForChanges = getMessageForDatesUpdate({
        shouldUpdateCurrentField,
        shouldUpdateOtherField,
        maxDayRange,
        currentFieldName,
        otherFieldName,
        formatMessage,
      });

      if (shouldUpdateCurrentField) {
        setValue(
          currentFieldName,
          currentFieldAdjustedMoment.format('YYYY-MM-DD')
        );
      }
      if (shouldUpdateOtherField) {
        setValue(otherFieldName, otherFieldAdjustedMoment.format('YYYY-MM-DD'));
      }
      setInfoMessage(messageForChanges);
    };
  return (
    <Flex direction="column">
      {infoText && <Box pb={2}>{infoText}</Box>}
      <SimpleGrid columns={2} spacing={2}>
        <FormControl isInvalid={!!errors.startDate}>
          {!hideLabels && (
            <FormLabel fontSize="xs">
              <FormattedMessage defaultMessage="From" id="dM+p3/" />
            </FormLabel>
          )}
          <Input
            data-testid="date-range-selection-from-date"
            type="date"
            min={minDate}
            max={maxDate}
            {...register('startDate', {
              validate: validateDateField,
              onBlur: getDateChangeHandler('startDate'),
            })}
          />

          {errors.startDate?.message && (
            <FormErrorMessage>{errors.startDate.message}</FormErrorMessage>
          )}
        </FormControl>
        <FormControl isInvalid={!!errors.endDate}>
          {!hideLabels && (
            <FormLabel fontSize="xs">
              <FormattedMessage defaultMessage="To" id="9j3hXO" />
            </FormLabel>
          )}
          <Input
            data-testid="date-range-selection-to-date"
            type="date"
            min={minDate}
            max={maxDate}
            {...register('endDate', {
              validate: validateEndDateField,
              deps: ['startDate'],
              onBlur: getDateChangeHandler('endDate'),
            })}
          />
          {errors.endDate && (
            <FormErrorMessage>{errors.endDate.message}</FormErrorMessage>
          )}
        </FormControl>
      </SimpleGrid>
      {infoMessage && (
        <Alert status="info" rounded="base" marginTop={4}>
          <AlertIcon />
          <Box>
            <AlertTitle>{infoMessage.title}</AlertTitle>
            <AlertDescription>{infoMessage.description}</AlertDescription>
          </Box>
        </Alert>
      )}
    </Flex>
  );
}

const infoMessageTitle = defineMessage({
  defaultMessage:
    '{fieldName, select, startDate {Start date} endDate {End date} other {Dates} } updated:',
  id: 'GWCD2i',
});

function adjustEditedDateWithinRange({
  dateMoment,
  minDate,
  maxDate,
}: {
  dateMoment: ReturnType<typeof moment>;
  minDate?: string;
  maxDate?: string;
}) {
  if (dateMoment.isAfter(maxDate, 'day')) {
    return moment(maxDate);
  }
  if (dateMoment.isBefore(minDate, 'day')) {
    return moment(minDate);
  }
  return dateMoment;
}

function adjustOtherDateDiff({
  dateMoment,
  otherDateMoment,
  currentFieldName,
  maxDayRange,
}: {
  dateMoment: ReturnType<typeof moment>;
  otherDateMoment: ReturnType<typeof moment>;
  currentFieldName: keyof DateFieldValues;
  maxDayRange?: number;
}) {
  const isDiffTooBig =
    maxDayRange !== undefined &&
    Math.abs(dateMoment.diff(otherDateMoment, 'days')) > maxDayRange;
  if (isDiffTooBig) {
    if (currentFieldName === 'startDate') {
      return dateMoment.clone().add(maxDayRange, 'days');
    }
    return dateMoment.subtract().add(maxDayRange, 'days');
  }
  return otherDateMoment;
}

function getMessageForDatesUpdate({
  shouldUpdateCurrentField,
  shouldUpdateOtherField,
  maxDayRange,
  currentFieldName,
  otherFieldName,
  formatMessage,
}: {
  shouldUpdateCurrentField: boolean;
  shouldUpdateOtherField: boolean;
  maxDayRange?: number;
  currentFieldName: keyof DateFieldValues;
  otherFieldName: keyof DateFieldValues;
  formatMessage: IntlFormatters['formatMessage'];
}) {
  if (shouldUpdateCurrentField) {
    return {
      title: formatMessage(infoMessageTitle, {
        fieldName: shouldUpdateOtherField ? 'bothFields' : currentFieldName,
      }),
      description: shouldUpdateOtherField
        ? formatMessage(
            {
              defaultMessage: `Date range must be no longer than {dateRange} days and within the period that data is available`,
              id: 'HpaE8m',
            },
            {
              dateRange: maxDayRange,
            }
          )
        : formatMessage({
            defaultMessage: `Dates must be within the period that data is available`,
            id: 'p11unB',
          }),
    };
  }
  if (shouldUpdateOtherField) {
    return {
      title: formatMessage(infoMessageTitle, { fieldName: otherFieldName }),
      description: formatMessage(
        {
          defaultMessage: `Date range must be no longer than {dateRange} days`,
          id: '7j9Ise',
        },
        {
          dateRange: maxDayRange,
        }
      ),
    };
  }
  return undefined;
}
