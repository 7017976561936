import {
  Box,
  Button,
  Heading,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  Text,
  VStack,
} from '@chakra-ui/react';

import { FormattedMessage } from 'react-intl';
import { Link as RouterLink } from 'react-router-dom';
import { ArrowGoIcon } from 'design-system/atoms/custom-icons';
import { MESSAGE_BUTTON_CLOSE } from '../../constants/messages';
import useProjectRoutes from '../../data/useProjectRoutes';
import useSitesData from '../../data/useSitesData';
import { useAnalytics } from '../../hooks/analytics/useAnalytics';
import RedLight from '../../svg/red-light.svg?react';
import RouteInsights from '../RouteInsights';

const padding = {
  base: 2,
  md: 4,
  lg: 8,
  xl: 16,
};

export interface RouteDetailModalProps {
  insightId: string;
  trackId: string;
  isOpen: boolean;
  onClose: () => void;
}

export default function RouteDetailModal({
  insightId,
  trackId,
  isOpen,
  onClose,
}: RouteDetailModalProps): JSX.Element {
  const projectRoutes = useProjectRoutes(insightId, {
    includeDisabled: true,
  });
  const route = projectRoutes.data?.find(
    (projectRoute) => projectRoute.trackId === trackId
  );
  const { startSiteMonitoring } = useSitesData(insightId);
  const { track } = useAnalytics();
  const onEnableMonitoringButtonClick = async () => {
    if (route?.siteId !== undefined) {
      track('Enable Site Monitoring', {
        referrer: 'Route Detail Modal',
        siteId: route.siteId,
        projectId: insightId,
      });
      startSiteMonitoring(route?.siteId);
    }
  };
  return (
    <Modal
      onClose={onClose}
      isOpen={isOpen}
      size="full"
      scrollBehavior="inside"
    >
      <ModalContent backgroundColor="gray.50">
        <ModalHeader paddingX={padding} boxShadow="soft">
          <Heading>{route?.routeName}</Heading>
          <Button
            size="sm"
            variant="outline"
            as={RouterLink}
            to={route?.siteUrl ?? '#'}
            isDisabled={route?.siteUrl === undefined}
            rightIcon={<ArrowGoIcon width="8px" />}
            mt={4}
            onClick={() => {
              track('Full Site Link Clicked', {
                referrer: 'Route Detail Modal',
                siteId: route?.siteId,
                projectId: insightId,
              });
            }}
          >
            <FormattedMessage
              defaultMessage="Go to full site"
              id="5B2IfW"
              description="route performance modal - full site link label"
            />
          </Button>
          <ModalCloseButton right={padding} />
        </ModalHeader>
        <ModalBody padding={padding}>
          {route?.enabled === true ? (
            <RouteInsights
              siteId={route.siteId!}
              insightId={insightId}
              trackId={trackId}
            />
          ) : (
            <VStack spacing={4}>
              <Box maxWidth="md" height="md" marginTop={-8} marginBottom={-6}>
                <RedLight height="100%" width="100%" />
              </Box>
              <Heading maxWidth="md" textAlign="center">
                <FormattedMessage
                  id="A7/aNv"
                  defaultMessage="Monitoring is currently inactive on this route"
                  description="Route detail modal - monitoring disabled heading"
                />
              </Heading>
              <Text maxWidth="md" textAlign="center">
                <FormattedMessage
                  id="/odSsJ"
                  defaultMessage="In order to view this routes current performance, turn on
                  site-level monitoring."
                  description="Route detail modal - monitoring disabled text"
                />
              </Text>
              <Button
                colorScheme="brand"
                variant="solid"
                onClick={onEnableMonitoringButtonClick}
              >
                <FormattedMessage
                  id="bYtOjR"
                  defaultMessage="Turn on monitoring"
                  description="Route detail modal - enable monitoring button"
                />
              </Button>
            </VStack>
          )}
        </ModalBody>
        <ModalFooter
          paddingX={padding}
          justifyContent="start"
          backgroundColor="white"
          borderTop="1px"
          borderTopColor="gray.200"
        >
          <Button onClick={onClose} variant="solid" colorScheme="greenDark">
            <FormattedMessage {...MESSAGE_BUTTON_CLOSE} />
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}
