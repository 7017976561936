import {
  Box,
  Button,
  Checkbox,
  Select,
  Text,
  Wrap,
  WrapItem,
} from '@chakra-ui/react';
import { SplitClient } from '@splitsoftware/splitio-react';
import { FormEventHandler, useCallback, useRef, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useIntl } from 'react-intl';
import AutoCompleteInput, {
  AutoCompleteRefMethods,
} from 'design-system/molecules/auto-complete-input';
import FeatureFlag from '../../../components/FeatureFlag';
import { FEATURE_SITE_IMPACT_KPI_ALERTS } from '../../../constants/features';
import { emailValidator } from '../../../utils/email';

type Role = 'OWNER' | 'CAN_EDIT' | 'CAN_VIEW';

interface InviteUsersFormValues {
  role: Role;
  siteImpactAlert: boolean;
  delayAlert: boolean;
  speedAlert: boolean;
  queueAlert: boolean;
  annotationsAlert: boolean;
}

export interface InviteUsersResult {
  emails: string[];
  role: Role;
  siteImpactAlert: boolean;
  delayAlert: boolean;
  speedAlert: boolean;
  queueAlert: boolean;
  annotationsAlert: boolean;
}

export interface InviteUsersFormProps {
  siteId: number;
  onSubmit: (result: InviteUsersResult) => void | Promise<void>;
  autocompleteEmails: string[];
}

export default function InviteUsersForm({
  siteId,
  onSubmit,
  autocompleteEmails,
}: InviteUsersFormProps) {
  const [query, setQuery] = useState<string>('');
  const [isInviting, setIsInviting] = useState(false);
  const [selectedEmails, setSelectedEmails] = useState<string[]>([]);
  const { handleSubmit, register } = useForm<InviteUsersFormValues>({
    defaultValues: {
      role: 'CAN_VIEW',
      siteImpactAlert: false,
      delayAlert: false,
      speedAlert: false,
      queueAlert: false,
    },
  });
  const { formatMessage } = useIntl();
  const autoCompleteRef = useRef<AutoCompleteRefMethods>(null);

  const tokenizer = (str: string) => str.trim().split(/[\s,]+/);

  const onFormSubmit: FormEventHandler = handleSubmit(async (values) => {
    setIsInviting(true);
    try {
      // Combine the valid email in the input with the selected emails
      let eligibleEmails = [...selectedEmails];
      if (query && emailValidator(tokenizer(query))) {
        eligibleEmails = eligibleEmails.concat(tokenizer(query));
      }
      autoCompleteRef.current?.resetTags();
      await onSubmit({
        emails: eligibleEmails,
        role: values.role,
        siteImpactAlert: values.siteImpactAlert,
        delayAlert: values.delayAlert,
        speedAlert: values.speedAlert,
        queueAlert: values.queueAlert,
        annotationsAlert: values.annotationsAlert,
      });
    } finally {
      setIsInviting(false);
    }
  });

  const onValueChange = (value: string) => {
    setQuery(value);
  };

  const onTagsChange = (newEmails: string[]) => {
    setSelectedEmails(newEmails);
  };

  const isDisableSubmitButton = () => {
    if (isInviting) {
      return true;
    }
    if (query) {
      return !emailValidator(tokenizer(query));
    }
    return selectedEmails.length === 0;
  };

  const SelectRole = useCallback(
    () => (
      <Select {...register('role')} border="">
        <option value="CAN_VIEW">
          {formatMessage({
            id: 'Ep0FAp',
            description: 'Site access settings can view',
            defaultMessage: 'Can view',
          })}
        </option>
        <option value="CAN_EDIT">
          {formatMessage({
            id: 'lcEPhI',
            description: 'Site access settings can edit',
            defaultMessage: 'Can edit',
          })}
        </option>
        <option value="OWNER">
          {formatMessage({
            id: '2XopuH',
            description: 'Site access settings owner',
            defaultMessage: 'Owner',
          })}
        </option>
      </Select>
    ),
    [register, formatMessage]
  );
  const handleSubmitNoPropagation = (e) => {
    e.stopPropagation();
    return onFormSubmit(e);
  };

  return (
    <Box as="form" onSubmit={handleSubmitNoPropagation}>
      <Box
        backgroundColor="gray.50"
        borderRadius="lg"
        padding={2}
        marginBottom={4}
      >
        <AutoCompleteInput
          onValueChange={onValueChange}
          onTagsChange={onTagsChange}
          tokenizer={tokenizer}
          validator={emailValidator}
          autoCompleteItems={autocompleteEmails}
          errorMessage={formatMessage({
            defaultMessage: 'Please enter a valid email address',
            id: 'akmyCq',
            description: 'Validate email',
          })}
          placeholder={formatMessage({
            defaultMessage: 'Enter email(s)',
            id: '3AfnNo',
            description: 'Invite user email input placeholder',
          })}
          inputRightElement={<SelectRole />}
          ref={autoCompleteRef}
        />
        <Wrap justify="right" margin={2} spacingX={5} spacingY={2}>
          <WrapItem>
            <Text margin={0} textStyle="heading">
              {formatMessage({
                id: 'PVE7Dy',
                description: 'Site access settings alerts',
                defaultMessage: 'Alerts:',
              })}
            </Text>
          </WrapItem>
          <WrapItem>
            {/* Control this feature by siteId instead of userId. */}
            <SplitClient splitKey={String(siteId)}>
              <FeatureFlag
                feature={FEATURE_SITE_IMPACT_KPI_ALERTS}
                treatment="on"
              >
                <Checkbox margin={0} {...register('siteImpactAlert')}>
                  {formatMessage({
                    id: 'KIjKa/',
                    description: 'Site access settings site impact',
                    defaultMessage: 'Site Impact',
                  })}
                </Checkbox>
              </FeatureFlag>
            </SplitClient>
          </WrapItem>
          <WrapItem>
            <Checkbox margin={0} {...register('delayAlert')}>
              {formatMessage({
                id: '36Qwkd',
                description: 'Site access settings delay',
                defaultMessage: 'Delay',
              })}
            </Checkbox>
          </WrapItem>
          <WrapItem>
            <Checkbox margin={0} {...register('speedAlert')}>
              {formatMessage({
                id: '/4Z7AT',
                description: 'Site access settings speed',
                defaultMessage: 'Speed',
              })}
            </Checkbox>
          </WrapItem>
          <WrapItem>
            <Checkbox margin={0} {...register('queueAlert')}>
              {formatMessage({
                id: 'vOlLYG',
                description: 'Site access settings queue',
                defaultMessage: 'Queue',
              })}
            </Checkbox>
          </WrapItem>
          <WrapItem>
            <Checkbox margin={0} {...register('annotationsAlert')}>
              {formatMessage({
                id: '9VOP3F',
                description: 'Site access settings annotations',
                defaultMessage: 'Annotations',
              })}
            </Checkbox>
          </WrapItem>
        </Wrap>
      </Box>
      <Button
        colorScheme="greenDark"
        isDisabled={isDisableSubmitButton()}
        isLoading={isInviting}
        type="submit"
      >
        {formatMessage({
          id: 'dy2l0R',
          description: 'Site access settings send invite',
          defaultMessage: 'Send invite',
        })}
      </Button>
    </Box>
  );
}
