import { ReactNode, useEffect, useRef, useState } from 'react';
import { DrawingManager, useGoogleMap } from '@react-google-maps/api';
import { LatLng, googleDynamicCoordinatesToPointArray } from '@lib/gis';
import { Tag, TagLabel } from '@chakra-ui/react';
import { FormattedMessage } from 'react-intl';
import { PolygonCreatorManager } from './usePolygonCreatorManager';

export default function PolygonCreatorMapLayer({
  onCreate,
  polygonCreatorManager,
}: {
  onCreate: (polygonPoints: LatLng[]) => void;
  polygonCreatorManager: PolygonCreatorManager;
}) {
  const { isCreating, stopCreating } = polygonCreatorManager;

  const mapInstance = useGoogleMap();
  const lastMouseDownRef = useRef({ x: 0, y: 0 });
  const isCreatingHandledRef = useRef(false);
  const [isWaitingFirstDrawingInput, setIsWaitingFirstDrawingInput] =
    useState(false);

  useEffect(() => {
    if (!mapInstance) {
      return undefined;
    }
    const mapDiv = mapInstance.getDiv();
    const bodyMouseDownHandler = (e: MouseEvent) => {
      lastMouseDownRef.current = {
        x: e.x,
        y: e.y,
      };
    };
    const bodyClickHandler = (e: MouseEvent) => {
      const didMouseMove =
        e.x !== lastMouseDownRef.current.x ||
        e.y !== lastMouseDownRef.current.y;
      const isMapClick =
        e.target &&
        (e.target === mapDiv ||
          (IsNode(e.target) && mapDiv.contains(e.target)));
      if (!didMouseMove && isMapClick) {
        setIsWaitingFirstDrawingInput(false);
      }
    };

    const startListeningForMapInput = () => {
      if (isCreatingHandledRef.current) {
        return;
      }
      isCreatingHandledRef.current = true;
      setIsWaitingFirstDrawingInput(true);
      setTimeout(() => {
        document.addEventListener('click', bodyClickHandler);
        document.addEventListener('mousedown', bodyMouseDownHandler);
      }, 0);
    };
    const stopListeningForMapInput = () => {
      if (!isCreatingHandledRef.current) {
        return;
      }
      isCreatingHandledRef.current = false;
      setIsWaitingFirstDrawingInput(false);
      document.removeEventListener('click', bodyClickHandler);
      document.removeEventListener('mousedown', bodyMouseDownHandler);
    };

    if (isCreating) {
      startListeningForMapInput();
      return stopListeningForMapInput;
    }
    return undefined;
  }, [isCreating, mapInstance]);

  const onPolygonComplete = (completedPolygon: google.maps.Polygon) => {
    const overlaypoints = completedPolygon.getPath().getArray();
    completedPolygon.setMap(null);
    if (overlaypoints.length > 2) {
      const layerGeometry = googleDynamicCoordinatesToPointArray(overlaypoints);
      onCreate(layerGeometry);
      stopCreating();
    } else {
      stopCreating();
    }
  };

  if (!isCreating) {
    return null;
  }

  return (
    <>
      <MouseTooltip
        offsetX={5}
        offsetY={20}
        visible={isWaitingFirstDrawingInput}
      >
        <Tag color="white" backgroundColor="blackAlpha.700">
          <TagLabel>
            <FormattedMessage defaultMessage="Click map to draw" id="ByQZlv" />
          </TagLabel>
        </Tag>
      </MouseTooltip>
      <DrawingManager
        options={{
          drawingControl: false,
          polygonOptions: {
            strokeColor: '#ffff00',
            strokeWeight: 5,
            zIndex: 1,
          },
        }}
        drawingMode={google.maps.drawing.OverlayType.POLYGON}
        onPolygonComplete={onPolygonComplete}
      />
    </>
  );
}

function IsNode(eTarget: EventTarget | null): eTarget is Node {
  if (!eTarget || !('nodeType' in eTarget)) {
    return false;
  }
  return true;
}

function MouseTooltip({
  children,
  offsetX = 0,
  offsetY = 0,
  visible = true,
}: {
  children: ReactNode;
  offsetX?: number;
  offsetY?: number;
  visible?: boolean;
}) {
  const [position, setPosition] = useState<{ x: number; y: number }>();
  useEffect(() => {
    const getTooltipPosition = ({ clientX, clientY }: MouseEvent) => {
      setPosition({ x: clientX, y: clientY });
    };
    window.addEventListener('mousemove', getTooltipPosition);
    return () => {
      window.removeEventListener('mousemove', getTooltipPosition);
    };
  }, []);

  return !position || !visible ? null : (
    <div
      style={{
        position: 'fixed',
        top: position.y + offsetY,
        left: position.x + offsetX,
        pointerEvents: 'none',
        zIndex: '1000',
      }}
    >
      {children}
    </div>
  );
}
