export enum DistanceUnit {
  meter = 'meter',
  mile = 'mile',
}

export enum SpeedUnit {
  kilometerPerHour = 'kilometer-per-hour',
  milePerHour = 'mile-per-hour',
}

export type SiteUnitType = 'METRIC' | 'IMPERIAL';
