import { useState } from 'react';
import { Button } from '@chakra-ui/react';
import type { Role, TeamUser } from '@webapp/bff';
import { FormattedMessage } from 'react-intl';
import { InviteUsersModal } from './InviteUsersModal';
import Permissions from '../../local_design_system/organisms/SiteAccessSettings/Permissions';
import UsersList from '../../local_design_system/organisms/SiteAccessSettings/UsersList';
import UserContextMenu from '../../local_design_system/organisms/SiteAccessSettings/UserContextMenu';
import RoleMenu from '../../local_design_system/organisms/SiteAccessSettings/RoleMenu';
import UserActionsMenu from '../../local_design_system/organisms/SiteAccessSettings/UserActionsMenu';
import StatusGroupsLayout from '../../local_design_system/organisms/SiteAccessSettings/StatusGroupsLayout';

export interface TeamMembersSettingsProps {
  orgId: string;
  users: TeamUser[];
  currentUserId: number;
  updateTeamRole: (input: {
    user: TeamUser;
    orgId: string;
    newRoleValue: Role;
  }) => Promise<any>;
  inviteTeamMembers: (data: {
    recipients: string[];
    role: Role;
    orgId: string;
  }) => Promise<any>;
  removeTeamMember: (user: TeamUser) => Promise<void>;
  sendPasswordReset: (user: TeamUser) => Promise<void>;
  resendTeamInvite: (user: TeamUser) => Promise<void>;
}

export default function TeamMembersSettings({
  orgId,
  users,
  currentUserId,
  updateTeamRole,
  removeTeamMember,
  sendPasswordReset,
  inviteTeamMembers,
  resendTeamInvite,
}: TeamMembersSettingsProps) {
  const [isInviting, setIsInviting] = useState(false);
  const activeUsers = users.filter((u) => !u.pending);
  const pendingUsers = users.filter((u) => u.pending);
  const updateRole = ({ user, newRoleValue }) =>
    updateTeamRole({ orgId, user, newRoleValue });
  return (
    <StatusGroupsLayout
      actionHeader={
        <>
          <InviteUsersModal
            onClose={() => setIsInviting(false)}
            orgId={orgId}
            isOpen={isInviting}
            inviteTeamMembers={inviteTeamMembers}
          />
          <Button
            onClick={() => setIsInviting(true)}
            colorScheme="greenDark"
            size="md"
          >
            <FormattedMessage
              defaultMessage="Invite"
              id="0VUsKu"
              description="Invite users button"
            />
          </Button>
        </>
      }
      activeList={
        <UsersList
          users={activeUsers}
          currentUserId={currentUserId}
          getUserContextMenu={(user) => (
            <UserContextMenu
              user={user.name}
              triggerButtonLabel={
                <Permissions
                  user={{
                    canEdit: user.teamRole === 'Editor',
                  }}
                  rawText
                />
              }
            >
              <RoleMenu
                user={user}
                role={user.teamRole}
                updateRole={updateRole}
              />
              <UserActionsMenu
                user={user}
                sendPasswordReset={sendPasswordReset}
                removeTeamMember={
                  user.userId === currentUserId ? undefined : removeTeamMember
                }
              />
            </UserContextMenu>
          )}
        />
      }
      pendingList={
        pendingUsers.length > 0 && (
          <UsersList
            users={pendingUsers}
            currentUserId={currentUserId}
            getUserContextMenu={(user) => (
              <UserContextMenu
                user={user.name}
                triggerButtonLabel={
                  <Permissions
                    user={{
                      canEdit: user.teamRole === 'Editor',
                    }}
                    rawText
                  />
                }
              >
                <RoleMenu
                  user={user}
                  role={user.teamRole}
                  updateRole={updateRole}
                />
                <UserActionsMenu
                  user={user}
                  resendInvite={resendTeamInvite}
                  removeTeamMember={removeTeamMember}
                />
              </UserContextMenu>
            )}
          />
        )
      }
    />
  );
}
