import { Fragment, ReactNode, useState } from 'react';
import { WorkArea } from '@webapp/bff/src/types/work-area';
import {
  Box,
  Button,
  Flex,
  Heading,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Text,
  VStack,
} from '@chakra-ui/react';
import { FormattedMessage, useIntl } from 'react-intl';
import { ChevronDownIcon } from '@chakra-ui/icons';
import {
  EditWorkArea,
  NewWorkArea,
  WorkAreasEditorManager,
  isEditWorkArea,
} from './useWorkAreasEditorManager';
import WorkAreaEditingProps from './WorkAreaEditingProps';
import conesBg from '../../svg/cones.svg?inline';
import { useSuccessToast } from '../../hooks/useSuccessToast';
import { useFailureToast } from '../../hooks/useFailureToast';
import { errorReport } from '../../utils/errors';

export default function WorkAreasEditorList({
  workAreas,
  workAreasEditorManager,
  createWorkArea,
  editWorkArea,
  deleteWorkArea,
  LayoutFocusContent = Fragment,
}: {
  workAreas: WorkArea[];
  workAreasEditorManager: WorkAreasEditorManager;
  createWorkArea: (workArea: NewWorkArea) => Promise<void>;
  editWorkArea: (workArea: EditWorkArea) => Promise<void>;
  deleteWorkArea: (workAreaId: string) => Promise<void>;
  LayoutFocusContent?: (props: { children: ReactNode }) => ReactNode;
}) {
  const {
    polygonCreatorManager: { isCreating, startCreating, stopCreating },
    areaEditing,
    startEditing,
    cancelEditing,
    onEditingChange,
  } = workAreasEditorManager;
  const { formatMessage } = useIntl();
  const successToast = useSuccessToast({
    title: formatMessage({
      defaultMessage: 'Area defined',
      id: 'vdB4ya',
    }),
  });
  const failureToast = useFailureToast({
    title: formatMessage({
      defaultMessage: 'There was an issue saving your changes',
      id: '85LLcj',
    }),
  });
  const [isSaving, setIsSaving] = useState(false);
  const onSave = async () => {
    if (!areaEditing || isSaving) {
      return;
    }
    setIsSaving(true);
    try {
      if (isEditWorkArea(areaEditing)) {
        await editWorkArea(areaEditing);
      } else {
        await createWorkArea(areaEditing);
      }
      successToast();
    } catch (e) {
      errorReport.handled(e, { action: 'updateWorkArea' });
      failureToast();
    } finally {
      cancelEditing();
      setIsSaving(false);
    }
  };

  const [isDeleting, setIsDeleting] = useState<string[]>([]);
  const onDelete = async (workAreaId: string) => {
    if (isDeleting.indexOf(workAreaId) > -1) {
      return;
    }
    setIsDeleting((isDeletingState) => [...isDeletingState, workAreaId]);
    try {
      await deleteWorkArea(workAreaId);
      successToast({
        title: formatMessage({
          defaultMessage: 'Area deleted',
          id: 'y186NO',
        }),
      });
    } catch (e) {
      errorReport.handled(e, { action: 'deleteWorkArea' });
      failureToast();
    } finally {
      setIsDeleting((isDeletingState) =>
        isDeletingState.filter((_id) => _id !== workAreaId)
      );
    }
  };

  return (
    <VStack spacing={4} alignItems="stretch">
      {workAreas.length === 0 && <InstructionsCard />}
      <Text fontSize="xs" m="0">
        <FormattedMessage
          id="UnEG9r"
          defaultMessage="Selecting your work area will allow us to give you context of potential local business and transport disruption"
        />
      </Text>
      {!isCreating && workAreas.length === 0 && (
        <Box>
          <Button
            variant="solid"
            colorScheme="greenDark"
            size="sm"
            onClick={startCreating}
            isDisabled={isCreating || !!areaEditing || isSaving}
          >
            <FormattedMessage id="g2dK3U" defaultMessage="Define area" />
          </Button>
        </Box>
      )}
      {workAreas.map((area) => (
        <WorkAreaCard
          key={area.id}
          area={area}
          onEdit={() => startEditing(area.id)}
          onDelete={() => onDelete(area.id)}
          isDeleting={isDeleting.indexOf(area.id) > -1}
        />
      ))}
      {isCreating && (
        <Box>
          <Button
            onClick={stopCreating}
            isDisabled={isSaving}
            variant="outline"
            size="xs"
          >
            <FormattedMessage defaultMessage="Cancel drawing" id="4P6d6V" />
          </Button>
        </Box>
      )}
      {!isCreating && workAreas.length > 0 && (
        <Box>
          <Button
            onClick={startCreating}
            isDisabled={isCreating || !!areaEditing || isSaving}
            variant="outline"
            size="xs"
          >
            <FormattedMessage defaultMessage="Add another area" id="NMlE/o" />
          </Button>
        </Box>
      )}
      {areaEditing && (
        <LayoutFocusContent>
          <WorkAreaEditingProps
            workArea={areaEditing}
            setWorkArea={onEditingChange}
            isSaving={isSaving}
            onSave={onSave}
            onCancel={cancelEditing}
          />
        </LayoutFocusContent>
      )}
    </VStack>
  );
}

function WorkAreaCard({
  area,
  onEdit,
  onDelete,
  isDeleting,
}: {
  area: WorkArea;
  onEdit: () => void;
  onDelete: () => void;
  isDeleting: boolean;
}) {
  return (
    <Box
      width="full"
      boxShadow="soft"
      borderRadius="12px"
      padding={4}
      position="relative"
    >
      <Box
        position="absolute"
        borderRadius="3px"
        left="8px"
        top="8px"
        w="16px"
        h="6px"
        backgroundColor={area.fillColor}
      />
      <Flex justifyContent="space-between" gap={2}>
        <Heading size="sm" paddingTop="1px" margin={0}>
          {area.name}
        </Heading>
        <Box flex={0}>
          <Menu strategy="fixed">
            <MenuButton
              as={Button}
              rightIcon={<ChevronDownIcon />}
              variant="outline"
              size="xs"
              isLoading={isDeleting}
            >
              <FormattedMessage defaultMessage="Options" id="NDV5Mq" />
            </MenuButton>
            <MenuList>
              <MenuItem onClick={onEdit}>
                <FormattedMessage defaultMessage="Edit" id="wEQDC6" />
              </MenuItem>
              <MenuItem onClick={onDelete}>
                <FormattedMessage defaultMessage="Delete" id="K3r6DQ" />
              </MenuItem>
            </MenuList>
          </Menu>
        </Box>
      </Flex>
    </Box>
  );
}

function InstructionsCard() {
  return (
    <Box
      minHeight="182px"
      background={`bottom center/100% no-repeat url(${conesBg})`}
      backgroundColor="yellow.100"
      borderRadius="3xl"
      padding="6"
      paddingBottom="80px"
      paddingRight="70px"
    >
      <Text fontSize="sm" fontWeight="bold" mb={1}>
        <FormattedMessage
          id="uy02jl"
          defaultMessage="Match your work area to the site you’re most likely to be working"
        />
      </Text>
      <Text fontSize="xs">
        <FormattedMessage
          id="W8oA6n"
          defaultMessage="Tap “Define area” to start"
        />
      </Text>
    </Box>
  );
}
