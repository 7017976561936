import { ChangeEvent, ReactNode } from 'react';
import {
  Box,
  Button,
  FormControl,
  FormLabel,
  HStack,
  Input,
  Tag,
  VStack,
  useToken,
  Text,
} from '@chakra-ui/react';
import area from '@turf/area';
import { polygon } from '@turf/helpers';
import { TintIcon } from 'design-system/atoms/custom-icons';
import { FormattedMessage } from 'react-intl';
import CustomRadioGroup, {
  CustomRadioComponentProps,
} from 'design-system/molecules/radio-group/custom-radio-group';
import { EditWorkArea, NewWorkArea } from './useWorkAreasEditorManager';

const colorOptionTokens = [
  'red.300',
  'orange.300',
  'yellow.300',
  'green.300',
  'teal.300',
  'blue.300',
  'cyan.300',
  'purple.300',
  'pink.300',
];
const colorOptionsFallback = [
  '#FC8181',
  '#F6AD55',
  '#F6E05E',
  '#98EDB0',
  '#4FD1C5',
  '#63B3ED',
  '#76E4F7',
  '#B794F4',
  '#F687B3',
];
const transparencyOptions = [
  { label: '20%', value: '0.2' },
  { label: '40%', value: '0.4' },
  { label: '60%', value: '0.6' },
  { label: '80%', value: '0.8' },
  { label: '100%', value: '1' },
];

export default function WorkAreaEditingProps({
  workArea,
  setWorkArea,
  isSaving,
  onSave,
  onCancel,
}: {
  workArea: EditWorkArea | NewWorkArea;
  setWorkArea: (workArea: EditWorkArea | NewWorkArea) => void;
  isSaving: boolean;
  onSave: () => Promise<void>;
  onCancel: () => void;
}) {
  const fieldValueChangeProps = (
    fieldName: keyof EditWorkArea,
    isNumeric = false
  ) => ({
    value: workArea[fieldName].toString(),
    onChange: (e: string | ChangeEvent<HTMLInputElement>) => {
      let fieldValue: string | number =
        typeof e === 'string' ? e : e.target.value;
      if (isNumeric) {
        fieldValue = parseFloat(fieldValue);
      }
      setWorkArea({ ...workArea, [fieldName]: fieldValue });
    },
  });

  const colorOptions = useToken(
    'colors',
    colorOptionTokens,
    colorOptionsFallback
  ).map((colorValue, colorIndex) => ({
    value: colorValue,
    label: colorOptionTokens[colorIndex],
  }));

  return (
    <VStack
      padding={2}
      boxShadow="md"
      borderRadius="6px"
      alignItems="left"
      spacing={8}
    >
      <VStack alignItems="left" spacing={4}>
        <FormControl>
          <FormLabel fontSize="xs" fontWeight="normal">
            <FormattedMessage defaultMessage="Name of area" id="fSKkpf" />
          </FormLabel>
          <Input size="sm" {...fieldValueChangeProps('name')} />
        </FormControl>
        <CustomRadioGroup
          radioGroupProps={fieldValueChangeProps('fillColor')}
          options={colorOptions}
          renderRootComponent={ColorRadioGroupRoot}
          renderRadioComponent={ColorRadio}
        />
        <CustomRadioGroup
          radioGroupProps={fieldValueChangeProps('fillTransparency', true)}
          options={transparencyOptions}
          renderRootComponent={TransparencyRadioGroupRoot}
          renderRadioComponent={TransparencyRadio}
        />
        <Text fontSize="xs" m={0}>
          <FormattedMessage
            defaultMessage="Total area: <strong>{siteArea}m²</strong>"
            id="6LvqpJ"
            values={{
              siteArea: getPolygonArea(workArea.wkt),
            }}
          />
        </Text>
      </VStack>

      <HStack spacing={2}>
        <Button
          onClick={onSave}
          variant="solid"
          colorScheme="greenDark"
          size="sm"
          isLoading={isSaving}
        >
          <FormattedMessage defaultMessage="Save" id="jvo0vs" />
        </Button>
        <Button
          onClick={onCancel}
          variant="outline"
          size="sm"
          isDisabled={isSaving}
        >
          <FormattedMessage defaultMessage="Cancel" id="47FYwb" />
        </Button>
      </HStack>
    </VStack>
  );
}
function ColorRadioGroupRoot({ children }: { children: ReactNode }) {
  return (
    <HStack spacing={0}>
      <TintIcon mr="6px" />
      {children}
    </HStack>
  );
}
function ColorRadio({ option, stateProps }: CustomRadioComponentProps) {
  return (
    <Box
      {...stateProps}
      aria-label={option.label}
      width="26px"
      height="26px"
      border="solid 4px transparent"
      borderRadius="full"
      padding="1px"
      _checked={{
        borderColor: option.value,
      }}
      _focusVisible={{
        boxShadow: 'outline',
      }}
    >
      <Box
        width="100%"
        height="100%"
        background={option.value}
        borderRadius="full"
      />
    </Box>
  );
}

function TransparencyRadioGroupRoot({ children }: { children: ReactNode }) {
  return <HStack spacing={2}>{children}</HStack>;
}
function TransparencyRadio({ option, stateProps }: CustomRadioComponentProps) {
  return (
    <Tag
      {...stateProps}
      data-group
      aria-label={option.label}
      size="sm"
      _checked={{
        backgroundColor: 'purple.600',
      }}
      _focusVisible={{
        boxShadow: 'outline',
      }}
    >
      <Box
        as="span"
        color="gray.700"
        _groupChecked={{
          color: 'white',
        }}
      >
        {option.label}
      </Box>
    </Tag>
  );
}

function getPolygonArea(wkt) {
  const wktMatch = wkt.match(/\(([-,\d\s.]+)\)/);
  const wktContent = (wktMatch && wktMatch[1]) || '';
  const wktPoints = wktContent
    .split(',')
    .map((wktPoint) => wktPoint.trim().split(' '));
  const tPolygon = polygon([wktPoints]);
  const siteArea = area(tPolygon);
  return Math.round(siteArea);
}
