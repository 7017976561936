import { ChevronDownIcon, ChevronUpIcon } from '@chakra-ui/icons';
import { Button, Center, Tag, TagLeftIcon } from '@chakra-ui/react';
import { FormattedMessage } from 'react-intl';

export default function StickyBackButton({
  onClick,
  direction,
}: {
  onClick: () => void;
  direction: 'up' | 'down';
}) {
  return (
    <>
      <Center
        position="sticky"
        zIndex={1}
        top={direction === 'down' ? '-16px' : undefined}
        bottom={direction === 'up' ? '-64px' : undefined}
        p={5}
        mt={-4}
        mb={-16}
      >
        <Button
          onClick={onClick}
          colorScheme="teal"
          bgColor="teal.500"
          color="white"
          size="xs"
          leftIcon={
            direction === 'up' ? <ChevronDownIcon /> : <ChevronUpIcon />
          }
          fontSize="sm"
          fontWeight="medium"
        >
          <FormattedMessage
            defaultMessage="Back to selected date range"
            id="y8tSon"
            description="Annotations - back to current time period button label"
          />
        </Button>
      </Center>
      <Center p={5} position="relative" zIndex={1}>
        <Tag bgColor="teal.500" color="white">
          <TagLeftIcon
            as={direction === 'up' ? ChevronUpIcon : ChevronDownIcon}
          />
          {direction === 'up' ? (
            <FormattedMessage
              defaultMessage="Later than selected date range"
              id="8JgSSO"
              description="Annotations later than the current time period"
            />
          ) : (
            <FormattedMessage
              defaultMessage="Earlier than selected date range"
              id="Yk1ovm"
              description="Annotations earlier than the current time period"
            />
          )}
        </Tag>
      </Center>
    </>
  );
}
