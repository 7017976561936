import { Button } from '@chakra-ui/react';
import { useIntl } from 'react-intl';
import { parse } from 'date-fns';
import { CalendarIcon } from 'design-system/atoms/custom-icons';
import DateRangeSelection, { DateRangeSelectionProps } from '.';
import { DATE_PRESET_SHORT_D_M_Y } from '../../utilities/date-presets';

export default function DateRangeSelectionButton({
  startDate,
  endDate,
  isOpen,
  onOpen,
  ...rest
}: Omit<DateRangeSelectionProps, 'value'> & {
  startDate: string;
  endDate: string;
  onOpen: () => void;
}) {
  const { formatDate } = useIntl();
  return (
    <>
      <Button
        data-testid="date-range-field-trigger"
        width="full"
        height="32px"
        backgroundColor="white"
        padding="0.6em"
        color="gray.700"
        fontSize="sm"
        justifyContent="space-between"
        boxShadow="soft"
        rightIcon={<CalendarIcon />}
        _hover={{
          backgroundColor: 'white',
          boxShadow: 'md',
        }}
        onClick={onOpen}
      >
        {startDate
          ? formatDate(parse(startDate, 'yyyy-MM-dd', new Date()), {
              ...DATE_PRESET_SHORT_D_M_Y,
            })
          : ''}
        {' - '}
        {endDate
          ? formatDate(parse(endDate, 'yyyy-MM-dd', new Date()), {
              ...DATE_PRESET_SHORT_D_M_Y,
            })
          : ''}
      </Button>
      <DateRangeSelection
        key={isOpen ? 0 : 1}
        isOpen={isOpen}
        value={{ startDate, endDate }}
        {...rest}
      />
    </>
  );
}
