import { useRef, useState, ChangeEvent, useEffect } from 'react';
import FormField from './FormField';
import { FormFieldProps } from '../types/form';

export interface DelayedInputFieldProps {
  fieldProps?: Omit<FormFieldProps, 'value' | 'options'>;
  value?: FormFieldProps['value'];
  onDelayedChange: (value: string) => void;
}
export default function DelayedInputField({
  fieldProps = {},
  value,
  onDelayedChange,
}: DelayedInputFieldProps) {
  const [tempValue, setTempValue] = useState(value || '');
  const latestValueRef = useRef(value || '');
  const focusRef = useRef(false);
  const inputRef = useRef<HTMLInputElement>();
  const handleFocus = (e) => {
    if (!focusRef.current) {
      if (fieldProps?.onFocus) {
        fieldProps.onFocus(e);
      }
      focusRef.current = true;
    }
  };
  const handleBlur = (e) => {
    focusRef.current = false;
    onDelayedChange(tempValue);
    setTimeout(() => {
      if (!focusRef.current) {
        setTempValue(latestValueRef.current);
        if (fieldProps?.onBlur) {
          fieldProps.onBlur(e);
        }
      }
    });
  };
  useEffect(() => {
    if (value !== undefined && latestValueRef.current !== value) {
      latestValueRef.current = value;
      if (value !== tempValue) {
        setTempValue(value);
      }
    }
  }, [value, tempValue]);
  return (
    <FormField
      {...fieldProps}
      value={tempValue}
      onFocus={handleFocus}
      onBlur={handleBlur}
      onChange={(e: ChangeEvent<HTMLInputElement>) => {
        setTempValue(e.currentTarget.value);
        if (fieldProps?.onChange) {
          fieldProps.onChange(e);
        }
      }}
      elementRef={(element: HTMLInputElement) => {
        inputRef.current = element;
      }}
      onKeyDown={(event) => {
        if (
          !(event.altKey || event.ctrlKey || event.metaKey) &&
          event.key === 'Enter'
        ) {
          event.preventDefault();
          onDelayedChange(tempValue);
        }
      }}
    />
  );
}
