import { Text, Box, Button, Flex } from '@chakra-ui/react';
import type { MessageType } from '@vms/shared/src/message-types';
import {
  TimerOnIcon,
  SpeedIcon,
  ThreeCarsIcon,
  FlashLightIcon,
  PencilIcon,
  AlternateRouteIcon,
} from 'design-system/atoms/custom-icons';
import CustomTabsList from 'design-system/atoms/custom-tabs';
import React, { useEffect, useImperativeHandle } from 'react';
import { UseFormReturn, useForm } from 'react-hook-form';
import { FormattedMessage } from 'react-intl';
import { useIntercom } from 'react-use-intercom';
import { ActiveVms } from '@webapp/bff/src/types/vms';
import { useSearchParams } from 'react-router-dom';
import { StepBoardMessageTypeAdminTab } from './StepBoardMessageTypeAdminTab';
import StepBoardMessageTypeLiveJourneyTimeTab from './StepBoardMessageTypeLiveJourneyTimeTab';
import StepBoardMessageTypeAutomatedLoadBalancingTab, {
  DEFAULT_ROUTE_INITIAL_LIMIT,
  generateDefaultRouteMessages,
  getAllRouteIdsFromAutomatedLoadBalancingContent,
} from './StepBoardMessageTypeAutomatedLoadBalancing';
import { MessageTypeFormType, RouteProps } from './messageTypes';
import { RouteSelectionProvider } from '../../hooks/useRouteSelection';
import { getDefaultBoardMessage } from './utils';
import { CreateWizardStepRenderProps } from '../CreateWizardLayout/CreateWizardLayout';

export interface StepBoardMessageTypeProps {
  vms: ActiveVms;
  updateVms: (vms: Partial<ActiveVms>) => Promise<void>;
  stepFormRenderProps: CreateWizardStepRenderProps;
  siteId: string;
  routes: { routeId: number; name: string }[];
  isAdmin?: boolean;
  setSelectedRoutes: (routeIds: string[]) => void;
}

export default function StepBoardMessageType({
  vms,
  updateVms,
  stepFormRenderProps: { submitActionRef, isSubmitting },
  routes,
  siteId,
  isAdmin = true,
  setSelectedRoutes,
}: StepBoardMessageTypeProps) {
  const [searchParams, setSearchParams] = useSearchParams();

  useEffect(() => {
    const newSearchParams = new URLSearchParams(searchParams);

    if (newSearchParams.has('startIntercomVmsMessageTour')) {
      return;
    }

    newSearchParams.set('startIntercomVmsMessageTour', 'true');

    setSearchParams(newSearchParams, {
      replace: true,
    });
  }, [setSearchParams, searchParams]);
  const formManager = useForm({
    defaultValues: messageTypeStepFormPropsInitializer({
      vmsData: vms,
      routesData: routes,
    }),
  });
  const { watch, handleSubmit } = formManager;
  useImperativeHandle(submitActionRef, () =>
    handleSubmit(
      async (formFields) => {
        if (
          formFields.messageTypeMode === 'AdvancedMode' &&
          formFields.advancedMonitoredRoutes.some((r) => r === null)
        ) {
          // TODO: Create error type for this use case (aborting submit with message)
          // eslint-disable-next-line prefer-promise-reject-errors
          return Promise.reject('Please assign all variables to a route');
        }
        return updateVms({
          messageConfig: {
            type: formFields.messageTypeMode,
            content: messageTypeStepFormExtractContent(formFields),
          },
          monitoredRoutes: getSelectedRoutesFromMessageStep(formFields).map(
            (routeId) => Number(routeId)
          ),
        });
      },
      // eslint-disable-next-line prefer-promise-reject-errors
      () => Promise.reject(true)
    )
  );

  const [
    messageTypeMode,
    liveJourneyTimeContent,
    advancedMonitoredRoutes,
    automatedLoadBalancingContent,
  ] = watch([
    'messageTypeMode',
    'liveJourneyTimeContent',
    'advancedMonitoredRoutes',
    'automatedLoadBalancingContent',
  ]);

  useEffect(() => {
    const selectedRoutes = getSelectedRoutesFromMessageStep({
      messageTypeMode,
      liveJourneyTimeContent,
      advancedMonitoredRoutes,
      automatedLoadBalancingContent,
    });
    setSelectedRoutes(selectedRoutes.map((r) => r.toString()));
  }, [
    messageTypeMode,
    liveJourneyTimeContent,
    advancedMonitoredRoutes,
    automatedLoadBalancingContent,
    setSelectedRoutes,
  ]);

  const tabs = getTabsData(
    { formManager, isSubmitting, routes, siteId },
    isAdmin
  );
  return (
    <RouteSelectionProvider siteId={siteId}>
      <CustomTabsList
        defaultIndex={tabs.findIndex((tab) => tab.type === messageTypeMode)}
        onChange={(tabIndex) => {
          const messageType = tabs[tabIndex].type;
          if (messageType !== undefined) {
            formManager.setValue('messageTypeMode', messageType);
          }
        }}
        tabsData={tabs.map((tab, index) => ({ ...tab, id: index }))}
      />
    </RouteSelectionProvider>
  );
}

function AdvancedCondition() {
  const intercom = useIntercom();
  return (
    <Flex
      direction="column"
      width="full"
      borderRadius="16px"
      backgroundColor="gray.50"
      p={4}
    >
      <Text color="gray.900" fontSize="md" lineHeight={6} fontWeight={700}>
        <FormattedMessage
          defaultMessage="Advanced conditions allow you to mix and match between conditions."
          id="BI+S13"
        />
      </Text>
      <Text color="gray.900" fontSize="xs" lineHeight={4} fontWeight={400}>
        <FormattedMessage
          defaultMessage="Currently this is only available by getting Mooven to produce these conditions for you.{br}Talk to us about whether this is something you’re interested in."
          id="7gUcip"
          values={{ br: <br /> }}
        />
      </Text>
      <Box>
        <Button
          size="xs"
          variant="solid"
          colorScheme="greenDark"
          onClick={() => {
            intercom.show();
          }}
        >
          <FormattedMessage id="aX9C8R" defaultMessage="Talk to us" />
        </Button>
      </Box>
    </Flex>
  );
}

function getTabsData(
  props: {
    formManager: UseFormReturn<MessageTypeFormType>;
    isSubmitting?: boolean;
    routes: { routeId: number; name: string }[];
    siteId: string;
  },
  isAdmin: boolean
): {
  type?: MessageType;
  title: React.ReactNode;
  icon: React.ReactNode;
  component: React.ReactNode;
  disabled?: boolean;
}[] {
  return [
    {
      type: 'LiveJourneyTime',
      title: (
        <FormattedMessage defaultMessage="Live journey time" id="Vbo7VJ" />
      ),
      icon: <TimerOnIcon width="24px" height="24px" />,
      component: <StepBoardMessageTypeLiveJourneyTimeTab {...props} />,
    },
    {
      type: 'AutomatedLoadBalancing',
      title: (
        <FormattedMessage
          defaultMessage="Automated load balancing"
          id="RyPZbM"
        />
      ),
      icon: <AlternateRouteIcon width="24px" height="24px" />,
      component: <StepBoardMessageTypeAutomatedLoadBalancingTab {...props} />,
      disabled: false,
    },
    {
      title: (
        <FormattedMessage defaultMessage="Speed based message" id="01E/ri" />
      ),
      icon: <SpeedIcon width="24px" height="24px" />,
      component: undefined,
      disabled: true,
    },
    {
      title: (
        <FormattedMessage defaultMessage="Queue based message" id="zowG6Z" />
      ),
      icon: <ThreeCarsIcon width="24px" height="24px" />,
      component: undefined,
      disabled: true,
    },
    {
      type: 'AdvancedMode',
      title: (
        <FormattedMessage defaultMessage="Advanced conditions" id="iuRo5x" />
      ),
      icon: <FlashLightIcon width="24px" height="24px" />,
      component: (
        <>
          <AdvancedCondition />
          {isAdmin && <StepBoardMessageTypeAdminTab {...props} />}
        </>
      ),
      disabled: false,
    },
    {
      title: <FormattedMessage defaultMessage="Manual message" id="ZcH38p" />,
      icon: <PencilIcon width="24px" height="24px" />,
      component: undefined,
      disabled: true,
    },
  ];
}

function messageTypeStepFormPropsInitializer({
  vmsData,
  routesData,
}: {
  vmsData: ActiveVms;
  routesData: RouteProps[];
}): MessageTypeFormType {
  const defaultBoardMessage =
    routesData.length > 0
      ? [getDefaultBoardMessage(`${routesData[0].routeId}`)]
      : [];
  return {
    messageTypeMode: vmsData.messageConfig?.type ?? 'LiveJourneyTime',
    advancedContent:
      vmsData.messageConfig?.type === 'AdvancedMode' &&
      vmsData.messageConfig.content
        ? vmsData.messageConfig?.content
        : '',
    advancedMonitoredRoutes: vmsData.monitoredRoutes ?? [],
    liveJourneyTimeContent:
      vmsData.messageConfig?.type === 'LiveJourneyTime' &&
      vmsData.messageConfig.content
        ? vmsData.messageConfig?.content
        : defaultBoardMessage,
    automatedLoadBalancingContent: {
      type: 'FastestRoute',
      messages: generateDefaultRouteMessages(routesData),
      alternateDefaultRouteId: routesData?.[0]?.routeId ?? '',
      alternateDefaultRouteLimit: DEFAULT_ROUTE_INITIAL_LIMIT,
      ...vmsData.messageConfig?.content,
    },
  };
}

function messageTypeStepFormExtractContent(fields: MessageTypeFormType) {
  switch (fields.messageTypeMode) {
    case 'LiveJourneyTime': {
      return fields.liveJourneyTimeContent;
    }
    case 'AdvancedMode': {
      return fields.advancedContent;
    }
    case 'AutomatedLoadBalancing': {
      return fields.automatedLoadBalancingContent;
    }
    default:
      throw new Error('Unknown message type');
  }
}

function getSelectedRoutesFromMessageStep(
  fields: Partial<MessageTypeFormType>
): string[] {
  switch (fields.messageTypeMode) {
    case 'LiveJourneyTime':
      return (fields.liveJourneyTimeContent ?? []).flatMap((frame) =>
        frame.lines.flatMap((line) =>
          line.lineParts
            .filter((part) => part.type === 'journeyTime')
            .map((part) => part.content)
        )
      );
    case 'AdvancedMode':
      return filterArrayNullItems(fields.advancedMonitoredRoutes ?? []).map(
        (r) => r.toString()
      );

    case 'AutomatedLoadBalancing':
      return getAllRouteIdsFromAutomatedLoadBalancingContent(
        fields.automatedLoadBalancingContent
      );
    default:
      return [];
  }
}
function filterArrayNullItems<T>(arr: Array<T | null>): Array<T> {
  return arr.filter((item) => item !== null) as Array<T>;
}
