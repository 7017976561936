import { KeyboardEventHandler } from 'react';

export function createEnterKeyHandler(
  onEnterKeyDown: () => void
): KeyboardEventHandler {
  return (event) => {
    if (event.key === 'Enter') {
      onEnterKeyDown();
    }
  };
}
