import { Box, Heading, Text, Button } from '@chakra-ui/react';
import { FormattedMessage } from 'react-intl';
import PromoSvg from '../../svg/promo.svg?react';

export default function ScorecardPromo() {
  return (
    <Box
      borderRadius="2xl"
      backgroundColor="blue.200"
      p={{ base: '24px', md: '48px' }}
      width="full"
    >
      <PromoSvg width="100%" />
      <Heading size="lg">
        <FormattedMessage defaultMessage="Scorecards," id="l/aZkP" />
      </Heading>
      <Text fontSize="2xl" fontWeight="bold">
        <FormattedMessage
          defaultMessage="a birds-eye-view of your projects’ disruption management performance."
          id="mymoob"
        />
      </Text>
      <Box>
        <Text fontSize="lg" as="span">
          <FormattedMessage
            description="Scorecard promo description"
            defaultMessage="Benchmark and track the disruption of your projects, and view potential working hour efficiency gains <b>all in one simple, easily consumable scorecard.</b>"
            id="z0UrsW"
            values={{
              b: (chunks) => <b>{chunks}</b>,
            }}
          />
        </Text>
      </Box>
      <Button
        as="a"
        href="https://mooven.framer.website/score-card/demo"
        target="_blank"
        variant="outline"
        mt="24px"
        size="xs"
      >
        <FormattedMessage
          defaultMessage="See an example scorecard"
          id="Auz/io"
        />
      </Button>
    </Box>
  );
}
