import { useMemo } from 'react';
import { FormatDateOptions, useIntl } from 'react-intl';

const WEEK_DATES = [
  new Date('2022-04-24T00:00:00.000Z'), // Sunday
  new Date('2022-04-25T00:00:00.000Z'), // Monday
  new Date('2022-04-26T00:00:00.000Z'), // Tuesday
  new Date('2022-04-27T00:00:00.000Z'), // Wednesday
  new Date('2022-04-28T00:00:00.000Z'), // Thursday
  new Date('2022-04-29T00:00:00.000Z'), // Friday
  new Date('2022-04-30T00:00:00.000Z'), // Saturday
];

export default function useIntlWeekDays(
  weekdayFormat: FormatDateOptions['weekday'] = 'short'
): Array<{
  value: number;
  label: string;
}> {
  const { formatDate } = useIntl();
  return useMemo(
    () =>
      WEEK_DATES.map((date, dateIndex) => ({
        value: dateIndex,
        label: formatDate(date, { weekday: weekdayFormat, timeZone: 'UTC' }),
      })),
    [formatDate, weekdayFormat]
  );
}
