import { LatLng, getImpactPolygon } from '@lib/gis';
import { WorkArea } from '@webapp/bff/src/types/work-area';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { parseWkt } from '../../utils/routes';
import { usePolygonCreatorManager } from '../PolygonCreator';

export type DisruptionAreaEditorManager = ReturnType<
  typeof useDisruptionAreaEditorManager
>;
interface DisruptionAreaEditorManagerProps {
  workAreas?: WorkArea[];
  disruptionArea?: string;
}

export default function useDisruptionAreaEditorManager(
  siteProps: DisruptionAreaEditorManagerProps
) {
  const { workAreas, disruptionArea } = siteProps;
  const prevPropsRef = useRef<Partial<DisruptionAreaEditorManagerProps>>({});

  const [disruptionAreaState, setDisruptionAreaState] = useState<LatLng[]>();

  const polygonCreatorManager = usePolygonCreatorManager();
  const onAreaCreated = useCallback(
    (polygonPoints: LatLng[]) => {
      setDisruptionAreaState(polygonPoints);
    },
    [setDisruptionAreaState]
  );

  const resetDisruptionArea = useCallback(() => {
    if (!workAreas || workAreas.length === 0) {
      return;
    }
    const disruptionAreaPoints = getDisruptionAreaFromWorkAreas(workAreas);
    setDisruptionAreaState(disruptionAreaPoints);
  }, [workAreas, setDisruptionAreaState]);

  const hasUnsavedChanges = useMemo(() => {
    if (disruptionArea && disruptionAreaState) {
      const disruptionAreaPoints = parseWkt(disruptionArea);
      return isPathDiff(disruptionAreaPoints, disruptionAreaState);
    }
    // Converting to boolean first because disruption area comes through as null from
    // site data, contradicting type definition of SiteResponse.
    return Boolean(disruptionArea) !== Boolean(disruptionAreaState);
  }, [disruptionArea, disruptionAreaState]);

  const hasWorkAreas = (workAreas?.length ?? 0) > 0;
  const isMatchWorkAreas = useMemo(() => {
    if (!workAreas || workAreas.length === 0 || !disruptionAreaState) {
      return false;
    }
    const disruptionAreaFromWorkingAreas =
      getDisruptionAreaFromWorkAreas(workAreas);
    return !isPathDiff(disruptionAreaFromWorkingAreas, disruptionAreaState);
  }, [workAreas, disruptionAreaState]);

  // reset state when new data comes in
  useEffect(() => {
    if (
      prevPropsRef.current.disruptionArea !== disruptionArea ||
      prevPropsRef.current.workAreas !== workAreas
    ) {
      prevPropsRef.current = { workAreas, disruptionArea };
      if (disruptionArea) {
        const newArea = parseWkt(disruptionArea);
        if (!disruptionAreaState || isPathDiff(newArea, disruptionAreaState)) {
          setDisruptionAreaState(newArea);
        }
      } else if (workAreas?.length) {
        resetDisruptionArea();
      } else {
        setDisruptionAreaState(undefined);
      }
    }
  }, [workAreas, disruptionArea, resetDisruptionArea, disruptionAreaState]);

  return {
    polygonCreatorManager,
    onAreaCreated,
    disruptionAreaState,
    setDisruptionAreaState,
    resetDisruptionArea,
    hasUnsavedChanges,
    hasWorkAreas,
    isMatchWorkAreas,
  };
}

const IMPACT_RADIUS_KM = 0.1;
function getDisruptionAreaFromWorkAreas(workAreas: WorkArea[]) {
  return getImpactPolygon(
    workAreas.map((area) => area.wkt),
    IMPACT_RADIUS_KM
  );
}

function isPathDiff(pathA: LatLng[], pathB: LatLng[]) {
  return (
    pathA.length !== pathB.length ||
    pathA.some(
      (pointFromA, i) =>
        pointFromA.lat !== pathB[i]?.lat || pointFromA.lng !== pathB[i]?.lng
    )
  );
}
