import useSWR, { SWRResponse } from 'swr';
import { RouteMaxJourneyTimeChange } from '../types/route';
import { dataTrpc } from '../config/trpc';

export type RouteMaxJourneyTimeChangeResult = SWRResponse<
  RouteMaxJourneyTimeChange[]
>;

const refreshInterval = 5 * 60 * 1000; // 5 minutes in milliseconds
export default function useRouteMaxJourneyTimeChangeData(
  insightId: string
): RouteMaxJourneyTimeChangeResult {
  const dataHandler = useSWR<RouteMaxJourneyTimeChange[]>(
    `useRouteMaxJourneyTimeChangeData/${insightId}`,
    () =>
      dataTrpc.routeAnomaly.get.query({
        orgId: insightId,
      }) as Promise<RouteMaxJourneyTimeChange[]>,
    {
      refreshInterval,
    }
  );
  return dataHandler;
}
