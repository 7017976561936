import { Box, Checkbox, Flex, Text } from '@chakra-ui/react';
import { useCallback, useMemo, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import performanceStatsOptions from '../../constants/performanceStatsOptions';
import useSitesPerformanceOverviewData from '../../data/useSitesPerformanceOverviewData';
import { useAnalytics } from '../../hooks/analytics/useAnalytics';
import { SiteOverviewModel, SummaryModel } from '../../types/site';
import {
  DataHandlerFeedback,
  hasDataHandlerFeedBack,
} from '../../utils/dataHandler';
import { compareSortValues, CompareSortValuesMode } from '../../utils/sort';
import HomepageMap from '../HomepageMap';
import LayoutNetworkMonitoring, {
  LAST_EDITED_OPTION_VALUE,
  NAME_OPTION_VALUE,
  lastEditedOption,
  nameOption,
} from './LayoutNetworkMonitoring';
import SitesList from './SitesList';
import BaseMap from '../BaseMap';
import WrapComponent from '../WrapComponent';

export default function MonitoringSites({
  insightId,
  showInactiveSites = true,
  Wrapper,
}: {
  insightId: string;
  showInactiveSites?: boolean;
  Wrapper: typeof LayoutNetworkMonitoring;
}) {
  const analytics = useAnalytics();
  const [searchTerm, setSearchTerm] = useState('');
  const handleClearSearch = useCallback(
    () => setSearchTerm(''),
    [setSearchTerm]
  );
  const [selectedSortOption, setSelectedSortOption] = useState(
    showInactiveSites ? LAST_EDITED_OPTION_VALUE : NAME_OPTION_VALUE
  );
  const [sortDirection, setSortDirection] = useState(
    showInactiveSites
      ? CompareSortValuesMode.HIGHEST_FIRST
      : CompareSortValuesMode.LOWEST_FIRST
  );
  const [showInactive, setShowInactive] = useState(false);
  const [selectedSiteId, setSelectedSiteId] = useState<number | null>(null);

  const siteDataHandler = useSitesPerformanceOverviewData(insightId);
  const sortedSites = useMemo(
    () =>
      applySort(siteDataHandler.data?.siteSummaries || [], {
        sortBy: selectedSortOption,
        direction: sortDirection,
      }),
    [siteDataHandler.data?.siteSummaries, selectedSortOption, sortDirection]
  );
  const checkVisibility = useCallback(
    (summary: SiteOverviewModel): boolean => {
      if (!showInactiveSites && !showInactive && !summary.active) {
        return false;
      }
      return (
        !searchTerm
          ? true
          : (summary.siteName &&
              summary.siteName.toLowerCase().indexOf(searchTerm.toLowerCase()) >
                -1) ||
            (summary.siteAddress &&
              summary.siteAddress
                .toLowerCase()
                .indexOf(searchTerm.toLowerCase()) > -1) ||
            (summary.projectName &&
              summary.projectName
                .toLowerCase()
                .indexOf(searchTerm.toLowerCase()) > -1)
      ) as boolean;
    },
    [searchTerm, showInactive, showInactiveSites]
  );
  const enabledSortOptions =
    sortedSites.reduce(
      (all, s) =>
        all.concat(
          s.summaryModels
            ?.map((m) => m.summaryType!)
            ?.filter((t) => all.indexOf(t) < 0) || []
        ),
      [] as string[]
    ) || [];
  const sitesDataHandlerFeedBack = hasDataHandlerFeedBack(siteDataHandler);

  const toggleShowInactive = () => {
    setShowInactive((inactive) => {
      analytics.track('Site List Filtered', {
        referrer: 'NetworkMonitoring',
        active: !inactive,
      });
      return !inactive;
    });
  };
  const mapComponentProps = useMemo(
    () => ({
      sites: siteDataHandler.data?.siteSummaries ?? [],
      selectedSiteId,
      checkVisibility: (summary) => {
        if (!showInactive && !summary.active) {
          return false;
        }
        return checkVisibility(summary);
      },
      setSelectedSiteId,
    }),
    [siteDataHandler.data, selectedSiteId, showInactive, checkVisibility]
  );
  const mapComponent = (
    <WrapComponent
      Wrapper={BaseMap}
      Component={HomepageMap}
      componentProps={mapComponentProps}
    />
  );

  return (
    <Wrapper
      insightId={insightId}
      searchTerm={searchTerm}
      setSearchTerm={setSearchTerm}
      sortOptions={[
        nameOption,
        ...(showInactiveSites
          ? [lastEditedOption]
          : Object.values(performanceStatsOptions).map((o) => ({
              sortOptionMessage: (o as any).sortOptionMessage,
              value: (o as any).value,
              enabled: enabledSortOptions.indexOf((o as any).value) >= 0,
            }))),
      ]}
      selectedSortOption={selectedSortOption}
      setSelectedSortOption={setSelectedSortOption}
      sortDirection={sortDirection}
      setSortDirection={setSortDirection}
      sidebarContent={
        <>
          <Flex
            alignItems="center"
            justifyContent="space-between"
            width="full"
            paddingX={4}
            paddingBottom={4}
          >
            <Text fontSize="sm" margin={0}>
              <FormattedMessage
                defaultMessage="Past 24 hours"
                id="+srIX5"
                description="Past 24 hours time period"
              />
            </Text>
            <Checkbox
              size="lg"
              isChecked={showInactive}
              onChange={toggleShowInactive}
              data-testid="search-bar-show-inactive"
            >
              <Box fontSize="xs">
                {showInactiveSites ? (
                  <FormattedMessage
                    defaultMessage="Show inactive on the map"
                    id="m6dgyO"
                    description="Show inactive route on the map checkbox label"
                  />
                ) : (
                  <FormattedMessage
                    defaultMessage="Show inactive"
                    id="CsolMH"
                    description="Show inactive route checkbox label"
                  />
                )}
              </Box>
            </Checkbox>
          </Flex>
          {sitesDataHandlerFeedBack ? (
            <DataHandlerFeedback dataHandlersParam={siteDataHandler} />
          ) : (
            <SitesList
              checkVisibility={checkVisibility}
              sites={sortedSites}
              clearSearch={handleClearSearch}
              insightId={insightId}
              searchTerm={searchTerm}
              selectedSiteId={selectedSiteId}
              setSelectedSiteId={setSelectedSiteId}
            />
          )}
        </>
      }
      mapContent={
        sitesDataHandlerFeedBack ? (
          <DataHandlerFeedback dataHandlersParam={siteDataHandler} />
        ) : (
          mapComponent
        )
      }
    />
  );
}

function getValueBySummaryType(type: string, summaries: SummaryModel[] = []) {
  const summaryMatch = summaries.find(
    (summary) => summary.summaryType === type
  );
  return summaryMatch?.value;
}

export function applyFilter(
  summaries: SiteOverviewModel[],
  params = {
    searchTerm: '',
  }
) {
  return !params.searchTerm
    ? summaries
    : summaries.filter(
        (summary) =>
          (summary.siteName &&
            summary.siteName
              .toLowerCase()
              .indexOf(params.searchTerm.toLowerCase()) > -1) ||
          (summary.siteAddress &&
            summary.siteAddress
              .toLowerCase()
              .indexOf(params.searchTerm.toLowerCase()) > -1) ||
          (summary.projectName &&
            summary.projectName
              .toLowerCase()
              .indexOf(params.searchTerm.toLowerCase()) > -1)
      );
}

export function applySort(
  summaries: SiteOverviewModel[],
  params: {
    sortBy: string;
    direction: CompareSortValuesMode;
  }
) {
  const filteredSorted = summaries.sort((summaryA, summaryB) => {
    if (params.sortBy === NAME_OPTION_VALUE) {
      return params.direction === CompareSortValuesMode.LOWEST_FIRST
        ? summaryA.siteName!.localeCompare(summaryB.siteName!)
        : -summaryA.siteName!.localeCompare(summaryB.siteName!);
    }
    if (params.sortBy === LAST_EDITED_OPTION_VALUE) {
      return params.direction === CompareSortValuesMode.LOWEST_FIRST
        ? summaryA.lastEdited - summaryB.lastEdited
        : summaryB.lastEdited - summaryA.lastEdited;
    }
    if (summaryA.active !== summaryB.active) {
      return summaryA.active ? -1 : 1;
    }
    if (summaryA.numberOfRoutes === 0 && summaryB.numberOfRoutes! > 0) {
      return -1;
    }
    if (summaryB.numberOfRoutes === 0 && summaryA.numberOfRoutes! > 0) {
      return 1;
    }
    const summaryValueA = getValueBySummaryType(
      params.sortBy,
      summaryA.summaryModels
    );
    const summaryValueB = getValueBySummaryType(
      params.sortBy,
      summaryB.summaryModels
    );
    return compareSortValues(summaryValueA, summaryValueB, params.direction);
  });
  return filteredSorted;
}
