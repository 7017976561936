import { useState } from 'react';
import useSWR, { useSWRConfig } from 'swr';
import confirmMFACode from '../api/onboard/confirm-mfa-code';
import {
  CreateAccountParams,
  MFACode,
  OnboardStatus,
  PhoneNumber,
} from '../api/onboard/constants';
import createAccount from '../api/onboard/create-account';
import setPhoneNumber from '../api/onboard/set-phone-number';
import verifyToken, { decodeToken } from '../api/onboard/token';
import { ServerError } from '../types/error';
import { envConfig } from '../config/envConfig';
import {
  ONBOARD_USER_SWR_CACHE_KEY,
  USER_SWR_CACHE_KEY,
} from './useCurrentUserData';

const ONBOARD_SWR_CACHE_KEY = `${envConfig.API_URL}/api/v1/onboard`;

export function useVerifyToken(token?: string) {
  const { data, error } = useSWR(
    token ? ONBOARD_SWR_CACHE_KEY : null,
    token ? verifyToken(token) : null,
    {
      shouldRetryOnError: false,
      revalidateOnFocus: false,
      revalidateOnReconnect: false,
      revalidateIfStale: false,
    }
  );
  if (!token) {
    return {
      data: undefined,
      error: undefined,
      isLoading: false,
      decoded: { hasError: false, userId: 0, email: '' },
    };
  }
  const decoded = decodeToken(token);
  const combinedError = error || decoded.hasError;

  return {
    data,
    error: combinedError,
    isLoading: !data && !combinedError,
    decoded,
  };
}

export function useCreateAccount(token?: string) {
  const { mutate } = useSWRConfig();
  const [error, setError] = useState<ServerError | null>(null);
  const response = useSWR<OnboardStatus | null>(
    token ? ONBOARD_SWR_CACHE_KEY : null,
    token ? () => null : null,
    {
      shouldRetryOnError: false,
      revalidateOnFocus: false,
      revalidateOnReconnect: false,
      revalidateIfStale: false,
    }
  );

  const exec = async (ps: CreateAccountParams) => {
    if (token) {
      try {
        setError(null);
        const result = await createAccount(ps, token);
        response.mutate(result, { revalidate: true });
        mutate(USER_SWR_CACHE_KEY);
        mutate(ONBOARD_USER_SWR_CACHE_KEY);
        return true;
      } catch (e) {
        setError(e as ServerError);
        return false;
      } finally {
        setTimeout(() => {
          setError(null);
        }, 1000);
      }
    }
    return false;
  };

  return [
    exec,
    {
      data: response.data,
      error,
      isLoading: !response.data && !error,
    },
  ] as const;
}

export function useSetPhoneNumber() {
  const { mutate } = useSWRConfig();
  const [error, setError] = useState<ServerError | null>(null);
  const response = useSWR<OnboardStatus | null>(
    ONBOARD_SWR_CACHE_KEY,
    () => null,
    {
      shouldRetryOnError: false,
      revalidateOnFocus: false,
      revalidateOnReconnect: false,
      revalidateIfStale: false,
    }
  );

  const exec = async (phoneNumber: PhoneNumber, token?: string) => {
    try {
      setError(null);
      const result = await setPhoneNumber(phoneNumber, token);
      response.mutate(result, { revalidate: true });
      mutate(USER_SWR_CACHE_KEY);
      mutate(ONBOARD_USER_SWR_CACHE_KEY);
      return true;
    } catch (e) {
      setError(e as ServerError);
      return false;
    } finally {
      setTimeout(() => {
        setError(null);
      }, 1000);
    }
  };

  return [
    exec,
    {
      data: response.data,
      error,
      isLoading: !response.data && !error,
    },
  ] as const;
}

export function useConfirmMFACode() {
  const { mutate } = useSWRConfig();
  const [error, setError] = useState<ServerError | null>(null);
  const response = useSWR<OnboardStatus | null>(
    ONBOARD_SWR_CACHE_KEY,
    () => null,
    {
      shouldRetryOnError: false,
      revalidateOnFocus: false,
      revalidateOnReconnect: false,
      revalidateIfStale: false,
    }
  );

  const exec = async (code: MFACode, token?: string) => {
    try {
      setError(null);
      const result = await confirmMFACode(code, token);
      response.mutate(result, { revalidate: true });
      mutate(USER_SWR_CACHE_KEY);
      mutate(ONBOARD_USER_SWR_CACHE_KEY);
      return true;
    } catch (e) {
      setError(e as ServerError);
      return false;
    } finally {
      setTimeout(() => {
        setError(null);
      }, 1000);
    }
  };

  return [
    exec,
    {
      data: response.data,
      error,
      isLoading: !response.data && !error,
    },
  ] as const;
}
