import { WrapItem, Tag, TagLabel, TagCloseButton } from '@chakra-ui/react';

interface Props {
  label: string;
  onRemove: (label: string) => void;
}

const SelectTag = ({ label, onRemove }: Props) => (
  <WrapItem>
    <Tag borderRadius="md" fontWeight="normal">
      <TagLabel>{label}</TagLabel>
      <TagCloseButton cursor="pointer" onClick={() => onRemove(label)} />
    </Tag>
  </WrapItem>
);

export default SelectTag;
