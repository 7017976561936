import { defineMessage, MessageDescriptor } from 'react-intl';
import { UNIT_SYSTEM_TYPE_IMPERIAL } from '../constants/serverConstants';
import {
  MAX_DISTANCE,
  MIN_DISTANCE,
  METRIC_TO_IMPERIAL_FACTOR,
} from '../constants/clientConstants';
import { Route } from '../types/route';

export function validateRequired(value: string): MessageDescriptor | null {
  if (value === '') {
    return defineMessage({
      defaultMessage: 'This field is required',
      id: 'tdHzXb',
      description: 'Error message for empty required field.',
    });
  }
  return null;
}

export function validateNumeric(value: string): MessageDescriptor | null {
  if (Number.isNaN(parseInt(value, 10))) {
    return defineMessage({
      defaultMessage: 'Only numbers are allowed',
      id: 'ChtPvh',
      description:
        'Error message for numeric input field with non-numeric value.',
    });
  }
  return null;
}

export function validateRouteName(
  newRouteName: string,
  otherRoutes: Route[]
): MessageDescriptor | null {
  const requiredResult = validateRequired(newRouteName);
  if (requiredResult) {
    return requiredResult;
  }
  if (
    otherRoutes.some(
      (route) => route.name.toLowerCase() === newRouteName.toLowerCase()
    )
  ) {
    return defineMessage({
      defaultMessage: 'Route name already exists.',
      id: 'RU0yPs',
      description: 'validation message for duplicated route name',
    });
  }
  return null;
}

export function validatorWithFormatter(
  checkErrorFunction: (...params: any) => MessageDescriptor | null,
  formatMessageFunction: (errorMessage: MessageDescriptor) => string
): (value: unknown) => string | undefined {
  return (value) => {
    const errorMessage = checkErrorFunction(value);
    return errorMessage ? formatMessageFunction(errorMessage) : undefined;
  };
}

export const validatePolylineLength = (
  distance: number,
  siteUnit: string,
  queueEnabled = true
): string | null => {
  const isImperial = UNIT_SYSTEM_TYPE_IMPERIAL === siteUnit;
  let distanceCal = distance;
  let maxDistance = MAX_DISTANCE;
  let minDistance = MIN_DISTANCE;
  if (isImperial) {
    distanceCal = distance * METRIC_TO_IMPERIAL_FACTOR;
    maxDistance = MAX_DISTANCE * METRIC_TO_IMPERIAL_FACTOR;
    minDistance = MIN_DISTANCE * METRIC_TO_IMPERIAL_FACTOR;
  }
  const maxDistanceDelta = distanceCal - maxDistance;
  const minDistanceDelta = minDistance - distanceCal;
  if (distanceCal > maxDistance && queueEnabled) {
    return `Maximum route length with queue monitoring is ${
      isImperial ? (maxDistance / 1000).toFixed(3) : maxDistance / 1000
    } ${isImperial ? 'miles' : 'kilometres'}.
    Please reduce the length of your route by ${
      isImperial ? (maxDistanceDelta / 1000).toFixed(3) : maxDistanceDelta
    } ${isImperial ? 'miles' : 'meters'} or contact us for custom monitoring.`;
  }
  if (distanceCal < minDistance) {
    return `Minimum route length for your licence should be more than ${
      isImperial ? (minDistance / 1000).toFixed(3) : minDistance
    } ${isImperial ? 'miles' : 'meters'}. 
    Please increase the length of your route by ${
      isImperial ? (minDistanceDelta / 1000).toFixed(3) : minDistanceDelta
    } ${isImperial ? 'miles' : 'meters'}.`;
  }
  return null;
};
