import useSWR from 'swr';
import { wktToPointArray } from '@lib/gis';
import type { Incident } from '@data-pipeline/lib-collection/src/types/incidents';
import { useMemo } from 'react';
import { dataTrpc } from '../config/trpc';
import { getBoundsWithMinDiameter } from '../utils/routes';
import useRoutesData from './useRoutesData';
import useSiteView from '../hooks/useSiteView';
import useFeatureSwitch, {
  FeatureSwitchState,
} from '../hooks/useFeatureSwitch';
import { FEATURE_SHOW_INCIDENTS_VIEW_SITE } from '../constants/features';

export default function useIncidentsData(suspense = false) {
  const siteViewState = useSiteView();
  if (!siteViewState) {
    throw new Error('siteViewState is undefined');
  }
  const { data: routes } = useRoutesData(siteViewState.state.siteId);
  const bounds = useMemo(
    () =>
      getBoundsWithMinDiameter(
        (routes?.map((r) => wktToPointArray(r.wkt)) || []).reduce(
          (acc, val) => acc.concat(val),
          []
        )
      ),
    [routes]
  );
  const featureShowIncidentsSelector = useFeatureSwitch(
    FEATURE_SHOW_INCIDENTS_VIEW_SITE
  );
  const incidentsQueryParams =
    !bounds || featureShowIncidentsSelector !== FeatureSwitchState.ON
      ? null
      : {
          siteId: `${siteViewState.state.siteId}`,
          period: {
            period: siteViewState.state.period.type,
            ...(siteViewState.state.period.type === 'TIME_PERIOD_CUSTOM'
              ? {
                  from: siteViewState.state.period.startDate,
                  to: siteViewState.state.period.endDate,
                }
              : {}),
          },
          bounds: {
            nw: { lat: bounds.sw.lat, lng: bounds.sw.lng },
            se: { lat: bounds.ne.lat, lng: bounds.ne.lng },
          },
        };
  const { data, isValidating } = useSWR<Incident[]>(
    incidentsQueryParams,
    (params) =>
      dataTrpc.viewData.getViewIncidents
        .query(params)
        .then((response) =>
          (response || [])
            .filter(
              (d) =>
                d.type !== 'Jam' &&
                d.datasource === 'TomTom' &&
                d.type !== 'RoadClosed' &&
                d.type !== 'RoadWorks' &&
                d.type !== 'LaneClosed'
            )
            .map((d) => ({ ...d, endTime: d.lastReportedTime }))
        ),
    {
      suspense,
    }
  );
  return {
    data,
    error: undefined,
    isValidating,
  };
}
