import { useRef, useState } from 'react';
import {
  Box,
  Button,
  HStack,
  Text,
  useDisclosure,
  VStack,
} from '@chakra-ui/react';
import { FormattedMessage, useIntl } from 'react-intl';
import ContextMenu, {
  ContextMenuGroupItemConfig,
  ContextMenuItemConfig,
} from 'design-system/molecules/context-menu';
import { Chart } from 'highcharts';
import InsightChart from '../InsightChart';
import ChartEditableAnnotationLayer from './ChartEditableAnnotationLayer';
import { Annotation } from '../../types/annotations';
import { CreateBaselineModal } from './CreateBaselineModal';

export function SiteImpactChartPanelContent({
  baselines,
  baselineRequests,
  createAnnotation,
  resendAnnotationAlert,
  selectedBaselineId,
  setSelectedBaselineId,
  createBaseline,
  ...chartProps
}: Omit<
  React.ComponentProps<typeof InsightChart>,
  'actions' | 'extraConfig' | 'callback'
> & {
  baselines: { baselineId: string; name: string; isDefault: boolean }[];
  baselineRequests: { jobId: string; name: string }[];
  createAnnotation: (annotation: Partial<Annotation>) => Promise<void>;
  resendAnnotationAlert: (annotationId: number) => Promise<void>;
  setSelectedBaselineId: (baselineId: string) => void;
  selectedBaselineId?: string;
  createBaseline: (input: {
    name: string;
    startDate: string;
    endDate: string;
    description?: string;
  }) => Promise<void>;
}) {
  const [internalChart, setInternalChart] = useState<Chart | null>(null);
  const [isEditingAnnotation, setEditingAnnotation] = useState(false);
  const [showAnnotation, setShowAnnotation] = useState<boolean>(true);
  const chartContainerRef = useRef<HTMLDivElement>(null);
  const { formatMessage } = useIntl();

  const { isOpen, onClose, onOpen } = useDisclosure();

  const baselineOptions:
    | ContextMenuItemConfig[]
    | ContextMenuGroupItemConfig[] = (baselines ?? []).map((baseline) => ({
    key: baseline.baselineId.toString(),
    label:
      baseline.name ||
      formatMessage({ defaultMessage: 'Site baseline', id: 'ujO9kZ' }),
    action: () => {
      setSelectedBaselineId(baseline.baselineId.toString());
    },
  }));
  const pendingGroup: ContextMenuGroupItemConfig = {
    label: formatMessage({ defaultMessage: 'Pending baselines', id: 'bTlxwh' }),
    key: 'pending',
    members: baselineRequests.map((request) => ({
      key: request.jobId,
      label:
        request.name ||
        formatMessage({ defaultMessage: 'Site baseline', id: 'ujO9kZ' }),
    })),
  };
  if (baselineRequests.length > 0) {
    baselineOptions.push(pendingGroup);
  }
  const selectedBaseline = selectedBaselineId
    ? baselineOptions.find((b) => b.key === selectedBaselineId)
    : baselineOptions[0];

  return (
    <>
      <CreateBaselineModal
        createBaseline={createBaseline}
        isOpen={isOpen}
        onClose={onClose}
      />
      <InsightChart
        {...chartProps}
        chartContainerRef={chartContainerRef}
        annotations={showAnnotation ? chartProps.annotations : []}
        chartConfig={
          !isEditingAnnotation
            ? chartProps.chartConfig
            : {
                ...chartProps.chartConfig,
                zoomingType: undefined,
                disableCrosshair: true,
                disableTooltip: true,
              }
        }
        actions={
          internalChart && (
            <ChartEditableAnnotationLayer
              chart={internalChart}
              timeZone={chartProps.timeZone}
              isEditingAnnotation={isEditingAnnotation}
              setEditingAnnotation={setEditingAnnotation}
              showAnnotation={showAnnotation}
              setShowAnnotation={setShowAnnotation}
              createAnnotation={createAnnotation}
              resendAnnotationAlert={resendAnnotationAlert}
            />
          )
        }
        callback={setInternalChart}
        extraConfig={
          selectedBaseline ? (
            <HStack gap={2} pt={1}>
              <Box flexGrow={1}>
                <Text as="span" style={{ whiteSpace: 'nowrap' }}>
                  <FormattedMessage
                    defaultMessage="Performance compared to:"
                    id="n3zNJn"
                  />
                </Text>
              </Box>
              <ContextMenu
                triggerLabel={selectedBaseline.label}
                items={baselineOptions}
                actions={
                  <VStack w="full" alignItems="flex-start" px={2} pt={2}>
                    <Button size="xs" colorScheme="greenDark" onClick={onOpen}>
                      <FormattedMessage
                        defaultMessage="New baseline"
                        id="qUa6D8"
                      />
                    </Button>
                  </VStack>
                }
              />
            </HStack>
          ) : undefined
        }
      />
    </>
  );
}
