import { Box, Input, List, ListItem, Text } from '@chakra-ui/react';
import { ChangeEvent, useState } from 'react';
import { useIntl } from 'react-intl';
import { Country } from './types';

export type Props = {
  data: readonly Country[];
  onChange: (args: Country) => void;
  selectedCountry: Country;
};

const CountrySearch = ({ data, onChange, selectedCountry }: Props) => {
  const { formatMessage } = useIntl();
  const [filteredList, setFilteredList] = useState(data);

  const placeholder = formatMessage({
    id: 'U8pfG2',
    description: 'Country select menu placeholder text',
    defaultMessage: 'Select a country',
  });

  const handleSearch = (event: ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value.toLowerCase();
    const result =
      data.filter((item) => item.name.toLowerCase().includes(value)) || [];
    setFilteredList(result);
  };

  return (
    <Box
      my={1}
      maxH="xs"
      bg="white"
      width="full"
      zIndex="dropdown"
      height="auto"
      overflow="auto"
      borderRadius="lg"
      position="absolute"
      boxShadow="md"
    >
      <Box position="sticky" top="0" p={4} bg="white">
        <Input
          size="sm"
          type="search"
          borderRadius="md"
          autoComplete="off"
          placeholder={placeholder}
          onChange={(event) => handleSearch(event)}
          _focusWithin={{ borderColor: 'secondary' }}
          _invalid={{ bg: 'white', borderColor: 'gray.50' }}
        />
      </Box>

      <List>
        {filteredList?.map((country: Country) => {
          const selectedStyles =
            country.code === selectedCountry.code
              ? {
                  backgroundColor: 'gray.50',
                  color: 'gray.700',
                }
              : {};
          return (
            <ListItem
              key={country.code}
              paddingY={2}
              cursor="pointer"
              fontWeight="500"
              textTransform="capitalize"
              onClick={() => {
                onChange(country);
              }}
              transition="all 0.125s ease"
              _hover={{ bg: 'gray.50', color: 'gray.900' }}
              {...selectedStyles}
            >
              <Text as="span" mx={4}>
                {country.flag}
              </Text>
              <Text as="span">{country.name}</Text>
            </ListItem>
          );
        })}
      </List>
    </Box>
  );
};

export default CountrySearch;
