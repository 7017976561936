import { createTRPCProxyClient, httpLink } from '@trpc/client';
import type { AppRouter, AppDataRouter } from '@webapp/bff';
import superjson from 'superjson';
import { envConfig } from './envConfig';

export const trpc = createTRPCProxyClient<AppRouter>({
  transformer: superjson,
  links: [
    httpLink({
      url: `${envConfig.API_URL}/bff/trpc`,
      fetch(url, options) {
        return fetch(url, {
          ...options,
          credentials: 'include',
        });
      },
      headers(opts) {
        const token = opts.op?.context?.token;
        const authHeader = token
          ? {
              Authorization: `Bearer ${token}`,
            }
          : {};
        return authHeader;
      },
    }),
  ],
});

export const dataTrpc = createTRPCProxyClient<AppDataRouter>({
  links: [
    httpLink({
      url: `${envConfig.API_URL}/bff/data`,
      fetch(url, options) {
        return fetch(url, {
          ...options,
          credentials: 'include',
        });
      },
    }),
  ],
});
