import useSWR from 'swr';
import { envConfig } from '../config/envConfig';

export interface UseMapInsightsDataParams {
  insightId?: string;
  siteId?: number;
  panelId?: number;
}

export interface MapInsightsData {
  count?: any;
  heavyCount?: any;
  siteImpactDelay?: any;
  speed?: any;
  timeDelay?: any;
}

export default function useMapInsightsData({
  insightId,
  siteId,
  panelId,
}: UseMapInsightsDataParams) {
  const { data, error, isValidating } = useSWR<MapInsightsData>(
    insightId && siteId && panelId
      ? `${envConfig.API_URL}/api/v1/insights/${insightId}/panels/${panelId}?siteId=${siteId}`
      : null,
    {
      refreshInterval: 5 * 60 * 1000,
    }
  );
  return {
    data,
    error,
    isValidating,
  };
}
