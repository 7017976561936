import { AddIcon, ArrowForwardIcon } from '@chakra-ui/icons';
import { Box, Button, Flex, HStack, IconButton, Input } from '@chakra-ui/react';
import {
  FieldPathByValue,
  FieldValues,
  UseFormReturn,
  useFieldArray,
} from 'react-hook-form';
import { DeleteIcon } from 'design-system/atoms/custom-icons';
import { FormattedMessage } from 'react-intl';

interface TimeWindow {
  startTime: string;
  endTime: string;
}

const defaultFieldValue: TimeWindow = { startTime: '00:00', endTime: '06:00' };

export default function TimeSelection<
  TFieldValues extends FieldValues,
  TPath extends FieldPathByValue<TFieldValues, TimeWindow[]>,
>({
  label,
  fieldName: fieldNameParameter,
  formManager: formManagerParameter,
}: {
  label: string;
  fieldName: TPath;
  formManager: UseFormReturn<TFieldValues>;
}) {
  // form types are not correctly inferred, so we need to cast it.
  const formManager = formManagerParameter as unknown as UseFormReturn<{
    typeSafeFieldPath: TimeWindow[];
  }>;
  const fieldName = fieldNameParameter as 'typeSafeFieldPath';

  const { control, register, getFieldState } = formManager;
  const { fields, remove, append } = useFieldArray({
    control,
    name: fieldName,
  });
  const hasTimeSelected = fields.length > 0;
  return (
    <Flex
      padding={2}
      gap={2}
      flexDirection={{ base: 'column', lg: 'row' }}
      alignItems={{ base: 'stretch', lg: 'center' }}
      background="white"
      borderRadius="2xl"
    >
      <Box flex="0" margin="-3px">
        <Button
          variant="solid"
          colorScheme="purple"
          fontWeight="500"
          size="xs"
          width="46px"
          height="26px"
          border="solid 3px white"
          borderRadius="9px"
          borderColor={hasTimeSelected ? 'purple.300' : 'white'}
          backgroundColor={hasTimeSelected ? 'purple.700' : 'purple.100'}
          color={hasTimeSelected ? 'white' : 'purple.900'}
          _hover={{
            backgroundColor: hasTimeSelected ? 'purple.600' : 'purple.200',
            color: hasTimeSelected ? 'white' : 'purple.900',
          }}
          onClick={() => {
            if (hasTimeSelected) {
              remove();
            } else {
              append(defaultFieldValue);
            }
          }}
        >
          {label}
        </Button>
      </Box>
      {fields.map((timeField, timeFieldIndex) => (
        <Box key={timeField.id} flex="1" maxWidth="100%">
          <HStack spacing={2}>
            <Input
              type="time"
              size="sm"
              variant="outline"
              isInvalid={
                getFieldState(`${fieldName}.${timeFieldIndex}.startTime`)
                  .invalid
              }
              {...register(`${fieldName}.${timeFieldIndex}.startTime`, {
                required: true,
              })}
            />
            <Box>
              <ArrowForwardIcon width="24px" height="24px" color="gray.500" />
            </Box>
            <Input
              type="time"
              size="sm"
              variant="outline"
              isInvalid={
                getFieldState(`${fieldName}.${timeFieldIndex}.endTime`).invalid
              }
              {...register(`${fieldName}.${timeFieldIndex}.endTime`, {
                required: true,
              })}
            />
            {timeFieldIndex === 0 ? (
              <Box
                display={{ base: 'none', lg: 'block' }}
                alignSelf="stretch"
                borderLeft="2px solid"
                borderLeftColor="gray.300"
              />
            ) : (
              <Box>
                <IconButton
                  type="button"
                  size="xs"
                  onClick={() => remove(timeFieldIndex)}
                  aria-label="Remove time window"
                  fontSize="16px"
                  icon={<DeleteIcon />}
                  isRound
                  variant="solid"
                >
                  Remove
                </IconButton>
              </Box>
            )}
          </HStack>
        </Box>
      ))}
      {fields.length === 1 && (
        <Box flex="1">
          <Button
            type="button"
            onClick={() => append(defaultFieldValue)}
            size="xs"
            variant="outline"
            leftIcon={<AddIcon fontSize="8px" />}
          >
            <FormattedMessage
              defaultMessage="Additional override"
              id="r/yB3/"
            />
          </Button>
        </Box>
      )}
    </Flex>
  );
}
