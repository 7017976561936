import { VStack, HStack, Button, Box, Textarea } from '@chakra-ui/react';
import { useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import RadioGroup from 'design-system/molecules/radio-group';
import { RoutePollingStatusFailureType } from '../../data/useRoutePollingStatusData';

interface ResponseFieldsetProps<
  TStateType extends ResponseFieldsetState = ResponseFieldsetState,
> {
  responseState: TStateType;
  setResponseState: (newResponse: TStateType) => void;
}
interface ResponseFieldsetState {
  responseType: Exclude<RoutePollingStatusFailureType, 'ROAD_LAYOUT'>;
  responseComment?: string;
}
interface RoadLayoutFieldsetState extends ResponseFieldsetState {
  responseType: 'ROAD_LAYOUT_UNAVAILABLE' | 'ROAD_LAYOUT_UPDATE';
}
interface OtherCauseFieldsetState extends ResponseFieldsetState {
  responseType: 'UNKNOWN_CAUSE' | 'KNOWN_CAUSE';
}
function IsRoadLayoutFieldsetState(
  state: ResponseFieldsetState
): state is RoadLayoutFieldsetState {
  return ['ROAD_LAYOUT_UNAVAILABLE', 'ROAD_LAYOUT_UPDATE'].includes(
    state.responseType
  );
}
function IsOtherCauseFieldsetState(
  state: ResponseFieldsetState
): state is OtherCauseFieldsetState {
  return ['UNKNOWN_CAUSE', 'KNOWN_CAUSE'].includes(state.responseType);
}

export default function MoreDetailsForm({
  currentResponse,
  setNewResponse,
  cancelEditing,
}: {
  currentResponse: {
    responseType: RoutePollingStatusFailureType | 'OTHER';
    responseComment?: string;
  };
  setNewResponse: (newResponse: {
    responseType: RoutePollingStatusFailureType;
    responseComment?: string;
  }) => void;
  cancelEditing?: () => void;
}) {
  const initialState = getInitialStateForMoreDetailsForm(currentResponse);
  const [newResponseState, setNewResponseState] = useState(initialState);
  return (
    <VStack alignItems="flex-start" spacing="4" width="full">
      <MoreDetailsFieldset
        responseState={newResponseState}
        setResponseState={setNewResponseState}
      />
      <HStack spacing="2">
        <Button
          onClick={() => setNewResponse(newResponseState)}
          variant="solid"
          colorScheme="greenDark"
          size="sm"
        >
          <FormattedMessage id="wSZR47" defaultMessage="Submit" />
        </Button>
        {cancelEditing && (
          <Button onClick={cancelEditing} variant="outline" size="sm">
            <FormattedMessage id="47FYwb" defaultMessage="Cancel" />
          </Button>
        )}
      </HStack>
    </VStack>
  );
}
function MoreDetailsFieldset({
  responseState,
  setResponseState,
}: ResponseFieldsetProps) {
  if (IsRoadLayoutFieldsetState(responseState)) {
    return (
      <RoadLayoutFieldset
        responseState={responseState}
        setResponseState={setResponseState}
      />
    );
  }
  if (IsOtherCauseFieldsetState(responseState)) {
    return (
      <OtherCauseFieldset
        responseState={responseState}
        setResponseState={setResponseState}
      />
    );
  }
  return (
    <ClosureFieldset
      responseState={responseState}
      setResponseState={setResponseState}
    />
  );
}
function ClosureFieldset({
  responseState,
  setResponseState,
}: ResponseFieldsetProps) {
  const { formatMessage } = useIntl();
  return (
    <VStack alignItems="flex-start" spacing="2" width="full">
      <Box fontSize="sm">
        <FormattedMessage
          id="oVthjd"
          defaultMessage="Is there anything else you would like to tell us about this closure?"
        />
        <Box color="gray.500">
          <FormattedMessage
            id="UWGRoX"
            defaultMessage="(E.g.: Closures are expected on weekends until end of April.)"
          />
        </Box>
      </Box>
      <Textarea
        value={responseState.responseComment}
        onChange={(e) =>
          setResponseState({
            responseType: responseState.responseType,
            responseComment: e.target.value,
          })
        }
        placeholder={formatMessage({
          id: '7YAVR6',
          defaultMessage: 'Tell us more about the closure...',
        })}
        size="sm"
      />
    </VStack>
  );
}
function OtherCauseFieldset({
  responseState,
  setResponseState,
}: ResponseFieldsetProps<OtherCauseFieldsetState>) {
  const { formatMessage } = useIntl();
  const responseStateType = responseState?.responseType;
  return (
    <RadioGroup
      options={[
        {
          value: 'UNKNOWN_CAUSE',
          label: formatMessage({
            id: 'DD9vQ0',
            defaultMessage: 'I’m unaware of the cause',
          }),
        },
        {
          value: 'KNOWN_CAUSE',
          label: formatMessage({
            id: 'vfbVsZ',
            defaultMessage: 'I know the likely cause',
          }),
          allowComment: true,
        },
      ]}
      selection={responseStateType}
      onSelectionChange={(newValue) =>
        setResponseState({
          responseType: newValue,
          responseComment: '',
        })
      }
      comment={responseState?.responseComment}
      onCommentChange={(newValue) =>
        setResponseState({
          responseType: responseStateType,
          responseComment: newValue,
        })
      }
    />
  );
}
function RoadLayoutFieldset({
  responseState,
  setResponseState,
}: ResponseFieldsetProps<RoadLayoutFieldsetState>) {
  const { formatMessage } = useIntl();
  const responseStateType = responseState?.responseType;
  return (
    <VStack alignItems="flex-start" spacing="4" width="full">
      <Box>
        <FormattedMessage
          id="R4lzbC"
          defaultMessage="This could mean the route is no longer available and should stop monitoring, or needs to be adjusted to match the new road layout. How would you like us to handle it?"
        />
      </Box>
      <RadioGroup
        options={[
          {
            value: 'ROAD_LAYOUT_UNAVAILABLE',
            label: formatMessage({
              id: 'MIDKm0',
              defaultMessage: 'Route is no longer available, stop monitoring',
            }),
          },
          {
            value: 'ROAD_LAYOUT_UPDATE',
            label: formatMessage({
              id: 'OZx4Mc',
              defaultMessage: 'Route needs to be adjusted',
            }),
            allowComment: true,
          },
        ]}
        selection={responseStateType}
        onSelectionChange={(newValue) =>
          setResponseState({
            responseType: newValue,
            responseComment: '',
          })
        }
        comment={responseState?.responseComment}
        onCommentChange={(newValue) =>
          setResponseState({
            responseType: responseStateType,
            responseComment: newValue,
          })
        }
      />
    </VStack>
  );
}
function getInitialStateForMoreDetailsForm(currentResponse: {
  responseType: RoutePollingStatusFailureType | 'OTHER';
  responseComment?: string;
}): ResponseFieldsetState {
  switch (currentResponse.responseType) {
    case 'OTHER':
      return {
        responseType: 'UNKNOWN_CAUSE' as 'UNKNOWN_CAUSE' | 'KNOWN_CAUSE',
        responseComment: currentResponse.responseComment,
      };
    case 'ROAD_LAYOUT':
      return {
        responseType: 'ROAD_LAYOUT_UNAVAILABLE' as
          | 'ROAD_LAYOUT_UNAVAILABLE'
          | 'ROAD_LAYOUT_UPDATE',
        responseComment: currentResponse.responseComment,
      };
    default:
      return {
        responseType: currentResponse.responseType,
        responseComment: currentResponse.responseComment,
      };
  }
}
