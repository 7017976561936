import { FormattedMessage, useIntl } from 'react-intl';
import { Link as ReactRouterLink, useLocation } from 'react-router-dom';
import {
  Link as ChakraLink,
  HStack,
  Text,
  Tooltip,
  VStack,
  useBreakpointValue,
} from '@chakra-ui/react';
import { QuestionIcon } from '@chakra-ui/icons';
import { PlanInsightType, pathSitesPlan } from '../../constants/path';
import {
  MESSAGE_PLAN_INSIGHT_TYPE_DELAY,
  MESSAGE_PLAN_INSIGHT_TYPE_IMPACT,
  MESSAGE_PLAN_INSIGHT_TYPE_SPEED,
  MESSAGE_PLAN_INSIGHT_TYPE_TIME,
  MESSAGE_PLAN_INSIGHT_TYPE_VEHICLE_COUNT,
} from '../../constants/messages';

interface PlanInsightTypeSelectorProps {
  insightId: string;
  currentInsightType: PlanInsightType;
  hasSiteImpact: boolean;
  isCompact?: boolean;
}

export default function PlanInsightTypeSelector(
  props: PlanInsightTypeSelectorProps
) {
  const isSmallScreen = useBreakpointValue({ base: true, lg: false });
  const { isCompact } = props;

  return (
    <VStack spacing={{ base: 1, lg: 2 }} alignItems="flex-start">
      {!isCompact && (
        <Text fontSize="sm" fontWeight="bold" marginBottom={0}>
          <FormattedMessage
            defaultMessage="Aggregated insight type"
            id="ZtokOA"
          />
        </Text>
      )}
      <InsightTypeMenu {...props} isCompact={isCompact || isSmallScreen} />
    </VStack>
  );
}

function InsightTypeMenu({
  insightId,
  currentInsightType,
  hasSiteImpact,
  isCompact,
}: PlanInsightTypeSelectorProps) {
  const { formatMessage } = useIntl();
  const location = useLocation();
  const links = [
    {
      type: PlanInsightType.DELAY,
      label: formatMessage(MESSAGE_PLAN_INSIGHT_TYPE_DELAY),
      tooltipText: formatMessage({
        defaultMessage:
          '<strong>Delay</strong> shows the additional time it takes to travel the entirety of a route compared to regular free-flow conditions.',
        id: 'nnwZV/',
      }),
    },
    {
      type: PlanInsightType.TIME,
      label: formatMessage(MESSAGE_PLAN_INSIGHT_TYPE_TIME),
      tooltipText: formatMessage({
        defaultMessage:
          '<strong>Journey time</strong> is the time it takes to travel from the start to end of defined routes.',
        id: 'rOfXSq',
      }),
    },
    {
      type: PlanInsightType.COUNT,
      label: formatMessage(MESSAGE_PLAN_INSIGHT_TYPE_VEHICLE_COUNT),
      tooltipText: formatMessage({
        defaultMessage:
          '<strong>Vehicle count</strong> provides the number of vehicles recorded to have driven through selected count locations.',
        id: 'kWz4ME',
      }),
    },
    {
      type: PlanInsightType.SPEED,
      label: formatMessage(MESSAGE_PLAN_INSIGHT_TYPE_SPEED),
      tooltipText: formatMessage({
        defaultMessage:
          '<strong>Speed</strong> provides a view of the average speed recorded across an entire route.',
        id: 'nsyKkw',
      }),
    },
    {
      type: PlanInsightType.IMPACT,
      label: formatMessage(MESSAGE_PLAN_INSIGHT_TYPE_IMPACT),
      tooltipText: formatMessage({
        defaultMessage:
          '<strong>Site impact</strong> measures the additional time taken to travel a route compared to expected traffic conditions.',
        id: 'SgHK4Y',
      }),
    },
  ];
  return (
    <HStack spacing={isCompact ? 3 : 4}>
      {links.map((linkConfig) => {
        const isActiveTab = currentInsightType === linkConfig.type;

        return !hasSiteImpact &&
          linkConfig.type === PlanInsightType.IMPACT ? null : (
          <HStack
            key={linkConfig.type}
            spacing={1}
            alignItems="start"
            className="insight-options-root"
          >
            <ChakraLink
              _hover={{ textDecoration: 'none', color: 'gray.900' }}
              fontSize={isCompact ? 'sm' : '2xl'}
              fontWeight={isActiveTab ? 'bold' : 'normal'}
              color={isActiveTab ? 'gray.900' : 'gray.500'}
              borderBottom="solid 1px transparent"
              borderBottomColor={isActiveTab ? 'gray.600' : 'transparent'}
              as={ReactRouterLink}
              state={location.state}
              to={{
                pathname: pathSitesPlan(insightId, linkConfig.type),
                search: location.search,
              }}
            >
              {linkConfig.label}
            </ChakraLink>
            {!isCompact && (
              <Tooltip hasArrow label={linkConfig.tooltipText}>
                <QuestionIcon
                  color={isActiveTab ? 'gray.700' : 'gray.500'}
                  sx={{
                    '.insight-options-root:hover &': {
                      color: 'gray.700',
                    },
                  }}
                />
              </Tooltip>
            )}
          </HStack>
        );
      })}
    </HStack>
  );
}
