import { Box, Flex, Heading, Spinner } from '@chakra-ui/react';
import omit from 'lodash/omit';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import {
  Navigate,
  Route,
  Routes,
  useNavigate,
  useLocation,
} from 'react-router-dom';
import { useIntercom } from 'react-use-intercom';
import {
  CreateAccountParams,
  MFACode,
  OnboardStatus,
  PhoneNumber,
} from '../../api/onboard/constants';
import { pathDashboard, pathLogin } from '../../constants/path';
import { useOnboardUser } from '../../data/useCurrentUserData';
import {
  useConfirmMFACode,
  useCreateAccount,
  useSetPhoneNumber,
  useVerifyToken,
} from '../../data/useOnboard';
import { useAnalytics } from '../../hooks/analytics/useAnalytics';
import { useFailureToast } from '../../hooks/useFailureToast';
import { useSuccessToast } from '../../hooks/useSuccessToast';
import MoovenLogo from '../../svg/mooven-logo.svg?react';
import { persistPostAuthRoles } from '../../utils/roles';
import ConfirmMFACode from './ConfirmMFACode';
import CreateAccount from './CreateAccount';
import SetPhoneNumber from './SetPhoneNumber';
import { ServerError } from '../../types/error';
import { errorReport } from '../../utils/errors';

const Onboarding = () => {
  const { formatMessage } = useIntl();
  const { track } = useAnalytics();
  const navigate = useNavigate();
  const { search, state: locationState } = useLocation();
  const successToast = useSuccessToast();
  const failureToast = useFailureToast();
  const [onboardStatus, setOnboardStatus] = useState<OnboardStatus | null>(
    null
  );
  const { boot } = useIntercom();
  // Memoized to guarantee updates downstream
  // when location changes
  const token = useMemo(() => {
    const params = new URLSearchParams(search);
    return params.get('token') ?? '';
  }, [search]);

  // Memoized via useCallback to prevent unnecessary updates
  // in side effects that use this function as a dependency
  const withToken = useCallback(
    (uri: string) => {
      if (token) {
        return `/onboarding/${uri}?token=${token}`;
      }
      return `/onboarding/${uri}`;
    },
    [token]
  );
  const {
    data: parsedToken,
    error: verifyTokenError,
    decoded,
  } = useVerifyToken(token);

  // Memoized to guarantee users are only fetched when these
  // conditions are met, and thus guarantee updates
  const shouldFetchUser = useMemo(
    () =>
      !token ||
      (onboardStatus?.valid === true &&
        ['MISSING_PHONE_NUMBER', 'MISSING_MFA', 'ACTIVE'].includes(
          onboardStatus.userStatus
        )),
    [token, onboardStatus]
  );

  const { data: user, error: userError } = useOnboardUser({
    shouldFetch: shouldFetchUser,
    token,
  });

  useEffect(() => {
    if (parsedToken) {
      setOnboardStatus(parsedToken);
    }
  }, [parsedToken]);

  useEffect(() => {
    if (user?.accountStatus) {
      const valid = user.accountStatus !== 'INVALID';
      setOnboardStatus({ userStatus: user.accountStatus, valid, roles: [] });
    }
  }, [user?.accountStatus]);

  const [createAccount, { error: createAccountError }] =
    useCreateAccount(token);
  const [setNumber, { error: setPhoneNumberError }] = useSetPhoneNumber();
  const [
    confirmMFACode,
    { error: confirmMFACodeError, data: confirmMFACodeResponse },
  ] = useConfirmMFACode();

  useEffect(() => {
    switch (onboardStatus?.userStatus) {
      case 'INVITED': {
        navigate(withToken('create-account'));
        break;
      }
      case 'MISSING_PHONE_NUMBER': {
        navigate(withToken('set-mfa-device'));
        break;
      }
      case 'MISSING_MFA': {
        navigate(withToken('confirm-mfa-code'));
        break;
      }
      case 'ACTIVE': {
        // this can happen before we process the reply from the confirmMFACode
        // so we need to store roles in this case as well
        if (confirmMFACodeResponse?.roles) {
          persistPostAuthRoles(confirmMFACodeResponse.roles);
        }
        navigate('/');
        break;
      }
      default: {
        break;
      }
    }
  }, [onboardStatus, navigate, withToken, confirmMFACodeResponse?.roles]);

  const isValid = useMemo(() => {
    // If we have a token status, use that
    if (onboardStatus) {
      return onboardStatus.valid;
    }

    // If we don't have one yet, assume it's loading
    if (!token && !user) {
      return true;
    }

    return false;
  }, [token, onboardStatus, user]);

  useEffect(() => {
    boot({
      name: user
        ? `${user.firstName} ${user.lastName} (${user.email}) `
        : `From onboarding (${!decoded.hasError ? decoded.email : ''})`,
      customLauncherSelector: '#intercom-activator',
    });
  }, [user, decoded, boot]);

  const handleCreateAccount = async (params: CreateAccountParams) => {
    const ok = await createAccount(params);
    if (ok) {
      track('User Account Created', {
        referrer: 'Onboarding',
        user: {
          ...omit(params, ['password', 'confirmPassword', 'agreedTerms']),
        },
      });
      successToast({
        title: formatMessage({
          defaultMessage: 'Account created successfully',
          id: 'yTdVMf',
          description: 'Create account success toast',
        }),
      });
    }
  };

  const handleSetPhoneNumber = useCallback(
    async (num: PhoneNumber) => {
      const ok = await setNumber(num, token);
      if (ok) {
        navigate(withToken('confirm-mfa-code'));
        track('MFA Device Set', {
          referrer: 'Onboarding',
          deviceType: 'SMS',
          user,
        });
        successToast({
          title: formatMessage({
            defaultMessage: 'One time code sent via SMS',
            id: '2xJo8P',
            description: 'Set MFA device success toast',
          }),
        });
      }
    },
    [
      setNumber,
      navigate,
      withToken,
      successToast,
      track,
      formatMessage,
      token,
      user,
    ]
  );

  const handleConfirmMFACode = async (code: MFACode) => {
    const ok = await confirmMFACode(code, token);
    if (ok) {
      track('MFA Code Enrolled', {
        referrer: 'Onboarding',
        deviceType: 'SMS',
        user,
      });
      // The BFF treats the user as logged in at this point
      // albeit without a fingerprint.
      if (confirmMFACodeResponse?.roles) {
        persistPostAuthRoles(confirmMFACodeResponse.roles);
      }
      setTimeout(() => {
        navigate(pathDashboard());
      }, 0);
      successToast({
        title: formatMessage({
          defaultMessage: 'Successfully enrolled MFA device.',
          id: 'CjP8Tw',
          description: 'Enroll MFA device success toast',
        }),
      });
    }
  };

  const handleRequestNewCode = async () => {
    if (user?.phone) {
      const ok = await setNumber(user?.phone);
      if (ok) {
        track('MFA Code Requested', {
          referrer: 'Onboarding',
          deviceType: 'SMS',
          user,
        });
        successToast({
          title: formatMessage({
            defaultMessage: 'One time code sent via SMS',
            id: '2xJo8P',
            description: 'Set MFA device success toast',
          }),
        });
      }
    }
  };

  useEffect(() => {
    if (
      locationState?.fromLogin &&
      onboardStatus?.userStatus === 'MISSING_MFA' &&
      user?.phone
    ) {
      handleSetPhoneNumber(user?.phone);
    }
  }, [
    handleSetPhoneNumber,
    locationState?.fromLogin,
    onboardStatus?.userStatus,
    user?.phone,
  ]);

  const userErrorRef = useRef();
  useEffect(() => {
    if (userError && userErrorRef.current !== userError) {
      userErrorRef.current = userError;
      failureToast({
        title: formatMessage({
          defaultMessage: 'Unauthorized',
          id: 'VGO90H',
          description: 'Onboarding fetch user error toast',
        }),
      });
    }
  }, [userError, failureToast, formatMessage]);

  const createAccountErrorRef = useRef<ServerError>();
  useEffect(() => {
    if (
      createAccountError &&
      createAccountErrorRef.current !== createAccountError
    ) {
      createAccountErrorRef.current = createAccountError;
      if (
        createAccountError.message.includes(
          'Password requirements were not met'
        )
      ) {
        failureToast({
          title: formatMessage({
            defaultMessage:
              'Please ensure your password has at least 8 characters, a lowercase letter, an uppercase letter, a number, and no parts of your email.',
            id: 'gIFPvq',
            description: 'Set password strength error message',
          }),
        });
      } else {
        failureToast({
          title: formatMessage({
            defaultMessage:
              'There was an issue creating your account. Try again or contact us.',
            id: 'FhAYj2',
            description: 'Onboarding create account error toast',
          }),
        });
      }
    }
  }, [createAccountError, failureToast, formatMessage]);

  const setPhoneNumberErrorRef = useRef<ServerError>();
  useEffect(() => {
    if (
      setPhoneNumberError &&
      setPhoneNumberErrorRef.current !== setPhoneNumberError
    ) {
      setPhoneNumberErrorRef.current = setPhoneNumberError;
      failureToast({
        title: formatMessage({
          defaultMessage:
            'There was an issue setting your phone number. Try again in 30 seconds or contact us.',
          id: 'CKuNHl',
          description: 'Onboarding set phone number error toast',
        }),
      });
    }
  }, [setPhoneNumberError, failureToast, formatMessage]);

  const confirmMFACodeErrorRef = useRef<ServerError>();
  useEffect(() => {
    if (
      confirmMFACodeError &&
      confirmMFACodeErrorRef.current !== confirmMFACodeError
    ) {
      confirmMFACodeErrorRef.current = confirmMFACodeError;
      failureToast({
        title: formatMessage({
          defaultMessage:
            'There was an issue with your MFA code. Try again or request a new code.',
          id: 'E8ot5B',
          description: 'Onboarding confirm mfa code error toast',
        }),
      });
    }
  }, [confirmMFACodeError, failureToast, formatMessage]);

  const verifyTokenErrorRef = useRef();
  useEffect(() => {
    if (verifyTokenError && verifyTokenErrorRef.current !== verifyTokenError) {
      verifyTokenErrorRef.current = verifyTokenError;
      failureToast({
        title: formatMessage({
          defaultMessage:
            'There was an issue with your activation link. Contact support@mooven.com for more help.',
          id: 'qi1z1b',
          description: 'Onboarding verify token error toast',
        }),
      });
      navigate('/login');
    }
  }, [failureToast, formatMessage, navigate, verifyTokenError]);

  const isLoading = !onboardStatus;
  if (isLoading) {
    return (
      <Flex w="100wh" h="100vh" bgColor="white" justify="center" align="center">
        <Spinner />
      </Flex>
    );
  }

  if (!isValid) {
    errorReport.critical('Invalid user returned by BE in onboarding process');
    return <Navigate to={pathLogin()} />;
  }

  const email = (!decoded.hasError ? decoded.email : '') ?? user?.email;

  return (
    <Flex w="100wh" h="100vh" bgColor="white" justify="center" align="center">
      <Box width={{ sm: '768px' }} m={8}>
        <Box w="150px" h="33px">
          <MoovenLogo />
        </Box>
        <Heading fontSize="7xl" mb={2}>
          <FormattedMessage
            defaultMessage="Welcome to Mooven"
            id="r3+oyG"
            description="Welcome to Mooven header"
          />
        </Heading>
        <Routes>
          <Route
            path="/create-account"
            element={
              <CreateAccount
                handleCreateAccount={handleCreateAccount}
                email={email}
              />
            }
          />
          <Route
            path="/set-mfa-device"
            element={
              <SetPhoneNumber handleSetPhoneNumber={handleSetPhoneNumber} />
            }
          />
          <Route
            path="/confirm-mfa-code"
            element={
              <ConfirmMFACode
                handleChangePhoneNumber={() => {
                  setOnboardStatus({
                    ...onboardStatus,
                    userStatus: 'MISSING_PHONE_NUMBER',
                  });
                }}
                handleConfirmMFACode={handleConfirmMFACode}
                handleRequestNewCode={handleRequestNewCode}
                phoneNumber={user?.phone ?? ''}
              />
            }
          />
          <Route path="*" element={<Navigate to="/create-account" />} />
        </Routes>
      </Box>
    </Flex>
  );
};

export default Onboarding;
