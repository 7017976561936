import { useIntl } from 'react-intl';
import { VehicleCountSummary } from '@data-pipeline/api/src/types/vehicle-count';
import Spinner from '../Spinner';
import { useCountDailyProfileData } from '../../data/useCountDailyProfileData';
import useCountAvailabilityData from '../../data/useCountAvailabilityData';
import {
  NightDayPeriod,
  SourceQueryParams,
} from '../../types/vehicleCountSource';
import VehicleCountChartPanel from './VehicleCountChartPanel';
import { Site } from '../../types/site';
import useIntlWeekDays from '../../hooks/useIntlWeekDays';
import {
  getWeekDaySelectionCategory,
  WeekDaysSelection,
} from '../../utils/weekDayUtils';
import CountAvailabilityPanel from '../CountAvailabilityPanel';

interface VehicleCountChartsProps {
  insightId?: string;
  source: VehicleCountSummary;
  site: Pick<Site, 'siteId' | 'siteTimeZone'>;
  parameters: SourceQueryParams;
}

export default function VehicleCountCharts({
  insightId,
  source,
  site: { siteId, siteTimeZone },
  parameters: { sourceId, startDate, endDate, daysOfTheWeek, period },
}: VehicleCountChartsProps) {
  const dailyProfileDataHandler = useCountDailyProfileData({
    insightId,
    siteId,
    sourceId,
    startDate,
    endDate,
    daysOfTheWeek,
  });
  const availabilityDataHandler = useCountAvailabilityData({
    insightId,
    siteId,
    sourceId,
    startDate,
    endDate,
    daysOfTheWeek,
  });

  const { formatMessage, formatDate } = useIntl();
  const allWeekdays = useIntlWeekDays();

  if (!dailyProfileDataHandler.data || !availabilityDataHandler.data) {
    return (
      <div className="text-center pt-5">
        <Spinner />
      </div>
    );
  }

  const selectedWeekdaysLabels = allWeekdays
    .filter((weekday) => daysOfTheWeek.indexOf(weekday.value) !== -1)
    .map((weekday) => weekday.label)
    .join(', ');
  const weekdaysSelectionCategory = getWeekDaySelectionCategory(daysOfTheWeek);
  const periodDescription = formatMessage(
    {
      description: 'Vehicle count charts - selected period description',
      defaultMessage: `{startDate} to {endDate} {selectedWeekDays, select,
        ALL_DAYS {}
        WEEKEND {- weekends only}
        WEEKDAYS {- weekdays only}
        other {- {selectedWeekDays}}
      }`,
      id: '3kOTOu',
    },
    {
      startDate: formatDate(startDate, { timeZone: siteTimeZone }),
      endDate: formatDate(endDate, { timeZone: siteTimeZone }),
      selectedWeekDays:
        weekdaysSelectionCategory === WeekDaysSelection.OTHER
          ? selectedWeekdaysLabels
          : weekdaysSelectionCategory,
    }
  );
  return (
    <>
      <CountAvailabilityPanel
        title={source.name}
        description={formatMessage(
          {
            description: 'Data availability panel: selected period description',
            defaultMessage: `Data availability for <strong>{selectedPeriod}</strong> `,
            id: 'XVDpYQ',
          },
          {
            selectedPeriod: periodDescription,
          }
        )}
        availabilityData={availabilityDataHandler.data.dataAvailability}
        startDate={startDate!}
        endDate={endDate!}
        timeZone={siteTimeZone}
      />
      {dailyProfileDataHandler.data.vehicleCounts.map((vehicleData) => (
        <VehicleCountChartPanel
          key={`${vehicleData.id}-${vehicleData.name}`}
          countData={
            period === NightDayPeriod.NIGHT
              ? shiftData(vehicleData.items)
              : vehicleData.items
          }
          title={vehicleData.name}
          subtitle={formatMessage(
            {
              description: 'Vehicle count charts - count charts subtitle',
              defaultMessage: `Average daily count from {selectedPeriod}`,
              id: 'P63EA7',
            },
            {
              selectedPeriod: periodDescription,
            }
          )}
          formattedAvailableStartDate={formatDate(
            (source.availableStartDate || 0) * 1000,
            { timeZone: siteTimeZone }
          )}
          formattedAvailableEndDate={formatDate(
            (source.availableEndDate || 0) * 1000,
            { timeZone: siteTimeZone }
          )}
        />
      ))}
    </>
  );
}

const dayInSeconds = 60 * 60 * 24;
function shiftData(data) {
  const midPointIndex = Math.ceil(data.length / 2);
  const morning = data.slice(0, midPointIndex);
  const afternoon = data.slice(midPointIndex);
  return [
    ...afternoon,
    ...morning.map((item) => ({
      ...item,
      date: item.date + dayInSeconds,
    })),
  ];
}
