import {
  Box,
  Button,
  Flex,
  FormControl,
  FormLabel,
  Stack,
  Switch,
  SwitchProps,
  Text,
  useDisclosure,
} from '@chakra-ui/react';

import { FormattedMessage } from 'react-intl';
import SiteAccessSettingModal from 'design-system/organisms/site-access-settings-modal';
import useParamInsightId from '../../../hooks/useParamInsightId';
import useSiteView from '../../../hooks/useSiteView';
import SentIcon from '../../../svg/sent-icon.svg?react';
import UserSiteAccessSettings from '../../../components/UserSiteAccessSettings';

export interface SendAlertProps {
  hasAlertBeenSent: boolean;
  onResendAlert: () => void;
  sendAlert: boolean;
  sendAlertSwitchProps: SwitchProps;
}

export default function SendAlert({
  hasAlertBeenSent,
  onResendAlert,
  sendAlert,
  sendAlertSwitchProps,
}: SendAlertProps) {
  const insightId = Number(useParamInsightId());
  const siteView = useSiteView();
  const siteId = Number(siteView.state.siteId);
  const siteAccessSettingsModal = useDisclosure();
  if (hasAlertBeenSent) {
    return (
      <Flex marginY={6}>
        <SentIcon />
        <Box marginLeft={2}>
          <Text as="span" fontSize="sm">
            <FormattedMessage
              defaultMessage="An alert has been sent to designated recipients."
              id="sAQbgw"
            />
          </Text>{' '}
          <Button
            colorScheme="green"
            variant="link"
            onClick={onResendAlert}
            fontSize="sm"
          >
            <FormattedMessage defaultMessage="Save and resend?" id="NRr+2X" />
          </Button>
        </Box>
      </Flex>
    );
  }
  return (
    <>
      <Stack marginY={6}>
        <FormControl display="flex" alignItems="center">
          <Switch
            id="send-alert"
            colorScheme="green"
            defaultChecked={sendAlert}
            marginBottom={0}
            {...sendAlertSwitchProps}
          />
          <FormLabel
            htmlFor="send-alert"
            marginLeft={2}
            fontSize="sm"
            marginBottom={0}
          >
            Send alert
          </FormLabel>
        </FormControl>
        {sendAlert && (
          <Box>
            <Text as="span" fontSize="sm">
              <FormattedMessage
                defaultMessage="Send an alert to designated recipients."
                id="bI6TDj"
              />
            </Text>{' '}
            <Button
              colorScheme="green"
              variant="link"
              onClick={siteAccessSettingsModal.onOpen}
              fontSize="sm"
            >
              <FormattedMessage defaultMessage="Edit recipients" id="d5qsZ0" />
            </Button>
          </Box>
        )}
      </Stack>
      <SiteAccessSettingModal
        isOpen={siteAccessSettingsModal.isOpen}
        onClose={siteAccessSettingsModal.onClose}
      >
        {/* TODO: Remove reference to application component from design system: */}
        <UserSiteAccessSettings orgId={insightId} siteId={siteId} />
      </SiteAccessSettingModal>
    </>
  );
}
