import { components } from '../types/schema';
import { envConfig } from '../config/envConfig';
import { standardFetch } from '../utils/fetch';
import { User } from '../types/user';

type InviteSiteUsersBody = components['schemas']['UserInviteEmails'];

type UpdateSiteUserBody = components['schemas']['UserSiteAssignmentVO'];

export function inviteSiteUsers(
  insightId: number,
  siteId: number,
  inviteUsersData: InviteSiteUsersBody
) {
  return standardFetch(
    `${envConfig.API_URL}/api/v1/insights/${insightId}/sites/${siteId}/sendInvite`,
    {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(inviteUsersData),
    }
  );
}

export function resendInvite(
  insightId: number,
  siteId: number,
  data: {
    email: string;
  }
) {
  return standardFetch(
    `${envConfig.API_URL}/api/v1/insights/${insightId}/sites/${siteId}/resendInvite`,
    {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(data),
    }
  );
}

export function postSiteUser(insightId, siteId, newUserData) {
  return standardFetch(
    `${envConfig.API_URL}/api/v1/assignment/insight/${insightId}/site/${siteId}/user`,
    {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(newUserData),
    }
  );
}
export function putSiteUser(
  insightId: number,
  siteId: number,
  userId: number,
  updatedUserData: UpdateSiteUserBody
) {
  return standardFetch<User>(
    `${envConfig.API_URL}/api/v1/assignment/insight/${insightId}/site/${siteId}/user/${userId}`,
    {
      method: 'PUT',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(updatedUserData),
    }
  );
}
export function deleteSiteUser(insightId, siteId, userId, userData) {
  return standardFetch(
    `${envConfig.API_URL}/api/v1/assignment/insight/${insightId}/site/${siteId}/user/${userId}`,
    {
      method: 'DELETE',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(userData),
    }
  );
}
export function resetPassword(email: string) {
  return standardFetch(`${envConfig.API_URL}/api/auth/forgottenPassword`, {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({ email }),
  });
}
