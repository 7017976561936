import queryString from 'query-string';
import { standardFetch } from '../utils/fetch';
import { envConfig } from '../config/envConfig';
import { Annotation } from '../types/annotations';
import { Site } from '../types/site';

export function createAnnotation(
  insightId: string,
  siteId: number,
  annotation: Partial<Annotation>
) {
  return standardFetch<Annotation>(
    `${envConfig.API_URL}/api/v1/insights/${insightId}/sites/${siteId}/annotations`,
    {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(annotation),
    }
  );
}
export function updateAnnotation(
  insightId: string,
  siteId: number,
  annotation: Required<
    Pick<Annotation, 'annotationId'> &
      Pick<Annotation, 'title' | 'description' | 'startDate' | 'endDate'>
  >
) {
  return standardFetch<Annotation>(
    `${envConfig.API_URL}/api/v1/insights/${insightId}/sites/${siteId}/annotations/${annotation.annotationId}`,
    {
      method: 'PUT',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(annotation),
    }
  );
}
export function deleteAnnotation(
  insightId: string,
  siteId: number,
  annotation: Required<Pick<Annotation, 'annotationId'>>
) {
  return standardFetch<Annotation>(
    `${envConfig.API_URL}/api/v1/insights/${insightId}/sites/${siteId}/annotations/${annotation.annotationId}`,
    {
      method: 'DELETE',
    }
  );
}

export function exportAnnotationCSV(
  insightId: number,
  siteId: number,
  startTime: number,
  endTime: number,
  site: Pick<Site, 'siteName' | 'siteTimeZone'>
) {
  const queryParams = queryString.stringify({
    startTime,
    endTime,
    siteName: site.siteName,
    siteTimeZone: site.siteTimeZone,
  });
  return standardFetch<Response>(
    `${envConfig.API_URL}/api/v1/insights/${insightId}/sites/${siteId}/annotations/csv/export?${queryParams}`,
    {
      method: 'GET',
      headers: { 'Content-Type': 'application/json' },
    }
  );
}

export function resendAnnotationAlert(
  insightId: number,
  siteId: number,
  annotationId: number
) {
  return standardFetch<Response>(
    `${envConfig.API_URL}/api/v1/insights/${insightId}/sites/${siteId}/annotations/${annotationId}/alert/resend`,
    {
      method: 'POST',
    }
  );
}
