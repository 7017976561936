import classnames from 'classnames';
import { CSSProperties, ReactNode, useEffect, useRef, useState } from 'react';
import { useIntl } from 'react-intl';
import { useLocation, unstable_usePrompt as usePrompt } from 'react-router-dom';
import { LocalDate } from '@webapp/bff/src/types/dates';
import SpinnerSuspense from 'design-system/atoms/spinner-suspense';
import {
  MESSAGE_BROWSE_AWAY_UNSAVED_CHANGES,
  MESSAGE_ERROR_SITE_NOT_FOUND,
} from '../../constants/messages';
import useParamInsightId from '../../hooks/useParamInsightId';
import useInsightData from '../../data/useInsightData';
import useRoutesData from '../../data/useRoutesData';
import useSitesData from '../../data/useSitesData';
import './EditSite.scss';
import EditSiteProperties from '../EditSiteProperties';
import EditSiteSchedules from '../EditSiteSchedules';
import EditSiteUsers from '../EditSiteUsers';
import LayoutSite from '../LayoutSite';
import RoutesMap from '../RoutesMap';
import SiteStatus, { SiteStatusMode } from '../SiteStatus';
import Spinner from '../Spinner';
import Map = google.maps.Map;
import { useCurrentSiteData } from '../../data/useSiteData';
import {
  DataHandlerFeedback,
  hasDataHandlerFeedBack,
} from '../../utils/dataHandler';
import useSiteView from '../../hooks/useSiteView';
import { SiteType } from '../CreateSiteTrigger/types';
import {
  WorkAreasEditorList,
  useWorkAreasEditorManager,
} from '../WorkAreasEditor';
import useWorkAreasData from '../../data/useWorkAreasData';
import OccupationDatesEditor from '../OccupationDatesEditor';
import { DisruptionPackSection } from '../DisruptionPackSection';
import useDisruptionPacksData from '../../data/useDisruptionPacksData';
import {
  useDisruptionAreaEditorManager,
  DisruptionAreaEditorPanel,
} from '../DisruptionAreaEditor';
import { RoutesEditorSection, useRoutesEditorManager } from '../RoutesEditor';
import useCurrentUserData from '../../data/useCurrentUserData';
import { useSitePermissions } from '../../hooks/useSitePermissions';

export enum SiteFormSections {
  NONE,
  DESCRIPTION,
  OCCUPATION_DATES,
  WORK_AREA,
  DISRUPTION_AREA,
  DISRUPTION_PACK,
  ROUTES,
  SCHEDULES,
  USERS,
}

export default function EditSite() {
  const { formatMessage } = useIntl();
  const insightId = useParamInsightId();
  const location = useLocation();
  const insightDataHandler = useInsightData(insightId!);
  const sitesDataHandler = useSitesData(insightId!);
  const currentUserDataHandler = useCurrentUserData();
  const currentSiteDataHandler = useCurrentSiteData();
  const currentSite = currentSiteDataHandler.data;
  const siteView = useSiteView();
  const routesDataHandler = useRoutesData(siteView?.state.siteId);
  const {
    hasCurrentViewSiteMonitorPermission,
    hasCurrentViewSiteEditPermission,
    checkSitePermission,
  } = useSitePermissions(
    !insightId
      ? null
      : {
          insightId,
          siteId: siteView.state.siteId.toString(),
        }
  );
  const sortedRoutes =
    routesDataHandler.data &&
    routesDataHandler.data
      .slice()
      .sort((routeA, routeB) => routeA.trackId.localeCompare(routeB.trackId));
  const initialActivePanel =
    location.state && (location.state as any).siteFormSection
      ? (location.state as any).siteFormSection
      : SiteFormSections.NONE;
  const [activePanelIndex, setActivePanelIndex] = useState(initialActivePanel);
  const disruptionPacksHandler = useDisruptionPacksData(
    siteView?.state.siteId.toString()
  );
  const handlePanelClick = (clickedSectionId: SiteFormSections) => () => {
    if (activePanelIndex === clickedSectionId) {
      setActivePanelIndex(SiteFormSections.NONE);
    } else {
      setActivePanelIndex(clickedSectionId);
    }
  };

  const [hasUnsavedSiteDetails, setHasUnsavedSiteDetails] = useState(false);
  const routesEditorManager = useRoutesEditorManager();
  const { editingRoute, stopEditingRoute } = routesEditorManager;

  const workAreasDataHandler = useWorkAreasData(
    siteView?.state.siteId.toString()
  );
  const workAreasEditorManager = useWorkAreasEditorManager(
    workAreasDataHandler.data
  );

  const disruptionAreaEditorManager = useDisruptionAreaEditorManager({
    disruptionArea: currentSite?.disruptionArea,
    workAreas: workAreasDataHandler.data,
  });

  usePrompt({
    message: formatMessage(MESSAGE_BROWSE_AWAY_UNSAVED_CHANGES),
    when:
      hasUnsavedSiteDetails || disruptionAreaEditorManager.hasUnsavedChanges,
  });

  const lastSiteIdRef = useRef(siteView?.state.siteId);
  useEffect(() => {
    if (lastSiteIdRef.current !== siteView?.state.siteId) {
      lastSiteIdRef.current = siteView?.state.siteId;
      if (editingRoute) {
        stopEditingRoute();
      }
    }
  }, [siteView?.state.siteId, editingRoute, stopEditingRoute]);
  const mapElementRef = useRef<Map>();

  if (hasDataHandlerFeedBack([currentSiteDataHandler])) {
    return <DataHandlerFeedback dataHandlersParam={[currentSiteDataHandler]} />;
  }
  if (!siteView) {
    return (
      <div className="text-center pt-5">
        <Spinner />
      </div>
    );
  }

  if (!currentSite) {
    return (
      <div className="text-center pt-5">
        {formatMessage(MESSAGE_ERROR_SITE_NOT_FOUND)}
      </div>
    );
  }
  const isUserReadOnly = !hasCurrentViewSiteEditPermission;

  return (
    <LayoutSite
      windowTitle={`Mooven | ${
        insightDataHandler.data ? insightDataHandler.data.name : ''
      }`}
      sideBarContent={
        <div className="v2-edit-site__pages">
          <div className="v2-edit-site__page">
            <SiteFormSection
              isOpen={activePanelIndex === SiteFormSections.DESCRIPTION}
              onHeaderClick={handlePanelClick(SiteFormSections.DESCRIPTION)}
              title={`${formatMessage({
                defaultMessage: 'Site details',
                id: 'Kqifnq',
                description: 'Edit site: site details tab title',
              })}${hasUnsavedSiteDetails ? ' *' : ''}`}
            >
              <EditSiteProperties
                key={currentSite.siteId}
                updateSiteProps={sitesDataHandler.updateSiteProps}
                siteHasQueue={currentSiteDataHandler.siteHasQueue}
                site={currentSite}
                isUserReadOnly={isUserReadOnly}
                onUnsavedStatusChange={setHasUnsavedSiteDetails}
                mapReference={mapElementRef.current}
              />
            </SiteFormSection>

            {currentSite.siteType === SiteType.PROJECT_SITE && (
              <>
                <SiteFormSection
                  isOpen={
                    activePanelIndex === SiteFormSections.OCCUPATION_DATES
                  }
                  onHeaderClick={handlePanelClick(
                    SiteFormSections.OCCUPATION_DATES
                  )}
                  title={formatMessage({
                    defaultMessage: 'Occupation dates',
                    id: 'mIfoU2',
                    description: 'Edit site: occupation dates tab title',
                  })}
                >
                  <OccupationDatesEditor
                    occupationDates={{
                      startDate: currentSite.occupationStartDate,
                      endDate: currentSite.occupationEndDate,
                    }}
                    updateOccupationDates={async ({ startDate, endDate }) => {
                      await currentSiteDataHandler.updateSiteOccupationDates({
                        startDate: startDate as LocalDate,
                        endDate: endDate as LocalDate,
                      });
                    }}
                  />
                </SiteFormSection>
                <SiteFormSection
                  isOpen={activePanelIndex === SiteFormSections.WORK_AREA}
                  onHeaderClick={handlePanelClick(SiteFormSections.WORK_AREA)}
                  title={formatMessage({
                    defaultMessage: 'Work area',
                    id: 'gCC3YL',
                    description: 'Edit site: work area tab title',
                  })}
                >
                  <WorkAreasEditorList
                    workAreas={workAreasDataHandler.data || []}
                    workAreasEditorManager={workAreasEditorManager}
                    createWorkArea={workAreasDataHandler.createWorkArea}
                    editWorkArea={workAreasDataHandler.editWorkArea}
                    deleteWorkArea={workAreasDataHandler.deleteWorkArea}
                  />
                </SiteFormSection>
                <SiteFormSection
                  isOpen={activePanelIndex === SiteFormSections.DISRUPTION_AREA}
                  onHeaderClick={handlePanelClick(
                    SiteFormSections.DISRUPTION_AREA
                  )}
                  title={`${formatMessage({
                    defaultMessage: 'Disruption area',
                    id: 'V4ZBWm',
                    description: 'Edit site: disruption area tab title',
                  })}${
                    disruptionAreaEditorManager.hasUnsavedChanges ? ' *' : ''
                  }`}
                >
                  <DisruptionAreaEditorPanel
                    disruptionAreaEditorManager={disruptionAreaEditorManager}
                    saveDisruptionArea={
                      currentSiteDataHandler.updateSiteDisruptionArea
                    }
                  />
                </SiteFormSection>
                <SiteFormSection
                  isOpen={activePanelIndex === SiteFormSections.DISRUPTION_PACK}
                  onHeaderClick={handlePanelClick(
                    SiteFormSections.DISRUPTION_PACK
                  )}
                  title={formatMessage({
                    defaultMessage: 'Impact assessment',
                    id: 'bf+0+y',
                  })}
                  style={{ paddingTop: 0 }}
                >
                  <DisruptionPackSection
                    currentUserEmail={currentUserDataHandler.data?.email}
                    disruptionPacks={disruptionPacksHandler.data || []}
                    createDisruptionPack={
                      disruptionPacksHandler.createDisruptionPack
                    }
                    createDisruptionPackTrackProps={{
                      siteId: siteView.state.siteId.toString(),
                      referrer: 'edit site',
                    }}
                    hasDisruptionArea={Boolean(
                      currentSiteDataHandler.data?.disruptionArea
                    )}
                    plannedDates={{
                      startDate:
                        currentSiteDataHandler.data?.occupationStartDate,
                      endDate: currentSiteDataHandler.data?.occupationEndDate,
                    }}
                    goToDisruptionArea={() => {
                      setActivePanelIndex(SiteFormSections.DISRUPTION_AREA);
                    }}
                    goToSetDates={() => {
                      setActivePanelIndex(SiteFormSections.OCCUPATION_DATES);
                    }}
                  />
                </SiteFormSection>
              </>
            )}

            <SiteFormSection
              isOpen={activePanelIndex === SiteFormSections.ROUTES}
              onHeaderClick={handlePanelClick(SiteFormSections.ROUTES)}
              title={formatMessage({
                defaultMessage: 'Routes',
                id: 'qBb7EK',
                description: 'Edit site: routes tab title',
              })}
            >
              <RoutesEditorSection
                canEditRoutes={checkSitePermission('CanEditSiteRoutes')}
                routes={sortedRoutes}
                createRoute={routesDataHandler.createRoute}
                editRoute={routesDataHandler.editRoute}
                deleteRoute={routesDataHandler.deleteRoute}
                routesEditorManager={routesEditorManager}
                LayoutFocusContent={FocusItemEditPanel}
              />
            </SiteFormSection>
            <SiteFormSection
              isOpen={activePanelIndex === SiteFormSections.SCHEDULES}
              onHeaderClick={handlePanelClick(SiteFormSections.SCHEDULES)}
              title={formatMessage({
                defaultMessage: 'Monitoring schedules',
                id: 'Jd0CGg',
                description: 'Edit site: schedules tab title',
              })}
            >
              <EditSiteSchedules
                site={currentSite}
                schedules={currentSite.schedules}
                saveSiteSchedule={sitesDataHandler.saveSiteSchedule}
                deleteSiteSchedule={sitesDataHandler.deleteSiteSchedule}
                startSiteMonitoring={sitesDataHandler.startSiteMonitoring}
                stopSiteMonitoring={sitesDataHandler.stopSiteMonitoring}
                hasSiteMonitorPermission={hasCurrentViewSiteMonitorPermission}
              />
            </SiteFormSection>
            <SiteFormSection
              isOpen={activePanelIndex === SiteFormSections.USERS}
              onHeaderClick={handlePanelClick(SiteFormSections.USERS)}
              title={formatMessage({
                defaultMessage: 'Site users',
                id: 'ZH+hPU',
                description: 'Edit site: users tab title',
              })}
            >
              <SpinnerSuspense>
                {activePanelIndex === SiteFormSections.USERS ? (
                  <EditSiteUsers
                    siteId={currentSite.siteId}
                    siteHasQueue={currentSiteDataHandler.siteHasQueue}
                    isUserReadOnly={isUserReadOnly}
                  />
                ) : null}
              </SpinnerSuspense>
            </SiteFormSection>
          </div>
        </div>
      }
      mapContent={
        <RoutesMap
          onLoad={(map) => {
            mapElementRef.current = map;
          }}
          routesEditorManager={routesEditorManager}
          routes={sortedRoutes}
          siteLocation={currentSite.siteLocation}
          monitoringStatus={
            <SiteStatus site={currentSite} mode={SiteStatusMode.LONG} />
          }
          workAreas={workAreasDataHandler.data || []}
          workAreasEditorManager={workAreasEditorManager}
          disruptionAreaEditorManager={disruptionAreaEditorManager}
          isEditingDisruptionArea={
            activePanelIndex === SiteFormSections.DISRUPTION_AREA
          }
        />
      }
    />
  );
}

function SiteFormSection({
  isOpen,
  onHeaderClick,
  title,
  children,
  style,
}: {
  isOpen: boolean;
  onHeaderClick: () => void;
  title: ReactNode;
  children: ReactNode;
  style?: CSSProperties;
}) {
  return (
    <div className="v2-site-form-section">
      <button
        type="button"
        onClick={onHeaderClick}
        className={classnames({
          'v2-site-form-section__header': true,
          'v2-site-form-section__header--active': isOpen,
        })}
      >
        {title}
      </button>
      <div
        className={classnames({
          'v2-site-form-section__body': true,
          'v2-site-form-section__body--active': isOpen,
        })}
        style={style}
      >
        {children}
      </div>
    </div>
  );
}

function FocusItemEditPanel({ children }: { children: ReactNode }) {
  return (
    <div className="v2-edit-site__page v2-edit-site__page--focus">
      {children}
    </div>
  );
}
