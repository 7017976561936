import { FormattedMessage } from 'react-intl';
import { VehicleCountSummary } from '@data-pipeline/api/src/types/vehicle-count';
import { Box } from '@chakra-ui/react';
import Spinner from '../Spinner';
import './VehicleCountSources.scss';
import { VehicleCountItem } from './VehicleCountItem';

interface VehicleCountSourcesProps {
  sources?: VehicleCountSummary[];
  isFetchingSources: boolean;
  selectedSourceId?: number | null;
  setSelectedSourceId: (id: number) => void;
  focusedSourceId: number | null;
  setFocusedSourceId: (id: number | null) => void;
}

export const VehicleCountSources = ({
  sources,
  isFetchingSources,
  selectedSourceId,
  setSelectedSourceId,
  focusedSourceId,
  setFocusedSourceId,
}: VehicleCountSourcesProps) => (
  <div className="v2-vehicle-count-sources">
    <div className="v2-vehicle-count-sources__header">
      <FormattedMessage
        defaultMessage="Count Sources"
        id="qouRp+"
        description="Vehicle count sources"
      />
    </div>
    {isFetchingSources && !sources && (
      <Box textAlign="center" pt={12}>
        <Spinner />
      </Box>
    )}
    {sources?.length === 0 && (
      <div className="v2-vehicle-count-sources__text">
        <FormattedMessage
          defaultMessage="No sources available"
          id="oJpnSW"
          description="No Vehicle count sources"
        />
      </div>
    )}
    {sources &&
      sources.length > 0 &&
      sources.map((source) => (
        <VehicleCountItem
          key={source.id}
          source={source}
          selected={selectedSourceId === source.id}
          focused={focusedSourceId === source.id}
          setFocusedSourceId={setFocusedSourceId}
          setSelectedSourceId={setSelectedSourceId}
        />
      ))}
  </div>
);
