import { buffer, polygon, convex, union, featureCollection } from '@turf/turf';
import {
  Feature as TurfFeatureType,
  Polygon as TurfPolygonType,
  MultiPolygon as TurfMultiPolygonType,
} from 'geojson';

export type LatLng = {
  lat: number;
  lng: number;
};

export function wktToPointArray(wkt: string): LatLng[] {
  const isPolygon = wkt.includes('POLYGON');
  const wktMatch = /\(([-,\d\s.]+)\)/.exec(wkt);
  const wktContent = wktMatch?.[1] ?? '';
  const wktPoints = wktContent.split(',');
  return wktPoints
    .slice(0, wktPoints.length - (isPolygon ? 1 : 0))
    .map((wktPoint) => {
      const coordinates = wktPoint.trim().split(' ');
      if (coordinates.length !== 2) {
        throw new Error(
          `Could not parse WKT. Longitude and Latitude expected in string: "${wktPoint}" , wkt: "${wkt}"`
        );
      }
      return {
        lat: Number(coordinates[1]),
        lng: Number(coordinates[0]),
      };
    });
}

export function pointArrayToWkt(
  coordinates: LatLng[],
  type: 'POLYGON' | 'LINESTRING' = 'POLYGON'
) {
  const stringPoints = coordinates.map((point) => `${point.lng} ${point.lat}`);
  const shuoldClosePolygon =
    type === 'POLYGON' &&
    stringPoints[0] !== stringPoints[stringPoints.length - 1];
  const treatedStringPoints = shuoldClosePolygon
    ? [...stringPoints, stringPoints[0]]
    : stringPoints;
  const pointsString = treatedStringPoints.join(',');
  if (type === 'LINESTRING') {
    return `LINESTRING(${pointsString})`;
  }
  return `POLYGON((${pointsString}))`;
}

// This method can only handle simple polygons, with one ring
export function wktPolygonToGeoJsonCoordinates(wkt: string): number[][][] {
  const wktMatch = wkt.match(/\(([-,\d\s.]+)\)/);
  const wktContent = (wktMatch && wktMatch[1]) || '';
  const wktPoints = wktContent.split(',');
  return [
    wktPoints.map((wktPoint) => {
      const coordinates = wktPoint.trim().split(' ');
      if (coordinates.length !== 2) {
        throw new Error(
          `Could not parse WKT. Longitude and Latitude expected in string: "${wktPoint}" , wkt: "${wkt}"`
        );
      }
      return [Number(coordinates[0]), Number(coordinates[1])];
    }),
  ];
}

export function geoJsonPolygonCoordinatesToPointArray(
  coordinates: number[][][]
): LatLng[] {
  return coordinates.flatMap((ring) =>
    ring.slice(0, ring.length - 1).map((pointCoords) => ({
      lng: pointCoords[0],
      lat: pointCoords[1],
    }))
  );
}

export function pointArrayToGeoJsonPolygonCoordinates(
  points: LatLng[]
): number[][][] {
  const pointCoordinates = points.map((p) => {
    return [p.lng, p.lat];
  });
  if (
    points[0].lat === points[points.length - 1].lat &&
    points[0].lng === points[points.length - 1].lng
  ) {
    // eslint-disable-next-line no-console
    console.warn(
      'PointArray should not repeat first point in last position for polygons.'
    );
    return [pointCoordinates];
  }
  return [[...pointCoordinates, pointCoordinates[0]]];
}

export function pointArrayToGeoJsonCoordinates(points: LatLng[]): number[][][] {
  const pointCoordinates = points.map((p) => {
    return [p.lng, p.lat];
  });
  return [pointCoordinates];
}

export function checkPolygonWkt(polygonWkt: string) {
  const regexPolygon = /POLYGON\(\(([-,\d\s.]+)\)\)/;
  const matches = polygonWkt.match(regexPolygon);
  if (!matches) {
    throw new Error('Invalid Polygon Wkt string');
  } else {
    const wktPoints = matches[1].split(',');
    const points = wktPoints.map((wktPoint) => {
      const coordinates = wktPoint.trim().split(' ');
      return `${coordinates[0]} ${coordinates[1]}`;
    });
    if (points[0] !== points[points.length - 1]) {
      throw new Error('First and Last point of polygon need to be the same');
    }
  }
}

// impact radius in kilometers
export function getImpactPolygon(
  areas: string[],
  impactRadius: number
): LatLng[] {
  const turfBufferedAreas = areas.map((workArea) =>
    buffer(polygon(wktPolygonToGeoJsonCoordinates(workArea)), impactRadius, {
      steps: 3,
    })
  );
  const turfDisruptionUnion = turfBufferedAreas.reduce(
    (
      acc: TurfFeatureType<TurfPolygonType | TurfMultiPolygonType> | null,
      turfPolygon
    ) => {
      if (turfPolygon === undefined) {
        return acc;
      }
      if (acc === null) {
        return turfPolygon;
      }
      try {
        return union(featureCollection([acc, turfPolygon])) ?? acc;
      } catch (e) {
        return acc;
      }
    },
    null
  );
  if (!turfDisruptionUnion) {
    return [];
  }
  if (turfDisruptionUnion.geometry.type === 'Polygon') {
    return geoJsonPolygonCoordinatesToPointArray(
      turfDisruptionUnion.geometry.coordinates
    );
  }
  const turfHullArea = convex(turfDisruptionUnion);
  return geoJsonPolygonCoordinatesToPointArray(
    turfHullArea?.geometry.coordinates || []
  );
}

export function googleDynamicCoordinatesToPointArray(
  coordinates: google.maps.LatLng[]
) {
  const staticCoordinates = coordinates.map((p) => ({
    lng: p.lng(),
    lat: p.lat(),
  }));
  return staticCoordinates;
}
