import {
  ButtonGroup,
  Menu,
  MenuButton,
  Button,
  MenuList,
  MenuItem,
  IconButton,
} from '@chakra-ui/react';
import { MessageDescriptor, useIntl } from 'react-intl';
import { ArrowUpIcon, ArrowDownIcon } from '@chakra-ui/icons';

export enum SortDirection {
  DESC,
  INC,
}

export type SortOption = {
  sortOptionMessage: MessageDescriptor;
  value: string;
  enabled: boolean;
};

export interface DropDownSortProps {
  selectedDirection: SortDirection;
  selectedSortOption: string;
  onSelectDirection: (dir: SortDirection) => void;
  onSelectSortOption: (optionValue: string) => void;
  sortOptions: SortOption[];
}

export default function DropDownSort({
  selectedDirection,
  onSelectDirection,
  selectedSortOption,
  onSelectSortOption,
  sortOptions,
}: DropDownSortProps) {
  const { formatMessage } = useIntl();
  const selectedSortOptionProps = sortOptions.find(
    (option) => option.value === selectedSortOption
  );
  return (
    <ButtonGroup>
      <Menu size="sm">
        <MenuButton as={Button} variant="outline" size="sm">
          {selectedSortOptionProps &&
            formatMessage(selectedSortOptionProps.sortOptionMessage)}
        </MenuButton>
        <MenuList>
          {sortOptions.map((option) => (
            <MenuItem
              fontSize="sm"
              key={option.value}
              disabled={!option.enabled}
              color={!option.enabled ? 'gray.300' : undefined}
              onClick={() => onSelectSortOption(option.value)}
            >
              {formatMessage(option.sortOptionMessage)}
            </MenuItem>
          ))}
        </MenuList>
      </Menu>
      <IconButton
        icon={
          selectedDirection === SortDirection.INC ? (
            <ArrowUpIcon />
          ) : (
            <ArrowDownIcon />
          )
        }
        aria-label="Change sort direction"
        variant="outline"
        size="sm"
        onClick={() =>
          onSelectDirection(
            selectedDirection === SortDirection.INC
              ? SortDirection.DESC
              : SortDirection.INC
          )
        }
      />
    </ButtonGroup>
  );
}
