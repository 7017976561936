import { CircularProgress, Heading } from '@chakra-ui/react';
import { useEffect } from 'react';
import { useIntl } from 'react-intl';
import SpinnerSuspense from 'design-system/atoms/spinner-suspense';
import useCurrentUserData from '../../data/useCurrentUserData';
import { useAnalytics } from '../../hooks/analytics/useAnalytics';
import UserSiteAccessSettings from '../UserSiteAccessSettings';

export interface EditableSiteUsersProps {
  insightId: number;
  siteId: number;
  // TODO: investigate usage of siteHasQueue, why it was removed, should have been removed?
  // eslint-disable-next-line react/no-unused-prop-types
  siteHasQueue: boolean;
}

export default function EditableSiteUsers({
  insightId,
  siteId,
}: EditableSiteUsersProps) {
  const currentUser = useCurrentUserData();
  const { formatMessage } = useIntl();
  const { setSessionTag } = useAnalytics();
  useEffect(() => {
    setSessionTag('edit-site-users', true);
  }, [setSessionTag]);
  if (!currentUser?.data?.userId) {
    return <CircularProgress />;
  }
  return (
    <>
      <Heading as="h3" fontSize="16px" marginBottom={5}>
        {formatMessage({
          id: 'R3s8/V',
          defaultMessage: 'Site user access settings',
        })}
      </Heading>
      <SpinnerSuspense>
        <UserSiteAccessSettings orgId={insightId} siteId={siteId} />
      </SpinnerSuspense>
    </>
  );
}
