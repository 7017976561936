import useSWR from 'swr';
import type { ViewScorecard } from '@webapp/bff/src/types/report';
import { trpc } from '../config/trpc';

export interface ScorecardsDataHandler {
  data: ViewScorecard[];
  error: any;
  isValidating: boolean;
  createScoreCard: (scoreCard: Partial<ViewScorecard>) => Promise<void>;
}

export default function useScorecardsData(
  insightId: string
): ScorecardsDataHandler {
  const { data, isValidating, error } = useSWR(
    `${
      localStorage.getItem('session-id') ?? ''
    }/useScorecardsData/${insightId}`,
    () =>
      trpc.report.getScorecards.query({
        orgId: insightId,
      }),
    {
      suspense: true,
    }
  );
  return {
    data,
    isValidating,
    error,
    createScoreCard: async (_scoreCard) => {},
  };
}
