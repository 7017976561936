import useSWR from 'swr';
import { ActiveVms } from '@webapp/bff/src/types/vms';
import { trpc } from '../config/trpc';

export default function useSiteVmsData(siteId?: string, suspense = false) {
  const { data, mutate, isValidating, error } = useSWR<ActiveVms[]>(
    siteId ? `useSiteVmsData-${siteId}` : null,
    !siteId
      ? null
      : () => trpc.vms.getActiveVms.query({ siteId: Number(siteId) }),
    {
      suspense,
      refreshInterval: 5 * 60 * 1000,
    }
  );

  const mutateThenUpdate =
    (serverOp) =>
    async (
      ...args: Parameters<typeof serverOp>
    ): Promise<ReturnType<typeof serverOp>> => {
      const response = await serverOp(...args);
      await mutate();
      return response;
    };

  return {
    data,
    error,
    isValidating,
    updateVmsSchedule: ({
      vmsConfigId,
      startUpdatingAt,
      stopUpdatingAt,
    }: {
      vmsConfigId: number;
      startUpdatingAt?: Date;
      stopUpdatingAt?: Date;
    }): Promise<void> =>
      mutateThenUpdate(trpc.vms.updateVmsSchedule.mutate)({
        vmsConfigId,
        startUpdatingAt,
        stopUpdatingAt,
      }),

    deleteVmsBoard: (vmsConfigId: number): Promise<void> =>
      mutateThenUpdate(trpc.vms.deleteVms.mutate)({
        vmsConfigId,
      }),
    createVmsBoard: (vmsName: string): Promise<{ vmsConfigId?: number }> =>
      mutateThenUpdate(trpc.vms.createVmsBoard.mutate)({
        siteId: Number(siteId),
        vmsName,
      }),
    updateVmsBoard: (
      vmsDetails: Partial<ActiveVms> & { vmsConfigId: string }
    ): Promise<void> =>
      mutateThenUpdate(trpc.vms.updateVms.mutate)({
        ...vmsDetails,
        vmsConfigId: Number(vmsDetails.vmsConfigId),
      }),
    testVmsBoardConnection: async (
      vmsProps: Pick<
        ActiveVms,
        'vmsConfigId' | 'vmsId' | 'vmsType' | 'vmsApiKey'
      >
    ) => {
      const { vmsConfigId, vmsId, vmsType, vmsApiKey } = vmsProps;
      if (!vmsConfigId || !vmsId || !vmsType) {
        return Promise.reject(new Error('Not enough data to test connection'));
      }
      const response = await trpc.vms.testBoardConnection.query({
        vmsConfigId,
        vmsId,
        vmsType,
        vmsApiKey,
      });
      return response;
    },
    getVmsList: async (vmsProps: Pick<ActiveVms, 'vmsType' | 'vmsApiKey'>) => {
      const { vmsType, vmsApiKey } = vmsProps;
      if (!vmsType || !vmsApiKey) {
        return Promise.reject(new Error('Not enough data to get vms list'));
      }
      const response = await trpc.vms.getVmsListForVendor.query({
        vendor: vmsType,
        vmsApiKey,
      });
      return response;
    },
  };
}
