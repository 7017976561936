import { useMemo } from 'react';
import { Permission } from 'permissions';
import useUserSiteAuthorizationData from '../data/useUserSiteAuthorizationData';

interface SitePermissionsDictionary {
  permissions: Permission[];
  checkSitePermission: (permission: Permission) => boolean;
  hasCurrentViewSiteEditPermission: boolean;
  hasCurrentViewSiteMonitorPermission: boolean;
}

const emptyArray = [];

export function useSitePermissions(
  siteContext: null | {
    insightId: string;
    siteId: string;
  }
): SitePermissionsDictionary {
  const { siteId, insightId } = siteContext ?? {};
  const { data: siteAuthorizationData } = useUserSiteAuthorizationData({
    insightId,
    siteId,
  });

  const legacyPermissions = siteAuthorizationData?.permissions ?? emptyArray;
  const newPermissions = siteAuthorizationData?.newPermissions ?? emptyArray;

  return useMemo(
    () => ({
      permissions: newPermissions,
      checkSitePermission: (permission: Permission) =>
        newPermissions.includes(permission),

      // Legacy permissions props. Use checkSitePermission method instead
      hasCurrentViewSiteEditPermission: legacyPermissions.includes(
        'UPDATE_SITE_PRIVILEGE'
      ),
      hasCurrentViewSiteMonitorPermission:
        legacyPermissions.includes('UPDATE_SITE_PRIVILEGE') &&
        legacyPermissions.includes('MONITOR_SITE_PRIVILEGE'),
    }),
    [legacyPermissions, newPermissions]
  );
}
