import moment from 'moment-timezone';
import { FormattedMessage, useIntl } from 'react-intl';
import { Text } from '@chakra-ui/react';
import { DATE_PRESET_SHORT_D_M_Y } from 'design-system/utilities/date-presets';
import DateRangeFieldset from 'design-system/molecules/date-range-selection/date-range-fieldset';
import ModalFieldsetTemplate from './ModalFieldsetTemplate';
import { PlanControlsManager } from './types';
import { useAnalytics } from '../../hooks/analytics/useAnalytics';
import { MS_24_HOURS } from '../../constants/time';

type DatesFieldState = Pick<
  PlanControlsManager['controlsState'],
  'startDate' | 'endDate'
>;

export default function DateRangeField({
  fieldState,
  setFieldState,
  siteTimeZone,
  availableDateRange,
  isCompact = false,
}: {
  fieldState: DatesFieldState;
  setFieldState: (dates: DatesFieldState) => void;
  siteTimeZone: string;
  availableDateRange: PlanControlsManager['availableDateRange'];
  isCompact?: boolean;
}) {
  const { track } = useAnalytics();
  const startDateSt = dateMSToString(fieldState.startDate, siteTimeZone);
  const endDateSt = dateMSToString(fieldState.endDate, siteTimeZone);
  return (
    <ModalFieldsetTemplate
      isCompact={isCompact}
      title="Date range"
      labels={[
        {
          title: 'Date range',
          value: <DateRangeLabel dates={fieldState} timeZone={siteTimeZone} />,
        },
      ]}
      values={{
        startDate: startDateSt,
        endDate: endDateSt,
      }}
      onChange={(newValues) => {
        const startDateMS = dateStringToMS(newValues.startDate, siteTimeZone);
        const endDateMS = dateStringToMS(newValues.endDate, siteTimeZone, true);
        if (
          fieldState.startDate !== startDateMS ||
          fieldState.endDate !== endDateMS
        ) {
          setFieldState({
            startDate: startDateMS,
            endDate: endDateMS,
          });
          track('Plan Settings Changed', {
            referrer: 'Plan controls',
            control: 'Date range',
            startDate: startDateMS,
            endDate: endDateMS,
            range: Math.round((endDateMS - startDateMS) / MS_24_HOURS),
            value: `${newValues.startDate} - ${newValues.endDate}`,
          });
        }
      }}
      renderFieldset={({ formProps }) => (
        <DateRangeFieldset
          formProps={formProps}
          infoText={
            <InfoTextForDateRange
              dateRange={availableDateRange}
              timeZone={siteTimeZone}
            />
          }
          minDate={
            availableDateRange?.from
              ? dateMSToString(availableDateRange.from, siteTimeZone)
              : undefined
          }
          maxDate={
            availableDateRange?.to
              ? dateMSToString(availableDateRange.to, siteTimeZone)
              : undefined
          }
        />
      )}
    />
  );
}

function DateRangeLabel({
  dates,
  timeZone,
}: {
  dates?: {
    startDate?: number;
    endDate?: number;
  };
  timeZone: string;
}) {
  const { formatDate } = useIntl();

  const { startDate, endDate } = dates || {};
  if (!startDate && !endDate) {
    return <FormattedMessage defaultMessage="Select date range" id="qp/RxH" />;
  }
  const fromDateFormatted = formatDate(startDate, {
    timeZone,
    ...DATE_PRESET_SHORT_D_M_Y,
  });
  if (!endDate) {
    return (
      <FormattedMessage
        defaultMessage="from {fromDate}"
        id="Eq+IzW"
        values={{
          fromDate: fromDateFormatted,
        }}
      />
    );
  }
  const toDateFormatted = formatDate(endDate, {
    timeZone,
    ...DATE_PRESET_SHORT_D_M_Y,
  });
  if (!startDate) {
    return (
      <FormattedMessage
        defaultMessage="up to {toDate}"
        id="+OnfJd"
        values={{
          toDate: toDateFormatted,
        }}
      />
    );
  }
  return `${fromDateFormatted} - ${toDateFormatted}`;
}

function InfoTextForDateRange({
  dateRange,
  timeZone,
}: {
  dateRange?: {
    from: number;
    to: number;
  };
  timeZone: string;
}) {
  if (!dateRange) {
    return undefined;
  }
  return (
    <Text fontSize="xs" m={0}>
      <Text as="span" fontWeight="bold" mr={2}>
        <FormattedMessage defaultMessage="Available data:" id="h25Gs4" />
      </Text>
      <DateRangeLabel
        dates={{ startDate: dateRange.from, endDate: dateRange.to }}
        timeZone={timeZone}
      />
    </Text>
  );
}

function dateMSToString(date: number | undefined, timeZone: string) {
  if (date === undefined) {
    return '';
  }
  return moment(date).tz(timeZone).format('YYYY-MM-DD');
}
function dateStringToMS(date: string, timeZone: string, endOfDay = false) {
  const dateMoment = moment.tz(date, timeZone);
  return endOfDay ? dateMoment.endOf('day').valueOf() : dateMoment.valueOf();
}
