import { Box } from '@chakra-ui/react';
import useSitesData from '../data/useSitesData';
import EditableSite from './EditableSite';
import {
  DataHandlerFeedback,
  hasDataHandlerFeedBack,
} from '../utils/dataHandler';
import useParamInsightId from '../hooks/useParamInsightId';

export default function EditableSites() {
  const insightId = useParamInsightId();
  const sitesDataHandler = useSitesData(insightId);

  if (hasDataHandlerFeedBack(sitesDataHandler)) {
    return <DataHandlerFeedback dataHandlersParam={sitesDataHandler} />;
  }
  return (
    <Box p={12} pt="110px">
      {sitesDataHandler.data?.map((site) => (
        <EditableSite
          key={site.siteId}
          site={site}
          insightId={insightId}
          deleteSite={sitesDataHandler.deleteSite}
          updateSiteProps={sitesDataHandler.updateSiteProps}
          updateSiteKpis={sitesDataHandler.updateSiteProps}
          saveSiteSchedule={sitesDataHandler.saveSiteSchedule}
          deleteSiteSchedule={sitesDataHandler.deleteSiteSchedule}
          startSiteMonitoring={sitesDataHandler.startSiteMonitoring}
          stopSiteMonitoring={sitesDataHandler.stopSiteMonitoring}
          defaultExpanded={
            sitesDataHandler.data && sitesDataHandler.data.length === 1
          }
        />
      ))}
    </Box>
  );
}
