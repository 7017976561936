import { SmallCloseIcon } from '@chakra-ui/icons';
import {
  Box,
  Button,
  ButtonProps,
  Flex,
  HStack,
  IconButton,
  Modal,
  ModalBody,
  ModalBodyProps,
  ModalContent,
  ModalContentProps,
  ModalFooter,
  ModalFooterProps,
  ModalHeader,
  ModalHeaderProps,
  ModalOverlay,
  ModalOverlayProps,
  ModalProps,
} from '@chakra-ui/react';
import { ReactNode, useState } from 'react';
import { useIntl } from 'react-intl';

export interface ConfirmationModalProps {
  body: ReactNode;
  cancelButtonText?: string;
  confirmButtonColorScheme?: ButtonProps['colorScheme'];
  confirmButtonText?: string;
  onCancel: () => void;
  onConfirm: () => void | Promise<void>;
  open: boolean;
  title: ReactNode;
}

export default function ConfirmationModal(props: ConfirmationModalProps) {
  const { formatMessage } = useIntl();
  const [inProgress, setInProgress] = useState(false);
  const {
    body,
    cancelButtonText = formatMessage({
      id: 'HSXXAS',
      description: 'Confirmation modal cancel button text.',
      defaultMessage: 'Cancel',
    }),
    confirmButtonColorScheme = 'greenDark',
    confirmButtonText = formatMessage({
      id: 'rqXCrj',
      description: 'Confirmation modal confirm button text.',
      defaultMessage: 'Confirm',
    }),
    onCancel,
    onConfirm,
    open,
    title,
  } = props;
  return (
    <StyledModal
      isOpen={open}
      onClose={onCancel}
      title={title}
      footer={
        <HStack spacing={2}>
          <Button
            data-testid="confirmation-modal-confirm-button"
            colorScheme={confirmButtonColorScheme}
            onClick={async () => {
              setInProgress(true);
              try {
                await onConfirm();
              } finally {
                setInProgress(false);
              }
            }}
            isLoading={inProgress}
          >
            {confirmButtonText}
          </Button>
          <Button onClick={onCancel} variant="outline">
            {cancelButtonText}
          </Button>
        </HStack>
      }
    >
      {body}
    </StyledModal>
  );
}

export function StyledModal({
  title,
  children,
  footer,
  isOpen,
  onClose,
  modalProps,
  modalOverelayProps,
  modalContentProps,
  modalHeaderProps,
  modalBodyProps,
  modalFooterProps,
}: {
  title: ReactNode;
  children: ReactNode;
  footer?: ReactNode;
  isOpen: ModalProps['isOpen'];
  onClose: ModalProps['onClose'];
  modalProps?: Omit<ModalProps, 'isOpen' | 'onClose' | 'children'>;
  modalOverelayProps?: ModalOverlayProps;
  modalContentProps?: Omit<ModalContentProps, 'children'>;
  modalHeaderProps?: Omit<ModalHeaderProps, 'children'>;
  modalBodyProps?: Omit<ModalBodyProps, 'children'>;
  modalFooterProps?: Omit<ModalFooterProps, 'children'>;
}) {
  return (
    <Modal isCentered {...(modalProps ?? {})} isOpen={isOpen} onClose={onClose}>
      <ModalOverlay
        backgroundColor="whiteAlpha.800"
        {...(modalOverelayProps ?? {})}
      />
      <ModalContent
        padding={8}
        gap={4}
        borderRadius="32px"
        {...(modalContentProps ?? {})}
      >
        <ModalHeader padding={0} {...(modalHeaderProps ?? {})}>
          <Flex justifyContent="space-between" gap={2}>
            <Box flex={1}>{title}</Box>
            <IconButton
              variant="solid"
              size="sm"
              aria-label="close"
              onClick={onClose}
              icon={<SmallCloseIcon />}
            />
          </Flex>
        </ModalHeader>
        <ModalBody padding={0} {...(modalBodyProps ?? {})}>
          {children}
        </ModalBody>
        {footer && (
          <ModalFooter
            padding={0}
            justifyContent="start"
            {...(modalFooterProps ?? {})}
          >
            {footer}
          </ModalFooter>
        )}
      </ModalContent>
    </Modal>
  );
}
