import {
  Box,
  Flex,
  Heading,
  IconButton,
  Link,
  List,
  ListItem,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Text,
  VStack,
} from '@chakra-ui/react';
import { useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { ExternalLinkIcon, SettingsIcon } from '@chakra-ui/icons';
import ConfirmationModal from 'design-system/molecules/confirmation-modal';
import usePublicViewData from '../../data/usePublicViewData';
import {
  DataHandlerFeedback,
  hasDataHandlerFeedBack,
} from '../../utils/dataHandler';
import { PublicView } from '../../types/views';
import { EditViewForm } from './EditViewForm';
import { getPublicViewURL } from '../../config/envConfig';
import { useFailureToast } from '../../hooks/useFailureToast';
import { useSuccessToast } from '../../hooks/useSuccessToast';
import { errorReport } from '../../utils/errors';
import useSiteData from '../../data/useSiteData';
import { useAnalytics } from '../../hooks/analytics/useAnalytics';
import useRoutesData from '../../data/useRoutesData';
import { UserViewConfigParams } from './utils';

function PublicViewListItem({
  view,
  insightId,
  siteViewParams,
  onDelete,
  applyCurrent,
}: {
  view: PublicView;
  insightId: number;
  siteViewParams: UserViewConfigParams;
  onDelete: () => Promise<void>;
  applyCurrent: () => Promise<void>;
}) {
  const [isEditing, setIsEditing] = useState(false);
  const [isConfirmingDelete, setConfirmingDelete] = useState(false);
  const [isConfirmingUpdate, setConfirmingUpdate] = useState(false);
  const failureToast = useFailureToast();
  const successToast = useSuccessToast();
  const { formatMessage } = useIntl();
  const url = `${getPublicViewURL()}/v/${view.linkId}`;

  return (
    <ListItem
      borderRadius={4}
      width="full"
      key={view.viewId}
      border={isEditing ? '1px solid' : undefined}
    >
      <ConfirmationModal
        open={isConfirmingDelete}
        onCancel={() => setConfirmingDelete(false)}
        onConfirm={async () => {
          try {
            await onDelete();
            successToast({
              title: formatMessage({
                defaultMessage: 'View deleted',
                id: 'DS4clp',
                description: 'Public link dropdown menu - View deleted',
              }),
            });
          } catch (e) {
            errorReport.handled(e);
            failureToast({
              title: formatMessage({
                defaultMessage: 'Error deleting the view',
                id: 'mvzGtt',
                description:
                  'Public link dropdown menu - Error deleting the view',
              }),
            });
          }
        }}
        title={formatMessage({
          defaultMessage: 'Delete view?',
          id: 'BKXD9B',
          description: 'Public view list - delete dialog title',
        })}
        body={formatMessage({
          defaultMessage:
            'This view is currently shared publicly and cannot be undone. Are you sure you want to delete it?',
          id: 'TV4Srw',
          description: 'Public view list - delete dialog content',
        })}
        confirmButtonColorScheme="red"
        confirmButtonText="Delete view"
      />
      <ConfirmationModal
        open={isConfirmingUpdate}
        onCancel={() => setConfirmingUpdate(false)}
        onConfirm={async () => {
          try {
            await applyCurrent();
            setConfirmingUpdate(false);
            successToast({
              title: formatMessage({
                defaultMessage: 'View updated',
                id: 'X9Yq0Y',
                description: 'Public link dropdown menu - View updated',
              }),
            });
          } catch (e) {
            errorReport.handled(e);
            failureToast({
              title: formatMessage({
                defaultMessage: 'Error updating the view',
                id: 'NVEwkt',
                description:
                  'Public link dropdown menu - Error updating the view',
              }),
            });
          }
        }}
        title={formatMessage({
          defaultMessage: 'Update view?',
          id: 'DWQya7',
          description: 'Public view list - update dialog title',
        })}
        body={formatMessage({
          defaultMessage:
            'This view is currently shared publicly. Are you sure you want to update the visible routes and time period?',
          id: 'IdxZge',
          description: 'Public view list - update dialog content',
        })}
        confirmButtonText="Update"
      />
      <Flex
        justifyContent="space-between"
        alignItems="center"
        backgroundColor="white"
        borderRadius={4}
        py={1}
        pl={2}
        pr={1}
        position="relative"
      >
        <Link
          data-testid={`${view.name}-link`}
          href={`${getPublicViewURL()}/v/${view.linkId}`}
          isExternal
        >
          <Flex alignItems="center">
            <Text
              data-testid={`${view.name}`}
              fontWeight="bold"
              fontSize="xs"
              m={0}
              color="green"
            >
              {view.name}
            </Text>
            <ExternalLinkIcon mx={1} />
          </Flex>
        </Link>
        <Menu size="xs">
          {({ isOpen }) => (
            <>
              <MenuButton
                isActive={isOpen}
                as={IconButton}
                size="xs"
                variant="ghost"
                icon={<SettingsIcon />}
                data-testid={`${view.name}-menu-button`}
              />
              <MenuList>
                <MenuItem as={Link} href={url} isExternal fontSize="xs">
                  <Flex justifyContent="space-between" width="full">
                    <Text m={0}>View public site</Text>{' '}
                    <ExternalLinkIcon mx={1} />
                  </Flex>
                </MenuItem>
                <MenuItem
                  fontSize="xs"
                  onClick={async () => {
                    try {
                      await navigator.clipboard.writeText(url);
                      successToast({
                        title: formatMessage({
                          defaultMessage: 'Link copied',
                          id: 'G39M+e',
                          description:
                            'Public link dropdown menu - Link copied',
                        }),
                      });
                    } catch (error) {
                      errorReport.handled(error);
                      failureToast({
                        title: formatMessage({
                          defaultMessage: 'Could not copy the link',
                          id: 'dhg94u',
                          description:
                            'Public link dropdown menu - Failed to copy the link',
                        }),
                      });
                    }
                  }}
                >
                  <FormattedMessage
                    defaultMessage="Copy to clipboard"
                    id="QePekS"
                    description="Copy to clipboard."
                  />
                </MenuItem>
                <MenuItem
                  onClick={() => setIsEditing(true)}
                  fontSize="xs"
                  isDisabled={!view.canEdit}
                  data-testid={`${view.name}-edit-button`}
                >
                  Edit name &amp; graph selection
                </MenuItem>
                <MenuItem
                  onClick={() => setConfirmingUpdate(true)}
                  fontSize="xs"
                  isDisabled={!view.canEdit}
                >
                  Update view with current route &amp; time selection
                </MenuItem>
                <MenuItem
                  onClick={() => setConfirmingDelete(true)}
                  fontSize="xs"
                  isDisabled={!view.canEdit}
                  data-testid={`${view.name}-delete-button`}
                >
                  Delete view
                </MenuItem>
              </MenuList>
            </>
          )}
        </Menu>
      </Flex>
      {isEditing && (
        <Box p={4}>
          <EditViewForm
            insightId={insightId}
            editable={view.canEdit}
            viewId={view.viewId}
            name={view.name}
            showViewForm={() => setIsEditing(false)}
            siteViewParams={siteViewParams}
            publicViewConfig={view.config}
            publicView
          />
        </Box>
      )}
    </ListItem>
  );
}
export function PublicViewList({
  insightId,
  siteId,
  siteViewParams,
}: {
  insightId: number;
  siteId: number;
  siteViewParams: UserViewConfigParams;
}) {
  const { track } = useAnalytics();
  const publicViewsHandler = usePublicViewData(insightId, siteId);
  const siteDataHandler = useSiteData({
    orgId: insightId.toString(),
    siteId: siteId.toString(),
  });
  const routesDataHandler = useRoutesData(siteId);
  const deletePublicView = async (viewId) => {
    await publicViewsHandler.deletePublicView(viewId);
    track('Public View Deleted', { referrer: 'View Site Tab' });
  };
  if (
    hasDataHandlerFeedBack([
      publicViewsHandler,
      siteDataHandler,
      routesDataHandler,
    ])
  ) {
    return (
      <DataHandlerFeedback
        configParam={{ error: null }}
        dataHandlersParam={[
          publicViewsHandler,
          siteDataHandler,
          routesDataHandler,
        ]}
      />
    );
  }
  if (!publicViewsHandler.data?.length) {
    return null;
  }
  return (
    <VStack alignItems="flex-start" width="full" pt={2}>
      <Heading size="xs">
        <FormattedMessage
          defaultMessage="Publicly available views"
          id="SMAwGX"
          description="Publicly available views header"
        />
      </Heading>
      <List spacing={3} width="full">
        {(publicViewsHandler.data || []).map((view) => (
          <PublicViewListItem
            key={view.viewId}
            view={view}
            insightId={insightId}
            siteViewParams={siteViewParams}
            applyCurrent={async () => {
              const trackIds = siteViewParams.hidden
                ? siteViewParams.hidden.split(',')
                : [];

              const viewUpdate: Partial<PublicView> = {
                viewId: view.viewId,
                name: view.name,
                config: {
                  timePeriod: siteViewParams.timePeriod,
                  endDate: siteViewParams.date,
                  startDate: siteViewParams.startDate,
                  charts: view.config.charts,
                  routes: (routesDataHandler.data || [])
                    .filter((r) => trackIds.indexOf(r.trackId) < 0)
                    .map((r) => r.routeId),
                },
              };
              await publicViewsHandler.editPublicView(viewUpdate);
            }}
            onDelete={() => deletePublicView(view.viewId)}
          />
        ))}
      </List>
    </VStack>
  );
}
