export const UNIT_SYSTEM_TYPE_METRIC: string = 'METRIC';
export const UNIT_SYSTEM_TYPE_IMPERIAL: string = 'IMPERIAL';

export const MIN_SPEED: string = 'MIN_SPEED';
export const MAX_TIME_DELAY: string = 'MAX_TIME_DELAY';
export const MAX_QUEUE_LENGTH: string = 'MAX_QUEUE_LENGTH';
export const MAX_SITE_IMPACT_DELAY: string = 'MAX_SITE_IMPACT_DELAY';
export const MAX_DUST: string = 'MAX_DUST';
export const MAX_NOISE: string = 'MAX_NOISE';
export const TOTAL_RAIN: string = 'TOTAL_RAIN';
export const SUM_TRUCK_HOURS: string = 'SUM_TRUCK_HOURS';
export const SUM_DWELL_HOURS: string = 'SUM_DWELL_HOURS';
export const SUM_TRIP_COUNTS: string = 'SUM_TRIP_COUNTS';
