import useSWR from 'swr';
import { WeekDay } from '@lib/dates';
import { DataType } from '@webapp/bff';
import { dataTrpc } from '../config/trpc';
import { PlanInsightType } from '../constants/path';
import { PredictionMode } from '../types/plan';

export interface PlanRequestParams {
  insightId: string;
  insightType: PlanInsightType;
  siteId: number;
  siteTimeZone: string;
  startDate?: number;
  endDate?: number;
  daysOfTheWeek: number[];
  predictionMode: PredictionMode;
  isNightShift: boolean;
}
type PopulatedPlanRequestParams = Required<PlanRequestParams>;
function IsPopulatedPlanRequestParams(
  params: PlanRequestParams
): params is PopulatedPlanRequestParams {
  return params.startDate !== undefined && params.endDate !== undefined;
}

export interface PlanningTimeDataHandler {
  data:
    | {
        routeId: number;
        name: string;
        label: string;
        items: {
          date: number;
          value: number;
        }[];
      }[][]
    | undefined;
  error: any;
  isValidating: boolean;
}

export default function usePlanningTimeData(
  params: PlanRequestParams
): PlanningTimeDataHandler {
  const { data, isValidating, error } = useSWR(
    IsPopulatedPlanRequestParams(params)
      ? { keyName: 'usePlanningTimeData', params }
      : null,
    ({ params: populatedParams }) =>
      Promise.all(
        // This is a nested promise chain that fetches data for aggregated days, then for each day of the week.
        // It was changed from parallel to serial fetching to avoid overloading the server.
        populatedParams.daysOfTheWeek.sort().reduce(
          (acc, dayValue, callIndex) => [
            ...acc,
            acc[callIndex].then(() =>
              fetchPlanningData({
                ...populatedParams,
                daysOfTheWeek: [dayValue],
              })
            ),
          ],
          [fetchPlanningData(populatedParams)] as ReturnType<
            typeof fetchPlanningData
          >[]
        )
      ),
    {
      revalidateOnFocus: false,
      revalidateOnReconnect: false,
      revalidateIfStale: false,
    }
  );
  return {
    data,
    isValidating,
    error,
  };
}

const weekdaysArray: WeekDay[] = [
  'SUN',
  'MON',
  'TUE',
  'WED',
  'THU',
  'FRI',
  'SAT',
];
function fetchPlanningData(params: Required<PlanRequestParams>) {
  return dataTrpc.planningData.get
    .query({
      siteId: params.siteId.toString(),
      startTime: params.startDate,
      endTime: params.endDate,
      dataType: insightTypeToReqParam(params.insightType),
      nightWorks: params.isNightShift ?? false,
      percentile:
        params.predictionMode === PredictionMode.PESSIMISTIC ? 0.85 : 0,
      selectedWeekDays: params.daysOfTheWeek.map((d) => weekdaysArray[d]),
      timeZone: params.siteTimeZone,
      dataSource: 'Google',
    })
    .then((routeDataList) =>
      routeDataList.map((routeData) => ({
        routeId: routeData.routeId,
        name: routeData.trackId,
        label: routeData.label,
        items: routeData.datapoints.map((dp) => ({
          date: dp.time,
          value: dp.value!,
        })),
      }))
    );
}

function insightTypeToReqParam(insightType: PlanInsightType): DataType {
  switch (insightType) {
    case PlanInsightType.DELAY:
      return 'TIME_DELAY';
    case PlanInsightType.TIME:
      return 'JOURNEY_TIME';
    case PlanInsightType.IMPACT:
      return 'SITE_IMPACT_DELAY';
    case PlanInsightType.SPEED:
      return 'SPEED';
    default:
      return 'JOURNEY_TIME';
  }
}
