import './EditableSiteSchedules.scss';
import { useState } from 'react';
import InfoTooltip from 'design-system/atoms/info-tooltip';
import { FormattedMessage, useIntl } from 'react-intl';
import { Box } from '@chakra-ui/react';
import EditableSiteStatus from './EditableSiteStatus';
import { MESSAGE_UI_DISABLED_SITE_MONITOR_PERMISSION } from '../../constants/messages';
import EditableSiteSchedule from './EditableSiteSchedule';
import { hasScheduleOverlap, sortFutureSchedules } from '../../utils/schedules';
import { Site } from '../../types/site';
import { BlankSchedule, Schedule } from '../../types/schedule';

interface EditableSiteSchedulesProps {
  siteId: Site['siteId'];
  siteTimeZone: Site['siteTimeZone'];
  isSiteActive: Site['active'];
  siteSchedules: Site['schedules'];
  saveSiteSchedule: (
    siteId: number,
    scheduleProps: Schedule | BlankSchedule
  ) => Promise<unknown>;
  deleteSiteSchedule: (siteId: number, scheduleId: number) => Promise<unknown>;
  startSiteMonitoring: (siteId: number) => Promise<unknown>;
  stopSiteMonitoring: (siteId: number) => Promise<unknown>;
  hasSiteMonitorPermission: boolean;
}
export default function EditableSiteSchedules({
  siteId,
  siteTimeZone,
  isSiteActive,
  siteSchedules,
  saveSiteSchedule,
  deleteSiteSchedule,
  startSiteMonitoring,
  stopSiteMonitoring,
  hasSiteMonitorPermission,
}: EditableSiteSchedulesProps) {
  const { formatMessage } = useIntl();
  const [isCreatingSchedule, setCreatingSchedule] = useState(false);
  const [editingSchedules, setEditingSchedules] = useState<number[]>([]);
  const sortedFutureSchedules = sortFutureSchedules(
    siteSchedules,
    isSiteActive
  );
  const nextSchedule = sortedFutureSchedules[0];
  const saveSchedule = (schedule: Schedule | BlankSchedule) =>
    saveSiteSchedule(siteId, schedule);
  const deleteSchedule = (schedule: Schedule) =>
    deleteSiteSchedule(siteId, schedule.scheduleId);
  const startMonitoring = () => startSiteMonitoring(siteId);
  const stopMonitoring = () => stopSiteMonitoring(siteId);

  const toggleEditingSchedule = (schedule: Schedule) =>
    setEditingSchedules((asyncEditingSchedules) =>
      asyncEditingSchedules.indexOf(schedule.scheduleId) > -1
        ? asyncEditingSchedules.filter(
            (filterId) => filterId !== schedule.scheduleId
          )
        : [...asyncEditingSchedules, schedule.scheduleId]
    );

  return (
    <div className="v2-editable-site-schedules">
      <h3 className="v2-editable-site-schedules__title">
        <FormattedMessage
          defaultMessage="Monitoring Schedules"
          id="qfQ6gM"
          description="Monitoring schedules configuration panel title."
        />
      </h3>
      <EditableSiteStatus
        isSiteActive={isSiteActive}
        siteTimeZone={siteTimeZone}
        nextSchedule={nextSchedule}
        startMonitoring={startMonitoring}
        stopMonitoring={stopMonitoring}
        hasSiteMonitorPermission={hasSiteMonitorPermission}
        startEditingSchedule={() => {
          if (
            nextSchedule &&
            editingSchedules.indexOf(nextSchedule.scheduleId) === -1
          ) {
            toggleEditingSchedule(nextSchedule);
          }
          if (!nextSchedule) {
            setCreatingSchedule(true);
          }
        }}
      />
      {sortedFutureSchedules.map((schedule) => {
        const hasOverlap = hasScheduleOverlap(schedule, sortedFutureSchedules);
        return (
          <EditableSiteSchedule
            key={schedule.scheduleId}
            siteTimeZone={siteTimeZone}
            schedule={schedule}
            saveSchedule={saveSchedule}
            deleteSchedule={deleteSchedule}
            hasSiteMonitorPermission={hasSiteMonitorPermission}
            hasOverlap={hasOverlap}
            disableStartDate={isSiteActive && schedule === nextSchedule}
            isEditing={editingSchedules.indexOf(schedule.scheduleId) > -1}
            toggleEditing={() => toggleEditingSchedule(schedule)}
          />
        );
      })}
      {isCreatingSchedule ? (
        <EditableSiteSchedule
          siteTimeZone={siteTimeZone}
          schedule={{
            scheduleName: `Schedule ${sortedFutureSchedules.length + 1}`,
            startDate: 0,
            endDate: 0,
          }}
          saveSchedule={saveSchedule}
          toggleEditing={() => setCreatingSchedule(false)}
          isEditing
          disableStartDate={isSiteActive && !nextSchedule}
        />
      ) : (
        <Box textAlign="end">
          <InfoTooltip
            content={
              !hasSiteMonitorPermission
                ? formatMessage(MESSAGE_UI_DISABLED_SITE_MONITOR_PERMISSION)
                : undefined
            }
          >
            <button
              type="button"
              onClick={() => {
                setCreatingSchedule(true);
              }}
              className="v2-editable-site-schedules__btn-add"
              disabled={!hasSiteMonitorPermission}
            >
              {'+ '}
              <FormattedMessage
                defaultMessage="Add schedule"
                id="XnvJ0d"
                description="Add schedule button label."
              />
            </button>
          </InfoTooltip>
        </Box>
      )}
    </div>
  );
}
