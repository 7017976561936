import { FormattedMessage } from 'react-intl';
import { Box, Button, Heading, Text, VStack } from '@chakra-ui/react';
import { useState, useRef } from 'react';
import {
  RoutePollingStatusFailureType,
  RoutePollingStatusDataFailure,
} from '../../data/useRoutePollingStatusData';
import RouteMap from './RouteMap';
import { useActionWithFeedback } from '../../hooks/useActionWithFeedback';
import { LoadingFeedbackBlock } from '../../utils/dataHandler';
import OtherCauses from './OtherCauses';
import MoreDetailsForm from './MoreDetailsForm';
import { TextFailureStatus } from './TextContent';

export default function UpdateResponse({
  pollingStatus,
  setResponse,
  newResponse,
}: {
  pollingStatus: RoutePollingStatusDataFailure;
  setResponse: (newResponse: {
    responseType: RoutePollingStatusFailureType;
    responseComment?: string;
  }) => Promise<void>;
  newResponse?: {
    responseType: RoutePollingStatusFailureType | 'OTHER';
    responseComment?: string;
  };
}) {
  const hasHandledInitialResponse = useRef(false);
  const [isResponseOther, setIsResponseOther] = useState(false);
  const {
    isPerformingAction: isSettingResponse,
    performAction: setResponseWithFeedback,
  } = useActionWithFeedback({
    action: setResponse,
    successMessage: false,
  });

  if (hasHandledInitialResponse.current === false) {
    hasHandledInitialResponse.current = true;
    if (newResponse) {
      if (newResponse.responseType === 'OTHER') {
        setIsResponseOther(true);
      } else {
        setResponseWithFeedback({
          responseType: newResponse.responseType,
          responseComment: newResponse.responseComment,
        });
      }
      return null;
    }
  }
  if (isSettingResponse) {
    return <LoadingFeedbackBlock />;
  }
  return (
    <VStack alignItems="flex-start" spacing="4">
      <Heading size="2xl">
        <FormattedMessage
          id="ev3HJD"
          defaultMessage="Data collection has been interrupted for {routeName}"
          values={{ routeName: pollingStatus.route.name }}
        />
      </Heading>

      <RouteMap route={pollingStatus.route} />

      <Box>
        <Text fontSize="2xl">
          <TextFailureStatus pollingStatus={pollingStatus} />
        </Text>
        <Text mb={1}>
          <FormattedMessage
            id="rqAi2j"
            defaultMessage="This often occurs due to road closures, or when road layouts have recently been changed."
          />
        </Text>
        <Text mb={1}>
          <FormattedMessage
            id="E96hj+"
            defaultMessage="Help us confirm if that is the case."
          />
        </Text>
      </Box>
      {!isResponseOther ? (
        <VStack alignItems="flex-start" spacing="4">
          <Button
            onClick={() => setResponseWithFeedback({ responseType: 'CLOSURE' })}
            variant="solid"
            colorScheme="greenDark"
          >
            <FormattedMessage id="C0UCOI" defaultMessage="Road is closed" />
          </Button>
          <Button
            onClick={() =>
              setResponseWithFeedback({ responseType: 'ROAD_LAYOUT' })
            }
            variant="solid"
            colorScheme="greenDark"
          >
            <FormattedMessage
              id="dHjyeb"
              defaultMessage="Road layout has changed"
            />
          </Button>
          <Button
            onClick={() => {
              setIsResponseOther(true);
            }}
            variant="outline"
          >
            <FormattedMessage id="qm/eL5" defaultMessage="Something else" />
          </Button>
        </VStack>
      ) : (
        <VStack alignItems="flex-start" spacing="20" width="full">
          <MoreDetailsForm
            currentResponse={{
              responseType: 'UNKNOWN_CAUSE',
            }}
            setNewResponse={(response) => {
              setResponseWithFeedback(response);
            }}
          />
          <OtherCauses
            currentResponseType="UNKNOWN_CAUSE"
            setNewResponse={(otherResponse) => {
              if (otherResponse.responseType !== 'OTHER') {
                setResponseWithFeedback({
                  responseType: otherResponse.responseType,
                  responseComment: otherResponse.responseComment,
                });
              }
            }}
          />
        </VStack>
      )}
    </VStack>
  );
}
