import { ReactNode } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { Box, Heading, Text } from '@chakra-ui/react';
import GridWrapper from 'design-system/atoms/grid-wrapper';
import Insight from 'design-system/molecules/insight';
import StatImpactIcon from '../../svg/stat_impact.svg?react';
import { RoutePerformance } from '../../types/route';
import { getInsightPerformanceConfigFromType } from '../../utils/insightCharts';

interface RouteCurrentPerformanceProps {
  performanceData: RoutePerformance;
}

export default function RouteCurrentPerformance({
  performanceData,
}: RouteCurrentPerformanceProps) {
  const { formatMessage } = useIntl();
  return (
    <>
      <Heading as="h3" fontSize="18px" mb={6}>
        <FormattedMessage
          defaultMessage="Current performance"
          id="EcFSJG"
          description="Current route performance panel title"
        />
      </Heading>
      <Box w="full" overflowX="auto" mb={-4} pb={4}>
        <GridWrapper>
          {performanceData.insights.map((insight) => {
            const insights: ReactNode[] = [];
            const insightPerformanceConfig =
              getInsightPerformanceConfigFromType(
                insight.insightType,
                { siteUnit: performanceData.siteUnit },
                formatMessage
              );
            if (
              insight.anomalyScore &&
              insightPerformanceConfig.colorForScoreValue &&
              insightPerformanceConfig.formatScoreValue
            ) {
              insights.push(
                <Insight
                  IconSVG={StatImpactIcon}
                  label={`Current ${insightPerformanceConfig.title.toLowerCase()}`}
                  title={
                    <Text
                      m={0}
                      color={insightPerformanceConfig.colorForScoreValue(
                        insight.anomalyScore
                      )}
                    >
                      {insightPerformanceConfig.formatScoreValue(
                        insight.anomalyScore
                      )}
                    </Text>
                  }
                  paragraph={formatMessage({
                    defaultMessage: 'compared to 4 week average',
                    id: 'eIzv6n',
                    description:
                      'Current route performance - stat diff disclaimer',
                  })}
                />
              );
            }
            if (insightPerformanceConfig.iconSVG) {
              insights.push(
                <Insight
                  IconSVG={insightPerformanceConfig.iconSVG}
                  label={`Current ${insightPerformanceConfig.title.toLowerCase()}`}
                  title={insightPerformanceConfig.formatValue(
                    insight.currentValue
                  )}
                />
              );
            }
            return insights;
          })}
        </GridWrapper>
      </Box>
    </>
  );
}
