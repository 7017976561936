import useSWR from 'swr';
import { components } from '../types/schema';
import { dataTrpc } from '../config/trpc';

export type RouteInsights = components['schemas']['RouteInsights'];

export default function useRouteInsightsData(
  trackId: string,
  insightId: string,
  siteId: number
) {
  const { data, error, isValidating } = useSWR<RouteInsights>(
    `useRouteInsightsData/${insightId}/${trackId}`,
    () =>
      dataTrpc.routeInsights.get.query({
        passageId: trackId,
        orgId: insightId,
        siteId: `${siteId}`,
      }) as Promise<RouteInsights>,
    {
      refreshInterval: 5 * 60 * 1000,
    }
  );
  return {
    data,
    error,
    isValidating,
  };
}
