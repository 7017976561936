import { AddIcon } from '@chakra-ui/icons';
import { Box, Button, Flex, VStack, useDisclosure } from '@chakra-ui/react';
import { ShowIcon } from 'design-system/atoms/custom-icons';
import type { LiveJourneyTimeMessageContent } from '@vms/shared/src/message-types/live-journey-time';
import { UseFormReturn } from 'react-hook-form';
import { FormattedMessage } from 'react-intl';
import { useParams } from 'react-router-dom';
import { MessageTypeFormType } from './messageTypes';
import Frame from './Frame';
import VmsPreview from './VmsPreview';
import { useAnalytics } from '../../hooks/analytics/useAnalytics';

export default function StepBoardMessageTypeLiveJourneyTimeTab({
  routes,
  formManager,
}: {
  routes: { routeId: number; name: string }[];
  formManager: UseFormReturn<MessageTypeFormType>;
}) {
  const { watch, setValue } = formManager;
  const liveJourneyTimeContent = watch('liveJourneyTimeContent');
  const content = liveJourneyTimeContent ?? [];
  const { vmsConfigId } = useParams();
  const { track } = useAnalytics();
  const {
    isOpen: isPreviewOpen,
    onClose: onPreviewClose,
    onOpen,
  } = useDisclosure();

  const handleDeleteFrame = (index: number) => {
    setValue(
      'liveJourneyTimeContent',
      content.filter((_, ind) => ind !== index)
    );
  };

  const handleSetValue = (value: LiveJourneyTimeMessageContent[]) => {
    setValue('liveJourneyTimeContent', value);
  };

  const handleAddFrame = () => {
    handleSetValue(
      content.concat([
        { lines: [], key: `frame-${Math.random()}-${Date.now()}` },
      ])
    );
  };

  const handleFrameChange = (
    frameIndex: number,
    newFrame: LiveJourneyTimeMessageContent
  ) => {
    handleSetValue(content.map((b, i) => (i === frameIndex ? newFrame : b)));
  };

  return (
    <VStack bgColor="gray.50" borderRadius="16px" p={4} spacing={6}>
      <Flex
        w="full"
        justifyContent="space-between"
        gap="2"
        direction={{ base: 'column', md: 'row' }}
      >
        <Box fontSize="md" fontWeight="bold">
          <FormattedMessage
            defaultMessage="Display live journey times for your selected routes"
            id="BChCwC"
          />
        </Box>
        <Box flex="0">
          <Button
            size="xs"
            variant="outline"
            leftIcon={<ShowIcon />}
            onClick={() => {
              onOpen();
              track('Preview button clicked', {
                property: 'Preview button',
                referrer: 'Live journey time tab',
              });
            }}
          >
            <FormattedMessage defaultMessage="Preview message" id="qZMvvj" />
          </Button>
        </Box>
      </Flex>
      {content.map((box, index) => (
        <Frame
          canAddTag
          key={box.key}
          index={index}
          content={box}
          routes={routes}
          onDelete={() => handleDeleteFrame(index)}
          onContentChange={(newContent) => handleFrameChange(index, newContent)}
        />
      ))}
      <Flex justifyContent="center">
        <Button
          size="xs"
          variant="outline"
          leftIcon={<AddIcon />}
          onClick={handleAddFrame}
        >
          <FormattedMessage defaultMessage="Add frame" id="vSbhQI" />
        </Button>
      </Flex>
      {isPreviewOpen && (
        <VmsPreview
          messageConfig={{ type: 'LiveJourneyTime', content }}
          vmsConfigId={vmsConfigId}
          isLive={false}
          isOpen={isPreviewOpen}
          onCancel={onPreviewClose}
        />
      )}
    </VStack>
  );
}
