import { useCallback, useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Color from 'color';
import { Polyline } from '@react-google-maps/api';
import useMapBounds from '../../hooks/useMapBounds';
import { SiteOverviewModel } from '../../types/site';
import { parseWkt } from '../../utils/routes';
import BaseMap from '../BaseMap';
import { MAP_DEFAULT_CENTER } from '../../constants/map';
import {
  getColorBySiteId,
  getLabelColorBySiteId,
  getLabelFontColorBySiteId,
} from '../../utils/colors';
import { pathSitesView } from '../../constants/path';
import useParamInsightId from '../../hooks/useParamInsightId';
import ReactSVGMarker from '../BaseMap/ReactSVGMarker';
import Point = google.maps.Point;

type LatLngLiteral = google.maps.LatLngLiteral;

interface HomepageMapProps {
  Wrapper: typeof BaseMap;
  onLoad?: (map: google.maps.Map) => void;
  onMapClick?: (e: google.maps.MapMouseEvent) => void;
  mapCursor?: string;
  sites: SiteOverviewModel[];
  mapCenter?: LatLngLiteral;
  selectedSiteId: number | null;
  setSelectedSiteId: (siteId: number | null) => void;
  checkVisibility: (s: SiteOverviewModel) => boolean;
}
interface SiteLabelProps {
  color: string;
  labelClassName: string;
  locationOffset: number;
  summary: SiteOverviewModel;
}
interface RouteProps {
  routeId: string;
  wkt: string;
  color: string;
  summary: SiteOverviewModel;
  path: LatLngLiteral[];
}

const OVERVIEW_MAP_OPTIONS = {
  minZoom: 3,
  mapTypeControlOptions: {
    style: google.maps.MapTypeControlStyle.DEFAULT,
    position: google.maps.ControlPosition.BOTTOM_LEFT,
    mapTypeIds: [
      google.maps.MapTypeId.ROADMAP,
      google.maps.MapTypeId.SATELLITE,
    ],
  },
};

export default function HomepageMap({
  Wrapper,
  onLoad,
  onMapClick,
  mapCursor,
  sites = [],
  selectedSiteId,
  setSelectedSiteId,
  mapCenter = MAP_DEFAULT_CENTER,
  checkVisibility,
}: HomepageMapProps) {
  const [mapInstance, setMapInstance] = useState<google.maps.Map>();
  const insightId = useParamInsightId();
  const onCountMapLoad = useCallback(
    (map: google.maps.Map) => {
      setMapInstance(map);
      if (onLoad) {
        onLoad(map);
      }
    },
    [onLoad, setMapInstance]
  );

  const formattedData = useMemo(() => {
    const existingLocations = {};
    const showRoutesForInactive =
      sites.reduce((count, s) => count + (s.routes?.length ?? 0), 0) <= 500;
    const formattedSites = sites.reduce(
      (acc, site) => {
        const labelColor = getLabelColorBySiteId(site.siteId!);
        const routesColor = getColorBySiteId(site.siteId!);
        let locationOffset = 0;
        const siteLocation = site.siteLocation || MAP_DEFAULT_CENTER;
        if (existingLocations[`${siteLocation.lat}-${siteLocation.lng}`]) {
          locationOffset =
            existingLocations[`${siteLocation.lat}-${siteLocation.lng}`];
          existingLocations[`${siteLocation.lat}-${siteLocation.lng}`] =
            existingLocations[`${siteLocation.lat}-${siteLocation.lng}`] + 1;
        } else {
          existingLocations[`${siteLocation.lat}-${siteLocation.lng}`] = 1;
        }
        const siteProps = {
          summary: site,
          color: labelColor,
          labelClassName: `mapLabel_${site.siteId}`,
          locationOffset,
        } as SiteLabelProps;
        if (showRoutesForInactive || site.active) {
          const routesProps =
            site.routes?.map((r) => ({
              routeId: r.routeId,
              summary: site,
              wkt: r.wkt,
              path: parseWkt(r.wkt),
              color: routesColor,
            })) || [];
          acc.routes.push(...routesProps);
        }
        acc.sites.push(siteProps);
        return acc;
      },
      {
        sites: [],
        routes: [],
        insightId,
      } as {
        sites: SiteLabelProps[];
        routes: RouteProps[];
        insightId: string;
      }
    );
    formattedSites.sites = formattedSites.sites.reverse();
    return formattedSites;
  }, [sites, insightId]);
  const sources = useMemo(
    () =>
      sites
        .filter((s) => checkVisibility(s))
        .map((s) => ({
          location: {
            lat: (s?.siteLocation || MAP_DEFAULT_CENTER).lat!,
            lng: (s?.siteLocation || MAP_DEFAULT_CENTER).lng!,
          },
        })),
    [sites, checkVisibility]
  );

  const visibleRoutesBoundaries = useMemo(
    () =>
      formattedData.sites.filter((s) => checkVisibility(s.summary)).length > 10
        ? []
        : formattedData.routes.filter((r) => checkVisibility(r.summary)),
    [formattedData, checkVisibility]
  );

  useMapBounds({
    mapInstance,
    routes: visibleRoutesBoundaries,
    sources,
  });

  return (
    <Wrapper
      onLoad={onCountMapLoad}
      onMapClick={onMapClick}
      mapCursor={mapCursor}
      mapCenter={mapCenter}
      options={OVERVIEW_MAP_OPTIONS}
    >
      {formattedData.routes.map((routeProps) => (
        <Polyline
          key={`${routeProps.routeId}-${routeProps.summary.siteId}`}
          visible={checkVisibility(routeProps.summary)}
          options={{
            clickable: false,
            strokeColor: routeProps.color,
            strokeWeight:
              selectedSiteId === routeProps.summary.siteId ? 30 : 20,
            strokeOpacity:
              selectedSiteId === routeProps.summary.siteId ? 0.15 : 0.1,
          }}
          path={routeProps.path}
        />
      ))}
      {formattedData.routes.map((routeProps) => (
        <Polyline
          key={`${routeProps.routeId}-${routeProps.summary.siteId}`}
          visible={checkVisibility(routeProps.summary)}
          options={{
            clickable: false,
            strokeColor: routeProps.color,
            strokeWeight: 3,
            strokeOpacity: 1,
          }}
          path={routeProps.path}
        />
      ))}
      {formattedData.sites.map((siteProps) => (
        <SiteLabelMarker
          visible={checkVisibility(siteProps.summary)}
          key={siteProps.summary.siteId}
          siteId={siteProps.summary.siteId!}
          position={
            (siteProps.summary.siteLocation ||
              MAP_DEFAULT_CENTER) as LatLngLiteral
          }
          offset={siteProps.locationOffset}
          insightId={formattedData.insightId}
          color={siteProps.color}
          setSelectedSiteId={setSelectedSiteId}
          isSelected={siteProps.summary.siteId === selectedSiteId}
          siteName={siteProps.summary.siteName!}
        />
      ))}
    </Wrapper>
  );
}

function SiteLabelMarker({
  position,
  color,
  siteId,
  siteName,
  setSelectedSiteId,
  isSelected,
  insightId,
  visible,
  offset,
}: {
  position: LatLngLiteral;
  color: string;
  siteId: number;
  siteName: string;
  setSelectedSiteId: (s: number | null) => void;
  isSelected: boolean;
  insightId: string;
  visible: boolean;
  offset: number;
}) {
  const navigate = useNavigate();
  const onClick = useCallback(() => {
    navigate({
      pathname: pathSitesView(insightId),
      search: siteId ? `?siteId=${siteId}` : '',
    });
  }, [navigate, siteId, insightId]);
  const labelBoxColor = isSelected
    ? Color(color).lighten(0.1).toString()
    : color;
  const [labelWidth, setLabelWidth] = useState(200);

  useEffect(() => {
    const span = document.createElement('span');
    span.setAttribute(
      'style',
      'position: absolute; white-space: nowrap; visibility: hidden; font-size: 12px; font-family: Helvetica;'
    );
    span.innerText = siteName;
    document.body.appendChild(span);
    setLabelWidth(span.offsetWidth);
    document.body.removeChild(span);
  }, [siteName]);

  return !visible ? null : (
    <ReactSVGMarker
      onClick={onClick}
      position={position}
      svgComponentAnchor={new Point((labelWidth + 16) / 2, (offset + 1) * 20)}
      svgComponent={
        <SiteLabelSvg
          labelWidth={labelWidth}
          siteName={siteName}
          boxColor={labelBoxColor}
          textColor={getLabelFontColorBySiteId(siteId)}
        />
      }
      onMouseOver={() => {
        setSelectedSiteId(siteId);
      }}
      onMouseOut={
        isSelected
          ? () => {
              setSelectedSiteId(null);
            }
          : undefined
      }
    />
  );
}
function SiteLabelSvg({
  boxColor,
  textColor,
  siteName,
  labelWidth,
}: {
  boxColor: string;
  textColor: string;
  siteName: string;
  labelWidth: number;
}) {
  const shadowPadding = 4;
  const xPadding = 8;
  const boxWidth = labelWidth + xPadding * 2;
  const svgWidth = boxWidth + shadowPadding * 2;
  return (
    <svg
      width={svgWidth}
      height="27"
      viewBox={`0 0 ${svgWidth} 27`}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g filter="url(#filterDropShadow)">
        <rect
          fill={boxColor}
          className="site-marker__bg"
          width={boxWidth}
          x={shadowPadding}
          height="19"
          rx="6"
        />
      </g>
      <text
        className="site-marker__text"
        fontFamily="Helvetica, Arial, sans-serif"
        fontSize="12px"
        fill={textColor}
        x={shadowPadding + xPadding}
        y={14}
      >
        {siteName}
      </text>
      <defs>
        <filter
          id="filterDropShadow"
          x="0"
          y="0"
          width={svgWidth}
          height="27"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="4" />
          <feGaussianBlur stdDeviation="2" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_1_2"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_1_2"
            result="shape"
          />
        </filter>
      </defs>
    </svg>
  );
}
