import { Flex, Text } from '@chakra-ui/react';

import { Link } from 'react-router-dom';
import { ListItem } from 'design-system/molecules/list-item';
import { RouteSummary } from '../../data/useProjectRoutes';
import { pathSitesOverview } from '../../constants/path';
import useParamInsightId from '../../hooks/useParamInsightId';
import AnomalyIcon from './AnomalyIcon';

export interface RouteCardProps {
  route: RouteSummary;
  onMouseEnter?: () => void;
  onMouseLeave?: () => void;
}

export default function RouteCard({
  route,
  onMouseEnter,
  onMouseLeave,
}: RouteCardProps): JSX.Element {
  const insightId = useParamInsightId();
  return (
    <Link to={pathSitesOverview(insightId, 'routes', route.trackId)}>
      <ListItem
        onMouseEnter={onMouseEnter}
        onMouseLeave={onMouseLeave}
        title={route.routeName ?? ''}
        description={route.siteName ?? ''}
        rightActions={
          <Flex align="center" gap={1}>
            <Text fontSize="xs" margin={0} fontWeight="semibold">
              {route.anomalyScorePercentage}
            </Text>
            <AnomalyIcon
              anomalyScore={route.anomalyScore}
              color={route.anomalyScoreColor ?? 'gray.400'}
            />
          </Flex>
        }
      />
    </Link>
  );
}
