import useSWR from 'swr';
import { envConfig } from '../config/envConfig';

export type Project = {
  name: string;
  insightId: number;
};

export default function useProjectsData() {
  const { data, error, isValidating } = useSWR<Project[]>(
    `${envConfig.API_URL}/api/v1/insights`
  );
  return {
    data,
    error,
    isValidating,
  };
}
