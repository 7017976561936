import './EmptyCount.scss';

import { FormattedMessage } from 'react-intl';
import LayoutEmpty from 'design-system/molecules/layout-empty';
import NoCountData from '../../svg/no-data-woman.svg?react';

export default function EmptyCount() {
  return (
    <div className="empty-count">
      <LayoutEmpty
        image={<NoCountData width="310" />}
        title={
          <FormattedMessage
            defaultMessage="No sources available"
            id="0gg4yn"
            description="No count sources available"
          />
        }
        text={
          <>
            <p>
              <FormattedMessage
                defaultMessage="There is no count information available in this area"
                id="gp2I6Z"
                description="There is no count information available in this area"
              />
            </p>
            <p>
              <FormattedMessage
                defaultMessage="{contactUs} for more information"
                id="I8RBee"
                description="Contact us for more information"
                values={{
                  contactUs: (
                    <a
                      id="intercom-activator"
                      href="mailto:rkrctuz0@mooven.intercom-mail.com"
                      aria-label="Contact us"
                    >
                      <FormattedMessage
                        defaultMessage="Contact us"
                        id="MvYXSx"
                        description="Contact us"
                      />
                    </a>
                  ),
                }}
              />
            </p>
          </>
        }
      />
    </div>
  );
}
