import moment from 'moment-timezone';
import { TimePeriod } from '../types/timePeriod';

export enum DateFormat {
  JS_DATE = 'JS_DATE',
  UNIX_SECONDS = 'UNIX_SECONDS',
}
export interface DateBoundaries<TDateType> {
  startTime: TDateType;
  endTime: TDateType;
  compare:
    | undefined
    | {
        startTime: TDateType;
        endTime: TDateType;
      };
}

export function selectedStartDate(
  selectedPeriod: TimePeriod,
  timeZone: string,
  useMilliseconds = false
): number {
  const selectedEndDateMoment = moment
    .unix(selectedEndDate(selectedPeriod))
    .tz(timeZone);
  switch (selectedPeriod.type) {
    case 'TIME_PERIOD_CUSTOM':
      return selectedPeriod.startDate / (useMilliseconds ? 1 : 1000);
    case 'TIME_PERIOD_LAST_24_HOURS':
      return selectedEndDateMoment
        .subtract(1, 'day')
        [useMilliseconds ? 'valueOf' : 'unix']();
    case 'TIME_PERIOD_LAST_WEEK':
      return selectedEndDateMoment
        .subtract(1, 'week')
        [useMilliseconds ? 'valueOf' : 'unix']();
    case 'TIME_PERIOD_LAST_MONTH':
      return selectedEndDateMoment
        .subtract(1, 'month')
        [useMilliseconds ? 'valueOf' : 'unix']();
    default:
      return 0;
  }
}

export function getBoundariesForTimePeriod(
  timePeriod: TimePeriod,
  dateFormat?: DateFormat.JS_DATE
): DateBoundaries<Date>;
export function getBoundariesForTimePeriod(
  timePeriod: TimePeriod,
  dateFormat: DateFormat.UNIX_SECONDS
): DateBoundaries<number>;
export function getBoundariesForTimePeriod(
  timePeriod: TimePeriod,
  dateFormat: DateFormat = DateFormat.JS_DATE
) {
  const endDateMoment =
    timePeriod.type === 'TIME_PERIOD_CUSTOM'
      ? moment(timePeriod.endDate)
      : moment();
  const startDateMoment =
    timePeriod.type === 'TIME_PERIOD_CUSTOM'
      ? moment(timePeriod.startDate)
      : moment();
  if (timePeriod.type === 'TIME_PERIOD_LAST_24_HOURS') {
    startDateMoment.subtract(1, 'day');
  } else if (timePeriod.type === 'TIME_PERIOD_LAST_WEEK') {
    startDateMoment.subtract(1, 'week');
  } else if (timePeriod.type === 'TIME_PERIOD_LAST_MONTH') {
    startDateMoment.subtract(1, 'month');
  }

  const formatMethod =
    !dateFormat || dateFormat === DateFormat.JS_DATE ? 'toDate' : 'unix';
  const diffDays =
    timePeriod.compareDate === undefined
      ? 0
      : startDateMoment.diff(timePeriod.compareDate, 'days') +
        // Custom period is exact days, but relative periods boundaries will span
        // across 2 days, so we compensate by adding 1 day diff for relative periods.
        // For example, 24H period selection starts yesterday and ends today, so a
        // 24H comparison with yesterday, should start the day before.
        (timePeriod.type === 'TIME_PERIOD_CUSTOM' ? 0 : 1);

  return {
    startTime: startDateMoment[formatMethod](),
    endTime: endDateMoment[formatMethod](),
    compare:
      timePeriod.compareDate === undefined
        ? undefined
        : {
            startTime: startDateMoment
              .clone()
              .subtract(diffDays, 'days')
              [formatMethod](),
            endTime: endDateMoment
              .clone()
              .subtract(diffDays, 'days')
              [formatMethod](),
          },
  };
}

export function selectedEndDate(selectedPeriod: TimePeriod): number {
  return (
    (selectedPeriod.type === 'TIME_PERIOD_CUSTOM'
      ? selectedPeriod.endDate
      : Date.now()) / 1000
  );
}

export function isOutsideTimePeriod(
  date: number,
  timePeriod: TimePeriod,
  timeZone: string
) {
  const selectedStartDateSeconds = selectedStartDate(timePeriod, timeZone);
  const selectedEndDateSeconds = selectedEndDate(timePeriod);
  return date < selectedStartDateSeconds || date > selectedEndDateSeconds;
}
