import { Box, BoxProps } from '@chakra-ui/react';

export default function LayoutPanel(props: BoxProps) {
  return (
    <Box
      backgroundColor="white"
      boxShadow="sm"
      borderRadius="xl"
      padding="2px"
      mb={4}
      {...props}
    />
  );
}
