import './EmptySite.scss';
import { FormattedMessage } from 'react-intl';
import { useNavigate } from 'react-router-dom';
import { VStack } from '@chakra-ui/react';
import { pathSitesEdit } from '../constants/path';
import useParamInsightId from '../hooks/useParamInsightId';
import NoRouteData from '../svg/no-routes-data-icon.svg?react';
import { SiteFormSections } from './SiteEdit/EditSite';

export enum EmptySiteMode {
  NO_ROUTE = 'NO_ROUTE',
  NO_DATA = 'NO_DATA',
}

export interface EmptySiteProps {
  siteId: number;
  emptySiteMode: EmptySiteMode;
}

export default function EmptySite({
  siteId,
  emptySiteMode = EmptySiteMode.NO_ROUTE,
}: EmptySiteProps) {
  const navigate = useNavigate();
  const insightId = useParamInsightId();
  const navigateToAddRoute = () => {
    navigate(
      {
        pathname: pathSitesEdit(insightId),
        search: siteId ? `?siteId=${siteId}` : '',
      },
      {
        state: {
          siteFormSection: SiteFormSections.ROUTES,
        },
      }
    );
  };
  const navigateToMonitor = () => {
    navigate(
      {
        pathname: pathSitesEdit(insightId),
        search: siteId ? `?siteId=${siteId}` : '',
      },
      {
        state: {
          siteFormSection: SiteFormSections.SCHEDULES,
        },
      }
    );
  };
  const navigateToEditSite = () => {
    navigate(
      {
        pathname: pathSitesEdit(insightId),
        search: siteId ? `?siteId=${siteId}` : '',
      },
      {
        state: {
          siteFormSection: SiteFormSections.DESCRIPTION,
        },
      }
    );
  };

  return (
    <VStack textAlign="center" px={12} py={20}>
      <div className="empty-site__icon">
        <NoRouteData />
      </div>
      <div className="empty-site__header">
        {emptySiteMode === EmptySiteMode.NO_ROUTE && (
          <FormattedMessage
            defaultMessage="No routes added"
            id="9EzerZ"
            description="No routes added"
          />
        )}
        {emptySiteMode === EmptySiteMode.NO_DATA && (
          <FormattedMessage
            defaultMessage="Ready to collect data?"
            id="zcN+8I"
            description="Ready to collect data?"
          />
        )}
      </div>
      <div className="empty-site__message">
        {emptySiteMode === EmptySiteMode.NO_ROUTE && (
          <FormattedMessage
            defaultMessage="Add a route to start monitoring data on this site."
            id="7P4q7Q"
            description="Add a route to start monitoring data on this site."
          />
        )}
        {emptySiteMode === EmptySiteMode.NO_DATA && (
          <FormattedMessage
            defaultMessage="Start monitoring or make some changes first."
            id="5PJm3K"
            description="Start monitoring or make some changes first."
          />
        )}
      </div>
      <div>
        {emptySiteMode === EmptySiteMode.NO_ROUTE && (
          <button
            type="button"
            className="empty-site__btn-add"
            aria-label="Add a route"
            onClick={navigateToAddRoute}
          >
            <FormattedMessage
              defaultMessage="Add a route"
              id="j6hQC1"
              description="Add a route"
            />
          </button>
        )}
        {emptySiteMode === EmptySiteMode.NO_DATA && (
          <button
            type="button"
            className="empty-site__btn-monitor"
            aria-label="Start Monitoring"
            onClick={navigateToMonitor}
          >
            <FormattedMessage
              defaultMessage="Start Monitoring"
              id="M1SkJ7"
              description="Start Monitoring"
            />
          </button>
        )}
        <button
          type="button"
          className="empty-site__btn-edit"
          aria-label="Edit Site"
          onClick={navigateToEditSite}
        >
          <FormattedMessage
            defaultMessage="Edit Site"
            id="RDjv6Y"
            description="Edit Site"
          />
        </button>
      </div>
    </VStack>
  );
}
