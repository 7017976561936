import {
  VStack,
  Text,
  HStack,
  Switch,
  Flex,
  Button,
  Box,
} from '@chakra-ui/react';
import { FormattedMessage } from 'react-intl';
import {
  UseFormReturn,
  useController,
  useFieldArray,
  useForm,
} from 'react-hook-form';
import { ActiveVms } from '@webapp/bff/src/types/vms';
import { useImperativeHandle } from 'react';
import { MessagesFramesContentType } from '@vms/shared/src/message-types/common';
import { AddIcon } from '@chakra-ui/icons';
import Frame, { GenericFrameContent } from './Frame';
import { CreateWizardStepRenderProps } from '../CreateWizardLayout/CreateWizardLayout';
import TimeSelection from './TimeSelection';
import useIntlWeekDays from '../../hooks/useIntlWeekDays';

interface OverrideFallbackForm {
  fallbackMessageConfig: MessagesFramesContentType;
  overrideScheduleMessageConfig: MessagesFramesContentType;
  overrideScheduleConfig: Array<{
    dow: number;
    schedules: Array<{ startTime: string; endTime: string }>;
  }>;
  isOverrideMessageEnabled: boolean;
}

export default function StepBoardOverrideFallback({
  vms,
  updateVms,
  stepFormRenderProps: { submitActionRef },
}: {
  stepFormRenderProps: CreateWizardStepRenderProps;
  vms?: ActiveVms;
  updateVms: (
    vms: Pick<
      ActiveVms,
      | 'fallbackMessageConfig'
      | 'overrideScheduleConfig'
      | 'overrideScheduleMessageConfig'
    >
  ) => Promise<void>;
}) {
  const defaultFallbackMessageConfig = [
    {
      key: `frame-${Math.random()}-${Date.now()}`,
      lines: [
        {
          key: `line-0-${Date.now()}`,
          lineParts: [{ type: 'text' as const, content: 'DRIVE' }],
        },
        {
          key: `line-1-${Date.now()}`,
          lineParts: [{ type: 'text' as const, content: 'WITH' }],
        },
        {
          key: `line-2-${Date.now()}`,
          lineParts: [{ type: 'text' as const, content: 'CARE' }],
        },
      ],
    },
  ];

  const formManager = useForm<OverrideFallbackForm>({
    defaultValues: {
      fallbackMessageConfig:
        vms?.fallbackMessageConfig ?? defaultFallbackMessageConfig,
      overrideScheduleMessageConfig:
        vms?.overrideScheduleMessageConfig ??
        getDefaultOverrideScheduleMessageConfig(),
      overrideScheduleConfig:
        vms?.overrideScheduleConfig ?? getDefaultOverrideScheduleConfig(),
      isOverrideMessageEnabled: (vms?.overrideScheduleConfig?.length ?? 0) > 0,
    },
  });
  const fallbackMessageConfigContent = formManager.watch(
    'fallbackMessageConfig'
  );
  const setFallbackMessageConfigContent = (
    newContent: MessagesFramesContentType
  ) => formManager.setValue('fallbackMessageConfig', newContent);
  useImperativeHandle(submitActionRef, () =>
    formManager.handleSubmit(
      (formValues) => {
        const {
          isOverrideMessageEnabled,
          overrideScheduleConfig,
          overrideScheduleMessageConfig,
          fallbackMessageConfig,
        } = formValues;
        return updateVms({
          fallbackMessageConfig,
          overrideScheduleConfig: isOverrideMessageEnabled
            ? overrideScheduleConfig
            : [],
          overrideScheduleMessageConfig,
        });
      },
      // eslint-disable-next-line prefer-promise-reject-errors
      () => Promise.reject(true)
    )
  );
  return (
    <VStack w="full" align="stretch" spacing={6}>
      <VStack
        bgColor="gray.50"
        borderRadius="16px"
        p={4}
        alignItems="start"
        spacing={2}
      >
        <Override formManager={formManager} />
      </VStack>
      <VStack
        bgColor="gray.50"
        borderRadius="16px"
        p={4}
        alignItems="start"
        spacing={2}
      >
        <Fallback
          fallbackMessageConfig={fallbackMessageConfigContent}
          setValue={setFallbackMessageConfigContent}
        />
      </VStack>
    </VStack>
  );
}

function Override({
  formManager,
}: {
  formManager: UseFormReturn<OverrideFallbackForm>;
}) {
  const weekDaysWithLabels = useIntlWeekDays();
  const { control } = formManager;
  const messageFieldArray = useFieldArray({
    name: 'overrideScheduleMessageConfig',
    control,
  });
  const { field: switchFieldProps } = useController({
    name: 'isOverrideMessageEnabled',
    control,
  });

  return (
    <VStack width="100%" alignItems="stretch" spacing={4}>
      <VStack alignItems="stretch" spacing={2}>
        <HStack spacing={2}>
          <Switch
            size="sm"
            colorScheme="green"
            name={switchFieldProps.name}
            ref={switchFieldProps.ref}
            onChange={switchFieldProps.onChange}
            isChecked={switchFieldProps.value}
            onBlur={switchFieldProps.onBlur}
          />
          <Box fontSize="md" fontWeight="bold">
            <FormattedMessage defaultMessage="Override message" id="1KFLNi" />
          </Box>
        </HStack>
        <Box fontSize="xs">
          <FormattedMessage
            defaultMessage="Apply a custom message to display for selected hours of the day and days of the week."
            id="MopAxj"
          />
        </Box>

        <VStack spacing={4}>
          {messageFieldArray.fields.map((frame, frameIndex) => (
            <Frame
              key={frame.key}
              index={frameIndex}
              content={frame}
              routes={[]}
              canAddTag={false}
              onDelete={() => {
                messageFieldArray.remove(frameIndex);
              }}
              onContentChange={(newContent) => {
                messageFieldArray.update(frameIndex, newContent);
              }}
              isDisabled={!switchFieldProps.value}
            />
          ))}
          <Flex justifyContent="center">
            <Button
              size="xs"
              variant="outline"
              leftIcon={<AddIcon />}
              onClick={() => {
                messageFieldArray.append(getDefaultOverrideFrame());
              }}
              isDisabled={!switchFieldProps.value}
            >
              <FormattedMessage defaultMessage="Add frame" id="vSbhQI" />
            </Button>
          </Flex>
        </VStack>
      </VStack>
      {switchFieldProps.value && (
        <VStack alignItems="stretch">
          <HStack pl={2} spacing={2} height="20px">
            <Box width={{ base: 'auto', lg: '40px' }} />
            <Box
              width={{
                base: '100%',
                lg: 'calc(50% - 20px)',
              }}
              display="flex"
              flexDirection="row"
              justifyContent="space-between"
            >
              <Box width="50%">
                <Text fontSize="xs" mb={0}>
                  <FormattedMessage defaultMessage="Start time" id="/zFP1/" />
                </Text>
              </Box>
              <Box
                width={{
                  base: '20px',
                  lg: '46px',
                }}
              />
              <Box width="50%">
                <Text fontSize="xs" mb={0}>
                  <FormattedMessage defaultMessage="End time" id="yc/tuy" />
                </Text>
              </Box>
            </Box>
            <Box
              ml="3px"
              display={{ base: 'none', lg: 'block' }}
              alignSelf="stretch"
              borderLeft="2px solid"
              borderLeftColor="gray.300"
              height="16px"
            />
            <Box
              width={{
                base: '100%',
                lg: '50%',
              }}
              display={{ base: 'none', lg: 'flex' }}
              flexDirection="row"
              justifyContent="space-between"
            >
              <Box width="50%">
                <Text fontSize="xs" mb={0}>
                  <FormattedMessage defaultMessage="Start time" id="/zFP1/" />
                </Text>
              </Box>
              <Box width="10px" />
              <Box width="50%">
                <Text fontSize="xs" mb={0}>
                  <FormattedMessage defaultMessage="End time" id="yc/tuy" />
                </Text>
              </Box>
            </Box>
          </HStack>
          {weekDaysWithLabels.map((day) => (
            <TimeSelection
              key={day.value}
              fieldName={`overrideScheduleConfig.${day.value}.schedules`}
              formManager={formManager}
              label={day.label}
            />
          ))}
        </VStack>
      )}
    </VStack>
  );
}

function Fallback({
  fallbackMessageConfig,
  setValue,
}: {
  fallbackMessageConfig: MessagesFramesContentType;
  setValue: (fallbackMessageConfig: MessagesFramesContentType) => void;
}) {
  const handleSetValue = (value: MessagesFramesContentType) => {
    setValue(value);
  };
  const handleFrameChange = (
    frameIndex: number,
    newFrame: GenericFrameContent
  ) => {
    handleSetValue(
      fallbackMessageConfig.map((frame, index) =>
        index === frameIndex ? newFrame : frame
      )
    );
  };
  return (
    <>
      <Text mb={0} fontWeight="bold" fontSize="md">
        <FormattedMessage defaultMessage="Fallback message" id="j7kqZi" />
      </Text>
      <Text mb={0} fontSize="xs">
        <FormattedMessage
          defaultMessage="Display in case connection to a route is lost."
          id="Im+DLs"
        />
      </Text>
      {fallbackMessageConfig.map((content, index) => (
        <Frame
          key={content.key}
          index={index}
          content={content}
          routes={[]}
          onContentChange={(newContent) => handleFrameChange(index, newContent)}
        />
      ))}
    </>
  );
}

function getDefaultOverrideScheduleMessageConfig() {
  return [getDefaultOverrideFrame()];
}
function getDefaultOverrideFrame() {
  return {
    key: `frame-${Math.random()}-${Date.now()}`,
    lines: [
      {
        key: `line-0-${Date.now()}`,
        lineParts: [{ type: 'text' as const, content: 'CUSTOM' }],
      },
      {
        key: `line-1-${Date.now()}`,
        lineParts: [{ type: 'text' as const, content: 'MESSAGE' }],
      },
    ],
  };
}
function getDefaultOverrideScheduleConfig() {
  return Array.from({ length: 7 }, (_, i) => ({
    dow: i,
    schedules:
      i === 0 || i === 6 ? [] : [{ startTime: '00:00', endTime: '06:00' }],
  }));
}
