import { useState } from 'react';
import { useIntl, defineMessage } from 'react-intl';
import { Controller, useForm } from 'react-hook-form';
import {
  Box,
  Checkbox,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Heading,
  Input,
  Radio,
  RadioGroup,
  Text,
  VStack,
} from '@chakra-ui/react';
import MultiSelectList from 'design-system/molecules/multi-select-list';
import { useFailureToast } from '../../hooks/useFailureToast';
import FormActions from '../FormActions';
import { PublicView, PublicViewConfig } from '../../types/views';
import { InsightType } from '../../types/panelData';
import { getInsightConfigFromType } from '../../utils/insightCharts';
import {
  DataHandlerFeedback,
  hasDataHandlerFeedBack,
} from '../../utils/dataHandler';
import useRoutesData from '../../data/useRoutesData';
import usePublicViewData from '../../data/usePublicViewData';
import { errorReport } from '../../utils/errors';
import { getPublicViewURL } from '../../config/envConfig';
import CreateConfirmation from './CreateConfirmation';
import { useSuccessToast } from '../../hooks/useSuccessToast';
import { useAnalytics } from '../../hooks/analytics/useAnalytics';
import useFeatureSwitch, {
  FeatureSwitchState,
} from '../../hooks/useFeatureSwitch';
import { FEATURE_PUBLIC_VIEW_SITE_IMPACT } from '../../constants/features';
import useSiteData from '../../data/useSiteData';
import useSiteUserViewData from '../../data/useSiteUserViewData';
import { UserViewConfigParams } from './utils';

interface ViewFormValues {
  viewName: string;
  sharedView: boolean;
  publicView: 'internal' | 'public';
  charts: InsightType[];
}

export interface EditViewFormProps {
  insightId: number;
  showViewForm: (show: boolean) => void;
  editable?: boolean;
  viewId?: number;
  name?: string;
  updateMode?: boolean;
  sharedView?: boolean;
  publicView?: boolean;
  siteViewParams: UserViewConfigParams;
  publicViewConfig?: PublicViewConfig;
  supportedChartTypes?: InsightType[];
}

export const EditViewForm = ({
  insightId,
  showViewForm,
  editable = true,
  viewId,
  name = '',
  sharedView = false,
  publicView = false,
  siteViewParams,
  publicViewConfig,
}: EditViewFormProps) => {
  const { formatMessage } = useIntl();
  const updateMode = !!viewId;
  const featureSiteImpactPublicView = useFeatureSwitch(
    FEATURE_PUBLIC_VIEW_SITE_IMPACT
  );
  const supportedChartTypes: InsightType[] =
    featureSiteImpactPublicView === FeatureSwitchState.ON
      ? ['SITE_IMPACT_DELAY', 'TIME_DELAY', 'JOURNEY_TIME', 'SPEED']
      : ['TIME_DELAY', 'JOURNEY_TIME', 'SPEED'];
  const {
    register,
    handleSubmit,
    control,
    formState: { errors, isSubmitting },
    getValues,
    clearErrors,
  } = useForm<ViewFormValues>({
    mode: 'onChange',
    defaultValues: {
      viewName: name,
      sharedView,
      publicView: publicView ? 'public' : 'internal',
      charts: publicViewConfig ? publicViewConfig.charts : supportedChartTypes,
    },
  });
  const [linkCreated, setLinkCreated] = useState('');
  const [creationInProgress, setCreationInProgress] = useState(false);
  const { track } = useAnalytics();
  const printError = editable
    ? errors.viewName
    : {
        type: 'author',
        message: formatMessage(
          defineMessage({
            defaultMessage: 'Only the author of this view can make changes',
            id: 'I9Rn0L',
            description: 'Only the author of this view can make changes',
          })
        ),
      };

  const { siteId } = siteViewParams;
  const siteDataHandler = useSiteData({
    orgId: insightId.toString(),
    siteId: siteId.toString(),
  });
  const { createView: createViewFetcher, updateView: updateViewFetcher } =
    useSiteUserViewData({ siteId: siteId.toString() });
  const routesDataHandler = useRoutesData(siteId);
  const publicViewsHandler = usePublicViewData(insightId, siteId);

  const failureToast = useFailureToast();
  const successToast = useSuccessToast();

  if (
    hasDataHandlerFeedBack([
      publicViewsHandler,
      siteDataHandler,
      routesDataHandler,
    ])
  ) {
    return (
      <DataHandlerFeedback
        dataHandlersParam={[
          publicViewsHandler,
          siteDataHandler,
          routesDataHandler,
        ]}
      />
    );
  }
  const createInternalView = async (data: ViewFormValues) => {
    try {
      const viewPayload = {
        viewName: data.viewName,
        hidden: siteViewParams.hidden,
        timePeriod: siteViewParams.timePeriod,
        date: siteViewParams.date,
        startDate: siteViewParams.startDate,
        isSiteLevel: data.sharedView,
      };

      await createViewFetcher(viewPayload);
      track('User View Created', { referrer: 'View Site Tab' });
      track('User View Shared', {
        referrer: 'View Site Tab',
        shared: data.sharedView,
      });
      showViewForm(false);
    } catch (err: any) {
      errorReport.handled(err);
      failureToast({
        title: formatMessage({
          defaultMessage: 'There has been a problem creating the view.',
          id: 'Mu7OgO',
          description: 'Create view failed',
        }),
      });
    }
  };

  const updateInternalView = async (data: ViewFormValues) => {
    const viewPayload = {
      viewId,
      viewName: data.viewName,
      isSiteLevel: data.sharedView,
    };
    try {
      await updateViewFetcher(viewId!.toString(), viewPayload);
      track('User View Updated', { referrer: 'View Site Tab' });
      track('User View Shared', {
        referrer: 'View Site Tab',
        shared: data.sharedView,
      });
      showViewForm(false);
    } catch (err: any) {
      errorReport.handled(err);
      failureToast({
        title: formatMessage({
          defaultMessage: 'There has been a problem updating the view.',
          id: 'Z9IOd0',
          description: 'Update view failed',
        }),
      });
    }
  };

  const handleCreatePublicView = async (data: ViewFormValues) => {
    try {
      const trackIds = siteViewParams.hidden
        ? siteViewParams.hidden.split(',')
        : [];
      const view: Partial<PublicView> = {
        name: data.viewName,
        config: {
          timePeriod: siteViewParams.timePeriod,
          endDate: siteViewParams.date,
          startDate: siteViewParams.startDate,
          charts: data.charts,
          routes: (routesDataHandler.data || [])
            .filter((r) => trackIds.indexOf(r.trackId) < 0)
            .map((r) => r.routeId),
        },
      };
      setCreationInProgress(true);
      const v = await publicViewsHandler.createPublicView(view);
      setLinkCreated(v.linkId);
      track('Public View Created', { referrer: 'View Site Tab' });
    } catch (err: any) {
      errorReport.handled(err);
      failureToast({
        title: formatMessage({
          defaultMessage: 'There has been a problem creating the view.',
          id: 'Mu7OgO',
          description: 'Create view failed',
        }),
      });
      setCreationInProgress(false);
    }
  };

  const handleUpdatePublicView = async (data: ViewFormValues) => {
    try {
      const view: Partial<PublicView> = {
        viewId: viewId!,
        name: data.viewName,
        config: {
          routes: publicViewConfig!.routes,
          charts: data.charts,
          timePeriod: publicViewConfig!.timePeriod,
          endDate: publicViewConfig!.endDate,
          startDate: publicViewConfig!.startDate,
        },
      };
      await publicViewsHandler.editPublicView(view);
      successToast({
        title: formatMessage({
          defaultMessage: 'View updated',
          id: 'CjLTC3',
          description: 'Public view successfully updated',
        }),
      });
      track('Public View Updated', { referrer: 'View Site Tab' });
      showViewForm(false);
    } catch (err: any) {
      errorReport.handled(err);
      failureToast({
        title: formatMessage({
          defaultMessage: 'There has been a problem updating the view.',
          id: 'Z9IOd0',
          description: 'Update view failed',
        }),
      });
    }
  };

  const createView = async (data: ViewFormValues) => {
    if (getValues('publicView') === 'internal') {
      await createInternalView(data);
    } else {
      await handleCreatePublicView(data);
    }
  };

  const updateView = async (data: ViewFormValues) => {
    if (!publicView) {
      await updateInternalView(data);
    } else {
      await handleUpdatePublicView(data);
    }
  };

  const onCancel = () => {
    clearErrors();
    setLinkCreated('');
    setCreationInProgress(false);
    showViewForm(false);
  };

  const link = linkCreated ? `${getPublicViewURL()}/v/${linkCreated}` : '';

  return (
    <VStack alignItems="flex-start" width="full">
      <CreateConfirmation
        onClose={onCancel}
        open={creationInProgress}
        link={link}
      />
      <FormControl isInvalid={!!printError} mb={4}>
        <FormLabel fontSize="sm">View name</FormLabel>
        <Input
          size="sm"
          type="text"
          {...register('viewName', { required: 'Please enter view name' })}
          autoFocus
          placeholder="Enter a view name"
          disabled={!editable}
          backgroundColor="white"
          data-testid="view-name-input"
        />
        {printError && (
          <FormErrorMessage>{printError.message}</FormErrorMessage>
        )}
      </FormControl>
      {updateMode && !publicView && (
        <Box
          py={4}
          alignItems="flex-start"
          width="full"
          backgroundColor="white"
          borderRadius={4}
        >
          <Checkbox
            {...register('sharedView')}
            pl={2}
            size="lg"
            isDisabled={!editable}
          >
            <Box fontSize="xs">
              {formatMessage({
                id: 'J3x6da',
                defaultMessage: 'Shared with everyone on this site',
              })}
            </Box>
          </Checkbox>
        </Box>
      )}
      {updateMode && publicView && (
        <VStack
          p={4}
          alignItems="flex-start"
          width="full"
          backgroundColor="white"
          borderRadius={4}
        >
          <Text mb={1}>
            {formatMessage({
              id: 'vInq+J',
              defaultMessage: 'Select graphs to share publicly',
            })}
          </Text>
          <Controller
            name="charts"
            control={control}
            defaultValue={getValues('charts')}
            render={({ field: multiselectField }) => (
              <MultiSelectList
                size="lg"
                isDisabled={!editable}
                options={supportedChartTypes}
                {...multiselectField}
                selected={getValues('charts')}
                getLabel={(type) => {
                  const config = getInsightConfigFromType(
                    type,
                    siteDataHandler.data!,
                    formatMessage
                  );
                  return <Box fontSize="xs">{config.title}</Box>;
                }}
              />
            )}
          />
        </VStack>
      )}
      {!updateMode && (
        <>
          <Heading size="sm">Access to this view:</Heading>
          <Box
            backgroundColor="white"
            borderRadius={4}
            alignItems="flex-start"
            p={4}
            width="full"
          >
            <Controller
              name="publicView"
              control={control}
              defaultValue={publicView ? 'public' : 'internal'}
              render={({ field }) => (
                <RadioGroup
                  {...field}
                  onChange={(nextValue) => {
                    if (nextValue === 'public' || nextValue === 'internal') {
                      field.onChange(nextValue);
                    }
                  }}
                >
                  <VStack gap={1} alignItems="flex-start">
                    <Radio value="internal" m={0} size="lg">
                      <Box fontSize="sm">
                        {formatMessage({
                          id: 'QxsH6p',
                          defaultMessage: 'Internal only',
                        })}
                      </Box>
                    </Radio>
                    {getValues('publicView') === 'internal' && (
                      <Checkbox
                        {...register('sharedView')}
                        pl={4}
                        size="lg"
                        iconSize="1rem"
                      >
                        <Box fontSize="xs">
                          {formatMessage({
                            id: 'J3x6da',
                            defaultMessage: 'Shared with everyone on this site',
                          })}
                        </Box>
                      </Checkbox>
                    )}
                    <Radio
                      value="public"
                      m={0}
                      size="lg"
                      data-testid="public-radio"
                    >
                      <Box fontSize="sm">
                        {formatMessage({
                          id: 'RDPmBm',
                          defaultMessage: 'Share with the public',
                        })}
                      </Box>
                    </Radio>
                    {getValues('publicView') === 'public' && (
                      <VStack pl={4}>
                        <Text mb={1} fontSize="xs">
                          Select graph to share publicly
                        </Text>
                        <Controller
                          name="charts"
                          control={control}
                          defaultValue={getValues('charts')}
                          render={({ field: multiselectField }) => (
                            <MultiSelectList
                              size="lg"
                              options={supportedChartTypes}
                              {...multiselectField}
                              selected={getValues('charts')}
                              getLabel={(type) => {
                                const config = getInsightConfigFromType(
                                  type,
                                  siteDataHandler.data!,
                                  formatMessage
                                );
                                return <Box fontSize="xs">{config.title}</Box>;
                              }}
                            />
                          )}
                        />
                      </VStack>
                    )}
                  </VStack>
                </RadioGroup>
              )}
            />
          </Box>
        </>
      )}
      <FormActions
        hasUserPermission={editable}
        isSaving={isSubmitting}
        onCancel={onCancel}
        onSave={handleSubmit(updateMode ? updateView : createView)}
      />
    </VStack>
  );
};
