import {
  createBrowserRouter,
  createRoutesFromElements,
  Link,
  Navigate,
  Route,
  Outlet,
} from 'react-router-dom';
import { Box, Heading } from '@chakra-ui/react';
import { FormattedMessage } from 'react-intl';
import { IntercomProvider } from 'react-use-intercom';
import {
  ForgotPassword,
  Login,
  ResetPassword,
  VerifyMFA,
} from '../../components/Auth';
import EditableSites from '../../components/EditableSites';
import EditSite from '../../components/SiteEdit/EditSite';
import LayoutAuthenticated, {
  AuthenticatedOrgContext,
  AuthenticatedUserLayoutContext,
} from '../../components/LayoutAuthenticated/LayoutAuthenticated';
import Onboarding from '../../components/Onboarding';
import PlanVehicleCount from '../../components/PlanVehicleCount';
import UserProfile from '../../components/UserProfile';
import {
  pathCookies,
  pathDashboard,
  pathForbidden,
  pathForgotPassword,
  pathLogin,
  pathLogout,
  pathMfaLogin,
  pathOnboarding,
  pathResetPassword,
  pathSettings,
  pathSites,
  pathSitesEdit,
  pathSitesManage,
  pathSitesOverviewLegacy,
  pathSitesOverview,
  pathSitesPlan,
  pathSitesPlanLegacy,
  pathSitesView,
  PlanInsightType,
  pathTeamSettings,
  pathSitesCreate,
  pathRouteStatus,
  pathUnsubscribe,
  pathSiteVmsBoards,
  pathSiteVmsBoard,
  pathRoutePollStatus,
} from '../../constants/path';
import useParamInsightId from '../../hooks/useParamInsightId';
import Plan from '../../components/SitePlan';
import HomePage from '../../components/HomePage';
import SiteView from '../../components/SiteView';
import ErrorPage from '../../components/ErrorPage';
import Logout from '../../components/Auth/Logout';
import CheckAuth from '../../components/Auth/CheckAuth';
import {
  AnalyticsPageViewTracker,
  UserAnalyticsProvider,
} from '../../hooks/analytics/useAnalytics';
import RouteErrorBoundary from '../../components/RouteErrorBoundary';
import { envConfig } from '../../config/envConfig';
import TeamSettings from '../../components/TeamSettings';
import SiteCreate from '../../components/SiteCreate';
import RoutePollingStatus from '../../components/RoutePollingStatus';
import Unsubscribe from '../../components/Unsubscribe';
import { SmartVmsPage } from '../../components/SmartVms/LayoutSmartVms';
import SmartVms from '../../components/SmartVms';
import SmartVmsCreateBoard from '../../components/SmartVms/SmartVmsCreateBoard';
import { SiteContextProvider } from '../../hooks/useSiteView';
import SiteUserViewDefaultSelector from '../../components/SiteView/SiteUserViewDefaultSelector';

export default createBrowserRouter(
  createRoutesFromElements(
    <Route element={<AppWrappers />} errorElement={<RouteErrorBoundary />}>
      <Route path="/" element={<Navigate to={pathDashboard()} replace />} />
      <Route
        path={pathCookies()}
        element={
          <ErrorPage text="Please enable cookies to view this website" noLink />
        }
      />
      <Route
        path={pathForbidden()}
        element={<ErrorPage text="403 Forbidden" />}
      />
      <Route path={pathOnboarding()} element={<Onboarding />} />
      <Route path={pathUnsubscribe()} element={<Unsubscribe />} />
      <Route path={pathRoutePollStatus()} element={<RoutePollingStatus />} />

      <Route element={<CheckAuth />}>
        <Route path={pathRouteStatus()} element={<RoutePollingStatus />} />
        <Route path={pathLogin()} element={<Login />} />
        <Route path={pathForgotPassword()} element={<ForgotPassword />} />
        <Route path={pathResetPassword()} element={<ResetPassword />} />
        <Route path={pathMfaLogin()} element={<VerifyMFA />} />
        <Route path={pathLogout()} element={<Logout />} />

        <Route element={<AuthenticatedUserLayoutContext />}>
          <Route element={<AuthenticatedOrgContext />}>
            <Route path={pathDashboard()} />
            <Route
              path={pathSiteVmsBoards()}
              element={<SmartVmsPage Component={SmartVms} />}
            />
            <Route
              path={pathSiteVmsBoard()}
              element={<SmartVmsPage Component={SmartVmsCreateBoard} />}
            />

            <Route element={<LayoutAuthenticated showProjectSelector />}>
              <Route path={pathTeamSettings()} element={<TeamSettings />} />
              <Route
                path={pathSitesOverviewLegacy()}
                element={
                  <RedirectWithInsightId
                    pathBuilder={(insightId) =>
                      pathSitesOverview(insightId, 'sites', null)
                    }
                  />
                }
              />

              <Route path={pathSitesOverview()} element={<HomePage />} />
              <Route path={pathSitesManage()} element={<EditableSites />} />
            </Route>

            <Route
              element={<LayoutAuthenticated showProjectSelector={false} />}
            >
              <Route path={pathSitesCreate()} element={<SiteCreate />} />
              <Route
                path={pathSites()}
                element={
                  <RedirectWithInsightId
                    pathBuilder={pathSitesOverviewLegacy}
                  />
                }
              />
              <Route
                element={
                  <SiteContextProvider>
                    <SiteUserViewDefaultSelector>
                      <Outlet />
                    </SiteUserViewDefaultSelector>
                  </SiteContextProvider>
                }
              >
                <Route path={pathSitesEdit()} element={<EditSite />} />
                <Route path={pathSitesView()} element={<SiteView />} />
                <Route path={pathSitesPlan()} element={<Plan />} />
                <Route
                  path={pathSitesPlan(undefined, PlanInsightType.COUNT)}
                  element={<PlanVehicleCount />}
                />
                <Route
                  path={pathSitesPlanLegacy()}
                  element={
                    <RedirectWithInsightId
                      pathBuilder={(insightId) =>
                        pathSitesPlan(insightId, PlanInsightType.TIME)
                      }
                    />
                  }
                />
              </Route>
            </Route>
          </Route>

          <Route element={<LayoutAuthenticated showProjectSelector={false} />}>
            <Route path={pathSettings()} element={<UserProfile />} />
            <Route
              path={'/*'}
              element={
                <Box textAlign="center" px={12} py={20}>
                  <Heading>
                    <FormattedMessage
                      defaultMessage="404 not found"
                      id="Cu34ak"
                      description="Page not found message"
                    />
                  </Heading>
                  <Link to="/">
                    <FormattedMessage
                      defaultMessage="Go to homepage"
                      id="bkWXbu"
                      description="link to homepage from error page"
                    />
                  </Link>
                </Box>
              }
            />
          </Route>
        </Route>
      </Route>
    </Route>
  )
);

function AppWrappers() {
  return (
    <IntercomProvider appId={envConfig.INTERCOM_APP}>
      <UserAnalyticsProvider>
        <AnalyticsPageViewTracker />
        <Outlet />
      </UserAnalyticsProvider>
    </IntercomProvider>
  );
}

function RedirectWithInsightId({
  pathBuilder,
}: {
  pathBuilder: (insightId: string) => string;
}) {
  const insightId = useParamInsightId();
  return <Navigate to={pathBuilder(insightId)} replace />;
}
