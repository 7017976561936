import { useRef, useState } from 'react';
import { BlankRoute, Route } from '../../types/route';
import Map = google.maps.Map;
import MapMouseEvent = google.maps.MapMouseEvent;
import useDirectionsEditor from './useDirectionsEditor';

interface MapAction {
  click: (e: MapMouseEvent) => void;
  cursor: string;
}

export type RoutesEditorManager = ReturnType<typeof useRoutesEditorManager>;

export default function useRoutesEditorManager() {
  const [editingRoute, setEditingRoute] = useState<null | Route | BlankRoute>(
    null
  );
  const directionsEditor = useDirectionsEditor(editingRoute);
  const [mapAction, setMapAction] = useState<MapAction>();
  const stopEditingRoute = () => {
    setEditingRoute(null);
    setMapAction(undefined);
  };
  const mapElementRef = useRef<Map>();
  const onMapLoad = (map) => {
    mapElementRef.current = map;
  };
  const onGoogleDirectionsChange = (newDirections) => {
    directionsEditor.setGoogleDirections(newDirections);
  };
  return {
    ...directionsEditor,
    editingRoute,
    setEditingRoute,
    stopEditingRoute,
    mapReference: mapElementRef.current,
    setMapAction,
    onGoogleDirectionsChange,
    mapProps: {
      onLoad: onMapLoad,
      onClick: mapAction?.click,
      options: {
        draggableCursor: mapAction?.cursor,
      },
    },
  };
}
