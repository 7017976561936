import { useNavigate, useLocation, Navigate } from 'react-router-dom';
import queryString from 'query-string';
import { useMemo, createContext, useContext } from 'react';
import {
  IsTimePeriodType,
  TimePeriod,
  TimePeriodType,
} from '../types/timePeriod';
import { errorReport } from '../utils/errors';
import {
  NightDayPeriod,
  PlanConstraints,
  PlanSettingsParams,
  PredictionMode,
  isTimeString,
} from '../types/plan';
import { pathDashboard } from '../constants/path';

export interface SiteView {
  state: SiteViewState;
  setState: (newState: SiteViewStateInput | SearchParamsWithSiteId) => void;
  isStateDefault: boolean;
  setPeriod: (newPeriod: TimePeriod) => void;
  setHiddenRoutes: (newHiddenRoutes: number[]) => void;
  setHiddenWorkAreas: (newHiddenWorkAreas: string[]) => void;
  setHiddenVms: (newHiddenVms: string[]) => void;
  matchState: (state: SiteViewState | SearchParamsWithSiteId) => boolean;
}
export interface SiteViewParams {
  siteId: string;
  timePeriod?: TimePeriodType;
  date?: string;
  startDate?: string;
  hidden?: string;
  hiddenWorkAreas?: string;
  hiddenVms?: string;
  comparestarttime?: string;
  planSettingsParams?: string;
  planConstraints?: string;
}
export interface SiteViewState {
  siteId: number;
  period: TimePeriod;
  hiddenRoutes: Array<number>;
  hiddenWorkAreas: Array<string>;
  hiddenVms: Array<string>;
  planSettingsParams?: PlanSettingsParams;
  planConstraints?: PlanConstraints;
}
type SiteViewStateInput = Partial<SiteViewState> &
  Pick<SiteViewState, 'siteId'>;

type SearchParamsWithSiteId = `${'&' | '?'}siteId=${number}${`&${any}` | ''}`;
function IsSearchParamsWithSiteId(str): str is SearchParamsWithSiteId {
  return typeof str === 'string' && !!str.match(/(&|\?)siteId=\d+($|&)/);
}
function IsValidParamValue(value: unknown): value is string {
  return typeof value === 'string' && value.length > 0;
}
function IsArrayOfNumbers(value: unknown): value is number[] {
  return Array.isArray(value) && value.every((v) => typeof v === 'number');
}

const SiteContext = createContext<SiteView | null>(null);

export function SiteContextProvider({
  children,
}: {
  children: React.ReactNode;
}) {
  const navigate = useNavigate();
  const { search, pathname } = useLocation();
  const hasSiteIdInUrl = IsSearchParamsWithSiteId(search);

  const siteView = useMemo(() => {
    if (!hasSiteIdInUrl) {
      return null;
    }
    const siteViewState = formatViewState(search);
    // if no time period is set, we consider the state to be default
    const isStateDefault = !IsTimePeriodType(
      queryString.parse(search).timePeriod
    );
    const setSiteViewState = (
      newState: SiteViewStateInput | SearchParamsWithSiteId,
      preserveHistory = false
    ) => {
      navigate(
        {
          pathname,
          search:
            typeof newState === 'string'
              ? newState
              : queryString.stringify(formatViewParams(newState)),
        },
        {
          replace: preserveHistory,
        }
      );
    };
    return {
      state: siteViewState,
      setState: setSiteViewState,
      isStateDefault,
      setPeriod(period) {
        setSiteViewState({
          ...siteViewState,
          period,
        });
      },
      setHiddenWorkAreas(hiddenWorkAreas) {
        setSiteViewState({
          ...siteViewState,
          hiddenWorkAreas,
        });
      },
      setHiddenVms(hiddenVms) {
        setSiteViewState({
          ...siteViewState,
          hiddenVms,
        });
      },
      setHiddenRoutes(hiddenRoutes) {
        setSiteViewState({
          ...siteViewState,
          hiddenRoutes,
        });
      },
      matchState(state: SearchParamsWithSiteId | SiteViewState) {
        const compareState =
          typeof state === 'string' ? formatViewState(state) : state;
        return (
          siteViewState.siteId === compareState.siteId &&
          siteViewState.period.type === compareState.period.type &&
          (siteViewState.period.type !== 'TIME_PERIOD_CUSTOM' ||
            compareState.period.type !== 'TIME_PERIOD_CUSTOM' ||
            (siteViewState.period.startDate === compareState.period.startDate &&
              siteViewState.period.endDate === compareState.period.endDate)) &&
          siteViewState.hiddenRoutes.length ===
            compareState.hiddenRoutes.length &&
          siteViewState.hiddenRoutes.every(
            (routeId) => compareState.hiddenRoutes.indexOf(routeId) !== -1
          ) &&
          siteViewState.hiddenWorkAreas.length ===
            compareState.hiddenWorkAreas.length &&
          siteViewState.hiddenWorkAreas.every(
            (workAreaId) =>
              compareState.hiddenWorkAreas.indexOf(workAreaId) !== -1
          ) &&
          siteViewState.hiddenVms.length === compareState.hiddenVms.length &&
          siteViewState.hiddenVms.every(
            (vmId) => compareState.hiddenVms.indexOf(vmId) !== -1
          )
        );
      },
    };
  }, [pathname, search, navigate, hasSiteIdInUrl]);

  if (!hasSiteIdInUrl) {
    return <Navigate to={pathDashboard()} replace />;
  }
  return (
    <SiteContext.Provider value={siteView}>{children}</SiteContext.Provider>
  );
}

export default function useSiteView(): SiteView {
  const siteView = useContext(SiteContext);
  if (!siteView) {
    throw new Error('useSiteView must be used within a SiteContextProvider');
  }
  return siteView;
}

function formatViewState(searchParams: SearchParamsWithSiteId): SiteViewState {
  const queryParams: { [k: string]: unknown } = queryString.parse(searchParams);
  if (!IsValidParamValue(queryParams.siteId)) {
    throw new Error('siteId is required in view state');
  }
  const reliableViewState = {
    siteId: parseInt(queryParams.siteId, 10),
    period: {
      type: 'TIME_PERIOD_LAST_24_HOURS' as 'TIME_PERIOD_LAST_24_HOURS',
    },
    hiddenRoutes: [],
    hiddenWorkAreas: [],
    hiddenVms: [],
  };
  try {
    return {
      ...reliableViewState,
      planConstraints: parsePlanConstraints(queryParams.planConstraints),
      planSettingsParams: parsePlanSettings(queryParams.planSettingsParams),
      period: formatTimePeriod(queryParams),
      hiddenRoutes: IsValidParamValue(queryParams.hidden)
        ? queryParams.hidden
            .split(',')
            .map((numericRouteId) => Number(numericRouteId.replace('_', '')))
        : [],
      hiddenWorkAreas: IsValidParamValue(queryParams.hiddenWorkAreas)
        ? queryParams.hiddenWorkAreas.split(',')
        : [],
      hiddenVms: IsValidParamValue(queryParams.hiddenVms)
        ? queryParams.hiddenVms.split(',')
        : [],
    };
  } catch (e) {
    errorReport.handled(e);
    return reliableViewState;
  }
}

function formatTimePeriod(queryParams: { [key: string]: unknown }): TimePeriod {
  if (!IsTimePeriodType(queryParams.timePeriod)) {
    return {
      type: 'TIME_PERIOD_LAST_24_HOURS',
    };
  }
  if (queryParams.timePeriod === 'TIME_PERIOD_CUSTOM') {
    if (
      !IsValidParamValue(queryParams.startDate) ||
      !IsValidParamValue(queryParams.date)
    ) {
      errorReport.handled(
        new Error('Missing mandatory dates from custom time period'),
        { queryParams }
      );
      return {
        type: 'TIME_PERIOD_LAST_24_HOURS',
      };
    }
    return {
      type: 'TIME_PERIOD_CUSTOM',
      startDate: parseInt(queryParams.startDate, 10) * 1000,
      endDate: parseInt(queryParams.date, 10) * 1000,
      compareDate: IsValidParamValue(queryParams.comparestarttime)
        ? parseInt(queryParams.comparestarttime, 10) * 1000
        : undefined,
    };
  }
  return {
    type: queryParams.timePeriod,
    compareDate: IsValidParamValue(queryParams.comparestarttime)
      ? parseInt(queryParams.comparestarttime, 10) * 1000
      : undefined,
  };
}

function formatViewParams(viewState: SiteViewStateInput): SiteViewParams {
  return {
    siteId: viewState.siteId.toString(),
    timePeriod: viewState.period?.type,
    startDate:
      viewState.period?.type === 'TIME_PERIOD_CUSTOM'
        ? Math.round(viewState.period.startDate / 1000).toString()
        : undefined,
    date:
      viewState.period?.type === 'TIME_PERIOD_CUSTOM'
        ? Math.round(viewState.period.endDate / 1000).toString()
        : undefined,
    comparestarttime: viewState.period?.compareDate
      ? Math.round(viewState.period.compareDate / 1000).toString()
      : undefined,
    hidden: constructHiddenRoutesParam(viewState.hiddenRoutes),
    hiddenWorkAreas: viewState.hiddenWorkAreas?.sort().join(','),
    hiddenVms: viewState.hiddenVms?.sort().join(','),
    planSettingsParams: viewState.planSettingsParams
      ? formatObjectValue(viewState.planSettingsParams)
      : undefined,
    planConstraints: viewState.planConstraints
      ? formatObjectValue(viewState.planConstraints)
      : undefined,
  };
}

function constructHiddenRoutesParam(hiddenRoutes: number[] = []) {
  const joinedRoutes = hiddenRoutes.sort().join(',_');
  if (joinedRoutes.length > 0) {
    return `_${joinedRoutes}`;
  }
  return undefined;
}

function parsePlanConstraints(value: unknown): PlanConstraints | undefined {
  if (!IsValidParamValue(value)) {
    return undefined;
  }
  const parsedValues = parseObjectValue(value);
  const parsedKpi =
    parsedValues.kpi === undefined || parsedValues.kpi === ''
      ? undefined
      : Number(parsedValues.kpi);
  return {
    startTime: isTimeString(parsedValues.startTime)
      ? parsedValues.startTime
      : '',
    endTime: isTimeString(parsedValues.endTime) ? parsedValues.endTime : '',
    kpi: Number.isNaN(parsedKpi) ? undefined : parsedKpi,
    showConstraints: Boolean(parsedValues.showConstraints),
    // TODO: remove show constraints for new controls
    // showConstraints: parsedValues.showConstraints === undefined ? undefined : Boolean(parsedValues.showConstraints),
  };
}

function parsePlanSettings(value: unknown): PlanSettingsParams | undefined {
  if (!IsValidParamValue(value)) {
    return undefined;
  }
  const parsedValues = parseObjectValue(value);
  const parsedStartDate =
    parsedValues.startDate === undefined || parsedValues.startDate === ''
      ? undefined
      : Number(parsedValues.startDate);
  const parsedEndDate =
    parsedValues.endDate === undefined || parsedValues.endDate === ''
      ? undefined
      : Number(parsedValues.endDate);
  return {
    startDate: Number.isNaN(parsedStartDate) ? undefined : parsedStartDate,
    endDate: Number.isNaN(parsedEndDate) ? undefined : parsedEndDate,
    daysOfTheWeek: IsArrayOfNumbers(parsedValues.daysOfTheWeek)
      ? parsedValues.daysOfTheWeek
      : [0, 1, 2, 3, 4, 5, 6],
    period: isEnumValue(parsedValues.period, NightDayPeriod)
      ? parsedValues.period
      : NightDayPeriod.DAY,
    mode: isEnumValue(parsedValues.mode, PredictionMode)
      ? parsedValues.mode
      : PredictionMode.AVERAGE,
  };
}

function isEnumValue<TEnum extends { [s: string]: unknown }>(
  value: unknown,
  enumType: TEnum
): value is TEnum[keyof TEnum] {
  return Object.values(enumType).includes(value as any);
}

function parseObjectValue(value: string): Record<any, unknown> {
  try {
    return JSON.parse(atob(value));
  } catch (e) {
    errorReport.handled(e, { value });
    return {};
  }
}

function formatObjectValue(obj: Record<any, unknown>): string {
  try {
    return btoa(JSON.stringify(obj));
  } catch (e) {
    errorReport.handled(e);
    return '';
  }
}
