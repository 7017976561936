import { useIntl } from 'react-intl';
import { Heading, Radio, RadioGroup, VStack } from '@chakra-ui/react';
import { ReactNode } from 'react';

export default function RoleMenu<TRole extends string, TUser>({
  user,
  role,
  updateRole,
  header,
}: {
  user: TUser;
  role: TRole | 'Editor' | 'Viewer';
  updateRole: (input: { user: TUser; newRoleValue: TRole }) => void;
  header?: ReactNode;
}) {
  const { formatMessage } = useIntl();
  return (
    <RadioGroup
      defaultValue={role}
      onChange={(newRoleValue: TRole) =>
        updateRole({
          user,
          newRoleValue,
        })
      }
    >
      <VStack spacing={4} alignItems="left">
        <Heading size="sm">
          {header ||
            formatMessage({
              id: '1ZgrhW',
              defaultMessage: 'Role',
            })}
        </Heading>
        <Radio value="Editor">
          {formatMessage({
            id: 'PtPnKX',
            defaultMessage: 'Can edit',
          })}
        </Radio>
        <Radio value="Viewer">
          {formatMessage({
            id: 'WYpfjO',
            defaultMessage: 'Can view',
          })}
        </Radio>
      </VStack>
    </RadioGroup>
  );
}
