import { Schedule } from '../types/schedule';

export function sortFutureSchedules(
  schedules: Schedule[],
  isSiteActive: boolean
): Schedule[] {
  return schedules
    .filter(
      (schedule) =>
        (schedule.startDate || 0) > Date.now() ||
        schedule.endDate === 0 ||
        (schedule.endDate || 0) > Date.now()
    )
    .sort(isSiteActive ? sortSchedulesByEndDate : sortSchedulesByStartDate);
}
function sortSchedulesByStartDate(
  scheduleA: Schedule,
  scheduleB: Schedule
): number {
  if (!scheduleA.startDate || scheduleA.startDate < Date.now()) {
    return 1;
  }
  if (!scheduleB.startDate || scheduleB.startDate < Date.now()) {
    return -1;
  }
  return scheduleA.startDate - scheduleB.startDate;
}
function sortSchedulesByEndDate(
  scheduleA: Schedule,
  scheduleB: Schedule
): number {
  if (!scheduleA.endDate) {
    return 1;
  }
  if (!scheduleB.endDate) {
    return -1;
  }
  return scheduleA.endDate - scheduleB.endDate;
}

export function hasScheduleOverlap(
  schedule: Schedule,
  otherSchedules: Schedule[]
): boolean {
  return otherSchedules.some((anotherSchedule) => {
    if (schedule.scheduleId === anotherSchedule.scheduleId) {
      return false;
    }
    const scheduleStartDate = schedule.startDate || 0;
    const anotherScheduleStartDate =
      anotherSchedule === otherSchedules[0] || !anotherSchedule.startDate
        ? 0
        : anotherSchedule.startDate;
    return (
      (scheduleStartDate >= anotherScheduleStartDate &&
        (!anotherSchedule.endDate ||
          scheduleStartDate <= anotherSchedule.endDate)) ||
      (schedule.endDate &&
        schedule.endDate >= anotherScheduleStartDate &&
        (!anotherSchedule.endDate ||
          schedule.endDate <= anotherSchedule.endDate))
    );
  });
}
