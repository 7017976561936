import './index.scss';
import { GoogleMap, GoogleMapProps } from '@react-google-maps/api';
import classnames from 'classnames';
import { memo, ReactNode, useContext, useEffect, useState } from 'react';
import {
  MapLayerTestMode,
  MapTestModeContext,
} from 'design-system/atoms/custom-google-map';
import {
  MAP_CONTAINER_STYLING,
  MAP_DEFAULT_CENTER,
  MAP_FEATURE_OPTIONS,
} from '../../constants/map';

type MapOptions = google.maps.MapOptions;

interface Props {
  onLoad?: GoogleMapProps['onLoad'];
  onMapClick?: GoogleMapProps['onClick'];
  onZoomChanged?: GoogleMapProps['onZoomChanged'];
  mapCursor?: string;
  children?: ReactNode;
  mapCenter?: google.maps.LatLngLiteral;
  options?: MapOptions;
}

function BaseMap({
  onLoad,
  onZoomChanged,
  onMapClick,
  mapCursor,
  children,
  mapCenter,
  options,
}: Props) {
  const mapOptions = {
    ...MAP_FEATURE_OPTIONS,
    draggableCursor: mapCursor,
    ...(options || {}),
  };
  const mapTestMode = useContext(MapTestModeContext);

  // Since this will be used as a persistent component,
  // we cannot rely on mounting to provide the map instance
  const [mapInstance, setMapInstance] = useState<google.maps.Map>();
  useEffect(() => {
    if (mapInstance) {
      onLoad?.(mapInstance);
    }
  }, [mapInstance, onLoad]);

  return (
    <div
      className={classnames({
        'v2-base-map': true,
      })}
      data-ignore-visual-test-conditional
    >
      <GoogleMap
        onLoad={setMapInstance}
        mapContainerStyle={MAP_CONTAINER_STYLING}
        center={mapCenter || MAP_DEFAULT_CENTER}
        clickableIcons={false}
        zoom={15}
        options={mapOptions}
        onClick={onMapClick}
        onZoomChanged={onZoomChanged}
      >
        {mapTestMode && <MapLayerTestMode />}
        {children}
      </GoogleMap>
    </div>
  );
}

export default memo(BaseMap);
