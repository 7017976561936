import './EditableSiteProperties.scss';
import { ChangeEventHandler, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { Grid, GridItem, Text } from '@chakra-ui/react';
import InfoTooltip from 'design-system/atoms/info-tooltip';
import useForm, { useFormValidation } from '../../hooks/useForm';
import useRequestStatus from '../../hooks/useRequestStatus';
import Spinner from '../Spinner';
import {
  validateNumeric,
  validateRequired,
  validatorWithFormatter,
} from '../../utils/validation';
import {
  getIntlJsSpeedUnit,
  getIntlJsDistanceUnit,
  convertSpeedValueToDisplay,
  convertDistanceValueToDisplay,
  secondsValueToMinutesDisplay,
  minutesDisplayToSecondsValue,
  convertSpeedDisplayToValue,
  convertDistanceDisplayToValue,
} from '../../utils/units';
import {
  MAX_SITE_NAME_LENGTH,
  TIME_ZONE_OPTIONS,
} from '../../constants/clientConstants';
import {
  MESSAGE_BUTTON_CANCEL,
  MESSAGE_BUTTON_SAVE,
  MESSAGE_GENERIC_ERROR,
  MESSAGE_UI_DISABLED_SITE_PERMISSION,
  MESSAGE_UNIT_SYSTEM_IMPERIAL,
  MESSAGE_UNIT_SYSTEM_METRIC,
} from '../../constants/messages';
import {
  UNIT_SYSTEM_TYPE_METRIC,
  UNIT_SYSTEM_TYPE_IMPERIAL,
} from '../../constants/serverConstants';
import { Site } from '../../types/site';

export interface EditableSitePropertiesProps {
  deleteSite: (...args: any[]) => Promise<void | Response>;
  updateSiteProps: (...args: any[]) => Promise<void | Response>;
  updateSiteKpis: (...args: any[]) => Promise<void | Response>;
  siteHasQueue: boolean;
  isUserReadOnly?: boolean;
  site: Site;
  canEditSite: boolean;
}

export default function EditableSiteProperties({
  deleteSite,
  canEditSite,
  updateSiteProps,
  updateSiteKpis,
  siteHasQueue,
  isUserReadOnly,
  site,
  site: {
    siteId,
    siteName,
    siteDescription,
    siteAddress,
    siteTimeZone,
    siteUnit,
    delayKPI,
    speedKPI,
    queueKPI,
  },
}: EditableSitePropertiesProps) {
  const { formatMessage, formatNumber } = useIntl();
  const [isEditingProps, setEditingProps] = useState(false);
  const [
    isSavingProps,
    hasSavePropsError,
    withSavingPropsStatus,
    clearSavePropsError,
  ] = useRequestStatus();
  const sitePropsForm = useForm({
    siteName,
    siteDescription,
    siteAddress,
    siteTimeZone,
    siteUnit,
  });
  const siteNameChangeHandler = (e) => {
    const trimmedValue = e.target.value.substring(0, MAX_SITE_NAME_LENGTH);
    sitePropsForm.fields.siteName.setValue(trimmedValue);
  };
  const checkRequiredError = validatorWithFormatter(
    validateRequired,
    formatMessage
  );
  const sitePropsFormValidation = useFormValidation(sitePropsForm, {
    siteName: checkRequiredError,
    siteAddress: checkRequiredError,
  });
  const saveProps = () => {
    if (sitePropsFormValidation.validate()) {
      withSavingPropsStatus(updateSiteProps)(siteId, {
        ...site,
        ...sitePropsForm.values,
      }).then(() => {
        setEditingProps(false);
      });
    }
  };
  const startPropsEditing = () => {
    sitePropsForm.reset();
    setEditingProps(true);
  };
  const cancelPropsEditing = () => {
    sitePropsForm.reset();
    clearSavePropsError();
    setEditingProps(false);
  };
  const deleteThisSite = () => {
    if (
      window.confirm(
        formatMessage({
          defaultMessage: 'Are you sure you want to delete this site?',
          id: 'otd67Q',
          description:
            'Confirmation dialogue message when trying to delete a site.',
        })
      )
    ) {
      withSavingPropsStatus(deleteSite)(siteId);
    }
  };

  const [isEditingKpis, setEditingKpis] = useState(false);
  const [
    isSavingKpis,
    hasSaveKpisError,
    withSavingKpisStatus,
    clearSaveKpisError,
  ] = useRequestStatus();
  const siteKpisForm = useForm(extractKpisFormValuesFromSite(site));
  const checkNumericError = validatorWithFormatter(
    validateNumeric,
    formatMessage
  );
  const siteKpisFormValidation = useFormValidation(siteKpisForm, {
    delayKPI: checkNumericError,
    speedKPI: checkNumericError,
    queueKPI: checkNumericError,
  });
  const saveKpis = () => {
    if (siteKpisFormValidation.validate()) {
      withSavingKpisStatus(updateSiteKpis)(siteId, {
        delayKPI: minutesDisplayToSecondsValue(siteKpisForm.values.delayKPI),
        speedKPI: convertSpeedDisplayToValue(
          siteKpisForm.values.speedKPI,
          site.siteUnit
        ),
        queueKPI: convertDistanceDisplayToValue(
          siteKpisForm.values.queueKPI,
          site.siteUnit
        ),
      }).then(() => {
        setEditingKpis(false);
      });
    }
  };
  const startKpisEditing = () => {
    siteKpisForm.reset();
    setEditingKpis(true);
  };
  const cancelKpisEditing = () => {
    siteKpisForm.reset();
    clearSaveKpisError();
    setEditingKpis(false);
  };

  return (
    <div className="v2-editable-site-properties">
      <h3 className="v2-editable-site-properties__title">
        <FormattedMessage
          defaultMessage="Site Properties"
          id="W+AjV6"
          description="Site Properties panel title"
        />
      </h3>
      <Grid gridTemplateColumns="repeat(12, 1fr)" gap={6}>
        <GridItem colSpan={{ base: 12, md: 7 }}>
          <Grid gridTemplateColumns="repeat(12, 1fr)" gap={6}>
            <GridItem colSpan={4}>
              <Field
                label={formatMessage({
                  defaultMessage: 'Title',
                  id: 'wLP4Qg',
                  description: 'Edit site name field label',
                })}
                dataTestId="admin-site-title"
                value={siteName}
                editableValue={sitePropsForm.fields.siteName.value}
                onChange={siteNameChangeHandler}
                isEditing={isEditingProps}
                disabled={isSavingProps}
                error={sitePropsFormValidation.fields.siteName.error}
              />
              {isEditingProps &&
                sitePropsForm.fields.siteName.value.length >
                  MAX_SITE_NAME_LENGTH - 16 && (
                  <Text mt="-12px" mb="12px" fontSize="12px">
                    <FormattedMessage
                      defaultMessage="characters left: {charCount}"
                      id="KrjaEY"
                      description="max char count for site name"
                      values={{
                        charCount:
                          MAX_SITE_NAME_LENGTH -
                          sitePropsForm.fields.siteName.value.length,
                      }}
                    />
                  </Text>
                )}
              <Field
                label={formatMessage({
                  defaultMessage: 'Location',
                  id: '+SMf4k',
                  description: 'Edit site location field label',
                })}
                dataTestId="admin-site-location"
                value={siteAddress}
                editableValue={sitePropsForm.fields.siteAddress.value}
                onChange={sitePropsForm.fields.siteAddress.onChange}
                isEditing={isEditingProps}
                disabled={isSavingProps}
                error={sitePropsFormValidation.fields.siteAddress.error}
              />
            </GridItem>
            <GridItem colSpan={4}>
              <Field
                label={formatMessage({
                  defaultMessage: 'Time zone',
                  id: 'FFEE1X',
                  description: 'Edit site time zone field label',
                })}
                value={siteTimeZone}
                editableValue={sitePropsForm.fields.siteTimeZone.value}
                onChange={sitePropsForm.fields.siteTimeZone.onChange}
                isEditing={isEditingProps}
                disabled={isSavingProps}
                options={TIME_ZONE_OPTIONS}
              />
              <Field
                label={formatMessage({
                  defaultMessage: 'Unit system',
                  id: '9shvsD',
                  description: 'Edit site unit system field label',
                })}
                value={siteUnit}
                editableValue={sitePropsForm.fields.siteUnit.value}
                onChange={sitePropsForm.fields.siteUnit.onChange}
                isEditing={isEditingProps}
                disabled={isSavingProps}
                options={[
                  {
                    value: UNIT_SYSTEM_TYPE_METRIC,
                    label: formatMessage(MESSAGE_UNIT_SYSTEM_METRIC),
                  },
                  {
                    value: UNIT_SYSTEM_TYPE_IMPERIAL,
                    label: formatMessage(MESSAGE_UNIT_SYSTEM_IMPERIAL),
                  },
                ]}
              />
            </GridItem>
            <GridItem colSpan={4}>
              <label className="v2-editable-site-properties__field-container">
                <span className="v2-editable-site-properties__field-label">
                  <FormattedMessage
                    defaultMessage="Description"
                    id="Wowq70"
                    description="Site description field label"
                  />
                </span>
                {isEditingProps ? (
                  <textarea
                    className="v2-editable-site-properties__textarea"
                    data-testid="admin-site-description-edit"
                    value={sitePropsForm.fields.siteDescription.value}
                    onChange={sitePropsForm.fields.siteDescription.onChange}
                    disabled={isSavingProps}
                  />
                ) : (
                  <span
                    className="v2-editable-site-properties__field-value"
                    data-testid="admin-site-description-value"
                  >
                    {siteDescription || '-'}
                  </span>
                )}
              </label>
            </GridItem>
          </Grid>
          <Actions
            edit={startPropsEditing}
            save={saveProps}
            cancel={cancelPropsEditing}
            isEditing={isEditingProps}
            isSaving={isSavingProps}
            hasError={hasSavePropsError}
            editButtonLabel={formatMessage({
              defaultMessage: 'Edit site properties',
              id: 'x09WYa',
              description: 'Edit site properties button label',
            })}
            isUserReadOnly={isUserReadOnly}
            extraActions={
              <button
                type="button"
                aria-label="Delete Site"
                onClick={deleteThisSite}
                disabled={isSavingProps || !canEditSite}
                className="v2-editable-site-properties__btn-delete"
              >
                <FormattedMessage
                  defaultMessage="Delete Site"
                  id="NWSF8w"
                  description="Delete site button label."
                />
              </button>
            }
          />
        </GridItem>
        <GridItem colSpan={{ base: 12, md: 5 }} mt={{ base: 4, md: 0 }}>
          <div className="v2-editable-site-properties__kpis">
            <div className="v2-editable-site-properties__kpis-intro">
              <FormattedMessage
                defaultMessage="These are the limits that you want to operate within, these limits are also used in SMS alerts."
                id="0GWzfS"
                description="Intro text of site KPIs management panel."
              />
            </div>
            <Grid gridTemplateColumns="repeat(12, 1fr)" gap={6}>
              <GridItem colSpan={4}>
                <Field
                  type="number"
                  label={formatMessage({
                    defaultMessage: 'Delay KPI',
                    id: 'mDjd8o',
                    description: 'Edit site Delay KPI field label',
                  })}
                  value={secondsValueToMinutesDisplay(delayKPI)}
                  editableValue={siteKpisForm.fields.delayKPI.value}
                  onChange={siteKpisForm.fields.delayKPI.onChange}
                  isEditing={isEditingKpis}
                  disabled={isSavingKpis}
                  dataTestId="admin-site-delaykpi"
                  error={siteKpisFormValidation.fields.delayKPI.error}
                  formatValue={(fieldValue) =>
                    formatNumber(Number(fieldValue), {
                      style: 'unit',
                      unit: 'minute',
                      unitDisplay: 'long',
                    })
                  }
                />
              </GridItem>
              <GridItem colSpan={4}>
                <Field
                  type="number"
                  label={formatMessage({
                    defaultMessage: 'Speed KPI',
                    id: 'gcB0Im',
                    description: 'Edit site Speed KPI field label',
                  })}
                  value={convertSpeedValueToDisplay(speedKPI, siteUnit)}
                  editableValue={siteKpisForm.fields.speedKPI.value}
                  onChange={siteKpisForm.fields.speedKPI.onChange}
                  isEditing={isEditingKpis}
                  dataTestId="admin-site-speedkpi"
                  disabled={isSavingKpis}
                  error={siteKpisFormValidation.fields.speedKPI.error}
                  formatValue={(fieldValue) =>
                    formatNumber(Number(fieldValue), {
                      style: 'unit',
                      unit: getIntlJsSpeedUnit(siteUnit),
                      unitDisplay: 'short',
                    })
                  }
                />
              </GridItem>
              <GridItem colSpan={4}>
                {siteHasQueue && (
                  <Field
                    type="number"
                    label={formatMessage({
                      defaultMessage: 'Queue KPI',
                      id: 'IVZIGS',
                      description: 'Edit site Queue KPI field label',
                    })}
                    value={convertDistanceValueToDisplay(queueKPI, siteUnit)}
                    editableValue={siteKpisForm.fields.queueKPI.value}
                    dataTestId="admin-site-queuekpi"
                    onChange={siteKpisForm.fields.queueKPI.onChange}
                    isEditing={isEditingKpis}
                    disabled={isSavingKpis}
                    error={siteKpisFormValidation.fields.queueKPI.error}
                    formatValue={(fieldValue) =>
                      formatNumber(Number(fieldValue), {
                        style: 'unit',
                        unit: getIntlJsDistanceUnit(siteUnit),
                        unitDisplay: 'long',
                      })
                    }
                  />
                )}
              </GridItem>
            </Grid>
            <Actions
              edit={startKpisEditing}
              save={saveKpis}
              cancel={cancelKpisEditing}
              isEditing={isEditingKpis}
              isSaving={isSavingKpis}
              hasError={hasSaveKpisError}
              editButtonLabel={formatMessage({
                defaultMessage: 'Edit site KPIs',
                id: 'k/Yx21',
                description: 'Edit site KPIs button label',
              })}
              isUserReadOnly={isUserReadOnly}
            />
          </div>
        </GridItem>
      </Grid>
    </div>
  );
}

interface ActionsProps {
  edit: () => void;
  save: () => void;
  cancel: () => void;
  isEditing: boolean;
  isSaving: boolean;
  hasError: boolean;
  editButtonLabel?: string;
  isUserReadOnly?: boolean;
  extraActions?: JSX.Element;
}

function Actions({
  edit,
  save,
  cancel,
  isEditing,
  isSaving,
  hasError,
  editButtonLabel,
  isUserReadOnly,
  extraActions,
}: ActionsProps) {
  const { formatMessage } = useIntl();
  return (
    <div className="v2-editable-site-properties__actions">
      {isEditing ? (
        <>
          <button
            type="button"
            onClick={save}
            disabled={isSaving}
            className="v2-editable-site-properties__btn-confirm"
          >
            {formatMessage(MESSAGE_BUTTON_SAVE)}
          </button>
          <button
            type="button"
            onClick={cancel}
            disabled={isSaving}
            className="v2-editable-site-properties__btn-cancel"
          >
            {formatMessage(MESSAGE_BUTTON_CANCEL)}
          </button>
          {extraActions && (
            <div className="v2-editable-site-properties__actions-extra">
              {extraActions}
            </div>
          )}
        </>
      ) : (
        <InfoTooltip
          content={
            isUserReadOnly
              ? formatMessage(MESSAGE_UI_DISABLED_SITE_PERMISSION)
              : undefined
          }
        >
          <button
            key={0}
            type="button"
            onClick={edit}
            className="v2-editable-site-properties__btn-confirm"
            disabled={isUserReadOnly}
          >
            {editButtonLabel}
          </button>
        </InfoTooltip>
      )}
      {isSaving && <Spinner />}
      {hasError && (
        <div className="v2-editable-site-properties__error">
          {formatMessage(MESSAGE_GENERIC_ERROR)}
        </div>
      )}
    </div>
  );
}

function extractKpisFormValuesFromSite(site: Site) {
  return {
    delayKPI: secondsValueToMinutesDisplay(site.delayKPI),
    speedKPI: convertSpeedValueToDisplay(site.speedKPI, site.siteUnit),
    queueKPI: convertDistanceValueToDisplay(site.queueKPI, site.siteUnit),
  };
}

interface FieldProps {
  type?: string;
  label?: string;
  value?: string;
  editableValue?: string;
  disabled?: boolean;
  isEditing?: boolean;
  dataTestId?: string;
  onChange: ChangeEventHandler;
  error?: string;
  options?: Array<{
    label: string;
    value: string;
  }>;
  formatValue?: <T>(value: T) => T | string;
}

function Field({
  type = 'text',
  label = '',
  value = '',
  editableValue = '',
  disabled = false,
  isEditing = false,
  onChange,
  error,
  options,
  dataTestId,
  formatValue = (v) => v,
}: FieldProps) {
  const editableElement = !options ? (
    <input
      className="v2-editable-site-properties__input"
      type={type}
      value={editableValue}
      onChange={onChange}
      disabled={disabled}
    />
  ) : (
    <select
      className="v2-editable-site-properties__select"
      value={editableValue}
      onChange={onChange}
      disabled={disabled}
    >
      {options.map((option) => (
        <option key={option.value} value={option.value}>
          {option.label}
        </option>
      ))}
    </select>
  );
  return (
    <label className="v2-editable-site-properties__field-container">
      <span className="v2-editable-site-properties__field-label">{label}</span>
      {isEditing ? (
        editableElement
      ) : (
        <span
          className="v2-editable-site-properties__field-value"
          data-testid={`${dataTestId}-value`}
        >
          {options
            ? formatValue(
                (options.find((opt) => opt.value === value) || { label: '-' })
                  .label
              )
            : formatValue(value)}
        </span>
      )}
      {error && (
        <span className="v2-editable-site-properties__field-error">
          {error}
        </span>
      )}
    </label>
  );
}
