import useSWR from 'swr';
import { RoutePerformance } from '../types/route';
import { dataTrpc } from '../config/trpc';

export interface RoutePerformanceDataHandler {
  data: RoutePerformance | undefined;
  error: any;
  isValidating: boolean;
}

export default function useRoutePerformanceDataHandler(
  trackId: string,
  insightId: string,
  siteId: number
): RoutePerformanceDataHandler {
  const { data, error, isValidating } = useSWR<RoutePerformance>(
    `useRoutePerformanceData/${insightId}/${trackId}`,
    () =>
      dataTrpc.routePerformance.get.query({
        passageId: trackId,
        orgId: insightId,
        siteId: `${siteId}`,
      }) as Promise<RoutePerformance>,
    {
      refreshInterval: 5 * 60 * 1000,
    }
  );
  return {
    data,
    error,
    isValidating,
  };
}
