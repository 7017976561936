import { useIntl } from 'react-intl';
import ConfirmationModal from 'design-system/molecules/confirmation-modal';

export interface DeleteNodeConfirmationModalProps {
  onCancel: () => void;
  onConfirm: () => void;
  open: boolean;
}

export default function DeleteNodeConfirmationModal({
  onCancel,
  onConfirm,
  open,
}: DeleteNodeConfirmationModalProps) {
  const { formatMessage } = useIntl();
  return (
    <ConfirmationModal
      body={formatMessage({
        id: 'TnB9q5',
        description: 'Delete note confirmation modal body.',
        defaultMessage:
          'Deleting this annotation cannot be undone, are you sure you want to do this?',
      })}
      cancelButtonText={formatMessage({
        id: 'ZJ8uNi',
        description: 'Delete note confirmation modal cancel text.',
        defaultMessage: 'Cancel',
      })}
      confirmButtonText={formatMessage({
        id: 'CegKlw',
        description: 'Delete note confirmation modal confirm text.',
        defaultMessage: 'Delete note',
      })}
      confirmButtonColorScheme="red"
      open={open}
      onCancel={onCancel}
      onConfirm={onConfirm}
      title={formatMessage({
        id: 'Zfh+fw',
        description: 'Delete note confirmation modal title.',
        defaultMessage: 'Delete note?',
      })}
    />
  );
}
