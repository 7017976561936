import { Box, Flex, HStack, Text } from '@chakra-ui/react';
import { FormattedMessage, useIntl } from 'react-intl';
import { Link } from 'react-router-dom';
import Card from 'design-system/atoms/card';
import Insight from 'design-system/molecules/insight';
import GridWrapper from 'design-system/atoms/grid-wrapper';
import { memo, useCallback } from 'react';
import { pathSitesView } from '../constants/path';
import performanceStatsOptions from '../constants/performanceStatsOptions';
import { useAnalytics } from '../hooks/analytics/useAnalytics';
import NoRouteData from '../svg/no-routes-data-icon.svg?react';
import ScheduledIcon from '../svg/schedule-icon.svg?react';
import SleepIcon from '../svg/sleep-icon.svg?react';
import {
  DATE_PRESET_NARROW_COMPLETE,
  DATE_PRESET_SHORT_D_M_Y,
  TIME_PRESET_COMPLETE_HOUR12,
} from '../utils/dateUtils';
import './SitePerformanceOverview.scss';
import { getColorBySiteId } from '../utils/colors';
import useInsightSummaryData from '../data/useInsightSummaryData';

interface SitePerformanceOverviewProps {
  siteName: string;
  siteUnit: 'IMPERIAL' | 'METRIC';
  siteTimeZone: string;
  siteAddress: string;
  siteId: number;
  insightId: number;
  active: boolean;
  numberOfRoutes: number;
  futureScheduleStartDate: number;
  currentScheduleEndDate: number;
  isSelected?: boolean;
}

function SitePerformanceOverview({
  siteName,
  siteUnit,
  siteTimeZone,
  siteAddress,
  siteId,
  insightId,
  active,
  numberOfRoutes,
  futureScheduleStartDate,
  currentScheduleEndDate,
  isSelected,
}: SitePerformanceOverviewProps) {
  const { formatMessage, formatDate, formatTime } = useIntl();
  const analytics = useAnalytics();
  const summaryDataHandler = useInsightSummaryData({
    insightId: `${insightId}`,
    siteId: active ? siteId : undefined,
    timePeriod: { type: 'TIME_PERIOD_LAST_24_HOURS' },
    siteUnit,
    hiddenRoutes: [],
  });
  const handleNavigate = useCallback(() => {
    analytics.track('Site Card Clicked', {
      siteActive: active,
      numberOfRoutes,
      referrer: 'Homepage',
    });
  }, [analytics, active, numberOfRoutes]);

  const enableMonitoringCommand = formatMessage({
    defaultMessage: 'ENABLE OR SCHEDULE MONITORING',
    id: 'vGob2S',
    description:
      'Command to enable monitoring from a site card on the Homepage',
  });
  const editMonitoringCommand = formatMessage({
    defaultMessage: 'EDIT MONITORING SCHEDULE',
    id: '2rmTb3',
    description:
      'Command to edit monitoring schedule from a site card on the Homepage',
  });
  const monitoringNotActiveLabel = formatMessage({
    defaultMessage: 'Monitoring not active',
    id: 'R0G7zS',
    description:
      'Site label when monitoring is not active on a site card on the Homepage',
  });
  return (
    <Link
      to={{
        pathname: pathSitesView(insightId),
        search: siteId ? `?siteId=${siteId}` : '',
      }}
      onClick={handleNavigate}
      className="v2-site-performance-overview__link"
    >
      <Card
        colorMode={active || numberOfRoutes === 0 ? 'light' : 'dark'}
        variant="link"
        width="full"
        mb={2}
        position="relative"
        boxShadow="soft"
      >
        <Box
          position="absolute"
          left="6px"
          top="6px"
          width={isSelected ? '48px' : '16px'}
          height="6px"
          borderRadius="3px"
          transition="width 0.5s"
          backgroundColor={getColorBySiteId(siteId)}
        />
        <Flex w="full" justifyContent="space-between" wrap="wrap">
          <Flex direction="column" alignItems="flex-start" mb={4}>
            <Text as="h3" fontSize="md">
              {siteName}
            </Text>
            <Text fontSize="xs" mt={0} as="h6" fontWeight="normal">
              {siteAddress}
            </Text>
          </Flex>
          <Box>
            {numberOfRoutes > 0 && active && currentScheduleEndDate && (
              <HStack mb={4} alignItems="center">
                <Text m="auto" fontSize="xs">
                  <FormattedMessage
                    defaultMessage="Monitoring ends:"
                    id="Dq6Dsm"
                    description="Home page card - monitoring ends text"
                  />
                </Text>
                <Text fontSize="xs">
                  <FormattedMessage
                    defaultMessage="{endDate}"
                    id="EfSDRN"
                    description="Home page card - monitoring scheduled end date"
                    values={{
                      endDate: formatDate(currentScheduleEndDate, {
                        ...DATE_PRESET_SHORT_D_M_Y,
                        timeZone: siteTimeZone,
                      }).toUpperCase(),
                    }}
                  />
                </Text>
              </HStack>
            )}
            {numberOfRoutes === 0 && (
              <Insight
                IconSVG={NoRouteData}
                summary={formatMessage({
                  defaultMessage: 'No routes added',
                  id: 'ebyTu7',
                  description:
                    'Site label when it has no routes on a site card on the Homepage',
                })}
                paragraph={formatMessage({
                  defaultMessage:
                    'Add a route to start monitoring data on this site.',
                  id: 'Bi2grf',
                })}
                actionLabel={formatMessage({
                  defaultMessage: 'Add a route',
                  id: '3RIv5k',
                  description:
                    'Add route command from a site card on the Homepage',
                })}
              />
            )}
            {numberOfRoutes > 0 && !active && !futureScheduleStartDate && (
              <Insight
                IconSVG={SleepIcon}
                summary={monitoringNotActiveLabel}
                paragraph={formatMessage({
                  defaultMessage:
                    'Data is not currently being collected and is showing the last recorded data at this site.',
                  id: 'FlNPpQ',
                })}
                actionLabel={enableMonitoringCommand}
              />
            )}
            {numberOfRoutes > 0 && !active && futureScheduleStartDate && (
              <Insight
                IconSVG={ScheduledIcon}
                summary={monitoringNotActiveLabel}
                paragraph={
                  <div>
                    {formatMessage({
                      defaultMessage: 'Monitoring is scheduled to begin',
                      id: 'XuAV0I',
                    })}
                    <br />
                    <strong>
                      {formatDate(futureScheduleStartDate, {
                        ...DATE_PRESET_SHORT_D_M_Y,
                        timeZone: siteTimeZone,
                      }).toUpperCase()}
                    </strong>
                  </div>
                }
                actionLabel={editMonitoringCommand}
              />
            )}
          </Box>
        </Flex>
        {summaryDataHandler.data && summaryDataHandler.data.length > 0 && (
          <Box>
            <Box w="full" overflowX="auto" mb={-4} pb={4}>
              <GridWrapper>
                {summaryDataHandler.data
                  .filter((s) => s.date)
                  .map((summaryModel) => {
                    if (!summaryModel.summaryType) {
                      return null;
                    }
                    const { IconComponent } =
                      performanceStatsOptions[summaryModel.summaryType];
                    const title = formatMessage(
                      performanceStatsOptions[summaryModel.summaryType]
                        .titleMessage
                    );
                    return (
                      <Insight
                        key={summaryModel.summaryType}
                        IconSVG={IconComponent}
                        label={title}
                        summary={summaryModel.routeName}
                        title={summaryModel.display}
                        paragraph={
                          summaryModel.date
                            ? `${formatDate(summaryModel.date! * 1000, {
                                ...DATE_PRESET_NARROW_COMPLETE,
                                timeZone: siteTimeZone,
                              })}, ${formatTime(summaryModel.date! * 1000, {
                                ...TIME_PRESET_COMPLETE_HOUR12,
                                timeZone: siteTimeZone,
                              })}`
                            : ''
                        }
                      />
                    );
                  })}
              </GridWrapper>
            </Box>
          </Box>
        )}
      </Card>
    </Link>
  );
}

export default memo(SitePerformanceOverview);
