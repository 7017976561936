import { Tab, TabList, Tabs } from '@chakra-ui/react';
import { useNavigate } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import { pathSitesOverview } from '../../constants/path';

export type TabPath = 'sites' | 'routes' | 'scorecards';

const tabsOrder: TabPath[] = ['sites', 'routes', 'scorecards'];

export default function TabsList({
  overviewTab,
  insightId,
}: {
  overviewTab?: string;
  insightId: string;
}) {
  const navigate = useNavigate();

  return (
    <Tabs
      index={tabsOrder.indexOf((overviewTab as TabPath) ?? 'sites')}
      onChange={(index) => {
        navigate(pathSitesOverview(insightId, tabsOrder[index], null));
      }}
      colorScheme="green"
    >
      <TabList>
        <Tab fontSize="sm" fontWeight="600">
          <FormattedMessage
            defaultMessage="Sites"
            id="+nwM0o"
            description="Homepage monitoring mode - tab label for sites"
          />
        </Tab>
        <Tab fontSize="sm" fontWeight="600">
          <FormattedMessage
            defaultMessage="Routes"
            id="zNeszo"
            description="Homepage monitoring mode - tab label for routes"
          />
        </Tab>
        <Tab fontSize="sm" fontWeight="600">
          <FormattedMessage
            defaultMessage="Scorecards"
            id="OiwdJg"
            description="Homepage monitoring mode - tab label for scorecards"
          />
        </Tab>
      </TabList>
    </Tabs>
  );
}
