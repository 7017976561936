export enum CheckboxShape {
  CIRCLE,
  RECT,
}

interface CheckBoxSvgProps {
  checked: boolean;
  color?: string;
  checkBoxShape?: CheckboxShape;
  className?: string;
}

const CheckBoxSvg = ({
  checked,
  color = 'black',
  checkBoxShape = CheckboxShape.RECT,
  className,
}: CheckBoxSvgProps) => (
  <svg
    width="22"
    height="23"
    viewBox="0 0 22 23"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
  >
    {checkBoxShape === CheckboxShape.RECT && (
      <rect
        x="0.784338"
        y="1.22434"
        width="20.4313"
        height="20.4313"
        rx="0.784338"
        fill="white"
        stroke={color}
        strokeWidth="1.56868"
      />
    )}

    {checkBoxShape === CheckboxShape.CIRCLE && (
      <circle
        cx="11"
        cy="11"
        r="10"
        stroke={color}
        fill="white"
        strokeWidth="2"
      />
    )}

    {checked && (
      <path
        d="M17.4929 8.69222L15.2598 6.4591L9.30488 12.414L7.44406 10.5532L5.21094 12.7863L9.30478 16.8803L17.4929 8.69222Z"
        fill={color}
      />
    )}
  </svg>
);

export default CheckBoxSvg;
