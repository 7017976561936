import { useEffect, useRef, useState } from 'react';

export type PolygonCreatorManager = ReturnType<typeof usePolygonCreatorManager>;

export default function usePolygonCreatorManager() {
  const [isCreating, setIsCreating] = useState(false);
  const keydownEventHandlerRef = useRef<((e: KeyboardEvent) => void) | null>(
    null
  );
  const stopCreating = () => {
    setIsCreating(false);
    if (keydownEventHandlerRef.current) {
      document.removeEventListener('keydown', keydownEventHandlerRef.current);
      keydownEventHandlerRef.current = null;
    }
  };
  const startCreating = () => {
    if (isCreating) {
      return;
    }
    setIsCreating(true);
    keydownEventHandlerRef.current = (e) => {
      if (e.key === 'Escape') {
        stopCreating();
      }
    };
    document.addEventListener('keydown', keydownEventHandlerRef.current);
  };
  useEffect(
    () => () => {
      if (keydownEventHandlerRef.current) {
        document.removeEventListener('keydown', keydownEventHandlerRef.current);
      }
    },
    []
  );
  return {
    isCreating,
    startCreating,
    stopCreating,
  };
}
