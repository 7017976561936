import {
  Button,
  Divider,
  Heading,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  useDisclosure,
  VStack,
} from '@chakra-ui/react';
import { createContext, ReactNode } from 'react';
import { useIntl } from 'react-intl';
import { ChevronDownIcon } from '@chakra-ui/icons';

export interface UserContextMenuProps {
  isOpen?: boolean;
  triggerButtonLabel?: ReactNode;
  children?: ReactNode;
  user: string;
}

export const UserContextMenuContext = createContext({
  onClose: () => {},
});

export default function UserContextMenu({
  isOpen,
  triggerButtonLabel,
  children,
  user,
}: UserContextMenuProps) {
  const menu = useDisclosure({ isOpen });
  const { formatMessage } = useIntl();
  return (
    <>
      <Button
        data-testid="user-list-context-menu-trigger"
        size="sm"
        fontWeight="normal"
        variant="outline"
        onClick={menu.onOpen}
        rightIcon={<ChevronDownIcon />}
      >
        {triggerButtonLabel ||
          formatMessage({
            id: '3XGzO/',
            defaultMessage: 'Options',
            description: 'User context menu default trigger button label',
          })}
      </Button>
      <Modal isOpen={menu.isOpen} onClose={menu.onClose}>
        <ModalOverlay backgroundColor="whiteAlpha.800" />
        <ModalContent>
          <ModalHeader p={8} pr={16}>
            <Heading size="md">
              {formatMessage(
                {
                  defaultMessage: 'Options for {name}',
                  id: 'iJo0G3',
                  description: 'User context menu modal title',
                },
                { name: user }
              )}
            </Heading>
            <ModalCloseButton
              backgroundColor="gray.100"
              top={8}
              right={8}
              size="sm"
            />
          </ModalHeader>
          <ModalBody px={8} py={0}>
            <UserContextMenuContext.Provider value={menu}>
              <VStack
                spacing={4}
                divider={<Divider variant="dashed" />}
                alignItems="left"
              >
                {children}
              </VStack>
            </UserContextMenuContext.Provider>
            <ModalFooter justifyContent="start" mx={0} px={0} py={8}>
              <Button onClick={menu.onClose} variant="outline" size="sm">
                {formatMessage({
                  id: 'CygiOx',
                  defaultMessage: 'Close',
                  description: 'User context menu close button',
                })}
              </Button>
            </ModalFooter>
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
}
