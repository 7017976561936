import {
  Flex,
  FormControl,
  FormErrorMessage,
  FormHelperText,
  FormLabel,
  Heading,
  HStack,
  Input,
  Switch,
  Text,
  useDisclosure,
} from '@chakra-ui/react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import useCurrentUserData from '../../data/useCurrentUserData';
import {
  DataHandlerFeedback,
  hasDataHandlerFeedBack,
} from '../../utils/dataHandler';
import { User } from '../../types/user';
import { useSuccessToast } from '../../hooks/useSuccessToast';
import { useFailureToast } from '../../hooks/useFailureToast';
import FormActions from '../FormActions';
import LayoutPage from '../LayoutPage';
import { errorReport } from '../../utils/errors';

interface FormValues {
  firstName: string;
  lastName: string;
  oldPassword?: string;
  password?: string;
  confirmPassword?: string;
}

export default function UserProfile() {
  const userHandler = useCurrentUserData();
  if (hasDataHandlerFeedBack(userHandler)) {
    return <DataHandlerFeedback dataHandlersParam={userHandler} />;
  }
  return (
    <UserProfileForm
      user={userHandler.data!}
      updateUserProfile={userHandler.updateUserProfile}
    />
  );
}

export function UserProfileForm({
  user,
  updateUserProfile,
}: {
  user: User;
  updateUserProfile: (
    firstName: string,
    lastName: string,
    isChangePassword: boolean,
    oldPassword?: string,
    newPassword?: string
  ) => Promise<void>;
}) {
  const { formatMessage } = useIntl();
  const { isOpen, onToggle, onClose } = useDisclosure();
  const {
    register,
    getValues,
    formState: { errors, isSubmitting },
    handleSubmit,
    resetField,
    reset,
  } = useForm<FormValues>({
    mode: 'onChange',
    defaultValues: {
      firstName: user.firstName ?? '',
      lastName: user.lastName ?? '',
    },
  });
  const successToast = useSuccessToast();
  const failureToast = useFailureToast();
  const navigate = useNavigate();

  const checkConfirmPassword = (
    confirmPassword: FormValues['confirmPassword']
  ) => {
    const formValues = getValues();
    if (confirmPassword && confirmPassword !== formValues.password) {
      return false;
    }
    return true;
  };

  const onSubmit = async (values: FormValues) => {
    try {
      await updateUserProfile(
        values.firstName,
        values.lastName,
        isOpen,
        values.oldPassword,
        values.password
      );
      onClose();
      reset({
        firstName: values.firstName,
        lastName: values.lastName,
        oldPassword: '',
        confirmPassword: '',
        password: '',
      });
      successToast({
        title: formatMessage({
          defaultMessage: 'Profile successfully updated',
          id: 'MwDURt',
          description: 'User profile - successful update',
        }),
      });
    } catch (e: any) {
      if (e.message?.includes('Password requirements were not met')) {
        failureToast({
          title: formatMessage({
            defaultMessage:
              'Please ensure your password has at least 8 characters, a lowercase letter, an uppercase letter, a number, and no parts of your email.',
            id: 'gIFPvq',
            description: 'Set password strength error message',
          }),
        });
      } else {
        errorReport.handled(e);
        failureToast({
          title: formatMessage({
            defaultMessage:
              'There was an issue updating your profile. Try again or contact us.',
            id: 'KtE3Y1',
            description: 'User profile - update profile error toast',
          }),
        });
      }
    }
  };

  return (
    <LayoutPage>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Flex w="full" justifyContent="center" mt={24}>
          <Flex
            direction="column"
            alignItems="flex-start"
            w="full"
            maxW="800px"
            p={8}
          >
            <Heading size="4xl" mb={4}>
              {user.firstName ?? (
                <FormattedMessage
                  defaultMessage="Add your name"
                  id="d3XdfB"
                  description="User profile: Add your name heading"
                />
              )}
            </Heading>
            <Text fontSize="2xl" mb={10}>
              <FormattedMessage
                defaultMessage="Customise your profile"
                id="qKktTw"
                description="User profile: Customize your profile text"
              />
            </Text>
            <HStack mb={6} w="full">
              <FormControl isInvalid={!!errors.firstName}>
                <Input
                  size="md"
                  maxLength={35}
                  placeholder={formatMessage({
                    defaultMessage: 'First name',
                    id: 'pONqz8',
                  })}
                  {...register('firstName', {
                    required: formatMessage({
                      defaultMessage: 'Please enter first name',
                      id: 'uBY5EC',
                      description: 'Error message for first name',
                    }),
                  })}
                />
                {errors?.firstName && (
                  <FormErrorMessage>
                    {errors.firstName.message}
                  </FormErrorMessage>
                )}
              </FormControl>
              <FormControl isInvalid={!!errors.lastName}>
                <Input
                  size="md"
                  maxLength={35}
                  placeholder={formatMessage({
                    defaultMessage: 'Last name',
                    id: 'txUL0F',
                  })}
                  {...register('lastName', {
                    required: formatMessage({
                      defaultMessage: 'Please enter last name',
                      id: '8um9H0',
                      description: 'Error message for last name',
                    }),
                  })}
                />
                {errors?.lastName && (
                  <FormErrorMessage>{errors.lastName.message}</FormErrorMessage>
                )}
              </FormControl>
            </HStack>
            <FormControl mb={6}>
              <Input
                disabled
                w="full"
                size="md"
                placeholder={formatMessage({
                  defaultMessage: 'Email',
                  id: 'sy+pv5',
                })}
                value={user.email}
              />
            </FormControl>
            <FormControl
              display="flex"
              alignItems="center"
              mb={6}
              justifyContent="flex-start"
            >
              <Switch
                size="md"
                id="user-profile-change-password-switch"
                colorScheme="green"
                data-testid="user-profile-change-password-switch"
                onChange={() => {
                  const open = isOpen;
                  onToggle();
                  if (open) {
                    resetField('oldPassword');
                    resetField('password');
                    resetField('confirmPassword');
                  }
                }}
                isChecked={isOpen}
              />
              <FormLabel
                htmlFor="user-profile-change-password-switch"
                ml={2}
                mb={0}
                fontSize="md"
                as={Text}
                fontWeight="normal"
              >
                <FormattedMessage
                  defaultMessage="Change password"
                  id="L4nXIc"
                />
              </FormLabel>
            </FormControl>
            {isOpen && (
              <>
                <FormControl mb={6} isInvalid={!!errors.oldPassword}>
                  <Input
                    w="full"
                    size="md"
                    type="password"
                    placeholder={formatMessage({
                      defaultMessage: 'Old password',
                      id: 'SViZJR',
                    })}
                    {...register('oldPassword', {
                      required: formatMessage({
                        defaultMessage: 'Please enter old password',
                        id: 'RNj3FF',
                        description: 'Error message for old password',
                      }),
                    })}
                  />
                  {errors?.oldPassword && (
                    <FormErrorMessage>
                      {errors.oldPassword.message}
                    </FormErrorMessage>
                  )}
                </FormControl>
                <FormControl mb={6} isInvalid={!!errors.password}>
                  <Input
                    w="full"
                    size="md"
                    type="password"
                    placeholder={formatMessage({
                      defaultMessage: 'New password',
                      id: 'fTHhSB',
                    })}
                    {...register('password', {
                      required: formatMessage({
                        defaultMessage: 'Please enter new password',
                        id: 'UjU/G/',
                        description: 'Error message for new password',
                      }),
                    })}
                  />
                  <FormHelperText>
                    {formatMessage({
                      defaultMessage:
                        'Must have at least 8 characters, a lowercase letter, an uppercase letter, a number, and no parts of your email.',
                      id: 'Mq+9D/',
                      description: 'create account password input helper text',
                    })}
                  </FormHelperText>
                  {errors?.password && (
                    <FormErrorMessage>
                      {errors.password.message}
                    </FormErrorMessage>
                  )}
                </FormControl>
                <FormControl mb={6} isInvalid={!!errors.confirmPassword}>
                  <Input
                    w="full"
                    size="md"
                    type="password"
                    placeholder={formatMessage({
                      defaultMessage: 'Confirm new password',
                      id: 'fe7Y2Z',
                    })}
                    {...register('confirmPassword', {
                      required: formatMessage({
                        defaultMessage: 'Please enter confirm password',
                        id: 'GJYY+y',
                        description: 'Error message for confirm password',
                      }),
                      validate: {
                        checkConfirmPassword,
                      },
                    })}
                  />
                  {errors.confirmPassword && (
                    <FormErrorMessage>
                      {errors.confirmPassword.message}
                    </FormErrorMessage>
                  )}
                  {errors.confirmPassword &&
                    errors.confirmPassword.type === 'checkConfirmPassword' && (
                      <FormErrorMessage>
                        {formatMessage({
                          defaultMessage:
                            'Please make sure your password matches',
                          id: 'ElIH9F',
                          description: 'Error message for confirm password',
                        })}
                      </FormErrorMessage>
                    )}
                </FormControl>
              </>
            )}
            <FormActions
              onSave={handleSubmit(onSubmit)}
              onCancel={() => navigate(-1)}
              isSaving={isSubmitting}
              saveLabel={formatMessage({
                defaultMessage: 'Save profile',
                id: 'ZBVds5',
              })}
            />
          </Flex>
        </Flex>
      </form>
    </LayoutPage>
  );
}
