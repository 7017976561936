import { SplitFactory, SplitSdk } from '@splitsoftware/splitio-react';

import { createRoot } from 'react-dom/client';
import { RouterProvider } from 'react-router-dom';
import 'regenerator-runtime/runtime';
import '../../styles/fonts.scss';
import '../../styles/styles.scss';
import ConfiguredChakraProvider from '../../config/chakra';
import { initTagManager } from '../../config/googleTagManager';
import { ConfiguredIntlProvider } from '../../config/intl';
import { initRaygun } from '../../config/raygun';
import { ConfiguredSWRWrapper } from '../../config/swr';
import { envConfig } from '../../config/envConfig';
import '../../utils/local-storage-events';
import routes from './routes';

function initApp() {
  initTagManager();
  initRaygun();
  const splitFactory = SplitSdk({
    core: {
      authorizationKey: envConfig.SPLIT_CLIENT_API_KEY,
    },
  });

  const container = document.getElementById('app');
  const root = createRoot(container);
  root.render(
    <SplitFactory factory={splitFactory}>
      <ConfiguredIntlProvider>
        <ConfiguredChakraProvider>
          <ConfiguredSWRWrapper>
            <RouterProvider router={routes} />
          </ConfiguredSWRWrapper>
        </ConfiguredChakraProvider>
      </ConfiguredIntlProvider>
    </SplitFactory>
  );
}

initApp();
