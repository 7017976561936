import { useSearchParams } from 'react-router-dom';
import { Heading, VStack, Box, Button } from '@chakra-ui/react';
import { FormattedMessage } from 'react-intl';
import { useEffect, useState } from 'react';
import { EventType } from '@lib/events';
import { intercomLinkFormatter } from '../../utils/messageUtils';
import LayoutWebpage from '../LayoutWebpage';
import { trpc } from '../../config/trpc';
import {
  DataHandlerFeedback,
  hasDataHandlerFeedBack,
} from '../../utils/dataHandler';

const mapEventTypeToSubscription = (
  eventType: EventType
): {
  title: string;
  description: string;
} => {
  switch (eventType) {
    case 'ScorecardGenerated':
      return {
        title: 'scorecard generation',
        description: 'scorecard generation',
      };
    case 'PollingFailureRequiresUserAttention':
    default:
      return {
        title: 'polling issue alerts',
        description: 'polling issues',
      };
  }
};

export default function Unsubscribe() {
  const [searchParams] = useSearchParams();
  const unsubscribeTokenParam = searchParams.get('token');
  const unsubscribeModeParam = searchParams.get('mode');
  const areParamsValid =
    typeof unsubscribeTokenParam === 'string' &&
    (unsubscribeModeParam === 'site' || unsubscribeModeParam === 'all');
  const [unsubscribeResponse, setUnsubscribeResponse] = useState<{
    data: { siteName?: string; title: string; description: string } | undefined;
    error: Error | undefined;
    isValidating: boolean;
  }>({
    data: undefined,
    error: areParamsValid
      ? undefined
      : new Error('Invalid URL parameters for unsubscription'),
    isValidating: false,
  });
  useEffect(() => {
    if (areParamsValid) {
      setUnsubscribeResponse({
        error: undefined,
        data: undefined,
        isValidating: true,
      });
      trpc.pollingFailures.unsubscribeFromEvents
        .mutate({
          unsubscibeToken: unsubscribeTokenParam,
          unsubscribeType: unsubscribeModeParam,
        })
        .then((data) => {
          setUnsubscribeResponse({
            error: undefined,
            data: {
              ...data,
              ...mapEventTypeToSubscription(data.eventType),
            },
            isValidating: false,
          });
        })
        .catch((error) => {
          setUnsubscribeResponse({
            error,
            data: undefined,
            isValidating: false,
          });
        });
    }
    // Run once on mount
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  if (
    !unsubscribeResponse.data ||
    hasDataHandlerFeedBack(unsubscribeResponse)
  ) {
    return (
      <LayoutWebpage>
        <DataHandlerFeedback dataHandlersParam={unsubscribeResponse} />
      </LayoutWebpage>
    );
  }
  return (
    <LayoutWebpage>
      <VStack alignItems="flex-start" spacing={20}>
        <VStack alignItems="flex-start" spacing={4}>
          <Heading size="4xl">
            <FormattedMessage
              id="KvRKxD"
              defaultMessage="Unsubscribed from {title}"
              values={{
                title: unsubscribeResponse.data.title,
              }}
            />
          </Heading>
          <Box fontSize="2xl">
            <FormattedMessage
              id="ST/BLW"
              defaultMessage="We will no longer send you emails regarding {description} of {siteName, select, ALL_SITES {your sites within Mooven} other {{siteName}}}."
              values={{
                siteName: unsubscribeResponse.data.siteName ?? 'ALL_SITES',
                description: unsubscribeResponse.data.description,
              }}
            />
          </Box>
        </VStack>
        <VStack alignItems="flex-start" spacing={4}>
          <Box fontSize="sm">
            <FormattedMessage
              id="+JS5Af"
              defaultMessage="To resubscribe, get in touch with <supportLink>Mooven</supportLink>."
              values={{ supportLink: intercomLinkFormatter() }}
            />
          </Box>
          <Button
            as="a"
            href="/"
            variant="solid"
            colorScheme="greenDark"
            size="sm"
          >
            <FormattedMessage id="So1VvU" defaultMessage="Go to Mooven" />
          </Button>
        </VStack>
      </VStack>
    </LayoutWebpage>
  );
}
