import { getMapElementsBounds } from 'design-system/atoms/custom-google-map/utils';
import { wktToPointArray } from '@lib/gis';
import { ROUTES_COLORS } from '../constants/clientConstants';
import { errorReport } from './errors';

type LatLngLiteral = google.maps.LatLngLiteral;
type LatLngString = `${number},${number}`;

export interface SwNeBounds {
  sw: LatLngLiteral;
  ne: LatLngLiteral;
}

const LAT_LONG_REGEX =
  /^[-+]?[0-9]{1,2}(\.[0-9]+,|,)\s*[-+]?[0-9]{1,3}(\.[0-9]+$|$)/;
export function isValidLatLngString(value?: string): value is LatLngString {
  if (value === undefined || !value.match(LAT_LONG_REGEX)) {
    return false;
  }
  const [latString, lngString] = value.split(',');
  const lat = parseFloat(latString);
  const lng = parseFloat(lngString);
  return lat >= -90 && lat <= 90 && lng >= -180 && lng <= 180;
}

export function parseCoordinates(coordinates: string): LatLngLiteral {
  const split = coordinates.split(',');
  return {
    lat: Number(split[0]) || 0,
    lng: Number(split[1]) || 0,
  };
}

export function formatCoordinates(point: LatLngLiteral): string {
  return `${point.lat},${point.lng}`;
}

export function parseWkt(wkt: string): LatLngLiteral[] {
  try {
    return wktToPointArray(wkt);
  } catch (e) {
    errorReport.handled(e, { wkt });
    return [];
  }
}

export function getBoundsWithMinDiameter(
  latlngs: LatLngLiteral[],
  minDiameter = 0.005
) {
  return getMapElementsBounds(latlngs, minDiameter);
}

// Please prefer using "colorsSortedByKey" instead
export function sortedColorsForItems<
  ItemType extends Record<string, any>,
  KeyType extends keyof ItemType,
>(items: ItemType[], sortBy: KeyType) {
  const sortedItems = items
    .slice(0, ROUTES_COLORS.length)
    .sort((itemA, itemB) => {
      if (
        !itemA ||
        itemA[sortBy] === undefined ||
        !itemB ||
        itemB[sortBy] === undefined
      ) {
        return 0;
      }
      return itemA[sortBy].toString().localeCompare(itemB[sortBy].toString());
    });
  const remainingColors = ROUTES_COLORS.slice();
  const sortedColors: string[] = [];
  sortedItems.forEach((item) => {
    sortedColors[items.indexOf(item)] = remainingColors.shift() || '';
  });
  return [...sortedColors, ...remainingColors];
}

export function colorsSortedByKey<ItemType extends Record<any, any>>(
  items: ItemType[],
  sortBy: keyof ItemType
) {
  const sortedItems = [...items].sort((itemA, itemB) => {
    if (itemA[sortBy] === undefined || itemB[sortBy] === undefined) {
      return 0;
    }
    return itemA[sortBy].toString().localeCompare(itemB[sortBy].toString());
  });
  return sortedItems.reduce(
    (keyedColors, item, itemIndex) => ({
      ...keyedColors,
      [item[sortBy]]: ROUTES_COLORS[itemIndex % ROUTES_COLORS.length],
    }),
    {}
  );
}

export function getMidPoint(points: LatLngLiteral[]): LatLngLiteral {
  if (points.length === 0) {
    return {
      lat: 0,
      lng: 0,
    };
  }
  return points[Math.floor(points.length / 2)];
}

export function toggleArrayItem(item, array) {
  if (array.indexOf(item) > -1) {
    return array.filter((filterItem) => filterItem !== item);
  }
  return [...array, item];
}
