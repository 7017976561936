import { useIntl } from 'react-intl';
import useSWR from 'swr';
import {
  deleteSiteUser,
  inviteSiteUsers,
  postSiteUser,
  putSiteUser,
  resendInvite,
  resetPassword,
} from '../api/siteUser';
import { useFailureToast } from '../hooks/useFailureToast';
import { useSuccessToast } from '../hooks/useSuccessToast';
import { SiteUser } from '../types/site-user';
import { envConfig } from '../config/envConfig';
import { useOptimisticMutation } from '../utils/dataHandler';

export default function useSiteUsersData(
  insightId: number,
  siteId: number,
  suspense = true
) {
  const { formatMessage } = useIntl();
  // TODO: Move visual feedback out of data handler. Please don't replicate this pattern.
  const successToast = useSuccessToast();
  const failureToast = useFailureToast();
  const { data, mutate } = useSWR<SiteUser[]>(
    `${envConfig.API_URL}/api/v1/assignment/insight/${insightId}/site/${siteId}/user`,
    {
      suspense,
    }
  );
  const optimisticMutation = useOptimisticMutation(mutate);

  return {
    data,
    mutate,
    addSiteUser: async (newUserData) => {
      try {
        const isNewUser = !newUserData.userId;
        await (isNewUser
          ? postSiteUser(insightId, siteId, newUserData)
          : putSiteUser(insightId, siteId, newUserData.userId, newUserData));
        await mutate();
        successToast({
          title: formatMessage({
            defaultMessage: 'User added',
            id: '+cCDf2',
            description: 'User added',
          }),
        });
      } catch (error) {
        failureToast({
          title: formatMessage({
            defaultMessage: 'Unable to add user',
            id: 'y7YnPP',
            description: 'Unable to add user',
          }),
        });
        throw error;
      }
    },
    updateSiteUser: optimisticMutation({
      serverOp: async (newUserData: SiteUser) => {
        try {
          await putSiteUser(
            insightId,
            siteId,
            newUserData.userId!,
            newUserData
          );
          successToast({
            title: formatMessage({
              defaultMessage: 'User updated',
              id: 'E19tKK',
              description: 'User updated',
            }),
          });
        } catch (error) {
          failureToast({
            title: formatMessage({
              defaultMessage: 'Unable to update user',
              id: '2OCimu',
              description: 'Unable to update user',
            }),
          });
          throw error;
        }
      },
      localOp: (newUserData) =>
        (data || []).map((siteUser) => {
          if (siteUser.userId !== newUserData.userId) {
            return siteUser;
          }
          return {
            ...siteUser,
            ...newUserData,
          };
        }),
    }),
    removeSiteUser: async (userData) => {
      try {
        await deleteSiteUser(insightId, siteId, userData.userId, userData);
        await mutate();
        successToast({
          title: formatMessage({
            defaultMessage: 'User removed',
            id: 'S/IPs9',
            description: 'User removed',
          }),
        });
      } catch (error) {
        failureToast({
          title: formatMessage({
            defaultMessage: 'Failed to remove user',
            id: '9y5gid',
            description: 'Failed to remove user',
          }),
        });
        throw error;
      }
    },
    inviteSiteUsers: async (params: Parameters<typeof inviteSiteUsers>[2]) => {
      try {
        await inviteSiteUsers(insightId, siteId, params);
        await mutate();
        successToast({
          title: formatMessage(
            {
              defaultMessage:
                '{numUsers, plural, =0 {No invites sent} one {# user} other {# users}} invited',
              id: 'QW0E5r',
              description: 'Users invited',
            },
            {
              numUsers: params.emails.length,
            }
          ),
        });
      } catch (error) {
        failureToast({
          title: formatMessage({
            defaultMessage: 'Failed to invite users',
            id: 'See9q/',
            description: 'Failed to invite users',
          }),
        });
        throw error;
      }
    },
    resendInvite: async (params: Parameters<typeof resendInvite>[2]) => {
      try {
        await resendInvite(insightId, siteId, params);
        successToast({
          title: formatMessage({
            defaultMessage: 'Invite resent',
            id: 'IWK+9W',
            description: 'Invite resent',
          }),
        });
      } catch (error) {
        failureToast({
          title: formatMessage({
            defaultMessage: 'Failed to resend invite',
            id: 'Z6t6VF',
            description: 'Failed to resend invite',
          }),
        });
        throw error;
      }
    },
    resetPassword: async (email: string) => {
      try {
        await resetPassword(email);
        successToast({
          title: formatMessage({
            defaultMessage: 'Password reset email sent',
            id: '3Jlqgv',
            description: 'Password reset email sent',
          }),
        });
      } catch (error) {
        failureToast({
          title: formatMessage({
            defaultMessage: 'Unable to send password reset email',
            id: 'k10lv+',
            description: 'Unable to send password reset email',
          }),
        });
        throw error;
      }
    },
  };
}
