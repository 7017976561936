import CustomGoogleMap, {
  CustomGoogleMapProps,
} from 'design-system/atoms/custom-google-map';
import { FunctionComponent, ReactNode } from 'react';
import { Box, Flex } from '@chakra-ui/react';
import LayoutMultiPanel, {
  LayoutMultiPanelProps,
  LayoutMultiPanelSettings,
} from '../LayoutMultiPanel';
import WrapComponent from '../WrapComponent';

interface LayoutSmartVmsSettings extends LayoutMultiPanelSettings {
  mapProps?: CustomGoogleMapProps;
}
interface LayoutSmartVmsProps extends LayoutMultiPanelProps {
  contentMap?: ReactNode;
  contentFooter?: ReactNode;
  layoutSettings?: LayoutSmartVmsSettings;
}

export function SmartVmsPage({
  Component,
}: {
  Component: FunctionComponent<{ Wrapper: typeof LayoutSmartVms }>;
}) {
  return <WrapComponent Wrapper={LayoutSmartVms} Component={Component} />;
}
export default function LayoutSmartVms({
  contentSidebar,
  contentMain,
  contentMap,
  contentFooter,
  layoutSettings,
}: LayoutSmartVmsProps) {
  return (
    <LayoutMultiPanel
      contentSidebar={
        <Flex direction="column" height="100%">
          <Box display="flex" flexDirection="column" flex="1">
            {contentSidebar}
          </Box>
          {contentFooter && (
            <Box
              position="sticky"
              bottom="0"
              margin="-8px -16px -16px"
              padding="8px 16px 16px"
              backgroundColor="rgba(255, 255, 255, 0.9)"
              backdropFilter="blur(4px)"
            >
              {contentFooter}
            </Box>
          )}
        </Flex>
      }
      contentMain={
        <Box height="100%" position="relative">
          <CustomGoogleMap
            {...layoutSettings?.mapProps}
            data-ignore-visual-test
          >
            {contentMap}
          </CustomGoogleMap>
          {contentMain && (
            <Flex
              backgroundColor="yellow.100"
              position="absolute"
              top="0"
              right="0"
              bottom="0"
              left="0"
              overflowY="auto"
            >
              {contentMain}
            </Flex>
          )}
        </Box>
      }
      layoutSettings={layoutSettings}
    />
  );
}
