import './WeekDayPicker.scss';

import classNames from 'classnames';
import useIntlWeekDays from '../../hooks/useIntlWeekDays';

export interface WeekDayPickerProps {
  value: number[];
  onChange: (newValue: number[]) => void;
}

export function WeekDayPicker({ value = [], onChange }: WeekDayPickerProps) {
  const weekdays = useIntlWeekDays();
  return (
    <div className="v2-week-day-picker">
      {weekdays.map((weekday) => (
        <button
          type="button"
          key={weekday.value}
          className={classNames('v2-week-day-picker__btn', {
            'v2-week-day-picker__btn--selected': value.includes(weekday.value),
          })}
          onClick={() => {
            const foundIndex = value.indexOf(weekday.value);
            const updatedDays =
              foundIndex > -1
                ? value.filter(
                    (_, filterDayIndex) => filterDayIndex !== foundIndex
                  )
                : value.concat(weekday.value);
            if (updatedDays.length > 0) {
              onChange(updatedDays);
            }
          }}
        >
          {weekday.label}
        </button>
      ))}
    </div>
  );
}
