import { CheckCircleIcon, WarningIcon } from '@chakra-ui/icons';
import { Icon, IconProps } from '@chakra-ui/react';

export interface AnomalyIconProps extends Pick<IconProps, 'color'> {
  anomalyScore: number | null | undefined;
}

export default function AnomalyIcon(props: AnomalyIconProps): JSX.Element {
  const { anomalyScore, ...otherProps } = props;
  if (anomalyScore === undefined || anomalyScore === null) {
    return (
      <Icon color="gray.400" viewBox="0 0 100 100">
        <circle fill="currentColor" cx="50" cy="50" r="50" />
      </Icon>
    );
  }
  if (anomalyScore >= 1.05) {
    return <WarningIcon {...otherProps} />;
  }
  return <CheckCircleIcon {...otherProps} />;
}
