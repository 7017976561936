import {
  Button,
  Heading,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Select,
  Text,
  VStack,
} from '@chakra-ui/react';
import type { Role } from '@webapp/bff';
import { useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import AutoCompleteInput from 'design-system/molecules/auto-complete-input';
import { useFailureToast } from '../../hooks/useFailureToast';
import { useSuccessToast } from '../../hooks/useSuccessToast';
import { emailValidator } from '../../utils/email';

export function InviteUsersModal({
  orgId,
  onClose,
  inviteTeamMembers,
  isOpen,
}: {
  orgId: string;
  onClose: () => void;
  inviteTeamMembers: (data: {
    recipients: string[];
    role: Role;
    orgId: string;
  }) => Promise<void>;
  isOpen: boolean;
}) {
  const { formatMessage } = useIntl();
  const failureToast = useFailureToast();
  const successToast = useSuccessToast();
  const [inProgress, setInProgress] = useState(false);
  const [invitationForm, setInvitationForm] = useState({
    recipients: [] as string[],
    role: 'Viewer' as Role,
    orgId,
  });
  const [emailsString, setEmailsString] = useState('');

  const tokenizer = (str: string) => str.trim().split(/[\s,]+/);

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay background="whiteAlpha.800" />
      <ModalContent>
        <ModalHeader>
          <Heading size="md">Invite team members</Heading>
        </ModalHeader>
        <ModalCloseButton backgroundColor="gray.100" />
        <ModalBody>
          <VStack align="full">
            <Heading fontSize="sm" fontWeight={600}>
              <FormattedMessage
                defaultMessage="Invite by email"
                id="4cdAUm"
                description="Invite by email heading"
              />
            </Heading>
            <Text>
              <FormattedMessage
                defaultMessage="You can add multiple by separating by comma, tab or enter keys."
                id="jB4QDF"
                description="Invite by email text"
              />
            </Text>
            <AutoCompleteInput
              tokenizer={tokenizer}
              variant="ghost"
              errorMessage={formatMessage({
                defaultMessage: 'Please enter a valid email address',
                id: 'akmyCq',
                description: 'Validate email',
              })}
              onValueChange={setEmailsString}
              onTagsChange={(tags) =>
                setInvitationForm({ ...invitationForm, recipients: tags })
              }
              validator={emailValidator}
              autoCompleteItems={[]}
              placeholder={formatMessage({
                defaultMessage: 'Enter email(s)',
                id: 'AP32WE',
                description: 'Invite user emails input placeholder',
              })}
              inputRightElement={
                <Select
                  border=""
                  defaultValue="Viewer"
                  onChange={(e) => {
                    setInvitationForm({
                      ...invitationForm,
                      role: e.target.value as Role,
                    });
                  }}
                >
                  <option
                    value="Viewer"
                    aria-label="Team access settings can view"
                  >
                    <FormattedMessage
                      defaultMessage="Can view"
                      id="VQfiE/"
                      description="Team access settings can view"
                    />
                  </option>
                  <option
                    value="Editor"
                    aria-label="Team access settings can edit"
                  >
                    <FormattedMessage
                      defaultMessage="Can edit"
                      id="oxD9G2"
                      description="Team access settings can edit"
                    />
                  </option>
                </Select>
              }
            />
          </VStack>
          <ModalFooter justifyContent="start" mx={0} px={0}>
            <Button
              isLoading={inProgress}
              disabled={inProgress}
              onClick={async () => {
                try {
                  setInProgress(true);
                  if (emailsString.trim()) {
                    const emails = tokenizer(emailsString);
                    if (emailValidator(emails)) {
                      invitationForm.recipients =
                        invitationForm.recipients.concat(emails);
                    }
                  }
                  await inviteTeamMembers(invitationForm);
                  successToast({
                    title: formatMessage({
                      defaultMessage: 'Users invited successfully',
                      id: '3CmOIM',
                      description: 'Invite to team modal: success',
                    }),
                  });
                  onClose();
                } catch (e) {
                  failureToast({
                    title: formatMessage({
                      defaultMessage:
                        'Something went wrong. Please try again or contact us.',
                      id: 'a3cAjD',
                      description: 'Invite to team modal: Something went wrong',
                    }),
                  });
                } finally {
                  setInProgress(false);
                }
              }}
              colorScheme="greenDark"
            >
              Send invite
            </Button>
          </ModalFooter>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
}
