import { ServerError } from '../types/error';
import { Maybe } from '../types/utils';
import { errorReport } from './errors';

const SUPPORT_CSV_TYPE = ['application/csv', 'text/csv; charset=utf-8'];
export async function unwrapResponse<T>(response: Maybe<Response>): Promise<T> {
  // This is used to handle csv blob response
  if (response && response.headers) {
    const contentType = response.headers.get('content-type') ?? '';
    if (SUPPORT_CSV_TYPE.includes(contentType)) {
      return response as any;
    }
  }

  // This is used to unwrap non-standard responses (i.e. numbers)
  if (!response || !response.json) {
    return response as any;
  }

  let unwrapped: any;
  try {
    unwrapped = await response.json();
  } catch (e) {
    if (!response.ok) {
      // Empty non-OK response
      const error = new Error(
        response.status?.toString() || 'Empty status response'
      );
      if (response.status >= 500) {
        errorReport.log(error, {
          requestUrl: response.url,
          status: response.status,
          statusText: response.statusText,
          response,
        });
      }
      throw error;
    } else {
      // If response is OK, these are successful, non-JSON responses
      return null as unknown as T;
    }
  }

  if (!response.ok) {
    if (unwrapped.code && unwrapped.message) {
      // Does it look like a ServerError?
      const err = unwrapped as unknown as ServerError;
      const error = new Error(err.message);
      if (err.code >= 500) {
        errorReport.log(error, {
          statusCode: err.code,
          requestUrl: response.url,
        });
      }
      throw error;
    } else if (unwrapped.status) {
      // Legacy error messages
      // e.g. unwrapped: {
      //   timestamp: 1661362612494,
      //   status: 401,
      //   error: "Unauthorized",
      //   path: "/api/v1/user"
      // }
      const error = new Error(
        unwrapped.error || response.statusText || 'Unknown error type'
      );
      if (unwrapped.status >= 500) {
        errorReport.log(error, {
          responseBody: unwrapped,
          requestUrl: response.url,
        });
      }
      throw error;
    } else {
      // Unknown non-OK responses
      const error = new Error(response.statusText || 'Unknown error type');
      errorReport.log(error, {
        responseBody: unwrapped,
        requestUrl: response.url,
      });
      throw error;
    }
  }

  return unwrapped as T;
}

export async function standardFetch<T = null>(
  fetchUrl: string,
  fetchParams?: RequestInit
): Promise<T> {
  const response = await fetch(fetchUrl, {
    ...fetchParams,
    credentials: 'include',
  });
  const unwrapped = await unwrapResponse<T>(response);
  return unwrapped;
}

export async function fetchWithError<T>(
  fetchUrl: string,
  fetchParams?: RequestInit
): Promise<T> {
  const headers = new Headers(fetchParams?.headers);
  headers.append('content-type', 'application/json');
  const response = await fetch(fetchUrl, {
    ...fetchParams,
    headers,
    credentials: 'include',
  });

  let unwrapped: T;

  try {
    unwrapped = await response.json();
  } catch (e) {
    if (response.ok) {
      return null as unknown as T;
    }
    throw e;
  }

  if (!response.ok) {
    const err = unwrapped as unknown as ServerError;
    const error = new Error(err.message);
    if (err.code >= 500) {
      errorReport.log(error, {
        statusCode: err.code,
        requestUrl: response.url,
      });
    }
    throw error;
  }

  return unwrapped;
}
