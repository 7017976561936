import { useParams, useNavigate, useLocation } from 'react-router-dom';
import { Flex, Spinner, useSteps, useToken } from '@chakra-ui/react';
import { Fragment, ReactNode, useMemo, useState } from 'react';
import { useIntl } from 'react-intl';
import { ActiveVms } from '@webapp/bff/src/types/vms';
import { pathSiteVmsBoard, pathSiteVmsBoards } from '../../constants/path';
import LayoutSmartVms from './LayoutSmartVms';
import useRoutesData from '../../data/useRoutesData';
import AnimatedRoute from '../AnimatedRoute';
import { colorsSortedByKey, parseWkt } from '../../utils/routes';
import { useAnalytics } from '../../hooks/analytics/useAnalytics';
import useSiteVmsData from '../../data/useSiteVmsData';
import CreateWizardLayout from '../CreateWizardLayout/CreateWizardLayout';
import StepBoardDetails from './StepBoardDetails';
import StepBoardConnect from './StepBoardConnect';
import MapLayerVmsBoardsWithPreview from './VmsMapLayerWithPreview';
import StepBoardMessageType from './StepBoardMessageType';
import StepBoardScheduleConnection from './StepBoardSchedule';
import useSiteData from '../../data/useSiteData';
import { useUserRoles } from '../../hooks/useUserRoles';
import { CreateVmsSteps, equalFieldValues } from './utils';
import StepBoardOverrideFallback from './StepBoardOverrideFallback';

export default function SmartVmsCreateBoard({
  Wrapper,
}: {
  Wrapper: typeof LayoutSmartVms;
}) {
  const { insightId, siteId, vmsConfigId } = useParams();
  const { isAdmin } = useUserRoles();
  const { search } = useLocation();

  const defaultStepNameParam =
    new URLSearchParams(search).get('step')?.toUpperCase() ?? 'DETAILS';
  const stepsManager = useSteps({
    index: CreateVmsSteps[defaultStepNameParam],
  });
  const { track } = useAnalytics();
  if (!insightId || !siteId) {
    throw new Error('insightId and siteId are required');
  }
  const routesDataHandler = useRoutesData(Number(siteId));
  const vmsDataHandler = useSiteVmsData(siteId);
  const siteDataHandler = useSiteData({ orgId: insightId, siteId });
  const navigate = useNavigate();
  const { formatMessage } = useIntl();
  const currentVmsData = vmsDataHandler.data?.find(
    (vms) => vms.vmsConfigId === vmsConfigId
  );
  const goToVmsPage = () => {
    navigate({
      pathname: pathSiteVmsBoards(insightId, siteId),
    });
  };

  const [mainPanelContent, setMainPanelContent] = useState<ReactNode>();
  const [selectedRoutes, setSelectedRoutes] = useState<string[] | undefined>();

  const updateVmsData = async (
    updatedVmsFields: Partial<ActiveVms>,
    referrer: string
  ) => {
    if (!currentVmsData) {
      throw new Error('VMS data is missing');
    }
    if (equalFieldValues(updatedVmsFields, currentVmsData)) {
      return undefined;
    }
    return vmsDataHandler
      .updateVmsBoard({
        vmsConfigId: currentVmsData.vmsConfigId,
        ...updatedVmsFields,
      })
      .then(() => {
        track('VMS Edited', {
          referrer,
          vms: {
            vmsConfigId: currentVmsData.vmsConfigId,
            updatedVmsFields,
          },
        });
      });
  };

  const updateVmsDates = async (
    vmsDates: Pick<ActiveVms, 'startUpdatingAt' | 'stopUpdatingAt'>
  ) => {
    if (!currentVmsData) {
      throw new Error('VMS data is missing');
    }
    if (equalFieldValues(vmsDates, currentVmsData)) {
      return undefined;
    }
    return vmsDataHandler
      .updateVmsSchedule({
        vmsConfigId: Number(vmsConfigId),
        ...vmsDates,
      })
      .then(() => {
        track('VMS Edited', {
          referrer: 'VMS schedule step',
          vms: {
            vmsConfigId: currentVmsData.vmsConfigId,
            vmsDates,
          },
        });
      });
  };

  const vmsDetailsSaveHandler = (formFields: Pick<ActiveVms, 'vmsName'>) =>
    currentVmsData
      ? updateVmsData(formFields, 'VMS details step')
      : vmsDataHandler.createVmsBoard(formFields.vmsName).then((response) => {
          navigate(
            pathSiteVmsBoard(insightId, siteId, String(response.vmsConfigId)),
            {
              replace: true,
            }
          );
          track('VMS Created', {
            referrer: 'VMS details step',
            vms: {
              vmsConfigId: response.vmsConfigId,
              vmsName: formFields.vmsName,
            },
          });
        });

  const routeColorsByTrackId = useMemo(
    () => colorsSortedByKey(routesDataHandler.data ?? [], 'trackId'),
    [routesDataHandler.data]
  );
  const nonSelectedRouteColor = useToken('colors', 'gray.400');
  const vmsColors = useMemo(
    () => colorsSortedByKey(vmsDataHandler.data ?? [], 'vmsConfigId'),
    [vmsDataHandler.data]
  );
  const vmsWithColors = useMemo(
    () =>
      vmsDataHandler.data?.map((vm) => ({
        ...vm,
        color: vmsColors[vm.vmsConfigId],
      })),
    [vmsDataHandler.data, vmsColors]
  );

  const currentStep = stepsManager.activeStep;
  const wideSteps: number[] = [
    CreateVmsSteps.MESSAGE_TYPE,
    CreateVmsSteps.OVERRIDE_FALLBACK,
    CreateVmsSteps.SCHEDULE,
  ];

  const isVmsDataLoading = vmsConfigId !== undefined && !currentVmsData;

  const steps = [
    {
      name: formatMessage({
        defaultMessage: 'Board details',
        id: 'J0YXmZ',
      }),
      title: formatMessage({
        defaultMessage: 'Board details',
        id: 'J0YXmZ',
      }),
      onCancel: () => {
        navigate(pathSiteVmsBoards(insightId, siteId));
      },
      renderContent: (stepFormRenderProps) =>
        isVmsDataLoading ? (
          <StepSpinner />
        ) : (
          <StepBoardDetails
            key={currentVmsData?.vmsConfigId}
            vms={currentVmsData}
            saveVms={vmsDetailsSaveHandler}
            stepFormRenderProps={stepFormRenderProps}
          />
        ),
    },
    {
      name: formatMessage({
        defaultMessage: 'Connect to board',
        id: 'Ny0Jp6',
      }),
      title: formatMessage({
        defaultMessage: 'Connect to board',
        id: 'Ny0Jp6',
      }),
      onCancel: () => {
        navigate(pathSiteVmsBoards(insightId, siteId));
      },
      renderContent: (stepFormRenderProps) =>
        !currentVmsData ? (
          <StepSpinner />
        ) : (
          <StepBoardConnect
            key={currentVmsData?.vmsConfigId}
            vms={currentVmsData}
            updateVms={(vmsData) => updateVmsData(vmsData, 'VMS connect step')}
            stepFormRenderProps={stepFormRenderProps}
            setMainPanelContent={setMainPanelContent}
            testConnection={vmsDataHandler.testVmsBoardConnection}
            getVmsList={vmsDataHandler.getVmsList}
          />
        ),
    },
    {
      name: formatMessage({
        defaultMessage: 'Message type',
        id: 'gXFgc7',
      }),
      title: formatMessage({
        defaultMessage: 'Messaging type',
        id: 'VWtbCK',
      }),
      onCancel: () => {
        navigate(pathSiteVmsBoards(insightId, siteId));
      },
      renderContent: (stepFormRenderProps) =>
        !currentVmsData || !routesDataHandler.data ? (
          <StepSpinner />
        ) : (
          <StepBoardMessageType
            key={JSON.stringify({
              vmsConfigId: currentVmsData?.vmsConfigId,
              siteId,
            })}
            siteId={`${siteId}`}
            vms={currentVmsData}
            updateVms={(vmsData) => updateVmsData(vmsData, 'Message Type Step')}
            stepFormRenderProps={stepFormRenderProps}
            routes={routesDataHandler.data}
            isAdmin={isAdmin}
            setSelectedRoutes={setSelectedRoutes}
          />
        ),
    },
    {
      name: formatMessage({
        defaultMessage: 'Override & fallback',
        id: 'sHUapc',
      }),
      title: formatMessage({
        defaultMessage: 'Override & fallback',
        id: 'sHUapc',
      }),
      onCancel: () => {
        navigate(pathSiteVmsBoards(insightId, siteId));
      },
      renderContent: (stepFormRenderProps) =>
        !currentVmsData || !siteDataHandler.data ? (
          <StepSpinner />
        ) : (
          <StepBoardOverrideFallback
            key={JSON.stringify({
              vmsConfigId: currentVmsData?.vmsConfigId,
              siteId,
            })}
            vms={currentVmsData}
            updateVms={(vmsData) =>
              updateVmsData(vmsData, 'Override & fallback step')
            }
            stepFormRenderProps={stepFormRenderProps}
          />
        ),
    },
    {
      name: formatMessage({
        defaultMessage: 'Schedule connection',
        id: '2/LKpR',
      }),
      title: formatMessage({
        defaultMessage: 'Schedule your connection',
        id: '9EEdjB',
      }),
      onCancel: () => {
        navigate(pathSiteVmsBoards(insightId, siteId));
      },
      renderContent: (stepFormRenderProps) =>
        !currentVmsData || !siteDataHandler.data ? (
          <StepSpinner />
        ) : (
          <StepBoardScheduleConnection
            key={JSON.stringify({
              vmsConfigId: currentVmsData?.vmsConfigId,
              siteId,
            })}
            vms={currentVmsData}
            updateVmsDates={updateVmsDates}
            stepFormRenderProps={stepFormRenderProps}
            siteTimeZone={siteDataHandler.data.siteTimeZone}
          />
        ),
    },
  ];

  return (
    <Wrapper
      contentSidebar={
        <CreateWizardLayout
          hideStepper={
            !!(
              currentVmsData?.startUpdatingAt ?? currentVmsData?.stopUpdatingAt
            )
          }
          stepsManager={stepsManager}
          onExit={() => goToVmsPage()}
          isDisabled={isVmsDataLoading}
          steps={steps}
        />
      }
      layoutSettings={{
        sidebarMode: wideSteps.includes(currentStep) ? 'wide' : 'narrow',
        mapProps: {
          setBoundsToElements: routesDataHandler.data,
        },
      }}
      contentMain={mainPanelContent}
      contentMap={
        <>
          {routesDataHandler?.data?.map((route) => {
            const routeColor =
              currentStep === CreateVmsSteps.MESSAGE_TYPE &&
              !selectedRoutes?.includes(route.routeId.toString())
                ? nonSelectedRouteColor
                : routeColorsByTrackId[route.trackId];
            return (
              <Fragment key={route.trackId}>
                <AnimatedRoute
                  zIndex={2}
                  shouldAnimate
                  animationSpeed={0.5}
                  routeColor={routeColor}
                  routePoints={parseWkt(route.wkt)}
                />
              </Fragment>
            );
          })}
          {vmsWithColors && (
            <MapLayerVmsBoardsWithPreview
              vmsBoards={vmsWithColors}
              orgId={insightId}
              siteId={siteId}
              deleteVmsBoard={vmsDataHandler.deleteVmsBoard}
            />
          )}
        </>
      }
    />
  );
}

function StepSpinner() {
  return (
    <Flex
      width="100%"
      height="100%"
      maxHeight="160px"
      alignItems="center"
      justifyContent="center"
    >
      <Spinner variant="mooven" size="xl" />
    </Flex>
  );
}
