import useSWR from 'swr';
import { ProcedureOptions } from '@trpc/server/src/core/procedure';
import { trpc } from '../config/trpc';
import { pathRoutePollStatus } from '../constants/path';

export type RoutePollingStatusFailureType =
  | 'UNKNOWN_CAUSE'
  | 'KNOWN_CAUSE'
  | 'CLOSURE'
  | 'ROAD_LAYOUT'
  | 'ROAD_LAYOUT_UNAVAILABLE'
  | 'ROAD_LAYOUT_UPDATE';
export function IsRoutePollingStatusFailureType(
  value: any
): value is RoutePollingStatusFailureType {
  return [
    'UNKNOWN_CAUSE',
    'KNOWN_CAUSE',
    'CLOSURE',
    'ROAD_LAYOUT',
    'ROAD_LAYOUT_UNAVAILABLE',
    'ROAD_LAYOUT_UPDATE',
  ].includes(value);
}
export interface RoutePollingStatusData {
  orgId: string;
  site: {
    siteId: string;
    siteName: string;
    siteTimeZone: string;
  };
  route: {
    routeId: string;
    name: string;
    wkt: string;
    active: boolean;
    monitorStarted: boolean;
  };
  failure?: {
    failStartDate: string; // Date;
    userResponse?: {
      userName: string;
      responseDate: string; // Date;
      responseType: RoutePollingStatusFailureType;
      responseComment?: string;
    };
  };
}
export interface RoutePollingStatusDataFailure extends RoutePollingStatusData {
  failure: NonNullable<RoutePollingStatusData['failure']>;
}
export function IsRoutePollingStatusDataFailure(
  status?: RoutePollingStatusData
): status is RoutePollingStatusData & {
  failure: NonNullable<RoutePollingStatusData['failure']>;
} {
  return Boolean(status?.failure);
}
export interface RoutePollingStatusDataResponded
  extends RoutePollingStatusDataFailure {
  failure: RoutePollingStatusDataFailure['failure'] & {
    userResponse: NonNullable<
      RoutePollingStatusDataFailure['failure']['userResponse']
    >;
  };
}
export function IsRoutePollingStatusDataResponded(
  status?: RoutePollingStatusData
): status is RoutePollingStatusDataResponded {
  return Boolean(status?.failure?.userResponse);
}

export default function useRoutePollingStatusData(pollInput: {
  routeId?: string;
  path: string;
  token: string | null;
}) {
  const procedureOptions: ProcedureOptions = {
    context: {
      token: pollInput.token,
    },
  };
  const { data, error, isValidating, mutate } = useSWR(
    pollInput.routeId
      ? { keyName: 'useRoutePollingStatusData', routeId: pollInput.routeId }
      : null,
    (params): Promise<RoutePollingStatusData> =>
      pollInput.path === pathRoutePollStatus(pollInput.routeId)
        ? trpc.pollingFailures.getActiveFailureWithToken.query(
            { routeId: params.routeId },
            procedureOptions
          )
        : trpc.pollingFailures.getActiveFailure.query(
            { routeId: params.routeId },
            procedureOptions
          )
  );

  return {
    data,
    error,
    isValidating,
    updateResponse: (input: {
      routeId: string;
      responseType: RoutePollingStatusFailureType;
      responseComment?: string;
      token: string | null;
    }) =>
      pollInput.path === pathRoutePollStatus(pollInput.routeId)
        ? trpc.pollingFailures.updateFailUserResponseWithToken
            .mutate(input, procedureOptions)
            .finally(() => {
              mutate(undefined);
            })
        : trpc.pollingFailures.updateFailUserResponse
            .mutate(input, procedureOptions)
            .finally(() => {
              mutate(undefined);
            }),
  };
}
