import { Box, Button, Text } from '@chakra-ui/react';
import moment from 'moment-timezone';
import { FormattedMessage, IntlFormatters, useIntl } from 'react-intl';
import { useEffect, useRef, useState } from 'react';
import { Site } from '../../types/site';
import { useActionWithFeedback } from '../../hooks/useActionWithFeedback';
import { SiteInsightData } from '../../data/useInsightChartData';
import useIntlWeekDays from '../../hooks/useIntlWeekDays';
import { processBlob } from '../../utils/processCSV';

interface SiteExportProps {
  site: Site;
  isLoading: boolean;
  allChartsData: (SiteInsightData | undefined)[];
}
export default function SiteExport({
  site,
  isLoading,
  allChartsData,
}: SiteExportProps) {
  const { formatTime } = useIntl();
  const weekdays = useIntlWeekDays('long');
  const [exportRequested, setExportRequested] = useState(false);
  const exportRequestRef = useRef<{
    resolve: () => void;
    reject: (reason: any) => void;
  }>();
  const handleExport = () =>
    new Promise<void>((resolve, reject) => {
      exportRequestRef.current = { resolve, reject };
      setExportRequested(true);
    });
  useEffect(() => {
    if (exportRequestRef.current && exportRequested && !isLoading) {
      try {
        syncExportSiteDataCSV({
          site,
          allChartsData,
          formatTime,
          weekdays,
        });
        exportRequestRef.current.resolve();
      } catch (error) {
        exportRequestRef.current.reject(error);
      }
      exportRequestRef.current = undefined;
      setExportRequested(false);
    }
  }, [
    exportRequested,
    setExportRequested,
    isLoading,
    site,
    allChartsData,
    formatTime,
    weekdays,
  ]);

  const { performAction: exportClickHandler, isPerformingAction: isExporting } =
    useActionWithFeedback({
      action: handleExport,
      trackEvent: {
        name: 'Site Exported CSV',
        data: {
          referrer: 'Site Data CSV',
          site,
        },
      },
    });

  return (
    <Box
      w={{ md: '250px', base: 'full' }}
      flex="0 0 auto"
      h="full"
      px="24px"
      py="20px"
      backgroundColor="gray.100"
      borderRadius="8px"
      ml={{ md: '16px', base: 0 }}
      mb="0"
    >
      <Text fontSize="md" fontWeight="700" mb="0">
        <FormattedMessage
          defaultMessage="Export all as CSV"
          id="eRIe7a"
          description="Export all as CSV"
        />
      </Text>
      <Text fontSize="xs" fontWeight="400" mb="12px">
        <FormattedMessage
          defaultMessage="Export all available data from the selected routes and time frame"
          id="uEeJzM"
          description="Export all available site data as CSV"
        />
      </Text>
      <Button
        mr={2}
        size="xs"
        variant="solid"
        colorScheme="brand"
        onClick={exportClickHandler}
        isLoading={isExporting}
      >
        <FormattedMessage defaultMessage="Export" id="SVwJTM" />
      </Button>
    </Box>
  );
}

/**
 * Exports site data to a CSV file.
 * It creates a unique key for each row of data using date, day, time, and route name.
 * A Map is used to store these rows, ensuring uniqueness and efficient updates.
 * After processing all data, it converts the Map to an array, creates a CSV string, and then a Blob.
 * The Blob is processed to create the CSV file. Success or failure toasts are shown accordingly.
 */
function syncExportSiteDataCSV({
  site,
  allChartsData,
  formatTime,
  weekdays,
}: {
  site: Site;
  allChartsData: (SiteInsightData | undefined)[];
  formatTime: IntlFormatters['formatTime'];
  weekdays: { label: string }[];
}) {
  const csvRowsMap = new Map<string, { [key: string]: string | number }>();
  allChartsData.forEach((data) => {
    data?.routes.forEach((route) => {
      route.items.forEach((item) => {
        const date = new Date(item.date);
        const formattedDate = moment(date)
          .tz(site?.siteTimeZone ?? 'UTC')
          .format('D-MMM-yy');

        const dayOfWeek = weekdays[date.getDay()].label;

        const formattedTime = formatTime(date, {
          hourCycle: 'h23',
          timeZone: site?.siteTimeZone,
        });

        const key = `${formattedDate}-${dayOfWeek}-${formattedTime}-${route.name}`;

        const csvRow = csvRowsMap.get(key) ?? {
          Date: `"${formattedDate}"`,
          Day: dayOfWeek,
          Time: `"${formattedTime}"`,
          Route_Name: route.name,
          Site_Impact_Delay: '',
          Delay: '',
          Journey: '',
          Queue: '',
          Speed: '',
          Vehicle_Count: '',
        };
        csvRowsMap.set(key, csvRow);

        switch (data.insightType) {
          case 'JOURNEY_TIME':
            csvRow.Journey = `${item.value ?? ''}`;
            break;
          case 'TIME_DELAY':
            csvRow.Delay = `${item.value ?? ''}`;
            break;
          case 'SPEED':
            csvRow.Speed = `${item.value ?? ''}`;
            break;
          case 'QUEUE_LENGTH':
            csvRow.Queue = `${item.value ?? ''}`;
            break;
          case 'SITE_IMPACT_DELAY':
            csvRow.Site_Impact_Delay = `${item.value ?? ''}`;
            break;
          default:
            break;
        }
      });
    });
  });
  const csvRows = Array.from(csvRowsMap.values());

  const csvContent = `Date,Day,Time,Route_Name,Site_Impact_Delay,Delay,Journey,Queue,Speed,Vehicle_Count\n${csvRows
    .map((row) => Object.values(row).join(','))
    .join('\n')}`;

  const blob = new Blob([csvContent], { type: 'text/csv' });
  processBlob(blob, `Mooven_${site.siteName}-${new Date().toISOString()}.csv`);
}
