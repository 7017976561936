import { InfoWindow } from '@react-google-maps/api';
import CustomGoogleMap from 'design-system/atoms/custom-google-map';
import { Dispatch, SetStateAction, useMemo, useState } from 'react';
import {
  useToken,
  Text,
  VStack,
  Button,
  Box,
  Select,
  ButtonGroup,
  Flex,
  Popover,
  PopoverArrow,
  PopoverTrigger,
  useDisclosure,
  IconButton,
  PopoverContent,
  HStack,
  Tag,
  TagLabel,
  TagCloseButton,
  PopoverBody,
} from '@chakra-ui/react';
import { FormattedMessage, useIntl } from 'react-intl';
import { ChevronDownIcon, ChevronUpIcon } from '@chakra-ui/icons';
import Checkbox from 'design-system/molecules/checkbox';
import { Route } from '../../types/route';
import { colorsSortedByKey, parseWkt } from '../../utils/routes';
import AnimatedRoute, { routesNearby } from '../AnimatedRoute';
import LatLng = google.maps.LatLng;
import { useAnalytics } from '../../hooks/analytics/useAnalytics';

type RouteSelectProps = Pick<Route, 'routeId' | 'name'>;
export interface RouteSelectMapProps {
  initialValue?: RouteSelectProps[];
  routes: Route[];
  onSave: (routes: RouteSelectProps[]) => void;
  onCancel: () => void;
  allowMultipleRoutes?: boolean;
}
export default function RouteSelectMap({
  routes,
  onSave,
  onCancel,
  initialValue,
  allowMultipleRoutes,
}: RouteSelectMapProps) {
  const { formatMessage } = useIntl();
  const [mapInstance, setMapInstance] = useState<google.maps.Map>();
  const [mapZoom, setMapZoom] = useState(mapInstance?.getZoom());
  const nonSelectedRouteColor = useToken('colors', 'gray.400');
  const [selectedItems, setSelectedItems] = useState<string[]>(
    initialValue?.map((route) => route.routeId.toString()) ?? []
  );
  const { track } = useAnalytics();
  const checkIsRouteSelected = (route: Route) =>
    selectedItems.some((item) => item === route.routeId.toString());

  const [showRoutesNearby, setShowRoutesNearby] = useState<{
    routes: Route[];
    position: LatLng;
  } | null>(null);
  const routeColorsByTrackId = useMemo(
    () => colorsSortedByKey(routes ?? [], 'trackId'),
    [routes]
  );
  return (
    <Flex direction="column" gap={6} h="100%">
      <Box
        height="90%"
        position="relative"
        borderRadius="16px"
        overflow="hidden"
      >
        <CustomGoogleMap
          onClick={() => {
            setShowRoutesNearby(null);
          }}
          onLoad={(map) => {
            setMapInstance(map);
            setMapZoom(map.getZoom());
          }}
          onZoomChanged={() => {
            if (mapInstance) {
              setMapZoom(mapInstance.getZoom());
            }
          }}
          setBoundsToElements={routes}
        >
          {routes.map((route) => {
            const isRouteSelected = checkIsRouteSelected(route);
            return (
              <AnimatedRoute
                key={route.trackId}
                zIndex={isRouteSelected ? 2 : 1}
                shouldAnimate
                animationSpeed={0.5}
                routeColor={
                  isRouteSelected
                    ? routeColorsByTrackId[route.trackId]
                    : nonSelectedRouteColor
                }
                routePoints={parseWkt(route.wkt)}
                onRouteClick={(e) => {
                  if (!e.latLng) {
                    return;
                  }
                  const routesNearbyCurrentRoute = routesNearby<Route>({
                    selectedRoute: route,
                    routes,
                    point: e.latLng,
                    mapZoom,
                  });
                  routesNearbyCurrentRoute.sort((routeA, routeB) =>
                    routeA.name > routeB.name ? 1 : -1
                  );
                  setShowRoutesNearby({
                    position: e.latLng,
                    routes: routesNearbyCurrentRoute,
                  });
                }}
              />
            );
          })}
          {showRoutesNearby && (
            <InfoWindow
              position={showRoutesNearby.position}
              onCloseClick={() => setShowRoutesNearby(null)}
            >
              <VStack m="4px" alignItems="flex-start" spacing="2">
                <Text mb={0} fontSize="sm" fontWeight="bold">
                  {allowMultipleRoutes ? (
                    <FormattedMessage
                      defaultMessage="Select routes"
                      id="8ks+D9"
                    />
                  ) : (
                    <FormattedMessage
                      defaultMessage="Select route"
                      id="Rcxr+5"
                    />
                  )}
                </Text>
                {allowMultipleRoutes ? (
                  <MultiSelectionList
                    items={showRoutesNearby.routes.map((route) => ({
                      value: route.routeId.toString(),
                      label: route.name,
                      color: routeColorsByTrackId[route.trackId],
                    }))}
                    selected={selectedItems}
                    setSelected={setSelectedItems}
                  />
                ) : (
                  showRoutesNearby.routes.map((route) => (
                    <Button
                      key={route.routeId}
                      maxWidth="200px"
                      size="sm"
                      variant="outline"
                      onClick={() => {
                        setSelectedItems([route.routeId.toString()]);
                        setShowRoutesNearby(null);
                        track('Route selected from map', {
                          property: 'Animated route',
                          referrer: 'Route select map',
                          value: route.routeId,
                        });
                      }}
                    >
                      <Text as="span" isTruncated>
                        {route.name}
                      </Text>
                    </Button>
                  ))
                )}
              </VStack>
            </InfoWindow>
          )}
        </CustomGoogleMap>
      </Box>
      <Box>
        {allowMultipleRoutes ? (
          <MultiSelectionPopover
            items={routes.map((route) => ({
              value: route.routeId.toString(),
              label: route.name,
              color: routeColorsByTrackId[route.trackId],
            }))}
            selected={selectedItems}
            setSelected={setSelectedItems}
          />
        ) : (
          <Select
            data-testid="select-route"
            whiteSpace="nowrap"
            overflow="hidden"
            textOverflow="ellipsis"
            value={selectedItems[0]}
            backgroundColor="white"
            maxWidth="320px"
            borderColor="gray.200"
            size="sm"
            placeholder={formatMessage({
              defaultMessage: 'Select route',
              id: 'Rcxr+5',
            })}
            onClick={() => setShowRoutesNearby(null)}
            onChange={(e) => {
              setSelectedItems([e.target.value]);
              track('Route selected from dropdown', {
                property: 'Select route dropdown',
                referrer: 'Route select map',
                value: e.target.value,
              });
            }}
          >
            {routes.map((route) => (
              <option key={route.routeId} value={route.routeId}>
                {route.name}
              </option>
            ))}
          </Select>
        )}
        <ButtonGroup mt={4}>
          <Button
            colorScheme="greenDark"
            size="sm"
            variant="solid"
            onClick={() => {
              const selectedRoutes = routes.filter((route) =>
                selectedItems.includes(route.routeId.toString())
              );
              onSave(selectedRoutes);
            }}
          >
            <FormattedMessage defaultMessage="Save" id="jvo0vs" />
          </Button>
          <Button
            size="sm"
            variant="outline"
            backgroundColor="white"
            onClick={onCancel}
          >
            <FormattedMessage defaultMessage="Cancel" id="47FYwb" />
          </Button>
        </ButtonGroup>
      </Box>
    </Flex>
  );
}

function MultiSelectionPopover({
  items,
  selected,
  setSelected,
}: {
  items: { value: string; label: string; color: string }[];
  selected: string[];
  setSelected: Dispatch<SetStateAction<string[]>>;
}) {
  const { onToggle, onClose, isOpen } = useDisclosure();
  const selectedItems = items.filter((item) => selected.includes(item.value));
  return (
    <Flex
      width="fit-content"
      borderRadius="md"
      border="1px"
      borderColor="gray.100"
      boxShadow="sm"
      backgroundColor="white"
    >
      <Popover isOpen={isOpen} onClose={onClose}>
        <PopoverTrigger>
          {selectedItems.length === 0 ? (
            <Button
              size="sm"
              variant="ghost"
              fontWeight="normal"
              fontStyle="italic"
              color="gray.600"
              onClick={onToggle}
              rightIcon={
                <Box
                  as="span"
                  display="block"
                  fontSize="20px"
                  lineHeight="0"
                  color="gray.700"
                >
                  {isOpen ? <ChevronUpIcon /> : <ChevronDownIcon />}
                </Box>
              }
            >
              <FormattedMessage defaultMessage="Select routes" id="8ks+D9" />
            </Button>
          ) : (
            <HStack spacing={1} padding={1}>
              <HStack spacing={1} flexWrap="wrap">
                {selectedItems.map((item) => (
                  <Tag
                    key={item.value}
                    size="sm"
                    variant="solid"
                    colorScheme="green"
                    backgroundColor="green.400"
                  >
                    <TagLabel>{item.label}</TagLabel>
                    <TagCloseButton
                      onClick={(e) => {
                        e.stopPropagation();
                        setSelected((state) =>
                          state.filter((stateItem) => stateItem !== item.value)
                        );
                      }}
                    />
                  </Tag>
                ))}
              </HStack>
              <IconButton
                variant="ghost"
                size="xs"
                aria-label="open/close"
                icon={isOpen ? <ChevronUpIcon /> : <ChevronDownIcon />}
                fontSize="20px"
                onClick={onToggle}
              />
            </HStack>
          )}
        </PopoverTrigger>
        <PopoverContent width="fit-content">
          <PopoverArrow />
          <PopoverBody p={4}>
            <MultiSelectionList
              items={items}
              selected={selected}
              setSelected={setSelected}
            />
          </PopoverBody>
        </PopoverContent>
      </Popover>
    </Flex>
  );
}

function MultiSelectionList({
  items,
  selected,
  setSelected,
}: {
  items: { value: string; label: string; color: string }[];
  selected: string[];
  setSelected: Dispatch<SetStateAction<string[]>>;
}) {
  const toggleItem = (item: string) => {
    setSelected((state) =>
      state.includes(item)
        ? state.filter((stateItem) => stateItem !== item)
        : [...state, item]
    );
  };
  const { track } = useAnalytics();
  return (
    <VStack spacing="2" alignItems="flex-start">
      {items.map((item) => {
        const isItemSelected = selected.indexOf(item.value) > -1;
        return (
          <Checkbox
            key={item.value}
            checked={isItemSelected}
            color={item.color}
            onChange={() => {
              toggleItem(item.value);
              track('Route selected from map checkbox', {
                property: 'Animated route',
                referrer: 'Route select map',
                value: item.value,
              });
            }}
          >
            {item.label}
          </Checkbox>
        );
      })}
    </VStack>
  );
}
