import { useEffect, useRef, useState } from 'react';
import debounce from 'lodash/debounce';
import Map = google.maps.Map;
import LatLngLiteral = google.maps.LatLngLiteral;
import { MAP_DEFAULT_CENTER } from '../../constants/map';
import { useAnalytics } from '../../hooks/analytics/useAnalytics';

export type MapPositionManager = ReturnType<typeof useMapPositionManager>;
export default function useMapPositionManager() {
  const mapRef = useRef<Map>();
  const [mapCenterState, setMapCenterState] = useState<LatLngLiteral>();
  const { track } = useAnalytics();
  useEffect(() => {
    if (navigator.geolocation && !mapCenterState) {
      navigator.geolocation.getCurrentPosition(
        (position: GeolocationPosition) => {
          const clientPos = {
            lat: position.coords.latitude,
            lng: position.coords.longitude,
          };
          setMapCenterState(clientPos);
        },
        () => {
          setMapCenterState(MAP_DEFAULT_CENTER);
          track('Auckland Default Map Center Used', {
            referrer: 'Create Site',
          });
        },
        { timeout: 5000 }
      );
    }
    // emulating componentDidMount
    // eslint-disable-next-line
  }, []);
  return {
    mapCenter: mapCenterState,
    mapLoadHandler: (mapElement: Map) => {
      mapRef.current = mapElement;
    },
    setMapCenter: (...params: Parameters<google.maps.Map['setCenter']>) => {
      mapRef.current?.setCenter(...params);
    },
    setMapBounds: (...params: Parameters<google.maps.Map['fitBounds']>) => {
      mapRef.current?.fitBounds(...params);
    },
    mapCenterChangeHandler: debounce(() => {
      const newPos = mapRef.current?.getCenter()?.toJSON();
      if (
        newPos &&
        mapCenterState &&
        (newPos.lat !== mapCenterState.lat || newPos.lng !== mapCenterState.lng)
      ) {
        setMapCenterState(mapRef.current?.getCenter()?.toJSON());
      }
    }, 800),
  };
}
