import {
  Box,
  FormControl,
  FormLabel,
  HStack,
  Switch,
  VStack,
} from '@chakra-ui/react';
import { useState } from 'react';
import { FormattedMessage } from 'react-intl';
import BaselineForm from '../BaselineForm';
import type { CreateWizardStepRenderProps } from '../CreateWizardLayout/CreateWizardLayout';
import useSiteBaselinesData from '../../data/useSiteBaselinesData';
import conesBg from '../../svg/cones.svg?inline';

export default function StepBaseline({
  stepFormRenderProps,
  siteId,
}: {
  stepFormRenderProps: CreateWizardStepRenderProps;
  siteId: string;
}) {
  const { data, requestBaselineCreation } = useSiteBaselinesData(siteId);
  const baselineProps = data?.baselineRequests?.[0];
  const [isFormActive, setIsFormActive] = useState(false);
  return (
    <VStack spacing={8} alignItems="stretch">
      <VStack spacing={4} alignItems="stretch">
        <VStack spacing={2} alignItems="stretch">
          <Box>
            <FormattedMessage
              defaultMessage="Is a baseline required to measure performance against?"
              id="IRTAL3"
            />
          </Box>
          <FormControl>
            <HStack spacing={2} alignItems="center">
              <Switch
                isChecked={isFormActive}
                isDisabled={stepFormRenderProps.isSubmitting}
                size="md"
                colorScheme="green"
                onChange={(e) => {
                  setIsFormActive(e.target.checked);
                }}
              />
              <FormLabel fontWeight="normal" mb={0}>
                <FormattedMessage defaultMessage="Yes" id="a5msuh" />
              </FormLabel>
            </HStack>
          </FormControl>
        </VStack>
        <Box
          background={`bottom right/auto no-repeat url(${conesBg})`}
          backgroundColor="yellow.100"
          borderRadius="3xl"
          padding="6"
          paddingRight="60px"
          paddingBottom="100px"
        >
          <Box fontSize="sm" fontWeight="bold" mb={1}>
            <FormattedMessage
              id="3BOufy"
              defaultMessage="Baselines help you benchmark"
            />
          </Box>
          <Box fontSize="xs">
            <FormattedMessage
              id="lewIC2"
              defaultMessage="Baselines allow you to compare the performance of your site with either a defined period in time or a rolling average of the prior weeks. This can give a good indication of the impact of your work compared to a time prior to work occurring."
            />
          </Box>
        </Box>
      </VStack>
      <Box>
        <BaselineForm
          key={baselineProps?.jobId ?? 'new'}
          defaultValues={
            baselineProps && {
              name: baselineProps.name,
              description: baselineProps.description,
              startDate: baselineProps.config.startDate,
              endDate: baselineProps.config.endDate,
            }
          }
          submitActionRef={stepFormRenderProps.submitActionRef}
          onSubmit={(formValues) =>
            isFormActive
              ? requestBaselineCreation(formValues)
              : Promise.resolve()
          }
          onValidationError={() =>
            isFormActive
              ? // Reject with true: prevent the user from moving to the next step
                // eslint-disable-next-line prefer-promise-reject-errors
                Promise.reject(true)
              : Promise.resolve()
          }
          isSubmitting={stepFormRenderProps.isSubmitting}
          isDisabled={!isFormActive}
        />
      </Box>
    </VStack>
  );
}
