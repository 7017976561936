import { useRef, useState } from 'react';
import { Box, Flex, Heading, Spacer, Text } from '@chakra-ui/react';
import { useIntl } from 'react-intl';
import moment from 'moment-timezone';
import { InfoWindow, Marker } from '@react-google-maps/api';
import { Route } from '../../types/route';
import { MapInsightsData } from '../../data/useMapInsightsData';
import highTrafficIcon from '../../svg/heavy-traffic-marker-red.svg?inline';
import mediumTrafficIcon from '../../svg/heavy-traffic-marker-orange.svg?inline';
import {
  getInsightConfigFromType,
  LegacyInsightType,
} from '../../utils/insightCharts';
import { Site } from '../../types/site';

export enum TrafficIntensity {
  HIGH = 'HIGH',
  MEDIUM = 'MEDIUM',
}

export default function TrafficInsightMarker({
  site,
  routeId,
  trafficDensity,
  routes,
  mapInsights,
}: {
  site: Site;
  routeId: number;
  trafficDensity: TrafficIntensity;
  routes: Route[];
  mapInsights: MapInsightsData;
}) {
  const { formatMessage } = useIntl();
  const [isInfoVisible, setInfoVisible] = useState(false);
  const markerRef = useRef<google.maps.Marker>();
  const route = routes.find((r) => r.routeId === routeId);
  const delayInsightName = mapInsights?.siteImpactDelay?.data
    ? 'siteImpactDelay'
    : 'timeDelay';
  const delayInsight = (mapInsights[delayInsightName]?.data || []).find(
    (insightItem) => insightItem.routeId === routeId
  );
  if (!route || !delayInsight) {
    return null;
  }
  return (
    <>
      <Marker
        position={route.startPoint}
        icon={
          (trafficDensity === TrafficIntensity.HIGH
            ? highTrafficIcon
            : mediumTrafficIcon) as unknown as string
        }
        onMouseOver={() => {
          setInfoVisible(true);
        }}
        onMouseOut={() => {
          setInfoVisible(false);
        }}
        onLoad={(markerInstance) => {
          markerRef.current = markerInstance;
        }}
      />
      {markerRef.current && isInfoVisible && (
        <InfoWindow anchor={markerRef.current}>
          <>
            <Heading fontSize={16} mb={3}>
              {route.name}
            </Heading>
            {(
              [
                delayInsightName,
                'queueLength',
                'journeyTime',
                'speed',
              ] as LegacyInsightType[]
            ).map((insightName) => {
              if (!mapInsights?.[insightName]?.data) {
                return null;
              }
              const routeInsight = mapInsights[insightName].data.find(
                (insightItem) => insightItem.routeId === routeId
              );
              if (!routeInsight?.items?.[0]?.date) {
                return null;
              }
              const { title, formatValue } = getInsightConfigFromType(
                insightName,
                site,
                formatMessage
              );
              return (
                <Flex key={insightName} py={1} minWidth={160}>
                  <Box pr={3}>{title}</Box>
                  <Spacer />
                  <Box>{formatValue(routeInsight.items[0].value)}</Box>
                </Flex>
              );
            })}
            <Text
              fontSize="11px"
              mb={0}
              mt={2}
              pt={2}
              borderTop="solid 1px"
              borderTopColor="gray.100"
            >
              {moment
                .unix(delayInsight.items?.[0]?.date)
                .tz(site.siteTimeZone)
                .format('L LT')}
            </Text>
          </>
        </InfoWindow>
      )}
    </>
  );
}
