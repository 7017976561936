import { Box, Button, Text, VStack, Link } from '@chakra-ui/react';
import { FormEvent, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import PhoneNumberInput from 'design-system/molecules/phone-number-input';
import { PhoneNumber } from '../../api/onboard/constants';

interface Props {
  handleSetPhoneNumber: (phoneNumber: PhoneNumber) => Promise<void>;
}

const SetPhoneNumber = ({ handleSetPhoneNumber }: Props) => {
  const [number, setNumber] = useState('');
  const [isValid, setIsValid] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const onChange = (num: string, valid: boolean) => {
    setNumber(num);
    setIsValid(valid);
  };

  const onSubmit = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setIsSubmitting(true);
    await handleSetPhoneNumber(number);
    setIsSubmitting(false);
  };

  return (
    <form onSubmit={onSubmit}>
      <Box mb="40px">
        <Text fontSize="2xl">
          <FormattedMessage
            defaultMessage="Now, set up 2-factor authentication"
            id="kOSNWH"
            description="Set phone number"
          />
        </Text>
        <Text color="gray.500" fontSize="md" mb="0">
          <FormattedMessage
            defaultMessage="This reduces the risk of unauthorised access to your account by requiring authentication codes when signing in."
            id="DWlFFs"
            description="Explanation of why 2FA is valuable"
          />
        </Text>
      </Box>
      <VStack spacing="40px" alignItems="left">
        <Box>
          <PhoneNumberInput defaultNumber={number} onChange={onChange} />
        </Box>
        <Text fontSize="14px" mb="0" color="gray.500">
          <FormattedMessage
            defaultMessage={`
              Confirmation of your mobile number will enable you to receive 2-factor authentication and KPI breaches via SMS.{lineBreak}
              Message and data rates may apply. For support, simply <contactLink>get in touch</contactLink>.{lineBreak}
              You can reply STOP to unsubscribe to alerts or disable alerts here at any time.{lineBreak}
              You can also view our <privacyLink>privacy policy</privacyLink> for more information
            `}
            id="9hIOOb"
            values={{
              privacyLink: (linkText) => (
                <Link isExternal href="//mooven.com/privacy">
                  {linkText}
                </Link>
              ),
              contactLink: (linkText) => (
                <Link
                  href="mailto:rkrctuz0@mooven.intercom-mail.com"
                  id="intercom-activator"
                >
                  {linkText}
                </Link>
              ),
              lineBreak: <br />,
            }}
          />
        </Text>
        <Box>
          <Button
            isLoading={isSubmitting}
            variant="solid"
            colorScheme="greenDark"
            type="submit"
            isDisabled={!isValid || isSubmitting}
          >
            <FormattedMessage
              defaultMessage="Confirm mobile number"
              id="Z+9N9V"
              description="Confirm phone number"
            />
          </Button>
        </Box>
      </VStack>
    </form>
  );
};

export default SetPhoneNumber;
