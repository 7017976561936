import {
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  VStack,
  Text,
} from '@chakra-ui/react';
import { FormattedMessage } from 'react-intl';
import InfoTooltip from '../info-tooltip';

export type TabData = {
  id: number;
  title: React.ReactNode;
  icon: React.ReactNode;
  component: React.ReactNode;
  disabled?: boolean;
};

export default function CustomTabsList({
  tabsData,
  onChange,
  defaultIndex,
}: {
  tabsData: TabData[];
  onChange: (tabId: number) => void;
  defaultIndex?: number;
}) {
  return (
    <Tabs onChange={onChange} defaultIndex={defaultIndex ?? 0}>
      <TabList
        borderBottom={0}
        gap={1}
        maxWidth="100%"
        overflowX="auto"
        overflowY="hidden"
      >
        {tabsData.map((tab) => (
          <InfoTooltip
            content={
              tab.disabled && (
                <FormattedMessage defaultMessage="Coming soon" id="e61Jf3" />
              )
            }
            key={tab.id}
          >
            <Tab
              key={tab.id}
              w="100px"
              _selected={{ bg: 'gray.50' }}
              borderRadius="16px"
              px={2}
              py={4}
              isDisabled={tab.disabled}
            >
              <VStack spacing={2} align="center">
                {tab.icon}
                <Text
                  marginBottom={0}
                  lineHeight={4}
                  fontSize="xs"
                  textAlign="center"
                  whiteSpace="normal"
                >
                  {tab.title}
                </Text>
              </VStack>
            </Tab>
          </InfoTooltip>
        ))}
      </TabList>

      <TabPanels>
        {tabsData.map((tab) => (
          <TabPanel px={0} key={tab.id}>
            {tab.component}
          </TabPanel>
        ))}
      </TabPanels>
    </Tabs>
  );
}
