import { Flex, VStack } from '@chakra-ui/react';
import { Link } from 'react-router-dom';
import MoovenLogo from 'design-system/atoms/mooven-logo';
import { pathDashboard } from '../../constants/path';

export default function LayoutWebpage({
  children,
}: {
  children: React.ReactNode;
}) {
  return (
    <Flex
      flex="1"
      padding={{ base: 4, md: 8, lg: '120px' }}
      justifyContent="center"
      alignItems="flex-start"
      bgColor="white"
    >
      <VStack width="100%" maxWidth="3xl" alignItems="flex-start" spacing={4}>
        <Link to={pathDashboard()}>
          <MoovenLogo />
        </Link>
        {children}
      </VStack>
    </Flex>
  );
}
