import { jwtDecode, JwtPayload } from 'jwt-decode';
import { envConfig } from '../../config/envConfig';
import { errorReport } from '../../utils/errors';
import { standardFetch } from '../../utils/fetch';
import { INVALID, OnboardStatus } from './constants';

const VERIFY_TOKEN_URI = `${envConfig.API_URL}/api/v1/onboard/verifyToken`;

export default function verifyToken(token: string) {
  return async () => {
    const response = await standardFetch<OnboardStatus>(VERIFY_TOKEN_URI, {
      method: 'POST',
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ token }),
    });

    if (!response) {
      return INVALID;
    }

    return response;
  };
}

interface DecodedToken extends JwtPayload {
  error: false;
  userId: number;
  sub: string;
}

interface InvalidToken {
  hasError: true;
}

interface ValidToken {
  hasError: false;
  userId: number;
  email: string;
}

type Token = ValidToken | InvalidToken;

export function decodeToken(token: string): Token {
  try {
    const { userId, sub } = jwtDecode<DecodedToken>(token);
    return { userId, email: sub, hasError: false };
  } catch (e) {
    errorReport.unknown(e);
    return { hasError: true };
  }
}
