import { useState } from 'react';
import { useIntl } from 'react-intl';
import { useAnalytics } from './analytics/useAnalytics';
import { useFailureToast } from './useFailureToast';
import { useSuccessToast } from './useSuccessToast';
import { errorReport } from '../utils/errors';

interface ToastMessageProps {
  title: string;
  description?: string;
}

interface ActionWithFeedbackConfig<TAction> {
  action: TAction;
  successMessage?: string | ToastMessageProps | false;
  failureMessage?: string | ToastMessageProps | false;
  trackEvent?: {
    name: string;
    data: { referrer: string } & Record<string, any>;
  };
  onActionWillPerform?: () => void;
  onSuccess?: (
    value: TAction extends (...args: any) => any
      ? ReturnType<TAction>
      : undefined
  ) => void;
  onFailure?: (err: any) => void;
  onComplete?: () => void;
}

export function useActionWithFeedback<
  TAction extends (...args: any[]) => Promise<any> | any,
>({
  action,
  successMessage,
  failureMessage,
  trackEvent,
  onActionWillPerform,
  onSuccess,
  onFailure,
  onComplete,
}: ActionWithFeedbackConfig<TAction>) {
  const [isPerformingAction, setIsPerformingAction] = useState(false);
  const successToast = useSuccessToast();
  const failureToast = useFailureToast();
  const { formatMessage } = useIntl();
  const { track } = useAnalytics();

  const successToastProps = getToastProps(
    formatMessage({
      id: 'JXdbo8',
      defaultMessage: 'Done',
    }),
    successMessage || undefined
  );
  const failureToastProps = getToastProps(
    formatMessage({
      id: 'NLgwF5',
      defaultMessage: 'An error has occured',
    }),
    failureMessage || undefined
  );

  const performAction = async (...args: Parameters<typeof action>) => {
    setIsPerformingAction(true);
    if (trackEvent) {
      track(trackEvent.name, trackEvent.data);
    }
    try {
      if (onActionWillPerform) {
        onActionWillPerform();
      }
      const returnValue = await action(...args);
      if (onSuccess) {
        onSuccess(returnValue);
      }
      if (successMessage !== false) {
        successToast(successToastProps);
      }
    } catch (e) {
      errorReport.handled(e);
      if (failureMessage !== false) {
        failureToast(failureToastProps);
      }
      if (onFailure) {
        onFailure(e);
      }
    } finally {
      setIsPerformingAction(false);
      if (onComplete) {
        onComplete();
      }
    }
  };

  return {
    performAction,
    isPerformingAction,
  };
}

function getToastProps(
  defaultMessage: string,
  customMessage?: string | ToastMessageProps
): ToastMessageProps {
  if (!customMessage) {
    return {
      title: defaultMessage,
    };
  }
  if (typeof customMessage === 'string') {
    return { title: customMessage };
  }
  return customMessage;
}
