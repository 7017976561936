import './VehicleCountChartPanel.scss';

import { SeriesLineOptions } from 'highcharts';
import { FormattedMessage, useIntl } from 'react-intl';
import LayoutEmpty from 'design-system/molecules/layout-empty';
import Chart from 'design-system/atoms/chart';
import { VehicleItemData } from '../../types/VehicleDailyProfile';
import NoDataForDates from '../../svg/no-data-for-dates.svg?react';
import {
  MID_BLUE_COLOR,
  LIGHT_BLUE_COLOR,
  YELLOW_COLOR,
} from '../../constants/clientConstants';
import { MS_24_HOURS, MS_ONE_HOUR } from '../../constants/time';
import { useAnalytics } from '../../hooks/analytics/useAnalytics';

interface VehicleCountLineChartProps {
  countData: VehicleItemData[];
  title: string;
  subtitle: string;
  formattedAvailableStartDate: string;
  formattedAvailableEndDate: string;
}

export default function VehicleCountChartPanel({
  countData,
  title,
  subtitle,
  formattedAvailableStartDate,
  formattedAvailableEndDate,
}: VehicleCountLineChartProps) {
  const { formatTime } = useIntl();
  const { track } = useAnalytics();
  const trackChartEvents = (eventName, eventProps = {}) => {
    track(eventName, {
      ...eventProps,
      referrer: 'Vehicle Count Chart',
    });
  };

  const formattedChartSeries = countData.reduce(
    (series, item, itemIndex) => {
      const timestamp = item.date * 1000;
      series[0].data!.push([timestamp, item.lightValue]);
      series[1].data!.push([timestamp, item.heavyValue]);
      series[2].data!.push([timestamp, item.value]);
      if (itemIndex === countData.length - 1) {
        const lastPointTimestamp = countData[0].date * 1000 + MS_24_HOURS;
        series[0].data!.push([lastPointTimestamp, countData[0].lightValue]);
        series[1].data!.push([lastPointTimestamp, countData[0].heavyValue]);
        series[2].data!.push([lastPointTimestamp, countData[0].value]);
      }
      return series;
    },
    [
      {
        type: 'line',
        data: [],
        name: 'Light Vehicles',
        color: MID_BLUE_COLOR,
      } as SeriesLineOptions,
      {
        type: 'line',
        data: [],
        name: 'Heavy Vehicles',
        color: LIGHT_BLUE_COLOR,
      } as SeriesLineOptions,
      {
        type: 'line',
        name: 'Total Counts',
        data: [],
        color: YELLOW_COLOR,
      } as SeriesLineOptions,
    ]
  );

  const isChartEmpty = countData.length === 0;
  return (
    <div
      className="v2-vehicle-count-chart-panel"
      data-testid="vehicle-count-chart-panel"
    >
      <Chart
        key={isChartEmpty ? 0 : 1}
        eventTracker={trackChartEvents}
        overlayComponent={
          isChartEmpty ? (
            <LayoutEmpty
              image={<NoDataForDates width="298" />}
              title={
                <FormattedMessage
                  defaultMessage="Try changing the date range"
                  id="0YpsNN"
                  description="Empty chart title for vehicle count charts"
                />
              }
              text={
                <FormattedMessage
                  defaultMessage={`
                  There’s no information available for this direction at this site in this date range.
                  Try selecting dates between <strong>{startDate}</strong> and <strong>{endDate}</strong>
                `}
                  id="/6vtFG"
                  description="Empty chart message for vehicle count charts"
                  values={{
                    startDate: formattedAvailableStartDate,
                    endDate: formattedAvailableEndDate,
                  }}
                />
              }
            />
          ) : null
        }
        options={{
          title: {
            text: title,
          },
          subtitle: {
            text: subtitle,
          },
          tooltip: {
            xDateFormat: '%l:%M %P',
          },
          xAxis: {
            type: 'datetime',
            tickInterval: MS_ONE_HOUR,
            labels: {
              formatter: (ctx) =>
                formatTime(ctx.value, {
                  hour: 'numeric',
                  hour12: true,
                  timeZone: 'UTC',
                }),
            },
          },
          series: formattedChartSeries,
          ...(isChartEmpty
            ? {
                legend: {
                  enabled: false,
                },
                exporting: {
                  enabled: false,
                },
              }
            : {}),
        }}
      />
    </div>
  );
}
