import classnames from 'classnames';
import moment from 'moment-timezone';
import PropTypes from 'prop-types';
import { FormattedMessage, useIntl } from 'react-intl';
import { Grid, GridItem } from '@chakra-ui/react';
import InfoTooltip from 'design-system/atoms/info-tooltip';
import {
  MESSAGE_BUTTON_CANCEL,
  MESSAGE_BUTTON_DELETE,
  MESSAGE_BUTTON_EDIT,
  MESSAGE_BUTTON_SAVE,
  MESSAGE_GENERIC_ERROR,
  MESSAGE_UI_DISABLED_SITE_MONITOR_PERMISSION,
} from '../../constants/messages';
import { useAnalytics } from '../../hooks/analytics/useAnalytics';
import useForm, { useFormValidation } from '../../hooks/useForm';
import useRequestStatus from '../../hooks/useRequestStatus';
import { FormValidationCheck } from '../../types/form';
import { BlankSchedule, Schedule } from '../../types/schedule';
import {
  validateRequired,
  validatorWithFormatter,
} from '../../utils/validation';
import './EditableSiteSchedule.scss';
import FormField from '../FormField';
import Spinner from '../Spinner';

const DATE_CONTINUOUS = 'DATE_CONTINUOUS';

interface EditableSiteScheduleProps {
  siteTimeZone: string;
  schedule: Schedule | BlankSchedule;
  saveSchedule: (schedule: Schedule | BlankSchedule) => Promise<unknown>;
  deleteSchedule?: (schedule: Schedule) => Promise<unknown>;
  toggleEditing: () => void;
  hasSiteMonitorPermission?: boolean;
  hasOverlap?: boolean;
  disableStartDate: boolean;
  isEditing: boolean;
  isLayoutNarrow?: boolean;
}
export default function EditableSiteSchedule({
  siteTimeZone,
  schedule,
  saveSchedule,
  deleteSchedule,
  hasSiteMonitorPermission,
  hasOverlap,
  disableStartDate,
  isEditing,
  toggleEditing,
  isLayoutNarrow = false,
}: EditableSiteScheduleProps) {
  const { formatMessage, formatTime } = useIntl();
  const { track } = useAnalytics();
  const [isSaving, hasSavingError, withSavingStatus] = useRequestStatus();
  const hourFromNowValue = Date.now() + 1000 * 60 * 60;

  return isEditing ? (
    <div
      className={classnames({
        'v2-editable-site-schedule': true,
        'v2-editable-site-schedule--narrow': isLayoutNarrow,
      })}
    >
      <ScheduleForm
        disableStartDate={disableStartDate}
        initialValues={{
          scheduleName: schedule.scheduleName!,
          startDateDisplay: getSiteDateDisplay(
            disableStartDate && !schedule.startDate
              ? 0
              : schedule.startDate || hourFromNowValue,
            siteTimeZone
          ),
          endDateDisplay: getSiteDateDisplay(
            disableStartDate || !schedule.endDate
              ? hourFromNowValue
              : schedule.endDate,
            siteTimeZone
          ),
        }}
        validationConfig={{
          scheduleName: validatorWithFormatter(validateRequired, formatMessage),
          startDateDisplay: (value) => {
            if (disableStartDate) {
              return undefined;
            }
            const hourFromNow = moment().add(58, 'minutes').tz(siteTimeZone);
            const enteredDate = moment.tz(value, siteTimeZone);
            if (!enteredDate.isValid()) {
              return formatMessage({
                defaultMessage: 'Invalid date',
                id: 'kaPa6t',
                description:
                  'Error message for invalid date format entered in monitoring schedule date input field.',
              });
            }
            if (enteredDate.isBefore(hourFromNow)) {
              return formatMessage({
                defaultMessage: 'Must be at least one hour from now.',
                id: 'GnmYPf',
                description:
                  'Error message for past date entered as start date in a monitoring schedule.',
              });
            }
            return undefined;
          },
          endDateDisplay: (value, allValues) => {
            if (value === DATE_CONTINUOUS) {
              return undefined;
            }
            const hourFromStartDate = moment(
              disableStartDate
                ? Date.now()
                : (allValues.startDateDisplay as string)
            )
              .add(58, 'minutes')
              .tz(siteTimeZone);
            const enteredDate = moment.tz(value, siteTimeZone);
            if (!enteredDate.isValid()) {
              return formatMessage({
                defaultMessage: 'Invalid date',
                id: 'kaPa6t',
                description:
                  'Error message for invalid date format entered in monitoring schedule date input field.',
              });
            }
            if (enteredDate.isBefore(hourFromStartDate)) {
              return formatMessage({
                defaultMessage: 'Must be at least one hour from start.',
                id: 'wd62xR',
                description:
                  'Error message for a date that is before the start date, entered as end date in a monitoring schedule.',
              });
            }
            return undefined;
          },
        }}
        onSave={({ scheduleName, startDateDisplay, endDateDisplay }) => {
          const updatedSchedule = {
            ...schedule,
            scheduleName,
            startDate: getSiteDateValue(startDateDisplay, siteTimeZone),
            endDate: getSiteDateValue(endDateDisplay, siteTimeZone),
          };
          withSavingStatus(saveSchedule)(updatedSchedule)
            .then(() => {
              const evt =
                schedule.scheduleId != null
                  ? 'Site Schedule Edited'
                  : 'Site Schedule Created';
              track(evt, {
                schedule: updatedSchedule,
                referrer: 'Edit Site Tab',
              });
            })
            .then(toggleEditing);
        }}
        onCancel={toggleEditing}
        isSaving={isSaving}
        isLayoutNarrow={isLayoutNarrow}
      />
      {hasSavingError && (
        <span className="v2-editable-site-schedule__error">
          {formatMessage(MESSAGE_GENERIC_ERROR)}
        </span>
      )}
    </div>
  ) : (
    <div
      className={classnames({
        'v2-editable-site-schedule': true,
        'v2-editable-site-schedule--narrow': isLayoutNarrow,
        'v2-editable-site-schedule--warning': hasOverlap,
      })}
    >
      <div className="v2-editable-site-schedule__summary">
        <div className="v2-editable-site-schedule__summary-title">
          {schedule.scheduleName}
        </div>
        <FormattedMessage
          defaultMessage={`
            {startDateStatus, select, future {<strong>Will monitor from {startDate}</strong>} past {Monitoring since {startDate}} other {monitoring}} ---
            {endDate, select, 0 {<strong>runs continuously</strong>} other {<strong>to {endDate}</strong>}}
          `}
          id="Wfd/Jf"
          description="Monitoring schedule summary, showing the dates the schedule is supposed to be running. Zero for endDate and other for startDateStatus means no date has been set."
          values={{
            startDateStatus: !schedule.startDate
              ? 'no-date'
              : isDatePastOrFuture(schedule.startDate),
            startDate: !schedule.startDate
              ? 0
              : formatTime(
                  getSiteDateDisplay(schedule.startDate, siteTimeZone),
                  {
                    year: 'numeric',
                    month: '2-digit',
                    day: '2-digit',
                    hour: 'numeric',
                    minute: 'numeric',
                  }
                ),
            endDate: !schedule.endDate
              ? 0
              : formatTime(getSiteDateDisplay(schedule.endDate, siteTimeZone), {
                  year: 'numeric',
                  month: '2-digit',
                  day: '2-digit',
                  hour: 'numeric',
                  minute: 'numeric',
                }),
          }}
        />
      </div>
      <span className="v2-editable-site-schedule__buttons">
        <InfoTooltip
          content={
            !hasSiteMonitorPermission
              ? formatMessage(MESSAGE_UI_DISABLED_SITE_MONITOR_PERMISSION)
              : undefined
          }
        >
          <button
            type="button"
            className="v2-editable-site-schedule__btn-edit"
            onClick={toggleEditing}
            disabled={isSaving || !hasSiteMonitorPermission}
          >
            {formatMessage(MESSAGE_BUTTON_EDIT)}
          </button>
        </InfoTooltip>
        {deleteSchedule && (
          <InfoTooltip
            content={
              !hasSiteMonitorPermission
                ? formatMessage(MESSAGE_UI_DISABLED_SITE_MONITOR_PERMISSION)
                : undefined
            }
          >
            <button
              type="button"
              className="v2-editable-site-schedule__btn-delete"
              onClick={() => {
                if (
                  schedule.scheduleId !== undefined &&
                  window.confirm(
                    formatMessage(
                      {
                        defaultMessage:
                          'Are you sure you want to delete {scheduleName}?',
                        id: 'kMffyG',
                        description:
                          'Confirmation message displayed when an user requests a monitoring schedule to be deleted.',
                      },
                      {
                        scheduleName: schedule.scheduleName,
                      }
                    )
                  )
                ) {
                  withSavingStatus(deleteSchedule)(schedule as Schedule).then(
                    () => {
                      track('Site Schedule Deleted', {
                        schedule,
                        referrer: 'Edit Site Tab',
                      });
                    }
                  );
                }
              }}
              disabled={isSaving || !hasSiteMonitorPermission}
            >
              {formatMessage(MESSAGE_BUTTON_DELETE)}
            </button>
          </InfoTooltip>
        )}
      </span>

      {isSaving && <Spinner />}
      {hasOverlap && (
        <div className="v2-editable-site-schedule__warning">
          <FormattedMessage
            defaultMessage="This schedule conflicts with others, the monitoring will end at the earliest end date selected."
            id="AtKht4"
            description="Warning message displayed for schedules with a time window overlapping another schedule's."
          />
        </div>
      )}
    </div>
  );
}
EditableSiteSchedule.propTypes = {
  siteTimeZone: PropTypes.string.isRequired,
  schedule: PropTypes.shape({
    scheduleId: PropTypes.number,
    scheduleName: PropTypes.string,
    startDate: PropTypes.number,
    endDate: PropTypes.number,
  }).isRequired,
  saveSchedule: PropTypes.func.isRequired,
  deleteSchedule: PropTypes.func,
  toggleEditing: PropTypes.func,
  hasSiteMonitorPermission: PropTypes.bool,
  hasOverlap: PropTypes.bool,
  disableStartDate: PropTypes.bool,
  isEditing: PropTypes.bool,
};
interface ScheduleFormValues extends Record<string, any> {
  scheduleName: string;
  startDateDisplay: string;
  endDateDisplay: string;
}
function ScheduleForm({
  initialValues,
  validationConfig,
  onSave,
  onCancel,
  isSaving,
  disableStartDate,
  isLayoutNarrow = false,
}: {
  initialValues: ScheduleFormValues;
  validationConfig: {
    scheduleName: FormValidationCheck<string, ScheduleFormValues>;
    startDateDisplay: FormValidationCheck<string, ScheduleFormValues>;
    endDateDisplay: FormValidationCheck<string, ScheduleFormValues>;
  };
  onSave: (values: ScheduleFormValues) => void;
  onCancel: () => void;
  isSaving: boolean;
  disableStartDate: boolean;
  isLayoutNarrow?: boolean;
}) {
  const { formatMessage } = useIntl();
  const startDateLabelMessage = formatMessage({
    defaultMessage: 'Start',
    id: 'lQIkTD',
    description: 'Schedule start date input field label',
  });
  const scheduleForm = useForm(initialValues);
  const scheduleFormValidation = useFormValidation(
    scheduleForm,
    validationConfig
  );
  const setDefaultEndDate = () => {
    const defaultEndDate = moment(
      moment(scheduleForm.fields.startDateDisplay.value).isValid()
        ? scheduleForm.fields.startDateDisplay.value
        : Date.now()
    )
      .add(1, 'hours')
      .format('YYYY-MM-DD[T]HH:mm');
    scheduleForm.fields.endDateDisplay.setValue(defaultEndDate);
  };
  return (
    <Grid
      gridTemplateColumns={isLayoutNarrow ? '1fr' : 'repeat(12, 1fr)'}
      gap={6}
    >
      <GridItem colSpan={isLayoutNarrow ? 1 : { base: 12, lg: 3 }}>
        <FormField
          label={formatMessage({
            defaultMessage: 'Schedule name',
            id: 'YeebIP',
            description: 'Schedule name input field label',
          })}
          value={scheduleForm.fields.scheduleName.value}
          onChange={scheduleForm.fields.scheduleName.onChange}
          error={scheduleFormValidation.fields.scheduleName.error}
          disabled={isSaving}
          autoFocus
        />
      </GridItem>

      <GridItem colSpan={isLayoutNarrow ? 1 : { base: 12, md: 6, lg: 3 }}>
        {disableStartDate ? (
          <div className="v2-editable-site-schedule__field-container">
            <span className="v2-editable-site-schedule__field-label">
              {startDateLabelMessage}
            </span>
            <div>
              <span className="v2-editable-site-schedule__field-text">
                <FormattedMessage
                  defaultMessage="Already running"
                  id="zIEOYz"
                  description="Message when schedule start date input is disabled because monitoring has already started."
                />
              </span>
            </div>
          </div>
        ) : (
          <FormField
            type="datetime-local"
            label={startDateLabelMessage}
            value={scheduleForm.fields.startDateDisplay.value}
            onChange={scheduleForm.fields.startDateDisplay.onChange}
            error={scheduleFormValidation.fields.startDateDisplay.error}
            disabled={isSaving}
          />
        )}
      </GridItem>
      <GridItem colSpan={isLayoutNarrow ? 1 : { base: 12, md: 6, lg: 3 }}>
        {scheduleForm.fields.endDateDisplay.value === DATE_CONTINUOUS ? (
          <div className="v2-editable-site-schedule__field-container">
            <span className="v2-editable-site-schedule__field-label">
              <FormattedMessage
                defaultMessage="Stop"
                id="vEK05Y"
                description="Schedule end date input field label."
              />
            </span>
            <div>
              <span className="v2-editable-site-schedule__field-text">
                <button
                  key={0}
                  type="button"
                  onClick={setDefaultEndDate}
                  className="v2-editable-site-schedule__btn-set-date"
                  disabled={isSaving}
                >
                  (
                  <FormattedMessage
                    defaultMessage="set an end date"
                    id="UA1czL"
                    description="Button label for allowing user to set an end date in a schedule setup form."
                  />
                  )
                </button>
              </span>
            </div>
          </div>
        ) : (
          <>
            <FormField
              type="datetime-local"
              label={formatMessage({
                defaultMessage: 'Stop',
                id: 'vEK05Y',
                description: 'Schedule end date input field label.',
              })}
              value={scheduleForm.fields.endDateDisplay.value}
              onChange={scheduleForm.fields.endDateDisplay.onChange}
              error={scheduleFormValidation.fields.endDateDisplay.error}
              disabled={isSaving}
              autoFocus={disableStartDate}
            />
            {scheduleForm.fields.endDateDisplay.value !== DATE_CONTINUOUS && (
              <button
                key={1}
                type="button"
                onClick={() =>
                  scheduleForm.fields.endDateDisplay.setValue(DATE_CONTINUOUS)
                }
                className="v2-editable-site-schedule__btn-set-date v2-editable-site-schedule__btn-set-date--remove"
                disabled={isSaving}
              >
                (
                <FormattedMessage
                  defaultMessage="remove end date"
                  id="fvKbE7"
                  description="Button label for removing end date from a schedule setup form."
                />
                )
              </button>
            )}
          </>
        )}
      </GridItem>
      <GridItem
        colSpan={isLayoutNarrow ? 1 : { base: 12, md: 6, lg: 3 }}
        alignSelf="center"
      >
        <div className="v2-editable-site-schedule__buttons">
          <button
            type="button"
            onClick={() => {
              if (scheduleFormValidation.validate()) {
                onSave(scheduleForm.values);
              }
            }}
            className="v2-editable-site-schedule__btn-save"
            disabled={isSaving}
          >
            {formatMessage(MESSAGE_BUTTON_SAVE)}
          </button>
          <button
            type="button"
            onClick={() => {
              scheduleForm.reset();
              onCancel();
            }}
            className="v2-editable-site-schedule__btn-cancel"
            disabled={isSaving}
          >
            {formatMessage(MESSAGE_BUTTON_CANCEL)}
          </button>
          {isSaving && <Spinner />}
        </div>
      </GridItem>
    </Grid>
  );
}
ScheduleForm.propTypes = {
  initialValues: PropTypes.shape({
    scheduleName: PropTypes.string.isRequired,
    startDateDisplay: PropTypes.string.isRequired,
    endDateDisplay: PropTypes.string.isRequired,
  }),
  validationConfig: PropTypes.shape({
    scheduleName: PropTypes.func,
    startDateDisplay: PropTypes.func,
    endDateDisplay: PropTypes.func,
  }),
  onSave: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  isSaving: PropTypes.bool.isRequired,
  disableStartDate: PropTypes.bool,
};

function isDatePastOrFuture(date: number): string {
  if (date > Date.now()) {
    return 'future';
  }
  return 'past';
}

function getSiteDateDisplay(date: number, siteTimeZone: string): string {
  if (!date) {
    return DATE_CONTINUOUS;
  }
  return moment(date).tz(siteTimeZone).format('YYYY-MM-DD[T]HH:mm');
}

function getSiteDateValue(displayDate: string, siteTimeZone: string): number {
  return displayDate === DATE_CONTINUOUS
    ? 0
    : moment.tz(displayDate, siteTimeZone).valueOf();
}
