import { Flex, Text } from '@chakra-ui/react';

import { ReactNode } from 'react';
import { WarningIcon, CalendarIcon } from '../custom-icons';

export const Status = ({
  calendarStatus,
  warningStatus,
}: {
  calendarStatus?: string;
  warningStatus?: ReactNode;
}) => (
  <Flex direction="column" width="full">
    {calendarStatus && (
      <Flex alignItems="center">
        <CalendarIcon color="gray.700" />
        <Text
          color="gray.700"
          fontSize="xs"
          lineHeight={4}
          mb={0}
          ml={1}
          fontWeight={400}
        >
          {calendarStatus}
        </Text>
      </Flex>
    )}
    {warningStatus && (
      <Flex alignItems="center">
        <WarningIcon color="orange.400" />
        <Text
          color="orange.400"
          fontSize="xs"
          lineHeight={4}
          mb={0}
          ml={1}
          fontWeight={400}
        >
          {warningStatus}
        </Text>
      </Flex>
    )}
  </Flex>
);
