import './FormField.scss';
import { ReactNode } from 'react';
import classnames from 'classnames';
import InfoTooltip from 'design-system/atoms/info-tooltip';
import { FormFieldProps } from '../../types/form';

export default function FormField({
  type = 'text',
  label = '',
  value = '',
  multiline = false,
  placeholder,
  onChange,
  onKeyDown,
  onFocus,
  onBlur,
  autoFocus = false,
  disabled = false,
  tooltip,
  readOnly = false,
  error,
  options,
  maxLength,
  step,
  elementRef,
  customClassNames = {},
  dataTestId,
}: FormFieldProps) {
  let inputElement;
  if (!options) {
    inputElement = multiline ? (
      <textarea
        className={classnames([
          'v2-form-field__textarea',
          customClassNames.element,
        ])}
        value={value}
        data-testid={dataTestId}
        onChange={onChange}
        onFocus={onFocus}
        onBlur={onBlur}
        onKeyDown={onKeyDown}
        disabled={disabled}
        readOnly={readOnly}
        placeholder={placeholder}
        autoFocus={autoFocus}
        maxLength={maxLength}
        ref={elementRef}
      />
    ) : (
      <input
        className={classnames([
          'v2-form-field__input',
          customClassNames.element,
        ])}
        type={type}
        value={value}
        data-testid={dataTestId}
        onChange={onChange}
        onFocus={onFocus}
        onBlur={onBlur}
        onKeyDown={onKeyDown}
        disabled={disabled}
        readOnly={readOnly}
        placeholder={placeholder}
        autoFocus={autoFocus}
        maxLength={maxLength}
        step={step}
        ref={elementRef}
      />
    );
  } else {
    inputElement = (
      <select
        className={classnames([
          'v2-form-field__select',
          customClassNames.element,
        ])}
        value={value}
        data-testid={dataTestId}
        onChange={onChange}
        onFocus={onFocus}
        onBlur={onBlur}
        onKeyDown={onKeyDown}
        disabled={disabled}
        autoFocus={autoFocus}
        ref={elementRef}
      >
        {options.map((option) => (
          <option key={option.value} value={option.value}>
            {option.label}
          </option>
        ))}
      </select>
    );
  }

  return (
    <FormFieldBlock
      label={label}
      error={error}
      customClassNames={customClassNames}
    >
      <InfoTooltip content={tooltip}>{inputElement}</InfoTooltip>
    </FormFieldBlock>
  );
}

export function FormFieldBlock({
  label,
  error,
  children,
  customClassNames = {},
}: {
  label?: string;
  error?: string;
  children?: ReactNode;
  customClassNames?: FormFieldProps['customClassNames'];
}) {
  return (
    <label
      className={classnames(['v2-form-field', customClassNames.container])}
    >
      {label && (
        <span
          className={classnames([
            'v2-form-field__label',
            customClassNames.label,
          ])}
        >
          {label}
        </span>
      )}
      {children}
      {error && (
        <span
          className={classnames([
            'v2-form-field__error',
            customClassNames.error,
          ])}
        >
          {error}
        </span>
      )}
    </label>
  );
}
