import { Suspense, useCallback, useEffect, useRef } from 'react';
import {
  Link as RouterLink,
  matchPath,
  Outlet,
  useLocation,
  useParams,
  Navigate,
} from 'react-router-dom';
import { Box, Flex, Link, Spinner, useBreakpointValue } from '@chakra-ui/react';
import { SplitClient } from '@splitsoftware/splitio-react';
import { Helmet } from 'react-helmet';
import './LayoutAuthenticated.scss';
import { useIntercom } from 'react-use-intercom';
import { FormattedMessage } from 'react-intl';
import LayoutEmpty from 'design-system/molecules/layout-empty';
import {
  pathDashboard,
  pathSitesEdit,
  pathSitesView,
  pathSitesPlan,
  pathSitesCreate,
  pathSitesOverview,
} from '../../constants/path';
import useCurrentUserData from '../../data/useCurrentUserData';
import MainNavigation from '../../local_design_system/organisms/MainNavigation';
import MoovenLogo from '../../svg/mooven-logo.svg?react';
import MoovenLogoOld from '../../svg/mooven-logo-solid.svg?react';
import {
  DataHandlerFeedback,
  hasDataHandlerFeedBack,
} from '../../utils/dataHandler';
import useProjectsData from '../../data/useProjectsData';
import ProjectSelector, {
  useProjectSelectorManager,
} from '../HomePage/ProjectSelector';
import useFeatureSwitch, {
  FeatureSwitchState,
} from '../../hooks/useFeatureSwitch';
import { FEATURE_PLAN_INSIGHT_TYPE_SELECTOR } from '../../constants/features';

export function AuthenticatedUserLayoutContext() {
  const { boot } = useIntercom();
  const currentUserDataHandler = useCurrentUserData();
  const intercomInitRef = useRef('');
  const currentUserHash = JSON.stringify({
    id: currentUserDataHandler.data?.userId,
    email: currentUserDataHandler.data?.email,
    firstName: currentUserDataHandler.data?.firstName,
    lastName: currentUserDataHandler.data?.lastName,
  });
  useEffect(() => {
    if (intercomInitRef.current !== currentUserHash) {
      intercomInitRef.current = currentUserHash;
      if (currentUserDataHandler.data?.userId !== undefined) {
        boot({
          userId: currentUserDataHandler.data.userId.toString(),
          email: currentUserDataHandler.data.email,
          name: `${currentUserDataHandler.data.firstName} ${currentUserDataHandler.data.lastName}`,
          customLauncherSelector: '#intercom-activator',
        });
      }
    }
  }, [currentUserDataHandler.data, currentUserHash, boot]);
  if (hasDataHandlerFeedBack(currentUserDataHandler)) {
    return <DataHandlerFeedback dataHandlersParam={currentUserDataHandler} />;
  }
  if (!currentUserDataHandler.data) {
    return null;
  }
  return (
    <SplitClient splitKey={currentUserDataHandler.data?.email}>
      <Outlet />
    </SplitClient>
  );
}

export function AuthenticatedOrgContext() {
  const { insightId: paramOrgId } = useParams();
  const projectsHandler = useProjectsData();

  if (!projectsHandler.data || hasDataHandlerFeedBack(projectsHandler)) {
    return <DataHandlerFeedback dataHandlersParam={projectsHandler} />;
  }
  if (projectsHandler.data.length === 0) {
    return (
      <LayoutEmpty
        title={
          <FormattedMessage
            defaultMessage="You don't have any projects."
            id="4qhvON"
            description="Message title for users with no project"
          />
        }
        text={
          <FormattedMessage
            defaultMessage="Please contact us if you need assistance."
            id="26RuQz"
            description="Message body for users with no project"
          />
        }
      />
    );
  }

  const hasOrgForParamId =
    paramOrgId !== undefined &&
    projectsHandler.data.some((p) => `${p.insightId}` === paramOrgId);
  if (hasOrgForParamId) {
    return <Outlet />;
  }

  const lastSelectedOrgId = localStorage.getItem('last-project-selected');
  const hasOrgForLastSelectedId =
    lastSelectedOrgId !== null &&
    projectsHandler.data.some((p) => `${p.insightId}` === lastSelectedOrgId);
  if (hasOrgForLastSelectedId) {
    return (
      <Navigate
        to={pathSitesOverview(lastSelectedOrgId, 'sites', null)}
        replace
      />
    );
  }

  return (
    <Navigate
      to={pathSitesOverview(
        projectsHandler.data[0].insightId?.toString(),
        'sites',
        null
      )}
      replace
    />
  );
}

export default function LayoutAuthenticated({
  showProjectSelector,
}: {
  showProjectSelector: boolean;
}) {
  const location = useLocation();

  // this is supposed to be a temporary solution and should not be extended.
  // If this pattern becomes an actual requirement,
  // please consider using outlet context instead: https://reactrouter.com/en/main/hooks/use-outlet-context
  const isOldLayout =
    matchPath(pathSitesEdit(), location.pathname) ||
    matchPath(pathSitesView(), location.pathname) ||
    matchPath(pathSitesPlan(), location.pathname);

  const isSiteCreateStep = !!matchPath(pathSitesCreate(), location.pathname);

  const featurePlanInsightTypeSelector = useFeatureSwitch(
    FEATURE_PLAN_INSIGHT_TYPE_SELECTOR
  );
  const oldLayoutNavSpacing =
    featurePlanInsightTypeSelector === FeatureSwitchState.ON ? 8 : 4;

  const Logo = useCallback(() => {
    if (isOldLayout) {
      return <MoovenLogoOld width="150" />;
    }
    return <MoovenLogo width="150" />;
  }, [isOldLayout]);

  const logoPosition = useBreakpointValue({
    base: {
      top: 4,
      left: 4,
    },
    md: {
      top: !isOldLayout ? 10 : 4,
      left: !isOldLayout ? 10 : 4,
    },
  });

  return (
    <>
      <Helmet>
        <title>Mooven</title>
      </Helmet>
      <Flex
        position={isOldLayout ? 'fixed' : 'absolute'}
        zIndex={20}
        direction={{ base: 'column', md: 'row' }}
        alignItems="flex-start"
        {...logoPosition}
      >
        {!isSiteCreateStep && (
          <Link as={RouterLink} to={pathDashboard()}>
            <Logo />
          </Link>
        )}

        {showProjectSelector && <ProjectSelectorRenderer />}
      </Flex>
      <Box
        position={isOldLayout ? 'fixed' : 'absolute'}
        top={{
          base: 4,
          md: isOldLayout ? 4 : 10,
          lg: isOldLayout ? oldLayoutNavSpacing : 10,
        }}
        right={{
          base: 4,
          md: isOldLayout ? 4 : 10,
          lg: isOldLayout ? oldLayoutNavSpacing : 10,
        }}
        zIndex={20}
      >
        <MainNavigation showCreateSiteButton={!isSiteCreateStep} />
      </Box>
      <Suspense
        fallback={
          <Flex
            width="100%"
            height="100%"
            alignItems="center"
            justifyContent="center"
            p={10}
          >
            <Spinner variant="mooven" size="xl" />
          </Flex>
        }
      >
        <Outlet />
      </Suspense>
    </>
  );
}

function ProjectSelectorRenderer() {
  const projectSelectorManager = useProjectSelectorManager();
  return !projectSelectorManager ||
    projectSelectorManager.projects.length <= 1 ? null : (
    <Box ml={{ base: 0, md: 4 }} mt={{ base: 4, md: 0 }}>
      <ProjectSelector {...projectSelectorManager} />
    </Box>
  );
}
