import { ReactNode } from 'react';
import {
  Box,
  Flex,
  FormControl,
  FormLabel,
  Input,
  InputGroup,
  InputRightAddon,
  VStack,
  Button,
} from '@chakra-ui/react';
import { FormattedMessage } from 'react-intl';
import { SmallCloseIcon } from '@chakra-ui/icons';
import { UseFormReturn } from 'react-hook-form';
import { PlanConstraints } from '../../types/plan';

const MINUTES_15_IN_SECONDS = 60 * 15;

export default function WorkConstraintsFieldset({
  formProps: { register, watch, setValue },
  kpiUnitLabel,
}: {
  formProps: UseFormReturn<PlanConstraints>;
  kpiUnitLabel?: string;
}) {
  const isStartTimeEmpty = watch('startTime') === '';
  const isEndTimeEmpty = watch('endTime') === '';
  const isKpiEmpty = watch('kpi') === undefined;
  return (
    <VStack spacing={8}>
      <FormRow
        onClear={() => {
          setValue('startTime', '');
          setValue('endTime', '');
        }}
        isClearDisabled={isStartTimeEmpty && isEndTimeEmpty}
      >
        <Flex gap={2}>
          <FormControl overflow="auto">
            <FormLabel fontSize="xs" fontWeight="normal">
              <FormattedMessage
                defaultMessage="Start time"
                id="26o1nI"
                description="Plan constraints form: start time label"
              />
            </FormLabel>
            <Input
              type="time"
              step={MINUTES_15_IN_SECONDS}
              {...register('startTime')}
            />
          </FormControl>
          <FormControl overflow="auto">
            <FormLabel fontSize="xs" fontWeight="normal">
              <FormattedMessage
                defaultMessage="End time"
                id="oC0bA4"
                description="Plan constraints form: end time label"
              />
            </FormLabel>
            <Input
              type="time"
              step={MINUTES_15_IN_SECONDS}
              {...register('endTime')}
            />
          </FormControl>
        </Flex>
      </FormRow>
      <FormRow
        onClear={() => {
          setValue('kpi', undefined);
        }}
        isClearDisabled={isKpiEmpty}
      >
        <FormControl flex="1">
          <FormLabel fontSize="xs" fontWeight="normal">
            <FormattedMessage defaultMessage="KPI" id="QVgyk9" />
          </FormLabel>
          <InputGroup>
            <Input
              type="number"
              {...register('kpi', {
                setValueAs: (stringValue) => {
                  if (stringValue === '' || stringValue === undefined) {
                    return undefined;
                  }
                  const numberValue = Number(stringValue);
                  if (Number.isNaN(numberValue)) {
                    return undefined;
                  }
                  return numberValue;
                },
              })}
              borderRightColor="transparent"
            />
            {kpiUnitLabel && (
              <InputRightAddon backgroundColor="white">
                {kpiUnitLabel}
              </InputRightAddon>
            )}
          </InputGroup>
        </FormControl>
      </FormRow>
    </VStack>
  );
}

function FormRow({
  children,
  onClear,
  isClearDisabled = false,
}: {
  children: ReactNode;
  onClear: () => void;
  isClearDisabled: boolean;
}) {
  return (
    <Flex width="100%" alignItems="end" gap={4}>
      <Box flex="1" overflow="auto">
        {children}
      </Box>
      <Flex flex="0 0 auto" mb="8px">
        <Button
          size="xs"
          variant="outline"
          onClick={onClear}
          leftIcon={<SmallCloseIcon />}
          isDisabled={isClearDisabled}
        >
          <FormattedMessage defaultMessage="Clear" id="/GCoTA" />
        </Button>
      </Flex>
    </Flex>
  );
}
