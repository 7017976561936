import { ReactNode, useState } from 'react';
import { Box, Button, useDisclosure, VStack } from '@chakra-ui/react';
import { useIntl } from 'react-intl';
import ConfirmationModal from 'design-system/molecules/confirmation-modal';

export default function UserActionsMenu<
  TUser extends {
    name: string;
    teamRole: string;
    pending: boolean;
  },
>({
  user,
  sendPasswordReset,
  resendInvite,
  removeTeamMember,
  removeSiteUser,
}: {
  user: TUser;
  sendPasswordReset?: (user: TUser) => Promise<void>;
  resendInvite?: (user: TUser) => Promise<void>;
  removeTeamMember?: (user: TUser) => Promise<void>;
  removeSiteUser?: (user: TUser) => Promise<void>;
}) {
  const isGuestUser = user.teamRole === 'None';
  const isUserPending = Boolean(user.pending);
  const { formatMessage } = useIntl();
  const removeTeamMemberConfirmationModal = useDisclosure();
  const removeSiteUserConfirmationModal = useDisclosure();
  return (
    <>
      <VStack spacing={4} alignItems="left">
        {resendInvite && (
          <ActionButton
            action={() => resendInvite(user)}
            label={formatMessage({
              id: 'yQqaoM',
              defaultMessage: 'Resend invite',
            })}
          />
        )}
        {sendPasswordReset && (
          <ActionButton
            action={() => sendPasswordReset(user)}
            label={formatMessage({
              id: 'FacQBr',
              defaultMessage: 'Send password reset',
            })}
          />
        )}
        {removeTeamMember && (
          <ActionButton
            data-testid="user-actions-menu-remove-member"
            action={async () => {
              if (isUserPending) {
                await removeTeamMember(user);
              } else {
                removeTeamMemberConfirmationModal.onOpen();
              }
            }}
            label={
              isGuestUser
                ? formatMessage({
                    id: 'snC7WK',
                    defaultMessage: 'Remove access to all sites',
                    description: 'user context menu > remove guest from team',
                  })
                : formatMessage({
                    id: '1yvw2c',
                    defaultMessage: 'Remove',
                    description: 'user context menu > remove member from team',
                  })
            }
          />
        )}
        {removeSiteUser && (
          <ActionButton
            action={async () => {
              if (isUserPending) {
                await removeSiteUser(user);
              } else {
                removeSiteUserConfirmationModal.onOpen();
              }
            }}
            label={formatMessage({
              id: 'hpiK2+',
              defaultMessage: 'Remove',
              description: 'user context menu > remove user from site',
            })}
          />
        )}
      </VStack>
      {removeTeamMember && !isUserPending && (
        <ConfirmationModal
          body={
            isGuestUser
              ? formatMessage(
                  {
                    id: 'W869Kf',
                    defaultMessage:
                      'Are you sure you want to remove {name} from all sites?',
                  },
                  { name: user.name }
                )
              : formatMessage(
                  {
                    id: 'NUlTeR',
                    defaultMessage: `Are you sure you want to remove {name} from this team?`,
                  },
                  { name: user.name }
                )
          }
          confirmButtonColorScheme="red"
          confirmButtonText={formatMessage({
            id: 'TP/O/b',
            defaultMessage: 'Remove user',
          })}
          onCancel={() => {
            removeTeamMemberConfirmationModal.onClose();
          }}
          onConfirm={async () => {
            await removeTeamMember(user);
            removeTeamMemberConfirmationModal.onClose();
          }}
          open={removeTeamMemberConfirmationModal.isOpen}
          title={formatMessage({
            id: 'vIBn/h',
            defaultMessage: 'Remove user?',
          })}
        />
      )}
      {removeSiteUser && !isUserPending && (
        <ConfirmationModal
          body={formatMessage(
            {
              id: 'OrFnOk',
              defaultMessage:
                'Are you sure you want to remove {name} from this sites?',
            },
            { name: user.name }
          )}
          confirmButtonColorScheme="red"
          confirmButtonText={formatMessage({
            id: 'TP/O/b',
            defaultMessage: 'Remove user',
          })}
          onCancel={() => {
            removeSiteUserConfirmationModal.onClose();
          }}
          onConfirm={async () => {
            await removeSiteUser(user);
            removeSiteUserConfirmationModal.onClose();
          }}
          open={removeSiteUserConfirmationModal.isOpen}
          title={formatMessage({
            id: 'vIBn/h',
            defaultMessage: 'Remove user?',
          })}
        />
      )}
    </>
  );
}

function ActionButton({
  action,
  label,
  'data-testid': dataTestId,
}: {
  action: () => Promise<void>;
  label: ReactNode;
  'data-testid'?: string;
}) {
  const [isPerformingAction, setIsPerformingAction] = useState(false);
  return (
    <Box>
      <Button
        isDisabled={isPerformingAction}
        onClick={async (e) => {
          e.preventDefault();
          setIsPerformingAction(true);
          try {
            await action();
          } finally {
            setIsPerformingAction(false);
          }
        }}
        variant="link"
        data-testid={dataTestId}
      >
        {label}
      </Button>
    </Box>
  );
}
