import useSWRImmutable from 'swr/immutable';
import { VehicleCountAvailability } from '@data-pipeline/api/src/types/vehicle-count';
import { dataTrpc } from '../config/trpc';

export interface CountAvailabilityParams {
  insightId?: string;
  siteId?: number;
  sourceId?: number;
  startDate?: number;
  endDate?: number;
  daysOfTheWeek?: number[];
}

const isParamsDefined = (params: CountAvailabilityParams) => {
  if (
    params.insightId !== undefined &&
    params.siteId !== undefined &&
    params.sourceId !== undefined &&
    params.startDate !== undefined &&
    params.endDate !== undefined &&
    params.daysOfTheWeek !== undefined
  ) {
    return true;
  }
  return false;
};

export default function useCountAvailabilityData(
  params: CountAvailabilityParams
): {
  data?: VehicleCountAvailability;
  isValidating: boolean;
  error: any;
} {
  const { data, isValidating, error } = useSWRImmutable(
    isParamsDefined(params)
      ? {
          keyName: 'useCountAvailabilityData',
          insightId: params.insightId,
          siteId: params.siteId,
          sourceId: params.sourceId,
          from: Math.floor(params.startDate! / 1000),
          to: Math.floor(params.endDate! / 1000),
          selectedWeekDays: params.daysOfTheWeek!.join(','),
        }
      : null,
    async (swrParams) => {
      const vehicleCountsAvailabilityData =
        await dataTrpc.vehicleCount.getVehicleCountAvailability.query({
          siteId: Number(swrParams.siteId),
          from: swrParams.from,
          to: swrParams.to,
          selectedWeekDays: swrParams.selectedWeekDays,
          sourceId: Number(params.sourceId),
        });
      return vehicleCountsAvailabilityData;
    }
  );

  return {
    data,
    isValidating,
    error,
  };
}
