import { ReactNode } from 'react';
import { Link } from 'react-router-dom';
import { pathSitesView } from '../constants/path';

export function intercomLinkFormatter() {
  return (text: ReactNode) => (
    <Link to="mailto:rkrctuz0@mooven.intercom-mail.com" id="intercom-activator">
      {text}
    </Link>
  );
}

export function siteLinkFormatter({ orgId, siteId }) {
  return (text: ReactNode) => (
    <Link to={pathSitesView(orgId, siteId)}>{text}</Link>
  );
}
