import { FormattedMessage } from 'react-intl';
import SelectionGroup from 'design-system/molecules/selection-group';
import { Box } from '@chakra-ui/react';
import { SiteView, SiteViewState } from '../../hooks/useSiteView';
import WorkAreaIcon from '../../svg/area.svg?react';

export interface VisibleWorkArea {
  id: string;
  name: string;
  fillColor: string;
}

interface VisibleWorkAreasSelectorProps {
  workAreas: VisibleWorkArea[];
  hiddenWorkAreas: SiteViewState['hiddenWorkAreas'];
  setHiddenWorkAreas: SiteView['setHiddenWorkAreas'];
}

export default function VisibleWorkAreasSelector({
  workAreas = [],
  hiddenWorkAreas,
  setHiddenWorkAreas,
}: VisibleWorkAreasSelectorProps) {
  const sortedWorkAreas = [...workAreas].sort((workAreaA, workAreaB) =>
    workAreaA.name.localeCompare(workAreaB.name)
  );
  const selectedOptions = sortedWorkAreas
    .filter((workArea) => !hiddenWorkAreas.includes(workArea.id))
    .map((workArea) => workArea.id);

  const onSelectedOptionsChange = (newSelectedOptions: string[]) => {
    const newHiddenWorkAreas = sortedWorkAreas
      .filter((workArea) => !newSelectedOptions.includes(workArea.id))
      .map((workArea) => workArea.id);

    setHiddenWorkAreas(newHiddenWorkAreas);
  };

  return (
    <Box p={4}>
      <SelectionGroup
        title={
          <FormattedMessage
            defaultMessage="Work Areas"
            id="AudB5m"
            description="Visible WorkAreas selector title"
          />
        }
        titleIcon={<WorkAreaIcon />}
        options={sortedWorkAreas.map((workArea) => ({
          id: workArea.id,
          label: workArea.name,
          color: workArea.fillColor,
        }))}
        selectedOptions={selectedOptions}
        onSelectedOptionsChange={onSelectedOptionsChange}
      />
    </Box>
  );
}
