import { CloseIcon, TimeIcon } from '@chakra-ui/icons';
import {
  Avatar,
  Box,
  Button,
  Flex,
  FormControl,
  FormErrorMessage,
  IconButton,
  Input,
  InputGroup,
  InputLeftElement,
  Text,
  Textarea,
  Tooltip,
  useDisclosure,
  Wrap,
  WrapItem,
} from '@chakra-ui/react';
import { runIfFn } from '@chakra-ui/utils';
import moment from 'moment-timezone';
import { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useIntl } from 'react-intl';
import { BinIcon, PencilIcon } from 'design-system/atoms/custom-icons';
import Label from 'design-system/molecules/label';
import {
  MESSAGE_BUTTON_CANCEL,
  MESSAGE_BUTTON_DELETE,
  MESSAGE_BUTTON_EDIT,
  MESSAGE_BUTTON_SAVE,
} from '../../../constants/messages';
import SentIcon from '../../../svg/sent-icon.svg?react';
import { Annotation } from '../../../types/annotations';
import {
  DATE_PRESET_NARROW_COMPLETE,
  TIME_PRESET_COMPLETE_HOUR12,
} from '../../../utils/dateUtils';
import { UpdateChartCallbacks } from '../../../utils/insightCharts';
import ResendAlertModal from './ResendAlertModal';
import SendAlert from './SendAlert';

interface NoteProps {
  annotation: Pick<
    Annotation,
    | 'startDate'
    | 'endDate'
    | 'title'
    | 'description'
    | 'ownerName'
    | 'canEdit'
    | 'sendAlert'
    | 'annotationId'
  >;
  timeZone: string;
  isEditing: boolean;
  errorMessage?: string;
  isSaving: boolean;
  onEdit: () => void;
  onCancel: () => void;
  onSave: (annotation: Partial<Annotation>) => Promise<void>;
  onDelete?: (annotation: Annotation) => void;
  onClick?: (annotation: { startDate: number; endDate?: number }) => void;
  onResendAlert: (annotationId: number) => void;
  updateChartCallbacks?: UpdateChartCallbacks;
}
type NoteEditProps = Pick<
  NoteProps,
  | 'annotation'
  | 'isSaving'
  | 'onCancel'
  | 'onSave'
  | 'onResendAlert'
  | 'timeZone'
  | 'errorMessage'
  | 'updateChartCallbacks'
>;

export default function Note({
  annotation,
  annotation: {
    title,
    description,
    startDate,
    endDate,
    ownerName,
    canEdit,
    sendAlert,
  },
  timeZone,
  isEditing,
  errorMessage,
  isSaving,
  onEdit,
  onCancel,
  onSave,
  onDelete,
  onClick,
  onResendAlert,
  updateChartCallbacks,
}: NoteProps) {
  const { formatMessage, formatDate, formatTime } = useIntl();
  const resendAlertModal = useDisclosure();

  return (
    <>
      {isEditing ? (
        <NoteEdit
          annotation={annotation}
          timeZone={timeZone}
          errorMessage={errorMessage}
          isSaving={isSaving}
          onCancel={onCancel}
          onSave={onSave}
          onResendAlert={onResendAlert}
          updateChartCallbacks={updateChartCallbacks}
        />
      ) : (
        <Box
          position="relative"
          data-testid={`annotation_title_${annotation.title ?? ''}`}
        >
          <Button
            position="absolute"
            left={0}
            width="100%"
            top={0}
            height="100%"
            bg="transparent"
            border="none"
            _hover={{
              bg: 'transparent',
              border: 'none',
            }}
            _active={{
              bg: 'transparent',
              border: 'none',
            }}
            onClick={
              onClick
                ? () => {
                    onClick({
                      startDate: annotation.startDate ?? 0,
                      endDate: annotation.endDate,
                    });
                  }
                : undefined
            }
          />
          <Box>
            <Text fontSize="md" fontWeight="bold" mb={2}>
              {title}
            </Text>
            <Text fontSize="sm" mb={2} color="gray.700" whiteSpace="pre-wrap">
              {description}
            </Text>
          </Box>
          <Flex mb={4} justify="space-between">
            {startDate && (
              <Box>
                <Text fontSize="xs" mb={0}>
                  <TimeIcon boxSize="14px" mr={1.5} />
                  {formatTime(startDate * 1000, {
                    ...TIME_PRESET_COMPLETE_HOUR12,
                    timeZone,
                  })}
                  ,{' '}
                  {formatDate(startDate * 1000, {
                    ...DATE_PRESET_NARROW_COMPLETE,
                    timeZone,
                  })}
                </Text>
              </Box>
            )}
            {startDate && endDate && (
              <Box>
                <Text fontSize="xs" mb={0} px={1.5}>
                  -
                </Text>
              </Box>
            )}
            {endDate && (
              <Box>
                <Text fontSize="xs" mb={0}>
                  <TimeIcon boxSize="14px" mr={1.5} />
                  {formatTime(endDate * 1000, {
                    ...TIME_PRESET_COMPLETE_HOUR12,
                    timeZone,
                  })}
                  ,{' '}
                  {formatDate(endDate * 1000, {
                    ...DATE_PRESET_NARROW_COMPLETE,
                    timeZone,
                  })}
                </Text>
              </Box>
            )}
          </Flex>

          <Flex justify="space-between">
            {ownerName && (
              <Label
                avatar={<Avatar name={ownerName} size="xs" />}
                primaryText={ownerName}
              />
            )}
            {canEdit && (
              <Flex gap={2}>
                {sendAlert && (
                  <Tooltip hasArrow label="Alert sent">
                    <IconButton
                      onClick={resendAlertModal.onOpen}
                      aria-label="alert sent"
                      icon={<SentIcon />}
                      size="sm"
                    />
                  </Tooltip>
                )}
                <IconButton
                  onClick={onEdit}
                  size="sm"
                  aria-label={formatMessage(MESSAGE_BUTTON_EDIT)}
                  icon={<PencilIcon />}
                />
                {onDelete && (
                  <IconButton
                    onClick={() => onDelete(annotation)}
                    size="sm"
                    aria-label={formatMessage(MESSAGE_BUTTON_DELETE)}
                    colorScheme="red"
                    icon={<BinIcon />}
                  />
                )}
              </Flex>
            )}
          </Flex>
        </Box>
      )}
      <ResendAlertModal
        isSaveAndResend={false}
        isOpen={resendAlertModal.isOpen}
        onCancel={resendAlertModal.onClose}
        onResendAlert={() => {
          resendAlertModal.onClose();
          onResendAlert(annotation.annotationId ?? -1);
        }}
      />
    </>
  );
}

function NoteEdit({
  annotation,
  annotation: { title, description, startDate, endDate },
  timeZone,
  errorMessage,
  isSaving,
  onCancel,
  onResendAlert,
  onSave,
  updateChartCallbacks,
}: NoteEditProps) {
  const { formatMessage } = useIntl();
  const saveAndResendAlertModal = useDisclosure();

  const { formState, getValues, handleSubmit, register, setValue, watch } =
    useForm({
      defaultValues: {
        title,
        description,
        startDate: formatEpochTimeInputValue(
          startDate ?? Date.now() / 1000,
          timeZone
        ),
        endDate: formatEpochTimeInputValue(endDate, timeZone),
        sendAlert: annotation.sendAlert ?? false,
      },
    });

  useEffect(() => {
    if (updateChartCallbacks) {
      setValue('startDate', formatEpochTimeInputValue(startDate, timeZone), {
        shouldValidate: true,
      });
      setValue('endDate', formatEpochTimeInputValue(endDate, timeZone), {
        shouldValidate: true,
      });
    }
  }, [startDate, endDate, timeZone, setValue, updateChartCallbacks]);

  useEffect(() => {
    const subscription = watch((data, { name, type }) => {
      if (name === 'startDate' && type === 'change') {
        const newStartDate =
          data.startDate === ''
            ? undefined
            : moment.tz(data.startDate, timeZone).unix();
        runIfFn(updateChartCallbacks?.updateChartStartDate, newStartDate);
      }
      if (name === 'endDate' && type === 'change') {
        const newEndDate =
          data.endDate === ''
            ? undefined
            : moment.tz(data.endDate, timeZone).unix();
        runIfFn(updateChartCallbacks?.updateChartEndDate, newEndDate);
      }
    });
    return () => subscription.unsubscribe();
  }, [watch, timeZone, updateChartCallbacks]);

  const onSubmit = () => {
    const formValues = getValues();
    return onSave({
      ...annotation,
      ...getValues(),
      startDate:
        formValues.startDate === ''
          ? undefined
          : moment.tz(formValues.startDate, timeZone).unix(),
      endDate:
        formValues.endDate === ''
          ? undefined
          : moment.tz(formValues.endDate, timeZone).unix(),
    });
  };

  return (
    <Box as="form" onSubmit={handleSubmit(onSubmit)}>
      <FormControl mb={2} isInvalid={!!formState.errors.title}>
        <Input
          type="text"
          placeholder="Title"
          {...register('title', { required: 'Please enter a title.' })}
          maxLength={250}
        />
        {formState.errors.title && (
          <FormErrorMessage>{formState.errors.title.message}</FormErrorMessage>
        )}
      </FormControl>
      <FormControl mb={2}>
        <Textarea
          placeholder="Description"
          {...register('description')}
          maxLength={500}
        />
      </FormControl>
      <Wrap spacing={2} mb={2}>
        <WrapItem>
          <FormControl isInvalid={!!formState.errors.startDate}>
            <InputGroup>
              <InputLeftElement pointerEvents="none">
                <TimeIcon color="gray.400" />
              </InputLeftElement>
              <Input
                type="datetime-local"
                {...register('startDate', {
                  required: 'Please enter a start date.',
                })}
              />
            </InputGroup>
            {formState.errors.startDate && (
              <FormErrorMessage>
                {formState.errors?.startDate?.message}
              </FormErrorMessage>
            )}
          </FormControl>
        </WrapItem>
        <WrapItem>
          {watch('endDate') === '' ? (
            <Button
              variant="ghost"
              onClick={() => {
                setValue(
                  'endDate',
                  formatEpochTimeInputValue(Date.now() / 1000, timeZone)
                );
                runIfFn(updateChartCallbacks?.goToSecondPhase);
              }}
            >
              Add end time
            </Button>
          ) : (
            <Flex gap={2}>
              <FormControl>
                <InputGroup>
                  <InputLeftElement pointerEvents="none">
                    <TimeIcon color="gray.400" />
                  </InputLeftElement>
                  <Input type="datetime-local" {...register('endDate')} />
                </InputGroup>
              </FormControl>
              <IconButton
                aria-label="remove end date"
                variant="ghost"
                icon={<CloseIcon boxSize="12px" />}
                onClick={() => {
                  setValue('endDate', '');
                  runIfFn(updateChartCallbacks?.backToFirstPhase);
                }}
              />
            </Flex>
          )}
        </WrapItem>
      </Wrap>
      <SendAlert
        hasAlertBeenSent={
          annotation.annotationId !== undefined && annotation.sendAlert === true
        }
        onResendAlert={saveAndResendAlertModal.onOpen}
        sendAlert={watch('sendAlert')}
        sendAlertSwitchProps={register('sendAlert')}
      />
      {errorMessage && (
        <Box color="red.500" fontSize="sm" mb={2}>
          {errorMessage}
        </Box>
      )}
      <Wrap spacing={2} mb={2}>
        <WrapItem>
          <Button
            variant="solid"
            colorScheme="brand"
            isDisabled={isSaving}
            isLoading={isSaving}
            loadingText={formatMessage(MESSAGE_BUTTON_SAVE)}
            type="submit"
          >
            {formatMessage(MESSAGE_BUTTON_SAVE)}
          </Button>
        </WrapItem>
        <WrapItem>
          <Button variant="outline" onClick={onCancel} isDisabled={isSaving}>
            {formatMessage(MESSAGE_BUTTON_CANCEL)}
          </Button>
        </WrapItem>
      </Wrap>
      <ResendAlertModal
        isOpen={saveAndResendAlertModal.isOpen}
        onCancel={saveAndResendAlertModal.onClose}
        onResendAlert={handleSubmit(async () => {
          saveAndResendAlertModal.onClose();
          await onSubmit();
          onResendAlert(annotation.annotationId ?? -1);
        })}
        isSaveAndResend
      />
    </Box>
  );
}

function formatEpochTimeInputValue(date?: number, timeZone?: string) {
  if (date === undefined) {
    return '';
  }
  return moment
    .unix(date)
    .tz(timeZone || '')
    .format('YYYY-MM-DD[T]HH:mm');
}
