import useSWR from 'swr';
import { HomeModel } from '../types/site';
import { getSitesListForHomePageKey } from './sharedKeys';

export default function useSitesPerformanceOverviewData(insightId?: string) {
  const { data, error, isValidating } = useSWR<HomeModel>(
    getSitesListForHomePageKey(insightId ?? ''),
    null,
    {
      refreshInterval: 5 * 60 * 1000,
    }
  );

  return {
    data,
    error,
    isValidating,
  };
}
