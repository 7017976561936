import {
  DirectionsRendererProps,
  DirectionsRenderer,
  Marker,
  Polyline,
} from '@react-google-maps/api';
import {
  DIRECTIONS_RENDERER_OPTIONS,
  POLYLINE_ICONS,
  WAYPOINT_MARKER_ICON,
} from 'design-system/settings/google-maps';
import { useRef } from 'react';
import { RoutesEditorManager } from './useRoutesEditorManager';
import DirectionsResult = google.maps.DirectionsResult;
import { Route } from '../../types/route';
import { parseWkt, sortedColorsForItems } from '../../utils/routes';

export default function RoutesEditorMapLayer({
  routes,
  routesEditorManager,
  otherRoutesOpacity = 0.6,
}: {
  routes: Route[];
  routesEditorManager: RoutesEditorManager;
  otherRoutesOpacity?: number;
}) {
  const {
    editingRoute,
    googleDirections,
    routeDirections,
    setRouteDirections,
    onGoogleDirectionsChange,
  } = routesEditorManager;
  const isRouteEditable = editingRoute && !editingRoute.monitorStarted;
  const sortedColors = sortedColorsForItems(routes, 'trackId');
  return (
    <>
      {routes &&
        routes.map((route, routeIndex) =>
          !isRouteEditable ||
          routesEditorManager.editingRoute?.routeId !== route.routeId ? (
            <Polyline
              key={route.name}
              options={{
                clickable: false,
                strokeColor: sortedColors[routeIndex],
                strokeWeight: 6,
                icons: POLYLINE_ICONS,
                strokeOpacity:
                  routesEditorManager.editingRoute &&
                  routesEditorManager.editingRoute.routeId !== route.routeId
                    ? otherRoutesOpacity
                    : 1,
                zIndex: 3,
              }}
              path={parseWkt(route.wkt)}
            />
          ) : null
        )}
      {isRouteEditable &&
        !googleDirections &&
        routeDirections &&
        routeDirections.startPoint && (
          <Marker
            label={{
              text: 'A',
              color: 'white',
              fontFamily: 'arial, sans-serif',
              fontSize: '16px',
              fontWeight: 'bold',
            }}
            position={routeDirections.startPoint}
            draggable
            onDragEnd={(event) => {
              if (setRouteDirections && event.latLng) {
                setRouteDirections({
                  ...routeDirections,
                  startPoint: event.latLng.toJSON(),
                });
              }
            }}
          />
        )}
      {isRouteEditable &&
        !googleDirections &&
        routeDirections &&
        routeDirections.endPoint && (
          <Marker
            label={{
              text: 'B',
              color: 'white',
              fontFamily: 'arial, sans-serif',
              fontSize: '16px',
              fontWeight: 'bold',
            }}
            position={routeDirections.endPoint}
            draggable
            onDragEnd={(event) => {
              if (setRouteDirections && event.latLng) {
                setRouteDirections({
                  ...routeDirections,
                  endPoint: event.latLng.toJSON(),
                });
              }
            }}
          />
        )}
      {isRouteEditable &&
        !googleDirections &&
        routeDirections &&
        routeDirections.waypoints.map((wp, wpIndex) => (
          <Marker
            // These items have no unique identifier, therefore, using index as key as per react docs - https://reactjs.org/docs/lists-and-keys.html
            // eslint-disable-next-line
            key={wpIndex}
            icon={WAYPOINT_MARKER_ICON}
            label={{
              text: (wpIndex + 1).toString(),
              color: 'black',
              fontFamily: 'arial, sans-serif',
              fontSize: '11px',
              fontWeight: 'bold',
            }}
            position={wp}
            draggable
            onDragEnd={(event) => {
              if (setRouteDirections && event.latLng) {
                setRouteDirections({
                  ...routeDirections,
                  waypoints: routeDirections.waypoints.map((_wp, _wpIndex) =>
                    wpIndex === _wpIndex ? event.latLng!.toJSON() : _wp
                  ),
                });
              }
            }}
          />
        ))}
      {isRouteEditable && googleDirections && onGoogleDirectionsChange && (
        <DirectionsEditor
          googleDirections={googleDirections}
          onGoogleDirectionsChange={onGoogleDirectionsChange}
        />
      )}
    </>
  );
}

function DirectionsEditor({
  googleDirections,
  onGoogleDirectionsChange,
}: {
  googleDirections: DirectionsResult;
  onGoogleDirectionsChange: (newDirections: DirectionsResult) => void;
}) {
  const lastSetDirectionsRef = useRef(googleDirections);
  const hasDirectionsChanged = useRef(false);
  hasDirectionsChanged.current =
    googleDirections !== lastSetDirectionsRef.current;
  lastSetDirectionsRef.current = googleDirections;

  const directionsRendererRef = useRef<null | DirectionsRendererProps>(null);
  const handleDirectionsRendererLoad = (
    directionsRendererInstance: DirectionsRendererProps
  ): void => {
    directionsRendererRef.current = directionsRendererInstance;
  };
  const handleDirectionsChange = () => {
    if (hasDirectionsChanged.current) {
      hasDirectionsChanged.current = false;
    } else if (
      directionsRendererRef.current &&
      directionsRendererRef.current.directions
    ) {
      onGoogleDirectionsChange(directionsRendererRef.current.directions);
    }
  };

  return (
    googleDirections && (
      <DirectionsRenderer
        directions={googleDirections}
        // It seems onLoad type definition is wrong in the source library.
        // @ts-ignore
        onLoad={handleDirectionsRendererLoad}
        options={DIRECTIONS_RENDERER_OPTIONS}
        onDirectionsChanged={handleDirectionsChange}
      />
    )
  );
}
