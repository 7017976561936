import './AutoCompleteField.scss';
import { ChangeEvent, useEffect, useRef, useState } from 'react';
import FormField from './FormField';
import Spinner from './Spinner';
import { FormFieldOption, FormFieldProps } from '../types/form';
import { errorReport } from '../utils/errors';

export interface AutoCompleteFieldProps {
  fieldProps?: Omit<
    FormFieldProps,
    'value' | 'onChange' | 'onFocus' | 'onBlur' | 'options' | 'multiline'
  >;
  value?: FormFieldProps['value'];
  onChange?: FormFieldProps['onChange'];
  onBlur?: () => void;
  onFocus?: () => void;
  onOptionSelected?: (option: FormFieldOption) => void;
  getOptions: (newValue: string) => Promise<FormFieldOption[]>;
}
export default function AutoCompleteField({
  fieldProps = {},
  value,
  onChange,
  onFocus,
  onBlur,
  onOptionSelected,
  getOptions,
}: AutoCompleteFieldProps) {
  const [options, setOptions] = useState<FormFieldOption[]>([]);
  const [showOptions, setShowOptions] = useState(false);
  const [isFetching, setFetching] = useState(false);
  const [tempValue, setTempValue] = useState(value || '');
  const latestValueRef = useRef(value || '');
  const focusRef = useRef(false);
  const inputRef = useRef<HTMLInputElement>();
  const optionsRef = useRef<HTMLButtonElement[]>([]);
  const handleFocus = () => {
    if (!focusRef.current) {
      if (onFocus) {
        onFocus();
      }
      focusRef.current = true;
    }
  };
  const handleBlur = () => {
    focusRef.current = false;
    setTimeout(() => {
      if (!focusRef.current) {
        setShowOptions(false);
        setTempValue(latestValueRef.current);
        if (onBlur) {
          onBlur();
        }
      }
    });
  };
  useEffect(() => {
    if (value !== undefined && latestValueRef.current !== value) {
      latestValueRef.current = value;
      if (value !== tempValue) {
        setTempValue(value);
      }
    }
  }, [value, tempValue]);
  return (
    <div className="v2-auto-complete-field">
      <FormField
        dataTestId="auto-complete-input"
        {...fieldProps}
        value={tempValue}
        onFocus={handleFocus}
        onBlur={handleBlur}
        onChange={(e: ChangeEvent<HTMLInputElement>) => {
          const newValue = e.currentTarget.value;
          setTempValue(newValue);
          if (newValue.length <= 2) {
            setShowOptions(false);
          } else {
            setFetching(true);
            setShowOptions(true);
            getOptions(newValue)
              .then((updatedOptions) => {
                setOptions(updatedOptions);
                setFetching(false);
              })
              .catch((ex) => {
                errorReport.log(ex);
                setOptions([]);
                setFetching(false);
              });
          }
          if (onChange) {
            onChange(e);
          }
        }}
        elementRef={(element) => {
          inputRef.current = element as HTMLInputElement;
        }}
        onKeyDown={(event) => {
          if (
            event.altKey ||
            event.ctrlKey ||
            event.metaKey ||
            options.length === 0
          ) {
            return;
          }
          switch (event.key) {
            case 'Down': // IE/Edge specific value
            case 'ArrowDown':
              optionsRef.current[0].focus();
              event.preventDefault();
              break;
            default:
          }
        }}
      />
      {showOptions && (
        <div className="v2-auto-complete-field__options">
          {isFetching && (
            <span className="v2-auto-complete-field__spinner">
              <Spinner />
            </span>
          )}
          {options.map((option, optionIndex) => (
            <button
              key={option.value}
              data-testid="auto-complete-option"
              type="button"
              className="v2-auto-complete-field__option-btn"
              onFocus={handleFocus}
              onBlur={handleBlur}
              onClick={(e) => {
                if (onOptionSelected) {
                  onOptionSelected(option);
                }
                e.currentTarget.blur();
              }}
              ref={(element) => {
                if (element) {
                  optionsRef.current[optionIndex] = element;
                }
              }}
              onKeyDown={(event) => {
                if (event.altKey || event.ctrlKey || event.metaKey) {
                  return;
                }
                switch (event.key) {
                  case 'Down': // IE/Edge specific value
                  case 'ArrowDown':
                    if (optionIndex + 1 < options.length) {
                      optionsRef.current[optionIndex + 1].focus();
                      event.preventDefault();
                    }
                    break;
                  case 'Up': // IE/Edge specific value
                  case 'ArrowUp':
                    if (optionIndex === 0 && inputRef.current) {
                      inputRef.current.focus();
                      event.preventDefault();
                    } else {
                      optionsRef.current[optionIndex - 1].focus();
                      event.preventDefault();
                    }
                    break;
                  default:
                }
              }}
            >
              {option.label}
            </button>
          ))}
        </div>
      )}
    </div>
  );
}
