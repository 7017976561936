import Card from 'design-system/atoms/card';

export default function AnnotationCard({
  inTimeWindow = true,
  children,
}: {
  inTimeWindow?: boolean;
  children: React.ReactNode;
}) {
  return (
    <Card
      p={4}
      mb={4}
      layerStyle="floating"
      bgColor={inTimeWindow ? 'white' : 'gray.100'}
    >
      {children}
    </Card>
  );
}
