import { components } from '../../types/schema';

export type OnboardStatus = components['schemas']['OnboardStatus'];
export type CreateAccountParams = components['schemas']['CreateAccount'];
export type PhoneNumber =
  components['schemas']['MFAPhoneNumber']['phoneNumber'];
export type MFACode = components['schemas']['MFACode']['code'];

export const INVALID: OnboardStatus = {
  userStatus: 'INVALID',
  valid: false,
  roles: [],
};
