import { Marker, Polyline, InfoWindow } from '@react-google-maps/api';
import { renderToStaticMarkup } from 'react-dom/server';
import { FunctionComponent, useRef, useState } from 'react';
import { LatLng } from '@lib/gis';
import { Heading, Text, VStack, useOutsideClick } from '@chakra-ui/react';
import { useIntl } from 'react-intl';
import MapIconRoadClosure from '../../svg/map-icon-road-closure.svg?react';
import MapIconQuestion from '../../svg/map-icon-question.svg?react';
import MapIconAccident from '../../svg/map-icon-accident.svg?react';
import MapIconRoadWorks from '../../svg/map-icon-road-works.svg?react';
import MapIconDangerousConditions from '../../svg/map-icon-dangerous-conditions.svg?react';
import MapIconFog from '../../svg/map-icon-fog.svg?react';
import MapIconWeather from '../../svg/map-icon-weather.svg?react';
import MapIconRain from '../../svg/map-icon-rain.svg?react';
import MapIconIce from '../../svg/map-icon-ice.svg?react';
import MapIconPlannedEvent from '../../svg/map-icon-planned-event.svg?react';
import MapIconWind from '../../svg/map-icon-wind.svg?react';
import MapIconFlooding from '../../svg/map-icon-flooding.svg?react';
import MapIconBrokenDownVehicle from '../../svg/map-icon-broken-down-vehicle.svg?react';
import MapIconLaneClosed from '../../svg/map-icon-lane-closed.svg?react';

import Point = google.maps.Point;

export type IncidentType =
  | 'Other'
  | 'DangerousConditions'
  | 'Accident'
  | 'Fog'
  | 'Weather'
  | 'Rain'
  | 'Ice'
  | 'Jam'
  | 'RoadClosed'
  | 'RoadWorks'
  | 'PlannedEvent'
  | 'Wind'
  | 'Flooding'
  | 'BrokenDownVehicle'
  | 'LaneClosed';

export type Coordinate = [number, number];
export type LineCoordinate = Coordinate[];
export type MultiLineCoordinate = LineCoordinate[];

export type GeoJSON = {
  type: 'Point' | 'Polygon' | 'LineString' | 'MultiPoint' | 'MultiLineString';
  coordinates: Coordinate | LineCoordinate | MultiLineCoordinate;
};

export type Incident = {
  id: string;
  geometry: GeoJSON;
  type: IncidentType;
  datasource: string;
  description: string;
  startTime: number;
  endTime?: number;
};

export interface MapLayerIncidentsProps {
  incidents?: Incident[];
}

const getIconString = (IconElement: FunctionComponent) => {
  const mapIconString = renderToStaticMarkup(<IconElement />);
  const mapIconDataUri = `data:image/svg+xml,${encodeURIComponent(
    mapIconString
  )}`;
  return mapIconDataUri;
};

const icons: Record<IncidentType, string> = {
  RoadClosed: getIconString(MapIconRoadClosure),
  Accident: getIconString(MapIconAccident),
  RoadWorks: getIconString(MapIconRoadWorks),
  DangerousConditions: getIconString(MapIconDangerousConditions),
  Fog: getIconString(MapIconFog),
  Weather: getIconString(MapIconWeather),
  Rain: getIconString(MapIconRain),
  Ice: getIconString(MapIconIce),
  PlannedEvent: getIconString(MapIconPlannedEvent),
  Wind: getIconString(MapIconWind),
  Flooding: getIconString(MapIconFlooding),
  BrokenDownVehicle: getIconString(MapIconBrokenDownVehicle),
  LaneClosed: getIconString(MapIconLaneClosed),
  Other: getIconString(MapIconQuestion),
  Jam: getIconString(MapIconQuestion),
};

export function getIncidentsNearby({
  incident,
  incidents,
  point,
}: {
  incident: Incident;
  incidents: Incident[];
  point: google.maps.LatLng | google.maps.LatLngLiteral;
}): Incident[] {
  const incidentsNearby = [
    incident,
    ...incidents.filter(
      (testingInc) =>
        testingInc !== incident &&
        google.maps.geometry.poly.isLocationOnEdge(
          point,
          new google.maps.Polyline({
            path: (testingInc.geometry.coordinates as LineCoordinate).map(
              (coordinate) => ({
                lat: coordinate[1],
                lng: coordinate[0],
              })
            ),
          })
        )
    ),
  ];
  return incidentsNearby;
}

export default function MapLayerIncidents({
  incidents,
}: MapLayerIncidentsProps) {
  const [showIncidentsNearBy, setShowIncidentsNearBy] = useState<{
    position: LatLng;
    incidents: Incident[];
  }>();
  const divRef = useRef<HTMLDivElement>(null);
  useOutsideClick({
    ref: divRef,
    handler: () => setShowIncidentsNearBy(undefined),
  });
  const { formatDateTimeRange } = useIntl();
  if (!incidents) {
    return null;
  }
  return (
    <>
      {incidents.map((incident) => {
        return (
          <>
            <Marker
              position={{
                lat: (incident.geometry.coordinates as LineCoordinate)[0][1],
                lng: (incident.geometry.coordinates as LineCoordinate)[0][0],
              }}
              icon={{
                url: icons[incident.type],
                anchor: new Point(8, 8),
              }}
              onClick={(e) => {
                if (!e.latLng) {
                  return;
                }
                const incidentsNearby = getIncidentsNearby({
                  incident,
                  incidents,
                  point: e.latLng,
                });
                incidentsNearby.sort((a, b) =>
                  a.startTime > b.startTime ? 1 : -1
                );
                setShowIncidentsNearBy({
                  position: { lat: e.latLng.lat(), lng: e.latLng.lng() },
                  incidents: incidentsNearby,
                });
              }}
            />
            <Polyline
              options={{
                strokeColor: 'red',
                strokeWeight: 3,
              }}
              path={(incident.geometry.coordinates as LineCoordinate).map(
                (coordinate) => ({
                  lat: coordinate[1],
                  lng: coordinate[0],
                })
              )}
            />
          </>
        );
      })}
      {showIncidentsNearBy && (
        <InfoWindow position={showIncidentsNearBy.position}>
          <VStack m="10px" spacing={2} align="start" ref={divRef}>
            {showIncidentsNearBy.incidents.map((incident) => (
              <VStack alignItems="flex-start" spacing={1} key={incident.id}>
                <Heading size="sm"> {incident.type}</Heading>
                <Text as="span"> {incident.description}</Text>
                <Text as="span">
                  {' '}
                  From:{' '}
                  {formatDateTimeRange(
                    new Date(incident.startTime),
                    incident.endTime ? new Date(incident.endTime) : new Date(),
                    { timeStyle: 'short', dateStyle: 'medium' }
                  )}{' '}
                </Text>
              </VStack>
            ))}
          </VStack>
        </InfoWindow>
      )}
    </>
  );
}
