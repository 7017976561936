import { Box, Button, HStack, VStack } from '@chakra-ui/react';
import { ReactNode, useState } from 'react';
import { FormattedMessage } from 'react-intl';

interface EditableQuoteProps {
  title?: ReactNode;
  quoteTitle?: ReactNode;
  quoteContent?: ReactNode;
  renderEditComponent: (props: { finishEditing: () => void }) => ReactNode;
}

export default function EditableQuote({
  title,
  quoteTitle,
  quoteContent,
  renderEditComponent,
}: EditableQuoteProps) {
  const [isEditing, setIsEditing] = useState(false);
  const handleEditClick = () => {
    setIsEditing(true);
  };
  const handleFinishEditing = () => {
    setIsEditing(false);
  };

  return (
    <Box width="full">
      {isEditing ? (
        renderEditComponent({ finishEditing: handleFinishEditing })
      ) : (
        <VStack alignItems="flex-start" spacing={4} fontSize="sm">
          {title && <Box>{title}</Box>}
          {(quoteTitle || quoteContent) && (
            <HStack alignItems="stretch" spacing={4}>
              <Box width="4px" backgroundColor="gray.300" borderRadius="2px" />
              <Box>
                {quoteTitle && <Box>{quoteTitle}</Box>}
                {quoteContent && (
                  <Box textColor="gray.500">{`"${quoteContent}"`}</Box>
                )}
              </Box>
            </HStack>
          )}
          <Button onClick={handleEditClick} colorScheme="greenDark" size="sm">
            <FormattedMessage id="nKGBQr" defaultMessage="Edit comment" />
          </Button>
        </VStack>
      )}
    </Box>
  );
}
