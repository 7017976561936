import {
  Box,
  Button,
  Checkbox,
  FormControl,
  FormErrorMessage,
  FormHelperText,
  Input,
  Link,
  SimpleGrid,
  Text,
} from '@chakra-ui/react';

import { useForm } from 'react-hook-form';
import { FormattedMessage, useIntl } from 'react-intl';
import { CreateAccountParams } from '../../api/onboard/constants';

interface FormValues {
  firstName: string;
  lastName: string;
  password: string;
  confirmPassword: string;
  acceptedTerms: boolean;
}

interface Props {
  email: string;
  handleCreateAccount: (params: CreateAccountParams) => Promise<void>;
}

const CreateAccount = ({ email, handleCreateAccount }: Props) => {
  const { formatMessage } = useIntl();
  const {
    register,
    getValues,
    formState: { errors, isSubmitting, isValid },
    handleSubmit,
  } = useForm({
    mode: 'onChange',
    defaultValues: {
      firstName: '',
      lastName: '',
      password: '',
      confirmPassword: '',
      acceptedTerms: false,
    },
  });

  const checkConfirmPassword = (
    confirmPassword: FormValues['confirmPassword']
  ) => {
    const formValues = getValues();
    if (confirmPassword && confirmPassword !== formValues.password) {
      return false;
    }
    return true;
  };

  const onSubmit = async (values: FormValues) => {
    await handleCreateAccount({
      email,
      firstName: values.firstName,
      lastName: values.lastName,
      password: values.password,
      confirmPassword: values.confirmPassword,
      agreedTerms: values.acceptedTerms,
    });
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Box mb={10}>
        <Text fontSize="2xl">
          <FormattedMessage
            defaultMessage="Lets get started by creating an account"
            id="LtXUdo"
            description="Create an account"
          />
        </Text>
      </Box>
      <FormControl mb={8}>
        <Input
          focusBorderColor="green.500"
          type="email"
          disabled
          placeholder="Email"
          value={email}
        />
        {errors?.lastName && (
          <FormErrorMessage>{errors.lastName.message}</FormErrorMessage>
        )}
      </FormControl>
      <SimpleGrid columns={{ base: 1, sm: 2 }} spacingX={4} width="100%">
        <FormControl mb={8} isInvalid={!!errors.firstName}>
          <Input
            focusBorderColor="green.500"
            type="text"
            maxLength={35}
            placeholder="First name"
            {...register('firstName', {
              required: formatMessage({
                defaultMessage: 'Please enter first name',
                id: 'uBY5EC',
                description: 'Error message for first name',
              }),
            })}
          />
          {errors?.firstName && (
            <FormErrorMessage>{errors.firstName.message}</FormErrorMessage>
          )}
        </FormControl>
        <FormControl mb={8} isInvalid={!!errors.lastName}>
          <Input
            focusBorderColor="green.500"
            type="text"
            maxLength={35}
            placeholder="Last name"
            {...register('lastName', {
              required: formatMessage({
                defaultMessage: 'Please enter last name',
                id: '8um9H0',
                description: 'Error message for last name',
              }),
            })}
          />
          {errors?.lastName && (
            <FormErrorMessage>{errors.lastName.message}</FormErrorMessage>
          )}
        </FormControl>
      </SimpleGrid>
      <FormControl mb={8} isInvalid={!!errors.password}>
        <Input
          focusBorderColor="green.500"
          type="password"
          autoComplete="new-password"
          id="new-password"
          placeholder={formatMessage({
            defaultMessage: 'Password',
            id: 'YXIKM7',
            description: 'Password email input placeholder',
          })}
          {...register('password', {
            required: formatMessage({
              defaultMessage: 'Please enter password',
              id: 'TIrT2w',
              description: 'Error message for password',
            }),
          })}
        />
        <FormHelperText>
          {formatMessage({
            defaultMessage:
              'Must have at least 8 characters, a lowercase letter, an uppercase letter, a number, and no parts of your email.',
            id: 'Mq+9D/',
            description: 'create account password input helper text',
          })}
        </FormHelperText>
        {errors?.password && (
          <FormErrorMessage>{errors.password.message}</FormErrorMessage>
        )}
      </FormControl>
      <FormControl mb={8} isInvalid={!!errors.confirmPassword}>
        <Input
          focusBorderColor="green.500"
          type="password"
          autoComplete="new-password"
          placeholder={formatMessage({
            defaultMessage: 'Confirm password',
            id: 'ypxIsX',
            description: 'Confirm password email input placeholder',
          })}
          {...register('confirmPassword', {
            required: formatMessage({
              defaultMessage: 'Please enter confirm password',
              id: 'GJYY+y',
              description: 'Error message for confirm password',
            }),
            validate: {
              checkConfirmPassword,
            },
          })}
        />
        {errors.confirmPassword && (
          <FormErrorMessage>{errors.confirmPassword.message}</FormErrorMessage>
        )}
        {errors.confirmPassword &&
          errors.confirmPassword.type === 'checkConfirmPassword' && (
            <FormErrorMessage>
              {formatMessage({
                defaultMessage: 'Please make sure your password matches',
                id: 'ElIH9F',
                description: 'Error message for confirm password',
              })}
            </FormErrorMessage>
          )}
      </FormControl>
      <FormControl mb={8} isInvalid={!!errors.acceptedTerms}>
        <Checkbox
          colorScheme="green"
          {...register('acceptedTerms', {
            required: true,
          })}
        >
          I agree to the{' '}
          <Link isExternal href="http://mooven.com/terms">
            Terms of Service
          </Link>{' '}
          and{' '}
          <Link isExternal href="http://mooven.com/privacy">
            Privacy Statement
          </Link>
        </Checkbox>
        <FormErrorMessage mt={0}>
          {formatMessage({
            defaultMessage: 'Please check agreement',
            id: 'XdQlPb',
            description: 'Error message for agreement',
          })}
        </FormErrorMessage>
      </FormControl>
      <Button
        isLoading={isSubmitting}
        variant="solid"
        colorScheme="brand"
        type="submit"
        isDisabled={!isValid || isSubmitting}
      >
        <FormattedMessage
          defaultMessage="Create account"
          id="4dLjm3"
          description="Create account"
        />
      </Button>
    </form>
  );
};

export default CreateAccount;
