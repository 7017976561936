import type { TeamUser } from '@webapp/bff';

export function getUserAlertsSettings(user: TeamUser, siteAlertsSettings) {
  const userSettings = siteAlertsSettings.find(
    (_userSettings) => _userSettings.userId === user.userId
  );
  return userSettings?.alerts || {};
}
export function canUserEditSite(user: TeamUser, siteId: number) {
  return user.sites.some(
    (site) => site.siteId === siteId.toString() && site.siteRole === 'Editor'
  );
}
