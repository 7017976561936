import { useIntl } from 'react-intl';
import { VehicleCountSummary } from '@data-pipeline/api/src/types/vehicle-count';
import classNames from 'classnames';
import CheckBoxSvg, { CheckboxShape } from '../CheckBoxSvg';
import './VehicleCountItem.scss';
import { DATE_PRESET_SHORT_D_M_Y } from '../../utils/dateUtils';

interface VehicleCountItemProps {
  source: VehicleCountSummary;
  selected: boolean;
  focused: boolean;
  setSelectedSourceId: (id: number) => void;
  setFocusedSourceId: (id: number | null) => void;
}

export function VehicleCountItem({
  source,
  selected,
  focused,
  setSelectedSourceId,
  setFocusedSourceId,
}: VehicleCountItemProps) {
  const { formatDate } = useIntl();
  return (
    <button
      type="button"
      data-testid="vehicle-count-source-selector"
      onMouseEnter={() => setFocusedSourceId(source.id)}
      onFocus={() => setFocusedSourceId(source.id)}
      onMouseLeave={() => setFocusedSourceId(null)}
      onBlur={() => setFocusedSourceId(null)}
      onClick={() => setSelectedSourceId(source.id)}
      className={classNames('v2-vehicle-count-item', {
        'v2-vehicle-count-item--selected': selected,
        'v2-vehicle-count-item--focused': focused,
      })}
    >
      <div>
        <div className="v2-vehicle-count-item__checkbox">
          <CheckBoxSvg
            checked={selected}
            checkBoxShape={CheckboxShape.CIRCLE}
          />
          <span className="v2-vehicle-count-item__header">{source.name}</span>
        </div>
        <div className="v2-vehicle-count-item__text">{`ID:${source.externalId}`}</div>
        <div className="v2-vehicle-count-item__text">{`${
          source.directions?.length ?? 0
        } Directions`}</div>
        {source.availableStartDate && source.availableEndDate && (
          <div className="v2-vehicle-count-item__text">
            {'Data available '}
            <span className="v2-vehicle-count-item__text--bold">
              {formatDate(source.availableStartDate * 1000, {
                timeZone: 'UTC',
                ...DATE_PRESET_SHORT_D_M_Y,
              })}
            </span>
            {' to '}
            <span className="v2-vehicle-count-item__text--bold">
              {formatDate(source.availableEndDate * 1000, {
                timeZone: 'UTC',
                ...DATE_PRESET_SHORT_D_M_Y,
              })}
            </span>
          </div>
        )}
      </div>
    </button>
  );
}
