import { useState } from 'react';
import { Box, Button, HStack, Text, VStack } from '@chakra-ui/react';
import { FormattedMessage, useIntl } from 'react-intl';
import {
  LatLng,
  pointArrayToGeoJsonPolygonCoordinates,
  pointArrayToWkt,
} from '@lib/gis';
import { area, polygon } from '@turf/turf';
import { DisruptionAreaEditorManager } from './useDisruptionAreaEditorManager';
import { useFailureToast } from '../../hooks/useFailureToast';
import { useSuccessToast } from '../../hooks/useSuccessToast';
import { errorReport } from '../../utils/errors';

export default function DisruptionAreaEditorPanel({
  disruptionAreaEditorManager,
  saveDisruptionArea,
}: {
  disruptionAreaEditorManager: DisruptionAreaEditorManager;
  saveDisruptionArea: (wkt: string) => Promise<void>;
}) {
  const {
    polygonCreatorManager: { isCreating, startCreating, stopCreating },
    disruptionAreaState,
    resetDisruptionArea,
    hasWorkAreas,
    hasUnsavedChanges,
    isMatchWorkAreas,
  } = disruptionAreaEditorManager;

  const [isSaving, setIsSaving] = useState(false);
  const failureToast = useFailureToast();
  const successToast = useSuccessToast();
  const { formatMessage } = useIntl();
  const onSave = async () => {
    if (isSaving || !disruptionAreaState || disruptionAreaState.length <= 2) {
      return;
    }
    setIsSaving(true);
    try {
      const wkt = pointArrayToWkt(disruptionAreaState);
      await saveDisruptionArea(wkt);
      successToast({
        title: formatMessage({
          defaultMessage: 'Disruption area saved successfully.',
          id: 'i4FJpL',
        }),
      });
    } catch (e) {
      errorReport.handled(e, {
        action: 'save disruption area',
      });
      failureToast({
        title: formatMessage({
          defaultMessage:
            'Something went wrong. Please try again or contact us.',
          id: 'Q9mJ7R',
        }),
      });
    } finally {
      setIsSaving(false);
    }
  };

  if (isCreating || !disruptionAreaState) {
    return (
      <Box>
        <Text fontSize="xs">
          <FormattedMessage
            id="bVNDf+"
            defaultMessage="Selecting your disruption area will allow us to give you context of potential local business and transport disruption"
          />
        </Text>
        {isCreating ? (
          <Button onClick={stopCreating} variant="outline" size="xs">
            <FormattedMessage id="4P6d6V" defaultMessage="Cancel drawing" />
          </Button>
        ) : (
          <Button
            onClick={startCreating}
            variant="solid"
            colorScheme="greenDark"
            size="sm"
          >
            <FormattedMessage id="g2dK3U" defaultMessage="Define area" />
          </Button>
        )}
      </Box>
    );
  }

  return (
    <VStack spacing={4} alignItems="left">
      <Text fontSize="xs" m="0">
        {isMatchWorkAreas && (
          <FormattedMessage
            id="1rpFdi"
            defaultMessage="We’ve automatically defined a disruption area based on your work area. "
          />
        )}
        <FormattedMessage
          id="kalo7x"
          defaultMessage="You can edit this area by dragging the circle handles on the map, or you can draw a new area."
        />
      </Text>
      <Text fontSize="xs" m={0}>
        <FormattedMessage
          defaultMessage="Total area: <strong>{siteArea}m²</strong>"
          id="6LvqpJ"
          values={{
            siteArea: getPolygonArea(disruptionAreaState),
          }}
        />
      </Text>
      <HStack spacing={4}>
        <Button
          onClick={startCreating}
          isDisabled={isSaving}
          variant="solid"
          colorScheme="greenDark"
          size="xs"
        >
          <FormattedMessage id="pnhDfh" defaultMessage="Draw new area" />
        </Button>
        {hasWorkAreas && (
          <Button
            onClick={resetDisruptionArea}
            isDisabled={isSaving || isMatchWorkAreas}
            variant="outline"
            size="xs"
          >
            <FormattedMessage id="JCLv8a" defaultMessage="Reset to work area" />
          </Button>
        )}
      </HStack>
      <Box>
        <Button
          onClick={onSave}
          isDisabled={isSaving || !hasUnsavedChanges}
          variant="solid"
          colorScheme="greenDark"
          size="sm"
        >
          <FormattedMessage id="Osk3vj" defaultMessage="Save disruption area" />
        </Button>
      </Box>
    </VStack>
  );
}

function getPolygonArea(points: LatLng[]) {
  const polygonCoordinates = pointArrayToGeoJsonPolygonCoordinates(points);
  const tPolygon = polygon(polygonCoordinates);
  const siteArea = area(tPolygon);
  return Math.round(siteArea);
}
