import {
  Box,
  Button,
  Divider,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  useToken,
} from '@chakra-ui/react';
import { ReactNode } from 'react';
import { useIntl } from 'react-intl';
import SpinnerSuspense from '../../atoms/spinner-suspense';

export interface SiteAccessSettingModalProps {
  isOpen: boolean;
  onClose: () => void;
  children: ReactNode;
}

export default function SiteAccessSettingModal({
  isOpen,
  onClose,
  children,
}: SiteAccessSettingModalProps) {
  const maxWidth = useToken('breakpoints', 'md');
  const { formatMessage } = useIntl();
  return (
    <Modal
      closeOnOverlayClick={false}
      isCentered
      isOpen={isOpen}
      onClose={onClose}
      scrollBehavior="inside"
      size="full"
    >
      <ModalOverlay background="white" />
      <ModalContent
        border="none"
        boxShadow="none"
        marginBottom={{ base: 0, md: 10 }}
        marginTop={{ base: 10, md: 40 }}
        maxWidth={maxWidth}
      >
        <ModalHeader data-testid="site-access-settings-header">
          {formatMessage({
            id: 'mXeZ8B',
            description: 'Site access settings modal title',
            defaultMessage: 'Site user access settings',
          })}
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <SpinnerSuspense>{children}</SpinnerSuspense>
        </ModalBody>
        <ModalFooter flexDirection="column">
          <Divider />
          <Box paddingTop={4} width="100%">
            <Button onClick={onClose} variant="outline">
              Close
            </Button>
          </Box>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}
