export enum WeekDaysSelection {
  ALL_DAYS = 'ALL_DAYS',
  WEEKEND = 'WEEKEND',
  WEEKDAYS = 'WEEKDAYS',
  OTHER = 'OTHER',
}

const WEEKEND_INDEX = [0, 6];
const WEEKDAY_INDEX = [1, 2, 3, 4, 5];

export function getWeekDaySelectionCategory(
  weekDaysIndexArray: number[]
): WeekDaysSelection {
  if (weekDaysIndexArray.length === 7) {
    return WeekDaysSelection.ALL_DAYS;
  }
  if (
    weekDaysIndexArray.length === 5 &&
    weekDaysIndexArray.every(
      (weekdayIndex) => !WEEKEND_INDEX.includes(weekdayIndex)
    )
  ) {
    return WeekDaysSelection.WEEKDAYS;
  }
  if (
    weekDaysIndexArray.length === 2 &&
    weekDaysIndexArray.every(
      (weekdayIndex) => !WEEKDAY_INDEX.includes(weekdayIndex)
    )
  ) {
    return WeekDaysSelection.WEEKEND;
  }
  return WeekDaysSelection.OTHER;
}
