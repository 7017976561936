import { ReactNode } from 'react';
import {
  Box,
  Flex,
  FormControl,
  FormLabel,
  Input,
  Switch,
  InputGroup,
  InputRightElement,
  IconButton,
  InputRightAddon,
} from '@chakra-ui/react';
import { FormattedMessage } from 'react-intl';
import { SmallCloseIcon } from '@chakra-ui/icons';
import { PlanConstraints } from '../../types/plan';

const MINUTES_15_IN_SECONDS = 60 * 15;

export default function PlanConstraintsControls({
  constraints,
  setConstraints,
  kpiUnitLabel,
}: {
  constraints: PlanConstraints;
  setConstraints: (updatedConstraints: PlanConstraints) => void;
  kpiUnitLabel?: string;
}) {
  const fieldChangeHandler = (fieldName) => (e) => {
    setConstraints({
      ...constraints,
      [fieldName]: e.target.value,
    });
  };
  return (
    <Box padding={5}>
      <FormControl>
        <Flex alignItems="center" mb="30px">
          <FormLabel mb={0} fontSize="18px" fontWeight="bold" flex={1}>
            <FormattedMessage
              defaultMessage="Work constraints"
              id="KT2p2U"
              description="Plan constraints form: start time label"
            />
          </FormLabel>
          <Switch
            colorScheme="greenDark"
            isChecked={constraints.showConstraints}
            onChange={(e) => {
              setConstraints({
                ...constraints,
                showConstraints: e.target.checked,
              });
            }}
          />
        </Flex>
      </FormControl>
      <FormRow
        label={
          <FormattedMessage
            defaultMessage="Start time"
            id="26o1nI"
            description="Plan constraints form: start time label"
          />
        }
        control={
          <InputGroup size="sm">
            <Input
              type="time"
              step={MINUTES_15_IN_SECONDS}
              value={constraints.startTime}
              onChange={fieldChangeHandler('startTime')}
              disabled={!constraints.showConstraints}
            />
            {constraints.showConstraints && constraints.startTime !== '' && (
              <InputRightElement>
                <IconButton
                  variant="ghost"
                  aria-label="clear"
                  size="xs"
                  onClick={() => {
                    setConstraints({
                      ...constraints,
                      startTime: '',
                    });
                  }}
                  icon={<SmallCloseIcon />}
                />
              </InputRightElement>
            )}
          </InputGroup>
        }
      />
      <FormRow
        label={
          <FormattedMessage
            defaultMessage="End time"
            id="oC0bA4"
            description="Plan constraints form: end time label"
          />
        }
        control={
          <InputGroup size="sm">
            <Input
              type="time"
              step={MINUTES_15_IN_SECONDS}
              value={constraints.endTime}
              onChange={fieldChangeHandler('endTime')}
              disabled={!constraints.showConstraints}
            />
            {constraints.showConstraints && constraints.endTime !== '' && (
              <InputRightElement>
                <IconButton
                  variant="ghost"
                  aria-label="clear"
                  size="xs"
                  onClick={() => {
                    setConstraints({
                      ...constraints,
                      endTime: '',
                    });
                  }}
                  icon={<SmallCloseIcon />}
                />
              </InputRightElement>
            )}
          </InputGroup>
        }
      />
      <FormRow
        label={
          <FormattedMessage
            defaultMessage="KPI"
            id="LQqXUs"
            description="Plan constraints form: kpi label"
          />
        }
        control={
          <InputGroup size="sm">
            <Input
              type="number"
              value={constraints.kpi ?? ''}
              onChange={fieldChangeHandler('kpi')}
              disabled={!constraints.showConstraints}
            />
            {kpiUnitLabel && (
              <InputRightAddon
                backgroundColor="white"
                opacity={constraints.showConstraints ? 1 : 0.3}
              >
                {kpiUnitLabel}
              </InputRightAddon>
            )}
          </InputGroup>
        }
      />
    </Box>
  );
}

function FormRow({ label, control }: { label: ReactNode; control: ReactNode }) {
  return (
    <FormControl>
      <Flex alignItems="center" mb={2}>
        <FormLabel mb={0} fontSize="13px" width="80px">
          {label}
        </FormLabel>
        <Box flex={1}>{control}</Box>
      </Flex>
    </FormControl>
  );
}
