import { useContext } from 'react';
import { SplitContext, useTreatments } from '@splitsoftware/splitio-react';
import useCurrentUserData from '../data/useCurrentUserData';
import { errorReport } from '../utils/errors';

export enum FeatureSwitchState {
  FETCHING = 'FETCHING',
  OFF = 'OFF',
  ON = 'ON',
}

export default function useFeatureSwitch(featureName) {
  const treatment = useTreatment(featureName);
  switch (treatment) {
    case 'on':
      return FeatureSwitchState.ON;
    case 'off':
      return FeatureSwitchState.OFF;
    case FeatureSwitchState.FETCHING:
      return FeatureSwitchState.FETCHING;
    default:
      errorReport.critical({
        error: new Error(
          `Feature treatment for "${featureName}" returned unexpected value "${treatment}"`
        ),
      });
      return FeatureSwitchState.OFF;
  }
}

function useTreatment(featureName) {
  const currentUser = useCurrentUserData();
  const treatments = useTreatments([featureName], {
    userId: currentUser.data?.email || '',
  });
  const { isReady } = useContext(SplitContext);
  if (!isReady) {
    return FeatureSwitchState.FETCHING;
  }
  return treatments[featureName]?.treatment;
}
