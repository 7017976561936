import { Box, FormControl, Input } from '@chakra-ui/react';
import moment from 'moment';
import { useState } from 'react';
import { useIntl } from 'react-intl';
import ConfirmationModal from 'design-system/molecules/confirmation-modal';

export interface ComparisonDateSelectionModalProps {
  dateStr: string;
  onConfirm: (dateStr: string) => void;
  onCancel: () => void;
  isOpen: boolean;
}

export default function ComparisonDateSelectionModal({
  dateStr,
  onConfirm,
  onCancel,
  isOpen,
}: ComparisonDateSelectionModalProps) {
  const { formatMessage } = useIntl();
  const [dateStrState, setDateStrState] = useState(dateStr);
  const [validationMessage, setValidationMessage] = useState<string>();
  return (
    <ConfirmationModal
      body={
        <>
          <FormControl>
            <Input
              type="date"
              value={dateStrState}
              onChange={(e) => {
                if (validationMessage) {
                  setValidationMessage(undefined);
                }
                setDateStrState(e.target.value);
              }}
            />
          </FormControl>
          {validationMessage && (
            <Box color="red.500" fontSize="sm" mt={2}>
              {validationMessage}
            </Box>
          )}
        </>
      }
      confirmButtonText={formatMessage({
        description:
          'View time selection - comparison date popup confirm button label',
        defaultMessage: 'Set date',
        id: 'RmpqoS',
      })}
      onCancel={onCancel}
      onConfirm={() => {
        if (!moment(dateStrState).isValid()) {
          setValidationMessage(
            formatMessage({
              defaultMessage: 'Start date not valid.',
              id: 'I2fw8V',
              description:
                'Time period selector: Validation message for invalid start date',
            })
          );
        } else {
          onConfirm(dateStrState);
        }
      }}
      open={isOpen}
      title={formatMessage({
        description: 'View time selection - comparison date popup title',
        defaultMessage: 'Select a date for comparison',
        id: 'trBBbz',
      })}
    />
  );
}
