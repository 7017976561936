import { useIntl } from 'react-intl';
import ConfirmationModal from 'design-system/molecules/confirmation-modal';

export interface ResendAlertModalProps {
  isOpen: boolean;
  onCancel: () => void;
  onResendAlert: () => void;
  isSaveAndResend: boolean;
}

export default function ResendAlertModal({
  isOpen,
  onCancel,
  onResendAlert,
  isSaveAndResend,
}: ResendAlertModalProps) {
  const { formatMessage } = useIntl();
  return (
    <ConfirmationModal
      body={formatMessage({
        defaultMessage:
          'An alert has already been sent for this annotation. Are you sure you want to resend it?',
        id: '3wgP9U',
      })}
      title={formatMessage({
        defaultMessage: 'Resend alert?',
        id: 'Jf+0Q7',
      })}
      confirmButtonText={
        isSaveAndResend
          ? formatMessage({
              defaultMessage: 'Save and resend alert',
              id: 'Wl47l5',
            })
          : formatMessage({
              defaultMessage: 'Resend alert',
              id: 'exCYu1',
            })
      }
      open={isOpen}
      onConfirm={onResendAlert}
      onCancel={onCancel}
    />
  );
}
