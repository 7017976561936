import { Text } from '@chakra-ui/react';

import { IntlShape, useIntl } from 'react-intl';

export interface PermissionsProps {
  user: {
    canEdit?: boolean;
    owner?: boolean;
  };
  rawText?: boolean;
}

export default function Permissions({
  user,
  rawText = false,
}: PermissionsProps) {
  const { formatMessage } = useIntl();
  const permissionsText = getPermissionText(user, formatMessage);
  if (rawText) {
    return <>{permissionsText}</>;
  }
  return (
    <Text color="gray.600" fontSize="xs" marginLeft={10}>
      {permissionsText}
    </Text>
  );
}

function getPermissionText(
  user: PermissionsProps['user'],
  formatMessage: IntlShape['formatMessage']
) {
  if (user.owner) {
    return formatMessage({
      id: 'vLpc+O',
      description: 'Permissions owner',
      defaultMessage: 'Owner',
    });
  }
  if (user.canEdit) {
    return formatMessage({
      id: 'NWMhKG',
      description: 'Permissions can edit',
      defaultMessage: 'Can edit',
    });
  }
  return formatMessage({
    id: 'leXXhn',
    description: 'Permissions can view',
    defaultMessage: 'Can view',
  });
}
