import { InfoWindow, Marker } from '@react-google-maps/api';
import { VehicleCountSummary } from '@data-pipeline/api/src/types/vehicle-count';
import sum from 'lodash/sum';
import { useIntl } from 'react-intl';
import countSourceSelected from '../../svg/count-source-selected.svg?inline';
import countSource from '../../svg/count-source.svg?inline';
import { CountSourceIdProps } from '../../types/sharedProps';
import './CountSourceMarker.scss';

interface Props extends CountSourceIdProps {
  source: VehicleCountSummary;
}

export default function CountSourceMarker({
  source,
  selectedSourceId,
  setSelectedSourceId,
  focusedSourceId,
  setFocusedSourceId,
}: Props) {
  const { formatNumber } = useIntl();
  const selected = selectedSourceId === source.id;
  const focused = focusedSourceId === source.id;
  const icon = selected ? countSourceSelected : countSource;
  return (
    <Marker
      onMouseOver={() => setFocusedSourceId(source.id)}
      onMouseOut={() => setFocusedSourceId(null)}
      onClick={() => setSelectedSourceId(source.id)}
      position={{ lat: source.location!.lat, lng: source.location!.lng }}
      icon={icon as string}
    >
      {focused && (
        <InfoWindow>
          <div className="v2-count-source-marker__container">
            <div className="v2-count-source-marker__header">
              <h4 className="v2-count-source-marker__name">{source.name}</h4>
              <p className="v2-count-source-marker__id">
                ID: {source.externalId}
              </p>
            </div>
            <p className="v2-count-source-marker__count">
              Average daily count:
            </p>
            {(source.directions || []).map((direction) => (
              <div
                key={direction.name}
                className="v2-count-source-marker__direction"
              >
                <div>{direction.name}</div>
                <div className="v2-count-source-marker__direction--bold">
                  {formatNumber(direction.averageDailyCount!)}
                </div>
              </div>
            ))}
            {source.directions && source.directions.length > 0 && (
              <div className="v2-count-source-marker__direction">
                <div>Total</div>
                <div className="v2-count-source-marker__direction--bold">
                  {formatNumber(
                    sum(
                      source.directions.map(
                        (direction) => direction.averageDailyCount!
                      )
                    )
                  )}
                </div>
              </div>
            )}
          </div>
        </InfoWindow>
      )}
    </Marker>
  );
}
