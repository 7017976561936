import { useRef, useState } from 'react';
import { Chart } from 'highcharts';
import ChartEditableAnnotationLayer from './ChartEditableAnnotationLayer';
import { Annotation } from '../../types/annotations';
import InsightChart from '../InsightChart';

export function ChartPanelContent(
  props: Omit<
    React.ComponentProps<typeof InsightChart>,
    'actions' | 'extraConfig' | 'callback' | 'chartContainerRef'
  > & {
    createAnnotation: (annotation: Partial<Annotation>) => Promise<void>;
    resendAnnotationAlert: (annotationId: number) => Promise<void>;
  }
) {
  const [internalChart, setInternalChart] = useState<Chart | null>(null);
  const [isEditingAnnotation, setEditingAnnotation] = useState(false);
  const [showAnnotation, setShowAnnotation] = useState<boolean>(true);
  const chartContainerRef = useRef<HTMLDivElement>(null);
  const {
    chartConfig,
    timeZone,
    createAnnotation,
    resendAnnotationAlert,
    annotations,
  } = props;
  return (
    <InsightChart
      {...props}
      chartContainerRef={chartContainerRef}
      annotations={showAnnotation ? annotations : []}
      chartConfig={
        !isEditingAnnotation
          ? chartConfig
          : {
              ...chartConfig,
              zoomingType: undefined,
              disableCrosshair: true,
              disableTooltip: true,
            }
      }
      actions={
        internalChart && (
          <ChartEditableAnnotationLayer
            createAnnotation={createAnnotation}
            resendAnnotationAlert={resendAnnotationAlert}
            chart={internalChart}
            timeZone={timeZone}
            isEditingAnnotation={isEditingAnnotation}
            setEditingAnnotation={setEditingAnnotation}
            showAnnotation={showAnnotation}
            setShowAnnotation={setShowAnnotation}
          />
        )
      }
      callback={setInternalChart}
    />
  );
}
