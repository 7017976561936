import { Box, useToken } from '@chakra-ui/react';
import { wktToPointArray } from '@lib/gis';
import CustomGoogleMap from 'design-system/atoms/custom-google-map';
import AnimatedRoute from '../AnimatedRoute';

export default function RouteMap({ route }: { route: { wkt: string } }) {
  const path = wktToPointArray(route.wkt);
  const [routeColor] = useToken('colors', ['orange.500']);
  return (
    <Box width="100%" height="300px" borderRadius="2xl" overflow="hidden">
      <CustomGoogleMap setBoundsToElements={[route]}>
        <AnimatedRoute routePoints={path} routeColor={routeColor} />
      </CustomGoogleMap>
    </Box>
  );
}
