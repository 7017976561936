import { Box, Flex } from '@chakra-ui/react';
import { ReactNode } from 'react';

export interface SubstepProps {
  stepNumber: number;
  stepText: ReactNode;
}

export default function Substep({ stepNumber, stepText }: SubstepProps) {
  return (
    <Flex w="full" gap={2} alignItems="center">
      <Box
        flex="0 0 24px"
        height="24px"
        lineHeight="21px"
        borderRadius="50%"
        textAlign="center"
        color="white"
        fontSize="xs"
        fontWeight="bold"
        bg="gray.700"
      >
        {stepNumber}
      </Box>
      <Box fontSize="xs" flex="1">
        {stepText}
      </Box>
    </Flex>
  );
}
