import { useMemo } from 'react';
import { SiteInsightData } from '../data/useInsightChartData';
import { getCompareColor } from '../utils/colors';
import { InsightChartSeries } from '../utils/insightCharts';

export default function useMemoChartDataFromSiteInsight(
  routes: SiteInsightData['routes'] = [],
  hiddenTrackIds: string[],
  colorsByTrackId: Record<string, string>,
  chartType: InsightChartSeries['type'],
  isCompare: boolean = false
) {
  const orderedRoutes = useMemo(
    () =>
      [...(routes ?? [])].sort((routeA, routeB) =>
        routeA.name.localeCompare(routeB.name)
      ),
    [routes]
  );
  const { formattedRoutesData, isDataEmpty, isDataHeavy } = useMemo(
    () => processRoutesData(orderedRoutes),
    [orderedRoutes]
  );
  const formattedChartSeries = useMemo(
    () =>
      orderedRoutes.reduce<InsightChartSeries[]>(
        (accSeries, route, routeIndex) => {
          const isRouteVisible = hiddenTrackIds.indexOf(route.trackId) === -1;
          const routeColor = colorsByTrackId[route.trackId];
          const formattedRouteSeries: InsightChartSeries = {
            type: chartType,
            visible: isRouteVisible,
            showInLegend: isRouteVisible,
            name: route.name,
            data: formattedRoutesData[routeIndex],
            keys: ['x', 'y', 'compareValue'],
            color: routeColor,
            animation: isDataHeavy ? false : undefined,
          };
          accSeries.push(formattedRouteSeries);
          if (isCompare) {
            accSeries.push({
              ...formattedRouteSeries,
              showInLegend: false,
              keys: ['x', '_ignore_key', 'y'],
              color: getCompareColor(routeColor),
              custom: { hideFromTooltip: true },
            });
          }
          return accSeries;
        },
        []
      ),
    [
      orderedRoutes,
      formattedRoutesData,
      hiddenTrackIds,
      colorsByTrackId,
      chartType,
      isCompare,
      isDataHeavy,
    ]
  );

  return { formattedChartSeries, isDataEmpty, isDataHeavy };
}

function processRoutesData(routes: SiteInsightData['routes']) {
  let dataPointCount = 0;
  const formattedRoutesData: [
    number,
    number | undefined,
    number | undefined,
  ][][] = [];
  routes.forEach((route) => {
    const routeData: [number, number | undefined, number | undefined][] = [];
    dataPointCount += route.items.length;
    route.items.forEach((routeItem, itemIndex) => {
      routeData.push([
        routeItem.date,
        routeItem.value ?? undefined,
        route.compareItems?.[itemIndex]?.value ?? undefined,
      ]);
    });
    formattedRoutesData.push(routeData);
  });
  return {
    formattedRoutesData,
    isDataEmpty: dataPointCount === 0,
    isDataHeavy: dataPointCount > 10000,
  };
}
