import { Box, Heading } from '@chakra-ui/react';
import { useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { SiteView } from '../../hooks/useSiteView';
import { EditViewForm } from './EditViewForm';
import { PublicViewList } from './PublicViewList';
import UserView from './UserView';
import './UserViewContainer.scss';
import useSiteUserViewData from '../../data/useSiteUserViewData';
import { getParamsFromSiteViewState } from './utils';

interface UserViewContainerProps {
  insightId: number;
  siteView: SiteView;
}

const UserViewContainer = ({ insightId, siteView }: UserViewContainerProps) => {
  const [showViewForm, setShowViewForm] = useState(false);
  const { data, deleteView, updateView, setDefaultView } = useSiteUserViewData({
    siteId: siteView.state.siteId.toString(),
  });
  if (!data || !siteView) {
    return null;
  }
  const selectedViewsIds = [
    ...(data?.globalViewConfigs || []),
    ...(data?.viewConfigs || []),
  ]
    .filter((viewConfig) =>
      siteView.matchState(
        `?siteId=${siteView.state.siteId}&${viewConfig.queryString}`
      )
    )
    .map((viewConfig) => viewConfig.viewId);
  const isViewSelected = (view) => selectedViewsIds.indexOf(view.viewId) !== -1;
  const handleCreateClick = () => setShowViewForm(true);
  const siteViewParams = getParamsFromSiteViewState(siteView.state);

  return (
    <div className="user-site-view__container">
      <PublicViewList
        insightId={insightId}
        siteId={siteView.state.siteId}
        siteViewParams={siteViewParams}
      />
      {data?.globalViewConfigs && data.globalViewConfigs.length > 0 && (
        <Heading size="xs" width="full" mt={2}>
          <FormattedMessage
            defaultMessage="Shared views"
            id="MHQXex"
            description="Global site level view config header."
          />
        </Heading>
      )}
      {data?.globalViewConfigs &&
        data.globalViewConfigs.map((view) => (
          <UserView
            key={view.viewId}
            insightId={insightId}
            siteId={siteView.state.siteId}
            viewId={view.viewId ?? 0}
            viewName={view.viewName ?? ''}
            viewUrl={view.queryString ?? ''}
            viewDefault={view.default ?? false}
            viewSelected={isViewSelected(view)}
            siteViewParams={siteViewParams}
            setSiteView={siteView.setState}
            editable={view.editable}
            sharedView={view.siteLevel ?? false}
            deleteViewFetcher={deleteView}
            updateViewFetcher={updateView}
            updateUserDefaultViewFetcher={setDefaultView}
          />
        ))}
      {data?.viewConfigs && data.viewConfigs.length > 0 && (
        <Heading size="xs" width="full" mt={2}>
          <FormattedMessage
            defaultMessage="Your views"
            id="f8BKQa"
            description="User view config header."
          />
        </Heading>
      )}
      {data?.viewConfigs &&
        data.viewConfigs.map((view) => (
          <UserView
            key={view.viewId}
            insightId={insightId}
            siteId={siteView.state.siteId}
            viewId={view.viewId ?? 0}
            viewName={view.viewName ?? ''}
            viewUrl={view.queryString ?? ''}
            viewDefault={view.default ?? false}
            viewSelected={isViewSelected(view)}
            siteViewParams={siteViewParams}
            setSiteView={siteView.setState}
            editable={view.editable}
            sharedView={view.siteLevel ?? false}
            deleteViewFetcher={deleteView}
            updateViewFetcher={updateView}
            updateUserDefaultViewFetcher={setDefaultView}
          />
        ))}
      {!showViewForm && (
        <button
          className="user-site-view__btn-add"
          type="button"
          data-testid="create-saved-view"
          onClick={handleCreateClick}
        >
          +{' '}
          <FormattedMessage
            defaultMessage="Create saved view"
            id="fRdPfA"
            description="Save user view button."
          />
        </button>
      )}
      {showViewForm && (
        <Box pt={4} width="full">
          <EditViewForm
            insightId={insightId}
            showViewForm={setShowViewForm}
            siteViewParams={siteViewParams}
          />
        </Box>
      )}
    </div>
  );
};

export default UserViewContainer;
