import useSWR, { mutate as mutateGlobal } from 'swr';
import { postSchedule, deleteSchedule } from '../api/schedule';
import { getSite, patchSite, putSite, postMonitoringStatus } from '../api/site';
import { standardFetch } from '../utils/fetch';
import { Site, SiteSummary } from '../types/site';
import { BlankSchedule, Schedule } from '../types/schedule';
import { envConfig } from '../config/envConfig';
import { sortFutureSchedules } from '../utils/schedules';
import { getSiteDetailsKey, getSitesListForHomePageKey } from './sharedKeys';
import { trpc } from '../config/trpc';

export default function useSitesData(insightId?: string): {
  data: Array<SiteSummary> | undefined;
  isValidating: boolean;
  error: any;
  forceValidate: () => void;
  createSite: (newSite: Partial<Site>) => Promise<Site['siteId']>;
  deleteSite: (siteId: number) => Promise<void>;
  updateSiteProps: (
    siteId: number,
    updatedSiteProps: Partial<Site>
  ) => Promise<void>;
  startSiteMonitoring: (siteId: number) => Promise<[void, void]>;
  stopSiteMonitoring: (siteId: number) => Promise<[void, void]>;
  saveSiteSchedule: (
    siteId: number,
    scheduleData: BlankSchedule | Schedule
  ) => Promise<void>;
  deleteSiteSchedule: (siteId: number, scheduleId: number) => Promise<void>;
} {
  const { data, mutate, isValidating, error } = useSWR<Array<SiteSummary>>(
    insightId
      ? `${envConfig.API_URL}/api/v1/assignment/insight/${insightId}/sitessummary`
      : null,
    (url) =>
      standardFetch(url).then((response: any) => {
        const sitesWithSchedules = response.map((site: SiteSummary) => ({
          ...site,
          schedules: site.schedules || [],
        }));
        return sortSites(sitesWithSchedules);
      })
  );

  const sitebySiteId = (siteId) => data?.find((site) => site.siteId === siteId);

  const updateLocalCache = (siteId, newData) => {
    if (insightId) {
      mutateGlobal(
        getSiteDetailsKey(insightId, siteId),
        (asyncData) => {
          if (!asyncData || asyncData.siteId !== siteId) {
            return undefined;
          }
          // value of permissions field has wrong value from sites endpoint and should not
          // be replaced. To be fixed on backend (todo reference: FRONTEND_SITE_LIST_NULL_PERMISSION)
          return {
            ...asyncData,
            ...newData,
            permissions: asyncData.permissions,
          };
        },
        false
      );
      mutate(
        (asyncData) =>
          asyncData?.map((site) =>
            site.siteId !== newData.siteId ? site : { ...site, ...newData }
          ),
        false
      );
    }
  };

  const addSiteData = (newData: Site) => {
    if (insightId) {
      return Promise.all([
        mutateGlobal(getSiteDetailsKey(insightId, newData.siteId)),
        mutateGlobal(getSitesListForHomePageKey(insightId), undefined, {
          revalidate: true,
        }),
        mutate(
          (asyncData: Array<SiteSummary> | undefined) =>
            asyncData && sortSites(asyncData.concat(newData)),
          false
        ),
      ]);
    }
    return undefined;
  };

  const setSiteMonitoringStatus = (siteId: number, newStatus: boolean) => {
    if (!insightId) {
      return Promise.reject(new Error('Error: No insight ID provided'));
    }
    return postMonitoringStatus(insightId, siteId, newStatus).then(() => {
      updateLocalCache(siteId, { siteId, active: newStatus });
    });
  };

  const deleteSiteSchedule = (siteId: number, scheduleId: number) =>
    deleteSchedule(insightId, siteId, scheduleId).then(() => {
      const siteData = sitebySiteId(siteId);
      const updatedSchedules = (siteData ? siteData.schedules : []).filter(
        (schedule) => schedule.scheduleId !== scheduleId
      );
      updateLocalCache(siteId, { siteId, schedules: updatedSchedules });
    });
  const createSiteSchedule = (siteId: number, scheduleData: BlankSchedule) => {
    const siteData = sitebySiteId(siteId);
    return postSchedule(
      insightId,
      siteId,
      siteData ? siteData.siteTimeZone : '',
      scheduleData.scheduleName,
      scheduleData.startDate,
      scheduleData.endDate
    ).then((response: any) => {
      const updatedSchedules = (siteData ? siteData.schedules : []).concat([
        response.schedule,
      ]);
      updateLocalCache(siteId, { siteId, schedules: updatedSchedules });
    });
  };

  const editSiteSchedule = async (
    siteId: number,
    updatedSchedule: Schedule
  ) => {
    const siteData = sitebySiteId(siteId);
    // FIXME: current edit API is broken, so we delete and re-create the schedule instead
    await deleteSchedule(insightId, siteId, updatedSchedule.scheduleId);
    const createResponse: any = await postSchedule(
      insightId,
      siteId,
      siteData ? siteData.siteTimeZone : '',
      updatedSchedule.scheduleName,
      updatedSchedule.startDate,
      updatedSchedule.endDate
    );
    const updatedSchedules = (siteData ? siteData.schedules : []).map(
      (siteSchedule) =>
        siteSchedule.scheduleId !== updatedSchedule.scheduleId
          ? siteSchedule
          : createResponse.schedule
    );
    updateLocalCache(siteId, { siteId, schedules: updatedSchedules });
  };
  const saveSiteSchedule = (
    siteId: number,
    scheduleData: BlankSchedule | Schedule
  ) =>
    scheduleData.scheduleId
      ? editSiteSchedule(siteId, scheduleData as Schedule)
      : createSiteSchedule(siteId, scheduleData);

  const mutateThenUpdate =
    (serverOp) =>
    async (
      ...args: Parameters<typeof serverOp>
    ): Promise<ReturnType<typeof serverOp>> => {
      const response = await serverOp(...args);
      await mutate();
      return response;
    };

  return {
    data,
    isValidating,
    error,
    forceValidate: () => mutate(),
    createSite: async (newSiteProps) => {
      if (!insightId) {
        return Promise.reject(new Error('Error: No insight ID provided'));
      }
      const siteId = await trpc.site.createNewSite.mutate({
        ...newSiteProps,
        siteAddress: newSiteProps.siteAddress!,
        siteName: newSiteProps.siteName!,
        siteTimeZone: newSiteProps.siteTimeZone!,
        siteType: newSiteProps.siteType!,
        siteUnit: newSiteProps.siteUnit!,
        siteLocation: {
          lat: newSiteProps!.siteLocation!.lat!,
          lng: newSiteProps!.siteLocation!.lng!,
        },
        insightId,
      });

      return getSite(insightId, siteId)
        .then((getSiteResponse) => addSiteData(getSiteResponse))
        .then(() => siteId);
    },
    deleteSite: async (siteId) => {
      if (!insightId) {
        return Promise.reject(new Error('Error: No insight ID provided'));
      }
      return mutateThenUpdate(trpc.site.deleteSite.mutate)({
        siteId: `${siteId}`,
        insightId,
      });
    },
    updateSiteProps: async (siteId, newSiteProps) => {
      if (!insightId) {
        throw new Error('Error: No insight ID provided');
      }
      // PUT request will override monitoring status if not sent with payload
      const siteData = sitebySiteId(siteId);
      const updatedSiteData = { ...siteData, ...newSiteProps };
      const hasUpdatedSiteUnit =
        newSiteProps.siteUnit !== updatedSiteData.siteUnit;
      const hasUpdatedMonitoringParams =
        newSiteProps.active !== updatedSiteData.active ||
        newSiteProps.delayKPI !== updatedSiteData.delayKPI ||
        newSiteProps.speedKPI !== updatedSiteData.speedKPI ||
        newSiteProps.queueKPI !== updatedSiteData.queueKPI;
      if (hasUpdatedMonitoringParams) {
        // PUT method will update KPIs and monitoring status only
        await putSite(insightId, siteId, updatedSiteData);
      }
      // PATCH method will update all other site props
      await patchSite(insightId, siteId, updatedSiteData);
      // Changing siteUnit affects KPIs values, so we need to get updated site from server
      const updatedSiteDataAfterChangesApplied = hasUpdatedSiteUnit
        ? await getSite(insightId, siteId)
        : updatedSiteData;
      updateLocalCache(siteId, updatedSiteDataAfterChangesApplied);
    },

    startSiteMonitoring: (siteId) => {
      const siteData = sitebySiteId(siteId);
      const nextSchedule =
        siteData && siteData.schedules && siteData.schedules.length > 0
          ? sortFutureSchedules(siteData.schedules, siteData.active!)[0]
          : undefined;
      return Promise.all([
        saveSiteSchedule(siteId, {
          endDate: 0,
          scheduleName: 'Current Schedule',
          ...nextSchedule,
          startDate: Date.now(),
        }),
        setSiteMonitoringStatus(siteId, true),
      ]);
    },
    stopSiteMonitoring: (siteId) => {
      const siteData = sitebySiteId(siteId);
      const nextSchedule =
        siteData && siteData.schedules && siteData.schedules.length > 0
          ? sortFutureSchedules(siteData.schedules, siteData.active!)[0]
          : undefined;
      return Promise.all([
        nextSchedule &&
        nextSchedule.scheduleId &&
        nextSchedule.startDate! < Date.now()
          ? deleteSiteSchedule(siteId, nextSchedule.scheduleId)
          : Promise.resolve(),
        setSiteMonitoringStatus(siteId, false),
      ]);
    },
    saveSiteSchedule,
    deleteSiteSchedule,
  };
}

export function sortSites(sitesArray: Array<SiteSummary> = []) {
  return sitesArray.slice().sort((siteA, siteB) => {
    if (siteA.isDefault || (siteA.active && !siteB.active)) {
      return -1;
    }
    if (siteB.isDefault || (!siteA.active && siteB.active)) {
      return 1;
    }
    if (siteA.active && siteB.active) {
      const nextEndDateA = (siteA.schedules || []).reduce(getNextEndDate, 0);
      const nextEndDateB = (siteB.schedules || []).reduce(getNextEndDate, 0);
      if (
        nextEndDateA !== nextEndDateB &&
        (nextEndDateA === 0 || nextEndDateB === 0)
      ) {
        return nextEndDateA - nextEndDateB;
      }
    } else if (!siteA.active && !siteB.active) {
      const nextStartDateA = (siteA.schedules || []).reduce(
        getNextStartDate,
        0
      );
      const nextStartDateB = (siteB.schedules || []).reduce(
        getNextStartDate,
        0
      );
      if (
        nextStartDateA !== nextStartDateB &&
        (nextStartDateA === 0 || nextStartDateB === 0)
      ) {
        return nextStartDateB - nextStartDateA;
      }
    }
    const nameA = (siteA.siteName || '').toUpperCase();
    const nameB = (siteB.siteName || '').toUpperCase();
    if (nameA < nameB) {
      return -1;
    }
    if (nameA > nameB) {
      return 1;
    }
    return 0;
  });
}
function getNextEndDate(nextEndDate: number, schedule: Schedule): number {
  if (schedule.endDate! > Date.now()) {
    return nextEndDate === 0
      ? schedule.endDate!
      : Math.min(nextEndDate, schedule.endDate!);
  }
  return nextEndDate;
}
function getNextStartDate(nextStartDate: number, schedule: Schedule): number {
  if (schedule.startDate! > Date.now()) {
    return nextStartDate === 0
      ? schedule.startDate!
      : Math.min(nextStartDate, schedule.startDate!);
  }
  return nextStartDate;
}
