import { ReactElement, useEffect, useMemo, useRef } from 'react';
import { SWRResponse, KeyedMutator } from 'swr';
import { useIntl } from 'react-intl';
import { Box, Spinner, Flex } from '@chakra-ui/react';
import { MESSAGE_GENERIC_ERROR } from '../constants/messages';
import { errorReport } from './errors';

enum DataHandlerFeedbackVariant {
  INLINE,
  BLOCK,
}

export function hasDataHandlerFeedBack(
  dataHandlersParam: DataHandlerParam | DataHandlerParam[]
) {
  const dataHandlers = Array.isArray(dataHandlersParam)
    ? dataHandlersParam
    : [dataHandlersParam];
  const isFetchingMissingData = dataHandlers.some(
    (handler) => handler.data === undefined && handler.isValidating
  );
  const hasError = dataHandlers.some((handler) => !!handler.error);
  return hasError || isFetchingMissingData;
}

interface DataHandlersFeedback {
  loader?: ReactElement | DataHandlerFeedbackVariant | null;
  error?: ReactElement | DataHandlerFeedbackVariant | null;
}
export type DataHandlerParam = Pick<
  SWRResponse,
  'data' | 'error' | 'isValidating'
>;

export function DataHandlerFeedback({
  dataHandlersParam,
  configParam = {
    loader: DataHandlerFeedbackVariant.BLOCK,
    error: DataHandlerFeedbackVariant.BLOCK,
  },
}: {
  dataHandlersParam: DataHandlerParam | DataHandlerParam[];
  configParam?: DataHandlersFeedback;
}): ReactElement | null {
  const { formatMessage } = useIntl();
  const dataHandlers = useMemo(
    () =>
      Array.isArray(dataHandlersParam)
        ? dataHandlersParam
        : [dataHandlersParam],
    [dataHandlersParam]
  );
  const isFetchingMissingData = dataHandlers.some(
    (handler) => handler.data === undefined && handler.isValidating
  );
  const handlerWithError = dataHandlers.find(
    (handler) => handler.error !== undefined
  );
  const reported = useRef<any>();
  const isComponentMounted = useRef(true);
  const is401 = useMemo(
    () =>
      handlerWithError?.error.message === 'Unauthorized' ||
      handlerWithError?.error.message === '401',
    [handlerWithError?.error.message]
  );

  useEffect(
    () => () => {
      isComponentMounted.current = false;
    },
    []
  );

  useEffect(() => {
    if (
      handlerWithError?.error &&
      reported.current !== handlerWithError?.error
    ) {
      reported.current = handlerWithError?.error;
      if (!is401) {
        errorReport.handled(
          new Error(
            `Error feedback is shown to the user ${handlerWithError?.error?.message}`
          ),
          {
            error: handlerWithError.error.message,
          }
        );
      } else {
        setTimeout(() => {
          if (isComponentMounted.current) {
            errorReport.log(
              new Error(
                'Unauthorized error caused feedback to be shown to the user.'
              )
            );
          }
          // This Unauthorized error can sometimes happen in the following scenario
          // 1. user gets back to the app after a while, several parallel requests are dispatched
          // 2. user is timed out, so all requests will come back with 401
          // 3. when the first 401 comes back, we clear the cache and redirect to login
          // 4. other responses come back and go into swr cache after we have cleared it
          // 5. user logs in and lands back in the original page, at this point swr has cached errors
          // 6. user will see error message while swr revalidates
          // TODO we need to implement swr midleware to ignore all requests that happen after logout.
          // The user experience is correct (we shouw loading Spinner, so it is not a priority)
        }, 10000);
      }
    }
  }, [handlerWithError?.error, dataHandlers, is401]);
  if (!handlerWithError && !isFetchingMissingData) {
    return null;
  }
  let {
    loader = DataHandlerFeedbackVariant.BLOCK,
    error = DataHandlerFeedbackVariant.BLOCK,
  } = configParam;
  if (loader === DataHandlerFeedbackVariant.BLOCK) {
    loader = <LoadingFeedbackBlock />;
  }
  if (loader === DataHandlerFeedbackVariant.INLINE) {
    loader = <LoadingFeedbackInline />;
  }

  if (error === DataHandlerFeedbackVariant.BLOCK) {
    error = (
      <Box data-testid="error-feedback" p={10} textAlign="center">
        {formatMessage(MESSAGE_GENERIC_ERROR)}
      </Box>
    );
  }
  if (error === DataHandlerFeedbackVariant.INLINE) {
    error = (
      <span data-testid="error-feedback">
        {formatMessage(MESSAGE_GENERIC_ERROR)}
      </span>
    );
  }
  return handlerWithError && !is401 ? error : loader;
}

export function useStandardMutation<Data>(
  swrBoundMutation: KeyedMutator<Data>
) {
  return function createStandardMutation<Input, ServerResponse>({
    serverOp,
    localOp,
  }: {
    serverOp: (args: Input) => Promise<ServerResponse>;
    localOp: (
      input: Input,
      response: ServerResponse,
      asyncData: Data | undefined
    ) => Data;
  }) {
    return async function mutate(input: Input) {
      const response = await serverOp(input);
      await swrBoundMutation(
        (asyncData: Data | undefined) => localOp(input, response, asyncData),
        {
          revalidate: false,
        }
      );
    };
  };
}

export function LoadingFeedbackBlock() {
  return (
    <Flex
      data-testid="loading-feedback"
      width="100%"
      height="100%"
      alignItems="center"
      justifyContent="center"
      p={10}
    >
      <Spinner variant="mooven" size="xl" />
    </Flex>
  );
}
export function LoadingFeedbackInline() {
  return (
    <span data-testid="loading-feedback">
      <Spinner variant="mooven" size="sm" verticalAlign="middle" />
    </span>
  );
}

export function useOptimisticMutation<Data>(
  swrBoundMutation: KeyedMutator<Data>
) {
  return function createOptimisticMutation<Input, ServerResponse>({
    serverOp,
    localOp,
  }: {
    serverOp: (args: Input) => Promise<ServerResponse>;
    localOp: (input: Input, data: Data | undefined) => Data;
  }) {
    return function mutateOptimistic(input: Input) {
      return swrBoundMutation(
        async () => {
          await serverOp(input);
          return undefined;
        },
        {
          optimisticData: (data) => localOp(input, data),
          revalidate: false,
          populateCache: false,
        }
      );
    };
  };
}
