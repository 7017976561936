import {
  Box,
  Button,
  Flex,
  FormControl,
  FormErrorMessage,
  Heading,
  HStack,
  Input,
  Link,
  Text,
} from '@chakra-ui/react';
import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { FormattedMessage, useIntl } from 'react-intl';
import { Link as RouterLink } from 'react-router-dom';
import { requestPasswordReset } from '../../api/auth';
import { pathLogin } from '../../constants/path';
import { useAnalytics } from '../../hooks/analytics/useAnalytics';
import { useFailureToast } from '../../hooks/useFailureToast';
import { useRedirectAfterLogin } from '../../hooks/useRedirectAfterLogin';
import { useSuccessToast } from '../../hooks/useSuccessToast';
import MoovenLogo from '../../svg/mooven-logo.svg?react';
import { errorReport } from '../../utils/errors';

interface FormValues {
  email: string;
}

export default function ForgotPassword() {
  const { track } = useAnalytics();
  const failureToast = useFailureToast();
  const successToast = useSuccessToast();
  const { formatMessage } = useIntl();
  const redirectAfterLogin = useRedirectAfterLogin();
  const [emailRequested, setEmailRequested] = useState(false);
  const {
    register,
    formState: { errors, isSubmitting, isValid },
    handleSubmit,
  } = useForm<FormValues>({
    mode: 'onChange',
    defaultValues: {
      email: '',
    },
  });

  const onSubmit = async (values: FormValues) => {
    try {
      await requestPasswordReset(values.email);
      setEmailRequested(true);
      track('Password Reset Requested', {
        referrer: 'Forgot Password',
        email: values.email,
      });
      successToast({
        title: formatMessage({
          defaultMessage: 'Password reset email sent',
          id: '8Vf8fv',
          description: 'Password reset successful toast message',
        }),
      });
    } catch (e) {
      errorReport.critical(e);
      setEmailRequested(false);
      failureToast({
        title: formatMessage({
          defaultMessage:
            'There was an issue sending the password reset email. Please try again or contact us.',
          id: 'BFUmLY',
          description: 'Password reset failure toast message',
        }),
      });
    }
  };

  return (
    <Flex w="100wh" h="100vh" bgColor="white" justify="center" align="center">
      <Box width={{ sm: '768px' }} m={8}>
        <Box w="150px" h="33px">
          <MoovenLogo />
        </Box>
        <Heading size="4xl" mb={4}>
          <FormattedMessage
            defaultMessage="Forgotten your password?"
            id="vmn3SM"
            description="Forgot password header"
          />
        </Heading>
        {emailRequested ? (
          <>
            <Box mb={10}>
              <Text fontSize="2xl">
                <FormattedMessage
                  defaultMessage="An email should arrive shortly to allow you to reset your password."
                  id="G+Z7NO"
                  description="Forgot password subheader"
                />
              </Text>
            </Box>
            <Link
              as={RouterLink}
              to={{ pathname: pathLogin() }}
              state={redirectAfterLogin}
            >
              <FormattedMessage
                defaultMessage="Return to login"
                id="TIrayi"
                description="Return to login action"
              />
            </Link>
          </>
        ) : (
          <form onSubmit={handleSubmit(onSubmit)}>
            <Box mb={10}>
              <Text fontSize="2xl">
                <FormattedMessage
                  defaultMessage="Not to worry, it happens to the best of us."
                  id="Dc3x0Y"
                  description="Forgot password subheader"
                />
              </Text>
              <Text color="gray.500">
                <FormattedMessage
                  defaultMessage="Enter your email address below to receive an email to reset your password."
                  id="2agxi2"
                  description="Forgot password instructions instructions"
                />
              </Text>
            </Box>
            <FormControl mb={10} isInvalid={!!errors.email}>
              <Input
                type="text"
                placeholder="Email"
                aria-label="email"
                {...register('email', {
                  pattern: {
                    value:
                      /^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+\.[:.a-zA-Z0-9-]+$/,
                    message: formatMessage({
                      defaultMessage: 'Please enter a valid email',
                      id: 'Y+9bzF',
                      description: 'Email input validation error message',
                    }),
                  },
                  required: formatMessage({
                    defaultMessage: 'Please enter your email',
                    id: 'sCjYcA',
                    description: 'Error message for username',
                  }),
                })}
              />
              {errors?.email && (
                <FormErrorMessage>{errors.email.message}</FormErrorMessage>
              )}
            </FormControl>
            <HStack mb={10} spacing={4}>
              <Button
                isLoading={isSubmitting}
                cursor="pointer"
                variant="solid"
                colorScheme="brand"
                type="submit"
                isDisabled={!isValid || isSubmitting}
              >
                <FormattedMessage
                  defaultMessage="Send reset email"
                  id="UWnn2y"
                  description="Send password reset email action"
                />
              </Button>
              <Link
                as={RouterLink}
                to={{ pathname: pathLogin() }}
                state={redirectAfterLogin}
              >
                <FormattedMessage
                  defaultMessage="Return to login"
                  id="TIrayi"
                  description="Return to login action"
                />
              </Link>
            </HStack>
          </form>
        )}
      </Box>
    </Flex>
  );
}
