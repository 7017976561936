import VmsLine, {
  ContentNode,
  getInputCharCounts,
} from 'design-system/molecules/vms-line';
import { AddIcon } from '@chakra-ui/icons';
import { Box, Button, HStack, Text, VStack } from '@chakra-ui/react';
import { DeleteIcon } from 'design-system/atoms/custom-icons';
import { useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { LinePartType } from '@vms/shared/src/message-types/common';
import { useRouteSelection } from '../../hooks/useRouteSelection';

export type GenericFrameContent = {
  lines: {
    lineParts: {
      type: 'text' | 'journeyTime';
      content: string;
    }[];
    key: string;
  }[];
  key: string;
};

const maxVmsLineCharCount = 12;

export default function Frame({
  content,
  index,
  onDelete,
  routes,
  onContentChange,
  canAddTag,
  isDisabled,
}: {
  content: GenericFrameContent;
  index: number;
  onDelete?: () => void;
  onContentChange: (newContent: GenericFrameContent) => void;
  routes: { name: string; routeId: number }[];
  canAddTag?: boolean;
  isDisabled?: boolean;
}) {
  const [focusedIndex, setFocusedIndex] = useState<number | undefined>(
    undefined
  );
  const [lastLineCreated, setLastLineCreated] = useState<string | undefined>(
    undefined
  );
  const { lines } = content;
  const { selectRoutes } = useRouteSelection();
  const focusedLineContent =
    focusedIndex === undefined
      ? undefined
      : convertLineToLineComponentInput(lines[focusedIndex], routes);
  const validationMessage = getLineValidationMessage(focusedLineContent);

  const handleLineChange = (lineIndex: number, parts: ContentNode[]) => {
    const newLine = {
      ...lines[lineIndex],
      lineParts: parts.map(
        (p: ContentNode): LinePartType => ({
          type: p.type === 'text' ? 'text' : 'journeyTime',
          content: p.value,
        })
      ),
    };
    const newContent = {
      ...content,
      lines: lines.map((l, i) => (i === lineIndex ? newLine : l)),
    };
    onContentChange(newContent);
  };
  const handleDelete = (lineIndex: number) => {
    onContentChange({
      ...content,
      lines: lines.filter((_, i) => i !== lineIndex),
    });
  };
  const handleAddLine = () => {
    const key = `line-${Math.random()}-${Date.now()}`;
    setLastLineCreated(key);
    onContentChange({
      ...content,
      lines: lines.concat([
        {
          key,
          lineParts: [],
        },
      ]),
    });
  };
  return (
    <VStack w="full" alignItems="flex-start">
      <HStack w="full" justifyContent="space-between" alignItems="center">
        <Text fontSize="xs" fontWeight="bold" as="span">
          <FormattedMessage
            defaultMessage="Frame {index}"
            values={{ index: index + 1 }}
            id="rSHXM5"
          />
        </Text>
        {onDelete && (
          <Button
            size="xs"
            variant="outline"
            leftIcon={<DeleteIcon />}
            onClick={onDelete}
            isDisabled={isDisabled}
          >
            <FormattedMessage defaultMessage="Remove frame" id="nMQQTA" />
          </Button>
        )}
      </HStack>
      <VStack
        opacity={isDisabled ? 0.5 : 1}
        bgColor="gray.900"
        minHeight="188px"
        borderRadius="16px"
        p={4}
        w="full"
        justifyContent="center"
        spacing={1}
      >
        {lines.map((l, lineIndex) => (
          <VmsLine
            key={l.key}
            autoFocus={l.key === lastLineCreated}
            value={convertLineToLineComponentInput(l, routes)}
            onAddNode={async (selectedRoute?: string) => {
              const addedRoute = await selectRoutes(
                routes.filter((r) => `${r.routeId}` === selectedRoute),
                false
              );
              if (addedRoute && addedRoute.length > 0) {
                return {
                  label: addedRoute[0].name,
                  value: `${addedRoute[0].routeId}`,
                  type: 'tag-journey-time',
                };
              }
              return undefined;
            }}
            onChange={(parts: ContentNode[]) =>
              handleLineChange(lineIndex, parts)
            }
            onFocus={() => {
              setFocusedIndex(lineIndex);
            }}
            onBlur={() => {
              setFocusedIndex((currentLineIndex) =>
                currentLineIndex === lineIndex ? undefined : currentLineIndex
              );
            }}
            onRemoveClick={() => handleDelete(lineIndex)}
            maxCharCount={maxVmsLineCharCount}
            canAddTag={canAddTag}
            isDisabled={isDisabled}
          />
        ))}
        {lines.length < 4 && (
          <Box>
            <Button
              size="xs"
              variant="outline"
              leftIcon={<AddIcon />}
              onClick={handleAddLine}
              color="yellow.300"
              borderColor="yellow.300"
              _hover={
                isDisabled
                  ? undefined
                  : {
                      color: 'yellow.500',
                      borderColor: 'yellow.500',
                      backgroundColor: 'black',
                    }
              }
              isDisabled={isDisabled}
            >
              <FormattedMessage defaultMessage="Add line" id="sWPGmB" />
            </Button>
          </Box>
        )}
      </VStack>
      {validationMessage && (
        <Box color="orange.400" fontSize="xs" fontWeight="500">
          {validationMessage}
        </Box>
      )}
    </VStack>
  );
}

function getLineValidationMessage(contentNodes?: ContentNode[]) {
  if (!contentNodes || contentNodes.length === 0) {
    return undefined;
  }
  const chartCount = getInputCharCounts(contentNodes);
  if (
    chartCount >= maxVmsLineCharCount / 2 &&
    chartCount <= maxVmsLineCharCount
  ) {
    return (
      <FormattedMessage
        defaultMessage="characters left: {charCount} (Tags occupy 2 characters)"
        id="qaqBq0"
        values={{ charCount: maxVmsLineCharCount - chartCount }}
      />
    );
  }
  return undefined;
}

function convertLineToLineComponentInput(
  line: {
    lineParts: { type: 'text' | 'journeyTime'; content: string }[];
  },
  routes: { name: string; routeId: number }[]
): ContentNode[] {
  return line.lineParts.map((part) => {
    if (part.type === 'text') {
      return { type: 'text', value: part.content };
    }
    return {
      value: part.content,
      label:
        routes.find((r) => `${r.routeId}` === part.content)?.name ??
        'Not found',
      type: 'tag-journey-time',
    };
  });
}
