import { useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import InfoTooltip from 'design-system/atoms/info-tooltip';
import {
  MESSAGE_GENERIC_ERROR,
  MESSAGE_UI_DISABLED_SITE_MONITOR_PERMISSION,
} from '../constants/messages';
import { useAnalytics } from '../hooks/analytics/useAnalytics';
import useRequestStatus from '../hooks/useRequestStatus';
import { BlankSchedule, Schedule } from '../types/schedule';
import { Site } from '../types/site';
import { hasScheduleOverlap, sortFutureSchedules } from '../utils/schedules';
import './EditSiteSchedules.scss';
import Spinner from './Spinner';
import EditableSiteSchedule from './EditableSite/EditableSiteSchedule';

interface EditSiteSchedulesProps {
  schedules: Schedule[];
  site: Site;
  hasSiteMonitorPermission: boolean;
  startSiteMonitoring: (siteId: number) => Promise<unknown>;
  stopSiteMonitoring: (siteId: number) => Promise<unknown>;
  saveSiteSchedule: (
    siteId: number,
    schedule: Schedule | BlankSchedule
  ) => Promise<unknown>;
  deleteSiteSchedule: (siteId: number, scheduleId: number) => Promise<unknown>;
}

export default function EditSiteSchedules({
  schedules,
  site,
  hasSiteMonitorPermission,
  startSiteMonitoring,
  stopSiteMonitoring,
  saveSiteSchedule,
  deleteSiteSchedule,
}: EditSiteSchedulesProps) {
  const { formatMessage } = useIntl();
  const { track } = useAnalytics();
  const [isCreatingSchedule, setCreatingSchedule] = useState(false);
  const [editingSchedules, setEditingSchedules] = useState<number[]>([]);
  const toggleEditingSchedule = (schedule: Schedule) =>
    setEditingSchedules((asyncEditingSchedules) =>
      asyncEditingSchedules.indexOf(schedule.scheduleId!) > -1
        ? asyncEditingSchedules.filter(
            (filterId) => filterId !== schedule.scheduleId
          )
        : [...asyncEditingSchedules, schedule.scheduleId!]
    );
  const sortedFutureSchedules = sortFutureSchedules(schedules, site.active);
  const nextSchedule =
    sortedFutureSchedules.length > 0 ? sortedFutureSchedules[0] : undefined;
  const [
    isSavingMonitoring,
    hasSavingMonitoringError,
    withSavingMonitoringStatus,
  ] = useRequestStatus();
  const startMonitoringWithStatus = withSavingMonitoringStatus(() =>
    startSiteMonitoring(site.siteId).then(() => {
      track('Site Monitoring Enabled', {
        referrer: 'Edit Site Tab',
      });
    })
  );
  const stopMonitoringWithStatus = withSavingMonitoringStatus(() =>
    stopSiteMonitoring(site.siteId).then(() => {
      track('Site Monitoring Disabled', {
        referrer: 'Edit Site Tab',
      });
    })
  );
  const saveSchedule = (schedule: Schedule | BlankSchedule) =>
    saveSiteSchedule(site.siteId, schedule);
  const deleteSchedule = (schedule: Schedule) =>
    deleteSiteSchedule(site.siteId, schedule.scheduleId!);

  return (
    <div className="v2-edit-site-schedules">
      {(sortedFutureSchedules.length > 0 || isCreatingSchedule) && (
        <div className="v2-edit-site-schedules__schedules-container">
          {sortedFutureSchedules.map((schedule) => {
            const hasOverlap = hasScheduleOverlap(
              schedule,
              sortedFutureSchedules
            );
            return (
              <EditableSiteSchedule
                key={schedule.scheduleId}
                siteTimeZone={site.siteTimeZone}
                schedule={schedule}
                saveSchedule={saveSchedule}
                deleteSchedule={deleteSchedule}
                hasSiteMonitorPermission={hasSiteMonitorPermission}
                hasOverlap={hasOverlap}
                disableStartDate={site.active && schedule === nextSchedule}
                isEditing={editingSchedules.indexOf(schedule.scheduleId!) > -1}
                toggleEditing={() => toggleEditingSchedule(schedule)}
                isLayoutNarrow
              />
            );
          })}
          {isCreatingSchedule && (
            <EditableSiteSchedule
              siteTimeZone={site.siteTimeZone}
              schedule={{
                scheduleName: `Schedule ${sortedFutureSchedules.length + 1}`,
                startDate: 0,
                endDate: 0,
              }}
              saveSchedule={saveSchedule}
              toggleEditing={() => setCreatingSchedule(false)}
              isEditing
              disableStartDate={site.active && !nextSchedule}
              isLayoutNarrow
            />
          )}
        </div>
      )}

      {!site.active ? (
        <div className="v2-edit-site-schedules__monitoring-container">
          <InfoTooltip
            content={
              !hasSiteMonitorPermission
                ? formatMessage(MESSAGE_UI_DISABLED_SITE_MONITOR_PERMISSION)
                : undefined
            }
          >
            <button
              type="button"
              aria-label="Start monitoring"
              onClick={startMonitoringWithStatus}
              className="v2-edit-site-schedules__btn-start"
              disabled={!hasSiteMonitorPermission || isSavingMonitoring}
            >
              <FormattedMessage
                defaultMessage="start monitoring now"
                id="8OaRI5"
                description="Edit site > schedules panel > start monitoring"
              />
            </button>
          </InfoTooltip>
          <span className="v2-edit-site-schedules__monitoring-spinner">
            {isSavingMonitoring && <Spinner />}
          </span>
        </div>
      ) : (
        <div className="v2-edit-site-schedules__monitoring-container">
          <InfoTooltip
            content={
              !hasSiteMonitorPermission
                ? formatMessage(MESSAGE_UI_DISABLED_SITE_MONITOR_PERMISSION)
                : undefined
            }
          >
            <button
              type="button"
              aria-label="Stop monitoring"
              onClick={stopMonitoringWithStatus}
              className="v2-edit-site-schedules__btn-stop"
              disabled={!hasSiteMonitorPermission || isSavingMonitoring}
            >
              <FormattedMessage
                defaultMessage="stop monitoring now"
                id="RbM4mH"
                description="Edit site > schedules panel > stop monitoring"
              />
            </button>
          </InfoTooltip>
          <span className="v2-edit-site-schedules__monitoring-spinner">
            {isSavingMonitoring && <Spinner />}
          </span>
        </div>
      )}
      {!isCreatingSchedule && (
        <InfoTooltip
          content={
            !hasSiteMonitorPermission
              ? formatMessage(MESSAGE_UI_DISABLED_SITE_MONITOR_PERMISSION)
              : undefined
          }
        >
          <button
            type="button"
            onClick={() => {
              setCreatingSchedule(true);
            }}
            className="v2-edit-site-schedules__btn-add"
            disabled={!hasSiteMonitorPermission}
          >
            {'+ '}
            <FormattedMessage
              defaultMessage="add custom schedule"
              id="uVdv/Y"
              description="Edit site > schedules panel > add schedule button label"
            />
          </button>
        </InfoTooltip>
      )}
      {hasSavingMonitoringError && (
        <div className="v2-edit-site-schedules__error">
          {formatMessage(MESSAGE_GENERIC_ERROR)}
        </div>
      )}
    </div>
  );
}
