import { standardFetch } from '../utils/fetch';
import { envConfig } from '../config/envConfig';
import { Site } from '../types/site';

export function getSite(insightId: string, siteId: number) {
  return standardFetch(
    `${envConfig.API_URL}/api/v1/assignment/insight/${insightId}/sitedetails/${siteId}`
  ).then((response: any) => response as Site);
}

export function createSite(insightId: string, newSiteProps: Partial<Site>) {
  return standardFetch<Site>(
    `${envConfig.API_URL}/api/v1/insights/${insightId}/sites`,
    {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(newSiteProps),
    }
  );
}
export function deleteSite(insightId: string, siteId: number) {
  return standardFetch(
    `${envConfig.API_URL}/api/v1/insights/${insightId}/sites/${siteId}`,
    {
      method: 'DELETE',
      headers: { 'Content-Type': 'application/json' },
    }
  );
}
export function patchSite(
  insightId: string,
  siteId: number,
  newSiteProps: Partial<Site>
) {
  return standardFetch(
    `${envConfig.API_URL}/api/v1/insights/${insightId}/sites/${siteId}`,
    {
      method: 'PATCH',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(newSiteProps),
    }
  );
}
export function putSite(
  insightId: string,
  siteId: number,
  newSiteKpis: Partial<Site>
) {
  return standardFetch(
    `${envConfig.API_URL}/api/v1/insights/${insightId}/sites/${siteId}`,
    {
      method: 'PUT',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(newSiteKpis),
    }
  );
}
export function postMonitoringStatus(
  insightId: string,
  siteId: number,
  newStatus: boolean
) {
  return standardFetch(
    `${envConfig.API_URL}/api/v1/insights/${insightId}/sites/${siteId}/status`,
    {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({
        siteId,
        active: newStatus,
      }),
    }
  );
}
