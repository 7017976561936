import {
  Button,
  Center,
  Flex,
  Link,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
} from '@chakra-ui/react';

import { FormattedMessage, useIntl } from 'react-intl';
import { motion } from 'framer-motion';
import { CopyIcon } from '@chakra-ui/icons';
import { useSuccessToast } from '../../hooks/useSuccessToast';
import { useFailureToast } from '../../hooks/useFailureToast';
import MoovenLogo from '../../svg/mooven.svg?react';
import { errorReport } from '../../utils/errors';

export interface ConfirmationModalProps {
  onClose: () => void;
  open: boolean;
  link: string;
}

export default function CreateConfirmation(props: ConfirmationModalProps) {
  const toast = useSuccessToast();
  const { formatMessage } = useIntl();
  const failureToast = useFailureToast();

  const { onClose, open, link } = props;

  const linkGenerated = link !== '';

  return (
    <Modal isCentered isOpen={open} onClose={onClose}>
      <ModalOverlay backgroundColor="whiteAlpha.800" />
      <ModalContent>
        <ModalHeader>
          {linkGenerated ? (
            <FormattedMessage
              defaultMessage="View created"
              id="5H4QBM"
              description="View created confirmation title"
            />
          ) : (
            <FormattedMessage
              defaultMessage="Creating view"
              id="pHawhw"
              description="Creating view confirmation title"
            />
          )}
        </ModalHeader>
        {linkGenerated ? <ModalCloseButton backgroundColor="gray.100" /> : null}
        <ModalBody>
          {!linkGenerated && (
            <>
              <Center pt={4} pb={8} data-ignore-visual-test>
                <motion.div
                  animate={{ rotate: 360 }}
                  transition={{ repeat: Infinity, duration: 1, ease: 'linear' }}
                >
                  <MoovenLogo />
                </motion.div>
              </Center>
              <Center>
                <Text>
                  <FormattedMessage
                    defaultMessage="We're creating your public view, it'll be available shortly"
                    id="1pnnQc"
                    description="We are creating your public view text"
                  />
                </Text>
              </Center>
            </>
          )}
          {linkGenerated && (
            <>
              <Text>
                <FormattedMessage
                  defaultMessage="Your site is now open to the public on the following address:"
                  id="M3n1vR"
                  description="Your site is now open to the public on the following address text"
                />
                Your site is now open to the public on the following address:
              </Text>
              <Button
                variant="outline"
                p={2}
                size="lg"
                borderRadius={6}
                width="full"
                onClick={async () => {
                  try {
                    await navigator.clipboard.writeText(link);
                    toast({
                      title: formatMessage({
                        defaultMessage: 'Link copied',
                        id: 'sipiE8',
                        description:
                          'Public link create confirmation - Link copied',
                      }),
                    });
                  } catch (error) {
                    errorReport.handled(error);
                    failureToast({
                      title: formatMessage({
                        defaultMessage: 'Could not copy the link',
                        id: '153wSm',
                        description:
                          'Create confirmation dropdown - Failed to copy the link',
                      }),
                    });
                  }
                }}
              >
                <Flex
                  justifyContent="space-between"
                  width="full"
                  alignItems="center"
                >
                  <Text fontWeight="normal" m={0}>
                    {link}
                  </Text>
                  <CopyIcon />
                </Flex>
              </Button>
            </>
          )}
        </ModalBody>
        {linkGenerated && (
          <ModalFooter justifyContent="start">
            <Button
              data-testid="confirmation-modal-close-button"
              colorScheme="greenDark"
              mr={2}
              onClick={onClose}
            >
              <FormattedMessage
                defaultMessage="Close"
                id="OadF/W"
                description="View created confirmation dialog close button"
              />
            </Button>
            <Link href={link} fontWeight="bold" isExternal>
              <FormattedMessage
                defaultMessage="Visit page"
                id="nbZvLm"
                description="View created confirmation dialog visit page"
              />
            </Link>
          </ModalFooter>
        )}
      </ModalContent>
    </Modal>
  );
}
