import classnames from 'classnames';
import { ReactNode, Fragment } from 'react';
import { FormattedMessage } from 'react-intl';
import { NavLink, useLocation, useMatch } from 'react-router-dom';
import { Box } from '@chakra-ui/react';
import {
  pathSitesEdit,
  pathSitesPlan,
  pathSitesView,
  PlanInsightType,
} from '../constants/path';
import useParamInsightId from '../hooks/useParamInsightId';
import LayoutSideBar from './LayoutSideBar';
import './LayoutSite.scss';
import SiteSelector from './SiteSelector';
import useOptimalHeight from '../hooks/useOptimalHeight';
import { FEATURE_PLAN_INSIGHT_TYPE_SELECTOR } from '../constants/features';
import useFeatureSwitch, {
  FeatureSwitchState,
} from '../hooks/useFeatureSwitch';

export interface LayoutSiteProps {
  sideBarContent: ReactNode;
  mapContent: ReactNode;
  children?: ReactNode;
  windowTitle?: string;
}

export default function LayoutSite({
  sideBarContent,
  mapContent,
  children,
  windowTitle,
}: LayoutSiteProps) {
  const featurePlanInsightTypeSelector = useFeatureSwitch(
    FEATURE_PLAN_INSIGHT_TYPE_SELECTOR
  );
  const isFeaturePlanInsightTypeSelector =
    featurePlanInsightTypeSelector === FeatureSwitchState.ON;

  const mapHeightParams = isFeaturePlanInsightTypeSelector
    ? []
    : [150, 400, 800];
  const mapHeight = useOptimalHeight(...mapHeightParams);
  const MapContainer = isFeaturePlanInsightTypeSelector
    ? PanelStyleMapContainer
    : Fragment;
  return (
    <LayoutSideBar
      sideBarHeader={
        <div className="v2-layout-sites__side-bar-header">
          <div className="v2-layout-sites__site-selector">
            <SiteSelector />
          </div>
          <SitesTabsNav />
        </div>
      }
      sideBarContent={sideBarContent}
      windowTitle={windowTitle}
    >
      <Box height={children ? mapHeight : 'full'}>
        <MapContainer>{mapContent}</MapContainer>
      </Box>
      {children}
    </LayoutSideBar>
  );
}

function PanelStyleMapContainer({ children }: { children: ReactNode }) {
  return (
    <Box height="full" padding={4} paddingBottom={0}>
      <Box height="full" borderRadius="lg" overflow="hidden">
        {children}
      </Box>
    </Box>
  );
}

function SitesTabsNav() {
  const location = useLocation();
  const planMatch = useMatch(pathSitesPlan());
  const insightId = useParamInsightId();
  return (
    <div className="v2-layout-sites__tabs">
      <NavLink
        to={{ ...location, pathname: pathSitesView(insightId) }}
        className={({ isActive }) =>
          classnames('v2-layout-sites__tab-button', {
            'v2-layout-sites__tab-button--active': isActive,
          })
        }
      >
        <FormattedMessage
          defaultMessage="View"
          id="1TNh3q"
          description="Mode selector of sites page: View button label"
        />
      </NavLink>
      <NavLink
        to={{
          ...location,
          pathname: pathSitesPlan(insightId, PlanInsightType.DELAY),
        }}
        className={classnames('v2-layout-sites__tab-button', {
          'v2-layout-sites__tab-button--active': planMatch,
        })}
      >
        <FormattedMessage
          defaultMessage="Plan"
          id="MHGdeY"
          description="Mode selector of sites page: Plan button label"
        />
      </NavLink>
      <NavLink
        to={{ ...location, pathname: pathSitesEdit(insightId) }}
        className={({ isActive }) =>
          classnames('v2-layout-sites__tab-button', {
            'v2-layout-sites__tab-button--active': isActive,
          })
        }
      >
        <FormattedMessage
          defaultMessage="Edit"
          id="r0W1Pc"
          description="Mode selector of sites page: Edit button label"
        />
      </NavLink>
    </div>
  );
}
