import { ReactNode } from 'react';
import {
  Box,
  Flex,
  IconButton,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  useDisclosure,
  useBreakpointValue,
} from '@chakra-ui/react';
import { motion } from 'framer-motion';
import { ChevronLeftIcon, ChevronRightIcon } from '@chakra-ui/icons';
import { useAnalytics } from '../../hooks/analytics/useAnalytics';

export default function LayoutResponsiveSidebar({
  sidebar,
  children,
  sidebarTitle = '',
  mainTitle = '',
  defaultOpen = false,
}: {
  sidebar: ReactNode;
  children: ReactNode;
  sidebarTitle: ReactNode;
  mainTitle: ReactNode;
  defaultOpen?: boolean;
}) {
  const isMobile = useBreakpointValue(
    { base: true, md: false },
    { ssr: false }
  );
  const { isOpen, onClose, onOpen, onToggle } = useDisclosure({
    defaultIsOpen: defaultOpen || isMobile,
  });

  const analytics = useAnalytics();

  return isMobile ? (
    <Tabs
      index={isOpen ? 0 : 1}
      variant="lineTop"
      borderBottomWidth="0"
      borderTopWidth="2px"
      colorScheme="green"
      isFitted
      display="flex"
      flexDirection="column"
      height="100%"
      isLazy
    >
      <TabPanels flex={1} fontWeight="bold">
        <TabPanel height="100%" padding={0}>
          {sidebar}
        </TabPanel>
        <TabPanel height="100%" padding={0}>
          {children}
        </TabPanel>
      </TabPanels>
      <TabList>
        <Tab onClick={onOpen}>{sidebarTitle}</Tab>
        <Tab onClick={onClose}>{mainTitle}</Tab>
      </TabList>
    </Tabs>
  ) : (
    <Flex padding={6} gap={6} height="100%">
      <motion.div
        initial={{ width: '30%', minWidth: '350px' }}
        animate={{
          width: isOpen ? '70%' : '30%',
          position: 'relative',
          minWidth: '350px',
        }}
      >
        <IconButton
          position="absolute"
          width="30px"
          minWidth="0"
          paddingRight="8px"
          right="-30px"
          top="160px"
          colorScheme="gray"
          icon={isOpen ? <ChevronLeftIcon /> : <ChevronRightIcon />}
          aria-label="Expand sidebar"
          variant="solid"
          borderRadius="0 16px 16px 0 "
          backgroundColor="white"
          fontSize="24px"
          zIndex={1}
          aria-expanded={isOpen}
          onClick={() => {
            analytics.track('Side Bar Toggled', {
              referrer: 'NetworkMonitoring',
              open: !isOpen,
            });
            onToggle();
          }}
        />
        <Box
          height="100%"
          borderRadius="16px"
          backgroundColor="white"
          overflow="auto"
        >
          {sidebar}
        </Box>
      </motion.div>

      <Box
        flex={1}
        backgroundColor="white"
        borderRadius="16px"
        overflow="hidden"
        maxHeight="100%"
      >
        {children}
      </Box>
    </Flex>
  );
}
